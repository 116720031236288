import {ContainerModule} from 'inversify';
import type {IAuthService} from '../service';
import {AUTH_SERVICE, AuthService} from '../service';
import type {IAuthConfigService} from '../config';
import {AUTH_CONFIG_SERVICE, AuthConfigService} from '../config';

const AuthModule = new ContainerModule((bind) => {
  bind<IAuthConfigService>(AUTH_CONFIG_SERVICE).to(AuthConfigService).inSingletonScope();
  bind<IAuthService>(AUTH_SERVICE).to(AuthService).inSingletonScope();
});

export {AuthModule};
