import {AssessmentQuestionType} from './assessment-question-type';

class AssessmentQuestionOptionModel {
  constructor(public displayName: string = '', public displayOrder: number = -1, public value: string = '') {}
}

abstract class AssessmentQuestionModel {
  constructor(
    public name: string = '',
    public displayName: string = '',
    public displayOrder: number = -1,
    public required: boolean = false,
    public defaultValue: string | null = null,
    public visibleIf: string | null = null,
    public minValue: string | null = null,
    public maxValue: string | null = null,
    public type: AssessmentQuestionType = AssessmentQuestionType.Undefined,
    public options: AssessmentQuestionOptionModel[] = [],
  ) {}
}

export {AssessmentQuestionModel};
