import {inject, injectable} from 'inversify';
import type {ITaskRepository, ITaskQuery} from './task.repository.interface';
import type {IHttpService} from '../../../utils/http';
import {HTTP_SERVICE} from '../../../utils/http';
import type {TaskModel} from '../../model';
import {TaskModelFactory} from '../../model';
import type {TaskResponseDto} from '../../dto';
import {NotImplementedException} from '../../../utils/exception/not-implemented.expection';

@injectable()
class TaskRepository implements ITaskRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async list(query?: Partial<ITaskQuery>): Promise<TaskModel[]> {
    let models: TaskModel[] = [];

    await this._http.get<TaskResponseDto[]>(`patientDailyTask`, {params: query}).then(({data}) => {
      models = data.map((task) => TaskModelFactory.fromResponseDto(task));
    });

    return models;
  }

  async save(model: TaskModel, begin = false): Promise<void> {
    await this._http.put(`patientDailyTask/updateStatus`, {
      patientDailyTaskActivityIntervalId: model.id,
      done: !begin,
      begin,
    });
  }

  async setFavoriteLesson(model: TaskModel, isFavoriteLesson: boolean): Promise<void> {
    await this._http.put(`patientDailyTask/setfavoritelesson`, {
      patientDailyTaskActivityIntervalId: model.id,
      isFavoriteLesson: isFavoriteLesson,
    });
  }

  find(): Promise<TaskModel | null> {
    throw new NotImplementedException();
  }

  remove(domain: TaskModel): Promise<void> {
    throw new NotImplementedException();
  }
}

export {TaskRepository};
