import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import type {IOption} from '../../types';
import {DropdownActivatorBehaviorType, DropdownPositionXEnum, DropdownPositionYEnum, useDropdown} from '../dropdown';
import styles from './search-dropdown.module.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import {ChevronLeftIcon} from '../../assets/icons';
import {TextEllipsis} from '../text-ellipsis';

const DEFAULT_MAX_OPTIONS_TO_DISPLAY = 10;

interface ISearchDropdownProps {
  currentOption?: IOption<string | number> | null;
  options: ReadonlyArray<IOption<string | number>>;
  name: string;
  placeholder?: string;
  maxOptionsToDisplay?: number;
  setOption: (option: IOption<string | number>) => void;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const clearString = (value: string): string => value.toLowerCase().trim();

const SearchDropdown: FC<ISearchDropdownProps> = (props) => {
  const {Dropdown, isOpenDropdown, closeDropdown} = useDropdown();
  const [query, setQuery] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpenDropdown) {
      searchRef.current?.focus();
    } else {
      searchRef.current?.blur();
    }
  }, [isOpenDropdown]);

  const optionsFiltered = useMemo(
    () =>
      props.options.filter(
        (option) =>
          clearString(option.displayName).includes(clearString(query)) && props.currentOption?.value !== option.value,
      ),
    [props.currentOption?.value, props.options, query],
  );

  const onOptionClick = useCallback(
    (option: IOption<string | number>) => {
      props.setOption && props.setOption(option);
      setQuery('');
      closeDropdown();
    },
    [closeDropdown, props],
  );

  const optionsToDisplay = useMemo(
    () => optionsFiltered.slice(0, props.maxOptionsToDisplay ?? DEFAULT_MAX_OPTIONS_TO_DISPLAY),
    [optionsFiltered, props.maxOptionsToDisplay],
  );

  const title = props.ariaLabelTitle ?? props.name;
  const error = !!props.ariaLabelError ? `${props.ariaLabelError}.` : '';

  return (
    <div className={styles.dropdownArea}>
      <Dropdown
        activatorBehaviorType={DropdownActivatorBehaviorType.Click}
        positionX={DropdownPositionXEnum.Left}
        positionY={DropdownPositionYEnum.SearchDropdown}
        fullWidth
        activator={({onOpen}) => (
          <button
            aria-label={`${error} ${title}. Option: ${props.currentOption?.displayName}`}
            className={classNames(styles.dropdownLabel, {
              [styles.dropdownLabelOpened]: isOpenDropdown,
              [styles.noDisplayName]: !props.currentOption?.displayName,
            })}
            onClick={(e) => {
              e.preventDefault();
              onOpen();
            }}>
            <div className={styles.dropdownLabelTextArea}>
              <TextEllipsis>{props.currentOption?.displayName}</TextEllipsis>
            </div>
            <ChevronLeftIcon className={isOpenDropdown ? styles.chevronDown : styles.chevronUp} />
          </button>
        )}>
        <OutsideClickHandler onOutsideClick={closeDropdown}>
          <div className={styles.optionsContainer}>
            <input
              id={props.name}
              data-testid={props.name}
              name={props.name}
              aria-label={props.placeholder}
              ref={searchRef}
              placeholder={props.placeholder}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className={styles.input}
              autoComplete={'off'}
            />
            <div className={styles.optionsList}>
              {optionsToDisplay.length ? (
                optionsToDisplay.map((option, index) => (
                  <div
                    className={styles.option}
                    key={index}
                    onClick={() => onOptionClick(option)}
                    tabIndex={0}
                    aria-label={`${error} ${title}. Option: ${option.displayName}`}>
                    <TextEllipsis>{option.displayName}</TextEllipsis>
                  </div>
                ))
              ) : (
                <div className={styles.option} onClick={closeDropdown}>
                  No found data
                </div>
              )}
            </div>
          </div>
        </OutsideClickHandler>
      </Dropdown>
    </div>
  );
};

export {SearchDropdown};
export type {ISearchDropdownProps};
