import {WeekDayEnum} from './week-day.enum';

const getWeekDay = (day: number): string | undefined => {
  switch (day) {
    case 1:
      return WeekDayEnum.Monday;
    case 2:
      return WeekDayEnum.Tuesday;
    case 3:
      return WeekDayEnum.Wednesday;
    case 4:
      return WeekDayEnum.Thursday;
    case 5:
      return WeekDayEnum.Friday;
    case 6:
      return WeekDayEnum.Saturday;
    case 0:
      return WeekDayEnum.Sunday;
    default:
      break;
  }
};

export {getWeekDay};
