import {inject, injectable} from 'inversify';
import type {IVideoStreamService} from './video-stream.service.interface';
import {makeAutoObservable} from 'mobx';
import type {IHttpService} from '../../http';
import {HTTP_SERVICE} from '../../http';
import type {IRoutingService} from '../../routing';
import {ROUTING_SERVICE} from '../../routing';
import type {IAuthConfigService} from '../../auth/config';
import {AUTH_CONFIG_SERVICE} from '../../auth/config';
import type {IAuthService} from '../../auth';
import {AUTH_SERVICE} from '../../auth';

@injectable()
class VideoStreamService implements IVideoStreamService {
  get video(): Blob | null {
    return this._video;
  }

  set video(value: Blob | null) {
    this._video = value;
  }

  private _video: Blob | null = null;

  constructor(
    @inject(HTTP_SERVICE) private readonly _http: IHttpService,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(AUTH_CONFIG_SERVICE) private readonly _authConfig: IAuthConfigService,
    @inject(AUTH_SERVICE) private readonly _auth: IAuthService,
  ) {
    makeAutoObservable(this);
  }

  async getVideo(id: string): Promise<boolean> {
    let needToRedirect = false;

    const resource = await this.getResourceType();

    if (resource) {
      const sourceElement = document.getElementById(id) as HTMLVideoElement;

      if (sourceElement) {
        const token = await this._auth.getToken();

        fetch(`${this._authConfig.audience}/FileStream?type=${resource}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.blob())
          .then((blob) => {
            sourceElement.src = URL.createObjectURL(blob);
            sourceElement.onload = () => {
              URL.revokeObjectURL(sourceElement.src);
            };
          });
      }
    } else {
      needToRedirect = true;
    }

    return needToRedirect;
  }

  private async getResourceType(): Promise<string | null> {
    let resourceType: string | null = null;

    try {
      const {data: resources} = await this._http.get<
        Array<{
          resourceType: string;
          resourceFileName: string;
        }>
      >('/Resource/welcomepatientvideo');

      const webResource = resources?.find((resource) => resource.resourceType === 'WelcomePatientWeb');
      const genericResource = resources?.find((resource) => resource.resourceType === 'WelcomePatientGeneric');

      if (!!webResource?.resourceFileName) {
        resourceType = webResource.resourceType;
      } else if (genericResource?.resourceFileName) {
        resourceType = genericResource.resourceType;
      }

      return resourceType;
    } catch (e) {
      console.error(e);

      return resourceType;
    }
  }
}

export {VideoStreamService};
