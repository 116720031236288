import type {FC} from 'react';
import React, {useCallback, useMemo, useState} from 'react';
import styles from './documents-modal.module.scss';
import type {IFile} from '../../../components';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  DatePicker,
  DatePickerInputType,
  DatePickerView,
  Error,
  FileUploader,
  Input,
  Modal,
  SingleDropdown,
  TextArea,
} from '../../../components';
import {MAX_FILE_SIZE} from '../../../helpers';
import {observer} from 'mobx-react';
import {Controller, useForm} from 'react-hook-form';
import {DocumentDateStrings, DocumentType, DocumentTypeStrings} from '../../../enums';
import {isAfter} from 'date-fns';
import classNames from 'classnames';
import type {IDocumentsService} from '../../service';
import {DOCUMENTS_SERVICE} from '../../service';
import type {IDocumentsFormDto} from './documents-form.dto';
import type {IFileStorageService} from '../../../file-storage';
import {FILE_STORAGE_SERVICE} from '../../../file-storage';
import {FileStorageModel, FileType} from '../../../file-storage/model';
import {useInjection} from '../../../ioc';

const documentTypeOptions = [
  {
    value: DocumentType.LabResults,
    displayName: String(DocumentTypeStrings.get(DocumentType.LabResults)),
  },
  {
    value: DocumentType.ImagingReports,
    displayName: String(DocumentTypeStrings.get(DocumentType.ImagingReports)),
  },
  {
    value: DocumentType.EndoscopyColonoscopy,
    displayName: String(DocumentTypeStrings.get(DocumentType.EndoscopyColonoscopy)),
  },
  {
    value: DocumentType.ProviderVisitNotes,
    displayName: String(DocumentTypeStrings.get(DocumentType.ProviderVisitNotes)),
  },
  {
    value: DocumentType.Other,
    displayName: String(DocumentTypeStrings.get(DocumentType.Other)),
  },
];

interface IDocumentsModalProps {
  onClose: () => void;
}

interface IService {
  documents: IDocumentsService;
  fileStorage: IFileStorageService;
}

const DocumentsModal: FC<IDocumentsModalProps & IService> = ({onClose, documents, fileStorage}) => {
  const {handleSubmit, control, watch, formState, getValues} = useForm<IDocumentsFormDto>({
    defaultValues: {type: DocumentType.LabResults},
  });
  const [files, setFiles] = useState<IFile[]>([]);
  const selectDocumentType = watch('type');

  const onSubmit = useCallback(
    (data: IDocumentsFormDto) => {
      files.forEach(async (file) => {
        if (file.base64) {
          const fileData = new FileStorageModel(file.base64, file.file.name, file.file.type, '', FileType.Document);

          const fileId = await fileStorage.upload(fileData);

          if (fileId) {
            await documents.create(data, fileId).then(() => {
              setFiles([]);
              onClose();
            });
          }
        }
      });
    },
    [documents, fileStorage, files, onClose],
  );

  const isNoteShown = useMemo(
    () =>
      [
        DocumentType.Other,
        DocumentType.MedicalRecords,
        DocumentType.ImagingReports,
        DocumentType.EndoscopyColonoscopy,
        DocumentType.ProviderVisitNotes,
        DocumentType.LabResults,
      ].includes(selectDocumentType),
    [selectDocumentType],
  );

  const actions = useMemo(() => {
    const isSubmitDisabled =
      !files.length || !!files.filter((file) => file.errors.length, []).length || formState.isSubmitSuccessful;

    return (
      <CardActions className={styles.cardActions}>
        <Button flat onClick={onClose} className={styles.cancel}>
          cancel
        </Button>
        <Button
          type={'submit'}
          onClick={handleSubmit(onSubmit)}
          className={classNames(styles.complete, {[styles.disable]: isSubmitDisabled})}
          disabled={isSubmitDisabled}>
          Save
        </Button>
      </CardActions>
    );
  }, [files, formState.isSubmitSuccessful, handleSubmit, onClose, onSubmit]);

  return (
    <Modal onClose={onClose}>
      <Card className={styles.card}>
        <CardHeader
          title={'File upload'}
          closable
          noBorder
          titleLarge
          onClose={onClose}
          className={styles.cardHeader}
        />
        <CardContent className={styles.cardContent}>
          <div className={styles.uploadFile}>
            <FileUploader
              accept={'.jpg, .jpeg, .png, .gif, .pdf, .doc, .docx'}
              maxSizeBytes={MAX_FILE_SIZE}
              errorMessageMaxSize={'File size limit is 5mb. Please resize and try again'}
              errorMessageAccept={'Invalid file format'}
              dragTextBefore={'Drag and drop or'}
              dragTextAfter={'your file'}
              uploadText={'browse'}
              onSetFiles={setFiles}
              filesUpload={files}
            />
          </div>
          <div className={styles.form}>
            <form>
              <Controller
                name={'type'}
                control={control}
                render={({value, name, onChange}) => {
                  const selectedValue = documentTypeOptions.find((option) => option.value === value);

                  return (
                    <div className={styles.documentType}>
                      <div className={styles.label}>Document type</div>
                      <SingleDropdown
                        options={documentTypeOptions}
                        name={name}
                        value={selectedValue}
                        className={styles.singleDropdown}
                        onChange={(documentTypeOption) => onChange(documentTypeOption.value)}
                      />
                    </div>
                  );
                }}
              />
              {selectDocumentType === DocumentType.Other && (
                <Controller
                  name={'otherNote'}
                  control={control}
                  render={({value, name, onChange}) => (
                    <div className={styles.textOther}>
                      <Input name={name} value={value} onValueChanged={onChange} maxLength={100} />
                    </div>
                  )}
                />
              )}
              <Controller
                name={'date'}
                control={control}
                defaultValue={new Date()}
                rules={{
                  validate: (value: Date) => {
                    if (!value) {
                      return;
                    }
                    if (isAfter(value, new Date())) {
                      return "Date can't be in the future";
                    }
                  },
                }}
                render={({value, name, onChange}) => (
                  <div className={styles.date}>
                    <div className={styles.label}>{DocumentDateStrings.get(getValues('type'))}</div>
                    <DatePicker
                      name={name}
                      value={value}
                      onChange={onChange}
                      inputType={DatePickerInputType.Medications}
                      isValid={!formState.errors['date']?.message}
                      view={DatePickerView.Selector}
                    />
                  </div>
                )}
              />
              {!!formState.errors['date']?.message && (
                <div className={styles.error}>
                  <Error errorMessage={formState.errors['date'].message} />
                </div>
              )}
              {isNoteShown && (
                <Controller
                  name={'note'}
                  control={control}
                  render={({value, name, onChange}) => (
                    <div className={styles.note}>
                      <div className={styles.label}>Note</div>
                      <TextArea name={name} value={value} onChange={onChange} maxLength={500} />
                    </div>
                  )}
                />
              )}
            </form>
          </div>
        </CardContent>
        {actions}
      </Card>
    </Modal>
  );
};

const ObservableDocumentsModal = observer(DocumentsModal);

const InjectedDocumentsModalInjected: FC<IDocumentsModalProps> = (props) => (
  <ObservableDocumentsModal
    documents={useInjection(DOCUMENTS_SERVICE)}
    fileStorage={useInjection(FILE_STORAGE_SERVICE)}
    {...props}
  />
);

export {InjectedDocumentsModalInjected as DocumentsModal};
export type {IDocumentsModalProps};
