import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IWorkProductivityRepository} from '../repository';
import {WORK_PRODUCTIVITY_REPOSITORY, WorkProductivityRepository} from '../repository';
import type {IWorkProductivityService} from '../service';
import {WORK_PRODUCTIVITY_SERVICE, WorkProductivityService} from '../service';

const WorkProductivityModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IWorkProductivityRepository>(WORK_PRODUCTIVITY_REPOSITORY).to(WorkProductivityRepository).inSingletonScope();

  bind<IWorkProductivityService>(WORK_PRODUCTIVITY_SERVICE).to(WorkProductivityService).inSingletonScope();
});

export {WorkProductivityModule};
