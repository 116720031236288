const getFtFromFtIn = (ftIn: string): number => {
  const [foot, inch] = extractFtInFromString(ftIn);

  const inchOrZero = inch ?? 0;

  return Number((foot + inchOrZero / 12).toFixed(2));
};

const getFtFromCm = (cm: string): number => {
  const foot = parseFloat(cm) / 30.48;

  return Number(foot.toFixed(2));
};

const getFtInFromCm = (cm: string): string => {
  const onlyInch = Number(cm) * 0.3937;
  const foot = parseInt(String(onlyInch / 12), 10);
  const inch = Math.round(onlyInch % 12);

  return foot ? `${foot} ft ${inch} in` : '';
};

const getFtInFromFt = (ft: number): string[] => {
  const foot = Math.floor(ft);
  const inch = Math.round((ft - foot) * 12);

  return [foot.toString(), inch.toString()];
};

const getCmFromFt = (ft: number): string => {
  const cm = ft * 30.48;

  return Math.round(cm).toString();
};

const getCmFromFtIn = (ftIn: string): string => {
  const cm = getFtFromFtIn(ftIn);

  return cm ? getCmFromFt(cm) : '';
};

const getKgFromLb = (lb: string): string => {
  const kg = parseFloat(lb) * 0.453592;

  return kg.toFixed(2);
};

const getLbFromKg = (kg: string): string => {
  const lb = parseFloat(kg) / 0.453592;

  return lb.toFixed(2);
};

const extractFtInFromString = (str: string): number[] => str?.match(/\b\d+\b/g)?.map((i) => Number(i)) ?? [0, 0];

export {getFtFromFtIn, getFtFromCm, getFtInFromCm, getFtInFromFt, getCmFromFt, getCmFromFtIn, getKgFromLb, getLbFromKg};
