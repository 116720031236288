import type React from 'react';

export interface DateSelectorProps {
  label?: string;
  value?: Date;
  className?: string;
  labelClassName?: string;
  onChange?: (value: Date) => void;
  type?: DateSelectorLegacyTypes;
  name?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  isValid?: boolean;
  disabled?: boolean;
  isInlineCalendar?: boolean;
  format?: string;
  customHeader?: (date: any, changeYear: any, changeMonth: any, decreaseMonth: any, increaseMonth: any) => JSX.Element;
  testId?: string;
  customIcon?: () => JSX.Element;
  nonclickable?: boolean;
  formatWeekDay?: (nameOfDay: string) => void;
  highlightDates?: Array<Date>;
}

export interface IDateSelectorState {
  currentInputValue: string;
  oldValue: string;
}

export interface IDateInputProps {
  value?: Date;
  inputValue?: string;
  setInputValue?: (value: string) => void;
  name?: string;
  onClick?: () => void;
  inputRef?: React.Ref<HTMLInputElement>;
  isValid?: boolean;
  disabled?: boolean;
  testId?: string;
  customIcon?: () => JSX.Element;
}

export enum DateSelectorLegacyTypes {
  Common = 'common',
  Inline = 'inline',
  FullWidth = 'full-width',
  InlineWithSmallLabel = 'inline-small-label',
}
