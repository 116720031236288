import type {StrengthsAssessmentGetResponseDto} from '../dto';
import {StrengthsAssessmentModel} from './strengths-assessment.model';

class StrengthsAssessmentModelFactory {
  static fromGetResponseDto(dto: StrengthsAssessmentGetResponseDto): StrengthsAssessmentModel {
    return new StrengthsAssessmentModel(
      dto.id,
      dto.patientId,
      dto.items,
      dto.lastUpdated ? new Date(dto.lastUpdated) : null,
      dto.name,
      dto.date,
    );
  }
}

export {StrengthsAssessmentModelFactory};
