import type {FC} from 'react';
import React, {useMemo} from 'react';
import styles from './observer-document-modal.module.scss';
import {Card, CardContent, CardHeader, Modal} from '../../../components';
import type {DocumentDetailsModel, DocumentsModel} from '../../model';
import {DocumentDateStrings, DocumentType, DocumentTypeStrings, UserRoleEnum, UserRoleStrings} from '../../../enums';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../../helpers';

interface IObserverDocumentModalProps {
  title: string;
  document: DocumentsModel;
  documentIcon: React.ReactNode;
  documentDetail: DocumentDetailsModel;
  onClose: () => void;
  onDownload: (document: DocumentsModel) => void;
}

const ObserverDocumentModal: FC<IObserverDocumentModalProps> = ({
  document,
  documentDetail,
  documentIcon,
  title,
  onClose,
  onDownload,
}) => {
  const isNoteShown = useMemo(
    () =>
      [
        DocumentType.Other,
        DocumentType.MedicalRecords,
        DocumentType.ImagingReports,
        DocumentType.EndoscopyColonoscopy,
        DocumentType.ProviderVisitNotes,
        DocumentType.LabResults,
      ].includes(documentDetail.documentType),
    [documentDetail.documentType],
  );

  const description = useMemo(() => {
    if (isNoteShown) {
      return (
        <div className={styles.note}>
          <div className={styles.noteTitle}>Note</div>
          <div className={styles.noteValue}>{documentDetail.note}</div>
        </div>
      );
    }

    return null;
  }, [documentDetail, isNoteShown]);

  const documentType =
    documentDetail.documentType === DocumentType.Other && documentDetail.otherNote
      ? `${DocumentTypeStrings.get(documentDetail.documentType)}. ${documentDetail.otherNote}`
      : DocumentTypeStrings.get(documentDetail.documentType);

  const uploadedBy =
    document.createdByTitle && document.createdByTitle !== UserRoleStrings.get(UserRoleEnum.Patient)
      ? `${document.createdByFullName}, ${document.createdByType}`
      : document.createdByFullName;

  return (
    <Modal onClose={onClose}>
      <Card className={styles.card}>
        <CardHeader
          title={title}
          closable
          noBorder
          onClose={onClose}
          className={styles.headerRootClassName}
          titleLarge
        />
        <CardContent className={styles.contentRootClassName}>
          <div className={styles.title}>
            {documentIcon}
            <span
              onClick={() => {
                onDownload(document);
              }}>
              {document.fileName}
            </span>
          </div>
          <div className={styles.details}>
            <div>
              <div className={styles.detailLabel}>Document type</div>
              <div className={styles.detailValue}>{documentType}</div>
            </div>
            <div>
              <div className={styles.detailLabel}>{DocumentDateStrings.get(documentDetail.documentType)}</div>
              <div className={styles.detailValue}>{document.upload ? format(document.upload, DEFAULT_FORMAT) : ''}</div>
            </div>
            <div>
              <div className={styles.detailLabel}>Uploaded by</div>
              <div className={styles.detailValue}>{uploadedBy}</div>
            </div>
          </div>
          {description}
        </CardContent>
      </Card>
    </Modal>
  );
};

export {ObserverDocumentModal};
export type {IObserverDocumentModalProps};
