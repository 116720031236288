import type {SurveySpecModel, SurveyType} from '../../survey';

type ISurveyFormValue =
  | string
  | Date
  | Array<string | null | {displayName: any; value: any} | Date>
  | {id: number; text: string}
  | null
  | {value: string}
  | Object;

type ISurveyFormDto = Record<string, ISurveyFormValue>;

interface ISurveyComment {
  createdById: number;
  createdDate: string;
  description: string;
  objectFileId: string;
  providerFirstName: string;
  providerLastName: string;
  providerTitle: string;
}

interface ISurveyElementResponse {
  elementId: number;
  name: string;
  value: string;
  dynamicSectionIndex?: string | null;
  elementOptionId?: number | null;
  elementOptionName?: string | null;
  comment?: ISurveyComment | null;
}

interface ISurvey {
  surveyId?: number;
  spec: SurveySpecModel | null;
  responses: Array<ISurveyElementResponse>;
  lastModifiedDate: Date | null;
  lastCompletedDate: Date | null;
  formResponses: ISurveyFormDto | null;
}

interface ISurveyByDate {
  survey: ISurvey;
  date: Date;
  type: SurveyType;
  completedDate?: Date | null;
}

class SurveyModel {
  constructor(
    public patientId: number | null = -1,
    public surveys: Array<ISurvey> = [],
    public isOnboarding: boolean = false,
    public surveyType: SurveyType | null = null,
    public surveyResponseId: number | null = null,
    public date?: Date | null,
  ) {}
}

export {SurveyModel};
export type {ISurveyFormValue, ISurveyFormDto, ISurveyElementResponse, ISurvey, ISurveyComment, ISurveyByDate};
