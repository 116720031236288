import type {BodyMassIndexGetResponseDto} from '../repository/dto';
import {BodyMassIndexModel} from './body-mass-index.model';

class BodyMassIndexModelFactory {
  static fromGetResponseDto(dto: BodyMassIndexGetResponseDto): BodyMassIndexModel {
    return new BodyMassIndexModel(dto.id, dto.patientId, new Date(dto.date), dto.weight);
  }
}

export {BodyMassIndexModelFactory};
