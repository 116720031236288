import type {FC} from 'react';
import React from 'react';
import {Icon, IconType, List, ListItem, PageLoader, ToggleSwitch} from '../../../../../../components';
import styles from './my-providers-list.module.scss';
import type {MyExternalProvider} from '../../../../../../account';

interface IMyProvidersListProps {
  isEditMode: boolean;
  isLoading: boolean;
  providers: MyExternalProvider[];
  onUpdate: (model: MyExternalProvider) => void;
  onDelete: (model: MyExternalProvider) => void;
  isShowShareToggle: boolean;
}
export const MyProvidersList: FC<IMyProvidersListProps> = ({
  isEditMode,
  providers,
  onUpdate,
  onDelete,
  isLoading,
  isShowShareToggle,
}) => (
  <List noStyle title={isEditMode ? 'Remove a provider ' : 'My providers'}>
    {!providers.length && !isLoading && <div className={styles.emptyListHea}>Your “My Providers” list is empty</div>}
    {!isLoading ? (
      providers.map((provider) => (
        <ListItem
          card
          title={provider.providerName}
          key={`my-providers--list--provider-${provider.providerId}`}
          actions={
            isEditMode && (
              <Icon type={IconType.RemoveCircleIcon} className={styles.drRemove} onClick={() => onDelete(provider)} />
            )
          }>
          <div className={styles.drDescription}>
            {isShowShareToggle && (
              <>
                Allow this provider to see my data
                <ToggleSwitch
                  value={provider.isSharingInfo}
                  small
                  disabled={!isEditMode}
                  onChange={(val) => {
                    onUpdate({...provider, isSharingInfo: val});
                  }}
                />
              </>
            )}
          </div>
        </ListItem>
      ))
    ) : (
      <div className={styles.loaderContainer}>
        <PageLoader removeMargin />
      </div>
    )}
  </List>
);
