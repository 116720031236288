import {makeAutoObservable} from 'mobx';
import type {INotifierService, INotifyMessage} from './notifier.service.interface';
import {injectable} from 'inversify';

@injectable()
class NotifierService implements INotifierService {
  set message(value: INotifyMessage | null) {
    this._message = value;
  }

  get message(): INotifyMessage | null {
    return this._message;
  }

  private _message: INotifyMessage | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  notify(message: INotifyMessage, timeout = 20000): void {
    this.message = message;

    setTimeout(() => this.clear(), timeout);
  }

  clear(): void {
    this.message = null;
  }
}

export {NotifierService};
