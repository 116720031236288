import {getKgFromLb} from '../helper';

class BodyMassIndexModel {
  constructor(
    public id: number = -1,
    public patientId: number = -1,
    public date: Date = new Date(),
    public weight: number = -1,
  ) {}

  get weightLb(): string {
    const lb = String(this.weight);

    return `${lb} lb`;
  }

  get weightKg(): string {
    const kg = getKgFromLb(String(this.weight));

    return `${kg} kg`;
  }
}

export {BodyMassIndexModel};
