import React from 'react';
import type {FC} from 'react';
import styles from './contacts.module.scss';

interface IProps {
  contactEmail: string;
  contactPhone: string;
}

const Contacts: FC<IProps> = (props) => (
  <ul className={styles.contactList}>
    <li className={styles.contactListCaption}>Contact us</li>
    <li className={styles.contactListItem}>
      <a href={`mailto:${props.contactEmail}`} className={styles.contactListLink}>
        {props.contactEmail}
      </a>
    </li>
    <li className={styles.contactListItem}>
      <a href={`tel:${props.contactPhone}`} className={styles.contactListLink}>
        {props.contactPhone}
      </a>
    </li>
  </ul>
);

export {Contacts};
