import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './chat-message-attachment.module.scss';
import classNames from 'classnames';
import {Card, CardContent, DateWrapper} from '../../../../../components';
import {FileIcon} from '../../../../../assets/icons';
import {fileSizeLabel, TIME_FORMAT} from '../../../../../helpers';
import type {MessageAttachmentModel} from '../../../../../message';
import type {IFileStorageService} from '../../../../../file-storage';
import {FILE_STORAGE_SERVICE} from '../../../../../file-storage';
import type {FileStorageModel} from '../../../../../file-storage/model';
import {useInjection} from '../../../../../ioc';
import {getFileDataUrl} from '../../../../../file-storage/helpers';
import {ImagePreview} from '../image-preview';

interface IChatMessageAttachmentProps {
  attachment: MessageAttachmentModel;
  isRecipient: boolean;
}

interface IService {
  fileStorage: IFileStorageService;
}

const ChatMessageAttachment: FC<IService & IChatMessageAttachmentProps> = ({attachment, isRecipient, fileStorage}) => {
  const [fileData, setFileData] = useState<FileStorageModel>();

  const isAttachmentImage = useMemo(() => attachment.mimeType?.indexOf('image') >= 0, [attachment]);

  const [imageSrc, setImageSrc] = useState('');

  const closeModal = useCallback(() => {
    setImageSrc('');
  }, []);

  useEffect(() => {
    const fileId = attachment.id;

    if (isAttachmentImage) {
      if (!fileStorage?.files.has(fileId)) {
        fileStorage?.get(fileId).then(() => setFileData(fileStorage.files.get(fileId)));
      } else {
        setFileData(fileStorage.files.get(fileId));
      }
    }
  }, [attachment.id, fileStorage, isAttachmentImage]);

  const image = useMemo(() => {
    const imgSrc = fileData ? getFileDataUrl(fileData) : attachment.name;

    return (
      <div className={styles.imgLink} onClick={() => setImageSrc(imgSrc)}>
        <img src={imgSrc} alt={attachment.name} className={styles.image} />
      </div>
    );
  }, [fileData, attachment]);

  const file = useMemo(() => {
    const fileSize = fileSizeLabel(attachment.size);

    return (
      <div className={styles.file}>
        <div className={styles.fileIcon}>
          <FileIcon />
        </div>
        <div>
          <div className={styles.fileName}>{attachment.name}</div>
          <div className={styles.fileInfo}>
            <div className={styles.fileSize}>{fileSize}</div>
            &middot;
            <div className={styles.fileLink} onClick={() => fileStorage?.download(attachment.id)}>
              Download
            </div>
          </div>
        </div>
      </div>
    );
  }, [fileStorage, attachment]);

  return (
    <Card
      className={classNames({
        [styles.wrapperRecipient]: isRecipient,
        [styles.wrapper]: !isRecipient,
      })}
      borderLess>
      <CardContent
        className={classNames({
          [styles.content]: !isRecipient,
          [styles.contentRecipient]: isRecipient,
        })}>
        <p className={styles.date}>
          <DateWrapper date={attachment.createdAt} format={TIME_FORMAT} />
        </p>
        {isAttachmentImage ? image : file}
      </CardContent>
      {!!imageSrc ? <ImagePreview imageSrc={imageSrc} onClose={closeModal} /> : null}
    </Card>
  );
};

const ChatMessageAttachmentInjected: FC<IChatMessageAttachmentProps> = (props) => (
  <ChatMessageAttachment fileStorage={useInjection(FILE_STORAGE_SERVICE)} {...props} />
);

export {ChatMessageAttachment, ChatMessageAttachmentInjected};
export type {IChatMessageAttachmentProps};
