import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, CollapseDescription, DateWrapper, PageLoader, PageTitle, SecondaryModal} from '../../components';
import type {ISelfEfficacyAssessmentFormDto, ISelfEfficacyAssessmentService} from '../service';
import {SELF_EFFICACY_ASSESSMENT_SERVICE} from '../service';
import {useInjection} from '../../ioc';
import {observer} from 'mobx-react';
import {useFormConfirmationDialog, useQuery} from '../../hooks';
import {SelfEfficacyAssessmentForm} from '../form';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE, calculateAge} from '../../account';
import styles from './self-efficacy-assessment-screen.module.scss';
import {SpecialtyType} from '../../enums';

interface IServiceProps {
  selfEfficacyAssessmentService: ISelfEfficacyAssessmentService;
  account: IAccountService;
}

const PAGE_TITLE = 'Self-Efficacy Scale';
const PAGE_TITLE_FOR_ADOLESCENT = 'Pediatric Adolescent Self-Efficacy Scale';

const SelfEfficacyAssessmentScreen: FC<IServiceProps> = ({selfEfficacyAssessmentService, account}) => {
  const {id: assessmentId} = useQuery<{id: number}>();
  const patientId = account.id;

  const {getConfirmation} = useFormConfirmationDialog();

  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [visibleCancelModal, setVisibleCancelModal] = useState(false);

  const pageTitle = useMemo(
    () =>
      account.info?.specialty?.name === SpecialtyType.IBD &&
      !!account.info?.dateOfBirth &&
      calculateAge(new Date(account.info?.dateOfBirth)) < 18
        ? PAGE_TITLE_FOR_ADOLESCENT
        : PAGE_TITLE,
    [account.info?.dateOfBirth, account.info?.specialty?.name],
  );

  useEffect(() => {
    if (assessmentId) {
      selfEfficacyAssessmentService.loadAssessmentById(assessmentId);
    } else {
      selfEfficacyAssessmentService.loadAssessments();
    }
  }, [assessmentId, selfEfficacyAssessmentService]);

  const onSubmit = useCallback(
    async (data: ISelfEfficacyAssessmentFormDto) => {
      setSubmitDisabled(true);

      const isConfirmed = await getConfirmation({});

      if (isConfirmed) {
        patientId && (await selfEfficacyAssessmentService.createAssessment(data, patientId));
      } else {
        setSubmitDisabled(false);
      }
    },
    [getConfirmation, patientId, selfEfficacyAssessmentService],
  );

  const onCancel = useCallback(() => {
    setVisibleCancelModal(true);
  }, [setVisibleCancelModal]);

  const onBack = useCallback(() => selfEfficacyAssessmentService.navigateToList(), [selfEfficacyAssessmentService]);

  const onModalClose = useCallback(() => setVisibleCancelModal(false), []);

  const selectedAssessment =
    selfEfficacyAssessmentService.selectedAssessment || selfEfficacyAssessmentService.lastFilledAssessment;

  const readonly = !!assessmentId || selfEfficacyAssessmentService.isReadonly;

  return (
    <>
      <div className={styles.header}>
        <PageTitle>{pageTitle}</PageTitle>
        {readonly && !selfEfficacyAssessmentService.isLoading && (
          <Button className={styles.button} onClick={onBack}>
            Back
          </Button>
        )}
      </div>

      {selfEfficacyAssessmentService.isLoading ? (
        <PageLoader />
      ) : (
        <>
          {readonly && selectedAssessment?.lastUpdated && (
            <div className={styles.subHeader}>
              Last updated · <DateWrapper date={selectedAssessment?.lastUpdated} />
            </div>
          )}
          {!readonly && (
            <CollapseDescription>
              <p>
                Because we focus on empowering our users to manage both the physical and emotional aspects of their
                condition, we want to understand your current level of confidence in managing it. As you progress
                through the Trellus Elevate™ Program you will see that your self-efficacy will improve as that reflects
                how resilient you are. Please indicate your confidence level in accomplishing each of the following
                items.
              </p>
            </CollapseDescription>
          )}
          <SelfEfficacyAssessmentForm
            selectedAssessmentItems={selectedAssessment?.items ?? null}
            listQuestions={selfEfficacyAssessmentService.listQuestions}
            readonly={readonly}
            isSubmitDisabled={isSubmitDisabled}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </>
      )}
      {visibleCancelModal && (
        <SecondaryModal
          text={'All answers will be lost. Do you want to cancel the assessment?'}
          onClose={onModalClose}
          onSubmit={onBack}
        />
      )}
    </>
  );
};

const SelfEfficacyAssessmentScreenObserver = observer(SelfEfficacyAssessmentScreen);

const SelfEfficacyAssessmentScreenInjected: FC = () => (
  <SelfEfficacyAssessmentScreenObserver
    selfEfficacyAssessmentService={useInjection(SELF_EFFICACY_ASSESSMENT_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
  />
);

export {SelfEfficacyAssessmentScreenInjected as SelfEfficacyAssessmentScreen};
