import {inject, injectable} from 'inversify';
import type {ILabResultsService} from './lab-results.service.interface';
import type {LabResultsModel} from '../model';
import {LabResultsModelFactory} from '../model';
import {LabResultsFormDto, LabResultsFormDtoFactory} from '../form';
import {makeAutoObservable} from 'mobx';
import type {ILabResultsRepository} from '../repository';
import {LAB_RESULTS_REPOSITORY} from '../repository';

@injectable()
class LabResultsService implements ILabResultsService {
  constructor(@inject(LAB_RESULTS_REPOSITORY) private readonly _repo: ILabResultsRepository) {
    makeAutoObservable(this);
  }

  private _results: Array<LabResultsModel> = [];

  get results(): ReadonlyArray<LabResultsModel> {
    return this._results;
  }

  async getResults(patientId: number): Promise<void> {
    this._results = (await this._repo.list().catch(console.error)) || [];
  }

  async saveResult(formDto: LabResultsFormDto, patientId: number, resultId: number | null): Promise<void> {
    const model = LabResultsModelFactory.fromFormDto(formDto, patientId, resultId);

    await this._repo.save(model).catch(console.error);

    return this.getResults(patientId);
  }

  getFormDtoById(id?: number): LabResultsFormDto {
    const model = this.results.find((model) => model.id === id);

    return !!id && !!model ? LabResultsFormDtoFactory.fromModel(model) : new LabResultsFormDto();
  }
}

export {LabResultsService};
