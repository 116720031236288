import type {FC} from 'react';
import React from 'react';
import styles from './radio-ladder.module.scss';
import {LadderIcon} from '../../../assets/icons';
import type {Control} from 'react-hook-form/dist/types/form';
import type {FieldErrors} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {REQUIRED_FIELD_ERROR} from '../../../helpers';
import type {ISurveyRadioButtonOption} from '../../../components';
import {Error, SurveyRadioButtonGroup} from '../../../components';
import classNames from 'classnames';
import type {ISurveyElementResponse, ISurveySpecElement} from '../../index';

interface IPropsRadioLadder {
  element: ISurveySpecElement;
  control: Control;
  readonly: boolean;
  radioOptions: ISurveyRadioButtonOption[];
  errors?: FieldErrors<Record<string, any>>;
  elementValues: ISurveyElementResponse[];
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const RadioLadder: FC<IPropsRadioLadder> = ({element, control, readonly, radioOptions, errors, elementValues, ariaLabelTitle, ariaLabelError}) => {
  const ladderValue = elementValues.find((elementValue) => elementValue.name === element.name)?.elementOptionId;

  return (
    <div className={styles.root}>
      <LadderIcon />
      <div className={styles.options}>
        {readonly ? (
          <div className={styles.readonly}>
            {radioOptions?.map((item) => (
              <div key={item.id}>
                <span className={classNames(styles.radio, {[styles.active]: ladderValue === item.id})} />
                {item.text}
              </div>
            ))}
          </div>
        ) : (
          !!radioOptions && (
            <Controller
              name={element.name}
              control={control}
              rules={{
                required: element.isRequired ? REQUIRED_FIELD_ERROR : undefined,
              }}
              render={({value, onChange}) => (
                <div>
                  <SurveyRadioButtonGroup
                    name={element.name}
                    ariaLabelTitle={ariaLabelTitle}
                    ariaLabelError={ariaLabelError}
                    options={radioOptions}
                    onChange={onChange}
                    value={value}
                    isError={errors && errors[element.name]?.message}
                    className={styles.radioButtonGroup}
                  />
                  {errors && !!errors[element.name]?.message && <Error errorMessage={errors[element.name].message} />}
                </div>
              )}
            />
          )
        )}
      </div>
    </div>
  );
};

export {RadioLadder};
