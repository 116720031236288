import type {FC} from 'react';
import React from 'react';
import styles from './confirmation-modal.module.scss';
import {Checkbox, Modal} from '../../../../../../../components';

interface IConfirmationModalProps {
  onSubmit(): void;
  onClose(): void;
}
export const ConfirmationModal: FC<IConfirmationModalProps> = ({onClose, onSubmit}) => (
  <Modal>
    <div className={styles.modalRoot}>
      <p>
        By opting to share my information with my provider, I understand that my provider needs to agree to receive
        information from Trellus Health before anything will be shared. I also understand that my provider may grant
        access to team members within their organization.
      </p>
      <Checkbox id={'id'} name={'name'} label={'I Agree'} value={false} onChange={onSubmit} />
      <div onClick={onClose} className={styles.modalOut}>
        I do not want to share information with my provider at this time
      </div>
    </div>
  </Modal>
);
