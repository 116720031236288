import type {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import axios from 'axios';
import type {IHttpConfigService} from './config';
import {HTTP_CONFIG_SERVICE} from './config';
import type {IHttpService} from './http.service.interface';
import {inject, injectable} from 'inversify';
import {ApiException, HttpException} from '../exception';
import type {IAuthService} from '../auth';
import {AUTH_SERVICE} from '../auth';

@injectable()
class HttpService implements IHttpService {
  private readonly client: AxiosInstance;

  constructor(
    @inject(HTTP_CONFIG_SERVICE) private readonly config: IHttpConfigService,
    @inject(AUTH_SERVICE) private readonly _auth: IAuthService,
  ) {
    this.client = axios.create({
      baseURL: this.config.apiUrl,
      headers: {Accept: 'application/json'},
      withCredentials: true
    });

    this.client.interceptors.request.use(async (config) => {
      const token = await this._auth.getToken();

      if (token) {
        config.headers.common['Authorization'] = `Bearer ${token}`;
      }

      return config;
    });

    this.client.interceptors.response.use(
      (response) => {
        this._auth.updateToken();

        return response;
      },
      async (error) => {
        if (HttpException.isHttpError(error) && 401 === error.response?.status) {
          await this._auth.logout();
        } else if (HttpException.isHttpError(error) && ApiException.isApiError(error)) {
          throw new ApiException(error);
        } else if (HttpException.isHttpError(error)) {
          throw new HttpException(error);
        } else {
          throw new Error(error);
        }
      },
    );
  }

  async get<Response>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<Response>> {
    return this.client.get<Response>(url, config);
  }

  async delete(url: string, config?: AxiosRequestConfig): Promise<void> {
    return this.client.delete(url, config);
  }

  async post<Response, Request = unknown>(
    url: string,
    data: Request,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Response>> {
    return this.client.post<Response>(url, data, config);
  }

  async put<Response, Request = unknown>(
    url: string,
    data: Request,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Response>> {
    return this.client.put<Response>(url, data, config);
  }
}

export {HttpService};
