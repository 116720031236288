import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import type {ICompanyService} from '../../../company';
import {COMPANY_SERVICE} from '../../../company';
import {AccentText, List} from '../../../components';
import {PreVisitTaskModal} from '../../../components/pre-visit-task';
import {ModalType, PredefinedTasks} from '../../../enums';
import {HealthMonitor} from '../../../health-monitor';
import {useInjection} from '../../../ioc';
import type {ICourseService} from '../../../patient-course/service';
import {COURSE_SERVICE} from '../../../patient-course/service';
import type {IPreVisitService} from '../../../pre-visit';
import {PREVISIT_SERVICE} from '../../../pre-visit';
import {Quote} from '../../../quote';
import {routes} from '../../../routing';
import type {TaskModel} from '../../../task';
import {TaskListItem} from '../../../task/components/task-list-item';
import {TaskModal} from '../../../task/components/task-modal';
import type {ITaskService} from '../../../task/service';
import {TASK_SERVICE} from '../../../task/service';
import type {IAuthConfigService} from '../../../utils/auth/config';
import {AUTH_CONFIG_SERVICE} from '../../../utils/auth/config';
import type {IPopupService} from '../../../utils/popup/service';
import {POPUP_SERVICE} from '../../../utils/popup/service';
import type {IRoutingService} from '../../../utils/routing';
import {ROUTING_SERVICE} from '../../../utils/routing';
import {Course} from '../my-roadmap/components/course';
import {DashboardLink, DashboardSection, NoDataMessage} from './components';
import {DashBoardBanner} from './components/dashboard-banner';
import {LESSONS_SECTION_TITLE, TASKS_SECTION_TITLE} from './dashboard.helper';
import styles from './dashboard.module.scss';
import {prepareList} from './helpers';

const HOME_ROUTE_VALUE = routes.home;
const DASHBOARD_LINK_TEXT = `<span>Show All</span>`;

interface IDashboardScreenProps {
  preVisit: IPreVisitService;
  task: ITaskService;
  course: ICourseService;
  account: IAccountService;
  company: ICompanyService;
  routing: IRoutingService;
  popup: IPopupService;
  authConfig: IAuthConfigService;
}

const DashboardScreen: FC<IDashboardScreenProps> = ({
  preVisit,
  task,
  account,
  company,
  routing,
  popup,
  authConfig,
  course,
}) => {
  const [openModalType, setOpenModalType] = useState<ModalType>(ModalType.None);
  const [selectedTask, setSelectedTask] = useState<TaskModel | null>(null);
  const [courseTask, setCourseTask] = useState<TaskModel | null>(null);

  const checkOpenModalType = useCallback((types: ModalType[]) => types.includes(openModalType), [openModalType]);

  const closeModal = useCallback(() => {
    setOpenModalType(ModalType.None);
    setSelectedTask(null);
  }, []);

  useEffect(() => {
    task.load();
  }, [task]);

  useEffect(() => {
    account.showNotif();
  }, [account]);

  const taskSelect = useCallback(
    (task: TaskModel) => {
      switch (task.patientDailyTaskId) {
        case PredefinedTasks.PreVisitTask:
          if (account.id) {
            preVisit.load(account.id).then(() => {
              setOpenModalType(ModalType.PreVisitTask);
              setSelectedTask(task);
            });
          }
          break;
        case PredefinedTasks.StrengthAssessment:
          routing.push(`${routes.home}${routes.strengthsAssessment}`, {forceRouting: true});
          break;
        case PredefinedTasks.Vaccination:
          routing.push(`${routes.home}${routes.vaccination}`);
          break;
        case PredefinedTasks.WeeklyWellnessReportSurvey:
          routing.push(`${routes.home}${routes.weeklyWellnessReport}`);
          break;
        case PredefinedTasks.ResilienceAssessment:
          routing.push(`${routes.home}${routes.resilienceAssessment}`, {forceRouting: true});
          break;
        case PredefinedTasks.DailySymptomTracker:
          routing.push(`${routes.home}${routes.dailySymptomTracker}`);
          break;
        case PredefinedTasks.EmotionalImpactScale:
          routing.push(`${routes.home}${routes.emotionalImpactScale}`, {forceRouting: true});
          break;
        case PredefinedTasks.SelfEfficacy:
          routing.push(`${routes.home}${routes.selfEfficacy}`, {forceRouting: true});
          break;
        case PredefinedTasks.DiseaseSeverityScreener:
          routing.push(`${routes.home}${routes.diseaseSeverityScreener}`, {forceRouting: true});
          break;
        case PredefinedTasks.ChronicConditionChecklist:
          routing.push(`${routes.home}${routes.chronicConditionChecklist}`, {forceRouting: true});
          break;
        case PredefinedTasks.WorkProductivity:
          routing.push(`${routes.home}${routes.workProductivity}`);
          break;
        case PredefinedTasks.LifeQuality:
          routing.push(`${routes.home}${routes.lifeQuality}`);
          break;
        case PredefinedTasks.GlobalImprovementScale:
          routing.push(`${routes.home}${routes.globalImprovementScale}`);
          break;
        case PredefinedTasks.MedicationsListUpdate:
          routing.push(`${routes.home}${routes.medications}`);
          break;
        default:
          setCourseTask(task);
      }
    },
    [account.id, preVisit, routing],
  );
  const onTaskComplete = useCallback(
    (begin?: boolean) => {
      if (courseTask?.id) {
        task.completeAndRefresh(courseTask?.id, begin);
        !begin && setCourseTask(null);
      }
    },
    [courseTask?.id, task],
  );

  const downloadPreVisitFile = useCallback(() => preVisit.downloadPreVisitFile(), [preVisit]);

  const onPrevisitModalComplete = useCallback(() => {
    if (selectedTask?.id) {
      task.completeAndRefresh(selectedTask.id);
      task.showPreVisitTaskBanner();
      setOpenModalType(ModalType.None);
      setSelectedTask(null);
    }
  }, [task, selectedTask]);

  const sortedTaskList = prepareList(task.activeTasks);

  const renderList = (items: TaskModel[], isLesson: boolean) =>
    items?.map((item) => (
      <TaskListItem
        key={item.id}
        task={item}
        timeZone={account.timeZone}
        isLesson={isLesson}
        {...(isLesson ? {} : {onClick: () => taskSelect(item)})}
      />
    ));

  const tasksLink = useMemo(
    () => (
      <DashboardLink
        isShown={!!task.activeTasks?.length}
        html={DASHBOARD_LINK_TEXT}
        route={`${HOME_ROUTE_VALUE}${routes.tasks}`}
      />
    ),
    [task.activeTasks],
  );
  const lessonsLink = useMemo(
    () => (
      <DashboardLink
        isShown={!!course.courses.length}
        html={DASHBOARD_LINK_TEXT}
        route={`${HOME_ROUTE_VALUE}${routes.myRoadmap}`}
      />
    ),
    [course.courses],
  );

  useEffect(() => {
    void account.getBanners();
  }, [account]);

  const onSubmitBannerClick = useCallback(
    (bannerId: number) => {
      account.setBanner(bannerId);
    },
    [account],
  );

  const setFavoriteLesson = useCallback(
    (taskId: number, isFavorite: boolean) => {
      if (taskId) {
        course.setFavoriteLesson(taskId, isFavorite);
      }
    },
    [course],
  );

  const onLessonComplete = useCallback(
    (id: number) => {
      course.updateTask(id, false);
    },
    [course],
  );

  const onLessonBegin = useCallback(
    (id: number) => {
      course.updateTask(id, true);
    },
    [course],
  );

  return (
    <>
      <TaskModal
        timeZone={account.timeZone}
        selectedTask={courseTask}
        onClose={() => setCourseTask(null)}
        onComplete={onTaskComplete}
        lmsLink={authConfig.lmsCourseLink}
      />
      {account.team && checkOpenModalType([ModalType.PreVisitTask]) && !!selectedTask && (
        <PreVisitTaskModal
          selectedTask={selectedTask}
          title={selectedTask?.title}
          onPreVisitFileDownload={downloadPreVisitFile}
          onClose={closeModal}
          onComplete={onPrevisitModalComplete}
          isSymptomTrackingComplete={preVisit.preVisitInfo.isSymptomTrackingComplete}
          isWeeklyWellnessReportComplete={preVisit.preVisitInfo.isWeeklyWellnessReportComplete}
          isLabResultComplete={preVisit.preVisitInfo.isLabResultComplete}
          timeZone={account.timeZone}
        />
      )}
      <div className={styles.root}>
        {company.companyInfo.lmsLink && (
          <Quote
            user={
              account.info
                ? {
                    name: account.info.firstName,
                    avatar: account?.avatar ? `data:image/png;base64,${account.avatar}` : '',
                  }
                : undefined
            }
            isShowProviderTitle={account.isShowProviderTitle}
            isFirstLogin={account?.isFirstLogin}
          />
        )}
        {!!account.bannersInfo?.length && (
          <DashBoardBanner banners={account.bannersInfo} onSubmit={onSubmitBannerClick} />
        )}
        {!!course.inCompleted?.length && (
          <DashboardSection title={LESSONS_SECTION_TITLE} link={lessonsLink}>
            <List noStyle>
              {course.inCompleted.map((course) => (
                <Course
                  key={`course-id-${course.patientCourseId}`}
                  course={course}
                  tasks={course.tasks}
                  onChangeFavoriteItem={setFavoriteLesson}
                  timeZone={account.timeZone}
                  onLessonComplete={onLessonComplete}
                  lmsLink={authConfig.lmsCourseLink}
                  onBegin={onLessonBegin}
                />
              ))}
            </List>
          </DashboardSection>
        )}
        <DashboardSection title={TASKS_SECTION_TITLE} link={tasksLink}>
          {!!task.activeTasks?.length && <List noStyle>{renderList(sortedTaskList, false)}</List>}
          {!task.activeTasks?.length && <NoDataMessage>You don't have any To-Do's</NoDataMessage>}
        </DashboardSection>
        {!!account.id && (
          <DashboardSection title="Health monitor" headerClassName={styles.sectionHeaderHealth}>
            <AccentText>See your health and wellness progress</AccentText>
            <HealthMonitor patientId={account.id} patientSpecialty={account.info?.specialty?.name ?? ''} />
          </DashboardSection>
        )}
      </div>
    </>
  );
};

const ObservableDashboardScreen = observer(DashboardScreen);
const InjectedDashboardScreen: FC = () => (
  <ObservableDashboardScreen
    account={useInjection(ACCOUNT_SERVICE)}
    preVisit={useInjection(PREVISIT_SERVICE)}
    task={useInjection(TASK_SERVICE)}
    course={useInjection(COURSE_SERVICE)}
    company={useInjection(COMPANY_SERVICE)}
    routing={useInjection(ROUTING_SERVICE)}
    popup={useInjection(POPUP_SERVICE)}
    authConfig={useInjection(AUTH_CONFIG_SERVICE)}
  />
);

export {InjectedDashboardScreen as DashboardScreen, ObservableDashboardScreen};
export type {IDashboardScreenProps};
