import type {IAssessmentReportScoreGetResponseDto} from '../dto/assessment-report-score-get-response.dto';
import {AssessmentReportOverallScoreModel} from './assessment-report-overall-score.model';

class AssessmentReportOverallScoreModelFactory {
  static fromGetResponseDto(dto: IAssessmentReportScoreGetResponseDto): AssessmentReportOverallScoreModel {
    const model = new AssessmentReportOverallScoreModel();
    model.scoreReal = dto.score;

    return model;
  }
}

export {AssessmentReportOverallScoreModelFactory};
