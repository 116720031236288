import type {QuoteModel} from '../model';
import {QuoteModelFabric} from '../model';
import type {IQuoteService} from './quote.service.interface';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {IQuoteGetResponseDto} from '../dto';
import {inject, injectable} from 'inversify';

@injectable()
class QuoteService implements IQuoteService {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  private _quote: QuoteModel | null = null;

  get quote(): QuoteModel | null {
    return this._quote;
  }

  async load(): Promise<void> {
    const response = await this._http.get<IQuoteGetResponseDto>('quote/day').catch(console.error);

    if (response) {
      this._quote = QuoteModelFabric.fromGetResponseDto(response.data);
    }
  }
}

export {QuoteService};
