enum AssessmentReportScoreModelIconKeyEnum {
  Emotion,
  Barriers,
  Nutrition,
  Independence,
  Symptoms,
}

class AssessmentReportScoreModel {
  constructor(
    public title = '',
    public description = '',
    public iconKey = AssessmentReportScoreModelIconKeyEnum.Emotion,
    public maxValue = 0,
    public minValue = 0,
    public score = 0,
  ) {}

  get completedPercent(): number {
    return (this.score * 100) / this.maxValue;
  }
}

export {AssessmentReportScoreModel, AssessmentReportScoreModelIconKeyEnum};
