import type {IGlobalImprovementScaleFormDto} from '../service';
import type {IGlobalImprovementScaleGetResponseDto} from '../repository';
import type {IGlobalImprovementScaleItem} from './global-improvement-scale.model';
import {GlobalImprovementScaleModel} from './global-improvement-scale.model';
import {isRadioButton} from '../../helpers';

class GlobalImprovementScaleModelFactory {
  static fromFormDto(dto: IGlobalImprovementScaleFormDto, patientId: number): GlobalImprovementScaleModel {
    const responses: Array<IGlobalImprovementScaleItem> = Object.keys(dto).reduce((acc, fieldName: any) => {
      const dtoElement = dto[fieldName];

      const globalImprovementScaleItem: IGlobalImprovementScaleItem = {
        question: fieldName,
        value: String(dtoElement),
      };

      if (isRadioButton(dtoElement)) {
        globalImprovementScaleItem.value = dtoElement?.id;
      }

      return [...acc, globalImprovementScaleItem];
    }, [] as Array<IGlobalImprovementScaleItem>);

    return new GlobalImprovementScaleModel(patientId, responses);
  }

  static fromGetDto(dto: IGlobalImprovementScaleGetResponseDto): GlobalImprovementScaleModel {
    return new GlobalImprovementScaleModel(
      dto.patientId,
      dto.items.map((i) => ({
        value: i.value,
        question: i.question,
      })),
      dto.id,
      new Date(dto.lastUpdated),
      dto.name,
    );
  }
}

export {GlobalImprovementScaleModelFactory};
