import type {FC} from 'react';
import React, {useCallback, useState} from 'react';
import styles from './dropdown.module.scss';
import classNames from 'classnames';

enum DropdownPositionXEnum {
  Left,
  Right,
}

enum DropdownPositionYEnum {
  Top,
  Bottom,
  ReverseTop,
  MedicalHistory,
  SearchDropdown,
  Datepicker,
}

enum DropdownActivatorBehaviorType {
  Leave,
  Click,
}

interface IDropdownProps {
  positionX?: DropdownPositionXEnum;
  positionY?: DropdownPositionYEnum;
  activator?: FC<{onOpen: () => void}>;
  activatorBehaviorType?: DropdownActivatorBehaviorType;
  fullWidth?: boolean;
}

const useDropdown = (defaultValue = false) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const Dropdown: FC<IDropdownProps> = useCallback(
    ({
      children,
      activator = () => <></>,
      positionX = DropdownPositionXEnum.Right,
      positionY = DropdownPositionYEnum.Bottom,
      activatorBehaviorType = DropdownActivatorBehaviorType.Leave,
      fullWidth = false,
    }) => {
      const Activator = activator;

      return (
        <div className={styles.root}>
          {!!activator && (
            <Activator
              onOpen={activatorBehaviorType === DropdownActivatorBehaviorType.Click && isOpen ? close : open}
            />
          )}
          {isOpen && (
            <div
              className={classNames(styles.dropdown, {
                [styles.bottom]: positionY === DropdownPositionYEnum.Bottom,
                [styles.top]: positionY === DropdownPositionYEnum.Top,
                [styles.reverseTop]: positionY === DropdownPositionYEnum.ReverseTop,
                [styles.medicalHistory]: positionY === DropdownPositionYEnum.MedicalHistory,
                [styles.searchDropdown]: positionY === DropdownPositionYEnum.SearchDropdown,
                [styles.datepicker]: positionY === DropdownPositionYEnum.Datepicker,
                [styles.left]: positionX === DropdownPositionXEnum.Left,
                [styles.right]: positionX === DropdownPositionXEnum.Right,
                [styles.fullWidth]: fullWidth,
              })}
              onMouseLeave={activatorBehaviorType === DropdownActivatorBehaviorType.Leave ? close : () => {}}>
              {children}
            </div>
          )}
        </div>
      );
    },
    [close, isOpen, open],
  );

  return {isOpenDropdown: isOpen, openDropdown: open, closeDropdown: close, Dropdown};
};

export {useDropdown, DropdownPositionYEnum, DropdownPositionXEnum, DropdownActivatorBehaviorType};
export type {IDropdownProps};
