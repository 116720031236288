import React, {useCallback} from 'react';
import {Button, Card, CardActions, CardContent, Checkbox, Modal} from '../../../../../components';
import {Controller, useForm} from 'react-hook-form';
import styles from './messaging-disabled-modal.module.scss';

export type MessagingDisabledModalForm = {dont_show_again: boolean};
type MessagingDisabledModalProps = {onClose: (data: MessagingDisabledModalForm) => void; checkboxShown?: boolean};

const MessagingDisabledModal: React.FC<MessagingDisabledModalProps> = ({
  onClose,
  checkboxShown = true,
  children,
}): JSX.Element => {
  const {handleSubmit, control} = useForm<MessagingDisabledModalForm>({defaultValues: {dont_show_again: false}});

  const onSubmit = useCallback(
    (data: MessagingDisabledModalForm) => {
      onClose(data);
    },
    [onClose],
  );

  return (
    <Modal>
      <Card className={styles.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <p className={styles.message}>{children}</p>
            {checkboxShown && (
              <Controller
                name="dont_show_again"
                control={control}
                render={({name, value, onChange}) => (
                  <div className={styles.checkbox}>
                    <Checkbox id={name} value={value} name={name} label="Don't show again" onChange={onChange} />
                  </div>
                )}
              />
            )}
          </CardContent>
          <CardActions>
            <Button type="submit">Ok</Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

export {MessagingDisabledModal};
