import type {FC} from 'react';
import styles from './section-title.module.scss';

const SectionTitle: FC<{
  label: string;
  subLabel: string;
  additionalSubLabel?: string;
}> = (props) => (
  <div className={styles.header} tabIndex={0}>
    <div className={styles.label}>{props.label}</div>
    <div className={styles.subLabel}>{props.subLabel}</div>
    {props.additionalSubLabel && <div className={styles.subLabel}>{props.additionalSubLabel}</div>}
  </div>
);

export {SectionTitle};
