import type {FC} from 'react';
import React, {useCallback, useMemo, useState} from 'react';
import {
  Button,
  Card,
  CardContent,
  Error,
  Preloader,
  Radio,
  SearchDropdown,
  ToggleSwitch,
} from '../../../../../components';
import styles from './profile-settings.module.scss';
import type {TimeZone} from '../../../../../profile-settings';
import {Controller, useForm} from 'react-hook-form';
import {DATE_API_FORMAT, DEFAULT_LANGUAGE_ID, formatDate, REQUIRED_FIELD_ERROR} from '../../../../../helpers';
import {observer} from 'mobx-react';
import {UserNameForm} from './forms/user-name';
import {TwoFactorAuthForm} from './forms/two-factor-auth';
import {EmailForm} from './forms/email';
import type {AccountModel} from '../../../../../account';
import {TwoFactorAuthForm as TwoFactorSave} from '../../components/two-factor-auth';
import {isEqual, pick} from 'lodash';
import type {IProfilePersonalInfoForm} from '../personal-info';

interface IProfileSettingsProps {
  timeZones: ReadonlyArray<TimeZone>;
  notificationType: string;
  areSmsReceived: boolean;
  arePushReceived: boolean;
  currentTimeZone: TimeZone | null;
  isLoading: boolean;
  onSubmit: (data: IProfileSettingsFormDto) => void;
  onResetPassword: () => void;
  onResetPhone: (code: string, phone: string) => void;
  email: string;
  userName: string;
  patientPersonalInfo: AccountModel | null;
  requestCode(): Promise<void>;
  onPersonalInfoSubmit: (data: IProfilePersonalInfoForm) => Promise<boolean>;
}

interface IProfileSettingsFormDto {
  timeZone: TimeZone | null;
  notificationType: string;
  receiveSms: boolean;
  receivePush: boolean;
  verificationCode: string;
}

enum NotificationTypes {
  NOTIFICATION_ALL = '0',
  NOTIFICATION_IMPORTANT = '1',
}

const getNotificationDisplayName = (notificationType: string) => {
  switch (notificationType) {
    case NotificationTypes.NOTIFICATION_ALL:
      return 'All';
    case NotificationTypes.NOTIFICATION_IMPORTANT:
      return 'Most important';
    default:
      return '';
  }
};

const RADIO_OPTIONS = [
  {
    id: 'setting_option_all',
    value: NotificationTypes.NOTIFICATION_ALL,
  },
  {
    id: 'setting_option_important',
    value: NotificationTypes.NOTIFICATION_IMPORTANT,
    description: `You will receive only <span class='${styles.radioSubLabelSpan} ${styles.radioSubLabelSpan__red}'>Urgent</span> and <span class='${styles.radioSubLabelSpan} ${styles.radioSubLabelSpan__yellow}'>Important</span> marked notifications. Notifications for vaccinations, tight controls, medications and pre-visit information will be turned off.`,
  },
];

const ProfileSettings: FC<IProfileSettingsProps> = ({
  timeZones,
  notificationType,
  areSmsReceived,
  arePushReceived,
  currentTimeZone,
  onSubmit,
  isLoading,
  onResetPassword,
  onResetPhone,
  email,
  userName,
  patientPersonalInfo,
  onPersonalInfoSubmit,
  requestCode,
}) => {
  const [isEditMode, setEditMode] = useState(false);
  const [visibleEditUserNameModal, setVisibleEditUserNameModal] = useState(false);
  const [visibleEditEmailModal, setVisibleEditEmailModal] = useState(false);
  const [visibleTwoFactorAuthModal, setVisibleTwoFactorAuthModal] = useState(false);
  const [visibleTwoFactorPhoneModal, setVisibleTwoFactorPhoneModal] = useState(false);
  const [visibleTwoFactorAltPhoneModal, setVisibleTwoFactorAltPhoneModal] = useState(false);
  const [visibleTwoFactorModal, setVisibleTwoFactorModal] = useState(false);

  const visibleTwoFactorModalHandler = useCallback(
    async (isOpen?: boolean) => {
      isOpen && (await requestCode());
      setVisibleTwoFactorModal(!visibleTwoFactorModal);
    },
    [requestCode, visibleTwoFactorModal],
  );

  const defaultValues = useMemo(
    (): IProfileSettingsFormDto => ({
      timeZone: currentTimeZone,
      notificationType: notificationType ?? '',

      receiveSms: areSmsReceived,
      receivePush: arePushReceived,
      verificationCode: '',
    }),
    [currentTimeZone, notificationType, arePushReceived, areSmsReceived],
  );

  const {control, errors, handleSubmit, reset, getValues} = useForm<IProfileSettingsFormDto>({
    defaultValues,
  });

  const onCancelSubmit = useCallback(() => {
    setEditMode(false);
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onFormSubmit = useCallback(
    (data: IProfileSettingsFormDto) => {
      isEqual(pick(defaultValues, Object.keys(data)), data) ? onCancelSubmit() : visibleTwoFactorModalHandler(true);
    },
    [visibleTwoFactorModalHandler, defaultValues, onCancelSubmit],
  );

  const submitHandler = (code: string) => {
    const dto = getValues();
    onSubmit({...dto, verificationCode: code});
    reset({...dto});
    setEditMode(false);
  };
  const numberSubmitHandler = (code: string, phone?: string) => {
    phone && onResetPhone(code, phone);
    setEditMode(false);
  };

  const altPhoneNumberSubmitHandler = (code: string, phone?: string) => {
    const data = {
      firstName: patientPersonalInfo?.firstName ?? '',
      lastName: patientPersonalInfo?.lastName ?? '',
      phone: patientPersonalInfo?.phone ?? '',
      alternatePhone: phone || patientPersonalInfo?.alternatePhone || '',
      country: patientPersonalInfo?.country ?? 0,
      zipCode: patientPersonalInfo?.zipCode ?? '',
      state: patientPersonalInfo?.state ?? '',
      city: patientPersonalInfo?.city ?? '',
      address: patientPersonalInfo?.address ?? '',
      dateOfBirth: patientPersonalInfo?.dateOfBirth
        ? formatDate(new Date(patientPersonalInfo?.dateOfBirth), DATE_API_FORMAT)
        : '',
      sex: patientPersonalInfo?.sex ?? 0,
      language: patientPersonalInfo?.language?.id ?? DEFAULT_LANGUAGE_ID,
      isNeedInterpreter:
        patientPersonalInfo?.language?.name !== 'English' && patientPersonalInfo?.language.isNeedInterpreter
          ? patientPersonalInfo?.language.isNeedInterpreter
          : false,
      otherLanguage: patientPersonalInfo?.language?.text ?? '',
      verificationCode: code,
    };

    phone && onPersonalInfoSubmit(data);
    setEditMode(false);
  };

  const phoneModalHandler = useCallback(
    async (isOpen?: boolean) => {
      isOpen && (await requestCode());
      setVisibleTwoFactorPhoneModal(!visibleTwoFactorPhoneModal);
    },
    [requestCode, visibleTwoFactorPhoneModal],
  );

  const alternativePhoneModalHandler = useCallback(
    async (isOpen?: boolean) => {
      isOpen && (await requestCode());
      setVisibleTwoFactorAltPhoneModal(!visibleTwoFactorAltPhoneModal);
    },
    [requestCode, visibleTwoFactorAltPhoneModal],
  );
  const editEmailModal = useCallback(() => setVisibleEditEmailModal(true), []);

  const editUserNameModal = useCallback(() => setVisibleEditUserNameModal(true), []);

  const actions = useMemo(
    () => (
      <div className={styles.actions}>
        {isEditMode ? (
          <>
            <div className={styles.cancel} onClick={onCancelSubmit}>
              Cancel
            </div>
            <Button className={styles.button} type={'submit'} block>
              Save Profile
            </Button>
          </>
        ) : (
          <Button className={styles.button} block onClick={() => setEditMode(true)}>
            Edit Profile
          </Button>
        )}
      </div>
    ),
    [isEditMode, onCancelSubmit],
  );

  return (
    <>
      {isLoading && <Preloader />}
      <form className={styles.cardGrid} onSubmit={handleSubmit(onFormSubmit)}>
        {actions}
        <Card>
          <CardContent gap={0}>
            <div className={styles.fields}>
              <div className={styles.field}>
                <div className={styles.label}>
                  Notifications
                  {isEditMode && <span className={styles.subLabel}>Manage your notifications visibility</span>}
                </div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <Controller
                      control={control}
                      name={'notificationType'}
                      render={({value, name, onChange}) => (
                        <div className={styles.radioContent} role="radiogroup">
                          {RADIO_OPTIONS.map((option) => (
                            <Radio
                              key={option.id}
                              id={option.id}
                              name={name}
                              ariaLabelTitle="Notifications"
                              ariaLabelError=""
                              label={getNotificationDisplayName(option.value)}
                              value={option.value === value}
                              subLabel={option.description}
                              classNameSubLabel={styles.radioSubLabel}
                              onChange={() => onChange(option.value)}
                            />
                          ))}
                        </div>
                      )}
                    />
                  ) : (
                    <>{getNotificationDisplayName(notificationType)}</>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent gap={0}>
            <div className={styles.fields}>
              <div className={styles.field}>
                <div className={styles.label}>Permission</div>
                <div className={styles.value}>
                  <Controller
                    control={control}
                    name={'receiveSms'}
                    render={({value, name, onChange}) => (
                      <div>
                        <div className={styles.toggleLabel}>SMS</div>
                        {isEditMode && (
                          <div className={styles.toggleSubLabel}>
                            Allow Trellus Elevate to send SMS in case of urgent information.
                          </div>
                        )}
                        <div className={styles.toggleControl}>
                          <ToggleSwitch
                            id="receiveSms"
                            disabled={!isEditMode}
                            small
                            value={value}
                            name={name}
                            ariaLabelTitle="Allow Trellus Elevate to send SMS in case of urgent information"
                            ariaLabelError=""
                            onChange={onChange}
                          />
                          <label htmlFor="receiveSms" className={styles.toggleValue} data-testid={'receiveSms'}>
                            {value ? 'On' : 'Off'}
                          </label>
                        </div>
                      </div>
                    )}
                  />
                  <Controller
                    control={control}
                    name={'receivePush'}
                    render={({value, name, onChange}) => (
                      <div>
                        <div className={styles.toggleLabel}>Push-Notification</div>
                        {isEditMode && (
                          <div className={styles.toggleSubLabel}>
                            Allow Trellus Elevate to send informational Push-notification.
                          </div>
                        )}
                        <div className={styles.toggleControl}>
                          <ToggleSwitch
                            id="receivePush"
                            disabled={!isEditMode}
                            small
                            value={value}
                            name={name}
                            ariaLabelTitle="Allow Trellus Elevate to send informational Push-notification."
                            ariaLabelError=""
                            onChange={onChange}
                          />
                          <label htmlFor="receivePush" className={styles.toggleValue} data-testid={'receivePush'}>
                            {value ? 'On' : 'Off'}
                          </label>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent gap={0}>
            <div className={styles.fields}>
              <div className={styles.field}>
                <div className={styles.label}>Timezone</div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <Controller
                      name={'timeZone'}
                      defaultValue={currentTimeZone}
                      control={control}
                      rules={{required: REQUIRED_FIELD_ERROR}}
                      render={({name, value, onChange}) => (
                        <SearchDropdown
                          name={name}
                          ariaLabelTitle="Timezone"
                          ariaLabelError=""
                          options={timeZones}
                          currentOption={value}
                          placeholder={'Search here...'}
                          setOption={onChange}
                          maxOptionsToDisplay={140}
                        />
                      )}
                    />
                  ) : (
                    currentTimeZone?.displayName ?? ''
                  )}
                  {errors.timeZone?.message && <Error errorMessage={errors.timeZone?.message} />}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent gap={0}>
            <div className={styles.fields}>
              <div className={styles.field}>
                <div className={styles.label}>Account credentials</div>
                <div className={styles.value}>
                  <div>
                    <div
                      className={styles.linkButton}
                      onClick={() => {
                        phoneModalHandler(true);
                      }}
                      role="link"
                      tabIndex={0}
                      aria-label="Change phone number. You will receive SMS with confirmation code...">
                      Change phone number
                    </div>
                    <div className={styles.description}>You will receive SMS with confirmation code...</div>
                  </div>
                  <div>
                    <div
                      className={styles.linkButton}
                      onClick={onResetPassword}
                      role="link"
                      tabIndex={0}
                      aria-label="Reset password. You will receive an email with instructions how to change password">
                      Reset password
                    </div>
                    <div className={styles.description}>
                      You will receive an email with instructions how to change password
                    </div>
                  </div>
                  <div>
                    <div
                      className={styles.linkButton}
                      onClick={editEmailModal}
                      role="link"
                      tabIndex={0}
                      aria-label="Change E-mail. You will go through verification steps to change your E-mail">
                      Change E-mail
                    </div>
                    <div className={styles.description}>
                      You will go through verification steps to change your E-mail
                    </div>
                    <div className={styles.currentData}>Your current E-mail: {email}</div>
                  </div>
                  <div>
                    <div
                      className={styles.linkButton}
                      onClick={editUserNameModal}
                      role="link"
                      tabIndex={0}
                      aria-label="Change Username. You will go through verification steps to change your Username">
                      Change Username
                    </div>
                    <div className={styles.description}>
                      You will go through verification steps to change your Username
                    </div>
                    <div className={styles.currentData}>Your current Username: {userName}</div>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </form>
      <TwoFactorSave
        onClose={alternativePhoneModalHandler}
        onSubmit={altPhoneNumberSubmitHandler}
        isVisible={visibleTwoFactorAltPhoneModal}
        variant={'newAltPhone'}
      />
      <TwoFactorSave
        onClose={phoneModalHandler}
        onSubmit={numberSubmitHandler}
        isVisible={visibleTwoFactorPhoneModal}
        variant={'newPhone'}
      />
      <TwoFactorSave
        onClose={visibleTwoFactorModalHandler}
        onSubmit={submitHandler}
        variant={'simple'}
        isVisible={visibleTwoFactorModal}
      />
      {visibleEditUserNameModal && (
        <UserNameForm
          onClose={() => setVisibleEditUserNameModal(false)}
          openTwoFactorAuth={setVisibleTwoFactorAuthModal}
        />
      )}
      {visibleEditEmailModal && (
        <EmailForm onClose={() => setVisibleEditEmailModal(false)} openTwoFactorAuth={setVisibleTwoFactorAuthModal} />
      )}
      <TwoFactorAuthForm onClose={() => setVisibleTwoFactorAuthModal(false)} isVisible={visibleTwoFactorAuthModal} />
    </>
  );
};

const ObservableProfileSettings = observer(ProfileSettings);

export {ObservableProfileSettings as ProfileSettings};
export type {IProfileSettingsFormDto};
