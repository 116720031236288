import type {FC} from 'react';
import React, {useCallback, useState, useEffect} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {REQUIRED_FIELD_ERROR, scrollToError} from '../../../helpers';
import {
  Button,
  CardActions,
  DatePicker,
  DatePickerInputType,
  DatePickerView,
  Error,
  SingleDropdown,
  SingleDropdownType,
} from '../../../components';
import type {IMedicationsCourseFormDto} from './medications-course-form.dto';
import {MedicationsDoseElement} from '../dose-element';
import {MedicationsFrequencyElement} from '../frequency-element';
import {MedicationsCardWrapper} from '../card-wrapper';
import {MedicationsCardRow} from '../card-row';
import styles from './medications-course-form.module.scss';
import type {IOption} from '../../../types';
import {isAfter, startOfDay} from 'date-fns';
import type {MedicationCourseForm} from '../../dto/medications-course-form.dto';

type MedicationOption = IOption<string>;

interface IMedicationCourseFormProps {
  onClose: () => void;
  onSubmit: (data: IMedicationsCourseFormDto) => void;
  medications: ReadonlyArray<MedicationOption>;
  onSubmitNewMedication: (medication: string) => Promise<string>;
  defaultValues: MedicationCourseForm | null;
}

const MedicationsCourseForm: FC<IMedicationCourseFormProps> = ({
  medications,
  onClose,
  onSubmit,
  onSubmitNewMedication,
  defaultValues,
}) => {
  const [medicationQuery, setMedicationQuery] = useState<string>('');
  const {reset, ...formMethods} = useForm({
    defaultValues: defaultValues || {},
  });

  const onMedicationAdd = useCallback(
    async (medication: string): Promise<string> => {
      let newMedicationId = '';

      if (!!medication) {
        await onSubmitNewMedication(medication).then((res) => (newMedicationId = res));
      }

      return newMedicationId;
    },
    [onSubmitNewMedication],
  );

  useEffect(() => {
    scrollToError(formMethods.formState.errors);
  }, [formMethods.formState.errors]);

  return (
    <FormProvider {...formMethods} reset={reset}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className={styles.formElements}>
          <MedicationsCardRow>
            <MedicationsCardWrapper label={'Medication'}>
              <Controller
                name={'medicationSafeId'}
                control={formMethods.control}
                rules={{required: REQUIRED_FIELD_ERROR}}
                render={({value, name, onChange}) => {
                  const dropdownValue = medications.find((medication) => medication.value === value);

                  return (
                    <SingleDropdown
                      name={name}
                      ariaLabelTitle="Search medications"
                      ariaLabelError={formMethods.formState.errors['medicationSafeId']?.message}
                      options={medications.filter((medication) =>
                        medication.displayName?.toUpperCase().includes(medicationQuery.toUpperCase()),
                      )}
                      value={dropdownValue}
                      placeholder={'Search medications...'}
                      isSearchEnabled
                      type={SingleDropdownType.Common}
                      onQueryChanged={(query) => {
                        setMedicationQuery(query);
                      }}
                      query={medicationQuery}
                      onChange={(providerOption) => onChange(providerOption.value)}
                      noOptionsMessage={'No items match this criteria.'}
                      isError={!!formMethods.formState.errors['medicationSafeId']?.message}
                      visibleOptionsCount={10}
                      className={styles.medicationDropdown}
                      isCleanIcon={!defaultValues}
                      onAdd={onMedicationAdd}
                      addLabel="Add new medication"
                      disabled={!!defaultValues}
                    />
                  );
                }}
              />
              {!!formMethods.formState.errors['medicationSafeId']?.message && (
                <div className={styles.error}>
                  <Error errorMessage={formMethods.formState.errors['medicationSafeId'].message} />
                </div>
              )}
            </MedicationsCardWrapper>
          </MedicationsCardRow>
          <div className={styles.rowWrapper}>
            <MedicationsCardRow className={styles.row}>
              <MedicationsCardWrapper
                isError={!!formMethods.formState.errors['end']?.message}
                label={'Start course date'}>
                <Controller
                  name={'start'}
                  control={formMethods.control}
                  render={({value, name, onChange}) => (
                    <DatePicker
                      name={name}
                      ariaLabelTitle="Start course date"
                      ariaLabelError={formMethods.formState.errors['start']?.message}
                      value={value}
                      onChange={onChange}
                      inputType={DatePickerInputType.Medications}
                      isValid={
                        !formMethods.formState.errors['start']?.message && !formMethods.formState.errors['end']?.message
                      }
                      view={DatePickerView.Selector}
                      numberYearsFuture={1}
                    />
                  )}
                />
              </MedicationsCardWrapper>
              <MedicationsCardWrapper
                isError={!!formMethods.formState.errors['end']?.message}
                label={'End course date'}>
                <Controller
                  name={'end'}
                  control={formMethods.control}
                  rules={{
                    validate: (value: Date) => {
                      if (!value) {
                        return;
                      }
                      if (isAfter(formMethods.getValues('start') as Date, value)) {
                        return "End course date can’t be earlier than 'Start course date'";
                      }
                      if (isAfter(startOfDay(new Date()), value)) {
                        return 'End course date can’t be earlier than today';
                      }
                    },
                  }}
                  render={({value, name, onChange}) => (
                    <DatePicker
                      name={name}
                      ariaLabelTitle="End course date"
                      ariaLabelError={formMethods.formState.errors['end']?.message}
                      value={value}
                      onChange={onChange}
                      inputType={DatePickerInputType.Medications}
                      isValid={!formMethods.formState.errors['end']?.message}
                      view={DatePickerView.Selector}
                      numberYearsFuture={1}
                    />
                  )}
                />
              </MedicationsCardWrapper>
            </MedicationsCardRow>
            {!!formMethods.formState.errors['end']?.message && (
              <div className={styles.error}>
                <Error errorMessage={formMethods.formState.errors['end'].message} />
              </div>
            )}
          </div>
          <MedicationsCardRow>
            <MedicationsCardWrapper label={'Dose'}>
              <MedicationsDoseElement />
            </MedicationsCardWrapper>
            <MedicationsCardWrapper label={'Frequency'}>
              <MedicationsFrequencyElement />
            </MedicationsCardWrapper>
          </MedicationsCardRow>
        </div>
        <CardActions>
          <Button flat onClick={onClose}>
            Cancel
          </Button>
          <Button bold type="submit">
            Submit
          </Button>
        </CardActions>
      </form>
    </FormProvider>
  );
};

export {MedicationsCourseForm};
export type {MedicationOption};
