import type {IProfileSettingsGetResponseDto, IProfileSettingsGetTimezonesResponseDto} from '../repository';
import {ProfileSettingsModel} from './profile-settings.model';
import type {IProfileSettingsFormDto} from '../../screens/private/profile/components';

class ProfileSettingsModelFactory {
  static fromGetDtos(
    settingsDto: IProfileSettingsGetResponseDto,
    timezonesDto?: Array<IProfileSettingsGetTimezonesResponseDto>,
  ): ProfileSettingsModel {
    const model = new ProfileSettingsModel();

    const timeZones = timezonesDto
      ? timezonesDto.map((timezoneDto) => ({
          value: timezoneDto.code,
          displayName: timezoneDto.name,
        }))
      : [];

    model.notificationType = String(settingsDto.notificationLevel);

    return new ProfileSettingsModel(
      settingsDto.receiveSms,
      settingsDto.receivePush,
      timeZones,
      String(settingsDto.notificationLevel),
      timeZones.find((timeZone) => timeZone.value === settingsDto.timezone),
    );
  }

  static fromFormDto(dto: IProfileSettingsFormDto) {
    return new ProfileSettingsModel(
      dto.receiveSms,
      dto.receivePush,
      [],
      dto.notificationType,
      dto.timeZone,
      dto.verificationCode,
    );
  }
}

export {ProfileSettingsModelFactory};
