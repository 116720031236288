import type {IWssConfig} from './wss.config.interface';
import {injectable} from 'inversify';

declare var API_URL: string;

@injectable()
class WssConfig implements IWssConfig {
  readonly url: string;

  constructor() {
    const url: string =
      process.env.REACT_APP_API_URL ||
      // eslint-disable-next-line no-template-curly-in-string
      (API_URL === '${API_URL}' ? `${window.location.protocol}//${window.location.host}/api` : API_URL);

    this.url = url.replace(/^https?:\/\//, 'wss://');
  }
}

export {WssConfig};
