import type {Dispatch, FC, SetStateAction} from 'react';
import React, {useCallback, useEffect, useMemo} from 'react';
import type {IProfileSettingsService} from '../../../../../../../profile-settings';
import {PROFILE_SETTINGS_SERVICE} from '../../../../../../../profile-settings';
import {Controller, useForm} from 'react-hook-form';
import {Button, Card, CardActions, CardContent, CardHeader, Error, Input, Modal} from '../../../../../../../components';
import styles from './email-form.module.scss';
import {PATTERN_EMAIL_ERROR, REQUIRED_FIELD_ERROR} from '../../../../../../../helpers';
import {Patterns} from '../../../../../../../helpers/patterns';
import {observer} from 'mobx-react';
import {useInjection} from '../../../../../../../ioc';
import {useCountdownTimer} from '../../../../../../../hooks';
import type {IAccountService} from '../../../../../../../account';
import {ACCOUNT_SERVICE} from '../../../../../../../account';

interface IEmailFormProps {
  onClose: () => void;
  openTwoFactorAuth: Dispatch<SetStateAction<boolean>>;
}

interface IServiceProps {
  profileSettingsService: IProfileSettingsService;
  accountService: IAccountService;
}

const name = 'email';

const MAX_EMAIL_LENGTH = 321;
const MAX_EMAIL_LENGTH_ERROR_MESSAGE = `Maximum length: ${MAX_EMAIL_LENGTH} characters`;
const OLD_EMAIL_ERROR = 'The new E-mail must be different from the old one';

type EmailFormDto = Record<'email', string>;

const EmailForm: FC<IServiceProps & IEmailFormProps> = ({
  profileSettingsService,
  accountService,
  onClose,
  openTwoFactorAuth,
}) => {
  const [resendCodeTimer, reloadResendCodeTimer, isDuringResetCodeTimer] = useCountdownTimer(
    0,
    profileSettingsService.resendCodeTimeout,
  );

  const {
    control,
    formState: {errors, isSubmitting},
    watch,
    handleSubmit,
  } = useForm<EmailFormDto>({
    defaultValues: {
      email: accountService.info?.email,
    },
  });

  const onFormSubmit = useCallback(
    async (dto: EmailFormDto) => {
      await profileSettingsService.changeEmail(dto.email);

      if (
        profileSettingsService.isEmailValid &&
        profileSettingsService.phoneForVerifying &&
        profileSettingsService.isCodeRequested &&
        !profileSettingsService.isWaitedResendCode
      ) {
        onClose();
        openTwoFactorAuth(true);
      }
    },
    [onClose, openTwoFactorAuth, profileSettingsService],
  );

  useEffect(() => {
    if (accountService.id && profileSettingsService.isEmailUpdated) {
      accountService.load();
    }
  }, [profileSettingsService.isEmailUpdated, accountService]);

  useEffect(() => {
    if (profileSettingsService.isWaitedResendCode && !isDuringResetCodeTimer) {
      reloadResendCodeTimer(0, profileSettingsService.resendCodeTimeout);
    }
  }, [
    isDuringResetCodeTimer,
    profileSettingsService.isWaitedResendCode,
    profileSettingsService.resendCodeTimeout,
    reloadResendCodeTimer,
  ]);

  const isSubmitDisabled = useMemo(
    () => profileSettingsService.isWaitedResendCode || !watch(name)?.length || isSubmitting,
    [isSubmitting, profileSettingsService.isWaitedResendCode, watch],
  );

  const actions = (
    <CardActions className={styles.bottomWrapper}>
      <div className={styles.resendCodeTimer}>
        {profileSettingsService.isWaitedResendCode &&
          isDuringResetCodeTimer &&
          `You can try again in ${resendCodeTimer} sec`}
      </div>
      <div className={styles.buttons}>
        {onClose && (
          <Button flat onClick={onClose}>
            Cancel
          </Button>
        )}
        <Button type={'submit'} disabled={isSubmitDisabled}>
          Ok
        </Button>
      </div>
    </CardActions>
  );

  return (
    <Modal>
      <Card className={styles.card}>
        <CardHeader title={'New Email'} closable noBorder onClose={onClose} />
        <CardContent>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Controller
              control={control}
              name={name}
              rules={{
                required: REQUIRED_FIELD_ERROR,
                maxLength: {
                  value: MAX_EMAIL_LENGTH,
                  message: MAX_EMAIL_LENGTH_ERROR_MESSAGE,
                },
                pattern: {
                  value: Patterns.Email,
                  message: PATTERN_EMAIL_ERROR,
                },
                validate: (value) => value !== accountService.info?.email ?? OLD_EMAIL_ERROR,
              }}
              render={({name, onChange, value}) => (
                <>
                  <Input
                    name={name}
                    value={value}
                    onValueChanged={onChange}
                    placeholder={'Please enter new email'}
                    isValid={!errors?.email?.message}
                    className={styles.input}
                    withClear={true}
                  />
                  {!!errors?.email?.message && <Error errorMessage={errors.email.message} name={name} />}
                </>
              )}
            />
            <div className={styles.current}>
              Your current Email: <span>{accountService.info?.email}</span>
            </div>
            {actions}
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
};

const EmailFormObserver = observer(EmailForm);
const EmailFormInjected: FC<IEmailFormProps> = (props) => (
  <EmailFormObserver
    {...props}
    profileSettingsService={useInjection(PROFILE_SETTINGS_SERVICE)}
    accountService={useInjection(ACCOUNT_SERVICE)}
  />
);

export {EmailFormInjected as EmailForm};
