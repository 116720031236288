import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import {OnBoardingLayout} from '../../../layouts';
import {Controller, useForm} from 'react-hook-form';
import styles from './account-setup.module.scss';
import {
  Button,
  Checkbox,
  DatePicker,
  DatePickerInputType,
  DatePickerView,
  ErrorListItem,
  ErrorListItemColor,
  ErrorListItemFontSize,
  Input,
  InputType,
  MaskedInput,
  PageLoader,
  Preloader,
  ProgressBar,
  Radio,
  SearchDropdown,
  SingleDropdown,
} from '../../../components';
import {
  DATE_API_FORMAT,
  DEFAULT_COUNTRY_ID,
  DEFAULT_LANGUAGE_ID,
  formatDate,
  getClearedPhoneValue,
  REQUIRED_FIELD_ERROR,
  TEL_LENGTH,
} from '../../../helpers';
import classNames from 'classnames';
import {Patterns} from '../../../helpers/patterns';
import {AlertCircleIcon} from '../../../assets/icons';
import {AccountSetupFormDto} from './dto';
import type {AdditionalInfoModel} from '../../../models';
import type {IProfileSettingsService} from '../../../profile-settings';
import {PROFILE_SETTINGS_SERVICE} from '../../../profile-settings';
import {useInjection} from '../../../ioc';
import {MaskedInputType} from '../../../components/masked-input/masked-input.types';
import {RADIO_OPTIONS} from '../../private/profile/components/personal-info/profile-personal-info.component';
import {isEnglishLanguageSelected, isOtherLanguageSelected} from '../../private/profile/helpers';
import {formatISO, parseISO} from 'date-fns';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import type {IOption} from '../../../types';

const DISABLE_AUTOFILL = 'off';

interface IServiceProps {
  profileSettingsService: IProfileSettingsService;
  account: IAccountService;
}

const acceptLinks = [
  {title: 'Acceptable Use Policy', href: 'https://trellushealth.com/acceptable-use-policy'},
  {title: 'Terms of Use', href: 'https://trellushealth.com/terms-of-use'},
  {title: 'Privacy Policy', href: 'https://trellushealth.com/privacy-policy'},
];

const AccountSetupScreen: FC<IServiceProps> = ({account, profileSettingsService}) => {
  const {handleSubmit, reset, control, getValues, setValue, formState, watch} = useForm<AccountSetupFormDto>({
    defaultValues: new AccountSetupFormDto(),
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    profileSettingsService.load();
    account.loadLanguages();
    account.loadCountries();
  }, [account, profileSettingsService]);

  const timeZones = useMemo(() => profileSettingsService.timeZones, [profileSettingsService.timeZones]);

  const states = useMemo(() => account.stateOptions, [account.stateOptions]);

  const countries = useMemo(() => account.countryOptions, [account.countryOptions]);

  useEffect(() => {
    reset({
      firstName: account.info?.firstName,
      lastName: account.info?.lastName,
      dateOfBirth: !!account.info?.dateOfBirth ? formatDate(new Date(account.info?.dateOfBirth), DATE_API_FORMAT) : '',
      phone: account.info?.phone,
      alternatePhone: account.info?.alternatePhone ?? '',
      country: account.info?.country,
      zipCode: account.info?.zipCode ?? '',
      state: account.info?.state ?? '',
      city: account.info?.city ?? '',
      address: account.info?.address ?? '',
      timeZone: profileSettingsService.getCurrentTimeZone(),
      language: account.info?.language?.id ?? DEFAULT_LANGUAGE_ID,
      otherLanguage: account.info?.language?.text ?? '',
      isNeedInterpreter:
        account.info?.language?.id !== DEFAULT_LANGUAGE_ID ? !!account.info?.language?.isNeedInterpreter : false,
      userName: account.info?.userName,
    });
  }, [
    account.info?.address,
    account.info?.alternatePhone,
    account.info?.city,
    account.info?.country,
    account.info?.dateOfBirth,
    account.info?.firstName,
    account.info?.language?.id,
    account.info?.language?.isNeedInterpreter,
    account.info?.language?.text,
    account.info?.lastName,
    account.info?.phone,
    account.info?.state,
    account.info?.userName,
    account.info?.zipCode,
    profileSettingsService,
    profileSettingsService.currentTimeZone,
    reset,
    timeZones,
  ]);

  const onSubmit = useCallback(
    async (data: AccountSetupFormDto): Promise<void> => {
      const alternatePhone = data.alternatePhone ? getClearedPhoneValue(data.alternatePhone) : null;
      const basicInfoData: AdditionalInfoModel = {
        alternatePhone: alternatePhone?.length === TEL_LENGTH ? alternatePhone : null,
        dateOfBirth: data.dateOfBirth,
        email: account.info?.email ?? '',
        firstName: data.firstName,
        lastName: data.lastName,
        phone: getClearedPhoneValue(data.phone ?? '') ?? '',
        sex: !!account.info?.sex ? account.info.sex : 0,
        country: data.country,
        zipCode: data.zipCode,
        state: data.state,
        city: data.city,
        address: data.address,
        language: data.language ?? 1,
        otherLanguage: data.otherLanguage ?? '',
        isNeedInterpreter: data.isNeedInterpreter,
        userName: account.info?.userName ?? '',
        verificationCode: null,
      };

      await account.updatePersonalInfo(basicInfoData, false);

      await profileSettingsService.sendSettings({
        receiveSms: profileSettingsService.areSmsReceived,
        receivePush: profileSettingsService.arePushReceived,
        timeZone: data.timeZone,
        notificationType: profileSettingsService.notificationType,
        verificationCode: '',
      });
    },
    [account, profileSettingsService],
  );

  const isEnglishSelected = useMemo(
    () => isEnglishLanguageSelected(account.languageOptions, watch('language')),
    [account.languageOptions, watch],
  );

  const isOtherSelected = useMemo(
    () => isOtherLanguageSelected(account.languageOptions, watch('language')),
    [account.languageOptions, watch],
  );

  const isLoading = useMemo(
    () => profileSettingsService.isLoading || account.isLoading,
    [account.isLoading, profileSettingsService.isLoading],
  );

  const getStates = useCallback(
    async (countryId: number) => {
      await account.loadStates(countryId);
    },
    [account],
  );

  const validateZipCode = useMemo(
    () => ({
      value: watch('country') === DEFAULT_COUNTRY_ID ? Patterns.ZipCodeUSA : Patterns.ZipCodeCanada,
      message: 'Please enter a valid ZIP code',
    }),
    [watch],
  );

  const maskZipCode = useMemo(
    () => (watch('country') === DEFAULT_COUNTRY_ID ? Patterns.ZipCodeMaskUSA : Patterns.ZipCodeMaskCanada),
    [watch],
  );

  return (
    <OnBoardingLayout>
      <div className={styles.root}>
        <ProgressBar step={1} totalSteps={account.onboardingStepsCount} title={'Account Setup'} />
        {account.isSaving && <Preloader isSpinnerShown={false} />}
        {isLoading && <PageLoader />}
        {!isLoading && (
          <>
            <h1 className={styles.title}>{`Let’s get started. Please verify your Trellus Health account details`}</h1>
            <form autoComplete={DISABLE_AUTOFILL} className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Controller
                  name="firstName"
                  rules={{
                    required: REQUIRED_FIELD_ERROR,
                  }}
                  control={control}
                  render={({value, onChange, name}) => (
                    <Input
                      name={name}
                      value={value}
                      className={classNames('input-simple', styles.input)}
                      onValueChanged={onChange}
                      placeholder={'First Name *'}
                      label={'First Name *'}
                      isValid={!formState.errors.firstName}
                      type={InputType.LabelInInput}
                    />
                  )}
                />
              </div>
              {formState.errors.firstName && formState.errors.firstName.message ? (
                <ErrorListItem
                  message={formState.errors.firstName.message}
                  icon={<AlertCircleIcon />}
                  color={ErrorListItemColor.Red}
                  fontSize={ErrorListItemFontSize.Small}
                />
              ) : (
                <></>
              )}
              <div>
                <Controller
                  name="lastName"
                  rules={{
                    required: REQUIRED_FIELD_ERROR,
                  }}
                  control={control}
                  render={({value, onChange, name}) => (
                    <Input
                      name={name}
                      value={value}
                      className={classNames('input-simple', styles.input)}
                      onValueChanged={onChange}
                      placeholder={'Last Name *'}
                      label={value ? 'Last Name *' : undefined}
                      isValid={!formState.errors.lastName}
                      type={InputType.LabelInInput}
                    />
                  )}
                />
              </div>
              {formState.errors.lastName && formState.errors.lastName.message ? (
                <ErrorListItem
                  message={formState.errors.lastName.message}
                  icon={<AlertCircleIcon />}
                  color={ErrorListItemColor.Red}
                  fontSize={ErrorListItemFontSize.Small}
                />
              ) : (
                <></>
              )}
              <div>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  rules={{
                    required: REQUIRED_FIELD_ERROR,
                  }}
                  render={({onChange, value, name}) => (
                    <div className={styles.datePicker}>
                      <DatePicker
                        name={name}
                        value={value && parseISO(value)}
                        onChange={(e) => onChange(formatISO(e, {representation: 'date'}))}
                        label={'Date of Birth *'}
                        inputType={DatePickerInputType.Wide}
                        max={new Date()}
                        view={DatePickerView.Selector}
                      />
                    </div>
                  )}
                />
              </div>
              {formState.errors.dateOfBirth && formState.errors.dateOfBirth.message ? (
                <ErrorListItem
                  message={formState.errors.dateOfBirth.message}
                  icon={<AlertCircleIcon />}
                  color={ErrorListItemColor.Red}
                  fontSize={ErrorListItemFontSize.Small}
                />
              ) : (
                <></>
              )}
              <div>
                <Controller
                  name={'phone'}
                  control={control}
                  rules={{
                    required: REQUIRED_FIELD_ERROR,
                    pattern: {
                      value: Patterns.PhoneFormat,
                      message: 'Please enter a valid phone number',
                    },
                  }}
                  render={({name, value, onChange}) => (
                    <MaskedInput
                      name={name}
                      mask={Patterns.PhoneMask}
                      label={value && 'Primary Phone *'}
                      placeholder="Primary Phone *"
                      className={classNames(['masked-simple', styles.input])}
                      value={value ? (value.startsWith('+1') ? value : `+1-${value}`) : undefined}
                      onValueChanged={onChange}
                      isValid={!formState.errors.phone}
                      type={MaskedInputType.LabelInInput}
                    />
                  )}
                />
              </div>
              {formState.errors.phone && formState.errors.phone.message ? (
                <ErrorListItem
                  message={formState.errors.phone.message}
                  icon={<AlertCircleIcon />}
                  color={ErrorListItemColor.Red}
                  fontSize={ErrorListItemFontSize.Small}
                />
              ) : (
                <></>
              )}
              <div>
                <Controller
                  name="alternatePhone"
                  control={control}
                  rules={{
                    pattern: {
                      value: Patterns.PhoneFormat,
                      message: 'Please enter a valid phone number',
                    },
                  }}
                  render={({name, value, onChange}) => (
                    <MaskedInput
                      name={name}
                      mask={Patterns.PhoneMask}
                      label={value && 'Alternate Phone'}
                      placeholder="Alternate Phone"
                      className={classNames(['masked-simple', styles.input])}
                      onValueChanged={onChange}
                      value={value ? (value?.startsWith('+1') ? value : `+1-${value}`) : undefined}
                      isValid={!formState.errors.alternatePhone}
                      type={MaskedInputType.LabelInInput}
                    />
                  )}
                />
              </div>
              {formState.errors.alternatePhone?.message && (
                <ErrorListItem
                  message={formState.errors.alternatePhone.message}
                  icon={<AlertCircleIcon />}
                  color={ErrorListItemColor.Red}
                  fontSize={ErrorListItemFontSize.Small}
                />
              )}
              <div>
                <Controller
                  name={'country'}
                  control={control}
                  rules={{required: REQUIRED_FIELD_ERROR}}
                  render={({name, value, onChange}) => {
                    const selectedValue = countries.find((option) => option.value === value);

                    const onChangeValue = (selectedItem: IOption<string | number>) => {
                      onChange(selectedItem.value);
                      setValue('state', '');
                      setValue('zipCode', '');
                      getStates(Number(selectedItem.value));
                    };

                    return (
                      <div className={styles.dropdownBlock}>
                        <label className={styles.label}>Country *</label>
                        <SingleDropdown
                          name={name}
                          className={styles.dropdownCountry}
                          options={countries}
                          value={selectedValue}
                          onChange={onChangeValue}
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                <Controller
                  name={'zipCode'}
                  control={control}
                  rules={{
                    required: REQUIRED_FIELD_ERROR,
                    pattern: validateZipCode,
                  }}
                  render={({value, onChange, name}) => (
                    <MaskedInput
                      name={name}
                      mask={maskZipCode}
                      label={value ? 'ZIP Code/Postal Code *' : undefined}
                      placeholder={'ZIP Code/Postal Code *'}
                      className={classNames(styles.zipCodeInput, 'masked-simple', styles.input)}
                      value={value}
                      onValueChanged={onChange}
                      isValid={!formState.errors.zipCode}
                      type={MaskedInputType.LabelInInput}
                    />
                  )}
                />
              </div>
              {formState.errors.zipCode && formState.errors.zipCode.message ? (
                <ErrorListItem
                  message={formState.errors.zipCode.message}
                  icon={<AlertCircleIcon />}
                  color={ErrorListItemColor.Red}
                  fontSize={ErrorListItemFontSize.Small}
                />
              ) : (
                <></>
              )}
              <div>
                <Controller
                  name={'state'}
                  control={control}
                  render={({name, value, onChange}) => {
                    const selectedValue = states?.find((option) => option.value === value);

                    const label = watch('country') === 1 ? 'State' : 'Province';

                    return (
                      <div className={styles.dropdownBlock}>
                        <label className={styles.label}>{label}</label>
                        <SingleDropdown
                          name={name}
                          className={styles.dropdownCountry}
                          options={states}
                          value={selectedValue}
                          onChange={(selectedItem) => onChange(selectedItem.value)}
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                <Controller
                  name="city"
                  control={control}
                  render={({value, onChange, name}) => (
                    <Input
                      name={name}
                      value={value}
                      className={classNames('input-simple', styles.input)}
                      onValueChanged={onChange}
                      placeholder={'City'}
                      label={value ? 'City' : undefined}
                      type={InputType.LabelInInput}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="address"
                  rules={{
                    required: REQUIRED_FIELD_ERROR,
                  }}
                  control={control}
                  render={({name, value, onChange}) => (
                    <Input
                      value={value}
                      className={classNames(['input-simple', styles.input])}
                      name={name}
                      onValueChanged={onChange}
                      label={value ? 'Address *' : undefined}
                      placeholder={'Address *'}
                      isValid={!formState.errors.address}
                      type={InputType.LabelInInput}
                    />
                  )}
                />
              </div>
              {formState.errors.address && formState.errors.address.message ? (
                <ErrorListItem
                  message={formState.errors.address.message}
                  icon={<AlertCircleIcon />}
                  color={ErrorListItemColor.Red}
                  fontSize={ErrorListItemFontSize.Small}
                />
              ) : (
                <></>
              )}

              <div>
                <Controller
                  name={'timeZone'}
                  control={control}
                  rules={{required: REQUIRED_FIELD_ERROR}}
                  render={({name, value, onChange}) => (
                    <div className={styles.dropdownBlock}>
                      <label className={styles.label}>Timezone</label>
                      <SearchDropdown
                        name={name}
                        options={timeZones}
                        currentOption={value}
                        placeholder={'Search here...'}
                        setOption={onChange}
                        maxOptionsToDisplay={140}
                      />
                    </div>
                  )}
                />
              </div>
              <div>
                <Controller
                  name={'language'}
                  control={control}
                  rules={{required: REQUIRED_FIELD_ERROR}}
                  render={({name, value, onChange}) => {
                    const selectedValue = account.languageOptions.length
                      ? account.languageOptions.find((option) => String(option.value) === String(value))
                      : undefined;

                    return (
                      <div className={styles.dropdownBlock}>
                        <label className={styles.label}>Language</label>
                        <SearchDropdown
                          name={name}
                          options={account.languageOptions}
                          currentOption={selectedValue}
                          placeholder={'Search here...'}
                          setOption={(langOption) => onChange(langOption.value)}
                          maxOptionsToDisplay={20}
                        />
                      </div>
                    );
                  }}
                />
              </div>
              {isOtherSelected && (
                <div className={styles.otherLanguage}>
                  <Controller
                    name="otherLanguage"
                    rules={{
                      required: REQUIRED_FIELD_ERROR,
                    }}
                    control={control}
                    render={({name, value, onChange}) => (
                      <Input
                        value={value}
                        className={classNames(['input-simple', styles.input])}
                        name={name}
                        onValueChanged={onChange}
                        label={value ? 'Type a language name' : undefined}
                        placeholder={'Type a language name'}
                        isValid={!formState.errors.otherLanguage}
                        type={InputType.LabelInInput}
                      />
                    )}
                  />
                </div>
              )}
              {formState.errors.otherLanguage && formState.errors.otherLanguage.message ? (
                <ErrorListItem
                  message={formState.errors.otherLanguage.message}
                  icon={<AlertCircleIcon />}
                  color={ErrorListItemColor.Red}
                  fontSize={ErrorListItemFontSize.Small}
                />
              ) : (
                <></>
              )}
              {!isEnglishSelected && (
                <div className={styles.interpreter}>
                  <p className={styles.interpreterLabel}>Do you need an interpreter?</p>
                  <Controller
                    control={control}
                    name={'isNeedInterpreter'}
                    render={({value, name, onChange}) => (
                      <>
                        {RADIO_OPTIONS.map((option) => (
                          <div className={styles.radioBtn} key={option.id}>
                            <Radio
                              key={option.id}
                              id={option.id}
                              name={name}
                              label={option.value ? 'Yes' : 'No'}
                              value={option.value === value}
                              onChange={() => onChange(option.value)}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>
              )}
              <div>
                <Controller
                  name={'terms'}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={false}
                  render={({name, value, onChange}) => (
                    <div className={styles.checkboxContainer}>
                      <Checkbox id={name} value={value} name={name} label={''} onChange={onChange} />
                      <div className={styles.checkboxLabel}>
                        I accept Trellus Health's{' '}
                        <a
                          className={styles.checkboxLink}
                          href={acceptLinks[0].href}
                          target="_blank"
                          rel="noreferrer noopener">
                          {acceptLinks[0].title}
                        </a>
                        ,{' '}
                        <a
                          className={styles.checkboxLink}
                          href={acceptLinks[1].href}
                          target="_blank"
                          rel="noreferrer noopener">
                          {acceptLinks[1].title}
                        </a>{' '}
                        and{' '}
                        <a
                          className={styles.checkboxLink}
                          href={acceptLinks[2].href}
                          target="_blank"
                          rel="noreferrer noopener">
                          {acceptLinks[2].title}
                        </a>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={classNames(styles.submit, {[styles.submitPending]: account.isSaving})}
                  type={'submit'}
                  disabled={!getValues('terms')}
                  isLoading={account.isSaving}>
                  Continue
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </OnBoardingLayout>
  );
};

const AccountSetupScreenObserver = observer(AccountSetupScreen);
const AccountSetupScreenInjected: FC = () => (
  <AccountSetupScreenObserver
    profileSettingsService={useInjection(PROFILE_SETTINGS_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
  />
);
export {AccountSetupScreenInjected as AccountSetupScreen};
