import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {IAccountRepository} from '../repository';
import {ACCOUNT_REPOSITORY} from '../repository';
import type {IAccountService} from './account.service.interface';
import type {ILayoutsService} from '../../layouts/service';
import {LAYOUTS_SERVICE} from '../../layouts/service';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import {routes} from '../../routing';
import type {ILocalStorageService} from '../../utils/local-storage';
import {LOCAL_STORAGE_SERVICE} from '../../utils/local-storage';
import type {INotifierService} from '../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../notifier';
import type {ICompanyService} from '../../company';
import {COMPANY_SERVICE} from '../../company';
import {UserRole} from '../model/user-role.enum';
import type {
  AccountDiseaseModel,
  AccountLanguage,
  AccountModel,
  AccountPaymentModel,
  Country,
  Coupon,
  DiseaseType,
  HealthInsurance,
  PersonalInfo,
  SearchProvider,
  Specialty,
  State,
  TrellusTeamMember,
  InfoDecision,
  MyExternalProvider,
  ExternalProvider,
  SearchInterface,
  UpdateMyExternalProvider,
  NPIProvider,
  IBannerModel,
} from '../model';
import type {IFileStorageService} from '../../file-storage';
import {FILE_STORAGE_SERVICE} from '../../file-storage';
import {modifyTeamTitles, teamTitleAbbreviation} from './helpers';
import type {EmergencyContact} from '../../models';
import {FileStorageModel, FileType} from '../../file-storage/model';
import type {IWssService, SocketData} from '../../utils/wss';
import {WSS_SERVICE} from '../../utils/wss';
import type {TrellusTeamMemberRemoveSocket, TrellusTeamMemberSocket} from '../../models/socket';
import {CLIENT_TYPE_B2B, LOCATION_STORAGE_IS_FIRST_LOGIN_KEY} from '../../helpers';
import type {IOption} from '../../types';
import {isNil} from 'lodash';
import {ProviderSearchStatusEnum, ShareInfoDecisionEnum} from '../../enums';
import {ProfileTabsEnum} from '../../screens/private/profile/profile.enum';
import {PROFILE_VERIFICATION_SERVICE} from '../../profile-verification';
import type {IProfileVerificationService} from '../../profile-verification';
import type {IProfileSettingsRepository} from '../../profile-settings/repository';
import {PROFILE_SETTINGS_REPOSITORY} from '../../profile-settings/repository';
import {THRIVE_SKILL_SERVICE} from '../../thrive-skill/service';
import type {IThriveSkillService} from '../../thrive-skill/service';

const PREREGISTERED_ONBOARDING_STEPS_COUNT = 2;
const FULL_ONBOARDING_STEPS_COUNT = 3;

@injectable()
class AccountService implements IAccountService {
  private _countries: Country[] = [];
  private _states: State[] = [];
  private _selectedCountry: Country | null = null;

  constructor(
    @inject(ACCOUNT_REPOSITORY) private readonly _repo: IAccountRepository,
    @inject(COMPANY_SERVICE) private readonly _company: ICompanyService,
    @inject(FILE_STORAGE_SERVICE) private readonly _fileStorage: IFileStorageService,
    @inject(LAYOUTS_SERVICE) private readonly _layouts: ILayoutsService,
    @inject(LOCAL_STORAGE_SERVICE) private readonly _localStorage: ILocalStorageService,
    @inject(NOTIFIER_SERVICE) private readonly _notifier: INotifierService,
    @inject(PROFILE_SETTINGS_REPOSITORY) private readonly _profileSettings: IProfileSettingsRepository,
    @inject(PROFILE_VERIFICATION_SERVICE) private readonly _profileVerification: IProfileVerificationService,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(WSS_SERVICE) private readonly _wss: IWssService,
    @inject(THRIVE_SKILL_SERVICE) private readonly _thrive: IThriveSkillService,
  ) {
    makeAutoObservable(this);
  }

  private _isSaving: boolean = false;

  get isSaving(): boolean {
    return this._isSaving;
  }

  private _id: number = -1;

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  private _isConfirmed: boolean = false;

  get isConfirmed(): boolean {
    return this._isConfirmed;
  }

  private _isFirstLogin: boolean = false;

  get isFirstLogin(): boolean {
    return this._isFirstLogin;
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _role: UserRole = UserRole.Undefined;

  get role(): UserRole {
    return this._role;
  }

  private _timeZone: string = '';

  get timeZone(): string {
    return this._timeZone;
  }

  private _info: AccountModel | null = null;

  get info(): AccountModel | null {
    return this._info;
  }

  private _avatar: string | null = null;

  get avatar(): string | null {
    return this._avatar;
  }

  private _team: Array<TrellusTeamMember> = [];

  get team(): Array<TrellusTeamMember> {
    return this._team;
  }

  private _diseaseInfo: AccountDiseaseModel | null = null;

  private _bannerInfo: IBannerModel | null = null;

  get bannerInfo(): IBannerModel | null {
    return this._bannerInfo;
  }

  private _bannersInfo: IBannerModel[] | null = null;

  get bannersInfo(): IBannerModel[] | null {
    return this._bannersInfo;
  }

  get diseaseInfo(): AccountDiseaseModel | null {
    return this._diseaseInfo;
  }

  private _paymentInfo: AccountPaymentModel | null = null;

  get paymentInfo(): AccountPaymentModel | null {
    return this._paymentInfo;
  }

  private _promocode: string | null = null;

  get promocode(): string | null {
    return this._promocode;
  }

  private _errors = new Map();

  get errors() {
    return this._errors;
  }

  private _languages: AccountLanguage[] = [];

  get languages(): AccountLanguage[] {
    return this._languages;
  }

  private _diseaseTypes: Array<DiseaseType> = [];

  get diseaseTypes(): Array<DiseaseType> {
    return this._diseaseTypes;
  }

  private _providers: Array<SearchProvider> = [];

  get providers(): Array<SearchProvider> {
    return this._providers;
  }

  private _specialties: Array<Specialty> = [];

  get specialties(): Array<Specialty> {
    return this._specialties;
  }

  private _areDiseaseTypesLoading: boolean = false;

  get areDiseaseTypesLoading(): boolean {
    return this._areDiseaseTypesLoading;
  }

  get giProviders(): TrellusTeamMember[] {
    return this._team?.filter((i) => i.isGiProvider) ?? [];
  }

  get countryOptions(): IOption[] {
    return (
      this._countries?.map((country) => ({
        value: country.id,
        displayName: country.name,
      })) ?? []
    );
  }

  get stateOptions(): IOption<string>[] {
    return (
      this._states.map((state) => ({
        value: state.id,
        displayName: state.name,
      })) ?? []
    );
  }

  get languageOptions(): IOption[] {
    return (
      this._languages.map((lang) => ({
        value: lang.id,
        displayName: lang.name,
      })) ?? []
    );
  }

  get isPreRegistered(): boolean {
    return !isNil(this._info?.disease);
  }

  get onboardingStepsCount(): number {
    return this.isPreRegistered ? PREREGISTERED_ONBOARDING_STEPS_COUNT : FULL_ONBOARDING_STEPS_COUNT;
  }

  private _completeOnboardingTaskURL: string = '';

  get completeOnBoardingTaskUrl(): string {
    return this._completeOnboardingTaskURL;
  }

  set completeOnBoardingTaskUrl(value: string) {
    this._completeOnboardingTaskURL = value;
  }

  static isCareTeamMemberAddedSocketData(data: SocketData): data is TrellusTeamMemberSocket {
    return (
      !!(data as TrellusTeamMemberSocket)?.firstName &&
      !!(data as TrellusTeamMemberSocket)?.lastName &&
      !!(data as TrellusTeamMemberSocket)?.providerTitleId &&
      !!(data as TrellusTeamMemberSocket)?.providerTypeId
    );
  }

  static isCareTeamMemberRemovedSocketData(data: SocketData): data is TrellusTeamMemberRemoveSocket {
    return !!(data as TrellusTeamMemberRemoveSocket)?.providerId && Object.getOwnPropertyNames(data).length === 1;
  }

  async setWelcomeToThriveWatched(): Promise<void> {
    this._info!.showWelcomeToThrive = false;

    await this._thrive.setWelcomeToThriveWatched();
  }

  async completeOnboarding(): Promise<void> {
    this._isLoading = true;
    this._localStorage.set(LOCATION_STORAGE_IS_FIRST_LOGIN_KEY, 'true');
    this._isFirstLogin = true;

    try {
      const {data} = await this._repo.completeOnboarding();
      this._completeOnboardingTaskURL = data;
      await this.load();
      this._isConfirmed = true;
    } catch (e) {
      console.error(e);
    } finally {
      this._isLoading = false;
    }
  }

  async load(): Promise<void> {
    if (!this._isSaving) {
      this._isLoading = true;
    }

    const result = await this._repo.loadBaseInfo().catch(console.error);

    if (result && result.roleId !== UserRole.Patient && this._company.companyInfo.contactEmail) {
      this._notifier.notify({
        text:
          `Unrecognized login. This system is available for Trellus Health patients only.\n` +
          `If you are a patient and seeing this message, please reach out to our support team at ${this._company.companyInfo.contactEmail}`,
        type: NotifyMessageTypeEnum.Danger,
      });

      return;
    }

    if (result && result.roleId === UserRole.Patient) {
      this.id = result.id;
      this._role = result.roleId;
      this._isConfirmed = result.isConfirmed;
      this._isFirstLogin = this._localStorage.get(LOCATION_STORAGE_IS_FIRST_LOGIN_KEY) === 'true';

      await this.get(result.id);
    }

    this._isLoading = false;
  }

  async get(id: number): Promise<void> {
    const patientModel = await this._repo.find({id}).catch(console.error);
    if (patientModel) {
      this._info = {...patientModel};

      this._timeZone = patientModel.timeZone;

      if (!!this._info?.objectFileId) {
        const avatarData = await this._fileStorage.get(this._info.objectFileId).catch(console.error);

        this._avatar = !!avatarData ? avatarData.content : null;
      }
    }
  }

  async loadTrellusTeam(): Promise<void> {
    const team = await this._repo.loadTeam({id: this._id});

    if (team) {
      for (const teamMember of team) {
        if (!!teamMember.objectFileId) {
          const avatarData = await this._fileStorage.get(teamMember.objectFileId).catch(console.error);
          teamMember.avatar = !!avatarData ? avatarData.content : null;
        }
      }
    }

    this._team = modifyTeamTitles(team);
  }

  async getDisease(): Promise<void> {
    this._isLoading = true;
    this._diseaseInfo = (await this._repo.loadDisease().catch(console.error)) ?? null;
    this._isLoading = false;
  }

  async getBanners(): Promise<void> {
    this._isLoading = true;
    this._bannersInfo = (await this._repo.loadBanners().catch(console.error)) ?? null;
    this._isLoading = false;
  }

  async setBanner(bannerId: number): Promise<void> {
    this._isSaving = true;

    await this._repo.submitBanner(bannerId);

    if (this._bannersInfo) {
      this._bannersInfo = [...this._bannersInfo?.filter((item) => item.bannerId !== bannerId)];
    }

    this._isSaving = false;
  }

  async setDisease(disease: AccountDiseaseModel): Promise<void> {
    this._isSaving = true;

    const model = {
      ...this._diseaseInfo,
      ...disease,
    };

    await this._repo.saveDisease(model);

    this._diseaseInfo = model;

    this._isSaving = false;

    await this.checkUserCoupon();
  }

  async setPaymentInformation(paymentInformation: AccountPaymentModel): Promise<void> {
    this._isSaving = true;

    const model = {
      ...this._paymentInfo,
      ...paymentInformation,
    };

    this._paymentInfo = model;

    this._isSaving = false;
  }

  async updatePersonalInfo(info: PersonalInfo, isNavigationDisabled: boolean): Promise<boolean> {
    let isPersonalInfoUpdateSuccessful: boolean = false;

    this._isSaving = true;
    this._profileVerification.loadStart();

    if (this._info) {
      this._info = {
        ...this._info,
        firstName: info.firstName.trim(),
        lastName: info.lastName.trim(),
        phone: info.phone,
        alternatePhone: info.alternatePhone,
        dateOfBirth: info.dateOfBirth,
        country: info.country,
        zipCode: info.zipCode.toUpperCase(),
        state: info.state,
        city: info.city,
        address: info.address,
        verificationCode: info.verificationCode,
        language: {
          id: info.language,
          name: this.languages?.find((lang) => lang.id === info.language)?.name ?? '',
          isNeedInterpreter: info.isNeedInterpreter ?? false,
          text: info.otherLanguage,
        },
      };

      if (info.sex !== undefined && info.sex !== null) {
        this._info = {...this._info, sex: info.sex};
      }

      await this._repo
        .save(this._info)
        .then(() => {
          this._profileVerification.setSuccess();
          isPersonalInfoUpdateSuccessful = true;
        })
        .catch((e) => {
          console.error(e);
          this._profileVerification.setError(e);
          isPersonalInfoUpdateSuccessful = false;
        });

      this._isSaving = false;

      this._isLoading = true;
      await this.load();
      this._isLoading = false;

      await this._layouts.loadNavigationAccess();

      !isNavigationDisabled &&
        this._routing.push(this.isPreRegistered ? routes.paymentInformation : routes.diseaseInformation);
    }

    return isPersonalInfoUpdateSuccessful;
  }

  async loadCountries(): Promise<void> {
    this._countries = (await this._repo.loadCountries().catch(console.error)) ?? [];

    if (!this._selectedCountry) {
      this._selectedCountry = this._countries[0];
    }

    const countryId = this._info?.country ?? this._selectedCountry?.id;

    await this.loadStates(countryId);
  }

  async loadStates(countryId: number): Promise<void> {
    this._states = (await this._repo.loadStates(countryId).catch(console.error)) ?? [];
  }

  async loadLanguages(): Promise<void> {
    this._languages = (await this._repo.loadLanguages().catch(console.error)) ?? [];
  }

  async updateEmergencyContact(emergencyContact: EmergencyContact): Promise<void> {
    this._isSaving = true;
    this._profileVerification.loadStart();

    if (this._info) {
      const updatedEmergencyContact = {...this._info.emergencyContact, ...emergencyContact};
      this._info = {
        ...this._info,
        emergencyContact: updatedEmergencyContact,
        verificationCode: emergencyContact.verificationCode,
      };
      await this._repo
        .save(this._info)
        .then(() => {
          this._profileVerification.setSuccess();
        })
        .catch((error) => {
          this._profileVerification.setError(error);
          console.error(error);
        });
    }

    this._isSaving = false;

    this._isLoading = true;
    await this.load();
    this._isLoading = false;
  }

  async updateHealthInsurance(healthInsurance: HealthInsurance): Promise<void> {
    this._isSaving = true;
    this._profileVerification.loadStart();

    if (this._info) {
      this._info = {...this._info, ...healthInsurance};

      await this._repo
        .save(this._info)
        .then(() => {
          this._profileVerification.setSuccess();
        })
        .catch((error) => {
          this._profileVerification.setError(error);
          console.error(error);
        });
    }

    this._isSaving = false;

    this._isLoading = true;
    await this.load();
    this._isLoading = false;
  }

  async removeAvatar(): Promise<void> {
    this._isSaving = true;

    if (this._info) {
      this._info = {...this._info, objectFileId: null};

      await this._repo.save(this._info).catch(console.error);
    }

    this._isSaving = false;

    this._avatar = null;
  }

  async uploadAvatar(fileContent: string, mimeType: string, fileName: string): Promise<void> {
    this._avatar = null;

    const id = await this._fileStorage
      .upload(new FileStorageModel(fileContent, fileName, mimeType, undefined, FileType.AvatarImage))
      .catch((e) => {
        this._notifier.notify({text: 'Error occurred with avatar uploading', type: NotifyMessageTypeEnum.Danger});
        console.error(e);
      });

    if (id) {
      this._avatar = id;
      if (this._info) {
        this._info = {...this._info, objectFileId: id};
      }
    }
  }

  connectWss(): void {
    this._wss.subscribe('CareTeamMemberAdded', async (data) => {
      if (AccountService.isCareTeamMemberAddedSocketData(data)) {
        await this.addTrellusTeamMember(data);
      }
    });

    this._wss.subscribe('CareTeamMemberRemoved', (data) => {
      if (AccountService.isCareTeamMemberRemovedSocketData(data)) {
        this.removeTrellusTeamMember(data);
      }
    });
  }

  async getProviders(query: string = ''): Promise<void> {
    this._providers = (await this._repo.loadFilteredProviders({query}).catch(console.error)) ?? [];
  }

  async loadDiseaseTypesBySpecialty(specialtyId: number): Promise<void> {
    this._areDiseaseTypesLoading = true;
    this._diseaseTypes = (await this._repo.loadDiseaseTypes({specialtyId}).catch(console.error)) ?? [];
    this._areDiseaseTypesLoading = false;
  }

  async loadSpecialties(): Promise<void> {
    this._specialties = (await this._repo.loadSpecialties().catch(console.error)) ?? [];
  }

  private async addTrellusTeamMember(member: TrellusTeamMemberSocket): Promise<void> {
    const newTrellusTeamMember: TrellusTeamMember = {
      providerId: member.providerId,
      firstName: member.firstName,
      lastName: member.lastName,
      providerTitle: teamTitleAbbreviation.get(member.providerTitle) ?? member.providerTitle,
      providerType: member.providerType,
      providerTypeId: member.providerTypeId,
      objectFileId: member.objectFileId,
      isTriadMember: member.isTriadMember,
      isGiProvider: member.isGiProvider,
      providerStatement: member.providerStatement,
      emergencyAppointmentProtocol: member.emergencyAppointmentProtocol,
      officePhone: member.officePhone,
      userRoleId: member.userRoleId,
      avatar: null,
      openForCommunicationWithPatient: true,
    };

    if (!!newTrellusTeamMember.objectFileId) {
      const avatarData = await this._fileStorage.get(newTrellusTeamMember.objectFileId).catch(console.error);
      newTrellusTeamMember.avatar = !!avatarData ? avatarData.content : null;
    }

    if (!this._team) {
      this._team = [];
    }

    this._team = [...this._team, newTrellusTeamMember];
  }

  private removeTrellusTeamMember(member: TrellusTeamMemberRemoveSocket): void {
    const index = this._team.findIndex((i) => i.providerId === member?.providerId);

    if (index !== -1) {
      this._team = [...this._team.slice(0, index), ...this._team.slice(index + 1)];
    }
  }

  private async couponValidator(coupon: Coupon | void): Promise<boolean> {
    if (coupon) {
      this._paymentInfo = {
        promocodeId: coupon.promotionCodeId,
      };

      if (
        this._info?.clientType === CLIENT_TYPE_B2B &&
        coupon.valid &&
        coupon.percentOff === 100 &&
        coupon.duration === 'forever'
      ) {
        await this.completeStripeSetup();

        return true;
      }
    }

    return false;
  }

  async checkUserCoupon() {
    const data = await this._repo.checkUserCoupon();

    if (data.promoCode) {
      this._promocode = data.promoCode;
    }

    const couponResult = await this.couponValidator(data.coupon);

    if (couponResult) {
      return;
    }

    this._routing.push(routes.paymentInformation);
  }

  async checkUserPromocode() {
    this._isLoading = true;
    const data = await this._repo.checkUserCoupon();

    if (data.promoCode) {
      this._promocode = data.promoCode;
    }

    if (data.coupon) {
      this._paymentInfo = {
        promocodeId: data.coupon.promotionCodeId,
      };

      if (
        this._info?.clientType === CLIENT_TYPE_B2B &&
        data.coupon.percentOff === 100 &&
        data.coupon.duration === 'forever' &&
        data.coupon.valid
      ) {
        await this.validateCoupon(data.promoCode);
      }
    }

    this._isLoading = false;
  }

  async validateCoupon(coupon: string) {
    this._isSaving = true;
    this._errors = new Map(this._errors);
    this._errors.delete('promocode');

    const promocode = await this._repo.validateCoupon(coupon).catch((error) => {
      this._errors = new Map(this._errors);
      this._errors.set('promocode', 'Invalid promocode entered');
    });

    await this.couponValidator(promocode);
    this._isSaving = false;
  }

  async completeStripeSetup() {
    this._isSaving = true;
    await this._repo.completeStripeSetUp(this._paymentInfo!);
    await this.completeOnboarding();
    await this._layouts.loadNavigationAccess();
    await this._getInfoDecision();
    this._routing.push(routes.home);
    this._isSaving = false;
  }

  private _infoDecision: InfoDecision | null = null;

  private async _getInfoDecision() {
    this._infoDecision = await this._repo.getInfoDecision();
  }

  public showNotif() {
    if (this._infoDecision?.providerSearchStatus === ProviderSearchStatusEnum.Unresolved) {
      this._notifier.notify(
        {
          text: `We’ve connected with your provider! Please go to “My Providers” to confirm.`,
          type: NotifyMessageTypeEnum.Danger,
          callback: async () => {
            this._routing.push(`${routes.home}${routes.profile}?tabNumber=${ProfileTabsEnum.Providers}`);
            await this._repo.updateProvidersMismatch();
            this.getInfoDecision();
            this._notifier.clear();
          },
          callbackText: `Go To My Providers Page`,
        },
        600000, // 10 min
      );
    }
  }

  async getInfoDecision() {
    this._isLoading = true;
    await this._getInfoDecision();
    this._isLoading = false;
  }

  async updateInfoDecision(status: ShareInfoDecisionEnum) {
    await this._repo.updateInfoDecision(status);
    await this.getInfoDecision();
  }
  get isShowProviderTitle() {
    return this._infoDecision !== null
      ? !(
          this._infoDecision?.sentInvitationToGIProvider ||
          this._infoDecision?.shareInfoDecision === ShareInfoDecisionEnum.Never ||
          this._myExternalProviders.length
        ) && this._infoDecision?.enablePatientToProviderInformationSharing
      : false;
  }

  get isShowProviderModal() {
    return this._infoDecision !== null
      ? !(
          this._infoDecision?.shareInfoDecision === ShareInfoDecisionEnum.Never ||
          this._infoDecision?.shareInfoDecision === ShareInfoDecisionEnum.Agree
        ) && this._infoDecision?.enablePatientToProviderInformationSharing
      : false;
  }

  get isEnabledPatientProviderInfoSharing() {
    return this._infoDecision !== null ? this._infoDecision?.enablePatientToProviderInformationSharing : false;
  }

  _myExternalProviders: MyExternalProvider[] = [];

  get myExternalProviders() {
    return this._myExternalProviders;
  }

  async getMyExternalProviders() {
    this._isLoading = true;
    this._myExternalProviders = (await this._repo.getMyExternalProviders()) ?? [];
    this._isLoading = false;
  }

  async addExternalProvider(model: UpdateMyExternalProvider) {
    this._isLoading = true;
    await this._repo.addExternalProvider(model);
    await this.getMyExternalProviders();
    this._isLoading = false;
  }

  async updateExternalProvider(model: UpdateMyExternalProvider) {
    this._isLoading = true;
    await this._repo.updateExternalProvider(model);
    await this.getMyExternalProviders();
    this._isLoading = false;
  }

  async deleteExternalProvider(id: number) {
    this._isLoading = true;
    await this._repo.deleteExternalProvider(id);
    await this.getMyExternalProviders();
    this._isLoading = false;
  }

  _externalProviderSearchResult: ExternalProvider[] = [];
  _npiProviderSearchResult: NPIProvider[] = [];

  get externalProvidersSearchResult() {
    return this._externalProviderSearchResult;
  }

  async searchForExternalProviders(formData: SearchInterface) {
    this._isLoading = true;
    this._externalProviderSearchResult = (await this._repo.externalProvidersSearch(formData)) ?? [];
    this._isLoading = false;
  }

  get npiProvidersSearchResult() {
    return this._npiProviderSearchResult;
  }

  async searchForNPIProviders(formData: SearchInterface) {
    this._isLoading = true;
    this._npiProviderSearchResult = (await this._repo.npiProvidersSearch(formData)) ?? [];
    this._isLoading = false;
  }

  async inviteExternalProvider(model: SearchInterface) {
    this._isLoading = true;
    await this._repo.inviteExternalProvider(model);
    await this.getMyExternalProviders();
    this.isShowProviderTitle && (await this._getInfoDecision());
    this._isLoading = false;
  }
}

export {AccountService};
