import type {FC} from 'react';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {optionsFromMapHelper} from '../../../helpers';
import {InputNumber, SingleDropdown} from '../../../components';
import styles from './medications-dose-element.module.scss';
import {unitTypesOptions} from '../../model';
import {InputNumberTypes} from '../../../components/input-number/input-number.component';

const MAX_VALUE = 99999.9;

const MedicationsDoseElement: FC = () => {
  const {control} = useFormContext();

  return (
    <div className={styles.root}>
      <Controller
        name={'dose'}
        control={control}
        render={({value, onChange, name}) => (
          <InputNumber
            className={styles.numeric}
            name={name}
            ariaLabelTitle='Dose'
            onChange={onChange}
            value={value || ''}
            maxValue={MAX_VALUE}
            numberInputType={InputNumberTypes.Decimal}
          />
        )}
      />
      <Controller
        name={'unit'}
        control={control}
        render={({name, onChange, value}) => {
          const options = optionsFromMapHelper(unitTypesOptions);
          const currentValue = options.find((option) => option.value === value);

          return (
            <SingleDropdown
              className={styles.unitsDropdown}
              name={name}
              ariaLabelTitle='Dose unit'
              onChange={(option) => onChange(option.value)}
              value={currentValue}
              options={options}
              showInputValueAlways
            />
          );
        }}
      />
    </div>
  );
};

export {MedicationsDoseElement};
