import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {IAccountQuery, IAccountRepository} from './account.repository.interface';
import type {
  AccountBaseInfoModel,
  AccountDiseaseModel,
  AccountLanguage,
  AccountModel,
  AccountPaymentModel,
  Country,
  Coupon,
  DiseaseType,
  SearchProvider,
  Specialty,
  State,
  TrellusTeamMember,
  ExternalProvider,
  SearchInterface,
  InfoDecision,
  MyExternalProvider,
  UpdateMyExternalProvider,
  NPIProvider,
  InviteInterface,
  IBannerModel,
} from '../model';
import {AccountBaseInfoModelFactory, AccountDiseaseModelFactory, AccountModelFactory} from '../model';
import type {IAccountBaseInfoGetRequestDto, IAccountDiseaseGetRequestDto, IAccountGetResponseDto} from './dto';
import {AccountPutRequestDtoFactory} from './dto';
import {ProviderType} from '../../enums';
import type {ShareInfoDecisionEnum} from '../../enums';

@injectable()
class AccountRepository implements IAccountRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(query?: Partial<IAccountQuery>): Promise<AccountModel | null> {
    if (query?.id) {
      const {data: dto} = await this._http.get<IAccountGetResponseDto>(`patient/${query?.id}`);

      return AccountModelFactory.fromGetDto(dto);
    }

    return null;
  }

  async save(model: AccountModel): Promise<void> {
    const dto = AccountPutRequestDtoFactory.fromModel(model);

    await this._http.put('patient', dto);
  }

  async completeOnboarding(): Promise<{data: string}> {
    return this._http.put('patientConfirm/completeInitialSurvey', {});
  }

  async loadBaseInfo(): Promise<AccountBaseInfoModel> {
    const {data: dto} = await this._http.get<IAccountBaseInfoGetRequestDto>('userAccount/getCurrentUserAccountInfo');

    return AccountBaseInfoModelFactory.fromDto(dto);
  }

  async loadTeam(query: Partial<IAccountQuery>): Promise<TrellusTeamMember[]> {
    let team: TrellusTeamMember[] = [];

    if (query?.id) {
      const {data: trellusTeam} = await this._http.get<TrellusTeamMember[]>(`/patientCareTeam/${query.id}`);
      team = trellusTeam;
    }

    return team;
  }

  async loadDisease(): Promise<AccountDiseaseModel | null> {
    const {data: diseaseDto} = await this._http.get<IAccountDiseaseGetRequestDto>(`patient/diseaseInformation`);

    return AccountDiseaseModelFactory.fromDto(diseaseDto);
  }

  async saveDisease(model: AccountDiseaseModel): Promise<void> {
    await this._http.put(`patient/diseaseInformation`, model);
  }

  async submitBanner(bannerId: number): Promise<void> {
    await this._http.post(`/Banner?id=${bannerId}`, null);
  }

  async savePaymentInformation(model: AccountPaymentModel): Promise<void> {
    await this._http.put(`patient/paymentInformation`, model);
  }

  async loadCountries(): Promise<Country[]> {
    const {data: dtos} = await this._http.get<{[key: string]: string}>('location/countries');

    return Object.keys(dtos).map((i) => ({id: Number(i), name: dtos[i]}));
  }

  async loadStates(countryId: number): Promise<State[]> {
    const {data: dtos} = await this._http.get<string[]>(`location/states?country=${countryId}`);

    return dtos.map((i) => ({id: i, name: i}));
  }

  async loadLanguages(): Promise<AccountLanguage[]> {
    const {data: dtos} = await this._http.get<
      {
        languageId: number;
        name: string;
      }[]
    >('patient/languages');

    return dtos.map((dto) => ({id: dto.languageId, name: dto.name}));
  }

  async loadDiseaseTypes(query: Partial<IAccountQuery>): Promise<Array<DiseaseType>> {
    if (query?.specialtyId) {
      const {data: dtos} = await this._http.get<Array<DiseaseType>>(
        `patient/availablediagnoses?specialtyId=${query.specialtyId}`,
      );

      return dtos;
    }

    return [];
  }

  async loadBanners(): Promise<IBannerModel[]> {
    const {data: dtos} = await this._http.get<IBannerModel[]>(`/Banner/getlastthreeactivebanners`);

    return dtos?.reverse();
  }

  async loadBanner(): Promise<IBannerModel> {
    const {data: dtos} = await this._http.get<IBannerModel>(`/Banner`);

    return dtos;
  }

  async loadFilteredProviders(query: Partial<IAccountQuery>): Promise<Array<SearchProvider>> {
    const {data: dtos} = await this._http.post<{records: Array<SearchProvider>}>(`/PatientCareTeam/providers/search`, {
      query: query.query,
      providerType: ProviderType.Physician,
      pageSize: 10000,
      pageNumber: 1,
      isAscending: true,
    });

    return dtos.records;
  }

  async loadSpecialties(): Promise<Array<Specialty>> {
    const {data: dto} = await this._http.get<Array<Specialty>>(`patient/availablespecialties`);

    return dto;
  }

  async checkUserCoupon(): Promise<{coupon: Coupon; promoCode: string}> {
    const {data} = await this._http.get<{coupon: Coupon; promoCode: string}>(`stripe/info`);

    return data;
  }

  async validateCoupon(coupon: string): Promise<Coupon> {
    const {data} = await this._http.get<Coupon>(`stripe/coupon?code=${coupon}`);

    return data;
  }

  async completeStripeSetUp(model: AccountPaymentModel): Promise<void> {
    await this._http.post(`stripe/complete`, model);
  }

  async externalProvidersSearch(model: SearchInterface): Promise<Array<ExternalProvider>> {
    const {data} = await this._http.post<Array<ExternalProvider>>('PatientAccessInfo/searchpatientaccessinfo', model);

    return data;
  }

  async npiProvidersSearch(model: SearchInterface): Promise<Array<NPIProvider>> {
    const {data} = await this._http.get<Array<NPIProvider>>('PatientAccessInfo/searchnpiregistry', {
      params: model,
    });

    return data;
  }

  async getInfoDecision(): Promise<InfoDecision> {
    const {data} = await this._http.get<InfoDecision>('PatientAccessInfo/getaccessinfosettings');

    return data;
  }

  async updateInfoDecision(status: ShareInfoDecisionEnum): Promise<void> {
    await this._http.put('PatientAccessInfo/updategiprovidershasaccess', undefined, {
      params: {shareInfoDecision: status},
    });
  }

  async getMyExternalProviders(): Promise<Array<MyExternalProvider>> {
    const {data} = await this._http.get<Array<MyExternalProvider>>('PatientAccessInfo');

    return data;
  }

  async addExternalProvider(model: UpdateMyExternalProvider) {
    await this._http.post('PatientAccessInfo', model);
  }

  async updateExternalProvider(model: UpdateMyExternalProvider) {
    await this._http.put('PatientAccessInfo', model);
  }

  async deleteExternalProvider(id: number): Promise<void> {
    await this._http.delete('PatientAccessInfo', {params: {providerId: id}});
  }

  async inviteExternalProvider(model: InviteInterface): Promise<void> {
    await this._http.post('PatientAccessInfo/inviteexternalprovider', model);
  }

  async updateProvidersMismatch(): Promise<void> {
    await this._http.put('PatientAccessInfo/resolveprovidersmismatch', {});
  }
}

export {AccountRepository};
