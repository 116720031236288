import type {FC} from 'react';
import React from 'react';
import styles from './search-result.module.scss';
import {Button, List, ListItem, PageLoader} from '../../../../../../components';
import type {
  ExternalProvider,
  InviteInterface,
  NPIProvider,
  ProviderAddress,
  SearchInterface,
} from '../../../../../../account';

interface ISearchResultProps {
  searchResultClickHandler(provider: ExternalProvider): void;
  inviteProviderClickHandler(model: InviteInterface): void;
  providers: ExternalProvider[];
  npiProviders: NPIProvider[];
  searchModel?: SearchInterface;
  isLoading: boolean;
}

const getAddressString = (addresses: ProviderAddress[]): string =>
  addresses
    .map(
      (item) =>
        `${item.zip ?? ''}, ${item.countryCode ?? ''} ${item.state ?? ''}, ${item.city ?? ''} ${item.address ?? ''}, ${
          item.phoneNumber ?? ''
        }`,
    )
    .join('\n');

export const SearchResult: FC<ISearchResultProps> = ({
  searchResultClickHandler,
  providers,
  npiProviders,
  inviteProviderClickHandler,
  searchModel,
  isLoading,
}) => (
  <div className={styles.root}>
    {isLoading ? (
      <div className={styles.loaderContainer} data-testid={'page-loader'}>
        <PageLoader removeMargin />
      </div>
    ) : !!providers.length || !!npiProviders.length ? (
      <>
        {!!providers.length && (
          <>
            <div className={styles.searchFormTitle}>
              The following Providers contain information matching your search criteria
            </div>
            <List noStyle>
              {providers.map((provider) => (
                <ListItem
                  card
                  hoverable
                  title={`${provider.firstName} ${provider.lastName}, ${provider.title}  ${provider.providerState}`}
                  onClick={() => {
                    searchResultClickHandler(provider);
                  }}
                  key={`my-providers--search-list--provider-${provider.providerId}`}
                />
              ))}
            </List>
          </>
        )}
        {!!npiProviders.length && (
          <>
            <div className={styles.searchFormTitle}>The following Providers found in NPI Registry</div>
            <List noStyle>
              {npiProviders.map((npiProvider) => (
                <ListItem
                  card
                  hoverable
                  title={`${npiProvider.firstName} ${npiProvider.lastName}, ${npiProvider.providerTitle ?? ''}  ${
                    npiProvider.addresses?.length ? npiProvider.addresses[0]?.state ?? '' : ''
                  }`}
                  metaText={npiProvider.addresses?.length ? getAddressString(npiProvider.addresses) : undefined}
                  onClick={() => {
                    inviteProviderClickHandler({
                      title: npiProvider.providerTitle,
                      firstName: npiProvider.firstName,
                      lastName: npiProvider.lastName,
                      npiNumber: npiProvider.npiNumber,
                      phone: !!npiProvider.addresses?.length ? npiProvider.addresses[0]?.phoneNumber : undefined,
                      state: !!npiProvider.addresses?.length ? npiProvider.addresses[0]?.state : undefined,
                    });
                  }}
                  key={`my-providers--search-list--provider-${npiProvider.npiNumber}`}
                />
              ))}
            </List>
          </>
        )}
        <Button secondary onClick={() => searchModel && inviteProviderClickHandler(searchModel)}>
          My provider is not on the list
        </Button>
      </>
    ) : (
      <>
        <div className={styles.noResults}>
          <span style={{color: 'red'}}>*</span>Your search returned no matches. Please update the search fields and try
          again or invite your provider.
        </div>
        <Button secondary onClick={() => searchModel && inviteProviderClickHandler(searchModel)}>
          Invite my provider
        </Button>
      </>
    )}
  </div>
);
