import type {IAccountBaseInfoGetRequestDto} from '../repository';
import {AccountBaseInfoModel} from './account-base-info.model';

class AccountBaseInfoModelFactory {
  static fromDto(dto: IAccountBaseInfoGetRequestDto): AccountBaseInfoModel {
    return new AccountBaseInfoModel(
      dto.userAccountId,
      dto.firstName,
      dto.lastName,
      dto.userRoleId,
      dto.userRoleName,
      dto.isConfirmed,
    );
  }
}

export {AccountBaseInfoModelFactory};
