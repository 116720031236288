import {inject, injectable} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IHttpService} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {ThriveSkillDto} from '../dto/thrive-skill.dto';
import {ThriveSkill} from '../model/thrive-skill';
import type {IThriveSkillRepository} from './thrive-skill.repository.interface';

@injectable()
class ThriveSkillRepository implements IThriveSkillRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async getSelected(patientCourseId: number): Promise<number[]> {
    return this._http
      .get<number[]>(`Thrive/getpreviouslyselectedskills?patientCourseId=${patientCourseId}`)
      .then((data) => data.data);
  }
  async list(): Promise<ThriveSkill[]> {
    return this._http
      .get<ThriveSkillDto[]>('Thrive/getskills', {})
      .then((data) => data.data.map((thriveSkillDto) => new ThriveSkill(thriveSkillDto)));
  }
  async find(): Promise<ThriveSkill | null> {
    throw new NotImplementedException();
  }
  async save(): Promise<void> {
    throw new NotImplementedException();
  }
  async remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async updateSkills(skillTypeIds: number[]): Promise<void> {
    await this._http.put(`Thrive/changeskills`, skillTypeIds);
  }

  async setWelcomeToThriveWatched(): Promise<void> {
    await this._http.put(`Thrive/assemblethrivecourses?welcomeToThriveIsWatched=true`, {});
  }
}

export {ThriveSkillRepository};
