import type {FC} from 'react';
import React, {useCallback} from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, Modal} from '../../components';
import styles from './confirm-navigation.module.scss';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';

interface IConfirmNavigationProps {
  message: string;
  onAnswer: (answer: boolean) => void;
}

const ConfirmNavigation: FC<IConfirmNavigationProps> = ({message, onAnswer}) => {
  const stayOnPage = useCallback(() => onAnswer(false), [onAnswer]);
  const leavePage = useCallback(() => onAnswer(true), [onAnswer]);

  return (
    <Modal onClose={stayOnPage}>
      <Card className={styles.card}>
        <CardHeader title={'Confirm Navigation'} closable noBorder onClose={stayOnPage} />
        <CardContent>
          <p className={styles.message}>{message}</p>
          <CardActions>
            <Button onClick={leavePage} flat>
              Leave this page
            </Button>
            <Button onClick={stayOnPage}>Stay on page</Button>
          </CardActions>
        </CardContent>
      </Card>
    </Modal>
  );
};

const ConfirmNavigationWrapper: FC<{routing: IRoutingService}> = observer(({routing}) =>
  routing.isShowConfirmation ? (
    <ConfirmNavigation message={routing.confirmNavigationMessage} onAnswer={routing.confirmationAnswer} />
  ) : (
    <></>
  ),
);

const ConfirmNavigationWrapperInjected: FC = () => <ConfirmNavigationWrapper routing={useInjection(ROUTING_SERVICE)} />;

export {ConfirmNavigationWrapperInjected as ConfirmNavigation};
