const PHONE_LENGTH = 10;
const CODE_LENGTH = 2;

const getClearedPhoneValue = (phoneValue: string): string | null => {
  if (!!phoneValue) {
    const phone = phoneValue.replace(/[-)(_]/g, '');

    return phone.length > PHONE_LENGTH ? phone.slice(CODE_LENGTH) : phone;
  }

  return null;
};

export {getClearedPhoneValue};
