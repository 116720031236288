import type {FC} from 'react';
import React from 'react';
import type {IOption} from '../../../types';
import classNames from 'classnames';
import type {HealthMonitorGraphTypes} from '../../model';
import styles from './health-monitor-navigation-bar.module.scss';

interface IHealthMonitorNavigationBarProps {
  options: Array<IOption<string | number>>;
  graphType: HealthMonitorGraphTypes | string;
  onOptionClick: (option: IOption<string | number>) => void;
  isShifted?: boolean;
}

const HealthMonitorNavigationBar: FC<IHealthMonitorNavigationBarProps> = ({
  options,
  graphType,
  onOptionClick,
  isShifted,
}) => (
  <>
    <div
      className={classNames(styles.switchContainer, {
        [styles.switchContainerShifted]: !!isShifted,
      })}>
      {options.map((option) => (
        <div
          className={classNames(styles.switchOption, {
            [styles.switchOptionActive]: option.value === graphType,
          })}
          key={option.value}
          onClick={() => onOptionClick(option)}
          data-testid={`health_monitor_navigation_bar_item_${option.value}`}>
          {option.displayName}
        </div>
      ))}
    </div>
    <div className={styles.line} />
  </>
);

export {HealthMonitorNavigationBar};
