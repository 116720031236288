import type {FC} from 'react';
import React, {useMemo} from 'react';
import {Modal} from '../modal';
import {Card} from '../card';
import {CardContent} from '../card-content';
import {CardHeader} from '../card-header';
import {Icon, IconType} from '../icon';
import {List} from '../list';
import {ListItem} from '../list-item';
import {CardActions} from '../card-actions';
import {Button} from '../button';
import {Tracking} from './components';
import styles from './pre-visit-task-modal.module.scss';
import {TaskModalCardStatusEnum} from '../../task/components/task-modal-card/task-modal-card.component';
import type {TaskModel} from '../../task';
import {BlackCalendarIcon} from '../../assets/icons';
import {DateWrapper} from '../date';
import classNames from 'classnames';

interface IPreVisitTaskModalProps {
  selectedTask: TaskModel | null;
  title?: string;
  onPreVisitFileDownload: () => void;
  onClose: () => void;
  onComplete: () => void;
  timeZone?: string;
}

interface IPreVisitTask {
  isSymptomTrackingComplete: boolean;
  isWeeklyWellnessReportComplete: boolean;
  isLabResultComplete: boolean;
}

const PreVisitTaskModal: FC<IPreVisitTaskModalProps & IPreVisitTask> = (props) => {
  const {status, statusText} = useMemo(() => {
    let statusText = 'In progress';
    let status = '';
    if (props.selectedTask) {
      const dateDiffInDays: number = props.selectedTask.getDiffInDays(props.timeZone);
      const isDateExpired: boolean = dateDiffInDays > 0;

      if (!props.selectedTask.complete && isDateExpired) {
        statusText = `Incomplete · ${dateDiffInDays} days ago`;
        status = TaskModalCardStatusEnum.Danger;
      } else if (props.selectedTask.complete) {
        statusText = 'Completed';
        status = TaskModalCardStatusEnum.Success;
      }
    }

    return {statusText, status};
  }, [props.selectedTask, props.timeZone]);

  const actions = (
    <CardActions>
      <Button onClick={props.onPreVisitFileDownload} className={styles.create}>
        Create Pre-Visit Form
      </Button>
      <Button flat onClick={props.onClose}>
        cancel
      </Button>
      <Button type={'submit'} onClick={props.onComplete}>
        complete
      </Button>
    </CardActions>
  );

  return (
    <Modal onClose={props.onClose}>
      <Card className={styles.card}>
        <CardHeader title={props.title || ''} closable noBorder onClose={props.onClose} metaText={'Task'} />
        <CardContent>
          <Tracking
            isSymptomTrackingComplete={props.isSymptomTrackingComplete}
            isWeeklyWellnessReportComplete={props.isWeeklyWellnessReportComplete}
            isLabResultComplete={props.isLabResultComplete}
          />
        </CardContent>
        <CardContent>
          <div className={styles.content}>
            <List noStyle>
              <ListItem>
                <div className={styles.listItemTitle}>
                  <BlackCalendarIcon />
                  &nbsp;<span>Date</span>
                </div>
              </ListItem>
              <ListItem>
                <div className={styles.listItemTitle}>
                  {status === TaskModalCardStatusEnum.Success && (
                    <span className={styles.status}>
                      <Icon type={IconType.Checkmark} stroke={'white'} />
                    </span>
                  )}
                  {status === TaskModalCardStatusEnum.Danger && <Icon type={IconType.AlertCircle} />}
                  {status === '' && <Icon type={IconType.Clock} />}
                  &nbsp;
                  <span>Status</span>
                </div>
              </ListItem>
            </List>

            <List noStyle>
              <ListItem>
                {props.selectedTask?.dateStart && <DateWrapper date={props.selectedTask?.dateStart} />}
              </ListItem>
              {props.selectedTask?.category && <ListItem>{props.selectedTask?.category}</ListItem>}
              <ListItem>
                <span
                  className={classNames({
                    [styles.danger]: status === TaskModalCardStatusEnum.Danger,
                    [styles.success]: status === TaskModalCardStatusEnum.Success,
                  })}>
                  {statusText}
                </span>
              </ListItem>
            </List>
          </div>
        </CardContent>
        {actions}
      </Card>
    </Modal>
  );
};

export {PreVisitTaskModal};
export type {IPreVisitTask, IPreVisitTaskModalProps};
