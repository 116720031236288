import type {FC} from 'react';
import React, {useMemo} from 'react';
import styles from './encounters-table-row.module.scss';
import {DEFAULT_FORMAT} from '../../../../../helpers';
import type {EncounterTypeEnum} from '../../../../../encounter';
import {EncounterMethodTypeEnum, EncounterTypeEnumLabelMap} from '../../../../../encounter';
import {providerTitle} from '../../../../../enums/provider-title';
import {format} from 'date-fns';

interface IEncountersTableRowProps {
  isCreatedByPatient: boolean;
  isPossibleViewSummary: boolean;
  date: Date;
  type: EncounterTypeEnum;
  createdByFullName: string;
  method: EncounterMethodTypeEnum;
  onEncounterDetailsView: () => void;
}

const EncountersTableRow: FC<IEncountersTableRowProps> = (props) => {
  const date = useMemo(() => format(props.date, DEFAULT_FORMAT), [props.date]);
  const type = useMemo(() => EncounterTypeEnumLabelMap.get(props.type), [props.type]);
  const createdByFullTitle = useMemo(() => {
    const [, title] = props.createdByFullName.split(', ');

    return title ? providerTitle.get(title) : '';
  }, [props.createdByFullName]);

  const createdByName = useMemo(() => {
    const [name] = props.createdByFullName.split(', ');

    return name ?? props.createdByFullName;
  }, [props.createdByFullName]);

  return (
    <div className={styles.gridRow}>
      <div className={styles.gridRowItem}>{date}</div>
      <div className={styles.gridRowItem}>{type}</div>
      <div className={styles.gridRowItem}>
        {props.isCreatedByPatient ? (
          <div>Me</div>
        ) : (
          <>
            <div>{createdByName}</div>
            <div className={styles.gridAdditionalInfo}>{createdByFullTitle}</div>
          </>
        )}
      </div>
      <div className={styles.gridRowItem}>{props.isCreatedByPatient ? '—' : EncounterMethodTypeEnum[props.method]}</div>
      <div>
        {props.isPossibleViewSummary && (
          <span
            className={styles.viewSummary}
            onClick={(e) => {
              e.stopPropagation();
              props.onEncounterDetailsView();
            }}>
            View Summary
          </span>
        )}
      </div>
    </div>
  );
};

export {EncountersTableRow};
export type {IEncountersTableRowProps};
