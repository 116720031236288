import type {IHealthMonitorPostResponseDto} from '../repository/dto';
import {HealthMonitorModel} from './health-monitor.model';

class HealthMonitorModelFactory {
  static fromPostResponseDto(dto: IHealthMonitorPostResponseDto): HealthMonitorModel {
    return new HealthMonitorModel(
      dto.points.map((point) => ({x: new Date(point.date), y: point.value})),
      dto.lowerBorderLine,
      dto.upperBorderLine,
      dto.absenceMessage,
    );
  }
}

export {HealthMonitorModelFactory};
