import type {CourseDto} from '../dto/course.dto';
import type {FileStorageModel} from '../../file-storage/model';
import {CourseTask} from './course-task';

export class Course {
  patientCourseId: number;
  patientCourseCategoryId: number;
  categoryId: number;
  categoryName: string;
  categoryCompletionDate: Date | null;
  behaviorTypeId: number;
  behaviorTypeName: string;
  behaviorTypeCompletionDate: Date | null;
  behaviorTypeThumbnailObjectFileId: string;
  categoryDefaultThumbnailPath: string;
  completedLessons: number;
  totalLessons: number;
  tasks: CourseTask[];
  completed: boolean;
  courseImage: null | FileStorageModel | string = null;
  hasGoal: boolean;
  canSetGoal: boolean;
  isAutoCreated: boolean;
  canChangeSkills: boolean;

  constructor(courseDto: CourseDto) {
    this.patientCourseId = courseDto.patientCourseId;
    this.patientCourseCategoryId = courseDto.patientCourseCategoryId;
    this.categoryId = courseDto.categoryId;
    this.categoryName = courseDto.categoryName;
    this.behaviorTypeName = courseDto.behaviorTypeName;
    this.behaviorTypeId = courseDto.behaviorTypeId;
    this.categoryDefaultThumbnailPath = courseDto.categoryDefaultThumbnailPath;
    this.completedLessons = courseDto.completedLessons;
    this.totalLessons = courseDto.totalLessons;
    this.behaviorTypeThumbnailObjectFileId = courseDto.behaviorTypeThumbnailObjectFileId;
    this.categoryCompletionDate = courseDto.categoryCompletionDate ? new Date(courseDto.categoryCompletionDate) : null;
    this.behaviorTypeCompletionDate = courseDto.behaviorTypeCompletionDate
      ? new Date(courseDto.behaviorTypeCompletionDate)
      : null;
    this.tasks = courseDto.tasks.map((task) => new CourseTask(task));
    this.completed = !!courseDto.behaviorTypeCompletionDate;
    this.hasGoal = courseDto.hasGoal;
    this.canSetGoal = courseDto.canSetGoal;
    this.isAutoCreated = courseDto.isAutoCreated;
    this.canChangeSkills = courseDto.canChangeSkills;
  }
}
