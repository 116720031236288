import type {IEmotionalImpactScaleFormDto} from '../service';
import type {IEmotionalImpactScaleItem} from './emotional-impact-scale.model';
import {EmotionalImpactScaleModel} from './emotional-impact-scale.model';
import type {IEmotionalImpactScaleGetResponseDto} from '../repository';

class EmotionalImpactScaleModelFactory {
  static fromFormDto(dto: IEmotionalImpactScaleFormDto, patientId: number): EmotionalImpactScaleModel {
    const responses: Array<IEmotionalImpactScaleItem> = Object.keys(dto).reduce(
      (acc, fieldName) => [
        ...acc,
        {
          question: fieldName,
          value: dto[fieldName],
        },
      ],
      [] as Array<IEmotionalImpactScaleItem>,
    );

    return new EmotionalImpactScaleModel(-1, patientId, responses);
  }

  static fromGetResponseDto(dto: IEmotionalImpactScaleGetResponseDto): EmotionalImpactScaleModel {
    return new EmotionalImpactScaleModel(
      dto.id,
      dto.patientId,
      dto.items,
      dto.lastUpdated ? new Date(dto.lastUpdated) : null,
      dto.name,
      dto.date,
    );
  }
}

export {EmotionalImpactScaleModelFactory};
