import type {FC} from 'react';
import React, {useCallback, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {PageTitle} from '../../components';
import classNames from 'classnames';
import {WeeklyWellnessReportSurvey} from '../survey';
import {
  addDays,
  differenceInCalendarWeeks,
  endOfDay,
  format,
  isWithinInterval,
  lastDayOfWeek,
  startOfDay,
  subDays,
} from 'date-fns';
import {ArrowIcon} from '../../assets/icons';
import {useInjection} from '../../ioc';
import {utcToZonedTime} from 'date-fns-tz';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';
import type {ISurveyService} from '../../survey';
import {SurveyType, SURVEY_SERVICE} from '../../survey';
import styles from './weekly-wellness-report-screen.module.scss';
import {shiftToUTCEndOfDay} from '../../helpers';

interface IServiceProps {
  surveyService: ISurveyService;
  account: IAccountService;
}

const WeeklyWellnessReportScreenComponent: FC<IServiceProps> = ({account, surveyService}) => {
  const startOfCurrentDay = useMemo(() => startOfDay(utcToZonedTime(new Date(), account.timeZone)), [account.timeZone]);

  const [selectedDate, setSelectedDate] = useState(startOfCurrentDay);

  const onIncrement = useCallback((days: number) => {
    setSelectedDate((currentSelectedDate) => addDays(currentSelectedDate, days));
  }, []);

  const onDecrement = useCallback((days: number) => {
    setSelectedDate((currentSelectedDate) => subDays(currentSelectedDate, days));
  }, []);

  const lastDayOfWeekDate = useMemo(() => endOfDay(lastDayOfWeek(selectedDate, {weekStartsOn: 4})), [selectedDate]);

  const firstDayOfWeekDate = subDays(lastDayOfWeekDate, 6);

  const onWeekIncrement = useCallback(() => onIncrement(7), [onIncrement]);

  const onWeekDecrement = useCallback(() => onDecrement(7), [onDecrement]);

  const isRightButtonDisabled = useMemo(
    () =>
      differenceInCalendarWeeks(lastDayOfWeek(startOfCurrentDay, {weekStartsOn: 4}), lastDayOfWeekDate, {
        weekStartsOn: 4,
      }) === 0,
    [lastDayOfWeekDate, startOfCurrentDay],
  );

  const registrationDate = useMemo(
    () =>
      account.info?.registrationDate
        ? utcToZonedTime(new Date(account.info?.registrationDate), account.timeZone)
        : null,
    [account.info?.registrationDate, account.timeZone],
  );

  const isLeftButtonDisabled = useMemo(
    () =>
      registrationDate
        ? differenceInCalendarWeeks(lastDayOfWeek(lastDayOfWeekDate, {weekStartsOn: 4}), registrationDate, {
            weekStartsOn: 4,
          }) === 0
        : true,
    [lastDayOfWeekDate, registrationDate],
  );

  const taskDate = useMemo(() => {
    const completedDate =
      surveyService.getSurveyCompletedDate(SurveyType.CombinedGWBHTP, shiftToUTCEndOfDay(lastDayOfWeekDate)) ??
      new Date();
    const inRange = isWithinInterval(completedDate, {
      start: firstDayOfWeekDate,
      end: lastDayOfWeekDate,
    });

    return inRange ? completedDate : firstDayOfWeekDate;
  }, [firstDayOfWeekDate, lastDayOfWeekDate, surveyService]);

  return (
    <>
      <div className={styles.root}>
        <PageTitle className={styles.pageTitle}>Weekly Wellness</PageTitle>
        <>
          <span className={styles.weeklyReportTitle}>Weekly Wellness</span>

          <p className={styles.weeklyReportContent}>
            <button
              className={classNames(styles.weeklyReportButton, styles.weeklyReportButtonLeft)}
              onClick={onWeekDecrement}
              disabled={isLeftButtonDisabled}
              data-testid={'td-right-decrease'}>
              <ArrowIcon />
            </button>
            <span className={styles.weeklyReportDates}>{format(taskDate, 'dd-MMM-yyyy')}</span>
            <button
              className={styles.weeklyReportButton}
              onClick={onWeekIncrement}
              disabled={isRightButtonDisabled}
              data-testid={'td-right-increase'}>
              <ArrowIcon />
            </button>
          </p>
        </>
        {!!account.id && (
          <WeeklyWellnessReportSurvey
            lastOfWeekDate={lastDayOfWeekDate}
            firstOfWeekDate={firstDayOfWeekDate}
            patientId={account.id}
            specialtyType={account.info?.specialty?.name ?? ''}
            isLoading={surveyService.isLoading}
            isSavingForm={surveyService.isSavingForm}
            timeZone={account.timeZone}
          />
        )}
      </div>
    </>
  );
};

const WeeklyWellnessReportObserver = observer(WeeklyWellnessReportScreenComponent);

const InjectedWeeklyWellnessReport: FC = (props) => (
  <WeeklyWellnessReportObserver
    surveyService={useInjection(SURVEY_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
    {...props}
  />
);

export {InjectedWeeklyWellnessReport as WeeklyWellnessReportScreen};
