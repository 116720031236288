import type {FC} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import {Button, PageLoader, PageTitle, SecondaryModal} from '../../components';
import type {IWorkProductivityFormDto} from '../form';
import {WorkProductivityForm} from '../form';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import type {IWorkProductivityService} from '../service';
import {WORK_PRODUCTIVITY_SERVICE} from '../service';
import styles from './work-productivity-screen.module.scss';

interface IServiceProps {
  workProductivityService: IWorkProductivityService;
}

const WorkProductivityScreen: FC<IServiceProps> = ({workProductivityService}) => {
  const [visibleCancelModal, setVisibleCancelModal] = useState(false);

  useEffect(() => {
    workProductivityService.loadQuestions();
  }, [workProductivityService]);

  const onSurveyCancel = useCallback(() => {
    setVisibleCancelModal(true);
  }, [setVisibleCancelModal]);

  const onBack = useCallback(() => workProductivityService.navigateToBack(), [workProductivityService]);

  const onSubmit = useCallback(
    (data: IWorkProductivityFormDto) => {
      workProductivityService.createAssessment(data);
    },
    [workProductivityService],
  );

  return (
    <>
      <div className={styles.header}>
        <PageTitle>Work Productivity and Activity Impairments Questionnaire</PageTitle>
        {!workProductivityService.isLoading && (
          <Button className={styles.button} onClick={onBack}>
            Back
          </Button>
        )}
      </div>
      <div className={styles.blockInfo}>
        <p>
          The following questions ask about the effect of your health problems on your ability to work and perform
          regular activities. By health problems we mean any physical or emotional problem or symptom.
        </p>
      </div>
      {workProductivityService.isLoading ? (
        <PageLoader />
      ) : (
        <>
          <WorkProductivityForm
            listQuestions={workProductivityService.listQuestions}
            onSubmit={onSubmit}
            onCancel={onSurveyCancel}
          />
        </>
      )}
      {visibleCancelModal && (
        <SecondaryModal
          text={'All answers will be lost. Do you want to cancel the assessment?'}
          onClose={() => setVisibleCancelModal(false)}
          onSubmit={onBack}
        />
      )}
    </>
  );
};

const WorkProductivityScreenObserver = observer(WorkProductivityScreen);

const WorkProductivityScreenInjected: FC = () => (
  <WorkProductivityScreenObserver workProductivityService={useInjection(WORK_PRODUCTIVITY_SERVICE)} />
);

export {WorkProductivityScreenInjected as WorkProductivityScreen};
