import type {FC} from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import {routes} from '../../../routing';
import {Warning} from '../../../components';
import styles from './survey-notification.module.scss';

const SurveyNotification: FC = () => (
  <Warning iconVisible className={styles.root}>
    <span className={styles.text}>
      Don't forget to inform your Trellus Elevate Team about your change in medications. Please visit the{' '}
      <Link
        className={styles.link}
        to={`${routes.home}${routes.medications}`}
        target="_blank"
        rel="noopener noreferrer">
        medications page
      </Link>
    </span>
  </Warning>
);

export {SurveyNotification};
