import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IProfileVerificationRepository} from '../repository';
import {PROFILE_VERIFICATION_REPOSITORY, ProfileVerificationRepository} from '../repository';
import type {IProfileVerificationService} from '../service';
import {ProfileVerificationService, PROFILE_VERIFICATION_SERVICE} from '../service';

const ProfileVerificationModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IProfileVerificationRepository>(PROFILE_VERIFICATION_REPOSITORY)
    .to(ProfileVerificationRepository)
    .inSingletonScope();

  bind<IProfileVerificationService>(PROFILE_VERIFICATION_SERVICE).to(ProfileVerificationService).inSingletonScope();
});

export {ProfileVerificationModule};
