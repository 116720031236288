import type {FC} from 'react';
import styles from './on-boarding-back-button.module.scss';
import {useHistory} from 'react-router';
import {GotoIcon} from '../../assets/icons';

const OnBoardingBackButton: FC = () => {
  const history = useHistory();

  return (
    <div className={styles.container} onClick={() => history.goBack()}>
      <GotoIcon className={styles.icon} />
      <div className={styles.text}>{'Back'}</div>
    </div>
  );
};

export {OnBoardingBackButton};
