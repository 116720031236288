import classNames from 'classnames';
import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useEffect, useState} from 'react';
import {BlueHydrangea, PinkHydrangea, YellowHydrangea} from '../../../../../assets/goals';
import {Button} from '../../../../../components';
import {useInjection} from '../../../../../ioc';
import type {IGoal} from '../../../../interfaces';
import type {IGoalService} from '../../../../service';
import {GOAL_SERVICE} from '../../../../service';
import goalStyles from '../goal-steps.module.scss';
import styles from './goal-step-2.module.scss';

interface IStep2Prop {
  onNext?: () => void;
  onPrevious?: () => void;
}

interface IServiceProps {
  goalService: IGoalService;
}

const GoalStep2: FC<IStep2Prop & IServiceProps> = ({goalService, onNext, onPrevious}) => {
  const [possibleGoals, setPossibleGoals] = useState<IGoal>();

  useEffect(() => {
    setPossibleGoals(goalService.goal);
  }, [goalService.goal]);

  return (
    <div className={goalStyles.modalContent}>
      <div className={goalStyles.modalHeader}>
        <h2>Possible Goals</h2>
      </div>
      <ul className={styles.body}>
        <li>
          <img className={styles.goalIcon} src={BlueHydrangea} alt="Blue flower" />
          <p>{possibleGoals?.levelOneExample}</p>
        </li>
        <li>
          <img className={styles.goalIcon} src={YellowHydrangea} alt="Yellow flower" />
          <p>{possibleGoals?.levelTwoExample}</p>
        </li>
        <li>
          <img className={styles.goalIcon} src={PinkHydrangea} alt="Pink flower" />
          <p>{possibleGoals?.levelThreeExample}</p>
        </li>
      </ul>
      <div className={goalStyles.modalFooter}>
        <Button
          type="button"
          className={classNames(goalStyles.button, goalStyles.darkTextColor)}
          onClick={onPrevious}
          primary
          radius>
          Previous
        </Button>
        <Button
          type="button"
          className={classNames(goalStyles.button, goalStyles.darkTextColor)}
          onClick={onNext}
          primary
          radius>
          Next
        </Button>
      </div>
    </div>
  );
};

const ObservableGoalStep2Component = observer(GoalStep2);
const InjectedGoalStep2Component: FC<IStep2Prop> = (props) => (
  <ObservableGoalStep2Component {...props} goalService={useInjection(GOAL_SERVICE)} />
);

export {InjectedGoalStep2Component as GoalStep2};
