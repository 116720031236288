import type {IBodyMassIndexSaveConvertedDto} from './body-mass-index-save-request.dto';
import {BodyMassIndexSaveRequestDto} from './body-mass-index-save-request.dto';

class BodyMassIndexSaveRequestDtoFactory {
  static fromModel(dto: IBodyMassIndexSaveConvertedDto): BodyMassIndexSaveRequestDto {
    return new BodyMassIndexSaveRequestDto(dto.weight);
  }
}

export {BodyMassIndexSaveRequestDtoFactory};
