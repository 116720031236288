import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IEmotionalImpactScaleRepository} from '../repository';
import {EMOTIONAL_IMPACT_SCALE_REPOSITORY, EmotionalImpactScaleRepository} from '../repository';
import type {IEmotionalImpactScaleService} from '../service';
import {EMOTIONAL_IMPACT_SCALE_SERVICE, EmotionalImpactScaleService} from '../service';

const EmotionalImpactScaleModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IEmotionalImpactScaleRepository>(EMOTIONAL_IMPACT_SCALE_REPOSITORY).to(EmotionalImpactScaleRepository).inSingletonScope();

  bind<IEmotionalImpactScaleService>(EMOTIONAL_IMPACT_SCALE_SERVICE).to(EmotionalImpactScaleService).inSingletonScope();
});

export {EmotionalImpactScaleModule};
