import {EnumCommentUnionParseHelper} from '../../helpers';
import {MedicationCourseForm} from './medications-course-form.dto';
import type {MedicationsCourseModel, MedicationsFrequenciesTypesEnum} from '../model';

class MedicationCourseFormFactory {
  static fromGetResponseDto(dto: MedicationsCourseModel): MedicationCourseForm {
    const frequency = EnumCommentUnionParseHelper(dto.frequency, dto.frequencyOther);

    return new MedicationCourseForm(
      dto.id,
      dto.patientId,
      dto.medication.safeId,
      null,
      dto.start ?? null,
      dto.end ?? null,
      dto.dose,
      dto.unit,
      frequency as MedicationsFrequenciesTypesEnum,
      dto.frequencyOther,
      dto.isDiscontinued,
      dto.discontinuedDate ?? null,
      dto.reason ? Number(dto.reason) : null,
      dto.reasonOther,
    );
  }
}

export {MedicationCourseFormFactory};
