import type {CourseTaskDto} from '../dto/course-task.dto';
import {shiftTimezone} from '../../helpers';
import type {FileStorageModel} from '../../file-storage/model';
import {DailyTaskActivityType} from '../../enums';
import {getTimezoneOffset} from 'date-fns-tz';
import {addMinutes, differenceInCalendarDays} from 'date-fns';
import {MINUTE} from '../../task/model/task/task.model';

export class CourseTask {
  id: number;
  patientDailyTaskActivityId: number;
  patientDailyTaskId: number;
  patientCourseSkillId: number;
  patientId: number;
  taskSubject: string;
  skillTypeId: number;
  skillTypeName: string;
  skillTypeCompletionDate: Date | null;
  isAutoAssigned: boolean;
  createdDate: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  completionDate: Date | null;
  taskStatus: number;
  appointmentId: number;
  isRestarted: boolean;
  lessonId: string;
  text: string;
  frequency: number;
  daysInWeek: string;
  beginDate: Date | null;
  doneDate: Date | null;
  priority?: 'branch' | 'leaf';
  isFavoriteLesson: boolean;
  isRatedLesson: boolean;
  displayTitle: any;
  courseThumbnailObjectFileId: string;
  courseThumbnailObjectFile: FileStorageModel | null | string = null;
  courseLink: string;
  dailyTaskActivityType: DailyTaskActivityType = DailyTaskActivityType.Lesson;
  complete: boolean;
  isSkippable: boolean;
  featuredContent: boolean;
  skipDate: Date | null;

  constructor(dto: CourseTaskDto) {
    this.id = dto.patientDailyTaskActivityIntervalId;
    this.patientDailyTaskId = dto.patientDailyTaskId;
    this.patientDailyTaskActivityId = dto.patientDailyTaskActivityId;
    this.patientCourseSkillId = dto.patientCourseSkillId;
    this.patientId = dto.patientId;
    this.taskSubject = dto.taskSubject;
    this.skillTypeId = dto.skillTypeId;
    this.skillTypeName = dto.skillTypeName;
    this.skillTypeCompletionDate = dto.skillTypeCompletionDate
      ? shiftTimezone(new Date(dto.skillTypeCompletionDate))
      : null;
    this.createdDate = dto.createdDate ? shiftTimezone(new Date(dto.createdDate)) : null;
    this.startDate = dto.startDate ? shiftTimezone(new Date(dto.startDate)) : null;
    this.endDate = dto.endDate ? shiftTimezone(new Date(dto.endDate)) : null;
    this.completionDate = dto.completionDate ? shiftTimezone(new Date(dto.completionDate)) : null;
    this.beginDate = dto.beginDate ? shiftTimezone(new Date(dto.beginDate)) : null;
    this.doneDate = dto.doneDate ? shiftTimezone(new Date(dto.doneDate)) : null;
    this.isAutoAssigned = dto.isAutoAssigned;
    this.appointmentId = dto.appointmentId;
    this.taskStatus = dto.taskStatus;
    this.isRestarted = dto.isRestarted;
    this.lessonId = dto.lessonId;
    this.text = dto.text;
    this.frequency = dto.frequency;
    this.daysInWeek = dto.daysInWeek;
    this.priority = dto.priority;
    this.isFavoriteLesson = dto.isFavoriteLesson;
    this.isRatedLesson = dto.isRatedLesson;
    this.displayTitle = dto.displayTitle;
    this.courseThumbnailObjectFileId = dto.courseThumbnailObjectFileId;
    this.courseLink = dto.courseLink;
    this.complete = !!dto.doneDate;
    this.isSkippable = dto.isSkippable;
    this.featuredContent = dto.featuredContent;
    this.skipDate = dto.skipDate ? shiftTimezone(new Date(dto.skipDate)) : null;
  }

  getDiffInDays(timeZone?: string, dateNow: Date = shiftTimezone(new Date())): number {
    const timeZoneOffsetInMinutes = timeZone ? getTimezoneOffset(timeZone) / MINUTE : 0;
    const date = addMinutes(dateNow, timeZoneOffsetInMinutes);

    return differenceInCalendarDays(date, this.startDate as Date);
  }
}
