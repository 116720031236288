import type {FC} from 'react';
import React from 'react';
import {CollapseDescription, PageTitle} from '../../../components';
import {ResilienceAssessmentSurvey} from '../survey';
import styles from './resilience-assessment-screen.module.scss';

const ResilienceAssessmentScreen: FC = () => (
  <>
    <PageTitle className={styles.header}>Resilience-5 Assessment</PageTitle>
    <CollapseDescription>
      <p>
        You may be noticing various changes and developments in your life because of your all of hard work. We would
        appreciate the opportunity to understand your experience better, allowing us to acknowledge your achievements
        and assist in planning for the future. Please answer the below questions as best you can.
      </p>
    </CollapseDescription>
    <ResilienceAssessmentSurvey />
  </>
);

export {ResilienceAssessmentScreen};
