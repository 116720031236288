import type {IPotData} from './pot.types';
import {PotFlowerTypeEnum} from './pot.types';

export const potFlowersConfig = [
  {
    toRender: ({completedLessonsCount}: IPotData) => completedLessonsCount >= 5,
    type: PotFlowerTypeEnum.Orange,
    flowerCount: ({completedLessonsCount}: IPotData) =>
      completedLessonsCount < 10 ? 1 : completedLessonsCount < 20 ? 2 : 3,
    modifier: ({completedLessonsCount}: IPotData) =>
      completedLessonsCount <= 1000 && completedLessonsCount >= 30
        ? completedLessonsCount % 10 === 0
          ? completedLessonsCount
          : Math.floor(completedLessonsCount / 10) * 10
        : undefined,
    text: ({completedLessonsCount}: IPotData) =>
      completedLessonsCount <= 1000
        ? `${
            completedLessonsCount >= 5 && completedLessonsCount < 10
              ? 5
              : completedLessonsCount >= 10 && completedLessonsCount < 20
              ? 10
              : completedLessonsCount % 10 === 0
              ? completedLessonsCount
              : Math.floor(completedLessonsCount / 10) * 10
          } lessons completed!`
        : '1000 lessons completed!',
  }, //pottedOrange
  {
    toRender: ({completedJumpstartLessonsCount}: IPotData) => completedJumpstartLessonsCount > 0,
    type: PotFlowerTypeEnum.Pink,
    flowerCount: ({completedJumpstartLessonsCount}: IPotData) =>
      completedJumpstartLessonsCount > 0 && completedJumpstartLessonsCount < 4 ? completedJumpstartLessonsCount : 4,
    modifier: ({completedJumpstartLessonsCount}: IPotData) =>
      completedJumpstartLessonsCount > 4
        ? completedJumpstartLessonsCount < 100
          ? completedJumpstartLessonsCount
          : 100
        : undefined,
    text: ({completedJumpstartLessonsCount}: IPotData) =>
      `Jump Start Lesson ${completedJumpstartLessonsCount < 100 ? completedJumpstartLessonsCount : 100} Completed`,
  }, //pottedPink
  {
    toRender: ({weeklyWellnessConsecutiveCompletionsCount}: IPotData) => weeklyWellnessConsecutiveCompletionsCount > 0,
    type: PotFlowerTypeEnum.Blue,
    flowerCount: () => undefined,
    modifier: ({weeklyWellnessConsecutiveCompletionsCount}: IPotData) =>
      weeklyWellnessConsecutiveCompletionsCount > 1 ? `X${weeklyWellnessConsecutiveCompletionsCount}` : undefined,
    text: (_data: IPotData) => `You're on a roll! You completed your Weekly Wellness 4 weeks in a row.`,
  }, //pottedBlue
  {
    toRender: ({weeklyWellnessOverallImprovementCount}: IPotData) => weeklyWellnessOverallImprovementCount > 0,
    type: PotFlowerTypeEnum.Lavender,
    flowerCount: () => undefined,
    modifier: ({weeklyWellnessOverallImprovementCount}: IPotData) =>
      weeklyWellnessOverallImprovementCount > 1 ? `X${weeklyWellnessOverallImprovementCount}` : undefined,
    text: (_data: IPotData) => 'Winning! Your weekly wellness improved in the last 4 weeks!',
  }, //pottedLavender
  {
    toRender: ({weeklyWellnessHappinessScoreImprovedCount}: IPotData) => weeklyWellnessHappinessScoreImprovedCount > 0,
    type: PotFlowerTypeEnum.Blue2,
    flowerCount: () => undefined,
    modifier: ({weeklyWellnessHappinessScoreImprovedCount}: IPotData) =>
      weeklyWellnessHappinessScoreImprovedCount > 1 ? `X${weeklyWellnessHappinessScoreImprovedCount}` : undefined,
    text: (_data: IPotData) =>
      `You're on track! Your Weekly Wellness happiness score has improved over the last 4 weeks.`,
  }, //pottedBlue2
  {
    toRender: ({weeklyWellnessHappinessLadderScoreIsConsecutivelyGreat}: IPotData) =>
      weeklyWellnessHappinessLadderScoreIsConsecutivelyGreat,
    type: PotFlowerTypeEnum.Marigold,
    flowerCount: () => undefined,
    modifier: () => undefined,
    text: (_data: IPotData) => 'Living your best life! Your Weekly Wellness happiness score has been high for 4 weeks.',
  }, //pottedMarigold
  {
    toRender: ({loggedFiveTimesInLast14daysCount}: IPotData) => loggedFiveTimesInLast14daysCount > 0,
    type: PotFlowerTypeEnum.Red,
    flowerCount: () => undefined,
    modifier: ({loggedFiveTimesInLast14daysCount}: IPotData) =>
      loggedFiveTimesInLast14daysCount > 1 ? `X${loggedFiveTimesInLast14daysCount}` : undefined,
    text: (_data: IPotData) => `You're on a roll! You logged in 5 times in the last two weeks.`,
  }, //pottedRed
  {
    toRender: ({loggedOneTimeInFourWeeksCount}: IPotData) => loggedOneTimeInFourWeeksCount > 0,
    type: PotFlowerTypeEnum.Purple,
    flowerCount: () => undefined,
    modifier: ({loggedOneTimeInFourWeeksCount}: IPotData) =>
      loggedOneTimeInFourWeeksCount > 1 ? `X${loggedOneTimeInFourWeeksCount}` : undefined,
    text: (_data: IPotData) => 'Keep it up! You logged in 4 weeks in a row.',
  }, //pottedPurple
  {
    toRender: ({haveLoginAfterPause}: IPotData) => haveLoginAfterPause,
    type: PotFlowerTypeEnum.PinkSpray,
    flowerCount: () => undefined,
    modifier: () => undefined,
    text: (_data: IPotData) => 'Welcome back! You came back after taking a short break.',
  }, //pottedPinkSpray
];
