import type {FC} from 'react';
import React, {useCallback, useEffect} from 'react';
import type {IAssessmentsTableRow} from '../../components';
import {AssessmentsTable, Button, PageLoader, PageTitle} from '../../components';
import styles from './chronic-condition-checklist-screen.module.scss';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import type {IChronicConditionChecklistService} from '../service';
import {CHRONIC_CONDITION_CHECKLIST_SERVICE} from '../service';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../helpers';

interface IServiceProps {
  chronicConditionChecklist: IChronicConditionChecklistService;
}

const ChronicConditionChecklistScreen: FC<IServiceProps> = ({chronicConditionChecklist}) => {
  const assessmentTableHeader = ['Date', 'Name'];

  const assessmentTableRows: IAssessmentsTableRow[] = chronicConditionChecklist.listAssessments.map((item) => {
    const date = item.lastUpdated ? format(item.lastUpdated, DEFAULT_FORMAT) : '';

    return {
      id: item.id,
      cells: [date, item.name],
    };
  });

  const onAssessmentClick = useCallback(() => {
    chronicConditionChecklist.navigateToAssessment();
  }, [chronicConditionChecklist]);

  const navigateCallback = useCallback(
    (id: number) => {
      chronicConditionChecklist.navigateToAssessment(id);
    },
    [chronicConditionChecklist],
  );

  useEffect(() => {
    chronicConditionChecklist.loadAssessments();
  }, [chronicConditionChecklist]);

  return (
    <>
      <div className={styles.headerContainer}>
        <PageTitle>Chronic Condition Checklists</PageTitle>
        {!chronicConditionChecklist.isReadonly && (
          <Button className={styles.button} onClick={onAssessmentClick} testid={'StartAssessmentButton'}>
            {chronicConditionChecklist.listAssessments.length ? 'Start the reassessment' : 'Start the assessment'}
          </Button>
        )}
      </div>
      {chronicConditionChecklist.isLoading ? (
        <PageLoader />
      ) : (
        <AssessmentsTable
          header={assessmentTableHeader}
          rows={assessmentTableRows}
          onClickRow={navigateCallback}
          noResultsMessage={'Reminder to complete the Chronic Condition Checklists'}
        />
      )}
    </>
  );
};

const ChronicConditionChecklistScreenLegacyInjected = observer(ChronicConditionChecklistScreen);

const ChronicConditionChecklistScreenInjected: FC = () => (
  <ChronicConditionChecklistScreenLegacyInjected
    chronicConditionChecklist={useInjection(CHRONIC_CONDITION_CHECKLIST_SERVICE)}
  />
);

export {ChronicConditionChecklistScreenInjected as ChronicConditionChecklistScreen};
