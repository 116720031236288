import type {FC} from 'react';
import React, {useEffect, useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {parseFormulaToShowQuestion, scrollToError} from '../../../helpers';
import {AssessmentItemWrapper, AssessmentRangeBar} from '../../../components';
import {AssessmentQuestionType} from '../../../assessment';
import type {LifeQualityQuestionModel} from '../../model';

interface ILifeQualityQuestionsProps {
  listQuestions: LifeQualityQuestionModel[];
}

const LifeQualityQuestions: FC<ILifeQualityQuestionsProps> = ({listQuestions}) => {
  const {control, watch, errors} = useFormContext();

  const formValues = watch();

  const isShowQuestion = useMemo(
    () => (question: LifeQualityQuestionModel) =>
      question?.visibleIf ? parseFormulaToShowQuestion(question.visibleIf, formValues) : true,
    [formValues],
  );

  const questions = useMemo(
    () =>
      listQuestions.map((question) => {
        switch (question.type) {
          case AssessmentQuestionType.Range:
            return (
              isShowQuestion(question) && (
                <AssessmentItemWrapper title={question.displayName} key={question.name}>
                  <Controller
                    control={control}
                    name={question.name}
                    render={({name, onChange, value}) => (
                      <AssessmentRangeBar question={question} name={name} value={value} onChange={onChange} />
                    )}
                  />
                </AssessmentItemWrapper>
              )
            );
          default:
            return null;
        }
      }),
    [control, isShowQuestion, listQuestions],
  );

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  return <>{questions}</>;
};

export {LifeQualityQuestions};
