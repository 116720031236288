import type {FC, ReactNode} from 'react';
import React, {useCallback, useMemo} from 'react';
import styles from './list-item.module.scss';
import classNames from 'classnames';
import {Icon, IconType} from '../icon';
import {Card} from '../card';
import {CardContent} from '../card-content';
import {FavoriteIcon} from '../favorite-icon';
import type {FileStorageModel} from '../../file-storage/model';
import {getFileDataUrl} from '../../file-storage/helpers';

enum ListItemDataTestIdEnum {
  MetaText = 'meta_text',
  Date = 'expired',
  Card = 'card',
  Title = 'Title',
}

interface IListItemProps {
  id?: number;
  title?: string;
  metaText?: string;
  card?: boolean;
  date?: string | JSX.Element;
  dateDanger?: boolean;
  isComplete?: boolean;
  onClick?: () => void;
  onChangeFavoriteItem?: (taskId: number, isFavorite: boolean) => void;
  hoverable?: boolean;
  color?: string;
  highlight?: boolean;
  isFavorite?: boolean;
  showFavoriteIcon?: boolean;
  image?: null | FileStorageModel | string;
  isLesson?: boolean;
  isCourse?: boolean;
  actions?: ReactNode;
}

const ListItem: FC<IListItemProps> = (props) => {
  const metaText = useMemo(
    () => (
      <>
        {props.metaText && (
          <span data-testid={ListItemDataTestIdEnum.MetaText} className={styles.metaText}>
            {props.metaText}
          </span>
        )}
      </>
    ),
    [props.metaText],
  );

  const date = useMemo(
    () =>
      props.date && !props.isComplete ? (
        <span
          data-testid={ListItemDataTestIdEnum.Date}
          className={classNames(styles.date, {
            [styles.danger]: props.dateDanger,
          })}>
          <Icon type={props.dateDanger ? IconType.AlertCircle : IconType.Clock} />
          <span>{props.date}</span>
        </span>
      ) : null,
    [props.date, props.dateDanger, props.isComplete],
  );

  const onChangeFavoriteItem = useCallback(() => {
    if (props.onChangeFavoriteItem && props?.id) {
      props.onChangeFavoriteItem(props.id, !props.isFavorite);
    }
  }, [props]);

  const favorite = useMemo(
    () =>
      !!props?.showFavoriteIcon ? (
        <FavoriteIcon size={1} onClick={onChangeFavoriteItem} isFilled={props.isFavorite ?? false} />
      ) : null,
    [props.showFavoriteIcon, props.isFavorite, onChangeFavoriteItem],
  );

  const statusGroup = useMemo(
    () => (!!props?.showFavoriteIcon ? <div className={classNames(styles.statusGroup)}>{favorite}</div> : <></>),
    [favorite, props?.showFavoriteIcon],
  );

  const onClick = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [props]);

  const Wrapper: React.FC = useCallback(
    (wrapperProps) => {
      if (props.card) {
        return (
          <Card
            data-testid={ListItemDataTestIdEnum.Card}
            className={classNames({
              [styles.hoverable]: props.hoverable,
              [styles.highlight]: props.highlight,
              [styles.success]: props.isComplete,
              [styles.gray]: !props.isComplete && props.isLesson && !props.isCourse,
            })}
            color={props.color}
            border="top"
            isLesson={props.isLesson}>
            <CardContent className={styles.wrapper}>{wrapperProps.children}</CardContent>
          </Card>
        );
      } else {
        return <>{wrapperProps.children}</>;
      }
    },
    [props.card, props.color, props.highlight, props.hoverable, props.isLesson, props.isComplete, props.isCourse],
  );

  const getImage = useMemo(() => {
    if (typeof props.image === 'string') {
      return <img src={props.image} alt="List item" className={styles.image} />;
    }
    if (props.image?.fileName.startsWith('STATIC_FILE_')) {
      return <img src={props.image?.fileName.replace('STATIC_FILE_', '')} alt="List item" className={styles.image} />;
    }

    if (!!props.image) {
      return <img src={getFileDataUrl(props.image)} alt="List item" className={styles.image} />;
    }

    return <></>;
  }, [props.image]);

  const actions = useMemo(() => (props.actions ? props.actions : null), [props.actions]);

  return (
    <li
      onClick={onClick}
      className={classNames({
        [styles.hoverable]: !props.card && props.hoverable,
      })}>
      <Wrapper>
        <div
          data-testid={ListItemDataTestIdEnum.Title}
          className={classNames(styles.content, {
            [styles.contentWithThreeColumns]: true,
          })}>
          <div className={classNames(styles.withImage, props.isCourse && styles.courseImage)}>
            {getImage}
            <div>
              {metaText}
              <p className={styles.title}>{props.title}</p>
              {date}
            </div>
          </div>
          {actions}
          {statusGroup}
        </div>
        {props.children ? <div>{props.children}</div> : null}
      </Wrapper>
    </li>
  );
};

export {ListItem, ListItemDataTestIdEnum};
export type {IListItemProps};
