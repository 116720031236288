import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './medications-list.module.scss';
import {Card, EmptyRectangle} from '../../../components';
import {MedicationsListItem} from '../list-item';
import {ChevronDownBlack} from '../../../assets/icons';
import classNames from 'classnames';

enum MedicationsListItemTypeEnum {
  CURRENT,
  DISCONTINUE,
  ON_DISCONTINUE,
  EDIT,
}

interface IMedicationListItem {
  id: number;
  medicationName: string;
  dose: string;
}

interface IMedicationListDiscontinuedItem extends IMedicationListItem {
  discontinue: Date | null;
}

interface IMedicationsListProps {
  currentItems: ReadonlyArray<IMedicationListItem>;
  discontinuedItems: ReadonlyArray<IMedicationListDiscontinuedItem>;
  onItemClick?: (id: number, type: MedicationsListItemTypeEnum) => void;

  onLoad(): void;
}

const HeaderItemsForCurrent = ['Medication', 'Dose', ''].map(function (item, index) {
  if (item.length > 0) {
    return (
      <th key={item + index} className={styles.tableHeader}>
        {item}
      </th>
    );
  }

  return <td key={item + index} className={styles.tableHeader} />;
});

const HeaderItemsForDiscontinued = ['Medication', 'Dose', 'D/C Date'].map(function (item) {
  if (item.length > 0) {
    return (
      <th key={item} className={styles.tableHeader}>
        {item}
      </th>
    );
  }

  return <td key={item} className={styles.tableHeader} />;
});

const MedicationsList: FC<IMedicationsListProps> = ({currentItems, discontinuedItems, onItemClick, onLoad}) => {
  const [arrowActive, setArrowActive] = useState(true);

  const current = useMemo(
    () =>
      currentItems.map(({id, medicationName, dose}) => {
        const showDiscontinue = () =>
          onItemClick && onItemClick(Number(id), MedicationsListItemTypeEnum.ON_DISCONTINUE);
        const showInfo = () => onItemClick && onItemClick(Number(id), MedicationsListItemTypeEnum.CURRENT);
        const editClick = () => onItemClick && onItemClick(Number(id), MedicationsListItemTypeEnum.EDIT);

        return (
          <MedicationsListItem
            key={id}
            id={id}
            medicationName={medicationName}
            dose={dose}
            onDiscontinue={showDiscontinue}
            onInfo={showInfo}
            onEdit={editClick}
          />
        );
      }),
    [currentItems, onItemClick],
  );

  const discontinued = useMemo(
    () =>
      discontinuedItems.map(({id, medicationName, discontinue, dose}) => {
        const show = () => onItemClick && onItemClick(Number(id), MedicationsListItemTypeEnum.DISCONTINUE);

        return (
          <MedicationsListItem
            key={id}
            id={id}
            medicationName={medicationName}
            discontinue={discontinue}
            dose={dose}
            onInfo={show}
          />
        );
      }),
    [discontinuedItems, onItemClick],
  );

  const scrollHandler = useCallback(
    (e: any) => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        !arrowActive
      ) {
        onLoad();
      }
    },
    [arrowActive, onLoad],
  );

  useEffect(() => {
    const eventType = 'scroll';
    document.addEventListener(eventType, scrollHandler);

    return () => document.removeEventListener(eventType, scrollHandler);
  }, [scrollHandler]);

  return (
    <>
      <h2 className={styles.tableTitle}>Current medications</h2>
      {current?.length ? (
        <Card className={styles.wrapper}>
          <table className={styles.table}>
            <thead>
              <tr>{HeaderItemsForCurrent}</tr>
            </thead>
            <tbody>{current}</tbody>
          </table>
        </Card>
      ) : (
        <div className={styles.wrapper}>
          <EmptyRectangle className={styles.rectangle}>
            <div className={styles.rectangleText}>You don't have current medications.</div>
          </EmptyRectangle>
        </div>
      )}

      <h2 className={styles.tableTitle}>
        Discontinued medications
        <ChevronDownBlack
          className={classNames(styles.tableArrow, {[styles.tableArrowActive]: arrowActive})}
          onClick={() => setArrowActive(!arrowActive)}
        />
      </h2>
      {discontinued?.length ? (
        <Card className={classNames({[styles.hidden]: arrowActive})}>
          <table className={styles.table}>
            <thead>
              <tr>{HeaderItemsForDiscontinued}</tr>
            </thead>
            <tbody>{discontinued}</tbody>
          </table>
        </Card>
      ) : (
        <div className={classNames(styles.wrapper, {[styles.hidden]: arrowActive})}>
          <EmptyRectangle className={styles.rectangle}>
            <div className={styles.rectangleText}>You don't have discontinued medications.</div>
          </EmptyRectangle>
        </div>
      )}
    </>
  );
};

export {MedicationsList, MedicationsListItemTypeEnum};
export type {IMedicationsListProps, IMedicationListItem, IMedicationListDiscontinuedItem};
