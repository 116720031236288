import type {MedicationsCourseModel} from '../../model';
import {MedicationsFrequenciesTypesEnum} from '../../model';
import {MedicationsCoursePostRequestDto} from './medications-course-post-request.dto';
import {
  EnumCommentSeparationParseHelper,
  formatFullDateTime,
  MEDICATION_DISCONTINUED_REASON_OTHER_ID,
} from '../../../helpers';

class MedicationsCoursePostRequestDtoFactory {
  static fromModel(model: MedicationsCourseModel): MedicationsCoursePostRequestDto {
    const {id: frequency, comment: frequencyOther} = EnumCommentSeparationParseHelper(
      model.frequency,
      MedicationsFrequenciesTypesEnum.Other,
    );

    const {id: reason, comment: reasonOther} = EnumCommentSeparationParseHelper(
      model?.reason,
      MEDICATION_DISCONTINUED_REASON_OTHER_ID,
    );

    return new MedicationsCoursePostRequestDto(
      model.patientId,
      model.medication.isCustom ? null : model.medication.id,
      model.medication.isCustom ? model.medication.id : null,
      model.start ? formatFullDateTime(model.start) : '',
      model.end ? formatFullDateTime(model.end) : '',
      model.dose ?? null,
      model.unit,
      frequency,
      frequencyOther,
      model.isDiscontinued,
      model.discontinuedDate ? formatFullDateTime(model.discontinuedDate) : null,
      reason,
      reasonOther,
    );
  }
}

export {MedicationsCoursePostRequestDtoFactory};
