import type { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, CardActions, Rate, TextArea } from '../../../components';
import styles from './education-course-rate-form.module.scss';

interface IEducationCourseRateFormProps {
  onSubmit(data: IEducationCourseRateFormDto): void;
  onSubmitStars(data: IEducationCourseRateFormDto): void;
}

interface IEducationCourseRateFormDto {
  rate: number | null;
  notes: string | null;
}

const EducationCourseRateForm: FC<IEducationCourseRateFormProps> = ({ onSubmit, onSubmitStars }) => {
  const { control, handleSubmit, formState } = useForm<IEducationCourseRateFormDto>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.root}>
        <Controller
          name={'rate'}
          control={control}
          render={({ value, onChange, name }) => (
            <Rate
              name={name}
              max={5}
              value={value}
              onChange={(i) => {
                onChange(i)
                handleSubmit(onSubmit)
              }}
              onSubmitStars={handleSubmit(onSubmitStars)}
            />
          )}
        />
        <span className={styles.Subtitle}>Anything else you'd like to share?</span>
        <span className={styles.SmallText}>Your feedback helps us improve!</span>
        <Controller
          name="notes"
          control={control}
          render={({ value, onChange, name }) => (
            <TextArea name={name} className={styles.TextArea} value={value} onChange={onChange} />
          )}
        />
      </div>
      <CardActions>
        <Button type={'submit'} disabled={!formState.isDirty} testid={'education-course-rate-form-submit'}>
          send
        </Button>
      </CardActions>
    </form>
  );
};

export { EducationCourseRateForm };
export type { IEducationCourseRateFormDto, IEducationCourseRateFormProps };

