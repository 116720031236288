import {UserRole} from './user-role.enum';

class AccountBaseInfoModel {
  constructor(
    public id: number = 0,
    public firstName: string = '',
    public lastName: string = '',
    public roleId: UserRole = UserRole.Undefined,
    public roleName: string = '',
    public isConfirmed: boolean = false,
  ) {}
}

export {AccountBaseInfoModel};
