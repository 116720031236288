import type {FC} from 'react';

const EmailParser: FC<{
  text: string;
  email: string;
  className?: string;
}> = (props) => {
  const emailIndex = props.text.indexOf(props.email);

  const textBefore = props.text.slice(0, emailIndex);
  const textAfter = props.text.slice(emailIndex + props.email.length);

  return (
    <>
      {textBefore}
      <a className={props.className} href={`mailto:${props.email}`}>
        {props.email}
      </a>
      {textAfter}
    </>
  );
};

export {EmailParser};
