import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import type {IAccountService} from '../../../../account';
import {ACCOUNT_SERVICE} from '../../../../account';
import {Modal, PopUpAchievementModal} from '../../../../components';
import {CourseIframe} from '../../../../course-iframe/components';
import {useAchievements} from '../../../../hooks';
import {useInjection} from '../../../../ioc';
import {GoalReviewWorkflow} from '../../../../task/components/goal-modal/review-workflow/review-workflow.component';
import type {IPopUpReviewWorkflow} from '../../../../task/interfaces';
import type {IGoalService, ITaskService} from '../../../../task/service';
import {GOAL_SERVICE, TASK_SERVICE} from '../../../../task/service';
import type {IAuthConfigService} from '../../../../utils/auth/config';
import {AUTH_CONFIG_SERVICE} from '../../../../utils/auth/config';
import type {IThriveSkillService} from '../../../../thrive-skill/service';
import {THRIVE_SKILL_SERVICE} from '../../../../thrive-skill/service';
import {THRIVE_SKILL_ID} from '../../../../helpers';

interface IServiceProps {
  accountService: IAccountService;
  authConfig: IAuthConfigService;
  goalService: IGoalService;
  taskService: ITaskService;
  thriveSkill: IThriveSkillService;
}

type TModalType = 'achievement' | 'welcomeToThrive' | 'goalReviewWorkflow';

type TModalConfig = {
  type: TModalType;
  props: any;
};

const ModalManager: FC<IServiceProps> = ({accountService, authConfig, goalService, taskService, thriveSkill}) => {
  const tourCompleted = localStorage.getItem('tourState') === 'true';
  const {
    achievements: ach,
    currentAchievementIndex: achCurrIndex,
    isModalOpen: isAchOpen,
    handleModalClose: handleCloseAch,
  } = useAchievements();
  const [isGoalReviewOpen, setIsGoalReviewOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState<TModalConfig>();

  const handleCloseReviewWorkflow = useCallback(() => {
    goalService.reviewWorkflow = {} as IPopUpReviewWorkflow;
  }, [goalService]);

  const handleCloseWelcomeToThrive = useCallback(async () => {
    await accountService.setWelcomeToThriveWatched();
  }, [accountService]);

  // Set queue of modals and layouts
  useEffect(() => {
    if (isAchOpen) {
      setCurrentModal({
        type: 'achievement' as TModalType,
        props: {
          show: isAchOpen,
          onClose: handleCloseAch,
        },
      });
    } else if (isGoalReviewOpen) {
      setCurrentModal({type: 'goalReviewWorkflow', props: {onClose: handleCloseReviewWorkflow}});
    } else if (accountService.info?.showWelcomeToThrive) {
      setCurrentModal({type: 'welcomeToThrive', props: {onClose: handleCloseWelcomeToThrive}});
    } else {
      setCurrentModal(undefined);
    }
  }, [
    accountService.info?.showWelcomeToThrive,
    isAchOpen,
    isGoalReviewOpen,
    handleCloseAch,
    handleCloseReviewWorkflow,
    handleCloseWelcomeToThrive,
  ]);

  useEffect(() => {
    setIsGoalReviewOpen(Boolean(goalService.reviewWorkflow?.worksheetId));
  }, [goalService.reviewWorkflow.worksheetId]);

  const renderModal = () => {
    if (!currentModal || !tourCompleted) {
      return null;
    }

    switch (currentModal.type) {
      case 'achievement':
        return (
          <PopUpAchievementModal
            {...currentModal.props}
            streaksCount={ach[achCurrIndex]?.streaksCount}
            title={ach[achCurrIndex]?.rewardText}
            meta={ach[achCurrIndex]?.meta}
            type={ach[achCurrIndex]?.type}
            onClose={handleCloseAch}
          />
        );
      case 'goalReviewWorkflow':
        return (
          <Modal closeIcon onClose={handleCloseReviewWorkflow}>
            <GoalReviewWorkflow {...currentModal.props} />
          </Modal>
        );
      case 'welcomeToThrive':
        const welcomeToThriveLesson = taskService.lessons.find(({skillId}) => skillId === THRIVE_SKILL_ID);

        return (
          <CourseIframe
            src={welcomeToThriveLesson?.courseLink}
            lmsLink={authConfig.lmsCourseLink}
            onClose={currentModal.props.onClose}
            onComplete={currentModal.props.onClose}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderModal()}</>;
};

const ModalManagerObserver = observer(ModalManager);
const ModalManagerInjected: FC = () => (
  <ModalManagerObserver
    goalService={useInjection(GOAL_SERVICE)}
    accountService={useInjection(ACCOUNT_SERVICE)}
    taskService={useInjection(TASK_SERVICE)}
    authConfig={useInjection(AUTH_CONFIG_SERVICE)}
    thriveSkill={useInjection(THRIVE_SKILL_SERVICE)}
  />
);

export {ModalManagerInjected as ModalManager};
