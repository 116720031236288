import React, {useCallback} from 'react';
import type {KeyboardEventHandler} from 'react';
import styles from './week-day-card.module.scss';
import {getWeekDay} from './get-week-day';
import {getDay} from 'date-fns';
import {Card} from '../card';
import classNames from 'classnames';
import {CardContent} from '../card-content';
import {formatDate, unshiftTimezone} from '../../helpers';

interface IWeekDayCardProps {
  date: Date;
  active?: boolean;
  hoverable?: boolean;
  onClick?: () => void;
  onSelectDate?: (newDate: Date) => void;
}

const WeekDayCard: React.FC<IWeekDayCardProps> = (props) => {
  const date = unshiftTimezone(props.date);
  const monthDay = Number(formatDate(date, 'd'));
  const weekDay = getWeekDay(getDay(props.date));

  const onEnterPress: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.code === 'Enter' && props.onSelectDate) {
        props.onSelectDate(props.date);
      }
    },
    [props],
  );

  const onClickCapture = useCallback(() => {
    if (props.onSelectDate) {
      props.onSelectDate(props.date);
    }
  }, [props]);

  return (
    <Card
      className={classNames(styles.root, {
        [styles.hoverable]: !!props.hoverable,
        [styles.active]: !!props.active,
      })}
      onClick={props.onClick}
      onClickCapture={onClickCapture}
      onKeyPress={onEnterPress}
      role="menuitem"
      tabIndex={props.hoverable ? 0 : -1}>
      <CardContent>
        <div className={styles.content}>
          <span className={styles.monthDay}>{monthDay}</span>
          <span
            className={classNames(styles.weekDay, {
              [styles.activeDay]: !!props.active,
            })}>
            {weekDay}
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

export {WeekDayCard};
export type {IWeekDayCardProps};
