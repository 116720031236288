import {mapFromOptionsHelper} from '../../helpers';
import {BodyMassIndexUnitEnum} from './body-mass-index-unit.enum';

const unitOptions = mapFromOptionsHelper<BodyMassIndexUnitEnum>([
  {
    value: BodyMassIndexUnitEnum.FtAndLb,
    displayName: 'lb',
  },
  {
    value: BodyMassIndexUnitEnum.CmAndKg,
    displayName: 'kg',
  },
]);

export {unitOptions};
