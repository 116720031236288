import type {VaccinationModel} from '../../model';
import {VaccinationFormDto} from './vaccination-form.dto';

class VaccinationFormDtoFactory {
  static fromModel(model: VaccinationModel): VaccinationFormDto {
    const findValue = (questionName: string) =>
      model.items.find((item) => item.question === questionName)?.value?.replace(`${questionName}_`, '') || '';

    const uniqueIndexes: number[] = model.items.reduce(
      (acc, currentValue) =>
        currentValue.dynamicSectionIndex !== null && !acc.some((i) => i === currentValue.dynamicSectionIndex)
          ? [...acc, currentValue.dynamicSectionIndex]
          : acc,
      [] as number[],
    );

    const covidDefaultItem =
      findValue('q_vaccination_record_covid19') === 'yes'
        ? [
            {
              q_vaccination_record_covid19_date: '',
              q_vaccination_record_covid19_vaccine: '',
            },
          ]
        : [];

    const covidItems = uniqueIndexes.length
      ? uniqueIndexes.map((index) => ({
          q_vaccination_record_covid19_date:
            model.items.find(
              (i) => i.dynamicSectionIndex === index && i.question === 'q_vaccination_record_covid19_date',
            )?.value ?? '',
          q_vaccination_record_covid19_vaccine:
            model.items
              .find((i) => i.dynamicSectionIndex === index && i.question === 'q_vaccination_record_covid19_vaccine')
              ?.value?.slice('q_vaccination_record_covid19_vaccine'.length + 1) ?? '',
        }))
      : covidDefaultItem;

    return new VaccinationFormDto(
      findValue('q_vaccination_record_varicella_as_child'),
      findValue('q_vaccination_record_varicella_immunity'),
      findValue('q_vaccination_record_flu'),
      findValue('q_vaccination_record_flu_date'),
      findValue('q_vaccination_record_menacwy'),
      findValue('q_vaccination_record_menb'),
      findValue('q_vaccination_record_mmr'),
      findValue('q_vaccination_record_hepatitis_a'),
      findValue('q_vaccination_record_hepatitis_b'),
      findValue('q_vaccination_record_hepatitis_b_immunity'),
      findValue('q_vaccination_record_herpes'),
      findValue('q_vaccination_record_herpes_date'),
      findValue('q_vaccination_record_herpes_second_date'),
      findValue('q_vaccination_record_hpv'),
      findValue('q_vaccination_record_hpv_date'),
      findValue('q_vaccination_record_ppsv23'),
      findValue('q_vaccination_record_pcv13'),
      findValue('q_vaccination_record_covid19'),
      covidItems,
      findValue('q_vaccination_record_rsv'),
    );
  }
}

export {VaccinationFormDtoFactory};
