import type {CrpExpression, TuberculosisTypes, UnitsTypesEnum} from '../enums';
import {LabTests} from '../enums';
import {expressionOptions, unitTypesOptions} from '../options';

class LabResultsModel {
  constructor(
    public id: number | null = null,
    public patientId: number | null = null,
    public labDate: Date | null = null,
    public lastUpdatedByFullName: string | null = null,
    public labTest: LabTests | null = null,
    public comparison: CrpExpression | null = null,
    public resultValue: number | null = null,
    public resultType: TuberculosisTypes | null = null,
    public unitsType: UnitsTypesEnum | null = null,
  ) {}

  getResultString(): string {
    let resultString = '';

    const expressionDisplayName = this.comparison ? expressionOptions.get(this.comparison) : '';
    const unitTypeDisplayName = this.unitsType ? unitTypesOptions.get(this.unitsType) : '';

    switch (this.labTest) {
      case LabTests.CRP:
        resultString = `${expressionDisplayName} ${this.resultValue ?? ''} ${unitTypeDisplayName}`.trim();
        break;
      case LabTests.FecalCalprotectin:
      case LabTests.VitaminD:
      case LabTests.Hemoglobin:
      case LabTests.Albumin:
        resultString = `${this.resultValue ?? ''} ${unitTypeDisplayName}`.trim();
        break;
      default:
        break;
    }

    return resultString;
  }
}

export {LabResultsModel};
