import type {FC} from 'react';

import styles from './vaccination-form-element.module.scss';
import type {IVaccinationFormElement} from '../vaccination-form.component';
import {VaccinationFormElementLabel} from '../form-element-label';
import {VaccinationFormElementInput} from '../form-element-input';
import {useFormContext} from 'react-hook-form';

const VaccinationFormElement: FC<{
  element: IVaccinationFormElement;
}> = (props) => {
  const {watch} = useFormContext();

  return (
    <div className={styles.root}>
      <VaccinationFormElementLabel
        value={watch(props.element.name)}
        label={props.element.label}
        noMessage={props.element.noScreenMessage}
        yesMessage={props.element.yesScreenMessage}
        tipMessage={props.element.tipMessage}
        isUploadLinkNeeded={props.element.isUploadLinkNeeded}
      />
      <VaccinationFormElementInput element={props.element} />
    </div>
  );
};

export {VaccinationFormElement};
