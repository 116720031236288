import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import {DailyTaskActivityType, PredefinedTasks} from '../../../enums';
import type {IFileStorageService} from '../../../file-storage';
import {FILE_STORAGE_SERVICE} from '../../../file-storage';
import type {FileStorageModel} from '../../../file-storage/model';
import type {INotifierService} from '../../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../../notifier';
import type {ICourseService} from '../../../patient-course/service';
import {COURSE_SERVICE} from '../../../patient-course/service';
import type {IPreVisitService} from '../../../pre-visit';
import {PREVISIT_SERVICE} from '../../../pre-visit';
import type {IWssService, SocketData} from '../../../utils/wss';
import {WSS_SERVICE} from '../../../utils/wss';
import type {TaskSocketDto} from '../../dto';
import {TaskModel, TaskModelFactory} from '../../model';
import type {ITaskRepository} from '../../repositoty';
import {TASK_REPOSITORY} from '../../repositoty';
import type {ITaskService} from './task.service.interface';

@injectable()
class TaskService implements ITaskService {
  constructor(
    @inject(COURSE_SERVICE) private readonly _courseService: ICourseService,
    @inject(FILE_STORAGE_SERVICE) private readonly _fileService: IFileStorageService,
    @inject(NOTIFIER_SERVICE) private readonly _notifier: INotifierService,
    @inject(PREVISIT_SERVICE) private readonly _preVisit: IPreVisitService,
    @inject(TASK_REPOSITORY) private readonly _repo: ITaskRepository,
    @inject(WSS_SERVICE) private readonly _wss: IWssService,
  ) {
    makeAutoObservable(this);
  }

  private _active: TaskModel[] = [];

  get activeTasks(): TaskModel[] {
    return this._active.filter((task) => task.dailyTaskActivityType === DailyTaskActivityType.General);
  }

  get lessons(): TaskModel[] {
    return this._active.filter((task) => task.dailyTaskActivityType === DailyTaskActivityType.Lesson);
  }

  get completeTasks(): readonly TaskModel[] {
    return this.activeTasks.filter((task) => task.complete);
  }

  get incompleteTasks(): readonly TaskModel[] {
    return this.activeTasks.filter((task) => !task.complete);
  }

  get isSelfEfficacyTaskIncomplete(): boolean {
    return this._active.some((task) => task.patientDailyTaskId === PredefinedTasks.SelfEfficacy);
  }

  private static isTaskSocketData(data: SocketData): data is TaskSocketDto {
    return (data as TaskSocketDto)?.patientDailyTaskActivityIntervalId !== undefined;
  }

  async load(dailyTaskActivityType?: DailyTaskActivityType): Promise<void> {
    const lessons = await this._repo
      .list({dailyTaskActivityType})
      .then((data) => data)
      .catch(console.error)
      .finally(() => {
        this._courseService.loadCourses();
      });

    const lessonsWithImage: TaskModel[] = [];

    if (lessons) {
      for (const lesson of lessons) {
        if (lesson.assessmentThumbnailStaticPath) {
          const fetchedImage = await this._fileService.get(lesson.assessmentThumbnailStaticPath);
          lessonsWithImage.push(
            new TaskModel(
              lesson.id,
              lesson.title,
              lesson.category,
              lesson.dateStart,
              lesson.dateEnd,
              lesson.doneDate,
              lesson.complete,
              lesson.patientDailyTaskId,
              lesson.courseLink,
              lesson.dailyTaskActivityType,
              lesson.beginDate,
              lesson.firstCompletionDate,
              lesson.isFavoriteLesson,
              lesson.lessonDisplayTitle,
              lesson.lessonThumbnailObjectFileId,
              fetchedImage,
              null,
              lesson.behaviorType,
              lesson.behaviorTypeName,
              lesson.patientCourseId,
              lesson.patientCourseName,
              lesson.categoryId,
              lesson.courseThumbnailObjectFileId,
              null,
              lesson.skillId,
            ),
          );
        } else if (lesson.lessonThumbnailObjectFileId || lesson.courseThumbnailObjectFileId) {
          let lessonImage: FileStorageModel | null = null;
          if (lesson.lessonThumbnailObjectFileId) {
            lessonImage = await this._fileService.get(lesson.lessonThumbnailObjectFileId);
          }
          let courseImage: FileStorageModel | null = null;
          if (lesson.courseThumbnailObjectFileId) {
            courseImage = await this._fileService.get(lesson.courseThumbnailObjectFileId);
          }
          lessonsWithImage.push(
            new TaskModel(
              lesson.id,
              lesson.title,
              lesson.category,
              lesson.dateStart,
              lesson.dateEnd,
              lesson.doneDate,
              lesson.complete,
              lesson.patientDailyTaskId,
              lesson.courseLink,
              lesson.dailyTaskActivityType,
              lesson.beginDate,
              lesson.firstCompletionDate,
              lesson.isFavoriteLesson,
              lesson.lessonDisplayTitle,
              lesson.lessonThumbnailObjectFileId,
              lessonImage,
              null,
              lesson.behaviorType,
              lesson.behaviorTypeName,
              lesson.patientCourseId,
              lesson.patientCourseName,
              lesson.categoryId,
              lesson.courseThumbnailObjectFileId,
              courseImage,
              lesson.skillId,
            ),
          );
        } else {
          lessonsWithImage.push(lesson);
        }
      }
      this._active = lessonsWithImage;
    }
  }

  async complete(taskId: number): Promise<void> {
    const task = this._active.find((task) => task.id === taskId);

    if (task) {
      await this._repo
        .save(task)
        .then(() => {
          this._active = this._active.map((task) => {
            if (task.id === taskId) {
              task.complete = true;
            }

            return task;
          });
        })
        .catch(console.error)
        .finally(() => {
          this._courseService.loadCourses();
        });
    }
  }

  async completeAndRefresh(taskId: number, begin = false): Promise<void> {
    const task = this._active.find((task) => task.id === taskId);

    if (task && !task.complete) {
      await this._repo
        .save(task, begin)
        .then(() => this.load())
        .catch(console.error);
    }
  }

  async begin(taskId: number): Promise<void> {
    const task = this._active.find((task) => task.id === taskId);

    if (task) {
      await this._repo
        .save(task, true)
        .catch(console.error)
        .finally(() => {
          this._courseService.loadCourses();
        });
    }
  }

  connectWss(): void {
    this._wss.subscribe('PatientDailyTaskAdded', (response) => {
      if (
        TaskService.isTaskSocketData(response) &&
        !this._active.some((task) => task.id === response.patientDailyTaskActivityIntervalId)
      ) {
        this._active.push(TaskModelFactory.fromSocketDto(response));
      }
    });

    this._wss.subscribe('PatientDailyTaskUpdated', (response) => {
      if (TaskService.isTaskSocketData(response)) {
        this._active = this._active.map((task) => {
          if (task.id === response.patientDailyTaskActivityIntervalId) {
            const newBeginDate = response.beginDate ? new Date(response.beginDate) : null;
            const newDoneDate = response.doneDate ? new Date(response.doneDate) : null;

            // Update beginDate if different
            if (task.beginDate?.getTime() !== newBeginDate?.getTime()) {
              task.beginDate = newBeginDate;
            }

            // Update doneDate and complete if different
            if (task.doneDate?.getTime() !== newDoneDate?.getTime()) {
              task.doneDate = newDoneDate;
              task.complete = Boolean(newDoneDate);
            }
          }

          return task;
        });
      }
    });
  }

  showPreVisitTaskBanner(): void {
    if (
      this._preVisit.preVisitInfo.isSymptomTrackingComplete ||
      this._preVisit.preVisitInfo.isWeeklyWellnessReportComplete
    ) {
      this._notifier.notify({
        text: 'Good job on starting to prepare for your GI visit. In order to get the most out of the visit, please be sure to update your other open items.',
        title: 'Success',
        type: NotifyMessageTypeEnum.Success,
      });
    } else {
      this._notifier.notify({
        text: 'In order to get the most out of your upcoming GI visit, please be sure to update your open items.',
        title: 'Success',
        type: NotifyMessageTypeEnum.Success,
      });
    }
  }

  isTaskComplete(taskId: PredefinedTasks): boolean {
    const task = this._active.find((task) => task.patientDailyTaskId === taskId);

    return task?.complete ?? true;
  }

  async setFavoriteLesson(taskId: number, isFavoriteLesson: boolean): Promise<void> {
    const task = this._active.find((task) => task.id === taskId);

    if (task) {
      await this._repo
        .setFavoriteLesson(task, isFavoriteLesson)
        .then(() => this.load())
        .catch(console.error);
    }
  }
}

export {TaskService};
