import {useCallback, useEffect, useState} from 'react';
import * as workerTimers from 'worker-timers';

const DEFAULT_MINUTES = 1;
const DEFAULT_SECONDS = 0;

const useCountdownTimer = (min?: number, sec?: number) => {
  const [minutes, setMinutes] = useState<number>(min ?? DEFAULT_MINUTES);
  const [seconds, setSeconds] = useState<number>(sec ?? DEFAULT_SECONDS);
  const [isDuring, setDuring] = useState<boolean>(true);

  const updateTime = useCallback(() => {
    if (minutes === 0 && seconds === 0) {
      setDuring(false);
    } else {
      if (seconds === 0) {
        setMinutes((minutes) => minutes - 1);
        setSeconds(59);
      } else {
        setSeconds((seconds) => seconds - 1);
      }
    }
  }, [minutes, seconds]);

  useEffect(() => {
    const timer = workerTimers.setTimeout(updateTime, 1000);

    return () => workerTimers.clearTimeout(timer);
  }, [minutes, updateTime]);

  const timer = ((): string => {
    if (min) {
      const minutesString = String(minutes).padStart(2, '0');
      const secondsString = String(seconds).padStart(2, '0');

      return `${minutesString}:${secondsString}`;
    }

    return String(seconds);
  })();

  const reset = (minutes: number = min ?? DEFAULT_SECONDS, seconds: number = sec ?? DEFAULT_SECONDS): void => {
    setDuring(true);
    setMinutes(minutes);
    setSeconds(seconds);
  };

  return [timer, reset, isDuring] as const;
};

export {useCountdownTimer};
