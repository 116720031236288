import type {TimeZone} from '../../../../profile-settings';
import {DEFAULT_LANGUAGE_ID} from '../../../../helpers';

class AccountSetupFormDto {
  constructor(
    public firstName = '',
    public lastName = '',
    public dateOfBirth = '',
    public email = '',
    public phone = '',
    public alternatePhone = '',
    public country = 0,
    public zipCode = '',
    public state = '',
    public city = '',
    public address = '',
    public terms = false,
    public timeZone: TimeZone | null = null,
    public language = DEFAULT_LANGUAGE_ID,
    public otherLanguage = '',
    public isNeedInterpreter = false,
    public userName = '',
  ) {}
}

export {AccountSetupFormDto};
