import {NumericalIndicator} from '../../../../components/numerical-indicator/numerical-indicator.component';
import type {IItemCoordinates} from '../../../model';
import styles from './leaf.module.scss';
import classNames from 'classnames';

export const LeafRed = ({x, y, angle = 0, title, numericalIndicator}: IItemCoordinates) => {
  // Calculate the position for the numerical indicator based on the angle
  let indicatorX = 0;
  let indicatorY = 0;

  if (angle >= -60 && angle < -30) {
    // Position for angle from -60 to -30
    indicatorX = 7; // Set appropriate X position
    indicatorY = 5; // Set appropriate Y position
  } else if (angle >= -30 && angle < 25) {
    // Position for angle from -30 to 25
    indicatorX = -12;
    indicatorY = 5;
  } else if (angle >= 25 && angle < 65) {
    // Position for angle from 25 to 65
    indicatorX = -22;
    indicatorY = -2;
  } else if (angle >= 65 && angle < 110) {
    // Position for angle from 65 to 110
    indicatorX = -17;
    indicatorY = -15;
  } else if (angle >= 110 && angle < 150) {
    // Position for angle from 110 to 150
    indicatorX = -10;
    indicatorY = -33;
  } else if (angle >= 150 && angle < 200) {
    // Position for angle from 150 to 200
    indicatorX = 12;
    indicatorY = -33;
  } else if (angle >= 200 && angle < 240) {
    // Position for angle from 200 to 240
    indicatorX = 27;
    indicatorY = -25;
  } else if (angle >= 240 && angle < 280) {
    // Position for angle from 240 to 280
    indicatorX = 27;
    indicatorY = 0;
  } else if (angle >= 280 && angle <= 320) {
    // Position for angle from 280 to 320
    indicatorX = 17;
    indicatorY = 7;
  }

  return (
    <svg data-testid="leaf-red" x={x - 42} y={y - 27} viewBox="-40 -40 140 140" width="100" height="100">
      <title>{title}</title>
      <path
        transform={`rotate(${angle}, 18, 0)`}
        className={classNames(styles.leaf, styles.red)}
        d="m17.7,9.71V.22c0-.12-.1-.22-.22-.22h0C7.83,0,0,7.83,0,17.48v9.49c0,.12.1.22.22.22H.22c9.66,0,17.48-7.83,17.48-17.48Z"
      />

      <NumericalIndicator numericalIndicator={numericalIndicator} x={indicatorX} y={indicatorY} />
    </svg>
  );
};

export const LeafYellow = ({x, y, angle = 0, title = 'End of skill', numericalIndicator}: IItemCoordinates) => {
  // Calculate the position for the numerical indicator based on the angle
  let indicatorX = 0;
  let indicatorY = 0;

  if (angle >= -60 && angle < -30) {
    // Position for angle from -60 to -30
    indicatorX = 7; // Set appropriate X position
    indicatorY = 10; // Set appropriate Y position
  } else if (angle >= -30 && angle < 25) {
    // Position for angle from -30 to 25
    indicatorX = -7;
    indicatorY = 10;
  } else if (angle >= 25 && angle < 65) {
    // Position for angle from 25 to 65
    indicatorX = -22;
    indicatorY = -2;
  } else if (angle >= 65 && angle < 110) {
    // Position for angle from 65 to 110
    indicatorX = -17;
    indicatorY = -20;
  } else if (angle >= 110 && angle < 150) {
    // Position for angle from 110 to 150
    indicatorX = -3;
    indicatorY = -33;
  } else if (angle >= 150 && angle < 200) {
    // Position for angle from 150 to 200
    indicatorX = 18;
    indicatorY = -33;
  } else if (angle >= 200 && angle < 240) {
    // Position for angle from 200 to 240
    indicatorX = 27;
    indicatorY = -20;
  } else if (angle >= 240 && angle < 280) {
    // Position for angle from 240 to 280
    indicatorX = 27;
    indicatorY = 4;
  } else if (angle >= 280 && angle <= 320) {
    // Position for angle from 280 to 320
    indicatorX = 17;
    indicatorY = 7;
  }

  return (
    <svg data-testid="leaf-yellow" x={x - 42} y={y - 27} viewBox="-40 -40 140 140" width="100" height="100">
      <title>{title}</title>
      <path
        transform={`rotate(${angle}, 18, 0)`}
        className={classNames(styles.leaf, styles.yellow)}
        d="m17.7,9.71V.22c0-.12-.1-.22-.22-.22h0C7.83,0,0,7.83,0,17.48v9.49c0,.12.1.22.22.22H.22c9.66,0,17.48-7.83,17.48-17.48Z"
      />

      <NumericalIndicator numericalIndicator={numericalIndicator} x={indicatorX} y={indicatorY} />
    </svg>
  );
};
