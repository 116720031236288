import {MessageThreadStatus} from './message-thread-status.enum';
import type {MessageModel} from './message.model';
import {MessageThreadRecipientModel} from './message-thread-recipient.model';

class MessageThreadModel {
  constructor(
    public id = -1,
    public recipient = new MessageThreadRecipientModel(),
    public unreadMessageCount = 0,
    public status = MessageThreadStatus.Read,
    public previewText = '',
    public lastMessageDate: Date | null = null,
    public messages: MessageModel[] = [],
    public readonly = false,
  ) {}

  get hasNewMessages(): boolean {
    return this.unreadMessageCount > 0;
  }
}

export {MessageThreadModel};
