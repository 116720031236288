import {ContainerModule} from 'inversify';
import {THRIVE_SKILL_SERVICE, ThriveSkillService} from '../service';
import type {IThriveSkillService} from '../service';
import {THRIVE_SKILL_REPOSITORY, ThriveSkillRepository} from '../repository';
import type {IThriveSkillRepository} from '../repository';

const ThriveSkillModule = new ContainerModule((bind) => {
  bind<IThriveSkillService>(THRIVE_SKILL_SERVICE).to(ThriveSkillService).inSingletonScope();

  bind<IThriveSkillRepository>(THRIVE_SKILL_REPOSITORY).to(ThriveSkillRepository).inSingletonScope();
});

export {ThriveSkillModule};
