import classNames from 'classnames';
import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useEffect, useState} from 'react';
import {Button} from '../../../../../components';
import {useInjection} from '../../../../../ioc';
import type {IGoal} from '../../../../interfaces';
import type {IGoalService} from '../../../../service';
import {GOAL_SERVICE} from '../../../../service';
import goalStyles from '../goal-steps.module.scss';
import styles from './goal-step-1.module.scss';

interface IStep1Prop {
  title: string;

  onNext?: () => void;
}

interface IServiceProps {
  goalService: IGoalService;
}

const GoalStep1: FC<IStep1Prop & IServiceProps> = ({title, goalService, onNext}) => {
  const [goal, setGoal] = useState<IGoal>();

  useEffect(() => {
    setGoal(goalService.goal);
  }, [goalService.goal]);

  return (
    <div className={goalStyles.modalContent}>
      <div className={goalStyles.modalHeader}>
        <h2>{title}</h2>
      </div>
      <div className={styles.body}>
        <p>{goal?.description}</p>
      </div>
      <div className={styles.footer}>
        <Button
          type="button"
          className={classNames(goalStyles.button, goalStyles.darkTextColor)}
          onClick={onNext}
          primary
          radius>
          Begin
        </Button>
      </div>
    </div>
  );
};

const GoalStep1Observer = observer(GoalStep1);
const GoalStep1Injected: FC<IStep1Prop> = (props) => (
  <GoalStep1Observer {...props} goalService={useInjection(GOAL_SERVICE)} />
);

export {GoalStep1Injected as GoalStep1, GoalStep1Observer};
