import classNames from 'classnames';
import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Button, Input, InputNumber, Radio} from '../../../../../components';
import {InputNumberTypes} from '../../../../../components/input-number/input-number.component';
import {mapWorksheetFormData} from '../../../../../goal-journal/helper';
import {REQUIRED_FIELD_ERROR} from '../../../../../helpers';
import {useInjection} from '../../../../../ioc';
import type {IGoalDetails, IGoalScore, IGoalWorksheetForm} from '../../../../interfaces';
import {EMotivationType} from '../../../../interfaces';
import type {IGoalService} from '../../../../service/goal/goal.service.interface';
import {GOAL_SERVICE} from '../../../../service/goal/goal.service.provider';
import goalStyles from '../goal-steps.module.scss';
import styles from './goal-step-3.module.scss';

interface IStep3Prop {
  courseTypeId?: number;
  patientCourseId?: number;
  isGoalJournal?: boolean;
  showPrevButton?: boolean;

  onNext?: () => void;
  onPrevious?: () => void;
  onClose?: () => void;
  onSubmit?: () => void;
}

interface IServiceProps {
  goalService: IGoalService;
}

const RADIO_OPTIONS = [
  {
    id: 'goal_carrot',
    value: EMotivationType.CARROT,
  },
  {
    id: 'goal_stick',
    value: EMotivationType.STICK,
  },
];

const GoalStep3: FC<IStep3Prop & IServiceProps> = ({
  courseTypeId,
  patientCourseId,
  isGoalJournal,
  goalService,
  showPrevButton = false,
  onNext,
  onPrevious,
  onSubmit,
}) => {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [totalScore, setTotalScore] = useState(0);

  const defaultValues: IGoalWorksheetForm = useMemo(
    () => ({
      adaptive: '',
      adaptiveScore: 0,
      behaviorTypeId: 0,
      effortLevel: 0,
      goal: '',
      meaningful: '',
      meaningfulScore: 0,
      patientCourseId: 0,
      realistic: '',
      realisticScore: 0,
      specific: '',
      specificScore: 0,
      timeBound: '',
      timeBoundScore: 0,
      totalScore: 0,
      motivation: undefined,
    }),
    [],
  );

  const {control, handleSubmit, watch, setValue} = useForm<IGoalWorksheetForm>({
    defaultValues,
  });

  const watchScores = watch([
    'adaptiveScore',
    'meaningfulScore',
    'realisticScore',
    'specificScore',
    'timeBoundScore',
    'motivation',
  ]);

  const criteria: Partial<Record<keyof IGoalDetails, string>> = {
    specific: 'Specific',
    meaningful: 'Meaningful',
    adaptive: 'Adaptive',
    realistic: 'Realistic',
    timeBound: 'Time-Bound',
  };

  const criteriaScore: Record<keyof IGoalScore, string> = {
    adaptiveScore: 'Specific',
    meaningfulScore: 'Meaningful',
    realisticScore: 'Adaptive',
    specificScore: 'Realistic',
    timeBoundScore: 'Time-Bound',
  };

  const openReviewModal = () => {
    if (goalService.worksheet.goalWorksheetId) {
      goalService.reviewWorkflow = {
        courseId: goalService.worksheet.behaviorTypeId,
        title: goalService.worksheet.goal,
        worksheetId: goalService.worksheet.goalWorksheetId,
      };
    }
  };

  const handleNext = useCallback(
    async (data: IGoalWorksheetForm) => {
      const innerCourseTypeId = isGoalJournal ? goalService.worksheet.behaviorTypeId : courseTypeId;
      const innerPatientCourseTyped = isGoalJournal ? goalService.worksheet.patientCourseId : patientCourseId;

      if (submitDisabled || !innerPatientCourseTyped) {
        return;
      }

      const worksheetData = {
        ...data,
        totalScore,
        patientCourseId: innerPatientCourseTyped,
        ...(innerCourseTypeId && {behaviorTypeId: innerCourseTypeId}),
      };

      const success = isGoalJournal
        ? await goalService.updateWorksheet(worksheetData)
        : await goalService.createWorksheet(worksheetData);

      if (success) {
        onSubmit?.();
        onNext?.();
      }
    },
    [submitDisabled, courseTypeId, totalScore, patientCourseId, isGoalJournal, goalService, onSubmit, onNext],
  );

  const firstAction = isGoalJournal ? (
    <span className={styles.link} onClick={openReviewModal}>
      Review Goal
    </span>
  ) : showPrevButton ? (
    <Button
      type="button"
      className={classNames(goalStyles.button, goalStyles.darkTextColor)}
      onClick={onPrevious}
      primary
      radius>
      Previous
    </Button>
  ) : (
    <div />
  );

  // Calculate total score whenever any score changes
  const calculateTotalScore = useCallback(() => {
    const sumOfScores = Object.values(watchScores as unknown as Record<string, number>)
      .slice(0, 5) // Get the first 5 values, which are the scores
      .reduce((acc, value) => acc + (Number(value) || 0), 0); // Calculate the sum of the scores

    const isStickSelected = watchScores.motivation === 'stick';

    // Apply rules for total score calculation
    let calculatedTotalScore = sumOfScores;
    if (isStickSelected) {
      calculatedTotalScore -= 5;
    }

    // Update total score state
    setTotalScore(calculatedTotalScore);

    // Check if the user can set the goal
    setSubmitDisabled(!(calculatedTotalScore > 9));
  }, [watchScores]);

  // Watch all fields in the form
  const formValues = watch();

  // This effect runs whenever a form value changes.
  useEffect(() => {
    const allFieldsFilled = Object.values(formValues).every((value) => {
      if (value === undefined) {
        return false;
      }

      return typeof value === 'number' ? value > 0 : value.trim() !== '';
    });

    if (allFieldsFilled) {
      calculateTotalScore();
    } else {
      setTotalScore(0);
      setSubmitDisabled(true);
    }
  }, [formValues, calculateTotalScore]);

  useEffect(() => {
    const worksheetData = goalService.worksheet;

    if (worksheetData && isGoalJournal) {
      const worksheetFormData = mapWorksheetFormData(worksheetData);

      Object.keys(worksheetFormData).forEach((key) => {
        const indexKey = key as keyof IGoalWorksheetForm;
        setValue(indexKey, worksheetFormData[indexKey]);
      });
    }
  }, [setValue, goalService.worksheet, isGoalJournal]);

  return (
    <div className={goalStyles.modalContent}>
      <div className={goalStyles.modalHeader}>
        <h2>Goal Worksheet</h2>
        <span className={styles.motivationLabel}>Select one:</span>
      </div>
      <div className={styles.body}>
        <div className={styles.topInputGroup}>
          <div className={classNames(styles.goalInput, styles.inputGroup)}>
            <label>Goal:</label>
            <Controller
              name="goal"
              control={control}
              render={({onChange, name, value}) => (
                <Input name={name} className={goalStyles.fullWidth} value={value} onValueChanged={onChange} />
              )}
            />
          </div>
          <div className={styles.motivationGroup}>
            <Controller
              control={control}
              defaultValue={undefined}
              name="motivation"
              rules={{required: REQUIRED_FIELD_ERROR}}
              render={({value, name, onChange}) => (
                <>
                  {RADIO_OPTIONS.map((option) => (
                    <div className={styles.radioInput} key={option.id}>
                      <Radio
                        key={option.id}
                        id={option.id}
                        name={name}
                        label={option.value}
                        value={option.value === value}
                        onChange={() => onChange(option.value)}
                      />
                    </div>
                  ))}
                </>
              )}
            />
          </div>
        </div>
        <div className={styles.criteriaContainer}>
          <div className={styles.criteriaColumn}>
            {Object.entries(criteria).map(([key, label]) => (
              <div key={key} className={styles.inputGroup}>
                <label className={styles.inputDescription} htmlFor={key}>
                  {label}
                </label>
                <Controller
                  name={key}
                  control={control}
                  rules={{required: REQUIRED_FIELD_ERROR}}
                  render={({onChange, name, value}) => (
                    <Input className={styles.inputDetails} name={name} value={value} onValueChanged={onChange} />
                  )}
                />
              </div>
            ))}
          </div>
          <div className={styles.criteriaColumnScore}>
            {Object.entries(criteriaScore).map(([key, label]) => (
              <div key={key} className={styles.inputGroupsScore}>
                <label htmlFor={key}>Score 1-5:</label>
                <Controller
                  name={key}
                  control={control}
                  rules={{required: REQUIRED_FIELD_ERROR}}
                  render={({onChange, name, value}) => (
                    <InputNumber
                      className={styles.inputScore}
                      name={name}
                      minValue={1}
                      maxValue={5}
                      value={value || ''}
                      onChange={onChange}
                      numberInputType={InputNumberTypes.Integer}
                    />
                  )}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.totalScore}>Total: {totalScore || '_______'}</div>
        <div className={styles.scoreText}>
          <span className={goalStyles.requiredSign}>*</span>Score:
          <b> 5-9</b> You may want to rethink SMART Goal |<b> 10-15</b> Stretch Goal |<b> 16-21</b> Mid-Range Goal |{' '}
          <b> 22-25</b> Easy Reach
        </div>
        <div className={isGoalJournal ? styles.journalFooter : goalStyles.modalFooter}>
          {firstAction}
          <Button
            disabled={submitDisabled}
            type="button"
            className={styles.setButton}
            onClick={handleSubmit(handleNext)}
            radius>
            Set it
          </Button>
        </div>
      </div>
    </div>
  );
};

const GoalStep3Observer = observer(GoalStep3);
const GoalStep3Injected: FC<IStep3Prop> = (props) => (
  <GoalStep3Observer {...props} goalService={useInjection(GOAL_SERVICE)} />
);

export {GoalStep3Injected as GoalStep3, GoalStep3Observer};
export type {IGoalService};
