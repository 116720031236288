import type {FC} from 'react';
import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import {Avatar} from '../../../../components/avatar';
import {useHistory} from 'react-router-dom';
import {routes} from '../../../../routing';
import {useInjection} from '../../../../ioc';
import {Icon, IconType} from '../../../../components';
import type {IMessageService} from '../../../../message';
import {MESSAGE_SERVICE} from '../../../../message';
import {useQuery} from '../../../../hooks';
import type {IAccountService} from '../../../../account';
import {ACCOUNT_SERVICE} from '../../../../account';
import './datepicker.css';
import styles from './right-panel.module.scss';

interface IProps {
  className?: string;
  onClose: () => void;
}

interface IService {
  messages: IMessageService;
  account: IAccountService;
}

const RightPanel: FC<IProps & IService> = ({className, onClose, account, messages}) => {
  const history = useHistory();

  const {id: providerId} = useQuery<{id: string}>();

  const selectThread = useCallback(
    async (threadId: number) => {
      if (!providerId || messages.selectedThread?.recipient?.id !== threadId) {
        history.replace(`${routes.home}${routes.messages}?id=${threadId}`);

        if (account.id && account.team.length) {
          await messages.loadThreadsPreview(account.team, account.id);
        }

        await messages.selectThread({recipientId: threadId});
      }
    },
    [account.id, account.team, history, messages, providerId],
  );

  const trellusTeamMembers = useMemo(
    () =>
      account.team
        .slice(0)
        ?.filter((item) => item.openForCommunicationWithPatient)
        .map((item, index) => {
          const name = `${item.firstName} ${item.lastName}`;
          const type = item.providerType;

          const navigateToTriadMemberProfile = () => {
            history.push(`${routes.home}${routes.trellusTeamMemberProfile}?id=${item.providerId}`);
          };

          const onSelectThread = () => selectThread(item.providerId);

          return (
            <div key={index} className={styles.trellusTeamItem}>
              <Avatar
                src={item.avatar ? `data:image/png;base64,${item.avatar}` : ''}
                size={48}
                alt={name}
                className={styles.avatar}
                onClick={navigateToTriadMemberProfile}
              />
              <div className={styles.trellusTeamItemInfo}>
                <div className={styles.trellusTeamItemName}>{name}</div>
                <div className={styles.trellusTeamItemTitle}>{type}</div>
              </div>
              {!item.isGiProvider && (
                <button
                  className={styles.trellusTeamItemMessage}
                  onClick={onSelectThread}
                  data-testid={'message-item-button'}>
                  <Icon className={styles.trellusTeamItemMessageIcon} type={IconType.Send} />
                </button>
              )}
            </div>
          );
        }),
    [account.team, history, selectThread],
  );

  return (
    <aside className={className} onMouseLeave={onClose}>
      <div className={styles.asideWrap}>
        {!!trellusTeamMembers?.length && (
          <div>
            <div className={styles.title}>My Elevate Team</div>
            <div className={styles.trellusTeamContent}>{trellusTeamMembers}</div>
          </div>
        )}
      </div>
    </aside>
  );
};

const ObservableRightPanel = observer(RightPanel);

const InjectedRightPanel: FC<IProps> = (props) => (
  <ObservableRightPanel messages={useInjection(MESSAGE_SERVICE)} account={useInjection(ACCOUNT_SERVICE)} {...props} />
);

export {InjectedRightPanel as RightPanel, ObservableRightPanel};
