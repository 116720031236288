import {DocumentsModel} from './documents.model';
import type {IDocumentsGetResponseDto} from '../repository';
import type {IDocumentsFormDto} from '../components/documents-modal/documents-form.dto';
import {DocumentDetailsModel} from './document-details.model';
import {shiftTimezone} from '../../helpers';

class DocumentsModelFactory {
  static fromFormDto(dto: IDocumentsFormDto, patientId: number, fileId: string): DocumentsModel {
    return new DocumentsModel(
      -1,
      patientId,
      fileId,
      '',
      null,
      dto.date ? shiftTimezone(new Date(dto.date)) : null,
      dto.type,
      dto.otherNote,
      '',
      new DocumentDetailsModel(
        fileId,
        -1,
        dto.date ? shiftTimezone(new Date(dto.date)) : null,
        dto.note,
        dto.type,
        dto.otherNote,
        '',
        dto.cbc,
        dto.bmp,
        dto.cmp,
        dto.liverFunction,
        dto.lipidPanel,
        dto.drugLevel,
        dto.renalFunction,
        dto.urinalysis,
        dto.tuberculosis,
      ),
    );
  }

  static fromGetResponseDto(dto: IDocumentsGetResponseDto): DocumentsModel {
    return new DocumentsModel(
      dto.labResultId,
      dto.patientId,
      dto.objectFileId,
      dto.objectFileName,
      shiftTimezone(new Date(dto.createdDate)),
      shiftTimezone(new Date(dto.documentUploadDate)),
      dto.documentType,
      dto.otherNote,
      dto.mimeType,
      null,
      dto.createdByFullName,
      dto.createdByTitle,
      dto.createdByType,
    );
  }
}

export {DocumentsModelFactory};
