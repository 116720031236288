import type {RichText} from '../../types';

const sliceString = (str: string, symbol: string) => {
  const sliceTo = str.indexOf(symbol) + 1;

  return str.slice(0, sliceTo);
};

const parseRichText = (text: string): RichText => {
  const result: RichText = [];

  if (!text.includes('${')) {
    result.push(text);
  } else {
    while (text.includes('${')) {
      const firstOpen = text.indexOf('${');
      const lastOpen = text.indexOf('}}');

      const textBeforeLink = text.substring(0, firstOpen);

      if (textBeforeLink) {
        result.push(textBeforeLink);
      }

      const linkObject = JSON.parse(text.substring(firstOpen + 2, lastOpen + 1));
      if (linkObject) {
        result.push({
          href: linkObject.routeId,
          title: linkObject.text,
          params: linkObject.params,
        });
      }

      text = text.substring(lastOpen + 2);

      if (!text.includes('${')) {
        result.push(text);
      }
    }
  }

  return result.filter((i) => !!i);
};

export {sliceString, parseRichText};
export type {RichText};
