import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useEffect, useState} from 'react';
import {Spinner} from '../../../components';
import {useInjection} from '../../../ioc';
import type {ITrellisService} from '../../service';
import {MAX_BOOTCAMP_LESSONS, TRELLIS_SERVICE} from '../../service';
import {Pots} from '../pot/pot.component';
import {Build} from '../trellis-build/trellis-build.component';
import {Vine} from '../vine/vine.component';
import styles from './trellis.module.scss';

interface ITrellisVisualizationProps {
  trellisService: ITrellisService;
}

const TrellisVisualization = ({trellisService}: ITrellisVisualizationProps) => {
  const [numberOfCurrentStage, setNumberOfCurrentStage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await trellisService.fetchMatrixData();
        trellisService.processVineData();
        await trellisService.loadPotsFlowerData();
        await trellisService.fetchBootcampLessonsData();

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [trellisService]);

  useEffect(() => {
    setNumberOfCurrentStage(
      trellisService.startDate ? MAX_BOOTCAMP_LESSONS : trellisService.completedBootcampLessonsCount,
    );
  }, [trellisService.startDate, trellisService.completedBootcampLessonsCount]);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  return (
    <svg viewBox="0 0 1024 567" className={styles.trellisVisualization}>
      <Build stage={numberOfCurrentStage} />
      {numberOfCurrentStage > 4 && <Vine />}
      <Pots />
    </svg>
  );
};

const TrellisVisualizationObserver = observer(TrellisVisualization);
const TrellisVisualizationInjected: FC = () => (
  <TrellisVisualizationObserver trellisService={useInjection(TRELLIS_SERVICE)} />
);
export {TrellisVisualizationInjected as TrellisVisualization};
