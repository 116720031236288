const teamTitleAbbreviation = new Map([
  ['MD', 'MD'],
  ['DO', 'DO'],
  ['NP', 'NP'],
  ['RN', 'RN'],
  ['RD', 'RD'],
  ['RDN', 'RDN'],
  ['PA', 'PA'],
  ['LMSW', 'RS'],
  ['LCSW', 'RS'],
  ['MA', 'MA'],
]);

export {teamTitleAbbreviation};
