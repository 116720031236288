import type {FC} from 'react';
import {Card, CardHeader, Modal} from '../../../components';
import {BodyMassIndexForm} from '../../form';
import type {IOption} from '../../../types';

interface IBodyMassIndexModalProps {
  units: IOption[];
  onSubmit: (data: any) => void;
  onClose: () => void;
}

const BodyMassIndexModal: FC<IBodyMassIndexModalProps> = ({units, onClose, onSubmit}) => (
  <Modal onClose={onClose}>
    <Card>
      <CardHeader title={'Add new weight'} closable noBorder onClose={onClose} />
      <BodyMassIndexForm units={units} onSubmit={onSubmit} onClose={onClose} />
    </Card>
  </Modal>
);

export {BodyMassIndexModal};
