import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {ILifeQualityRepository} from '../repository';
import {LIFE_QUALITY_REPOSITORY, LifeQualityRepository} from '../repository';
import type {ILifeQualityService} from '../service';
import {LIFE_QUALITY_SERVICE, LifeQualityService} from '../service';

const LifeQualityModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<ILifeQualityRepository>(LIFE_QUALITY_REPOSITORY).to(LifeQualityRepository).inSingletonScope();

  bind<ILifeQualityService>(LIFE_QUALITY_SERVICE).to(LifeQualityService).inSingletonScope();
});

export {LifeQualityModule};
