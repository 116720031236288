import type {SurveyModel} from '../../../model';
import {SurveyStatus, SurveyType} from '../../../model';
import type {ISurveyResponse} from './survey-post.dto';
import {SurveyPostDto} from './survey-post.dto';
import {getSurveyResponse} from '../../helpers';
import {formatFullDateTime} from '../../../../helpers';

class SurveyPostDtoFactory {
  private static HEALTHCARE_TRACKING_FORM_TYPE = 16;
  private static COMBINED_GWB_IBS_FORM_TYPE = 80;
  private static SYMPTOM_TRACKING_IBS_FORM_TYPE = 73;

  static fromModel(model: SurveyModel): SurveyPostDto {
    const survey = model.surveys.find(
      (survey) =>
        survey.spec?.surveyType === model.surveyType ||
        survey.spec?.surveyType === SurveyPostDtoFactory.SYMPTOM_TRACKING_IBS_FORM_TYPE ||
        survey.spec?.surveyType === SurveyPostDtoFactory.COMBINED_GWB_IBS_FORM_TYPE,
    );

    let response: ISurveyResponse | null = null;

    const formType =
      !model.isOnboarding && model.surveyType === SurveyType.CombinedGWBHTP
        ? SurveyPostDtoFactory.HEALTHCARE_TRACKING_FORM_TYPE
        : undefined;

    if (survey && survey.spec) {
      response = getSurveyResponse(survey.spec, survey.formResponses);
    }

    if (model.patientId) {
      const postDto = new SurveyPostDto(model.patientId, response, SurveyStatus.Completed);

      if (model.date) {
        postDto.date = formatFullDateTime(model.date);
      }

      if (formType) {
        postDto.formType = formType;
      }

      return postDto;
    } else {
      throw new Error(`Can't create survey post dto without patient id`);
    }
  }
}

export {SurveyPostDtoFactory};
