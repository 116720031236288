enum UserRoleEnum {
  Admin = 0,
  Nurse = 1,
  Provider = 2,
  Patient = 3,
  Doctor = 4,
  OfficeAdmin = 5,
}

const UserRoleStrings = new Map<UserRoleEnum, string>([
  [UserRoleEnum.Admin, 'Trellus Triad member'],
  [UserRoleEnum.Patient, 'Patient'],
  [UserRoleEnum.Provider, 'GI Provider'],
  [UserRoleEnum.Nurse, 'Nurse'],
  [UserRoleEnum.Doctor, 'Doctor'],
  [UserRoleEnum.OfficeAdmin, 'Office Admin'],
]);

export {UserRoleEnum, UserRoleStrings};
