class MedicationsCourseDiscontinuePostRequestDto {
  constructor(
    public medicationCourseId = -1,
    public discontinuedDate: string | null = null,
    public reason = -1,
    public reasonOther = '',
  ) {}
}

export {MedicationsCourseDiscontinuePostRequestDto};
