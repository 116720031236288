import type {FC} from 'react';
import React, {useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import styles from './payment-information.module.scss';
import {PageLoader, Preloader, ProgressBar} from '../../../components';
import {OnBoardingLayout} from '../../../layouts';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import {useInjection} from '../../../ioc';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentInformationForm} from './payment-information-form.component';

interface IService {
  account: IAccountService;
}

declare var STRIPE_PUB_KEY: string;

const stripePromise = loadStripe(
  // eslint-disable-next-line no-template-curly-in-string
  process.env.REACT_APP_STRIPE_PUB_KEY || (STRIPE_PUB_KEY === '${STRIPE_PUB_KEY}' ? '' : STRIPE_PUB_KEY),
);

const PaymentInformationScreen: FC<IService> = ({account}) => {
  const showPreloader: false | JSX.Element = useMemo(
    () => account.isSaving && <Preloader isSpinnerShown={!account.isSaving} />,
    [account.isSaving],
  );

  useEffect(() => {
    account.checkUserPromocode();
  }, [account]);

  return (
    <OnBoardingLayout>
      <div className={styles.content}>
        <ProgressBar
          title="Your Account Setup"
          totalSteps={account.onboardingStepsCount}
          step={account.onboardingStepsCount}
        />
        {showPreloader}
        {account.isLoading && <PageLoader />}
        {!account.isLoading && (
          <>
            <div className={styles.mainTitle}>Payment Information</div>

            <Elements stripe={stripePromise}>
              <PaymentInformationForm />
            </Elements>
          </>
        )}
      </div>
    </OnBoardingLayout>
  );
};

const PaymentInformationScreenObserver = observer(PaymentInformationScreen);
const PaymentInformationScreenInjected: FC = () => (
  <PaymentInformationScreenObserver account={useInjection(ACCOUNT_SERVICE)} />
);

export {PaymentInformationScreenInjected as PaymentInformationScreen};
