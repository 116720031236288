import type {FC} from 'react';
import React, {useEffect} from 'react';
import styles from './pre-screening-layout.module.scss';
import {Footer} from '../private-layout/components';
import type {ICompanyService} from '../../company';
import {COMPANY_SERVICE} from '../../company';
import {useInjection} from '../../ioc';
import {observer} from 'mobx-react';
import type {IQuoteService} from '../../quote';
import {QUOTE_SERVICE} from '../../quote';
import type {IAuthService} from '../../utils/auth';
import {AUTH_SERVICE} from '../../utils/auth';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import {routes} from '../../routing';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';

interface IService {
  company: ICompanyService;
  quote: IQuoteService;
  auth: IAuthService;
  routing: IRoutingService;
  account: IAccountService;
}

const PreScreeningLayout: FC<IService> = ({children, auth, routing, company, account, quote}) => {
  useEffect(() => {
    if (auth) {
      const env = auth?.getEnvironment();
      if (!['DEV', 'LOCAL'].includes(env)) {
        routing.push(routes.home);
      }
    }
  }, [auth, routing]);

  useEffect(() => {
    if (account.id) {
      Promise.all([company.load(), quote.load()]);
    } else {
      account.load();
    }
  }, [account, company, quote]);

  return (
    <div className={styles.root}>
      <main className={styles.main}>{children}</main>
      <Footer
        className={styles.footer}
        contactEmail={company?.companyInfo?.contactEmail}
        contactPhone={company?.companyInfo?.contactPhone}
        facebookLink={company?.companyInfo?.facebookLink}
        twitterLink={company?.companyInfo?.twitterLink}
        instagramLink={company?.companyInfo?.instagramLink}
        linkedInLink={company?.companyInfo?.linkedInLink}
        tikTokLink={company?.companyInfo?.tikTokLink}
        youTubeLink={company?.companyInfo?.youTubeLink}
        copyRight={company?.companyInfo?.copyRight}
        appVersion={company?.companyInfo?.informationalVersion}
      />
    </div>
  );
};

const ObservablePreScreeningLayout = observer(PreScreeningLayout);
const PreScreeningLayoutInjected: FC<{children: React.ReactNode}> = (props) => (
  <ObservablePreScreeningLayout
    company={useInjection(COMPANY_SERVICE)}
    quote={useInjection(QUOTE_SERVICE)}
    auth={useInjection(AUTH_SERVICE)}
    routing={useInjection(ROUTING_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
    {...props}
  />
);

export {PreScreeningLayoutInjected as PreScreeningLayout};
