import type {ISurveyGetResponsesResponseDto} from './get-responses';
import type {ISurveyGetLastResponseInfoResponseDto} from './get-last-response';
import type {ISurveySpecGetResponseDto} from './survey-spec-get-response.dto';

class SurveyGetResponseDto {
  constructor(
    public specDto: ISurveySpecGetResponseDto | null = null,
    public responsesDto: Array<ISurveyGetResponsesResponseDto> = [],
    public lastResponseInfo: ISurveyGetLastResponseInfoResponseDto | null = null,
  ) {}
}

export {SurveyGetResponseDto};
