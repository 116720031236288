import {memo} from 'react';
import styles from '../../strengths-assessment.module.scss';
import {DateWrapper} from '../../../../components';

interface IStrengthAssessmentSubHeaderProps {
  date?: Date | null;
}

const StrengthAssessmentSubHeader = memo<IStrengthAssessmentSubHeaderProps>(({date}) => {
  const formattedLastUpdatedDate = date ? <DateWrapper date={date} /> : null;

  return formattedLastUpdatedDate ? (
    <div className={styles.subHeader}>Last updated · {formattedLastUpdatedDate}</div>
  ) : null;
});

export {StrengthAssessmentSubHeader};
export type {IStrengthAssessmentSubHeaderProps};
