import type {IQuoteGetResponseDto} from '../dto';
import {QuoteModel} from './quote.model';

class QuoteModelFabric {
  static fromGetResponseDto(dto: IQuoteGetResponseDto): QuoteModel {
    return new QuoteModel(dto.text, dto.source);
  }
}

export {QuoteModelFabric};
