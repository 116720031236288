import {AbstractException} from '../abstract.exception';
import type {AxiosError} from 'axios';

type ApiErrors = Record<string, string[]>;

interface IApiExceptionError<Errors extends ApiErrors = ApiErrors> {
  errors?: Errors;
  type: string;
  title: string;
  status: number;
  traceId: string;
}

type AxiosApiError = Required<AxiosError<IApiExceptionError>>;

class ApiException extends AbstractException {
  constructor(error: AxiosApiError) {
    if (!ApiException.isApiError(error)) {
      throw new Error('invalid api error type');
    }

    super(ApiException.name, error.response.data);
  }

  static isApiError(error: AxiosError): error is AxiosApiError {
    return (
      typeof error.response?.data === 'object' &&
      'status' in error.response?.data &&
      'title' in error.response?.data &&
      'traceId' in error.response?.data &&
      (('errors' in error.response?.data &&
        Object.values(error.response.data.errors).every(
          (i) => Array.isArray(i) && i.every((str) => typeof str === 'string'),
        )) ||
        !('errors' in error.response?.data))
    );
  }

  parseError<Errors extends ApiErrors>(): IApiExceptionError<Errors> {
    if (!this.message.length) {
      throw new Error('empty error message');
    }

    return JSON.parse(this.message);
  }

  get firstError(): string {
    const {errors, title} = this.parseError();

    return errors ? Object.values(errors)[0][0] : title;
  }
}

export {ApiException};
export type {IApiExceptionError, AxiosApiError};
