import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useInjection} from '../../../ioc';
import {getSegmentAndSubSegmentIndex, vinePathsTemplate} from '../../helper/trellis.helper';
import type {ISegmentAndSubsegmentIndexes, IVinePath} from '../../model';
import type {ITrellisService} from '../../service';
import {TRELLIS_SERVICE} from '../../service';
import {Branch} from '../items/branch/branch.component';
import {UsedFlowersMemo} from '../items/flower/flowers-memo.component';

interface IVineProps {
  trellisService: ITrellisService;
}

const Vine: FC<IVineProps> = ({trellisService}) => {
  // For positioning vines
  const trellisWidth = 1024;
  const trellisHeight = 567;
  const translateY = trellisHeight / 8; // Calculate the translation values to center the vine path

  const [vinePaths, setVinePaths] = useState<IVinePath[]>([]);

  const {startDate, vineData} = trellisService;

  const currentSegmentAndSubsegmentIndexes = useMemo<ISegmentAndSubsegmentIndexes>(
    () => getSegmentAndSubSegmentIndex(startDate),
    [startDate],
  );

  const calculateBase = (numElements: number, startingX: number, threshold = 6, movingVines = 25) => {
    if (numElements < threshold) {
      return startingX;
    }
    const difference = numElements - threshold;

    return startingX - difference * -movingVines;
  };

  useEffect(() => {
    setVinePaths(vineData);
  }, [vineData]);

  return (
    <>
      {/* Render each vine */}
      {vinePaths.map(({path, totalLength, segments, startingPositionX, reversedPath}, index) => {
        const multiplier = Math.floor(index / vinePathsTemplate.length);
        const addToStartingPosition = -245;
        const baseX = calculateBase(vinePaths.length, startingPositionX, vinePathsTemplate.length);

        // Calculate the X position for each vine
        const translateX = multiplier ? baseX + multiplier * addToStartingPosition : baseX; // First 6 vines are already aligned;

        return (
          <svg
            key={index}
            viewBox={`${translateX} ${translateY} ${trellisWidth - 320} ${trellisHeight - 320}`}
            width={trellisWidth}
            height={trellisHeight}>
            <path
              d={path}
              stroke="#52695c"
              strokeWidth="1"
              fill="none"
              strokeDasharray={`${totalLength} ${totalLength}`}
              strokeDashoffset={
                reversedPath
                  ? -totalLength + segments[currentSegmentAndSubsegmentIndexes.segmentIndex]?.length || 0
                  : totalLength - segments[currentSegmentAndSubsegmentIndexes.segmentIndex]?.length || 0
              }
            />

            {segments.map(
              (segment, segmentIndex) =>
                segmentIndex <= currentSegmentAndSubsegmentIndexes.segmentIndex &&
                segment.subSegments.map((subSegment, subSegmentIndex) =>
                  subSegment.map((branch, branchIndex) => (
                    <Branch
                      key={`${segmentIndex}-${subSegmentIndex}-${branchIndex}`}
                      index={branchIndex}
                      hasFinished={currentSegmentAndSubsegmentIndexes.hasFinished}
                      branch={branch}
                    />
                  )),
                ),
            )}
          </svg>
        );
      })}
      <UsedFlowersMemo />
    </>
  );
};

const VineScreenObserver = observer(Vine);
const VineScreenInjected: FC = () => <VineScreenObserver trellisService={useInjection(TRELLIS_SERVICE)} />;
export {VineScreenInjected as Vine};
