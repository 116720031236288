enum MetaDataType {
  Dog = 'dog',
  Cat = 'cat',
  Fish = 'fish',
  Bird = 'bird',
  Snake = 'snake',
  Hamster = 'hamster',
  OtherPets = 'pet_other',

  Mom = 'mom',
  Dad = 'dad',
  Children = 'children',
  Siblings = 'siblings',
  Grandparents = 'grandparents',
  Friends = 'friends',
  Spouse = 'spouse',
  Partner = 'partner',

  Math = 'math',
  Science = 'science',
  English = 'english',
  Art = 'Art',
  Music = 'music',
  History = 'history',
  Gym = 'gym',
  Lunch = 'lunch',

  City = 'city',
  Beach = 'beach',
  Lake = 'lake',
  ForeignCountry = 'foreign_country',
  OtherVacation = 'vacation_other',

  Black = 'black',
  Cream = 'cream',
  CreamSugar = 'cream_sgar',
  CaffeineFree = 'caffeine_free',
  OtherCoffee = 'coffee_other',
}

export {MetaDataType};
