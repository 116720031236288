import {ContainerModule} from 'inversify';
import {AUTH_SERVICE} from '../../auth';
import type {IWssConfig} from '../config';
import {WSS_CONFIG, WssConfig} from '../config';
import type {IWssService} from '../service';
import {WSS_SERVICE, WssService} from '../service';

const WssModule = new ContainerModule((bind, unbind, isBound) => {
  if (!isBound(AUTH_SERVICE)) {
    throw new Error(`${AUTH_SERVICE.toString()} is required`);
  }

  bind<IWssConfig>(WSS_CONFIG).to(WssConfig).inSingletonScope();
  bind<IWssService>(WSS_SERVICE).to(WssService).inSingletonScope();
});

export {WssModule};
