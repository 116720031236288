import {FileType} from '../../../model';

class FileStoragePostRequestDto {
  constructor(
    public content: string = '',
    public filename: string = '',
    public mimeType: string = '',
    public objectFileType: FileType = FileType.Document,
  ) {}
}

export {FileStoragePostRequestDto};
