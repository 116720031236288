import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {LifeQualityModel, LifeQualityQuestionModel} from '../model';
import {LifeQualityQuestionModelFactory} from '../model';
import type {ILifeQualityQuestionsGetResponseDto} from './dto';
import {ILifeQualitySaveRequestDto} from './dto';
import type {ILifeQualityRepository} from './life-quality.repository.interface';

@injectable()
class LifeQualityRepository implements ILifeQualityRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  find(): Promise<LifeQualityModel | null> {
    throw new NotImplementedException();
  }

  list(): Promise<LifeQualityModel[]> {
    throw new NotImplementedException();
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async save(model: LifeQualityModel): Promise<void> {
    const postDto = new ILifeQualitySaveRequestDto(model.items);

    await this._http.post<number>('QualityOfLifeAssessment', postDto);
  }

  async listQuestions(): Promise<LifeQualityQuestionModel[]> {
    const {data} = await this._http.get<ILifeQualityQuestionsGetResponseDto[]>(
      'QualityOfLifeAssessment/getsurveyforpatient',
    );

    return data.map((dto) => LifeQualityQuestionModelFactory.fromGetDto(dto));
  }
}

export {LifeQualityRepository};
