import type {
  IGlobalImprovementScaleRepository,
  IGlobalImprovementScaleQuery,
} from './global-improvement-scale.repository.interface';
import {inject, injectable} from 'inversify';
import type {GlobalImprovementScaleModel, GlobalImprovementScaleQuestionsModel} from '../model';
import {GlobalImprovementScaleModelFactory, GlobalImprovementScaleQuestionsModelFactory} from '../model';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {IGlobalImprovementScaleGetResponseDto, IGlobalImprovementScaleQuestionsGetResponseDto} from './dto';
import {IGlobalImprovementScaleSaveRequestDto} from './dto';

@injectable()
class GlobalImprovementScaleRepository implements IGlobalImprovementScaleRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(query?: Partial<IGlobalImprovementScaleQuery>): Promise<GlobalImprovementScaleModel | null> {
    let model: GlobalImprovementScaleModel | null = null;

    if (query?.id) {
      const {data: dto} = await this._http.get<IGlobalImprovementScaleGetResponseDto>(
        `GlobalImprovementScaleAssessment/${query.id}`,
      );

      model = GlobalImprovementScaleModelFactory.fromGetDto(dto);
    }

    return model;
  }

  async list(): Promise<GlobalImprovementScaleModel[]> {
    const response = await this._http.get<IGlobalImprovementScaleGetResponseDto[]>(`GlobalImprovementScaleAssessment`);

    return response.data?.map((i) => GlobalImprovementScaleModelFactory.fromGetDto(i)) ?? [];
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async save(model: GlobalImprovementScaleModel): Promise<void> {
    const postDto = new IGlobalImprovementScaleSaveRequestDto(model.items);

    await this._http.post<number>('GlobalImprovementScaleAssessment', postDto);
  }

  async listQuestions(): Promise<GlobalImprovementScaleQuestionsModel[]> {
    const {data} = await this._http.get<IGlobalImprovementScaleQuestionsGetResponseDto[]>(
      'GlobalImprovementScaleAssessment/getsurveyforpatient',
    );

    return data.map((dto) => GlobalImprovementScaleQuestionsModelFactory.fromGetDto(dto));
  }
}

export {GlobalImprovementScaleRepository};
