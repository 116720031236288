import type {FC} from 'react';
import React, {useEffect, useMemo} from 'react';
import {SelfEfficacyAssessmentQuestions} from './questions';
import {FormProvider, useForm} from 'react-hook-form';
import {Button, Card} from '../../components';
import {formatDefaultValueForForm} from '../../helpers';
import type {ISelfEfficacyAssessmentItem, SelfEfficacyAssessmentQuestionsModel} from '../model';
import type {ISelfEfficacyAssessmentFormDto} from '../service';
import styles from './self-efficacy-assessment-form.module.scss';

interface ISelfEfficacyAssessmentFormProps {
  selectedAssessmentItems: ISelfEfficacyAssessmentItem[] | null;
  listQuestions: SelfEfficacyAssessmentQuestionsModel[];
  isSubmitDisabled: boolean;
  readonly: boolean;
  onSubmit: (data: ISelfEfficacyAssessmentFormDto) => Promise<void>;
  onCancel: () => void;
}

const SelfEfficacyAssessmentForm: FC<ISelfEfficacyAssessmentFormProps> = ({
  selectedAssessmentItems,
  listQuestions,
  readonly,
  isSubmitDisabled,
  onSubmit,
  onCancel,
}) => {
  const defaultValues = useMemo(
    () =>
      listQuestions?.length
        ? listQuestions.reduce(
            (acc, question) => ({
              ...acc,
              [question.name]: formatDefaultValueForForm(
                listQuestions,
                question.name,
                question.defaultValue ?? question.minValue,
              ),
            }),
            {},
          )
        : {},
    [listQuestions],
  );

  const defaultLoadedValues = useMemo(
    () =>
      selectedAssessmentItems?.length
        ? selectedAssessmentItems?.reduce(
            (acc, item) => ({
              ...acc,
              [item.question]: formatDefaultValueForForm(listQuestions, item.question, item.value),
            }),
            defaultValues,
          )
        : defaultValues,
    [defaultValues, listQuestions, selectedAssessmentItems],
  );

  const {reset, ...formMethods} = useForm<ISelfEfficacyAssessmentFormDto>({
    defaultValues: defaultLoadedValues,
  });

  useEffect(() => reset(defaultLoadedValues), [defaultLoadedValues, reset]);

  const actions = useMemo(
    () => (
      <div className={styles.buttonContainer}>
        <Button block className={styles.button} onClick={onCancel} testid={'self-efficacy-survey-button-cancel'}>
          Cancel
        </Button>
        <Button
          block
          type={'submit'}
          className={styles.button}
          disabled={isSubmitDisabled}
          testid={'self-efficacy-survey-button-submit'}>
          Submit
        </Button>
      </div>
    ),
    [isSubmitDisabled, onCancel],
  );

  return (
    <FormProvider reset={reset} {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Card>
          <SelfEfficacyAssessmentQuestions listQuestions={listQuestions} readonly={readonly} />
        </Card>
        {!readonly && actions}
      </form>
    </FormProvider>
  );
};

export {SelfEfficacyAssessmentForm};
