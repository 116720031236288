import {inject, injectable} from 'inversify';
import type {IProfileVerificationRepository} from './profile-verification.repository.interface';
import {HTTP_SERVICE} from '../../utils/http';
import type {IHttpService} from '../../utils/http';
import type {IProfileVerificationInfo} from '../model/profile-verification-info.interface';

@injectable()
class ProfileVerificationRepository implements IProfileVerificationRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async requestCode() {
    const {data} = await this._http.post<{code?: string}>('Verify/code', {});

    return data?.code;
  }

  async verifyCode(code: string) {
    return this._http
      .post<{success: boolean}>(`Verify/verify`, {code})
      .then(() => true)
      .catch(() => false);
  }

  async sendCodeToPhone(phone: string) {
    await this._http.post(`Verify/codetophone`, {phoneNumber: phone});
  }

  async getUserInfo(): Promise<IProfileVerificationInfo> {
    const {data} = await this._http.get<IProfileVerificationInfo>('Verify/info');

    return data;
  }
}

export {ProfileVerificationRepository};
