import type {FC} from 'react';
import React, {useMemo} from 'react';
import {Card, CardContent, DateWrapper, RichTextComponent} from '../../../components';
import styles from './notification.module.scss';
import {NotificationFlag} from './components';
import type {RichText} from '../../../types';
import classNames from 'classnames';
import {NotificationSelect} from '../notifications-list/notification-select';

const Notification: FC<{
  text: RichText;
  title?: RichText;
  isRead: boolean;
  isSelected: boolean;
  changeSelect(): void;
  date: Date;
  id: number;
  dateOptions: {lowerCase: boolean; format: string};
  flag?: number | null;
  notificationId: number;
  expandNotification(id: number, isRead: boolean): void;
  expandedNotification?: number;
}> = ({
  isRead,
  text,
  title,
  isSelected,
  changeSelect,
  date,
  dateOptions,
  flag,
  expandNotification,
  expandedNotification,
  notificationId,
}) => {
  const renderDate = useMemo(
    () =>
      dateOptions.lowerCase ? (
        <DateWrapper date={date} format={dateOptions.format} lower />
      ) : (
        <DateWrapper date={date} format={dateOptions.format} />
      ),
    [dateOptions, date],
  );

  return (
    <Card
      onClick={() => {
        expandNotification(notificationId, isRead);
      }}
      className={classNames(styles.card, {[styles.unread]: !isRead, [styles.selected]: isSelected})}>
      <NotificationFlag flagId={Number(flag)} />
      <CardContent gapTop={2} gapBottom={2} className={styles.container}>
        <div className={styles.indicator}>
          <NotificationSelect isSelected={isSelected} changeSelect={changeSelect} />
        </div>
        <div className={styles.wrapper} data-testid={'notification-toggle-button'}>
          {title && (
            <span className={styles.title}>
              <RichTextComponent text={title} />
              <span className={styles.time}>{renderDate}</span>
            </span>
          )}

          <p className={classNames(styles.text, {[styles.collapse]: expandedNotification !== notificationId})}>
            <RichTextComponent text={text} />
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export {Notification};
