import type {MedicationsCourseModel} from '../../model';
import {MedicationsCourseDiscontinuePostRequestDto} from './medications-course-discontinue-post-request.dto';
import {
  EnumCommentSeparationParseHelper,
  formatFullDateTime,
  MEDICATION_DISCONTINUED_REASON_OTHER_ID,
} from '../../../helpers';

class MedicationsCourseDiscontinuePostRequestDtoFactory {
  static fromModel(model: MedicationsCourseModel): MedicationsCourseDiscontinuePostRequestDto {
    const {id: reason, comment: reasonOther} = EnumCommentSeparationParseHelper(
      model.discontinue?.reason,
      MEDICATION_DISCONTINUED_REASON_OTHER_ID,
    );

    return new MedicationsCourseDiscontinuePostRequestDto(
      model.id,
      model.discontinue?.date ? formatFullDateTime(model.discontinue?.date) : null,
      reason,
      reasonOther,
    );
  }
}

export {MedicationsCourseDiscontinuePostRequestDtoFactory};
