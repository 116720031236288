import type {FC, SVGProps} from 'react';
import React from 'react';

import {ReactComponent as Add} from '../../assets/icons/ic_transporate_add.svg';
import {ReactComponent as Child} from '../../assets/emergency-contacts/child.svg';
import {ReactComponent as Friend} from '../../assets/emergency-contacts/friend.svg';
import {ReactComponent as Parent} from '../../assets/emergency-contacts/parent.svg';
import {ReactComponent as Spouse} from '../../assets/emergency-contacts/spouse.svg';
import {ReactComponent as Sibling} from '../../assets/emergency-contacts/sibling.svg';
import {ReactComponent as Email} from '../../assets/communications/email.svg';
import {ReactComponent as Message} from '../../assets/communications/message.svg';
import {ReactComponent as Phone} from '../../assets/communications/phone.svg';

import {ReactComponent as NotificationGray} from '../../assets/top-nav/ic_notification_gray.svg';
import {ReactComponent as SettingsGray} from '../../assets/top-nav/ic_settings_gray.svg';

import {ReactComponent as SurveyArt} from '../../assets/survey/art.svg';
import {ReactComponent as SurveyBeach} from '../../assets/survey/beach.svg';
import {ReactComponent as SurveyBird} from '../../assets/survey/bird.svg';
import {ReactComponent as SurveyBlack} from '../../assets/survey/black.svg';
import {ReactComponent as SurveyCat} from '../../assets/survey/cat.svg';
import {ReactComponent as SurveyChild} from '../../assets/survey/child.svg';
import {ReactComponent as SurveyCity} from '../../assets/survey/city.svg';
import {ReactComponent as SurveyCofeinless} from '../../assets/survey/cofeinless.svg';
import {ReactComponent as SurveyCream} from '../../assets/survey/cream.svg';
import {ReactComponent as SurveyDad} from '../../assets/survey/dad.svg';
import {ReactComponent as SurveyDog} from '../../assets/survey/dog.svg';
import {ReactComponent as SurveyFish} from '../../assets/survey/fish.svg';
import {ReactComponent as SurveyFriend} from '../../assets/survey/friend.svg';
import {ReactComponent as SurveyGrand} from '../../assets/survey/grand.svg';
import {ReactComponent as SurveyGym} from '../../assets/survey/gym.svg';
import {ReactComponent as SurveyHamster} from '../../assets/survey/hamster.svg';
import {ReactComponent as SurveyHistory} from '../../assets/survey/history.svg';
import {ReactComponent as SurveyLake} from '../../assets/survey/lake.svg';
import {ReactComponent as SurveyLunch} from '../../assets/survey/lunch.svg';
import {ReactComponent as SurveyMath} from '../../assets/survey/math.svg';
import {ReactComponent as SurveyMom} from '../../assets/survey/mom.svg';
import {ReactComponent as SurveyMusic} from '../../assets/survey/music.svg';
import {ReactComponent as SurveyScience} from '../../assets/survey/science.svg';
import {ReactComponent as SurveySibling} from '../../assets/survey/sibling.svg';
import {ReactComponent as SurveySnake} from '../../assets/survey/snake.svg';
import {ReactComponent as SurveySteam} from '../../assets/survey/steam.svg';
import {ReactComponent as SurveyEnglish} from '../../assets/survey/english.svg';
import {ReactComponent as SurveyForeignCountry} from '../../assets/survey/foreign_country.svg';

import {ReactComponent as Analytics} from '../../assets/drawer/ic_analytics.svg';
import {ReactComponent as Settings} from '../../assets/drawer/ic_settings.svg';
import {ReactComponent as Messages} from '../../assets/drawer/ic_messages.svg';
import {ReactComponent as Tasks} from '../../assets/drawer/ic_tasks.svg';
import {ReactComponent as Education} from '../../assets/drawer/ic_education.svg';
import {ReactComponent as CareVault} from '../../assets/drawer/ic_care_vault.svg';

import {ReactComponent as ArrowUp} from '../../assets/drawer/ic_arrow_up.svg';
import {ReactComponent as ArrowDown} from '../../assets/drawer/ic_arrow_down.svg';

import {ReactComponent as EmailIcon} from '../../assets/icons/ic_email.svg';
import {ReactComponent as CalendarIcon} from '../../assets/icons/ic_calendar.svg';
import {ReactComponent as Arrow} from '../../assets/icons/ic_arrow.svg';
import {ReactComponent as Checkmark} from '../../assets/icons/ic_checkmark.svg';
import {ReactComponent as CheckmarkGreen} from '../../assets/icons/ic_checkmark_green.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/ic_edit.svg';
import {ReactComponent as NotificationIcon} from '../../assets/icons/ic_notification.svg';
import {ReactComponent as ExpandIcon} from '../../assets/icons/ic_expand.svg';
import {ReactComponent as NewMessageIcon} from '../../assets/icons/ic_new_message.svg';
import {ReactComponent as SendIcon} from '../../assets/icons/ic_send.svg';
import {ReactComponent as ArrowGrayLeft} from '../../assets/icons/ic_arrow_gray_left.svg';
import {ReactComponent as ArrowGrayRight} from '../../assets/icons/ic_arrow_gray_right.svg';
import {ReactComponent as Pencil} from '../../assets/icons/ic_pencil.svg';
import {ReactComponent as AlertCircle} from '../../assets/icons/ic_alert_circle.svg';
import {ReactComponent as Clock} from '../../assets/icons/ic_clock.svg';
import {ReactComponent as Plus} from '../../assets/icons/ic_plus.svg';

import {ReactComponent as EmotionHappy} from '../../assets/emoji/01_emoj.svg';
import {ReactComponent as EmotionGood} from '../../assets/emoji/02_emoj.svg';
import {ReactComponent as EmotionNorm} from '../../assets/emoji/03_emoj.svg';
import {ReactComponent as EmotionSad} from '../../assets/emoji/04_emoj.svg';
import {ReactComponent as EmotionBad} from '../../assets/emoji/05_emoj.svg';

import {ReactComponent as SearchIcon} from '../../assets/icons/ic_search.svg';
import {ReactComponent as ArrowLeftIcon} from '../../assets/icons/arrow-left.svg';
import {ReactComponent as RefreshIcon} from '../../assets/icons/refresh.svg';
import {ReactComponent as LinesIcon} from '../../assets/icons/ic_lines.svg';
import {ReactComponent as CalendarMobileIcon} from '../../assets/icons/calendar_black.svg';

import {ReactComponent as Attachment} from '../../assets/icons/attachment.svg';
import {ReactComponent as Close} from '../../assets/icons/ic_close.svg';
import {ReactComponent as GoToIcon} from '../../assets/icons/ic_goto_web.svg';
import {ReactComponent as WarnCircleIcon} from '../../assets/icons/ic_warn_circle.svg';
import {ReactComponent as RemoveCircleIcon} from '../../assets/icons/circle_remove_red.svg';
import {ReactComponent as CheckmarkCompletedIcon} from '../../assets/icons/ic_checkmark_completed.svg';
import {ReactComponent as CloseCircle} from '../../assets/icons/ic_close_circle.svg';

import {ReactComponent as FavoriteEmpty} from '../../assets/favorite/empty.svg';
import {ReactComponent as FavoriteFilled} from '../../assets/favorite/filled.svg';
import {ReactComponent as FavoriteSimpleFilled} from '../../assets/favorite/simple-filled.svg';

import {ReactComponent as CloudDownload} from '../../assets/cloud-download/cloud-download.svg';
import {ReactComponent as GoalFlowerFilled} from '../../assets/goals/GoalFlowerFilled.svg';

enum IconType {
  Add = 11,
  Child = 1,
  Friend = 2,
  Parent = 3,
  Spouse = 4,
  Sibling = 5,
  Email = 6,
  Message = 7,
  Phone = 8,

  NotificationGray = 9,
  SettingsGray = 10,
  Close = 12,
  CloseCircle = 13,

  SurveyArt = 100,
  SurveyBeach = 101,
  SurveyBird = 102,
  SurveyBlackCoffee = 103,
  SurveyCat = 104,
  SurveyChild = 105,
  SurveyCity = 106,
  SurveyCofeinless = 107,
  SurveyCream = 108,
  SurveyDad = 109,
  SurveyDog = 110,
  SurveyFish = 111,
  SurveyFriend = 112,
  SurveyGrand = 113,
  SurveyGym = 114,
  SurveyHamster = 115,
  SurveyHistory = 116,
  SurveyLake = 117,
  SurveyLunch = 118,
  SurveyMath = 119,
  SurveyMom = 120,
  SurveyMusic = 121,
  SurveyScience = 122,
  SurveySibling = 123,
  SurveySnake = 124,
  SurveySteam = 125,
  SurveyEnglish = 126,
  SurveyForeignCountry = 127,
  ArrowGrayLeft = 128,
  ArrowGrayRight = 129,
  Pencil = 130,

  Analytics = 400,
  Tasks = 401,
  Messages = 402,
  Tools = 403,
  Education = 404,
  CareVault = 405,
  ArrowUp = 406,
  ArrowDown = 407,

  EmailIcon = 500,
  CalendarIcon = 501,
  Arrow = 502,
  Checkmark = 503,
  Edit = 504,
  Notification = 505,
  Expand = 506,
  NewMessage = 507,
  Send = 508,

  EmotionHappy = 509,
  EmotionGood = 510,
  EmotionNorm = 511,
  EmotionSad = 512,
  EmotionBad = 513,

  AlertCircle = 521,
  Clock = 522,
  Plus = 523,
  Search = 524,
  ArrowLeft = 525,
  Refresh = 526,
  Attachment = 527,
  Lines = 528,
  CalendarMobile = 529,
  GoTo = 530,
  WarnCircle = 531,
  RemoveCircleIcon = 532,
  CheckmarkCompletedIcon = 533,

  FavoriteEmpty = 601,
  FavoriteFilled = 602,
  CheckmarkGreen = 603,
  CloudDownload = 604,
  FavoriteSimpleFilled = 605,
  GoalFlowerFilled = 606,
}

enum IconColor {
  White = 1,
  Grey = 2,
}

type ISVGProps = Omit<SVGProps<SVGSVGElement>, 'type'>;

interface IIconProps extends ISVGProps {
  type: IconType | string | number;
}

const Icon: FC<IIconProps> = ({type, ...props}) => {
  let IconComponent: FC<ISVGProps> | null;

  switch (type) {
    case IconType.Add:
      IconComponent = Add;
      break;
    case IconType.Child:
      IconComponent = Child;
      break;
    case IconType.Friend:
      IconComponent = Friend;
      break;
    case IconType.Parent:
      IconComponent = Parent;
      break;
    case IconType.Spouse:
      IconComponent = Spouse;
      break;
    case IconType.Sibling:
      IconComponent = Sibling;
      break;
    case IconType.Email:
      IconComponent = Email;
      break;
    case IconType.Phone:
      IconComponent = Phone;
      break;
    case IconType.Message:
      IconComponent = Message;
      break;
    case IconType.SurveyArt:
      IconComponent = SurveyArt;
      break;
    case IconType.SurveyBeach:
      IconComponent = SurveyBeach;
      break;
    case IconType.SurveyBird:
      IconComponent = SurveyBird;
      break;
    case IconType.SurveyBlackCoffee:
      IconComponent = SurveyBlack;
      break;
    case IconType.SurveyCat:
      IconComponent = SurveyCat;
      break;
    case IconType.SurveyChild:
      IconComponent = SurveyChild;
      break;
    case IconType.SurveyCity:
      IconComponent = SurveyCity;
      break;
    case IconType.SurveyCofeinless:
      IconComponent = SurveyCofeinless;
      break;
    case IconType.SurveyCream:
      IconComponent = SurveyCream;
      break;
    case IconType.SurveyDad:
      IconComponent = SurveyDad;
      break;
    case IconType.SurveyDog:
      IconComponent = SurveyDog;
      break;
    case IconType.SurveyFish:
      IconComponent = SurveyFish;
      break;
    case IconType.SurveyFriend:
      IconComponent = SurveyFriend;
      break;
    case IconType.SurveyGrand:
      IconComponent = SurveyGrand;
      break;
    case IconType.SurveyGym:
      IconComponent = SurveyGym;
      break;
    case IconType.SurveyHamster:
      IconComponent = SurveyHamster;
      break;
    case IconType.SurveyHistory:
      IconComponent = SurveyHistory;
      break;
    case IconType.SurveyLake:
      IconComponent = SurveyLake;
      break;
    case IconType.SurveyLunch:
      IconComponent = SurveyLunch;
      break;
    case IconType.SurveyMath:
      IconComponent = SurveyMath;
      break;
    case IconType.SurveyMom:
      IconComponent = SurveyMom;
      break;
    case IconType.SurveyMusic:
      IconComponent = SurveyMusic;
      break;
    case IconType.SurveyScience:
      IconComponent = SurveyScience;
      break;
    case IconType.SurveySibling:
      IconComponent = SurveySibling;
      break;
    case IconType.SurveySnake:
      IconComponent = SurveySnake;
      break;
    case IconType.SurveySteam:
      IconComponent = SurveySteam;
      break;
    case IconType.SurveyEnglish:
      IconComponent = SurveyEnglish;
      break;
    case IconType.SurveyForeignCountry:
      IconComponent = SurveyForeignCountry;
      break;

    case IconType.Analytics:
      IconComponent = Analytics;
      break;
    case IconType.Tasks:
      IconComponent = Tasks;
      break;
    case IconType.Messages:
      IconComponent = Messages;
      break;
    case IconType.Tools:
      IconComponent = Settings;
      break;
    case IconType.Education:
      IconComponent = Education;
      break;
    case IconType.CareVault:
      IconComponent = CareVault;
      break;

    case IconType.ArrowUp:
      IconComponent = ArrowUp;
      break;
    case IconType.ArrowDown:
      IconComponent = ArrowDown;
      break;

    case IconType.EmailIcon:
      IconComponent = EmailIcon;
      break;
    case IconType.CalendarIcon:
      IconComponent = CalendarIcon;
      break;
    case IconType.Arrow:
      IconComponent = Arrow;
      break;
    case IconType.Checkmark:
      IconComponent = Checkmark;
      break;
    case IconType.Edit:
      IconComponent = EditIcon;
      break;
    case IconType.Notification:
      IconComponent = NotificationIcon;
      break;
    case IconType.Expand:
      IconComponent = ExpandIcon;
      break;
    case IconType.NewMessage:
      IconComponent = NewMessageIcon;
      break;
    case IconType.Send:
      IconComponent = SendIcon;
      break;

    case IconType.NotificationGray:
      IconComponent = NotificationGray;
      break;
    case IconType.SettingsGray:
      IconComponent = SettingsGray;
      break;
    case IconType.Close:
      IconComponent = Close;
      break;
    case IconType.CloseCircle:
      IconComponent = CloseCircle;
      break;

    case IconType.ArrowGrayLeft:
      IconComponent = ArrowGrayLeft;
      break;
    case IconType.ArrowGrayRight:
      IconComponent = ArrowGrayRight;
      break;
    case IconType.Pencil:
      IconComponent = Pencil;
      break;
    case IconType.EmotionHappy:
      IconComponent = EmotionHappy;
      break;
    case IconType.EmotionGood:
      IconComponent = EmotionGood;
      break;
    case IconType.EmotionNorm:
      IconComponent = EmotionNorm;
      break;
    case IconType.EmotionSad:
      IconComponent = EmotionSad;
      break;
    case IconType.EmotionBad:
      IconComponent = EmotionBad;
      break;
    case IconType.AlertCircle:
      IconComponent = AlertCircle;
      break;
    case IconType.Clock:
      IconComponent = Clock;
      break;
    case IconType.Plus:
      IconComponent = Plus;
      break;
    case IconType.Search:
      IconComponent = SearchIcon;
      break;
    case IconType.ArrowLeft:
      IconComponent = ArrowLeftIcon;
      break;
    case IconType.Refresh:
      IconComponent = RefreshIcon;
      break;
    case IconType.Attachment:
      IconComponent = Attachment;
      break;
    case IconType.Lines:
      IconComponent = LinesIcon;
      break;
    case IconType.CalendarMobile:
      IconComponent = CalendarMobileIcon;
      break;
    case IconType.GoTo:
      IconComponent = GoToIcon;
      break;
    case IconType.WarnCircle:
      IconComponent = WarnCircleIcon;
      break;
    case IconType.RemoveCircleIcon:
      IconComponent = RemoveCircleIcon;
      break;
    case IconType.CheckmarkCompletedIcon:
      IconComponent = CheckmarkCompletedIcon;
      break;
    case IconType.FavoriteEmpty:
      IconComponent = FavoriteEmpty;
      break;
    case IconType.FavoriteFilled:
      IconComponent = FavoriteFilled;
      break;
    case IconType.FavoriteSimpleFilled:
      IconComponent = FavoriteSimpleFilled;
      break;
    case IconType.CheckmarkGreen:
      IconComponent = CheckmarkGreen;
      break;
    case IconType.CloudDownload:
      IconComponent = CloudDownload;
      break;
    case IconType.GoalFlowerFilled:
      IconComponent = GoalFlowerFilled;
      break;
    default:
      IconComponent = null;
      break;
  }

  return !!IconComponent ? <IconComponent {...props} /> : <></>;
};

export {Icon, IconType, IconColor};
export type {IIconProps};
