class ProfileEmergencyContactDto {
  constructor(
    public firstName: string = '',
    public lastName: string = '',
    public email: string = '',
    public phone: string = '',
    public relationshipType: number = -1,
    public patientId: number = -1,
    public partnerInCareId: number = -1,
    public verificationCode: string | null = null,
  ) {}
}

export {ProfileEmergencyContactDto};
