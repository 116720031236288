import type {ILayoutsGetNavigationDto} from './dto';
import type {NavigationListAccess} from './layouts.service.interface';
import {NavigationIds} from './navigation-ids';

class NavigationItemsModelFactory {
  static fromGetDto(dto: ILayoutsGetNavigationDto): NavigationListAccess[] {
    return Object.keys(NavigationIds)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => ({
        displayName: dto.items.find((i) => i.id === Number(key))?.name,
        isActive: dto.items.find((i) => i.id === Number(key))?.isActive ?? false,
        id: Number(key),
      }));
  }
}

export {NavigationItemsModelFactory};
