import {VaccinationUpdateRequestDto} from './vaccination-update-request.dto';
import type {VaccinationModel} from '../../../model';

class VaccinationUpdateRequestDtoFabric {
  static fromModel(model: VaccinationModel): VaccinationUpdateRequestDto {
    return new VaccinationUpdateRequestDto(model.id, model.patientId, model.items);
  }
}

export {VaccinationUpdateRequestDtoFabric};
