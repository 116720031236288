export const Patterns = {
  Password: /^[A-Za-z0-9(~!@#$%^)]{8,}$/i,
  VerificationCodeMask: '999999',
  VerificationCode: /^\d{6,}$/i,
  Email: /^[A-Z0-9._%+\-!#$%&'*+/=?^_`{|}~]{1,64}@([A-Z0-9.-]{1,63}\.){1,2}[A-Z]{2,61}$/i,
  UserName: /^[A-Z0-9@^$.!\-#+`'~_]{1,128}$/i,
  Number: /^\d+$/i,
  PhoneMask: '+1-999-999-9999',
  PhoneFormat: /^(\+1)?-?[0-9]{3}-?[0-9]{3}-?[0-9]{4}$/i,
  ClearedPhoneFormat: /\d{10}/i,
  ZipCodeMaskUSA: '99999',
  ZipCodeMaskCanada: 'a9a-9a9',
  ZipCodeUSA: /^\d{5,}$/i,
  ZipCodeCanada: /^[ABCEGHJ-NPRSTVXYZ]\d[ABCEGHJ-NPRSTV-Z][-]\d[ABCEGHJ-NPRSTV-Z]\d$/i,
  SpecialCharacters: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
  LowerLetters: /[a-z]/,
  UpperLetters: /[A-Z]/,
  Numbers: /[0-9]/,
  DecimalNumber: /^\d+(\.?\d?)$/i,
  DecimalNumberWith3Digits: /^\d+(\.)?(\d{1,3})?$/i,
  Url: /^((https?:\/\/)|(www\.)){1}.{1,}/i,
  FootAndInch: /^(\s*[0-9]{1,3}\s*ft\s*([0-9]|1[0-2])?\s*in)$/i,
};
