class MedicationsCourseDiscontinueModel {
  constructor(
    public date: Date | null = null,
    public reason: number | string = -1,
    public author = '',
    public lastUpdated = new Date(),
  ) {}
}

export {MedicationsCourseDiscontinueModel};
