import type {IAssessmentReportScoreGetResponseDto} from '../dto/assessment-report-score-get-response.dto';
import {AssessmentReportScoreModel} from './assessment-report-score.model';

class AssessmentReportScoreModelFactory {
  static mergeWithGetResponseDto(
    score: AssessmentReportScoreModel,
    dto: IAssessmentReportScoreGetResponseDto,
  ): AssessmentReportScoreModel {
    return new AssessmentReportScoreModel(
      score.title,
      score.description,
      score.iconKey,
      dto.maxValue,
      dto.minValue,
      dto.score,
    );
  }
}

export {AssessmentReportScoreModelFactory};
