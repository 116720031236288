import {addMinutes} from 'date-fns';
import {inject, injectable} from 'inversify';
import type {ITrellisPopUpInformation} from '../../../trellis-visualization/model';
import type {IAuthService} from '../../auth';
import {AUTH_SERVICE} from '../../auth';
import type {ICookieService} from '../../cookie';
import {COOKIE_SERVICE, CookieModel, CookieModelSameSiteEnum} from '../../cookie';
import type {IWssConfig} from '../config';
import {WSS_CONFIG} from '../config';

import type {IWssService, SocketData} from './wss.service.interface';
import {isLoginWebsocket, storeUnreadMessages} from '../utils';

@injectable()
class WssService implements IWssService {
  static reconnectionAttemptsMaxCount = 10;
  static reconnectionIntervalDelay = 10000;
  private static COOKIE_TOKEN_KEY = 'token';
  socket: WebSocket | null = null;
  reconnectionAttemptsCount = 0;
  listeners: Map<string, Array<(data: SocketData) => void>> = new Map();
  reconnectListeners: Array<() => void> = [];
  reconnectionIntervalId: null | number = null;

  constructor(
    @inject(WSS_CONFIG) private readonly config: IWssConfig,
    @inject(AUTH_SERVICE) private readonly auth: IAuthService,
    @inject(COOKIE_SERVICE) private readonly cookies: ICookieService,
  ) {}

  get isOpen(): boolean {
    return !!this.socket;
  }

  static generateTokenCookie(token: string, url: string, dateZero = new Date()): CookieModel {
    const expires = addMinutes(dateZero, 15);
    const hostNameSections = new URL(url).hostname.split('.');
    const domain: string = `.${hostNameSections.slice(-2).join('.')}`;

    return new CookieModel(
      WssService.COOKIE_TOKEN_KEY,
      token,
      expires,
      '/',
      domain,
      false,
      false,
      CookieModelSameSiteEnum.Strict,
    );
  }

  async open(reconnected = false): Promise<void> {
    const token = await this.auth.getToken();

    if (!!token && !this.isOpen) {
      const tokenCookie = WssService.generateTokenCookie(token, this.config.url);
      this.cookies.set(tokenCookie);

      this.socket = new WebSocket(this.config.url);

      this.socket.onmessage = (e) => {
        const data: {type: string; data: SocketData} = JSON.parse(e.data);

        if (isLoginWebsocket(data.type, data.data as ITrellisPopUpInformation[])) {
          storeUnreadMessages(data);
        }

        this.listeners.get(data.type)?.forEach((listener) => {
          listener(data.data);
        });
      };

      this.socket.onopen = () => {
        this.clearInterval();

        if (reconnected) {
          this.reconnectListeners.forEach((listener) => {
            listener();
          });
          this.reconnectionAttemptsCount = 0;
        }
      };

      this.socket.onclose = () => {
        this.cookies.delete(WssService.COOKIE_TOKEN_KEY);
        this.socket = null;
      };

      this.socket.onerror = () => {
        if (this.reconnectionIntervalId === null) {
          this.reconnectionIntervalId = Number(
            setInterval(() => {
              if (this.reconnectionAttemptsCount < WssService.reconnectionAttemptsMaxCount) {
                this.reconnectionAttemptsCount++;

                this.open(true);
              } else {
                this.clearInterval();
              }
            }, WssService.reconnectionIntervalDelay),
          );
        }

        if (this.socket) {
          this.socket.close();
        }
      };
    }
  }

  close(): void {
    if (this.reconnectionIntervalId) {
      clearInterval(this.reconnectionIntervalId);
      this.reconnectionIntervalId = null;
    }

    if (this.socket) {
      this.socket.onclose = null;
      this.socket.close(1000);
      this.socket = null;
    }
  }

  async reconnect(): Promise<void> {
    if (this.auth.isAuthenticated()) {
      if (this.socket) {
        this.close();
      }

      return this.open();
    }
  }

  subscribe(eventType: string, callback: (data: SocketData) => void): () => void {
    const eventListeners = this.listeners.get(eventType);

    if (eventListeners) {
      eventListeners.push(callback);
    } else {
      this.listeners.set(eventType, [callback]);
    }

    return () => {
      const index = this.listeners?.get(eventType)?.indexOf(callback);
      this.listeners.get(eventType)?.splice(index ?? 0, 1);
    };
  }

  subscribeReconnect(callback: () => void): () => void {
    this.reconnectListeners.push(callback);

    return () => {
      const index = this.reconnectListeners.indexOf(callback);
      this.reconnectListeners.splice(index, 1);
    };
  }

  private clearInterval() {
    if (this.reconnectionIntervalId !== null) {
      clearInterval(this.reconnectionIntervalId);
      this.reconnectionIntervalId = null;
    }
  }
}

export {WssService};
