import {inject, injectable} from 'inversify';
import {CompanyModel, CompanyModelFactory} from '../model';
import {makeAutoObservable} from 'mobx';
import type {ICompanyService} from './index';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {ICompanyRecordGetResponseDto} from '../dto';

@injectable()
class CompanyService implements ICompanyService {
  constructor(@inject(HTTP_SERVICE) private readonly http: IHttpService) {
    makeAutoObservable(this);
  }

  private _companyInfo: CompanyModel = new CompanyModel();

  get companyInfo(): CompanyModel {
    return this._companyInfo;
  }

  async load(): Promise<void> {
    await this.http
      .get<ICompanyRecordGetResponseDto>('/Company/information')
      .then((response) => {
        this._companyInfo = CompanyModelFactory.fromGetResponseDtoRecord(response.data);
      })
      .catch(console.error);
  }
}

export {CompanyService};
