import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import type {FileError} from 'react-dropzone';
import {uploadFile} from '../../file-uploader.helper';
import styles from './item-file-uploader.module.scss';
import {CircleRemoveRedIcon, GreenCheckboxIcon, TrashIcon} from '../../../../assets/icons';
import {FileUploaderErrorType} from '../../file-uploader.enum';
import {ProgressBarLine} from '../../../progress-bar-line';

interface IItemFileUploaderProps {
  file: File;
  errors: FileError[];
  onDelete: (file: File) => void;
  setBase64: (file: File, base64: string) => void;
  errorMessageMaxSize?: string;
  errorMessageAccept?: string;
}

const ItemFileUploader: FC<IItemFileUploaderProps> = ({
  file,
  onDelete,
  setBase64,
  errors,
  errorMessageMaxSize,
  errorMessageAccept,
}: IItemFileUploaderProps) => {
  const [progress, setProgress] = useState(0);
  const [isTransitionEnded, setIsTransitionEnded] = useState(false);

  const upload = useCallback(async () => {
    const base64 = await uploadFile(file, setProgress);

    if (typeof base64 === 'string') {
      await setBase64(file, base64);
    }
  }, [file, setBase64]);

  const errorList = useMemo(
    () =>
      errors.map((err) => {
        if (errorMessageMaxSize && err.code === FileUploaderErrorType.WrongFileSize) {
          return <span key={err.code}>{errorMessageMaxSize}</span>;
        }

        if (errorMessageAccept && err.code === FileUploaderErrorType.WrongFileType) {
          return <span key={err.code}>{errorMessageAccept}</span>;
        }

        return <span key={err.code}>{err.message}</span>;
      }),
    [errorMessageAccept, errorMessageMaxSize, errors],
  );

  useEffect(() => {
    upload();
  }, [upload]);

  return (
    <div className={styles.root}>
      <div className={styles.file}>
        <div className={styles.fileInfo}>
          {isTransitionEnded && (
            <div className={styles.iconResult}>{!errors.length ? <GreenCheckboxIcon /> : <CircleRemoveRedIcon />}</div>
          )}
          <div>
            <div className={styles.fileName}>{file.name}</div>
            <div className={styles.message}>
              {isTransitionEnded && (!errors.length ? 'File has successfully uploaded!' : errorList)}
            </div>
          </div>
        </div>
        {isTransitionEnded && (
          <div className={styles.fileAction}>
            <button onClick={() => onDelete(file)} className={styles.fileDeleteBtn}>
              <TrashIcon />
            </button>
          </div>
        )}
      </div>

      {!isTransitionEnded && <ProgressBarLine progress={progress} onTransitionEnd={() => setIsTransitionEnded(true)} />}
    </div>
  );
};

export {ItemFileUploader};
