import type {FC} from 'react';
import React, {useCallback, useMemo} from 'react';
import type {IAutocompleteOption} from '../autocomplete';
import {Autocomplete} from '../autocomplete';
import styles from './combobox.module.scss';
import {CloseIcon} from '../../assets/icons';
import classNames from 'classnames';

type ComboboxValue = Array<IAutocompleteOption>;

enum ComboboxType {
  Default,
  MedicalHistory,
}

interface IComboboxProps {
  name: string;
  options: Array<IAutocompleteOption>;
  value?: ComboboxValue;
  onChange: (value?: ComboboxValue) => void;
  readonly?: boolean;
  label?: string;
  placeholder?: string;
  noSelectedMessage?: string;
  noOptionsMessage?: string;
  errorMessage?: boolean;
  type?: ComboboxType;
}

const Combobox: FC<IComboboxProps> = ({
  label,
  noSelectedMessage,
  onChange,
  options,
  placeholder,
  readonly,
  value,
  noOptionsMessage,
  type,
  name,
}) => {
  const removeValue = useCallback(
    (option: IAutocompleteOption) => {
      const values = value?.filter((i) => option.value !== i.value);
      onChange(values);
    },
    [onChange, value],
  );
  const addValue = useCallback(
    (option: IAutocompleteOption) => {
      onChange([...(value ?? []), option]);
    },
    [onChange, value],
  );

  const values = useMemo(
    () =>
      value?.map((option) => (
        <div className={styles.valueContainer} key={option.value}>
          <div className={styles.value}>{option.displayName}</div>
          {!readonly && <CloseIcon onClick={() => removeValue(option)} className={styles.closeIcon} />}
        </div>
      )),
    [readonly, removeValue, value],
  );

  return (
    <div className={classNames(styles.root, {[styles.rootMedicalHistory]: type === ComboboxType.MedicalHistory})}>
      {!!readonly && !value?.length && !!noSelectedMessage && (
        <label className={styles.label} data-testid={`combobox-${name}-label`}>
          {noSelectedMessage}
        </label>
      )}
      {!!readonly && !!value?.length && !!label && (
        <label className={styles.label} data-testid={`combobox-${name}-label`}>
          {label}
        </label>
      )}
      {!readonly && (
        <Autocomplete
          label={label}
          options={options}
          onChange={addValue}
          placeholder={placeholder}
          value={value}
          noOptionsMessage={noOptionsMessage}
        />
      )}
      {!!value && (
        <div className={styles.selected} data-testid={`combobox-${name}-selectedValue`}>
          {values}
        </div>
      )}
    </div>
  );
};

export {Combobox, ComboboxType};
export type {IComboboxProps, ComboboxValue};
