import type {FC} from 'react';
import React from 'react';
import {Icon, IconType, Modal} from '../../../../../components';
import styles from './image-preview.module.scss';
import classNames from 'classnames';

interface IOwnProps {
  onClose?: () => void;
  imageSrc: string;
  imageName?: string;
}
export const ImagePreview: FC<IOwnProps> = ({onClose, imageSrc, imageName}) => (
  <Modal onClose={onClose}>
    <div className={styles.root}>
      <a
        className={classNames(styles.action, styles.actionDownload)}
        download={true}
        href={imageSrc}
        data-testid={'image-download'}>
        <Icon type={IconType.CloudDownload} />
      </a>
      <div className={classNames(styles.action, styles.actionClose)} onClick={onClose} data-testid={'image-close'}>
        <Icon type={IconType.Close} />
      </div>
      <div className={styles.imageWrapper}>
        <img src={imageSrc} className={styles.image} alt={imageName} data-testid={'image-preview'} />
      </div>
    </div>
  </Modal>
);
