import classNames from 'classnames';
import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useCallback} from 'react';
import {GoalFlowerFilled, GoalFlowerOutline} from '../../../assets/goals';
import {Modal, Spinner} from '../../../components';
import {useInjection} from '../../../ioc';
import type {Course} from '../../../patient-course/model/course';
import type {CourseTask} from '../../../patient-course/model/course-task';
import type {TaskModel} from '../../model';
import type {IGoalService} from '../../service';
import {GOAL_SERVICE} from '../../service';
import {TaskListItem} from '../task-list-item';
import styles from './course-modal.module.scss';
import type {IRoutingService} from '../../../utils/routing';
import {ROUTING_SERVICE} from '../../../utils/routing';
import {routes} from '../../../routing';

interface ICourseModalProps {
  course: Course;
  routing?: IRoutingService;
  lmsLink?: string;
  tasks: TaskModel[] | CourseTask[];
  timeZone: string;
  noStyle?: boolean;

  onBegin: (id: number) => void;
  onChangeFavoriteItem: (taskId: number, isFavorite: boolean) => void;
  onClickGoalModal?: () => void;
  onClose(): void;
  onLessonComplete: (id: number, begin?: boolean, skip?: boolean) => void;
}

interface IServiceProps {
  goalService: IGoalService;
}

const CourseModal: FC<ICourseModalProps & IServiceProps> = ({
  noStyle,
  goalService,
  routing,
  tasks,
  onClose,
  lmsLink,
  onLessonComplete,
  timeZone,
  onChangeFavoriteItem,
  onBegin,
  course,
  onClickGoalModal,
}) => {
  const redirectToGoalJournal = useCallback(() => routing?.push(`${routes.home}${routes.goalJournal}`), [routing]);

  const renderGoalSetting = useCallback(() => {
    if (goalService.isLoading) {
      return (
        <span className={styles.spinner}>
          <Spinner />
        </span>
      );
    }

    if (!course.canSetGoal && !course.hasGoal) {
      return null;
    }

    let onClickHandler;
    let goalText = '';

    if (course.hasGoal) {
      onClickHandler = redirectToGoalJournal;
      goalText = 'Review My Goal';
    } else if (course.completedLessons === 0) {
      onClickHandler = onClickGoalModal;
      goalText = 'Set Goal';
    }

    const goalContainerClasses = classNames(styles.goalContainer, {
      [styles.noCursor]: onClickHandler === undefined,
    });

    const goalImageSrc = course.hasGoal ? GoalFlowerFilled : GoalFlowerOutline;

    return goalText ? (
      <div className={goalContainerClasses} onClick={onClickHandler}>
        <img className={styles.goalImage} src={goalImageSrc} alt="Goal flower" />
        <h4>{goalText}</h4>
      </div>
    ) : (
      <></>
    );
  }, [
    course.canSetGoal,
    course.completedLessons,
    goalService.isLoading,
    course.hasGoal,
    onClickGoalModal,
    redirectToGoalJournal,
  ]);

  return (
    <Modal onClose={onClose}>
      <div className={classNames(styles.modal, {[styles.noStyle]: noStyle})}>
        <div className={styles.titleWrapper}>
          <div>
            <h3 className={styles.title}>{course.behaviorTypeName}</h3>
            {!!course.completedLessons && (
              <p>
                {!course.completed && `in progress: ${course.completedLessons} of ${course.totalLessons} completed`}
              </p>
            )}
          </div>
          {renderGoalSetting()}
        </div>
        {tasks.map((task) => (
          <TaskListItem
            key={`task-item-${task.id}`}
            task={task}
            onChangeFavoriteItem={onChangeFavoriteItem}
            showFavoriteIcon
            isLesson
            timeZone={timeZone}
            onLessonComplete={onLessonComplete}
            onBegin={onBegin}
            lmsLink={lmsLink}
          />
        ))}
      </div>
    </Modal>
  );
};

const ObservableCourseModal = observer(CourseModal);
const CourseModalInjected: FC<ICourseModalProps> = (props) => (
  <ObservableCourseModal {...props} goalService={useInjection(GOAL_SERVICE)} routing={useInjection(ROUTING_SERVICE)} />
);

export {CourseModalInjected as CourseModal, ObservableCourseModal};
export type {ICourseModalProps};
