import type {FC} from 'react';
import {useCallback} from 'react';
import {Card, CardContent, CardHeader, Modal} from '../../../components';
import {useInjection} from '../../../ioc';
import type {TaskModel} from '../../../task';
import type {IEducationService} from '../../service';
import {EDUCATION_SERVICE} from '../../service';
import type {IEducationCourseRateFormDto} from '../course-rate-form';
import {EducationCourseRateForm} from '../course-rate-form';
import styles from './education-course-rate-modal.module.scss';
import type {INotifierService} from '../../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../../notifier';
import type {CourseTask} from '../../../patient-course/model/course-task';

interface IService {
  educationService: IEducationService;
  notifierService: INotifierService;
}

interface IEducationCourseRateModalProps {
  task: TaskModel | CourseTask;
  onClose: () => void;
}

const EducationCourseRateModal: FC<IService & IEducationCourseRateModalProps> = ({
  onClose,
  educationService,
  notifierService,
  task,
}) => {
  const sendRate = useCallback(
    async (data: IEducationCourseRateFormDto) =>
      educationService
        .rateCourse(task, data)
        .then(onClose)
        .then(() => {
          notifierService.notify({
            text: 'Thank you for your Feedback. We appreciate it!',
            type: NotifyMessageTypeEnum.Success,
          });
        })
        .catch(console.error),
    [educationService, notifierService, task, onClose],
  );

  const sendRateWithoutClose = useCallback(
    async (data: IEducationCourseRateFormDto) => educationService.rateCourse(task, data).then().catch(console.error),
    [educationService, task],
  );

  return (
    <Modal onClose={() => {}}>
      <Card className={styles.card}>
        <CardHeader
          title={'Let us know what you thought of this lesson:'}
          titleCenter
          closable
          noBorder
          onClose={onClose}
        />
        <CardContent className={styles.cardContent}>
          <EducationCourseRateForm onSubmit={sendRate} onSubmitStars={sendRateWithoutClose} />
        </CardContent>
      </Card>
    </Modal>
  );
};

const InjectedEducationCourseRateModal: FC<IEducationCourseRateModalProps> = (props) => (
  <EducationCourseRateModal
    {...props}
    educationService={useInjection(EDUCATION_SERVICE)}
    notifierService={useInjection(NOTIFIER_SERVICE)}
  />
);

export {EducationCourseRateModal, InjectedEducationCourseRateModal};
export type {IEducationCourseRateModalProps};
