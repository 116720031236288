import type {IOption} from '../../types';

const mapFromOptionsHelper = <Key = number, Value = string>(
  options: IOption<Key, Value>[],
): ReadonlyMap<Key, Value> => {
  const items = options.map<[Key, Value]>(({value, displayName}) => [value, displayName]);

  return new Map<Key, Value>(items);
};

const optionsFromMapHelper = <Key = number, Value = string>(map: ReadonlyMap<Key, Value>): IOption<Key, Value>[] =>
  Array.from(map.entries()).map(([value, displayName]) => ({
    value,
    displayName,
  }));

export {mapFromOptionsHelper, optionsFromMapHelper};
