import type {FC} from 'react';
import React from 'react';
import styles from './body-mass-index-form-card-row.module.scss';

interface IBodyMassIndexFormCardRowProps {
  children: React.ReactNode;
}

const BodyMassIndexFormCardRow: FC<IBodyMassIndexFormCardRowProps> = ({children}) => (
  <div className={styles.root}>{children}</div>
);

export {BodyMassIndexFormCardRow};
