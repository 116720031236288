import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, CollapseDescription, PageLoader, PageTitle, useButtonDropdown} from '../../components';
import type {IBodyMassIndexService} from '../service';
import {BODY_MASS_INDEX_SERVICE} from '../service';
import {useInjection} from '../../ioc';
import {optionsFromMapHelper} from '../../helpers';
import {BodyMassIndexList, BodyMassIndexModal, UnitDropdownItem} from '../components';
import {unitOptions} from '../model';
import type {IOption} from '../../types';
import styles from './body-mass-index-screen.module.scss';
import type {BodyMassIndexFormDto} from '../form/dto';
import {observer} from 'mobx-react';

interface IServiceProps {
  bodyMassIndexService: IBodyMassIndexService;
}

const BodyMassIndexScreen: FC<IServiceProps> = ({bodyMassIndexService}) => {
  const unitDropdown = useButtonDropdown();
  const [isModalOpened, setModalOpened] = useState(false);

  const units = optionsFromMapHelper(unitOptions);

  const selectUnit = useCallback(
    (unit: IOption) => {
      bodyMassIndexService.selectUnit(unit.value);
      unitDropdown.close();
    },
    [bodyMassIndexService, unitDropdown],
  );

  const unitItems = useMemo(
    () =>
      optionsFromMapHelper(unitOptions).map((i) => <UnitDropdownItem unit={i} onClick={selectUnit} key={i.value} />),
    [selectUnit],
  );

  const selectedUnit = useMemo(
    () => optionsFromMapHelper(unitOptions).find((i) => i.value === bodyMassIndexService.selectedUnit),
    [bodyMassIndexService.selectedUnit],
  );

  const openModal = useCallback(() => setModalOpened(true), []);

  const closeModal = useCallback(() => setModalOpened(false), []);

  const submit = useCallback(
    (data: BodyMassIndexFormDto) => {
      bodyMassIndexService.send(data);
      closeModal();
    },
    [bodyMassIndexService, closeModal],
  );

  useEffect(() => {
    bodyMassIndexService.loadRecords();
  }, [bodyMassIndexService]);

  return (
    <>
      <PageTitle className={styles.header}>Weight</PageTitle>
      {bodyMassIndexService.isLoading ? (
        <PageLoader />
      ) : (
        <>
          <div className={styles.control}>
            <div>
              {!!bodyMassIndexService.records.length && (
                <unitDropdown.ButtonDropdown label="Unit" value={selectedUnit?.displayName} shadow>
                  {unitItems}
                </unitDropdown.ButtonDropdown>
              )}
            </div>
            <Button className={styles.addWeightButton} onClick={openModal}>
              Add new weight
            </Button>
          </div>
          <CollapseDescription>
            Tracking your weight can be a good way to ensure you are optimizing your nutritional health which is
            important for your overall well-being.
          </CollapseDescription>
          <BodyMassIndexList records={bodyMassIndexService.records} selectedUnit={bodyMassIndexService.selectedUnit} />
          {isModalOpened && <BodyMassIndexModal units={units} onSubmit={submit} onClose={closeModal} />}
        </>
      )}
    </>
  );
};

const BodyMassIndexScreenObserver = observer(BodyMassIndexScreen);

const BodyMassIndexScreenInjected: FC = () => (
  <BodyMassIndexScreenObserver bodyMassIndexService={useInjection(BODY_MASS_INDEX_SERVICE)} />
);

export {BodyMassIndexScreenInjected as BodyMassIndexScreen};
