import type {FC} from 'react';
import React, {useMemo} from 'react';
import {Button, Card, CardActions, CardHeader, Modal} from '../../components';
import styles from './confirm-modal.component.module.scss';

interface IConfirmModalProps {
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmModal: FC<IConfirmModalProps> = ({onClose, onSubmit}) => {
  const actions = useMemo(
    () => (
      <CardActions className={styles.cardActions}>
        <Button flat onClick={onClose} className={styles.cancel} testid={'cancel-modal'}>
          No
        </Button>
        <Button type={'button'} onClick={onSubmit} testid={'submit-modal'}>
          Yes
        </Button>
      </CardActions>
    ),
    [onClose, onSubmit],
  );

  return (
    <Modal onClose={onClose}>
      <Card className={styles.card} role="dialog">
        <CardHeader
          title="Are you ready to submit?"
          closable
          noBorder
          titleLarge
          onClose={onClose}
          className={styles.cardHeader}
        />
        {actions}
      </Card>
    </Modal>
  );
};

export {ConfirmModal};
