import React from 'react';
import {Link} from 'react-router-dom';
import {ArrowRightIcon} from '../../../../../assets/icons';
import ReactHtmlParser from 'react-html-parser';
import styles from './dashboard-link.module.scss';

interface IDashboardLinkProps {
  route: string;
  isShown: boolean;
  html: string;
}

const DashboardLink: React.FC<IDashboardLinkProps> = ({route, isShown, html}): JSX.Element => (
  <>
    {isShown && (
      <Link to={route} className={styles.link}>
        {ReactHtmlParser(html)} <ArrowRightIcon />
      </Link>
    )}
  </>
);

export {DashboardLink};
