import type {FC, KeyboardEventHandler} from 'react';
import React, {useCallback} from 'react';
import styles from './checkbox.module.scss';

interface ICheckboxProps {
  id: string;
  name: string;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  fontSize?: string;
  className?: string;
}

const Checkbox: FC<ICheckboxProps> = ({label, id, name, value, onChange, disabled, fontSize, className}) => {
  const change = useCallback(
    () => {
      onChange(!value);
    }, [onChange, value]
  );

  const onEnterPress: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        return change();
      }
    }, [change]
  );

  return (
    <div className={className}>
      <input
        className={styles.input}
        type="checkbox"
        id={id}
        name={name}
        checked={value}
        onChange={change}
        onKeyPress={onEnterPress}
        data-testid={`checkbox-${name}`}
        disabled={disabled}
        tabIndex={-1}
      />
      <label
        className={styles.inputLabel}
        htmlFor={id}
        style={{fontSize}}
        role="checkbox"
        aria-checked={value}
        tabIndex={0}
        onChange={change}
        onKeyPress={onEnterPress}>
          {label}
      </label>
    </div>
  );
};

export {Checkbox};
export type {ICheckboxProps};
