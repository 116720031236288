import React from 'react';
import type {IDateInputProps} from './date-selector-legacy.types';
import './date-input.scss';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import {GreyCalendarIcon} from '../../assets/icons';

const DateInput: React.FC<IDateInputProps> = (params: IDateInputProps) => {
  const value = !!params.value ? params.value.toString() : '';

  const defaultIcon = <GreyCalendarIcon />;

  return (
    <div className="date-input__container">
      <InputMask
        className={classnames(
          'date-input__field',
          params.isValid === false && 'date-input__field--invalid',
          params.disabled && 'date-input__field--disabled',
        )}
        name={params.name}
        aria-label={params.name}
        mask={'99/99/9999'}
        onChange={(e) => params.setInputValue && params.setInputValue(e.target.value)}
        onClick={params.onClick}
        inputRef={params.inputRef}
        value={params.inputValue === undefined ? value : params.inputValue}
        placeholder={'mm/dd/yyyy'}
        disabled={params.disabled}
        data-testid={params.testId}
      />

      <div onClick={params.onClick}>{params.customIcon ? params.customIcon() : defaultIcon}</div>
    </div>
  );
};

export default React.forwardRef((props: IDateInputProps, ref?: React.Ref<HTMLInputElement>) => (
  <DateInput inputRef={ref} {...props} />
));
