import {MedicationsFrequenciesTypesEnum, MedicationsUnitsTypesEnum} from '../../model';

class MedicationsCoursePutRequestDto {
  constructor(
    public medicationCourseId = -1,
    public patientId = -1,
    public medicationId: number | null = null,
    public customMedicationId: number | null = null,
    public startDate: string | null = null,
    public endDate: string | null = null,
    public dose: number | null = null,
    public unit = MedicationsUnitsTypesEnum.BlankPerNull,
    public frequency = MedicationsFrequenciesTypesEnum.BlankPerNull,
    public frequencyOther = '',
    public isDiscontinued: boolean = false,
    public discontinuedDate: string | null = null,
    public reason: number = -1,
    public reasonOther: string = '',
  ) {}
}

export {MedicationsCoursePutRequestDto};
