import {ResilienceAssessmentQuestionType} from './resilience-assessment.enum';

class ResilienceAssessmentQuestionOptionModel {
  constructor(public displayName: string = '', public displayOrder: number = -1, public value: string = '') {}
}

class ResilienceAssessmentQuestionModel {
  constructor(
    public name: string = '',
    public displayName: string = '',
    public displayOrder: number = -1,
    public required: boolean = false,
    public defaultValue: string = '',
    public type: ResilienceAssessmentQuestionType = ResilienceAssessmentQuestionType.Range,
    public options: ResilienceAssessmentQuestionOptionModel[] = [],
    public minValue: string = '',
    public maxValue: string = '',
  ) {}
}

export {ResilienceAssessmentQuestionModel};
