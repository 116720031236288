class EncounterListRequestDto {
  constructor(
    public pageNumber = 1,
    public pageSize = 1,
    public orderBy = '',
    public isAscending = false,
    public patientId = -1,
    public createdBy?: number,
    public date?: Date | string,
  ) {}
}

export {EncounterListRequestDto};
