import type {MedicationsModel} from '../../model';
import {MedicationPostRequestDto} from './medication-post-request.dto';

class MedicationPostRequestDtoFactory {
  static fromModel(model: MedicationsModel): MedicationPostRequestDto {
    return new MedicationPostRequestDto(model.name);
  }
}

export {MedicationPostRequestDtoFactory};
