export class FileUploaderLegacyHelper {
  static toDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(String(reader.result));
      reader.onerror = (error) => reject(error);
    });
  }

  static getAcceptTypes(accept: string) {
    return accept.replace(/\s+/g, '').split(',');
  }

  static getFileType(file: File) {
    const fileNameSections = file.name?.split('.');

    const extension =
      fileNameSections.length === 1 || (fileNameSections[0] === '' && fileNameSections.length === 2)
        ? ''
        : fileNameSections.pop();

    return !!extension ? extension.toLowerCase() : '';
  }

  static getPreparedDataUrl(dataUrl: any) {
    return String(dataUrl).split(',')[1];
  }
}
