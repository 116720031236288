function getSurvey<T extends {lastUpdated: Date | null}>(surveys: T[]): T | null {
  const surveysSorted = surveys.slice().sort((assessment1, assessment2) => {
    if (assessment2?.lastUpdated && assessment1?.lastUpdated) {
      return assessment2.lastUpdated > assessment1.lastUpdated
        ? 1
        : assessment2.lastUpdated < assessment1.lastUpdated
        ? -1
        : 0;
    }

    return -1;
  });

  const [first] = surveysSorted;

  return Boolean(first?.lastUpdated) ? first : null;
}

export {getSurvey};
