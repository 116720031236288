import type {FC} from 'react';
import {useEffect, useState} from 'react';
import type {IHttpService} from '../../../utils/http';
import {HTTP_SERVICE} from '../../../utils/http';
import {observer} from 'mobx-react';
import {useInjection} from '../../../ioc';

interface InfraInfoDto {
  readonly apiVersion: string;
  readonly apiDeploymentDate: string;
  readonly dbVersion: string;
  readonly dbDeploymentDate: string;
  readonly apiBuildVersion: string;
  readonly dbBuildVersion: string;
}

type InfraVersion = Record<string, string>;

const Version: FC<{http: IHttpService}> = ({http}) => {
  const [infraInfo, setInfraInfo] = useState<InfraVersion>({});

  useEffect(() => {
    fetch('/version.txt')
      .then((res) => res.text())
      .then((res) =>
        setInfraInfo((infra) => ({
          ...infra,
          UI: res ?? '',
        })),
      )
      .then(() => {
        http.get<InfraInfoDto>('/version').then(({data}) => {
          setInfraInfo((infra) => ({
            ...infra,
            API: data.apiVersion,
            DB: data.dbVersion,
          }));
        });
      });
  }, [http]);

  const versionString = Object.keys(infraInfo)
    .map((i) => `${i}_${infraInfo[i]}`)
    .join('_');

  return <div>Full version info: {versionString}</div>;
};

const ObserverVersion = observer(Version);
const InjectedVersion: FC = () => <ObserverVersion http={useInjection<IHttpService>(HTTP_SERVICE)} />;

export {InjectedVersion as Version};
