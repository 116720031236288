import type {ISurveyElementResponse, ISurveyFormValue, ISurveySpecElement} from '../../model';
import {SurveySpecElementType} from '../../model';
import {isArray} from '../../repository/helpers';

const getComboboxFormValueByResponseValue = (
  elementValue: ISurveyElementResponse,
  flattenedElements: Array<ISurveySpecElement>,
): {value: string; displayName: string} | undefined => {
  const option = flattenedElements
    .find((el) => el.name === elementValue.name)
    ?.options?.find((op) => op.value === elementValue.value);

  return !!option ? {value: String(option.elementOptionId), displayName: option.value} : undefined;
};

const getDefaultValues = (
  elementValues: Array<ISurveyElementResponse>,
  flattenedElements: Array<ISurveySpecElement>,
  isEverCompleted?: boolean,
): {[name: string]: ISurveyFormValue} =>
  elementValues.length && isEverCompleted
    ? elementValues.reduce((obj: {[name: string]: ISurveyFormValue}, elementValue) => {
        if (!!elementValue.value && !!elementValue.name) {
          const elementType = flattenedElements.find((el) => el.name === elementValue.name)?.elementType;

          switch (elementType) {
            case SurveySpecElementType.Combobox:
              if (
                flattenedElements.find((el) => el.name === elementValue.name)?.elementType ===
                SurveySpecElementType.Combobox
              ) {
                const possibleValueToAdd = getComboboxFormValueByResponseValue(elementValue, flattenedElements);

                const valueArray = obj[elementValue.name];

                if (isArray(valueArray) && possibleValueToAdd) {
                  obj[elementValue.name] = [...valueArray, possibleValueToAdd];
                }
              }

              return obj;
            case SurveySpecElementType.Select: {
              return {
                ...obj,
                [elementValue.name]: {
                  id: elementValue.elementOptionId,
                  text: elementValue.value,
                },
              };
            }
            default:
              return {
                ...obj,
                [elementValue.name]: elementValue.value,
              };
          }
        }

        return obj;
      }, {})
    : flattenedElements.reduce((obj: {[name: string]: ISurveyFormValue}, elementValue) => {
        const value = typeof elementValue.metadata?.defaultValue === 'string' && elementValue.metadata?.defaultValue;

        return value
          ? {
              ...obj,
              [elementValue.name]: value,
            }
          : {
              ...obj,
            };
      }, {});

export {getDefaultValues};
