import {inject, injectable} from 'inversify';
import type {ILabResultsQuery, ILabResultsRepository} from './lab-results.repository.interface';
import type {LabResultsModel} from '../model';
import {LabResultsModelFactory} from '../model';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {ILabResultsGetResponseDto} from './dto';
import {LabResultsCreateRequestDtoFactory, LabResultsUpdateRequestDtoFactory} from './dto';

@injectable()
class LabResultsRepository implements ILabResultsRepository {
  constructor(@inject(HTTP_SERVICE) private readonly http: IHttpService) {}

  find(query?: Partial<ILabResultsQuery>): Promise<LabResultsModel | null> {
    throw new Error('Not implemented');
  }

  async list(query?: Partial<ILabResultsQuery>): Promise<LabResultsModel[]> {
    let models: LabResultsModel[] = [];

    await this.http
      .get<Array<ILabResultsGetResponseDto>>(`/LabResultInfo/getforpatient`)
      .then((response) => {
        models = response.data.map((dto) => LabResultsModelFactory.fromGetResponseDto(dto));
      })
      .catch(console.error);

    return models;
  }

  remove(): Promise<void> {
    throw new Error('Not implemented');
  }

  async save(model: LabResultsModel): Promise<LabResultsModel> {
    let responseDto: ILabResultsGetResponseDto | null = null;

    if (!!model.id) {
      const dto = LabResultsUpdateRequestDtoFactory.fromModel(model);

      await this.http
        .put<ILabResultsGetResponseDto>(`/LabResultInfo/${model.id}`, dto)
        .then((response) => {
          responseDto = response.data;
        })
        .catch(console.error);
    } else {
      const dto = LabResultsCreateRequestDtoFactory.fromModel(model);
      await this.http
        .post<ILabResultsGetResponseDto>('/LabResultInfo', dto)
        .then((response) => {
          responseDto = response.data;
        })
        .catch(console.error);
    }

    if (!responseDto) {
      throw new Error('Response dto is undefined');
    }

    return LabResultsModelFactory.fromGetResponseDto(responseDto);
  }
}

export {LabResultsRepository};
