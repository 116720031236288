import type {FC} from 'react';
import React from 'react';
import styles from './encounter-creator.module.scss';
import type {IOption} from '../../../../../types';

interface IEncounterCreatorProps {
  creator: IOption;
  onClick: (item: IOption) => void;
}

const EncounterCreator: FC<IEncounterCreatorProps> = (props) => (
  <div
    className={styles.filterItem}
    onClick={() => {
      props.onClick(props.creator);
    }}>
    {props.creator.displayName}
  </div>
);

export {EncounterCreator};
export type {IEncounterCreatorProps};
