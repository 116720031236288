const providerTitle = new Map([
  ['MD', 'Doctor of Medicine'],
  ['DO', 'Doctor of Osteopathy'],
  ['NP', 'Nurse Practitioner'],
  ['RN', 'Registered Nurse'],
  ['RD', 'Registered Dietitian'],
  ['RDN', 'Registered Dietitian Nutritionist'],
  ['PA', 'Physician Assistant'],
  ['LMSW', 'Elevate Coach'],
  ['LCSW', 'Elevate Coach'],
  ['RS', 'Elevate Coach'],
  ['RС', 'Elevate Coach'],
  ['MA', 'Medical Assistant'],
]);

export {providerTitle};
