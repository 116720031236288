import type {FC} from 'react';
import {useCallback, useState} from 'react';
import {Button} from '../../../components';
import {CourseIframe} from '../../../course-iframe/components';
import {EducationCourseRateModal} from '../../../education';
import type {CourseTask} from '../../../patient-course/model/course-task';
import type {TaskModel} from '../../model';
import {Complete} from './complete';
import styles from './task-actions.module.scss';

interface IOwnProps {
  id: number;
  complete: boolean;
  completeDate?: Date | null;
  beginDate?: Date | null;
  lmsLink?: string;
  timeZone: string;
  isSkippable: boolean;
  onBegin?: (id: number) => void;
  onComplete?: (id: number, begin?: boolean, skip?: boolean) => void;
  courseLink?: string;
  task: TaskModel | CourseTask;
}
export const TaskActions: FC<IOwnProps> = ({
  beginDate,
  completeDate,
  courseLink,
  id,
  isSkippable,
  lmsLink,
  onBegin,
  onComplete,
  task,
  timeZone,
}) => {
  const showSkip = isSkippable && !beginDate;
  const showRead =
    task.featuredContent && isSkippable;
  const [iframeSrc, setIframeSrc] = useState<string | undefined>('');
  const [isShowRate, setIsShowRate] = useState(false);

  const completeStyles = {
    ...(!beginDate ? {disabled: true, gray: true} : {success: true}),
  };

  const closeIframe = useCallback(() => {
    onBegin && onBegin(id);
    setIframeSrc('');
  }, [onBegin, id]);

  const closeRateWithSendHandler = useCallback(() => {
    setIsShowRate(false);
    onComplete && onComplete(id);
  }, [onComplete, id]);

  const openRateHandler = useCallback(() => {
    setIsShowRate(true);
  }, []);

  const handleComplete = useCallback(() => {
    !!beginDate && openRateHandler();
  }, [beginDate, openRateHandler]);

  const handleSkip = useCallback(() => {
    onComplete && onComplete(id, false, true);
  }, [id, onComplete]);

  const handleBegin = useCallback(() => {
    setIframeSrc(courseLink);
  }, [courseLink]);

  return (
    <div className={styles.wrapper}>
      <CourseIframe src={iframeSrc} lmsLink={lmsLink} onClose={closeIframe} onComplete={handleComplete} />
      {!!onComplete && !!onBegin && (
        <Button primary radius onClick={handleBegin}>
          {completeDate ? 'Restart' : 'Begin'}
        </Button>
      )}
      {completeDate ? (
        <Complete showRead={showRead} completeDate={completeDate} timeZone={timeZone} />
      ) : !!onComplete && !!onBegin ? (
        <Button {...(!showSkip ? completeStyles : {})} onClick={showSkip ? handleSkip : handleComplete}>
          {showSkip ? 'Skip Lesson' : 'Complete'}
        </Button>
      ) : null}
      {isShowRate && <EducationCourseRateModal onClose={closeRateWithSendHandler} task={task} />}
    </div>
  );
};
