import {ContainerModule} from 'inversify';
import type {IAlertIndicatorService} from '../service';
import {ALERT_INDICATOR_SERVICE, AlertIndicatorService} from '../service';
import type {IAlertIndicatorRepository} from '../repository';
import {ALERT_INDICATOR_REPOSITORY, AlertIndicatorRepository} from '../repository';
import {HTTP_SERVICE} from '../../utils/http';

const AlertIndicatorModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IAlertIndicatorRepository>(ALERT_INDICATOR_REPOSITORY).to(AlertIndicatorRepository).inSingletonScope();

  bind<IAlertIndicatorService>(ALERT_INDICATOR_SERVICE).to(AlertIndicatorService).inSingletonScope();
});

export {AlertIndicatorModule};
