const routes = {
  default: '/',
  diseaseInformation: '/disease-information',
  messages: '/messages',
  survey: '/survey',
  symptoms: '/symptoms',
  accountSetup: '/account-setup',
  home: '/home',
  myDashboard: '/my-dashboard',
  myRoadmap: '/my-roadmap',
  goalJournal: '/goal-journal',
  thriveSkillSelection: '/thrive-skill-selection',
  dailySymptomTracker: '/daily-symptom-tracker',
  weeklyWellnessReport: '/weekly-wellness-report',
  tasks: '/tasks',
  reports: '/reports',
  profile: '/profile',
  encounters: '/encounters',
  surveys: '/surveys',
  notifications: '/notifications',
  version: '/version',
  trellusTeamMemberProfile: '/trellus-team-member-profile',
  strengthsAssessment: '/strengths-assessment',
  strengthsAssessmentsList: '/strengths-assessments-list',
  vaccination: '/vaccination',
  labResultsTracking: '/lab-results-tracking',
  medications: '/medications',
  assessmentReport: '/assessment-report',
  pdf: '/pdf',
  preScreening: '/pre-screening',
  resilienceAssessment: '/resilience-assessment',
  selfEfficacy: '/self-efficacy',
  selfEfficacyList: '/self-efficacy-list',
  diseaseSeverityScreener: '/disease-severity-screener',
  diseaseSeverityScreenerList: '/disease-severity-screener-list',
  symptomSeverityScale: '/symptom-severity-scale',
  symptomSeverityScaleList: '/symptom-severity-scale-list',
  emotionalImpactScale: '/emotional-impact-scale',
  emotionalImpactScaleList: '/emotional-impact-scale-list',
  confirmEmail: '/confirm-email',
  addWeight: '/add-weight',
  chronicConditionChecklists: '/chronic-condition-checklists',
  chronicConditionChecklist: '/chronic-condition-checklist',
  workProductivity: '/work-productivity',
  lifeQuality: '/life-quality',
  globalImprovementScale: '/global-improvement-scale',
  paymentInformation: '/payment-information',
};

export {routes};
