class AlertIndicatorModel {
  constructor(
    public hasUnreadMessages: boolean = false,
    public hasUnreadNotifications: boolean = false,
    public hasAllowedStrengthsAssessment: boolean = false,
    public hasAllowedEmotionalImpactScale: boolean = false,
    public hasAllowedSelfEfficacyAssessment: boolean = false,
    public hasAllowedResilienceAssessment: boolean = false,
    public hasAllowedDiseaseSeverityScreenerAssessment: boolean = false,
    public hasAllowedChronicConditionChecklistAssessment: boolean = false,
  ) {}
}

export {AlertIndicatorModel};
