import type {IOption} from '../../types';
import type {ISurveyRadioButtonOption} from '../../components';

type CheckFormatFormResponseHelper = string | Date | IOption<string> | ISurveyRadioButtonOption;

const isDropdown = (response: CheckFormatFormResponseHelper): response is {value: string; displayName: string} =>
  typeof response === 'object' && 'value' in response && 'displayName' in response;

const isRadioButton = (response: CheckFormatFormResponseHelper): response is {id: string; text: string} =>
  typeof response === 'object' && 'id' in response && 'text' in response;

const isDate = (response: CheckFormatFormResponseHelper): response is Date => response instanceof Date;

export {isDropdown, isRadioButton, isDate};
