import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import styles from './my-roadmap.module.scss';
import {Icon, IconType, List, PageTitle} from '../../../components';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import {useInjection} from '../../../ioc';
import {TaskListItem} from '../../../task/components/task-list-item';
import type {IAuthConfigService} from '../../../utils/auth/config';
import {AUTH_CONFIG_SERVICE} from '../../../utils/auth/config';
import {Course} from './components/course';
import {TrellisVisualization} from '../../../trellis-visualization/components/visualization/visualization.component';
import {COURSE_SERVICE} from '../../../patient-course/service';
import type {ICourseService} from '../../../patient-course/service';
import {routes} from '../../../routing';
import type {ILayoutsService} from '../../../layouts/service';
import {LAYOUTS_SERVICE, NavigationIds} from '../../../layouts/service';
import classnames from 'classnames';

const LINK_ICON_SIZE = 20;

interface IProps {
  account: IAccountService;
  course: ICourseService;
  authConfig: IAuthConfigService;
  layouts: ILayoutsService;
}

const MyRoadmap: React.FC<IProps> = ({account, authConfig, layouts, course}): JSX.Element => {
  const [showOnlyFavorite, setOnlyFavorite] = useState(false);

  const onLessonComplete = useCallback(
    (id: number, begin = false, skip = false) => {
      course.updateTask(id, begin, skip);
    },
    [course],
  );
  const onLessonBegin = useCallback(
    (id: number) => {
      course.updateTask(id, true);
    },
    [course],
  );

  const setFavoriteLesson = useCallback(
    (taskId: number, isFavorite: boolean) => {
      if (taskId) {
        course.setFavoriteLesson(taskId, isFavorite);
      }
    },
    [course],
  );

  useEffect(() => {
    course.loadCourses();
  }, [course]);

  const isGoalJournalLinkVisible = layouts.getNavigationVisibility(NavigationIds.MyGoalJournal);

  return (
    <>
      <section className={styles.section}>
        <div className={styles.sectionHeader}>
          <PageTitle>My Roadmap</PageTitle>
        </div>
        <div className={classnames(styles.sectionLinks, {[styles.singleLink]: !isGoalJournalLinkVisible})}>
          {isGoalJournalLinkVisible && (
            <Link to={`${routes.home}${routes.goalJournal}`}>
              <Icon type={IconType.GoalFlowerFilled} width={LINK_ICON_SIZE} height={LINK_ICON_SIZE} />
              <button className={styles.selectButton}>Goal Journal</button>
            </Link>
          )}
          <button className={styles.selectButton} onClick={() => setOnlyFavorite(!showOnlyFavorite)}>
            {!showOnlyFavorite ? (
              <Icon type={IconType.FavoriteSimpleFilled} width={LINK_ICON_SIZE} height={LINK_ICON_SIZE} />
            ) : null}
            {!showOnlyFavorite ? 'Favorite Lessons' : 'Courses'}
          </button>
        </div>

        <TrellisVisualization />
        {showOnlyFavorite ? (
          <List noStyle title="My Favorite Lessons" className={styles.list}>
            {course.incompleteLessons.map((i) =>
              i?.isFavoriteLesson === true ? (
                <TaskListItem
                  key={i.id}
                  task={i}
                  showFavoriteIcon={true}
                  timeZone={account.timeZone}
                  onBegin={onLessonBegin}
                  onLessonComplete={onLessonComplete}
                  lmsLink={authConfig.lmsCourseLink}
                  isLesson={true}
                  onChangeFavoriteItem={setFavoriteLesson}
                />
              ) : null,
            )}
            {course.completeLessons.map((i) =>
              i?.isFavoriteLesson === true ? (
                <TaskListItem
                  key={i.id}
                  task={i}
                  showFavoriteIcon={true}
                  timeZone={account.timeZone}
                  onBegin={onLessonBegin}
                  onLessonComplete={onLessonComplete}
                  lmsLink={authConfig.lmsCourseLink}
                  isLesson={true}
                  onChangeFavoriteItem={setFavoriteLesson}
                />
              ) : null,
            )}
          </List>
        ) : (
          <>
            {!!course.inCompleted.length && (
              <List noStyle title="Courses">
                {course.inCompleted.map((course) => (
                  <Course
                    key={`course-id-${course.patientCourseId}`}
                    course={course}
                    tasks={course.tasks}
                    onChangeFavoriteItem={setFavoriteLesson}
                    timeZone={account.timeZone}
                    onLessonComplete={onLessonComplete}
                    lmsLink={authConfig.lmsCourseLink}
                    onBegin={onLessonBegin}
                  />
                ))}
              </List>
            )}
            {!!course.completed.length && (
              <List noStyle title="Completed">
                {course.completed.map((course) => (
                  <Course
                    key={`course-id-${course.patientCourseId}`}
                    course={course}
                    tasks={course.tasks}
                    onChangeFavoriteItem={setFavoriteLesson}
                    timeZone={account.timeZone}
                    onLessonComplete={onLessonComplete}
                    lmsLink={authConfig.lmsCourseLink}
                    onBegin={onLessonBegin}
                    completeDate={course.behaviorTypeCompletionDate}
                    isComplete={true}
                  />
                ))}
              </List>
            )}
          </>
        )}
      </section>
    </>
  );
};

const ObservableMyRoadmapScreen = observer(MyRoadmap);
const MyRoadmapScreenInjected: React.FC = () => (
  <ObservableMyRoadmapScreen
    account={useInjection(ACCOUNT_SERVICE)}
    course={useInjection(COURSE_SERVICE)}
    authConfig={useInjection(AUTH_CONFIG_SERVICE)}
    layouts={useInjection(LAYOUTS_SERVICE)}
  />
);

export {MyRoadmapScreenInjected as MyRoadmapScreen};
