import classNames from 'classnames';
import {useMemo} from 'react';
import {generateTooltipForGroupEvents} from '../../../helper';
import type {IBranch} from '../../../model';
import {EEventType} from '../../../model';
import {
  FlowerCourseCompletion1,
  FlowerCourseCompletion2,
  FlowerCourseCompletion3,
  FlowerEndOfCategory,
  FlowerEndOfVine,
  FlowerSelfEfficacy,
  FlowerWeeklyWellness,
} from '../flower/flowers.component';
import {LeafRed, LeafYellow} from '../leaf/leafs.component';
import styles from './branch.module.scss';

interface IBranchProps {
  index: number;
  branch: Partial<IBranch>;
  hasFinished: boolean;
}

const leafComponents = [LeafRed, LeafYellow];
const flowerComponents = [FlowerCourseCompletion1, FlowerCourseCompletion2, FlowerCourseCompletion3];

const GreenLeaf = (leafPath?: string, isEven = true) => {
  const leafClass = classNames({
    [styles.greenLeaf]: isEven,
    [styles.lightGreenLeaf]: !isEven,
  });

  return <>{leafPath && <path data-testid="green-leaf" d={leafPath} className={leafClass} />}</>;
};

const getRandomComponent = (
  components: any,
  x: number,
  y: number,
  angle: number,
  title: string,
  numericalIndicator = 0,
) => {
  const randomIndex = Math.floor(Math.random() * components.length);
  const Component = components[randomIndex];

  return <Component x={x} y={y} angle={angle} title={title} numericalIndicator={numericalIndicator} />;
};

// Component to render a branch
export const Branch = ({index, branch, hasFinished}: IBranchProps) => {
  // Memoize the component based on branch type and event triggers
  const renderElement = useMemo(() => {
    const x = branch.coordinates?.x || 0;
    const y = branch.coordinates?.y || 0;
    const angle = branch.coordinates?.angle || 0;

    if (branch.eventTriggers && branch.eventTriggers.length > 0) {
      const numericalIndicator = branch.eventTriggers?.length > 1 ? branch.eventTriggers.length : 0;
      const title = generateTooltipForGroupEvents(branch.eventTriggers);

      switch (branch.leafType) {
        case EEventType.Flower:
          return getRandomComponent(flowerComponents, x, y, angle, title, numericalIndicator);
        case EEventType.Leaf:
          return getRandomComponent(leafComponents, x, y, angle, title, numericalIndicator);
        case EEventType.SelfEfficacy:
          return <FlowerSelfEfficacy x={x} y={y} title={title} numericalIndicator={numericalIndicator} />;
        case EEventType.WeeklyWellness:
          return <FlowerWeeklyWellness x={x} y={y} title={title} numericalIndicator={numericalIndicator} />;
        case EEventType.EndOfCategory:
          return <FlowerEndOfCategory x={x} y={y} title={title} numericalIndicator={numericalIndicator} />;
        case EEventType.EndOfVine:
          return <FlowerEndOfVine x={x} y={y} />;
        default:
          return GreenLeaf(branch.leafPath, index % 2 === 0);
      }
    } else {
      if (branch.leafType === EEventType.EndOfVine && hasFinished) {
        return <FlowerEndOfVine x={x} y={y} />;
      }

      return GreenLeaf(branch.leafPath, index % 2 === 0);
    }
  }, [branch, hasFinished, index]);

  return (
    <g>
      {/* Always render the branch path */}
      <path data-testid="branch" d={branch.branchPath} className={styles.branch} />

      {renderElement}
    </g>
  );
};
