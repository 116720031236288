import type {FC} from 'react';
import React, {useMemo} from 'react';
import {MedicationsCardRow} from '../card-row';
import {MedicationsCardWrapper} from '../card-wrapper';
import styles from '../../screen/medication-screen.module.scss';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../../helpers';

interface IMedicationsInfoDiscontinueProps {
  discontinue: Date | null;
  discontinuedBy: string;
  dose: string;
  start: Date | null;
  end: Date | null;
  lastUpdated: Date;
  name: string;
  reason: string;
}

const MedicationsInfoDiscontinue: FC<IMedicationsInfoDiscontinueProps> = ({
  discontinue,
  discontinuedBy,
  dose,
  end,
  lastUpdated,
  name,
  reason,
  start,
}) => {
  const coursePeriodDate = useMemo(() => {
    const startDate = start ? `${format(start, DEFAULT_FORMAT)}` : '—';
    const endDate = end ? `${format(end, DEFAULT_FORMAT)}` : '—';

    return (
      <>
        {startDate} / {endDate}
      </>
    );
  }, [end, start]);

  const discontinueDate = useMemo(() => (discontinue ? format(discontinue, DEFAULT_FORMAT) : '—'), [discontinue]);
  const lastUpdatedDate = useMemo(() => format(lastUpdated, DEFAULT_FORMAT), [lastUpdated]);

  return (
    <>
      <MedicationsCardRow>
        <MedicationsCardWrapper label={'Medication'}>
          <div className={styles.modalInfoText}>{name}</div>
        </MedicationsCardWrapper>
      </MedicationsCardRow>
      <MedicationsCardRow>
        <MedicationsCardWrapper label={'Course'}>
          <div className={styles.modalInfoText}>{coursePeriodDate}</div>
        </MedicationsCardWrapper>
        <MedicationsCardWrapper label={'Dose'}>
          <div className={styles.modalInfoText}>{dose}</div>
        </MedicationsCardWrapper>
      </MedicationsCardRow>
      <MedicationsCardRow>
        <MedicationsCardWrapper label={'D/C Date'}>
          <div className={styles.modalInfoText}>{discontinueDate}</div>
        </MedicationsCardWrapper>
        <MedicationsCardWrapper label={'D/C Reason'}>
          <div className={styles.modalInfoText}>{reason}</div>
        </MedicationsCardWrapper>
      </MedicationsCardRow>
      <MedicationsCardRow>
        <MedicationsCardWrapper label={'Last updated'}>
          <div className={styles.modalInfoText}>{lastUpdatedDate}</div>
        </MedicationsCardWrapper>
        <MedicationsCardWrapper label={'Discontinued by'}>
          <div className={styles.modalInfoText}>{discontinuedBy}</div>
        </MedicationsCardWrapper>
      </MedicationsCardRow>
    </>
  );
};

export {MedicationsInfoDiscontinue};
