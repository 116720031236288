const generateLabels = (
  values: number[],
  labels?: string[],
  startLabel?: string,
  endLabel?: string,
): string[] | null => {
  let generatedLabels = null;

  if (startLabel && endLabel) {
    generatedLabels = values.map(() => '');
    generatedLabels[0] = startLabel;
    generatedLabels[generatedLabels.length - 1] = endLabel;
  }

  if (labels?.length) {
    generatedLabels = values.map(() => '');

    if (labels && values.length !== labels.length) {
      generatedLabels[0] = labels[0];
      generatedLabels[generatedLabels.length - 1] = labels[1];
    } else {
      generatedLabels = labels;
    }
  }

  return generatedLabels;
};

export {generateLabels};
