import type {SurveyPeriodType, SurveyType} from '../../../../model';

class SurveyGetResponsesRequestDto {
  constructor(
    public patientId: number = -1,
    public surveyType: SurveyType | null = null,
    public periodType: SurveyPeriodType | null = null,
    public downwardPeriodDate: Date | null = null,
  ) {}
}

export {SurveyGetResponsesRequestDto};
