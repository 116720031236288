import type {Dispatch, FC, SetStateAction} from 'react';
import React, {useCallback, useEffect, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Button, Card, CardActions, CardContent, CardHeader, Error, Input, Modal} from '../../../../../../../components';
import styles from './user-name-form.module.scss';
import {PATTERN_USER_NAME_ERROR, REQUIRED_FIELD_ERROR} from '../../../../../../../helpers';
import {Patterns} from '../../../../../../../helpers/patterns';
import type {IProfileSettingsService} from '../../../../../../../profile-settings';
import {PROFILE_SETTINGS_SERVICE} from '../../../../../../../profile-settings';
import {observer} from 'mobx-react';
import {useInjection} from '../../../../../../../ioc';
import {useCountdownTimer} from '../../../../../../../hooks';
import type { IAccountService} from '../../../../../../../account';
import {ACCOUNT_SERVICE} from '../../../../../../../account';

interface IUserNameFormProps {
  onClose: () => void;
  openTwoFactorAuth: Dispatch<SetStateAction<boolean>>;
}

interface IServiceProps {
  profileSettingsService: IProfileSettingsService;
  account: IAccountService;
}

const name = 'userName';

const MAX_USER_NAME_LENGTH = 128;
const MAX_USER_NAME_LENGTH_ERROR_MESSAGE = 'Maximum length: 128 characters';
const OLD_USERNAME_ERROR = 'The new username must be different from the old one';

type UserNameFormDto = Record<'userName', string>;

const UserNameForm: FC<IServiceProps & IUserNameFormProps> = ({
  profileSettingsService,
  account,
  onClose,
  openTwoFactorAuth,
}) => {
  const [resendCodeTimer, reloadResendCodeTimer, isDuringResetCodeTimer] = useCountdownTimer(
    0,
    profileSettingsService.resendCodeTimeout,
  );

  const {
    control,
    formState: {errors, isSubmitting},
    watch,
    handleSubmit,
  } = useForm<UserNameFormDto>({
    defaultValues: {
      userName: account.info?.userName,
    },
  });

  const onFormSubmit = useCallback(
    async (dto: UserNameFormDto) => {
      await profileSettingsService.changeUserName(dto.userName);

      if (
        profileSettingsService.isUserNameValid &&
        profileSettingsService.phoneForVerifying &&
        profileSettingsService.isCodeRequested &&
        !profileSettingsService.isWaitedResendCode
      ) {
        onClose();
        openTwoFactorAuth(true);
      }
    },
    [onClose, openTwoFactorAuth, profileSettingsService],
  );

  useEffect(() => {
    if (account.id && profileSettingsService.isUserNameUpdated) {
      account.load();
    }
  }, [profileSettingsService.isUserNameUpdated, account]);

  useEffect(() => {
    if (profileSettingsService.isWaitedResendCode && !isDuringResetCodeTimer) {
      reloadResendCodeTimer(0, profileSettingsService.resendCodeTimeout);
    }
  }, [
    isDuringResetCodeTimer,
    profileSettingsService.isWaitedResendCode,
    profileSettingsService.resendCodeTimeout,
    reloadResendCodeTimer,
  ]);

  const isSubmitDisabled = useMemo(
    () => profileSettingsService.isWaitedResendCode || !watch(name)?.length || isSubmitting,
    [isSubmitting, profileSettingsService.isWaitedResendCode, watch],
  );

  const actions = (
    <CardActions className={styles.bottomWrapper}>
      <div className={styles.resendCodeTimer}>
        {profileSettingsService.isWaitedResendCode &&
          isDuringResetCodeTimer &&
          `You can try again in ${resendCodeTimer} sec`}
      </div>
      <div className={styles.buttons}>
        {onClose && (
          <Button flat onClick={onClose}>
            Cancel
          </Button>
        )}
        <Button type={'submit'} disabled={isSubmitDisabled}>
          Ok
        </Button>
      </div>
    </CardActions>
  );

  return (
    <Modal>
      <Card className={styles.card}>
        <CardHeader title={'New Username'} closable noBorder onClose={onClose} />
        <CardContent>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Controller
              control={control}
              name={name}
              rules={{
                required: REQUIRED_FIELD_ERROR,
                maxLength: {
                  value: MAX_USER_NAME_LENGTH,
                  message: MAX_USER_NAME_LENGTH_ERROR_MESSAGE,
                },
                pattern: {
                  value: Patterns.UserName,
                  message: PATTERN_USER_NAME_ERROR,
                },
                validate: (value) => value !== account.info?.userName ?? OLD_USERNAME_ERROR,
              }}
              render={({name, onChange, value}) => (
                <>
                  <Input
                    name={name}
                    value={value}
                    onValueChanged={onChange}
                    placeholder={'Please, enter new username...'}
                    isValid={!errors?.userName?.message}
                    className={styles.input}
                  />
                  {!!errors?.userName?.message && <Error errorMessage={errors.userName.message} name={name} />}
                </>
              )}
            />
            <div className={styles.current}>
              Your current user name: <span>{account.info?.userName}</span>
            </div>
            {actions}
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
};

const UserNameFormObserver = observer(UserNameForm);
const UserNameFormInjected: FC<IUserNameFormProps> = (props) => (
  <UserNameFormObserver
    {...props}
    profileSettingsService={useInjection(PROFILE_SETTINGS_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
  />
);

export {UserNameFormInjected as UserNameForm};
