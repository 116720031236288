import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {AssessmentReportModel, AssessmentReportModelFactory} from '../model';
import type {IAssessmentReportService} from './assessment-report.service.interface';
import type {IAssessmentReportGetResponseDto} from '../dto';
import type {IDataPdf} from '../../helpers';
import {pdfConverter} from '../../helpers';
import printJS from 'print-js';

@injectable()
class AssessmentReportService implements IAssessmentReportService {
  private _isLoading: boolean = false;

  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {
    makeAutoObservable(this);
  }

  private _isDataExists: boolean = false;

  get isDataExists(): boolean {
    return this._isDataExists;
  }

  private _report: AssessmentReportModel = new AssessmentReportModel();

  get report(): AssessmentReportModel {
    return this._report;
  }

  async load(): Promise<void> {
    if (!this._isLoading) {
      this._isLoading = true;

      const res = await this._http.get<IAssessmentReportGetResponseDto>(`/Resilience/resilience`).catch(console.error);

      if (res && res.data) {
        this._report = AssessmentReportModelFactory.fromGetResponseDtoRecord(res.data);

        this._isDataExists = true;
      }

      this._isLoading = false;
    }
  }

  async printResilienceAssessmentFile(): Promise<void> {
    await this._http
      .get<IDataPdf>('/Resilience/resilienceAssessmentFile')
      .then(({data}) => {
        const pdf = pdfConverter(data);

        if (pdf) {
          printJS({printable: pdf});
        }
      })
      .catch(console.error);
  }
}

export {AssessmentReportService};
