import type {FC} from 'react';
import React, {useMemo} from 'react';
import {Button, Card, CardActions, Modal} from '../../../../../../../components';
import styles from './allow-modal.module.scss';

interface IAllowBaseModalProps {
  onClose(): void;
  onSubmit(): void;
}
export const AllowBaseModal: FC<IAllowBaseModalProps> = ({onSubmit, onClose, children}) => {
  const actions = useMemo(
    () => (
      <CardActions className={styles.cardActions}>
        <Button flat onClick={onClose} className={styles.cancel} testid={'cancel-modal'}>
          Cancel
        </Button>
        <Button type={'button'} onClick={onSubmit} testid={'submit-modal'}>
          Confirm
        </Button>
      </CardActions>
    ),
    [onClose, onSubmit],
  );

  return (
    <Modal>
      <Card className={styles.modalRoot}>
        {children}
        {actions}
      </Card>
    </Modal>
  );
};
