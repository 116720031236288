interface IChronicConditionChecklistItem {
  question: string;
  value: string;
}

class ChronicConditionChecklistModel {
  constructor(
    public items: IChronicConditionChecklistItem[] = [],
    public id: number = -1,
    public lastUpdated: Date | null = null,
    public name: string = '',
  ) {}
}

export {ChronicConditionChecklistModel};
export type {IChronicConditionChecklistItem};
