import type {IItemCoordinates} from '../../model';
import type {IPotFlower} from './pot.types';
import {PotFlowerTypeEnum} from './pot.types';
import {
  PottedBlue2Flower,
  PottedBlueFlower,
  PottedLavenderFlower,
  PottedMarigoldFlower,
  PottedOrangeFlower,
  PottedPinkFlower,
  PottedPinkSprayFlower,
  PottedPurpleFlower,
  PottedRedFlower,
} from './pot.helper';

const DEFAULT_CENTER = 500;
const C_STEP = 80;
const Y_LINE_1 = 440;
const Y_LINE_2 = Y_LINE_1 + 23;

export const potCoordinatesMap: IItemCoordinates[] = [
  {x: DEFAULT_CENTER - C_STEP * 4, y: Y_LINE_1}, //1
  {x: DEFAULT_CENTER + C_STEP * 4, y: Y_LINE_1}, //2
  {x: DEFAULT_CENTER - C_STEP * 2, y: Y_LINE_1}, //3
  {x: DEFAULT_CENTER + C_STEP * 2, y: Y_LINE_1}, //4
  {x: DEFAULT_CENTER, y: Y_LINE_1}, //5
  {x: DEFAULT_CENTER - C_STEP * 3, y: Y_LINE_2}, //6
  {x: DEFAULT_CENTER + C_STEP * 3, y: Y_LINE_2}, //7
  {x: DEFAULT_CENTER - C_STEP, y: Y_LINE_2}, //8
  {x: DEFAULT_CENTER + C_STEP, y: Y_LINE_2}, //9
  {x: DEFAULT_CENTER - C_STEP * 5, y: Y_LINE_2}, //10
  {x: DEFAULT_CENTER + C_STEP * 5, y: Y_LINE_2}, //11
];

export const renderPotFlowerElement = (item: IPotFlower, coordinates: IItemCoordinates) => {
  switch (item?.type) {
    case PotFlowerTypeEnum.Orange:
      return (
        <PottedOrangeFlower
          x={coordinates.x}
          y={coordinates.y}
          count={item.flowerCount as number}
          modifier={item.modifier}
          text={item.text}
          key={item?.type}
        />
      );
    case PotFlowerTypeEnum.Blue:
      return (
        <PottedBlueFlower
          x={coordinates.x}
          y={coordinates.y}
          modifier={item.modifier}
          text={item.text}
          key={item?.type}
        />
      );
    case PotFlowerTypeEnum.Lavender:
      return (
        <PottedLavenderFlower
          x={coordinates.x}
          y={coordinates.y}
          modifier={item.modifier}
          text={item.text}
          key={item?.type}
        />
      );
    case PotFlowerTypeEnum.Blue2:
      return (
        <PottedBlue2Flower
          x={coordinates.x}
          y={coordinates.y}
          modifier={item.modifier}
          text={item.text}
          key={item?.type}
        />
      );
    case PotFlowerTypeEnum.Marigold:
      return <PottedMarigoldFlower x={coordinates.x} y={coordinates.y} text={item.text} key={item?.type} />;
    case PotFlowerTypeEnum.Red:
      return (
        <PottedRedFlower
          x={coordinates.x}
          y={coordinates.y}
          modifier={item.modifier}
          text={item.text}
          key={item?.type}
        />
      );
    case PotFlowerTypeEnum.Purple:
      return (
        <PottedPurpleFlower
          x={coordinates.x}
          y={coordinates.y}
          modifier={item.modifier}
          text={item.text}
          key={item?.type}
        />
      );
    case PotFlowerTypeEnum.PinkSpray:
      return <PottedPinkSprayFlower x={coordinates.x} y={coordinates.y} text={item.text} key={item?.type} />;
    case PotFlowerTypeEnum.Pink:
      return (
        <PottedPinkFlower
          x={coordinates.x}
          y={coordinates.y}
          count={item.flowerCount as number}
          modifier={item.modifier}
          text={item.text}
          key={item?.type}
        />
      );
    default:
      return <></>;
  }
};
