import {makeAutoObservable} from 'mobx';
import {inject, injectable} from 'inversify';
import type {ISurveyService} from './survey.service.interface';
import type {ISurveyRepository} from '../repository';
import {SURVEY_REPOSITORY} from '../repository';
import type {ISurvey, ISurveyByDate, ISurveyFormDto} from '../model';
import {SurveyModel, SurveyPeriodType, SurveyType} from '../model';
import type {INotifierService} from '../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../notifier';
import {
  endOfDay,
  endOfWeek,
  isAfter,
  isBefore,
  isSameDay,
  isWithinInterval,
  lastDayOfWeek,
  startOfDay,
  subDays,
  subMinutes,
} from 'date-fns';
import {shiftToUTCEndOfDay} from '../../helpers';
import {utcToZonedTime} from 'date-fns-tz';

@injectable()
class SurveyService implements ISurveyService {
  private _surveysByDate: ISurveyByDate[] = [];

  constructor(
    @inject(SURVEY_REPOSITORY) private readonly _repo: ISurveyRepository,
    @inject(NOTIFIER_SERVICE) private readonly _notifier: INotifierService,
  ) {
    makeAutoObservable(this);
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _isSavingForm: boolean = false;

  get isSavingForm(): boolean {
    return this._isSavingForm;
  }

  getSurveyLastCompletedDate(type: SurveyType): Date | null {
    const survey =
      this._surveysByDate.find(
        (surveyByDate) => surveyByDate.type === type && Boolean(surveyByDate.survey.lastCompletedDate),
      )?.survey ?? null;

    return survey?.lastCompletedDate ?? null;
  }

  private getSurveyByDate(type: SurveyType, date: Date): ISurveyByDate | null {
    return (
      this._surveysByDate.find(
        (surveyByDate) => surveyByDate.type === type && surveyByDate.date.getTime() === date.getTime(),
      ) ?? null
    );
  }

  getSurvey(type: SurveyType, date: Date): ISurvey | null {
    return this.getSurveyByDate(type, date)?.survey ?? null;
  }

  getSurveyCompletedDate(type: SurveyType, date: Date): Date | null {
    return this.getSurveyByDate(type, date)?.completedDate ?? null;
  }

  isReadonly(date: Date, type: SurveyType, timeZone: string): boolean {
    let isReadonly;

    const lastCompletedDate = this.getSurveyLastCompletedDate(type);
    const nowDate = utcToZonedTime(new Date(), timeZone);

    if (type === SurveyType.SymptomTracking) {
      const isBeforeNow = isBefore(date.getTime(), startOfDay(startOfDay(new Date().getTime())));

      const isCompletedToday = !!lastCompletedDate && isSameDay(date.getTime(), startOfDay(lastCompletedDate));

      isReadonly = isBeforeNow || isCompletedToday;
    } else {
      isReadonly =
        (lastCompletedDate && this.checkDateEntryInPeriod(lastCompletedDate, date)) ||
        isAfter(endOfDay(endOfWeek(nowDate, {weekStartsOn: 4})), date);
    }

    return isReadonly;
  }

  checkDateEntryInPeriod(dateToAnalyse: Date, weekEndDate: Date) {
    const correctedDateToAnalysis = subMinutes(dateToAnalyse, new Date().getTimezoneOffset());

    return isWithinInterval(correctedDateToAnalysis, {
      start: startOfDay(subDays(weekEndDate, 6)),
      end: weekEndDate,
    });
  }

  async load(
    type: SurveyType,
    patientId: number,
    periodType: SurveyPeriodType,
    date: Date,
    isSending?: boolean,
  ): Promise<void> {
    this._isLoading = true;

    const model = await this._repo
      .find({type, date, periodType, patientId})
      .catch((e) => {
        console.error(e);
        this._notifier.notify({
          title: 'Error',
          text: 'Loading survey data error',
          type: NotifyMessageTypeEnum.Danger,
        });
      })
      .finally(() => {
        this._isLoading = false;
        this._isSavingForm = false;
      });

    if (model) {
      const [survey] = model.surveys;

      const newSurveyByDate: ISurveyByDate = {date, type, survey, completedDate: model.date};

      const index = this._surveysByDate.findIndex(
        (surveyByDate) =>
          surveyByDate.date.getTime() === newSurveyByDate.date.getTime() && surveyByDate.type === newSurveyByDate.type,
      );

      if (index > -1) {
        this._surveysByDate = [
          ...this._surveysByDate.slice(0, index),
          newSurveyByDate,
          ...this._surveysByDate.slice(index + 1),
        ];
      } else {
        this._surveysByDate = [...this._surveysByDate, newSurveyByDate];
      }
    }
  }

  async send(
    patientId: number,
    type: SurveyType,
    answers: ISurveyFormDto,
    needToShowNotify: boolean = false,
    isOnboarding: boolean = false,
    date: Date = new Date(),
  ): Promise<void> {
    this._isSavingForm = true;
    const survey = this._surveysByDate.find((surveyByDate) => surveyByDate.type === type)?.survey;

    if (survey) {
      const surveyModel = new SurveyModel(
        patientId,
        [
          {
            ...survey,
            formResponses: answers,
          },
        ],
        isOnboarding,
        type,
      );

      this._repo
        .save(surveyModel)
        .then(() => {
          if (needToShowNotify) {
            this._notifier.notify({
              text: 'Congrats on one more step toward a healthier you! We’ve received your update.',
              type: NotifyMessageTypeEnum.Success,
            });
          }

          const loadDate =
            type === SurveyType.SymptomTracking
              ? shiftToUTCEndOfDay(date)
              : subMinutes(endOfDay(lastDayOfWeek(date, {weekStartsOn: 4})), new Date().getTimezoneOffset());

          this.load(
            type,
            patientId,
            type === SurveyType.SymptomTracking ? SurveyPeriodType.Day : SurveyPeriodType.Week,
            loadDate,
            true,
          );
        })
        .catch((e) => {
          console.error(e);
          this._notifier.notify({
            title: 'Error',
            text: 'Saving data error',
            type: NotifyMessageTypeEnum.Danger,
          });
        });
    } else {
      this._notifier.notify({
        title: 'Error',
        text: "You can't save survey without survey info",
        type: NotifyMessageTypeEnum.Danger,
      });
    }
  }
}

export {SurveyService};
