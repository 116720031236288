import {HealthMonitorGraphTypes} from '../../model';

const healthMonitorChartLabels = new Map<HealthMonitorGraphTypes, string>([
  [HealthMonitorGraphTypes.Weight, '(Lb)'],
  [HealthMonitorGraphTypes.LabResultsCRP, '(mg/L)'],
  [HealthMonitorGraphTypes.LabResultsFecalCalprotectin, '(ug/g)'],
  [HealthMonitorGraphTypes.LabResultsVitaminD, '(ng/ML)'],
  [HealthMonitorGraphTypes.LabResultsHemoglobin, '(g/dL)'],
  [HealthMonitorGraphTypes.LabResultsAlbumin, '(g/dL)'],
]);

export {healthMonitorChartLabels};
