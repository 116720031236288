import type {FC} from 'react';
import React, {useCallback, useMemo} from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from '../../../../assets/icons';
import styles from './datepicker-header.module.scss';
import {format} from 'date-fns';

interface IDatepickerHeaderProps {
  currentDate: Date;
  showMonthYearPicker: boolean | undefined;
  disableDecreaseMonth?: boolean;
  disableIncreaseMonth?: boolean;
  disableDecreaseYear?: boolean;
  disableIncreaseYear?: boolean;
  onMonthDecrease: () => void;
  onMonthIncrease: () => void;
  decreaseDate: () => void;
  increaseDate: () => void;
}

const DatepickerHeader: FC<IDatepickerHeaderProps> = (props) => {
  const currentDateTitle = useMemo(
    () => (props.showMonthYearPicker ? format(props.currentDate, 'yyyy') : format(props.currentDate, 'MMMM yyyy')),
    [props.currentDate, props.showMonthYearPicker],
  );

  const disabledDecrease = useMemo(
    () => (!props.showMonthYearPicker ? props.disableDecreaseMonth : props.disableDecreaseYear),
    [props.disableDecreaseMonth, props.disableDecreaseYear, props.showMonthYearPicker],
  );

  const disabledIncrease = useMemo(
    () => (!props.showMonthYearPicker ? props.disableIncreaseMonth : props.disableIncreaseYear),
    [props.disableIncreaseMonth, props.disableIncreaseYear, props.showMonthYearPicker],
  );

  const dateDecrease = useCallback(() => {
    props.decreaseDate();
    props.onMonthDecrease();
  }, [props]);

  const dateIncrease = useCallback(() => {
    props.increaseDate();
    props.onMonthIncrease();
  }, [props]);

  return (
    <div className={styles.root} data-testid="date-picker-default-header">
      <span className={styles.title}>{currentDateTitle}</span>

      <div className={styles.calendarArrows}>
        <button
          type="button"
          data-testid={'monthDecrease'}
          disabled={disabledDecrease}
          className={styles.calendarArrowsItem}
          onClick={dateDecrease}>
          <ChevronLeftIcon />
        </button>
        <button
          type="button"
          data-testid={'monthIncrease'}
          disabled={disabledIncrease}
          className={styles.calendarArrowsItem}
          onClick={dateIncrease}>
          <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
};

export {DatepickerHeader};
