import type {IAlertIndicatorRepository} from './alert-indicator.repository.interface';
import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {AlertIndicatorModel} from '../model';
import {AlertIndicatorModelFactory} from '../model';
import type {IAlertIndicatorGetResponseDto} from './dto';

@injectable()
class AlertIndicatorRepository implements IAlertIndicatorRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  find(): Promise<void> {
    throw new NotImplementedException();
  }

  async list(): Promise<AlertIndicatorModel> {
    const {data} = await this._http.get<IAlertIndicatorGetResponseDto>('Patient/alertflags');

    return AlertIndicatorModelFactory.fromGetResponseDto(data);
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  save(): Promise<void> {
    throw new NotImplementedException();
  }
}

export {AlertIndicatorRepository};
