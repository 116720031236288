import {inject, injectable} from 'inversify';
import type {INotificationService, ITimeIntervalBorders} from './index';
import type {NotificationModel} from '../model';
import {NotificationsModelFabric} from '../model';
import {makeAutoObservable} from 'mobx';
import type {INotificationsRepository} from '../repository';
import {NOTIFICATIONS_REPOSITORY} from '../repository';
import {endOfDay, isToday, isYesterday, startOfDay, subDays} from 'date-fns';
import type {NotificationList} from './notifications.service.interface';
import {NotificationsDays} from '../model/enum';
import type {IWssService, SocketData} from '../../utils/wss';
import {WSS_SERVICE} from '../../utils/wss';
import type {NotificationSocketDto} from '../socket-dto';
import type {IAlertIndicatorService} from '../../alert-indicator';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';

@injectable()
class NotificationsService implements INotificationService {
  private pageNumber: number = 1;
  private pageSize: number = 10;
  private dates: ITimeIntervalBorders = {
    end: new Date(),
  };
  private isRead: boolean = false;
  private flags: number[] = [];

  private todayInterval: ITimeIntervalBorders = {
    end: endOfDay(new Date()),
    start: startOfDay(new Date()),
  };

  private yesterdayInterval: ITimeIntervalBorders = {
    end: endOfDay(subDays(new Date(), 1)),
    start: startOfDay(subDays(new Date(), 1)),
  };

  private hasMoreTodayNotifications: boolean = true;
  private hasMoreYesterdayNotifications: boolean = true;
  private hasMoreOlderNotifications: boolean = true;

  private currentInterval: NotificationsDays = NotificationsDays.DAY_TODAY;

  private olderInterval: ITimeIntervalBorders = {
    end: endOfDay(subDays(new Date(), 2)),
  };

  constructor(
    @inject(NOTIFICATIONS_REPOSITORY) private readonly repo: INotificationsRepository,
    @inject(WSS_SERVICE) private readonly wss: IWssService,
    @inject(ALERT_INDICATOR_SERVICE) private readonly _alertIndicator: IAlertIndicatorService,
  ) {
    makeAutoObservable(this);
  }

  private _unreadCount: number = 0;

  get unreadCount(): number {
    return this._unreadCount;
  }

  set unreadCount(value: number) {
    this._unreadCount = value;
  }

  private _notifications: NotificationModel[] = [];

  get notifications(): NotificationModel[] {
    return this._notifications;
  }

  set notifications(value: NotificationModel[]) {
    this._notifications = value;
  }

  private _permissionFlags: (number | null)[] | null = null;

  get permissionFlags() {
    if (this._permissionFlags) {
      return [...new Set([...this._permissionFlags])];
    }

    return [];
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private static isNotificationsSocketData(data: SocketData): data is NotificationSocketDto {
    return (data as NotificationSocketDto)?.notificationId !== undefined;
  }

  connectWss(): void {
    this.wss.subscribe('Notification', (data) => {
      if (NotificationsService.isNotificationsSocketData(data)) {
        if (!this.isRead) {
          this.notifications = this.notifications.filter(
            (notification) => notification.notificationId !== data.notificationId,
          );

          const model = NotificationsModelFabric.fromSocketDto(data);
          this.notifications = [...this.notifications, model];
          this.unreadCount = this.notifications.filter((n) => !n.isRead).length;

          this._alertIndicator.setIndicatorNotifications(!!this._unreadCount);
        }
      }
    });
  }

  async load(
    userAccountId: number,
    isRead: boolean,
    flags: number[],
    appendCollection: boolean = false,
    onlyUnreadCount: boolean = false,
  ): Promise<void> {
    if (this.isRead !== isRead) {
      if (!appendCollection) {
        this.notifications = [];
        this.dropNotificationsState();
      }
      this.pageNumber = 1;
      this.isRead = isRead;
    }

    if (flags && flags.length !== this.flags.length) {
      if (!appendCollection) {
        this.notifications = [];
        this.dropNotificationsState();
      }
      this.pageNumber = 1;
      this.flags = flags;
    }

    this.updateDatesAndIntervalForRequest();

    if (!this.hasMoreOlderNotifications && !this.notifications.length) {
      this.pageNumber = 1;
      this.dropNotificationsState();
    }

    if (this.hasMoreOlderNotifications) {
      this._isLoading = true;
      const model = await this.repo
        .list({
          userAccountId,
          isRead,
          flags,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          endDate: this.dates.end,
          startDate: this.dates.start,
        })
        .catch(console.error);
      this._isLoading = false;

      if (model) {
        const newNotifications = model.filter(
          (modelNotification) =>
            !this.notifications.some(
              (notification) => notification.notificationId === modelNotification.notificationId,
            ),
        );
        this.notifications = [...this.notifications, ...newNotifications];

        if (!appendCollection) {
          this.notifications = this.notifications.filter((notification) => notification.isRead === this.isRead);
        }

        this.notifications.sort();

        if (!this.isRead) {
          this.unreadCount = this.notifications.length;
        }

        if (!this._permissionFlags) {
          this._permissionFlags = this.notifications
            .filter((notification) => notification.flag !== null)
            .map((item) => item.flag);
        }

        if (!onlyUnreadCount) {
          this.pageNumber++;
        }

        if (model.length < this.pageSize) {
          switch (this.currentInterval) {
            case NotificationsDays.DAY_TODAY:
              this.hasMoreTodayNotifications = false;
              this.pageNumber = 1;
              await this.load(userAccountId, isRead, flags, appendCollection, onlyUnreadCount);
              break;
            case NotificationsDays.DAY_YESTERDAY:
              this.hasMoreYesterdayNotifications = false;
              this.pageNumber = 1;
              await this.load(userAccountId, isRead, flags, appendCollection, onlyUnreadCount);
              break;
            case NotificationsDays.DAY_OLDER:
              this.hasMoreOlderNotifications = false;
              break;
            default:
              break;
          }
        }
      }
    }
  }

  checkPermissionFlag(value: number) {
    if (this._permissionFlags) {
      return !!this._permissionFlags.filter((item) => item === value).length;
    }

    return false;
  }

  sort(): NotificationList[] {
    const notificationDivider = [
      {
        getTitle: () => NotificationsDays.DAY_TODAY,
        filterFunc: (date: Date) => isToday(date),
      },
      {
        getTitle: () => NotificationsDays.DAY_YESTERDAY,
        filterFunc: (date: Date) => isYesterday(date),
      },
      {
        getTitle: (list: NotificationList[]) =>
          list.length ? NotificationsDays.DAY_OLDER : NotificationsDays.DAY_LATEST,
        filterFunc: (date: Date) => !isToday(date) && !isYesterday(date),
      },
    ];

    const list: NotificationList[] = [];

    notificationDivider.forEach((divider) => {
      const notifications = this.notifications
        .filter((notification) => divider.filterFunc(notification.notificationDate))
        .sort((a, b) => Number(b.flag) - Number(a.flag));

      if (notifications.length) {
        list.push({
          title: divider.getTitle(list),
          notifications,
        });
      }
    });

    return list;
  }

  async changeNotificationStatus(notificationId: number): Promise<void> {
    this.notifications = this.notifications.map((notification) => {
      if (notification.notificationId === notificationId) {
        notification.isRead = !notification.isRead;

        if (notification.isRead) {
          this.unreadCount--;
        } else {
          this.unreadCount++;
        }
      }

      return notification;
    });

    const model = this.notifications.find((item) => item.notificationId === notificationId);
    if (!!model) {
      await this.repo.save(model);
    }

    this._alertIndicator.setIndicatorNotifications(!!this._unreadCount);
  }

  async changeNotificationsStatus(notificationIds: number[], status: boolean): Promise<void> {
    this.notifications = this.notifications.map((notification) =>
      notificationIds.includes(notification.notificationId) ? {...notification, isRead: status} : notification,
    );

    this.unreadCount = status ? 0 : this.notifications.length;

    await this.repo.updateManyStatuses(notificationIds, status);
  }

  clear(): void {
    this._notifications = this._notifications.filter((i) => !i.isRead);
  }

  dropNotificationsState(): void {
    this.hasMoreTodayNotifications = true;
    this.hasMoreYesterdayNotifications = true;
    this.hasMoreOlderNotifications = true;
    this.currentInterval = NotificationsDays.DAY_TODAY;
  }

  updateDatesAndIntervalForRequest(): void {
    if (this.hasMoreTodayNotifications) {
      if (this.currentInterval !== NotificationsDays.DAY_TODAY) {
        this.currentInterval = NotificationsDays.DAY_TODAY;
      }

      this.dates.start = this.todayInterval.start;
      this.dates.end = this.todayInterval.end;
    } else if (this.hasMoreYesterdayNotifications) {
      if (this.currentInterval !== NotificationsDays.DAY_YESTERDAY) {
        this.currentInterval = NotificationsDays.DAY_YESTERDAY;
      }

      this.dates.start = this.yesterdayInterval.start;
      this.dates.end = this.yesterdayInterval.end;
    } else if (this.hasMoreOlderNotifications) {
      if (this.currentInterval !== NotificationsDays.DAY_OLDER) {
        this.currentInterval = NotificationsDays.DAY_OLDER;
      }

      this.dates.start = this.olderInterval.start;
      this.dates.end = this.olderInterval.end;
    }
  }
}

export {NotificationsService};
