import React from 'react';
import type {ITextAreaProps, KeyDownType} from './text-area.types';
import classnames from 'classnames';
import styles from './text-area.module.scss';
import {Error} from '../error';

const TextAreaTemplate: React.FC<ITextAreaProps> = (params: ITextAreaProps) => {
  const title = params.ariaLabelTitle ?? params.name;
  const error = !!params.ariaLabelError ? `${params.ariaLabelError}.` : "";

return (
  <div className={classnames(styles.root, params.className)}>
    {!!params.label ? (
      <label
        className={classnames(styles.label, params.type ? styles[params.type] : null)}
        data-testid={`text-area-${params.name}-label`}>
        {params.label}
      </label>
    ) : null}
    <textarea
      className={classnames(
        styles.textArea,
        params.classNameInput,
        params.isValid === false && styles.invalid,
        params.disabled === true && styles.disabled,
        params.type ? styles[params.type] : null,
        {[styles.error]: !!params.errorMessage},
      )}
      name={params.name}
      aria-label={`${error} ${title}`}
      value={params.value}
      ref={params.inputRef}
      autoComplete='off'
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
        params.onChange ? params.onChange(e.target.value) : null
      }
      onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) =>
        params.onKeyDown ? params.onKeyDown(e as KeyDownType) : null
      }
      placeholder={params.placeholder}
      readOnly={params.disabled}
      maxLength={params.maxLength}
      data-testid={params.name}
    />
    {params.errorMessage && <Error className={styles.errorMessage} errorMessage={params.errorMessage} />}
  </div>
);
}

const TextArea = React.forwardRef((props: ITextAreaProps, ref?: React.Ref<HTMLTextAreaElement>) => (
  <TextAreaTemplate inputRef={ref} {...props} />
));

export {TextArea};
