import React, {forwardRef} from 'react';
import classnames from 'classnames';
import styles from './input.module.scss';
import type {IInputProps} from './input.type';
import {InputType} from './input.type';
import {CloseIcon} from '../../assets/icons';

const Input = forwardRef<HTMLInputElement, IInputProps>((params, ref) => {
  const type = params.type ?? InputType.Common;
  const labelInInput = params.type === InputType.LabelInInput ? InputType.LabelInInput : '';
  const isCloseShown = params.withClear ?? false;
  const title = params.ariaLabelTitle ?? params.name;
  const error = !!params.ariaLabelError ? `${params.ariaLabelError}.` : "";

  const handleChange = (value: string) => (params.onValueChanged ? params.onValueChanged(value) : null);

  return (
    <div
      className={classnames(
        styles.root,
        styles[labelInInput],
        params.isValid === false && params.type === InputType.LabelInInput && styles.invalid,
        params.className,
      )}>
      {(params.type === InputType.LabelInInput && !!params.value) ||
      (params.type !== InputType.LabelInInput && !!params.label) ? (
        <label className={classnames(styles.label, styles[type], params.labelClassName)}>{params.label}</label>
      ) : null}
      <input
        className={classnames(
          styles.input,
          styles[type],
          params.isValid === false && styles.invalid,
          params.disabled ? styles.disabled : null,
          params.type === InputType.LabelInInput && !params.value?.length ? styles.noLabel : null,
          params.inputClassName,
        )}
        data-testid={params.testId}
        type="text"
        name={params.name}
        aria-label={`${error} ${title}`}
        value={params.value}
        ref={ref}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
        placeholder={params.placeholder}
        maxLength={params.maxLength}
        min={params.minValue}
        max={params.maxValue}
        autoComplete='off'
      />
      {isCloseShown && params.value && (
        <button className={styles.closeButton} onClick={() => handleChange('')}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
});

export {Input};
