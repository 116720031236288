import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {ICompanyService} from '../service';
import {COMPANY_SERVICE, CompanyService} from '../service';

const CompanyModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<ICompanyService>(COMPANY_SERVICE).to(CompanyService).inSingletonScope();
});

export {CompanyModule};
