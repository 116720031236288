import type {FileStorageModel} from '../model';
import {FileStorageModelFactory} from '../model';
import type {IFileStorageQuery, IFileStorageRepository} from './file-storage.repository.interface';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {IFileStorageGetRequestDto} from './dto';
import {FileStoragePostRequestDtoFactory} from './dto';

@injectable()
class FileStorageRepository implements IFileStorageRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(query?: Partial<IFileStorageQuery>): Promise<FileStorageModel | null> {
    let model: FileStorageModel | null = null;

    if (query?.fileId) {
      const {data: responseDto} = await this._http.get<IFileStorageGetRequestDto>(
        `/fileStorage/getfile/?objectFileId=${query?.fileId}`,
      );

      model = FileStorageModelFactory.fromGetDto(responseDto, query?.fileId);
    }

    return model;
  }

  list(): Promise<FileStorageModel[]> {
    throw new NotImplementedException();
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async save(model: FileStorageModel): Promise<FileStorageModel> {
    const postDto = FileStoragePostRequestDtoFactory.fromModel(model);
    const {data: objectFileId} = await this._http.post<string>('/fileStorage/uploadfile', postDto);

    const modelToReturn = model;
    modelToReturn.objectId = objectFileId;

    return modelToReturn;
  }
}

export {FileStorageRepository};
