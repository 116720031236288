import type {FC} from 'react';
import React, {useMemo} from 'react';
import styles from './lab-results-list.module.scss';
import {Card, CardContent} from '../../components';
import type {LabResultsModel} from '../model';
import {MoreHorizontalGrey} from '../../assets/icons';
import {labTestOptions} from '../options';
import {useDropdown} from '../../components/dropdown';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import {format, isAfter} from 'date-fns';
import {DEFAULT_FORMAT} from '../../helpers';

interface ILabResultsListProps {
  results: ReadonlyArray<LabResultsModel>;
  openFormModal: () => void;
  onSelect: (result: LabResultsModel) => void;
}

const headerItems = ['Date of lab', 'Lab Test', 'Result', 'Last updated by', ''].map(function(i) {
  if(i.length > 0){
    return(
    <th key={i} className={styles.tableHeader}>
      {i}
    </th>);
  }

  return(<td key={i} className={styles.tableHeader}/>);
});

const ResultTableItem: FC<{result: LabResultsModel; onEdit: () => void}> = ({result, onEdit}) => {
  const {Dropdown, closeDropdown} = useDropdown();

  const items = useMemo(
    () => [
      !!result.labDate ? format(result.labDate, DEFAULT_FORMAT) : '',
      result.labTest ? labTestOptions.get(result.labTest) : [],
      result.getResultString(),
      result.lastUpdatedByFullName,

      <Dropdown
        activator={({onOpen}) => (
          <button className={styles.moreButton} onClick={onOpen}>
            <MoreHorizontalGrey />
          </button>
        )}>
        <Card className={styles.moreCard} borderLess>
          <OutsideClickHandler onOutsideClick={closeDropdown}>
            <ul className={styles.moreList}>
              <li className={styles.moreListItem}>
                <button
                  className={classNames(styles.moreListItemContainer, styles.moreListItemButton)}
                  onClick={onEdit}>
                  Edit
                </button>
              </li>
            </ul>
          </OutsideClickHandler>
        </Card>
      </Dropdown>,
    ],
    [Dropdown, closeDropdown, onEdit, result],
  );

  return (
    <tr>
      {items.map((i, index) => (
        <td key={index} className={styles.tableCell}>
          {i}
        </td>
      ))}
    </tr>
  );
};

const LabResultsList: FC<ILabResultsListProps> = (props) => {
  const resultsToRender = useMemo(
    () =>
      props.results
        .filter((result) => !!result.labTest && !!labTestOptions.get(result.labTest))
        .sort((result1, result2) =>
          result1.labDate && result2.labDate && isAfter(result2.labDate, result1.labDate) ? 1 : -1,
        ),
    [props.results],
  );

  return !!props.results.length ? (
    <Card>
      <table className={styles.table}>
        <thead>
          <tr>{headerItems}</tr>
        </thead>
        <tbody>
          {resultsToRender.map((result) => {
            const onResultEdit = () => {
              props.onSelect(result);
              props.openFormModal();
            };

            return <ResultTableItem key={result.id} result={result} onEdit={onResultEdit} />;
          })}
        </tbody>
      </table>
    </Card>
  ) : (
    <CardContent className={styles.noResultsContainer} center gapY={3}>
      <div className={styles.noResultsMessage}>No Lab results have been entered yet</div>
      <div className={styles.noResultsButton} onClick={props.openFormModal}>
        Add new lab result
      </div>
    </CardContent>
  );
};

export {LabResultsList};
export type {ILabResultsListProps};
