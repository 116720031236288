import {DocumentType} from '../../enums';
import type {DocumentDetailsModel} from './document-details.model';

class DocumentsModel {
  constructor(
    public id = -1,
    public patientId = -1,
    public fileId = '',
    public fileName = '',
    public created: Date | null = null,
    public upload: Date | null = null,
    public type = DocumentType.LabResults,
    public otherNote = '',
    public mimeType = '',
    public details: DocumentDetailsModel | null = null,
    public createdByFullName = '',
    public createdByTitle = '',
    public createdByType = '',
  ) {}
}

export {DocumentsModel};
