interface StrengthsAssessmentQuestion {
  question: string;
  value: number;
}

class StrengthsAssessmentModel {
  constructor(
    public id = -1,
    public patientId = -1,
    public items: StrengthsAssessmentQuestion[] = [],
    public lastUpdated: Date | null = null,
    public name: string = '',
    public date: string = '',
  ) {}
}

export {StrengthsAssessmentModel};
export type {StrengthsAssessmentQuestion};
