import type {FC} from 'react';
import React from 'react';
import styles from './footer.module.scss';
import {AppVersion, Contacts, Notice, Social} from './components';
import classNames from 'classnames';

interface IProps {
  className: string;
  contactEmail: string;
  contactPhone: string;
  facebookLink: string;
  twitterLink: string;
  instagramLink: string;
  linkedInLink: string;
  tikTokLink: string;
  youTubeLink: string;
  copyRight: string;
  appVersion: string;
  disclaimer?: string;
  light?: boolean;
  onBoarding?: boolean;
}

const Footer: FC<IProps> = (props) => (
  <footer
    className={classNames(props.className, styles.root, {
      [styles.light]: props.light,
      [styles.center]: props.onBoarding,
    })}>
    <section className={styles.wrap}>
      <Notice />
      <Contacts contactEmail={props.contactEmail} contactPhone={props.contactPhone} />
      <AppVersion appVersion={props.appVersion} />
      <Social
        facebookLink={props.facebookLink}
        twitterLink={props.twitterLink}
        instagramLink={props.instagramLink}
        linkedInLink={props.linkedInLink}
        tikTokLink={props.tikTokLink}
        youTubeLink={props.youTubeLink}
        copyRight={props.copyRight}
      />
    </section>

    <section className={styles.wrap}>
      <section className={styles.disclaimer}>{props.disclaimer}</section>
    </section>
  </footer>
);

export {Footer};
