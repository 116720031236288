import {inject, injectable} from 'inversify';
import type {IVaccinationService} from './vaccination.service.interface';
import type {VaccinationModel} from '../model';
import {VaccinationModelFactory} from '../model';
import {makeAutoObservable} from 'mobx';
import type {IVaccinationRepository} from '../repository';
import {VACCINATION_REPOSITORY} from '../repository';
import {VaccinationFormDto, VaccinationFormDtoFactory} from '../form';
import type {INotifierService} from '../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../notifier';
import type {IWssService, SocketData} from '../../utils/wss';
import {WSS_SERVICE} from '../../utils/wss';
import type {VaccinationSocketDto} from '../socket-dto';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import {routes} from '../../routing';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';

@injectable()
class VaccinationService implements IVaccinationService {
  constructor(
    @inject(VACCINATION_REPOSITORY) private readonly _repo: IVaccinationRepository,
    @inject(NOTIFIER_SERVICE) private readonly _notifier: INotifierService,
    @inject(WSS_SERVICE) private readonly _wss: IWssService,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(ACCOUNT_SERVICE) private readonly _account: IAccountService,
  ) {
    makeAutoObservable(this);
  }

  private _record: VaccinationModel | null = null;

  get record(): VaccinationModel | null {
    return this._record;
  }

  private _isLoading = true;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private static isVaccinationSocketData(data: SocketData): data is VaccinationSocketDto {
    return (
      (data as VaccinationSocketDto)?.Items !== undefined &&
      (data as VaccinationSocketDto)?.LastModifiedDate !== undefined
    );
  }

  async sendRecord(formDto: VaccinationFormDto): Promise<void> {
    const model = VaccinationModelFactory.fromFormDto(formDto, this._account.id);
    if (this._record?.id) {
      model.id = this._record?.id;
    }

    await this._repo
      .save(model)
      .then((model) => (this._record = model))
      .then(() => {
        this._notifier.notify({
          title: 'Success',
          text: 'You have updated your vaccination record',
          type: NotifyMessageTypeEnum.Success,
        });
        this._routing.push(`${routes.home}${routes.myDashboard}`);
      })
      .catch(console.error);
  }

  async loadLastRecord(): Promise<void> {
    this._isLoading = true;
    this._record = (await this._repo.find().catch(console.error)) ?? null;
    this._isLoading = false;
  }

  getRecordFormDto(): VaccinationFormDto {
    return this._record ? VaccinationFormDtoFactory.fromModel(this._record) : new VaccinationFormDto();
  }

  connectWss(): void {
    this._wss.subscribe('VaccinationRecordAdded', (data) => {
      if (VaccinationService.isVaccinationSocketData(data)) {
        this._record = VaccinationModelFactory.fromSocketDto(data);
      }
    });

    this._wss.subscribe('VaccinationRecordUpdated', (data) => {
      if (VaccinationService.isVaccinationSocketData(data)) {
        this._record = VaccinationModelFactory.fromSocketDto(data);
      }
    });
  }
}

export {VaccinationService};
