import type {FC} from 'react';
import React, {useCallback, useEffect} from 'react';
import type {LabResultsFormDto} from './form-dto';
import {Controller, useForm} from 'react-hook-form';
import styles from './lab-results-form.module.scss';
import {Button, DatePicker, DatePickerInputType, DatePickerView, Error, SingleDropdown} from '../../components';
import {CloseIcon} from '../../assets/icons';
import {LabResultsFormWrapper, LabResultsResultElement} from './components';
import {labTestOptions} from '../options';
import {optionsFromMapHelper, REQUIRED_FIELD_ERROR, scrollToError} from '../../helpers';
import {UnitsTypesEnum} from '../enums';

enum LabResultsHeaders {
  New = 'Add new lab result',
  Existing = 'Edit Lab result',
}

interface ILabResultsFormProps {
  defaultValues: LabResultsFormDto | null;
  onSubmit: (data: LabResultsFormDto) => void;
  onClose: () => void;
}

const LabResultsForm: FC<ILabResultsFormProps> = (props) => {
  const {control, handleSubmit, formState, watch, reset, getValues} = useForm<LabResultsFormDto>({
    defaultValues: props.defaultValues || {},
  });

  const watchLabTest = watch('labTest');

  const resetForm = useCallback(
    (labTest) => {
      reset({
        ...props.defaultValues,
        labTest: labTest,
        unitType: UnitsTypesEnum.Blank,
        labDate: getValues('labDate'),
        value: undefined,
      });
    },
    [getValues, props.defaultValues, reset],
  );

  useEffect(() => {
    scrollToError(formState.errors);
  }, [formState.errors]);

  return (
    <form className={styles.root} onSubmit={handleSubmit(props.onSubmit)}>
      <div className={styles.headerSection}>
        <div className={styles.header}>
          {!!props.defaultValues ? LabResultsHeaders.Existing : LabResultsHeaders.New}
        </div>
        <button type="button" className={styles.closeIcon} onClick={props.onClose}>
          <CloseIcon />
        </button>
      </div>
      <LabResultsFormWrapper label={'Date of lab*'}>
        <Controller
          name={'labDate'}
          control={control}
          rules={{required: REQUIRED_FIELD_ERROR}}
          render={({value, name, onChange}) => (
            <DatePicker
              name={name}
              ariaLabelTitle="Date of lab"
              ariaLabelError={formState.errors['labDate']?.message}
              value={value}
              onChange={onChange}
              inputType={DatePickerInputType.LabResults}
              max={new Date()}
              isValid={!formState.errors['labDate']?.message}
              view={DatePickerView.Selector}
            />
          )}
        />
        {!!formState.errors['labDate']?.message && <Error errorMessage={formState.errors['labDate'].message} />}
      </LabResultsFormWrapper>
      <LabResultsFormWrapper label={'Lab Test*'}>
        <Controller
          name={'labTest'}
          control={control}
          rules={{required: REQUIRED_FIELD_ERROR}}
          render={({value, name}) => {
            const options = optionsFromMapHelper(labTestOptions);
            const selectedValue = options.find((option) => option.value === value);

            return (
              <SingleDropdown
                value={selectedValue}
                name={name}
                ariaLabelTitle="Lab Test"
                ariaLabelError={formState.errors['labTest']?.message}
                onChange={(option) => {
                  if (option.value !== value) {
                    resetForm(option.value);
                  }
                }}
                options={options}
                isError={!!formState.errors['labTest']?.message}
              />
            );
          }}
        />
        {!!formState.errors['labTest']?.message && <Error errorMessage={formState.errors['labTest'].message} />}
      </LabResultsFormWrapper>
      {!!watchLabTest && (
        <LabResultsFormWrapper label={'Result*'}>
          <LabResultsResultElement errors={formState.errors} testType={watchLabTest} control={control} />
        </LabResultsFormWrapper>
      )}
      <Button type="submit" className={styles.button} disabled={formState.isSubmitting || formState.isSubmitSuccessful}>
        Save
      </Button>
    </form>
  );
};

export {LabResultsForm};
export type {ILabResultsFormProps};
