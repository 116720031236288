import type {IOption} from '../../types';
import {HealthMonitorPeriodTypes} from '../model';

const healthMonitorPeriodOptions: Array<IOption<HealthMonitorPeriodTypes>> = [
  {
    value: HealthMonitorPeriodTypes.Week,
    displayName: 'Week',
  },
  {
    value: HealthMonitorPeriodTypes.Month,
    displayName: 'Month',
  },
];

export {healthMonitorPeriodOptions};
