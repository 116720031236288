import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IFileStorageService} from '../service';
import {FILE_STORAGE_SERVICE, FileStorageService} from '../service';
import type { IFileStorageRepository} from '../respository';
import {FILE_STORAGE_REPOSITORY, FileStorageRepository} from '../respository';

const FileStorageModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IFileStorageRepository>(FILE_STORAGE_REPOSITORY).to(FileStorageRepository).inSingletonScope();

  bind<IFileStorageService>(FILE_STORAGE_SERVICE).to(FileStorageService).inSingletonScope();
});

export {FileStorageModule};
