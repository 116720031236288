import type {FC} from 'react';
import React from 'react';
import {AllowBaseModal} from './allow-base';
import type {SearchInterface} from '../../../../../../../account';

interface IOwnProps {
  onClose(): void;
  onSubmit(model: SearchInterface): void;
  provider: SearchInterface;
}

export const InviteExternalProviderModal: FC<IOwnProps> = ({provider, onSubmit, ...props}) => (
  <AllowBaseModal {...props} onSubmit={() => onSubmit(provider)}>
    <p>
      Would you like an Elevate Team Member to invite your provider
      <p>
        <b>
          {provider.firstName} {provider.lastName}
          {provider.title ? `, ${provider.title}` : null}
        </b>
      </p>
      to Trellus Elevate?
    </p>
  </AllowBaseModal>
);
