import type {Dispatch, FC, SetStateAction} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './profile-personal-info.module.scss';
import {Controller, useForm} from 'react-hook-form';
import {
  DATE_API_FORMAT,
  DEFAULT_COUNTRY_ID,
  DEFAULT_FORMAT,
  DEFAULT_LANGUAGE_ID,
  formatDate,
  getClearedPhoneValue,
  phoneFormatter,
  REQUIRED_FIELD_ERROR,
  scrollToError,
  sexFormatter,
} from '../../../../../helpers';
import {
  Button,
  Card,
  CardContent,
  DatePicker,
  DatePickerInputType,
  DatePickerView,
  Error,
  FileUploaderLegacy,
  FileUploaderType,
  Input,
  MaskedInput,
  Preloader,
  Radio,
  SearchDropdown,
  SingleDropdown,
} from '../../../../../components';
import classNames from 'classnames';
import {Patterns} from '../../../../../helpers/patterns';
import {differenceInMonths, formatISO, parseISO} from 'date-fns';
import {isEqual, pick} from 'lodash';
import {Sex} from '../../../../../enums';
import differenceInYears from 'date-fns/differenceInYears';
import type {IOption} from '../../../../../types';
import {isEnglishLanguageSelected, isOtherLanguageSelected} from '../../helpers';
import {ProfileTabsEnum} from '../../profile.enum';
import type {AccountModel} from '../../../../../account';
import {TwoFactorAuthForm} from '../two-factor-auth';
import {Link} from 'react-router-dom';
import {routes} from '../../../../../routing';

const FILE_SIZE = Math.pow(1024, 2) * 5;

const sexOptions = [
  {
    value: Sex.Male,
    displayName: sexFormatter(Sex.Male),
  },
  {
    value: Sex.Female,
    displayName: sexFormatter(Sex.Female),
  },
];

const ageCalculateString = (date: Date): string => {
  const age = differenceInYears(new Date(), date);

  if (age) {
    return `${age} ${age === 1 ? 'year' : 'years'} old`;
  }

  const months = differenceInMonths(new Date(), date);

  return `${months} ${months === 1 ? 'month' : 'months'} old`;
};

interface IProfilePersonalInfoForm {
  phone: string;
  alternatePhone: string;
  country: number;
  zipCode: string;
  state: string;
  city: string;
  address: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  sex: number;
  language: number;
  isNeedInterpreter: boolean;
  verificationCode: string;
  otherLanguage: string;
}

interface IProfilePersonalInfoProps {
  onSubmit: (data: IProfilePersonalInfoForm) => Promise<boolean>;
  onUploadAvatar: (data: {uri: string; type: string; name: string}) => Promise<void>;
  onRemoveAvatar: () => Promise<void>;
  patientPersonalInfo: AccountModel | null;
  patientAvatar?: string | null;
  languageOptions: Array<IOption>;
  setActiveTab: Dispatch<SetStateAction<ProfileTabsEnum>>;
  isEmailAddressChangeRequested: boolean;
  countries: IOption[];
  states: IOption<string>[];
  getStates: (countryId: number) => Promise<void>;
  isLoading?: boolean;
  requestCode(): Promise<void>;
}

interface IAvatarData {
  uri: string;
  type: string;
  name: string;
}

const AVATAR_COLORS = [styles.green, styles.red, styles.gray, styles.yellow];

const RADIO_OPTIONS = [
  {
    id: 'profile_option_yes',
    value: true,
  },
  {
    id: 'profile_option_no',
    value: false,
  },
];

const ProfilePersonalInfo: FC<IProfilePersonalInfoProps> = ({
  onRemoveAvatar,
  onSubmit,
  onUploadAvatar,
  requestCode,
  languageOptions,
  patientPersonalInfo = null,
  patientAvatar = null,
  setActiveTab,
  isEmailAddressChangeRequested,
  countries,
  states,
  getStates,
}) => {
  const defaultValues: IProfilePersonalInfoForm = useMemo(
    () => ({
      firstName: patientPersonalInfo?.firstName ?? '',
      lastName: patientPersonalInfo?.lastName ?? '',
      phone: patientPersonalInfo?.phone ?? '',
      alternatePhone: patientPersonalInfo?.alternatePhone ?? '',
      country: patientPersonalInfo?.country ?? 0,
      zipCode: patientPersonalInfo?.zipCode ?? '',
      state: patientPersonalInfo?.state ?? '',
      city: patientPersonalInfo?.city ?? '',
      address: patientPersonalInfo?.address ?? '',
      dateOfBirth: patientPersonalInfo?.dateOfBirth
        ? formatDate(new Date(patientPersonalInfo?.dateOfBirth), DATE_API_FORMAT)
        : '',
      sex: patientPersonalInfo?.sex ?? 0,
      language: patientPersonalInfo?.language?.id ?? DEFAULT_LANGUAGE_ID,
      isNeedInterpreter:
        patientPersonalInfo?.language?.name !== 'English' && patientPersonalInfo?.language.isNeedInterpreter
          ? patientPersonalInfo?.language.isNeedInterpreter
          : false,
      otherLanguage: patientPersonalInfo?.language?.text ?? '',
      verificationCode: '',
    }),
    [patientPersonalInfo],
  );

  const {control, errors, handleSubmit, reset, watch, setValue, getValues} = useForm<IProfilePersonalInfoForm>({
    defaultValues,
  });

  const emptyAvatarData: IAvatarData = useMemo(
    () => ({
      uri: '',
      type: '',
      name: '',
    }),
    [],
  );

  const [isEditMode, setEditMode] = useState(false);
  const [stateAvatarData, setStateAvatarData] = useState<IAvatarData>(emptyAvatarData);
  const [isAvatarRemoved, setAvatarRemoved] = useState(false);
  const [visibleModalLoader, setVisibleModalLoader] = useState(false);
  const [visibleTwoFactorModal, setVisibleTwoFactorModal] = useState(false);

  const color = useMemo(() => AVATAR_COLORS[Math.floor(Math.random() * AVATAR_COLORS.length)], []);

  useEffect(() => {
    isEditMode && reset(defaultValues);
  }, [isEditMode, defaultValues, reset]);
  const visibleTwoFactorModalHandler = useCallback(
    async (isOpen?: boolean) => {
      isOpen && (await requestCode());
      setVisibleTwoFactorModal(!visibleTwoFactorModal);
    },
    [requestCode, visibleTwoFactorModal],
  );

  const isEnglishSelected = useMemo(
    () => isEnglishLanguageSelected(languageOptions || [], watch('language')),
    [languageOptions, watch],
  );

  const isOtherSelected = useMemo(
    () => isOtherLanguageSelected(languageOptions || [], watch('language')),
    [languageOptions, watch],
  );

  const onPersonalInfoSubmit = (data: IProfilePersonalInfoForm) => {
    !isEqual(pick(defaultValues, Object.keys(data)), data) || !!stateAvatarData?.uri || isAvatarRemoved
      ? visibleTwoFactorModalHandler(true)
      : onCancel();
  };

  const submitHandler = async (code: string) => {
    const data = getValues();
    setVisibleModalLoader(true);

    stateAvatarData?.uri && (await onUploadAvatar(stateAvatarData));
    isAvatarRemoved && (await onRemoveAvatar());
    setAvatarRemoved(false);
    setVisibleModalLoader(false);

    const isPersonalInfoUpdateSuccessful = await onSubmit({
      ...data,
      phone: getClearedPhoneValue(patientPersonalInfo?.phone as string) ?? '',
      alternatePhone:
        getClearedPhoneValue(data.alternatePhone ?? (patientPersonalInfo?.alternatePhone as string)) ?? '',
      verificationCode: code,
    });

    if (isPersonalInfoUpdateSuccessful) {
      reset(defaultValues);
      setEditMode(false);
    }
  };

  const onCancel = useCallback(() => {
    setStateAvatarData(emptyAvatarData);
    setAvatarRemoved(false);
    setEditMode(false);
  }, [emptyAvatarData]);

  const onDelete = useCallback(() => {
    setStateAvatarData(emptyAvatarData);
    setAvatarRemoved(true);
  }, [emptyAvatarData]);

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  const actions = useMemo(
    () => (
      <div className={classNames(styles.actions, styles.personalInfoActions)}>
        {isEditMode ? (
          <>
            <div className={styles.cancel} onClick={onCancel}>
              Cancel
            </div>
            <Button className={styles.button} type={'submit'} block>
              Save Profile
            </Button>
          </>
        ) : (
          <Button className={styles.button} block onClick={() => setEditMode(true)}>
            Edit Profile
          </Button>
        )}
      </div>
    ),
    [isEditMode, onCancel],
  );

  const sexString = useMemo(
    () => sexOptions.find((option) => option.value === patientPersonalInfo?.sex)?.displayName ?? '',
    [patientPersonalInfo?.sex],
  );

  const ageString = useMemo(
    () => (!!patientPersonalInfo?.dateOfBirth ? ageCalculateString(new Date(patientPersonalInfo?.dateOfBirth)) : ''),
    [patientPersonalInfo?.dateOfBirth],
  );

  const abbrString = useMemo(() => {
    const firstName = patientPersonalInfo?.firstName[0] || '';
    const lastName = patientPersonalInfo?.lastName[0] || '';

    return (firstName + lastName).toUpperCase();
  }, [patientPersonalInfo?.firstName, patientPersonalInfo?.lastName]);

  const validateZipCode = useMemo(
    () => ({
      value: watch('country') === DEFAULT_COUNTRY_ID ? Patterns.ZipCodeUSA : Patterns.ZipCodeCanada,
      message: 'Please enter a valid ZIP code',
    }),
    [watch],
  );

  const maskZipCode = useMemo(
    () => (watch('country') === DEFAULT_COUNTRY_ID ? Patterns.ZipCodeMaskUSA : Patterns.ZipCodeMaskCanada),
    [watch],
  );

  const readonlyCountry = useMemo(
    () => countries?.find((i) => i.value === patientPersonalInfo?.country)?.displayName ?? '',
    [countries, patientPersonalInfo?.country],
  );

  const readonlyState = useMemo(
    () => states?.find((i) => i.value === patientPersonalInfo?.state)?.displayName ?? '',
    [states, patientPersonalInfo?.state],
  );

  const avatarUploader = useMemo(
    () => (
      <div className={styles.avatarContainer}>
        {!!stateAvatarData?.uri || (!!patientAvatar && !isAvatarRemoved) ? (
          <img
            alt={'avatar'}
            className={styles.avatar}
            src={`data:image/png;base64,${stateAvatarData?.uri || patientAvatar}`}
          />
        ) : (
          <div className={classNames(styles.avatar, color)}>{abbrString}</div>
        )}
        {isEditMode ? (
          <>
            <FileUploaderLegacy
              className={styles.uploadButton}
              accept={'.jpg, .jpeg, .png, .gif'}
              maxSizeBytes={FILE_SIZE}
              errorMaxSizeMessage={'All uploaded files must be less than 5mb. Please re-size the file and try again.'}
              errorAcceptMessage={'Only JPG, JPEG, Gif, and PNG files can be uploaded.'}
              onUploadFile={(file, base64) => {
                const data = {
                  uri: base64,
                  type: file.type,
                  name: file.name,
                };

                setStateAvatarData(data);
                setAvatarRemoved(false);
              }}
              onRemoveFile={() => {}}
              dragText={''}
              uploadText={'Upload Image'}
              uploadNewFileActionText={''}
              removeActionText={''}
              submitActionText={''}
              uploadedFile={!!stateAvatarData ? `data:image/png;base64,${stateAvatarData?.uri}` : ''}
              type={FileUploaderType.Button}
            />
            <Button className={styles.deleteButton} onClick={onDelete}>
              Delete
            </Button>
          </>
        ) : (
          <div>
            <div className={styles.name}>{`${patientPersonalInfo?.firstName ?? ''} ${
              patientPersonalInfo?.lastName ?? ''
            }`}</div>
            <div className={styles.info}>{`${sexString}, ${ageString}`}</div>
          </div>
        )}
      </div>
    ),
    [
      patientPersonalInfo,
      stateAvatarData,
      patientAvatar,
      isAvatarRemoved,
      color,
      abbrString,
      isEditMode,
      onDelete,
      sexString,
      ageString,
    ],
  );

  return (
    <>
      {avatarUploader}
      {visibleModalLoader && <Preloader />}
      <form onSubmit={handleSubmit(onPersonalInfoSubmit)} className={styles.cardGrid}>
        <Card>
          {actions}
          <CardContent gap={0}>
            <div className={styles.fields}>
              <div className={styles.field}>
                <div className={styles.label}>First Name</div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: REQUIRED_FIELD_ERROR,
                      }}
                      render={({onChange, name, value}) => (
                        <div>
                          <Input
                            value={value}
                            className={styles.inputField}
                            name={name}
                            onValueChanged={onChange}
                            isValid={!errors.firstName}
                          />
                          {!!errors.firstName?.message && (
                            <div className={styles.errorField}>
                              <Error errorMessage={errors.firstName.message} name={name} />
                            </div>
                          )}
                        </div>
                      )}
                    />
                  ) : (
                    <>{patientPersonalInfo?.firstName ?? ''}</>
                  )}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Last Name</div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: REQUIRED_FIELD_ERROR,
                      }}
                      render={({onChange, name, value}) => (
                        <div>
                          <Input
                            value={value}
                            className={styles.inputField}
                            name={name}
                            onValueChanged={onChange}
                            isValid={!errors.lastName}
                          />
                          {!!errors.lastName?.message && (
                            <div className={styles.errorField}>
                              <Error errorMessage={errors.lastName.message} name={name} />
                            </div>
                          )}
                        </div>
                      )}
                    />
                  ) : (
                    <>{patientPersonalInfo?.lastName ?? ''}</>
                  )}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Email</div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <Input
                      name="email"
                      aria-label="email"
                      value={patientPersonalInfo?.email}
                      className={styles.inputField}
                      disabled
                    />
                  ) : (
                    <>{patientPersonalInfo?.email ?? ''}</>
                  )}
                  {isEmailAddressChangeRequested && (
                    <div className={styles.emailChangeInfo}>Email address change had been requested</div>
                  )}
                  <div className={styles.description}>
                    To change the E-mail, you must confirm the changes in the{' '}
                    <Link
                      to={`${routes.home}${routes.profile}?tabNumber=${ProfileTabsEnum.Settings}`}
                      className={styles.link}>
                      <span>settings</span>
                    </Link>{' '}
                    and pass verification
                  </div>
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>User Name</div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <Input
                      name="userName"
                      aria-label="userName"
                      value={patientPersonalInfo?.userName}
                      className={styles.inputField}
                      disabled
                    />
                  ) : (
                    <>{patientPersonalInfo?.userName ?? ''}</>
                  )}
                  <div className={styles.description}>
                    To change the Username, you must confirm the changes in the{' '}
                    <Link
                      to={`${routes.home}${routes.profile}?tabNumber=${ProfileTabsEnum.Settings}`}
                      className={styles.link}>
                      <span>settings</span>
                    </Link>{' '}
                    and pass verification
                  </div>
                </div>
              </div>

              <div className={styles.field}>
                <div className={classNames(styles.label, styles.horizontal)}>
                  Mobile Phone Number{' '}
                  <span className={classNames(styles.subLabel, styles.widthLimit)}>
                    <div className={styles.widthLimitWrapper}>
                      <div className={styles.yellowMark}>*</div>Used for SMS verification
                    </div>
                  </span>
                </div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <MaskedInput
                      name={'phone'}
                      mask={Patterns.PhoneMask}
                      className={classNames(styles.inputField)}
                      value={
                        patientPersonalInfo?.phone
                          ? patientPersonalInfo?.phone.startsWith('+1')
                            ? patientPersonalInfo?.phone
                            : `+1-${patientPersonalInfo?.phone}`
                          : ''
                      }
                      disabled={true}
                    />
                  ) : (
                    <>{(patientPersonalInfo?.phone && phoneFormatter(patientPersonalInfo?.phone)) || ''}</>
                  )}
                  <div className={styles.description}>
                    To change the Mobile Phone Number, you must confirm the changes in the{' '}
                    <Link
                      to={`${routes.home}${routes.profile}?tabNumber=${ProfileTabsEnum.Settings}`}
                      className={styles.link}>
                      <span>settings</span>
                    </Link>{' '}
                    and pass verification
                  </div>
                </div>
              </div>

              <div className={styles.field}>
                <div className={styles.label}>Alternative Phone Number </div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <Controller
                      name="alternatePhone"
                      control={control}
                      render={({onChange, name, value}) => (
                        <div>
                          <MaskedInput
                            name={name}
                            mask={Patterns.PhoneMask}
                            className={classNames(styles.inputField)}
                            value={value ? (value.startsWith('+1') ? value : `+1-${value}`) : undefined}
                            onValueChanged={onChange}
                            isValid={!errors.alternatePhone}
                            errorMessage={errors.alternatePhone ? errors.alternatePhone.message : ''}
                          />
                        </div>
                      )}
                    />
                  ) : (
                    <>
                      {(patientPersonalInfo?.alternatePhone && phoneFormatter(patientPersonalInfo?.alternatePhone)) ||
                        ''}
                    </>
                  )}
                </div>
              </div>

              <div className={styles.field}>
                <div className={styles.label}>Date of Birth</div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      rules={{
                        required: REQUIRED_FIELD_ERROR,
                      }}
                      render={({onChange, name, value}) => (
                        <div>
                          <DatePicker
                            name={name}
                            value={parseISO(value)}
                            onChange={(e) => onChange(formatISO(e, {representation: 'date'}))}
                            inputType={DatePickerInputType.Wide}
                            max={new Date()}
                            view={DatePickerView.Selector}
                          />
                          {!!errors.dateOfBirth?.message && (
                            <Error errorMessage={errors.dateOfBirth.message} name={name} />
                          )}
                        </div>
                      )}
                    />
                  ) : (
                    <>
                      {(!!patientPersonalInfo?.dateOfBirth &&
                        formatDate(new Date(patientPersonalInfo?.dateOfBirth), DEFAULT_FORMAT)) ??
                        ''}
                    </>
                  )}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Sex</div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <Controller
                      name="sex"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({onChange, name, value}) => {
                        const selectedValue = sexOptions.find((option) => String(option.value) === String(value));

                        return (
                          <SingleDropdown
                            name={name}
                            value={selectedValue}
                            onChange={(sexOption) => onChange(String(sexOption.value))}
                            options={sexOptions}
                          />
                        );
                      }}
                    />
                  ) : (
                    <>{sexString}</>
                  )}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Language</div>
                <div className={styles.value}>
                  {isEditMode ? (
                    <>
                      <Controller
                        name="language"
                        control={control}
                        rules={{
                          required: REQUIRED_FIELD_ERROR,
                        }}
                        render={({onChange, name, value}) => {
                          const selectedValue = languageOptions.find(
                            (option) => String(option.value) === String(value),
                          );

                          return (
                            <SearchDropdown
                              name={name}
                              options={languageOptions}
                              currentOption={selectedValue}
                              placeholder={'Search here...'}
                              setOption={(langOption) => onChange(langOption.value)}
                              maxOptionsToDisplay={20}
                            />
                          );
                        }}
                      />
                      {isOtherSelected && (
                        <Controller
                          name="otherLanguage"
                          control={control}
                          rules={{
                            required: REQUIRED_FIELD_ERROR,
                          }}
                          render={({onChange, name, value}) => (
                            <div className={styles.otherLanguage}>
                              <Input
                                value={value}
                                className={styles.inputField}
                                name={name}
                                onValueChanged={onChange}
                                isValid={!errors.otherLanguage}
                              />
                              {!!errors.otherLanguage?.message && (
                                <div className={styles.errorField}>
                                  <Error errorMessage={errors.otherLanguage.message} name={name} />
                                </div>
                              )}
                            </div>
                          )}
                        />
                      )}
                      {!isEnglishSelected && (
                        <div className={styles.subValue}>
                          <p className={styles.subValueLabel}>Do you need an interpreter?</p>
                          <Controller
                            control={control}
                            defaultValue={false}
                            name={'isNeedInterpreter'}
                            render={({value, name, onChange}) => (
                              <>
                                {RADIO_OPTIONS.map((option) => (
                                  <div className={styles.radioBtn} key={option.id}>
                                    <Radio
                                      key={option.id}
                                      id={option.id}
                                      name={name}
                                      label={option.value ? 'Yes' : 'No'}
                                      value={option.value === value}
                                      onChange={() => onChange(option.value)}
                                    />
                                  </div>
                                ))}
                              </>
                            )}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {`${patientPersonalInfo?.language?.text ?? patientPersonalInfo?.language?.name} ` ?? ''}
                      {patientPersonalInfo?.language.isNeedInterpreter && (
                        <>
                          (<span className={styles.italicText}>Need an interpreter</span>)
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent gap={0}>
            <div className={styles.field}>
              <div className={styles.label}>Country</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name={'country'}
                    control={control}
                    rules={{required: REQUIRED_FIELD_ERROR}}
                    render={({name, value, onChange}) => {
                      const selectedValue = countries.find((option) => option.value === value);

                      const onChangeValue = (selectedItem: IOption<string | number>) => {
                        onChange(selectedItem.value);
                        setValue('state', '');
                        setValue('zipCode', '');
                        getStates(Number(selectedItem.value));
                      };

                      return (
                        <SingleDropdown
                          name={name}
                          className={styles.dropdownCountry}
                          options={countries}
                          value={selectedValue}
                          onChange={onChangeValue}
                        />
                      );
                    }}
                  />
                ) : (
                  <>{readonlyCountry}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>ZIP Code/Postal Code</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="zipCode"
                    control={control}
                    rules={{
                      required: REQUIRED_FIELD_ERROR,
                      pattern: validateZipCode,
                    }}
                    render={({onChange, name, value}) => (
                      <MaskedInput
                        name={name}
                        mask={maskZipCode}
                        placeholder={'ZIP Code/Postal Code'}
                        className={classNames(styles.inputField, styles.inputZipCode)}
                        value={value}
                        onValueChanged={onChange}
                        isValid={!errors.zipCode}
                        errorMessage={!!errors.zipCode ? errors.zipCode.message : ''}
                      />
                    )}
                  />
                ) : (
                  <>{patientPersonalInfo?.zipCode ?? ''}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}> {watch('country') === DEFAULT_COUNTRY_ID ? 'State' : 'Province'}</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name={'state'}
                    control={control}
                    render={({name, value, onChange}) => {
                      const selectedValue = states?.find((option) => option.value === value);

                      return (
                        <SingleDropdown
                          name={name}
                          className={styles.dropdownCountry}
                          options={states}
                          value={selectedValue}
                          onChange={(selectedItem) => onChange(selectedItem.value)}
                        />
                      );
                    }}
                  />
                ) : (
                  <>{readonlyState}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>City</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="city"
                    control={control}
                    render={({onChange, name, value}) => (
                      <Input
                        className={classNames(styles.inputField)}
                        name={name}
                        value={value}
                        onValueChanged={onChange}
                      />
                    )}
                  />
                ) : (
                  <>{patientPersonalInfo?.city ?? ''}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Address</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="address"
                    control={control}
                    rules={{
                      required: REQUIRED_FIELD_ERROR,
                    }}
                    render={({onChange, name, value}) => (
                      <div>
                        <Input
                          className={classNames(styles.inputField)}
                          name={name}
                          value={value}
                          onValueChanged={onChange}
                          isValid={!errors.address}
                        />
                        {!!errors.address?.message && (
                          <div className={styles.errorField}>
                            <Error errorMessage={errors.address.message} name={name} />
                          </div>
                        )}
                      </div>
                    )}
                  />
                ) : (
                  <>{patientPersonalInfo?.address ?? ''}</>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </form>
      <TwoFactorAuthForm
        onClose={visibleTwoFactorModalHandler}
        onSubmit={submitHandler}
        variant={'simple'}
        isVisible={visibleTwoFactorModal}
      />
    </>
  );
};

export {ProfilePersonalInfo, RADIO_OPTIONS};
export type {IProfilePersonalInfoProps, IProfilePersonalInfoForm};
