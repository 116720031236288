class VaccinationCreateRequestDto {
  constructor(
    public patientId: number | null = null,
    public items: Array<{
      question: string;
      value: string;
    }>,
  ) {}
}

export {VaccinationCreateRequestDto};
