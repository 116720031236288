import type {IAssessmentQuestionsGetResponseDto} from '../../dto';
import {AssessmentQuestionModel} from './assessment-question.model';

abstract class AssessmentQuestionModelFactory {
  static fromGetDto(dto: IAssessmentQuestionsGetResponseDto): AssessmentQuestionModel {
    class AssessmentQuestionModelExtended extends AssessmentQuestionModel {}

    return new AssessmentQuestionModelExtended(
      dto.name,
      dto.displayName,
      dto.displayOrder,
      dto.isRequired,
      dto.defaultValue,
      dto.visibleIf,
      dto.minValue,
      dto.maxValue,
      dto.type,
      dto.options,
    );
  }
}

export {AssessmentQuestionModelFactory};
