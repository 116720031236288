import type {Environment, IAuthConfigService} from './auth.config.service.interface';
import {injectable} from 'inversify';
import type {DebounceTimeout} from '../../debounce';

declare var API_URL: string;
declare var AUTH_CLIENT_ID: string;
declare var AUTH_DOMAIN: string;
declare var AUTH_AUDIENCE: string;
declare var STRIPE_PUB_KEY: string;
declare var LMS_COURSES_LINK: string;
declare var WEB_ENVIRONMENT: string;

const ONE_MINUTE_CLOCK_DELAY = 1000 * 60;
const CLOCK_SVG_ANIMATION_EXTRA_DELAY = 1000 * 3;

@injectable()
class AuthConfigService implements IAuthConfigService {
  readonly sessionWarningTimeout: DebounceTimeout;
  readonly tokenUpdateTimeout: DebounceTimeout;
  readonly clientId: string;
  readonly domain: string;
  readonly apiUrl: string;
  readonly redirectUri: string;
  readonly audience: string;
  readonly stripePubKey: string;
  readonly lmsCourseLink: string;
  readonly environment: Environment;

  constructor() {
    this.redirectUri = window.location.origin;

    this.tokenUpdateTimeout = ONE_MINUTE_CLOCK_DELAY;
    this.sessionWarningTimeout =
      ONE_MINUTE_CLOCK_DELAY * 14 - CLOCK_SVG_ANIMATION_EXTRA_DELAY - this.tokenUpdateTimeout;

    try {
      // eslint-disable-next-line no-template-curly-in-string
      this.apiUrl = process.env.REACT_APP_API_URL || (API_URL === '${API_URL}' ? '/api' : API_URL);
      // eslint-disable-next-line no-template-curly-in-string
      this.domain = process.env.REACT_APP_AUTH_DOMAIN || (AUTH_DOMAIN === '${AUTH_DOMAIN}' ? '' : AUTH_DOMAIN);
      this.clientId =
        // eslint-disable-next-line no-template-curly-in-string
        process.env.REACT_APP_AUTH_CLIENT_ID || (AUTH_CLIENT_ID === '${AUTH_CLIENT_ID}' ? '' : AUTH_CLIENT_ID);
      this.audience =
        // eslint-disable-next-line no-template-curly-in-string
        process.env.REACT_APP_AUTH_AUDIENCE || (AUTH_AUDIENCE === '${AUTH_AUDIENCE}' ? '' : AUTH_AUDIENCE);
      this.stripePubKey =
        // eslint-disable-next-line no-template-curly-in-string
        process.env.REACT_APP_STRIPE_PUB_KEY || (STRIPE_PUB_KEY === '${STRIPE_PUB_KEY}' ? '' : STRIPE_PUB_KEY);
      this.lmsCourseLink =
        // eslint-disable-next-line no-template-curly-in-string
        process.env.REACT_APP_LMS_COURSES_LINK || (LMS_COURSES_LINK === '${LMS_COURSES_LINK}' ? '' : LMS_COURSES_LINK);
      const environment =
        // eslint-disable-next-line no-template-curly-in-string
        process.env.REACT_APP_WEB_ENVIRONMENT || (WEB_ENVIRONMENT === '${WEB_ENVIRONMENT}' ? 'DEMO' : WEB_ENVIRONMENT);

      switch (environment) {
        case 'LOCAL':
        case 'DEV':
        case 'QA':
        case 'STAGE':
        case 'DEMO':
        case 'PROD':
          this.environment = environment;
          break;
        default:
          this.environment = 'DEMO';
          break;
      }
    } catch {
      this.apiUrl = '/api';
      this.domain = '';
      this.clientId = '';
      this.audience = '';
      this.stripePubKey = '';
      this.lmsCourseLink = '';
      this.environment = 'DEMO';
    }
  }
}

export {AuthConfigService};
