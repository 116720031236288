import {interpolate} from 'd3';
import type {IHealthMonitorPoint} from '../../../model';

const calculateLastPointHelper = (
  point1: IHealthMonitorPoint,
  point2: IHealthMonitorPoint,
  date: Date,
): IHealthMonitorPoint => {
  const time = date.getTime();
  const time1 = point1.x.getTime();
  const time2 = point2.x.getTime();

  const diff = time2 - time1;
  const subDiff = time - time1;

  const k = subDiff / diff;

  const interpolator = interpolate(point1, point2);

  return interpolator(k);
};

export {calculateLastPointHelper};
