import {NotificationsPutRequestDto} from './notifications-put-request-dto';
import type {NotificationModel} from '../../model';

class NotificationsPutRequestDtoFabric {
  static fromModel(model: NotificationModel): NotificationsPutRequestDto {
    return new NotificationsPutRequestDto([model.notificationId], model.isRead);
  }
}

export {NotificationsPutRequestDtoFabric};
