import type {IAccountGetResponseDto} from '../repository';
import {AccountModel} from './account.model';

class AccountModelFactory {
  static fromGetDto(dto: IAccountGetResponseDto) {
    const model: AccountModel = Object.keys(dto).reduce(
      (acc, key) =>
        key in acc
          ? {
              ...acc,
              [key]: dto[key as keyof IAccountGetResponseDto],
            }
          : acc,
      new AccountModel(),
    );

    model.language = {
      id: dto.language.id,
      name: dto.language.name,
      isNeedInterpreter: dto.language.isNeedInterpreter,
      text: dto.language.text,
    };

    model.id = dto.patientId ?? 0;

    model.userName = dto.userName ?? '';

    model.registrationDate = dto.startDate;

    return model;
  }
}

export {AccountModelFactory};
