import type {ISelfEfficacyAssessmentItem} from './self-efficacy-assessment.model';
import {SelfEfficacyAssessmentModel} from './self-efficacy-assessment.model';
import type {ISelfEfficacyAssessmentFormDto} from '../service';
import type {ISelfEfficacyAssessmentGetResponseDto} from '../repository';

class SelfEfficacyAssessmentModelFactory {
  static fromFormDto(dto: ISelfEfficacyAssessmentFormDto, patientId: number): SelfEfficacyAssessmentModel {
    const responses: ISelfEfficacyAssessmentItem[] = Object.keys(dto).reduce(
      (acc, fieldName) => [
        ...acc,
        {
          question: fieldName,
          value: dto[fieldName],
        },
      ],
      [] as ISelfEfficacyAssessmentItem[],
    );

    return new SelfEfficacyAssessmentModel(patientId, responses);
  }

  static fromGetDto(dto: ISelfEfficacyAssessmentGetResponseDto): SelfEfficacyAssessmentModel {
    return new SelfEfficacyAssessmentModel(
      dto.patientId,
      dto.items.map((i) => ({
        value: i.value,
        question: i.question,
      })),
      dto.id,
      new Date(dto.lastUpdated),
      dto.name,
    );
  }
}

export {SelfEfficacyAssessmentModelFactory};
