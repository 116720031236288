import type {IPdfViewerService} from './pdf-viewer.service.interface';
import {inject, injectable} from 'inversify';
import type {IFileStorageService} from '../../file-storage';
import {FILE_STORAGE_SERVICE} from '../../file-storage';
import {makeAutoObservable} from 'mobx';

@injectable()
class PdfViewerService implements IPdfViewerService {
  constructor(@inject(FILE_STORAGE_SERVICE) private readonly _files: IFileStorageService) {
    makeAutoObservable(this);
  }

  private _pdfData: string = '';

  get pdfData(): string {
    return this._pdfData;
  }

  async loadPdfData(objectFileId: string): Promise<void> {
    this._files
      .get(objectFileId)
      .then((file) => (this._pdfData = file.getLink()))
      .catch(console.error);
  }
}

export {PdfViewerService};
