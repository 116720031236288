import type {FC} from 'react';
import styles from './expiring-warning-modal.module.scss';
import {Button} from '../button';
import {Modal} from '../modal';
import {useExpiringClock} from '../expiring-clock';

interface IExpiringWarningProps {
  onSessionContinue: () => void;
  onSessionExpired?: () => void;
}

const ExpiringWarningModal: FC<IExpiringWarningProps> = ({onSessionContinue, onSessionExpired}) => {
  const {Clock} = useExpiringClock({name: 'logout-clock', onAnimationCompleted: onSessionExpired, shadowPlay: true});

  return (
    <Modal zIndex={1001}>
      <div className={styles.root}>
        <Clock />
        <p className={styles.message}>
          Your session will timeout soon for security purposes due to inactivity. Would you like to extend your session?
        </p>
        <Button className={styles.button} onClick={onSessionContinue}>
          Continue session
        </Button>
      </div>
    </Modal>
  );
};

export {ExpiringWarningModal};
export type {IExpiringWarningProps};
