import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, CollapseDescription, PageLoader} from '../../components';

import {WeeklyWellnessReportForm} from './form';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import {shiftToUTCEndOfDay} from '../../helpers';
import {useFormConfirmationDialog} from '../../hooks';
import styles from './weekly-wellness-report-survey.module.scss';
import type {ISurveyFormDto, ISurveyService} from '../../survey';
import {SURVEY_SERVICE, SurveyPeriodType, SurveyType} from '../../survey';

interface IService {
  surveyService: ISurveyService;
}

interface IWeeklyWellnessReportSurveyProps {
  lastOfWeekDate: Date;
  firstOfWeekDate: Date;
  patientId: number;
  specialtyType: string;
  isLoading: boolean;
  isSavingForm: boolean;
  timeZone: string;
}

const WeeklyWellnessReportSurvey: FC<IWeeklyWellnessReportSurveyProps & IService> = observer(
  ({lastOfWeekDate, patientId, specialtyType, surveyService, isLoading, isSavingForm, timeZone}) => {
    const {getConfirmation} = useFormConfirmationDialog();
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const loadSurvey = useCallback(
      () =>
        surveyService.load(
          SurveyType.CombinedGWBHTP,
          patientId,
          SurveyPeriodType.Week,
          shiftToUTCEndOfDay(lastOfWeekDate),
        ),
      [patientId, lastOfWeekDate, surveyService],
    );

    useEffect(() => {
      loadSurvey();
    }, [loadSurvey]);

    const survey = surveyService.getSurvey(SurveyType.CombinedGWBHTP, shiftToUTCEndOfDay(lastOfWeekDate));

    const readonly = surveyService.isReadonly(lastOfWeekDate, SurveyType.CombinedGWBHTP, timeZone);

    const isDescriptionMessageShown = useMemo(
      () => !readonly && !isLoading && !isSavingForm,
      [isLoading, isSavingForm, readonly],
    );

    const onSubmit = useCallback(
      async (data: ISurveyFormDto): Promise<void> => {
        setSubmitDisabled(true);
        const isConfirmed = await getConfirmation({});

        if (isConfirmed) {
          await surveyService.send(patientId, SurveyType.CombinedGWBHTP, data, true, false, new Date());
        } else {
          setSubmitDisabled(false);
        }
      },
      [getConfirmation, patientId, surveyService],
    );

    const submitButton = (
      <div className={styles.buttonContainer}>
        <Button
          bold
          block
          type={'submit'}
          className={styles.submit}
          disabled={isSubmitDisabled}
          isLoading={isSavingForm}
          testid={'weekly-wellness-report-submit'}>
          {`Save`}
        </Button>
      </div>
    );

    return !!survey?.spec ? (
      <>
        {(isLoading || isSavingForm) && <PageLoader />}
        {isDescriptionMessageShown && (
          <CollapseDescription>
            <p>
              Tell us how you're feeling! We would like to understand the impact your physical symptoms have on the way
              you feel emotionally. We'll use this information to alert you, your Gl provider, and your Trellus Elevate™
              Team when you may need additional support. Your answers should reflect how you felt over the last 7 days.
              <br />
              Please contact your healthcare provider for all medical advice and urgent medical matters.
            </p>
          </CollapseDescription>
        )}
        {!isLoading && (
          <>
            <section className={styles.boldSection} tabIndex={0}>
              <p>We want to know how you are doing on a weekly basis.</p>
              <p>Please fill out how you have been feeling for the past 7 days.</p>
            </section>
            <WeeklyWellnessReportForm
              spec={survey.spec}
              onSubmit={onSubmit}
              specialtyType={specialtyType}
              isOnBoarding={false}
              elementValues={survey.responses}
              isEverCompleted={!!survey.lastCompletedDate}
              readonly={readonly}>
              {submitButton}
            </WeeklyWellnessReportForm>
          </>
        )}
      </>
    ) : (
      <PageLoader />
    );
  },
);

const WeeklyWellnessReportSurveyInjected: FC<IWeeklyWellnessReportSurveyProps> = observer((props) => (
  <WeeklyWellnessReportSurvey {...props} surveyService={useInjection(SURVEY_SERVICE)} />
));

export {WeeklyWellnessReportSurveyInjected as WeeklyWellnessReportSurvey};
