import type {ThriveSkillDto} from '../dto/thrive-skill.dto';

export class ThriveSkill {
  id: number;
  name: string;
  value?: boolean;

  constructor(thriveSkillDto: ThriveSkillDto) {
    this.id = thriveSkillDto.skillTypeId;
    this.name = thriveSkillDto.skillTypeName;
    this.value = thriveSkillDto.value ?? false;
  }
}
