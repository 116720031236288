import classNames from 'classnames';
import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';
import type {IAccountService} from '../../../../account';
import {ACCOUNT_SERVICE} from '../../../../account';
import {ConnectCardArt} from '../../../../assets/emergency-card';
import {PrinterIcon} from '../../../../assets/icons';
import {LogoImage} from '../../../../assets/logo';
import {Button, Card, CardContent, CardHeader, Modal} from '../../../../components';
import {PrintLayout} from '../../../../components/print-layout';
import {providerTitle} from '../../../../enums/provider-title';
import {formatDate, phoneFormatter} from '../../../../helpers';
import {useInjection} from '../../../../ioc';
import styles from './emergency-card-modal.module.scss';

interface IProps {
  title: string;
  visible: boolean;
  onClose: () => void;
}

interface IService {
  account: IAccountService;
}

const DISEASE_NAME_NONE = 'NONE';

const EmergencyCardModal: FC<IProps & IService> = ({title, visible, onClose, account}) => {
  const [visibleModal, setVisibleModal] = useState(visible);

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const dateOfBirth = useMemo(() => {
    if (visibleModal) {
      return formatDate(new Date(String(account?.info?.dateOfBirth)), 'dd-MMM-yyyy');
    }
  }, [account.info?.dateOfBirth, visibleModal]);

  const providerInfo = useMemo(() => {
    const firstProvider = account.team?.filter((member) => !member.isTriadMember).slice(0, 1)[0];

    return !!firstProvider
      ? {
          firstName: firstProvider?.firstName,
          lastName: firstProvider?.lastName,
          providerTitle: providerTitle.get(String(firstProvider?.providerTitle)),
          officePhone: firstProvider?.officePhone,
        }
      : null;
  }, [account.team]);

  const patientSpecialtyAndDiseaseText = useMemo((): string => {
    const diseaseName = account.info?.disease?.name;
    const specialty = account.info?.specialty?.name;
    const needAdditionPart = diseaseName !== DISEASE_NAME_NONE;
    const additionPart = `, diagnosed with ${diseaseName}.`;

    return `I’m an ${specialty} patient${needAdditionPart ? additionPart : '.'}`;
  }, [account.info?.disease?.name, account.info?.specialty?.name]);

  useEffect(() => {
    setVisibleModal(visible);
  }, [visible]);

  return (
    <>
      {visibleModal && (
        <>
          <Modal onClose={onClose}>
            <Card className={styles.card}>
              <CardHeader
                title={title}
                closable
                noBorder
                onClose={onClose}
                logo={LogoImage}
                metaText="My TrellusConnect Card"
                titleLarge
                childrenTitleInline
                className={styles.Header}>
                <Button className={styles.printButton} onClick={handlePrint}>
                  <PrinterIcon /> print
                </Button>
              </CardHeader>
              <CardContent className={styles.Root}>
                <div className={styles.content}>
                  <CardContent gapX={0} gapTop={0} gapBottom={1} className={styles.PatientInfo}>
                    <div className={styles.patientName}>
                      <span className={styles.grayBold}>Patient Name:</span>
                      <span className={styles.Info}>
                        {account.info?.firstName} {account.info?.lastName}
                      </span>
                    </div>
                    <div className={styles.dateOfBirth}>
                      <span className={styles.grayBold}>Date Of Birth:</span>
                      <span className={styles.Info}>{dateOfBirth}</span>
                    </div>
                  </CardContent>
                  <div className={styles.contentText}>
                    <CardContent gapX={0} gapBottom={1}>
                      <p>{patientSpecialtyAndDiseaseText}</p>
                    </CardContent>
                    <CardContent gapX={0} gapBottom={1}>
                      <p>
                        Research shows that patients with IBD have too many unnecessary CT scans and are overprescribed
                        steroids and opioids, all of which can be harmful to us.
                      </p>
                      <div className={styles.Order}>
                        <div>
                          <p className={styles.TextSpace}>
                            Please call my primary GI Provider before performing a CT scan or prescribing steroids or
                            opioids in order to coordinate my care.
                          </p>
                          <p className={styles.ThankYouText}>Thank You for Taking Care of Me!</p>
                        </div>
                        <img src={ConnectCardArt} alt={title} className={styles.Image} />
                      </div>
                    </CardContent>
                  </div>
                </div>
                {!!providerInfo && (
                  <div className={styles.caption}>
                    <CardContent gapX={0} gapTop={2} gapBottom={1}>
                      <div className={classNames(styles.captionTitle, styles.bold)}>GI Provider</div>
                      <div className={styles.providerName}>
                        {providerInfo.firstName} {providerInfo.lastName}, {providerInfo.providerTitle}
                      </div>
                      <div className={styles.providerPhone}>{phoneFormatter(String(providerInfo.officePhone))}</div>
                    </CardContent>
                  </div>
                )}
              </CardContent>
            </Card>
          </Modal>

          <div className={styles.print}>
            <PrintLayout
              ref={componentRef}
              styles={{top: 20, left: 20, width: 323, height: 215, borderWidth: 1, padding: 1}}>
              <div className={styles.LogoBackground}>
                <img src={LogoImage} alt={title} className={styles.printLogo} />
              </div>
              <div className={styles.printTitle}>{title}</div>
              <div className={styles.printContent}>
                <div className={styles.printContentPatient}>
                  <div className={styles.printPatientName}>
                    <span className={styles.grayBold}>Patient Name:</span>
                    <span className={styles.printPatientInfo}>
                      {account.info?.firstName} {account.info?.lastName}
                    </span>
                  </div>
                  <div className={styles.printDateOfBirth}>
                    <span className={styles.grayBold}>Date Of Birth:</span>
                    <span className={styles.printPatientInfo}>{dateOfBirth}</span>
                  </div>
                </div>
                <div className={styles.printContentText}>
                  <p>{patientSpecialtyAndDiseaseText}</p>
                  <p>
                    Research shows that patients with IBD have too many unnecessary CT scans and are overprescribed
                    steroids and opioids, all of which can be harmful to us.
                  </p>
                  <div className={styles.Order}>
                    <div>
                      <p>
                        Please call my primary GI Provider before performing a CT scan or prescribing steroids or
                        opioids in order to coordinate my care.
                      </p>
                      <p className={styles.printThankYouText}>Thank You for Taking Care of Me!</p>
                    </div>
                    <img src={ConnectCardArt} alt={title} className={styles.printImage} />
                  </div>
                </div>
              </div>
              {!!providerInfo && (
                <div className={styles.printCaption}>
                  <div className={classNames(styles.printCaptionTitle, styles.bold)}>GI Provider</div>
                  <div className={styles.printProviderName}>
                    {providerInfo.firstName} {providerInfo.lastName}, {providerInfo.providerTitle}
                  </div>
                  <div className={styles.printProviderPhone}>{phoneFormatter(String(providerInfo.officePhone))}</div>
                </div>
              )}
            </PrintLayout>
          </div>
        </>
      )}
    </>
  );
};

const ObservableEmergencyCardModal = observer(EmergencyCardModal);
const InjectedEmergencyCardModal: FC<IProps> = (props) => (
  <ObservableEmergencyCardModal account={useInjection(ACCOUNT_SERVICE)} {...props} />
);

export {ObservableEmergencyCardModal as EmergencyCardModal, InjectedEmergencyCardModal};
