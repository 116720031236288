import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IAccountService} from '../service';
import {ACCOUNT_SERVICE, AccountService} from '../service';
import type {IAccountRepository} from '../repository';
import {ACCOUNT_REPOSITORY, AccountRepository} from '../repository';

const AccountModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IAccountRepository>(ACCOUNT_REPOSITORY).to(AccountRepository).inSingletonScope();

  bind<IAccountService>(ACCOUNT_SERVICE).to(AccountService).inSingletonScope();
});

export {AccountModule};
