import type {IProfileSettingsService} from './profile-settings.service.interface';
import type {ProfileSettingsModel, TimeZone} from '../model';
import {ProfileSettingsModelFactory} from '../model';
import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {IProfileSettingsRepository} from '../repository';
import {PROFILE_SETTINGS_REPOSITORY} from '../repository';
import type {IProfileSettingsFormDto} from '../../screens/private/profile/components';
import {getCurrentTimeZone} from './get-current-time-zone';
import type {INotifierService} from '../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../notifier';
import {ProfileSettingsFormType} from './profile-settings.service.enum';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import {routes} from '../../routing';
import * as workerTimers from 'worker-timers';
import {PROFILE_VERIFICATION_SERVICE} from '../../profile-verification';
import type {IProfileVerificationService} from '../../profile-verification';

const RESEND_CODE_TIMEOUT = 59;

@injectable()
class ProfileSettingsService implements IProfileSettingsService {
  private _selectedTypeForm: ProfileSettingsFormType = ProfileSettingsFormType.UserName;

  constructor(
    @inject(PROFILE_SETTINGS_REPOSITORY) private readonly _repo: IProfileSettingsRepository,
    @inject(NOTIFIER_SERVICE) private readonly notifier: INotifierService,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(PROFILE_VERIFICATION_SERVICE) private readonly _profileVerification: IProfileVerificationService,
  ) {
    makeAutoObservable(this);
  }

  private _isWaitedResendCode: boolean = false;

  get isWaitedResendCode(): boolean {
    return this._isWaitedResendCode;
  }

  private _phoneForVerifying: string = '';

  get phoneForVerifying(): string {
    return this._phoneForVerifying;
  }

  private _isUserNameValid: boolean = true;

  get isUserNameValid(): boolean {
    return this._isUserNameValid;
  }

  private _isEmailValid: boolean = true;

  get isEmailValid(): boolean {
    return this._isEmailValid;
  }

  private _currentTimeZone: TimeZone | null = null;

  get currentTimeZone(): TimeZone | null {
    return this._currentTimeZone;
  }

  private _notificationType: string = '';

  get notificationType(): string {
    return this._notificationType;
  }

  private _areSmsReceived: boolean = true;

  get areSmsReceived(): boolean {
    return this._areSmsReceived;
  }

  private _arePushReceived: boolean = true;

  get arePushReceived(): boolean {
    return this._arePushReceived;
  }

  private _timeZones: Array<TimeZone> = [];

  get timeZones(): ReadonlyArray<TimeZone> {
    return this._timeZones;
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _newUserName: string = '';

  get newUserName(): string {
    return this._newUserName;
  }

  private _newEmail: string = '';

  get newEmail(): string {
    return this._newEmail;
  }

  private _isCodeRequested: boolean = false;

  get isCodeRequested(): boolean {
    return this._isCodeRequested;
  }

  private _isUserNameUpdated: boolean = false;

  get isUserNameUpdated(): boolean {
    return this._isUserNameUpdated;
  }

  private _isEmailUpdated: boolean = false;

  get isEmailUpdated(): boolean {
    return this._isEmailUpdated;
  }

  private _isEmailConfirmed: boolean = false;

  get isEmailConfirmed(): boolean {
    return this._isEmailConfirmed;
  }

  private _isResendCodeProcessing: boolean = false;

  get isResendCodeProcessing(): boolean {
    return this._isResendCodeProcessing;
  }

  private _resendCodeTimeout: number = 0;

  get resendCodeTimeout(): number {
    return this._resendCodeTimeout;
  }

  async load(): Promise<void> {
    this._isLoading = true;
    const model = await this._repo.find().catch(console.error);

    if (model) {
      this.setServiceFields(model);
    }

    this._isUserNameUpdated = false;
    this._isLoading = false;
  }

  async sendSettings(formDto: IProfileSettingsFormDto): Promise<void> {
    const model = ProfileSettingsModelFactory.fromFormDto(formDto);

    this._isLoading = true;
    this._profileVerification.loadStart();

    await this._repo.save(model).catch(console.error);
    const newModel = await this._repo
      .find()
      .then((data) => {
        this._profileVerification.setSuccess();

        return data;
      })
      .catch((error) => {
        this._profileVerification.setError(error);
        console.error(error);
      });

    if (newModel) {
      this.setServiceFields(newModel);
    }

    this._isLoading = false;
  }

  setServiceFields(model: ProfileSettingsModel) {
    this._currentTimeZone = model.selectedTimeZone;
    this._notificationType = model.notificationType;
    this._areSmsReceived = model.areSmsReceived;
    this._arePushReceived = model.arePushReceived;
    this._timeZones = model.timeZones;
  }

  getCurrentTimeZone(): TimeZone {
    const currentTimeZoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return getCurrentTimeZone(this.timeZones, currentTimeZoneValue);
  }

  async resetPassword(): Promise<void> {
    this._profileVerification.loadStart();
    await this._repo
      .requestResetPassword()
      .then(() => {
        this.notifier.notify({
          text: 'Reset password had been sent to your email address',
          title: 'Success!',
          type: NotifyMessageTypeEnum.Success,
        });
      })
      .catch((error) => {
        this._profileVerification.setError(error);
        this.notifier.notify({
          text: 'We were unable to send an email with reset password instructions to you.\nPlease, try again later',
          type: NotifyMessageTypeEnum.Danger,
          title: 'Failed!',
        });
      });
  }

  async resetPhone(code: string, phone: string): Promise<void> {
    this._profileVerification.loadStart();

    await this._repo
      .requestResetPhone(code, phone)
      .then(() => {
        this._profileVerification.setSuccess();
      })
      .catch((error) => {
        this._profileVerification.setError(error);
      });
  }

  async requestCode(): Promise<void> {
    this._isResendCodeProcessing = true;

    await this._repo
      .requestCode()
      .then(() => (this._isCodeRequested = true))
      .catch((err) => {
        const message = this.getErrorMessageByFieldName(err, 'verificationPhoneNumberNotFound');

        if (message) {
          this.notifier.notify({text: message, title: 'Failed!', type: NotifyMessageTypeEnum.Danger});

          return;
        }

        this._isWaitedResendCode = true;
        this._resendCodeTimeout = RESEND_CODE_TIMEOUT;

        const codeTimeoutIntervalId = workerTimers.setInterval(() => {
          if (this._resendCodeTimeout) {
            --this._resendCodeTimeout;
          }
        }, 1000);

        workerTimers.setTimeout(() => {
          this._isWaitedResendCode = false;

          workerTimers.clearInterval(codeTimeoutIntervalId);
        }, RESEND_CODE_TIMEOUT * 1000);

        this.notifier.notify({
          text: 'It is not possible to send a new code more often than once every 1 minutes',
          title: 'Failed!',
          type: NotifyMessageTypeEnum.Danger,
        });
      })
      .finally(() => (this._isResendCodeProcessing = false));
  }

  async validateUserName(userName: string): Promise<void> {
    let isUserNameValid: boolean = true;

    await this._repo.requestUserNameValidation(userName).then((data) => {
      const message: string = JSON.parse(data).error;

      if (message) {
        this.notifier.notify({
          text: message,
          type: NotifyMessageTypeEnum.Danger,
          title: 'Failed!',
        });

        isUserNameValid = false;
      }
    });

    this._isUserNameValid = isUserNameValid;

    if (isUserNameValid) {
      this._newUserName = userName;
    }
  }

  async changeUserName(userName: string): Promise<void> {
    await this.validateUserName(userName);

    if (this._isUserNameValid) {
      await this._repo
        .requestPhoneVerifying()
        .then((phone) => {
          this._phoneForVerifying = phone;
        })
        .catch(console.error);

      await this.requestCode();

      this._selectedTypeForm = ProfileSettingsFormType.UserName;
    }
  }

  async validateEmail(email: string): Promise<void> {
    let isEmailValid: boolean = true;

    await this._repo.requestEmailValidation(email).then((data) => {
      const message: string = JSON.parse(data).error;

      if (message) {
        this.notifier.notify({
          text: message,
          type: NotifyMessageTypeEnum.Danger,
          title: 'Failed!',
        });

        isEmailValid = false;
      }
    });

    this._isEmailValid = isEmailValid;

    if (isEmailValid) {
      this._newEmail = email;
    }
  }

  async changeEmail(email: string): Promise<void> {
    await this.validateEmail(email);

    if (this._isEmailValid) {
      await this._repo
        .requestPhoneVerifying()
        .then((phone) => {
          this._phoneForVerifying = phone;
        })
        .catch(console.error);

      await this.requestCode();

      this._selectedTypeForm = ProfileSettingsFormType.Email;
    }
  }

  async send(code: string): Promise<void> {
    this._profileVerification.loadStart();

    switch (this._selectedTypeForm) {
      case ProfileSettingsFormType.Email:
        await this._repo
          .sendNewEmail(this._newEmail, code)
          .then(() => {
            this._isEmailUpdated = true;
            this._profileVerification.setSuccess();

            this.notifier.notify({
              text: 'We have sent you an E-mail with a link to confirm the new E-mail. This link will be valid for 24 hours.',
              title: 'Success!',
              type: NotifyMessageTypeEnum.Success,
            });
          })
          .catch((error) => {
            this.notifier.notify({
              text: 'Your code is invalid',
              title: 'Failed!',
              type: NotifyMessageTypeEnum.Danger,
            });
            this._profileVerification.setError(error);
          });

        break;

      case ProfileSettingsFormType.UserName:
        await this._repo
          .sendUserName(this._newUserName, code)
          .then(() => {
            this._isUserNameUpdated = true;
            this._profileVerification.setSuccess();

            this.notifier.notify({
              text: 'A Username had been changed',
              title: 'Success!',
              type: NotifyMessageTypeEnum.Success,
            });
          })
          .catch((error) => {
            this.notifier.notify({
              text: 'Your code is invalid',
              title: 'Failed!',
              type: NotifyMessageTypeEnum.Danger,
            });
            this._profileVerification.setError(error);
          });

        break;

      default:
        break;
    }
  }

  async confirmEmail(): Promise<void> {
    const url = new URL(window.location.href);

    const token = url.searchParams.get('token');

    if (token) {
      await this._repo
        .confirmEmail(token)
        .then(() => {
          this._isEmailConfirmed = true;

          this._routing.push(`${routes.home}${routes.profile}`);
        })
        .catch((e) => {
          this._routing.push(routes.home);

          console.error(e);
        });
    } else {
      this._routing.push(routes.home);
    }
  }

  private getErrorMessageByFieldName(error: any, field: string): string | null {
    try {
      const apiError = JSON.parse(error?.message);

      return this.getApiErrorMessage(apiError?.errors, field);
    } catch (error) {
      return null;
    }
  }

  private getApiErrorMessage(apiError: any, field: string): string | null {
    if (!apiError || !apiError[field] || !apiError[field].length) {
      return null;
    }

    return apiError[field][0];
  }
}

export {ProfileSettingsService};
