import type {FC} from 'react';
import React from 'react';
import styles from './notice.module.scss';

const links = [
  {title: 'Privacy policy', href: 'https://trellushealth.com/privacy-policy'},
  {title: 'Customer Terms of Use', href: 'https://trellushealth.com/terms-of-use'},
  {title: 'Nondiscrimination language notice', href: 'https://trellushealth.com/nondiscrimination-language-notice'},
  {title: 'Accessibility disclaimer', href: 'https://trellushealth.com/accessibility-disclaimer'},
];

const Notice: FC = () => (
  <ul className={styles.noticeList}>
    <li className={styles.noticeListCaption}>Legal notice</li>
    {links.map(({title, href}) => (
      <li className={styles.noticeListItem} key={title}>
        <a href={href} target="_blank" rel="noopener noreferrer" className={styles.noticeListLink}>
          {title}
        </a>
      </li>
    ))}
  </ul>
);

export {Notice};
