class BodyMassIndexSaveRequestDto {
  constructor(public weight: number = -1) {}
}

interface IBodyMassIndexSaveConvertedDto {
  weight: number;
}

export {BodyMassIndexSaveRequestDto};
export type {IBodyMassIndexSaveConvertedDto};
