import type {FC} from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import {DEFAULT_FORMAT} from '../../../helpers';
import {format} from 'date-fns';
import styles from './survey-readonly-element.module.scss';
import type {ISurveyElementResponse} from '../../index';
import {SurveySpecElementType} from '../../index';

const SurveyReadonlyElement: FC<{
  elementValues: Array<ISurveyElementResponse>;
  name: string;
  type: SurveySpecElementType;
  isConditionElement?: boolean;
}> = (props) => {
  const value = props.elementValues.find((elementValue) => elementValue.name === props.name)?.value;
  const allValues = props.elementValues
    .filter((elementValue) => elementValue.name === props.name)
    .map((elementValue) => elementValue.value);

  const displayValue = useMemo(() => {
    switch (props.type) {
      case SurveySpecElementType.Toggle:
        return !!value ? (value === 'true' ? 'Yes' : 'No') : '';
      case SurveySpecElementType.DynamicSection:
        return allValues
          .filter((value) => !!value)
          .map((value) => format(new Date(Date.parse(value)), DEFAULT_FORMAT))
          .join('\n');
      case SurveySpecElementType.Numeric:
      default:
        return value;
    }
  }, [allValues, props.type, value]);

  return (
    <div
      className={classNames(styles.questionValue, {
        [styles.conditionQuestionValue]: props.isConditionElement,
      })}
      data-testid={`survey-readonly-${props.name}`}>
      {displayValue}
    </div>
  );
};

export {SurveyReadonlyElement};
