import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import type {ILabResultsService} from '../service';
import {LAB_RESULTS_SERVICE} from '../service';
import {LabResultsList} from '../list';
import {Button, CollapseDescription, Modal, PageTitle} from '../../components';
import type {LabResultsFormDto} from '../form';
import {LabResultsForm} from '../form';
import styles from './lab-results-screen.module.scss';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';

interface IProps {
  labResults: ILabResultsService;
  account: IAccountService;
}

const LabResultsScreen: FC<IProps> = ({labResults, account}) => {
  const [openedResultId, setOpenedResultId] = useState<number | null>(null);
  const [isModalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    if (account.id) {
      labResults.getResults(account.id);
    }
  }, [account.id, labResults]);

  const onSubmit = useCallback(
    (data: LabResultsFormDto) => {
      if (account.id) {
        labResults.saveResult(data, account.id, openedResultId).then(() => {
          setModalOpened(false);
          setOpenedResultId(null);
        });
      }
    },
    [account.id, labResults, openedResultId],
  );

  const onClose = useCallback(() => {
    setOpenedResultId(null);
    setModalOpened(false);
  }, []);

  const defaultValues = useMemo(
    () => (!!openedResultId ? labResults.getFormDtoById(openedResultId) : null),
    [labResults, openedResultId],
  );

  return (
    <>
      <div className={styles.headerSection}>
        <PageTitle>Lab Results</PageTitle>
        <Button
          className={styles.button}
          onClick={() => {
            setOpenedResultId(null);
            setModalOpened(true);
          }}>
          Add new lab result
        </Button>
      </div>
      <CollapseDescription>
        <p>
          When you are taking certain medications, it’s important to get routine lab tests. <br />
          You will receive automated reminders based on your updated medication list and your disease course. <br />
          To make it easier to keep track of your results you can upload them here and you can also share them with your
          healthcare team.
        </p>
      </CollapseDescription>

      <LabResultsList
        results={labResults.results}
        openFormModal={() => setModalOpened(true)}
        onSelect={(model) => setOpenedResultId(model.id)}
      />
      {isModalOpened && (
        <Modal onClose={() => setModalOpened(false)} zIndex={1001}>
          <LabResultsForm onSubmit={onSubmit} defaultValues={defaultValues} onClose={onClose} />
        </Modal>
      )}
    </>
  );
};

const ObservableLabResultsScreen = observer(LabResultsScreen);
const InjectedLabResultsScreen: FC = () => (
  <ObservableLabResultsScreen labResults={useInjection(LAB_RESULTS_SERVICE)} account={useInjection(ACCOUNT_SERVICE)} />
);

export {InjectedLabResultsScreen as LabResultsScreen};
