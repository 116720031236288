interface IPreScreeningTask {
  id: number;
  text: string;
  isCompleted: boolean;
}

class PreScreeningModel {
  constructor(public tasks: Array<IPreScreeningTask> = []) {}
}

export {PreScreeningModel};
export type {IPreScreeningTask};
