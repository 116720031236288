import type {FC} from 'react';
import React, {useMemo} from 'react';
import {MedicationsCardRow} from '../card-row';
import {MedicationsCardWrapper} from '../card-wrapper';
import styles from '../../screen/medication-screen.module.scss';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../../helpers';

interface IMedicationsInfoCurrentProps {
  created: Date;
  author: string;
  dose: string;
  end: Date | null;
  name: string;
  start: Date | null;
  updated: Date | null;
  editor: string;
}

const MedicationsInfoCurrent: FC<IMedicationsInfoCurrentProps> = ({
  created,
  author,
  dose,
  end,
  name,
  start,
  updated,
  editor,
}) => {
  const coursePeriodDate = useMemo(() => {
    const startDate = start ? `${format(start, DEFAULT_FORMAT)}` : '—';
    const endDate = end ? `${format(end, DEFAULT_FORMAT)}` : '—';

    return (
      <>
        {startDate} / {endDate}
      </>
    );
  }, [end, start]);

  const courseCreatedDate = useMemo(() => format(created, DEFAULT_FORMAT), [created]);
  const courseUpdatedDate = useMemo(() => (!!updated ? format(updated, DEFAULT_FORMAT) : null), [updated]);

  return (
    <>
      <MedicationsCardRow>
        <MedicationsCardWrapper label={'Medication'}>
          <div className={styles.modalInfoText}>{name}</div>
        </MedicationsCardWrapper>
      </MedicationsCardRow>
      <MedicationsCardRow>
        <MedicationsCardWrapper label={'Course'}>
          <div className={styles.modalInfoText}>{coursePeriodDate}</div>
        </MedicationsCardWrapper>
        <MedicationsCardWrapper label={'Dose'}>
          <div className={styles.modalInfoText}>{dose}</div>
        </MedicationsCardWrapper>
      </MedicationsCardRow>
      <MedicationsCardRow>
        <MedicationsCardWrapper label={'Created'}>
          <div className={styles.modalInfoText}>{courseCreatedDate}</div>
        </MedicationsCardWrapper>
        <MedicationsCardWrapper label={'Created by'}>
          <div className={styles.modalInfoText}>{author}</div>
        </MedicationsCardWrapper>
      </MedicationsCardRow>
      {!!editor && (
        <MedicationsCardRow>
          <MedicationsCardWrapper label={'Updated'}>
            <div className={styles.modalInfoText}>{courseUpdatedDate}</div>
          </MedicationsCardWrapper>
          <MedicationsCardWrapper label={'Updated by'}>
            <div className={styles.modalInfoText}>{editor}</div>
          </MedicationsCardWrapper>
        </MedicationsCardRow>
      )}
    </>
  );
};
export {MedicationsInfoCurrent};
