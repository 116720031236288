import type {LabResultsModel} from '../../../model';
import {LabResultsCreateRequestDto} from './lab-results-create-request.dto';
import {formatDateApi} from '../../../../helpers';

class LabResultsCreateRequestDtoFactory {
  static fromModel(model: LabResultsModel): LabResultsCreateRequestDto {
    return new LabResultsCreateRequestDto(
      model.patientId,
      formatDateApi(model.labDate || new Date()),
      model.labTest,
      model.comparison,
      model.resultValue,
      model.resultType,
      model.unitsType,
    );
  }
}

export {LabResultsCreateRequestDtoFactory};
