import type {MessageThreadModel} from '../../../../message';
import {UserRoleEnum} from '../../../../enums';

const prepareStringForSearch = (str: string): string => str.toLowerCase().replace(' ', '');

const searchThreadHelper = (threads: readonly MessageThreadModel[], query: string): MessageThreadModel[] =>
  threads.filter((i) => {
    const name = prepareStringForSearch(i.recipient.name);
    const q = prepareStringForSearch(query);

    return name.includes(q) && i.recipient.role !== UserRoleEnum.Provider;
  });

export {searchThreadHelper};
