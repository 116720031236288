import type {FC} from 'react';
import React, {useCallback, useEffect} from 'react';
import type {IAssessmentsTableRow} from '../../components';
import {AssessmentsTable, Button, PageLoader, PageTitle} from '../../components';
import type {IDiseaseSeverityScreenerService} from '../service';
import {DISEASE_SEVERITY_SCREENER_SERVICE} from '../service';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import styles from './disease-severity-screener-list.module.scss';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../helpers';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';
import {SpecialtyType} from '../../enums';

interface IServiceProps {
  diseaseSeverityScreenerService: IDiseaseSeverityScreenerService;
  account: IAccountService;
}

const PAGE_TITLE_DEFAULT = 'Disease Severity Screener';
const PAGE_TITLE_IBS = 'Symptom Severity Scale';

const DiseaseSeverityScreenerList: FC<IServiceProps> = ({diseaseSeverityScreenerService, account}) => {
  const assessmentTableHeader = ['Date', 'Name'];

  const isIbs = account.info?.specialty?.name === SpecialtyType.IBS;
  const pageTitle = isIbs ? PAGE_TITLE_IBS : PAGE_TITLE_DEFAULT;

  const assessmentTableRows: IAssessmentsTableRow[] = diseaseSeverityScreenerService.listAssessments.map((item) => {
    const date = item.lastUpdated ? format(item.lastUpdated, DEFAULT_FORMAT) : '';

    return {
      id: item.id,
      cells: [date, item.name],
    };
  });

  const onReassessmentClick = useCallback(() => {
    diseaseSeverityScreenerService.navigateToAssessment(isIbs);
  }, [diseaseSeverityScreenerService, isIbs]);

  const navigateCallback = useCallback(
    (id: number) => {
      diseaseSeverityScreenerService.navigateToAssessment(isIbs, id);
    },
    [diseaseSeverityScreenerService, isIbs],
  );

  useEffect(() => {
    diseaseSeverityScreenerService.loadAssessments();
  }, [diseaseSeverityScreenerService]);

  return (
    <>
      <div className={styles.headerContainer}>
        <PageTitle>{pageTitle}</PageTitle>
        {!diseaseSeverityScreenerService.isReadonly && (
          <Button className={styles.button} onClick={onReassessmentClick} testid={'start-reassessment-button'}>
            {diseaseSeverityScreenerService.listAssessments.length ? 'Start the reassessment' : 'Start the assessment'}
          </Button>
        )}
      </div>
      {diseaseSeverityScreenerService.isLoading ? (
        <PageLoader />
      ) : (
        <AssessmentsTable
          header={assessmentTableHeader}
          rows={assessmentTableRows}
          onClickRow={navigateCallback}
          noResultsMessage={`Reminder to complete the ${pageTitle}`}
        />
      )}
    </>
  );
};

const DiseaseSeverityScreenerListLegacyInjected = observer(DiseaseSeverityScreenerList);

const DiseaseSeverityScreenerListInjected: FC = () => (
  <DiseaseSeverityScreenerListLegacyInjected
    account={useInjection(ACCOUNT_SERVICE)}
    diseaseSeverityScreenerService={useInjection(DISEASE_SEVERITY_SCREENER_SERVICE)}
  />
);

export {DiseaseSeverityScreenerListInjected as DiseaseSeverityScreenerList};
