import type {FC} from 'react';
import React, {useCallback, useEffect} from 'react';
import type {IStrengthsAssessmentService} from '../service';
import {STRENGTHS_ASSESSMENT_SERVICE} from '../service';
import {useInjection} from '../../ioc';
import {observer} from 'mobx-react';
import type {IAssessmentsTableRow} from '../../components';
import {AssessmentsTable, Button, PageLoader, PageTitle} from '../../components';
import styles from './strengths-assessment-list.module.scss';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../helpers';

interface IServiceProps {
  strengthsAssessmentService: IStrengthsAssessmentService;
}

const StrengthsAssessmentList: FC<IServiceProps> = ({strengthsAssessmentService}) => {
  const assessmentTableHeader = ['Date', 'Name'];

  const assessmentTableRows: IAssessmentsTableRow[] = strengthsAssessmentService.assessments.map((item) => {
    const date = item.lastUpdated ? format(item.lastUpdated, DEFAULT_FORMAT) : '';

    return {
      id: item.id,
      cells: [date, item.name],
    };
  });

  const onOpenAssessmentReadonly = useCallback(
    (id) => strengthsAssessmentService.navigateToAssessment(id),
    [strengthsAssessmentService],
  );

  useEffect(() => {
    strengthsAssessmentService.loadAssessments();
  }, [strengthsAssessmentService]);

  const onReassessmentClick = useCallback(() => {
    strengthsAssessmentService.navigateToAssessment();
  }, [strengthsAssessmentService]);

  return (
    <>
      <div className={styles.headerContainer}>
        <PageTitle>Strengths Assessments</PageTitle>
        {!strengthsAssessmentService.isReadOnly && (
          <Button className={styles.button} onClick={onReassessmentClick}>
            {strengthsAssessmentService.assessments.length ? 'Start the reassessment' : 'Start the assessment'}
          </Button>
        )}
      </div>
      {strengthsAssessmentService.isLoading ? (
        <PageLoader />
      ) : (
        <AssessmentsTable
          header={assessmentTableHeader}
          rows={assessmentTableRows}
          onClickRow={onOpenAssessmentReadonly}
          noResultsMessage={'Reminder to complete the Strengths Assessment'}
        />
      )}
    </>
  );
};

const StrengthsAssessmentListObserver = observer(StrengthsAssessmentList);

const StrengthsAssessmentListInjected: FC = () => (
  <StrengthsAssessmentListObserver strengthsAssessmentService={useInjection(STRENGTHS_ASSESSMENT_SERVICE)} />
);

export {StrengthsAssessmentListInjected as StrengthsAssessmentList};
