import type {MessageModel} from '../model';
import {
  MessageDeliveryStatus,
  MessageModelFactory,
  MessageStatus,
  MessageThreadModel,
  MessageThreadModelFactory,
} from '../model';
import type {IMessageService, IMessageThreadQuery} from './message.service.interface';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {IWssService} from '../../utils/wss';
import {WSS_SERVICE} from '../../utils/wss';
import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {
  IMessageGetResponseDto,
  IMessageSocketDto,
  IMessageStatusSocketDto,
  IMessageThreadGetResponseDto,
  IMessageThreadSocketDto,
} from '../dto';
import {MessagePostDto, MessageThreadPostDto} from '../dto';
import {MessageThreadStatus} from '../model/message-thread-status.enum';
import {generateFakeMessageId} from '../dto/helpers';
import {timeout} from '../../helpers/timeout.helper';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import type {IAlertIndicatorService} from '../../alert-indicator';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';
import type {TrellusTeamMember} from '../../account';

@injectable()
class MessageService implements IMessageService {
  private _selectedThreadId: number | null = null;

  constructor(
    @inject(HTTP_SERVICE) private readonly _http: IHttpService,
    @inject(WSS_SERVICE) private readonly _wss: IWssService,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(ALERT_INDICATOR_SERVICE) private readonly _alertIndicator: IAlertIndicatorService,
  ) {
    makeAutoObservable(this);
  }

  private _isLoading: boolean = true;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _threads: MessageThreadModel[] = [];

  get threads(): ReadonlyArray<MessageThreadModel> {
    return this._threads;
  }

  get selectedThread(): MessageThreadModel | null {
    return this._threads.find((i) => i.id === this._selectedThreadId) ?? null;
  }

  checkUnreadMessages(): void {
    const haveUnreadMessages = !!this._threads.reduce(
      (previousValue, currentValue) => previousValue + currentValue.unreadMessageCount,
      0,
    );

    this._alertIndicator.setIndicatorMessages(haveUnreadMessages);
  }

  updateThread(threadIndex: number, thread: MessageThreadModel): void {
    if (threadIndex > -1) {
      const threadNew = MessageThreadModelFactory.merge(this._threads[threadIndex], thread);

      this._threads = [...this._threads.slice(0, threadIndex), threadNew, ...this._threads.slice(threadIndex + 1)];
    } else {
      this._threads = [thread, ...this._threads];
    }
    this._threads = this._threads.sort(
      (a, b) => new Date(b.lastMessageDate!).getTime() - new Date(a.lastMessageDate!).getTime(),
    );

    this.checkUnreadMessages();
  }

  updateThreadMessages(threadIndex: number, message: MessageModel): void {
    if (threadIndex > -1) {
      const thread = this._threads[threadIndex];
      const messageIndex = thread.messages.findIndex(
        (i) => (!!i.fakeId && i.fakeId === message.fakeId) || i.id === message.id,
      );

      if (messageIndex > -1) {
        thread.messages = [
          ...thread.messages.slice(0, messageIndex),
          {
            ...thread.messages[messageIndex],
            ...message,
          },
          ...thread.messages.slice(messageIndex + 1),
        ];
      } else {
        thread.messages = [message, ...thread.messages];
        thread.previewText = (message.text?.length > 0 ? message.text.join() : message.attachments?.[0]?.name) ?? '';
        thread.lastMessageDate = message.createdAt;
        thread.unreadMessageCount++;
      }

      thread.messages.sort((a, b) => {
        const left = a.createdAt.getTime();
        const right = b.createdAt.getTime();

        return left - right;
      });

      this.updateThread(threadIndex, thread);
    }
  }

  async readThread(): Promise<void> {
    if (
      this._selectedThreadId &&
      this._selectedThreadId > -1 &&
      this.selectedThread &&
      this.selectedThread.unreadMessageCount > 0
    ) {
      const res = await this._http
        .post<IMessageGetResponseDto[]>('Threads/messages/status', {
          threadId: this._selectedThreadId,
          status: MessageThreadStatus.Read,
        })
        .catch(console.error);

      if (res) {
        const threadIndex = this._threads.findIndex((i) => i.id === this._selectedThreadId);
        const thread = this._threads[threadIndex];
        thread.messages = res.data.map((i) => MessageModelFactory.fromGetResponse(i));
        thread.status = MessageThreadStatus.Read;
        thread.unreadMessageCount = 0;

        this.updateThread(threadIndex, thread);
      }
    }
  }

  async loadThreadMessages(): Promise<void> {
    if (this._selectedThreadId && this._selectedThreadId > -1) {
      const threadIndex = this._threads.findIndex((i) => i.id === this._selectedThreadId);

      if (threadIndex < 0) {
        throw Error('thread not found');
      }

      const thread = this._threads[threadIndex];

      const res = await this._http
        .get<IMessageGetResponseDto[]>(`Threads/${this._selectedThreadId}/messages`)
        .catch(console.error)
        .finally(() => {
          this._isLoading = false;
        });

      if (res) {
        thread.messages = res.data.map((i) => MessageModelFactory.fromGetResponse(i));

        this.updateThread(threadIndex, thread);
      }
    }
  }

  async loadThreadsPreview(trellusTeam: TrellusTeamMember[], accountId: number): Promise<void> {
    const res = await this._http
      .get<IMessageThreadGetResponseDto[]>('Threads/preview')
      .catch(console.error)
      .finally(() => {
        this._isLoading = false;
      });

    if (res) {
      this._threads = res.data.map((i) => MessageThreadModelFactory.fromGetResponseDto(i, accountId));
    }

    const threadsToCreate = trellusTeam
      .filter(
        (trellusTeamMember) =>
          !this._threads.find((thread) => thread.recipient.id === trellusTeamMember.providerId) &&
          trellusTeamMember.openForCommunicationWithPatient,
      )
      .map((i) => MessageThreadModelFactory.fromTrellusTeamMember(i));

    this._threads.push(...threadsToCreate);
  }

  async sendMessage(accountId: number, text: string, attachmentObjectFileIds: string[]): Promise<void> {
    if (this._selectedThreadId === null || this.selectedThread === null) {
      throw new Error('no selected thread');
    }

    const threadIndex = this._threads.findIndex((i) => i.id === this._selectedThreadId);

    if (this._selectedThreadId < 0) {
      const fakeId = generateFakeMessageId(8);

      const dto = new MessageThreadPostDto(
        [accountId, this.selectedThread.recipient.id],
        text,
        attachmentObjectFileIds,
        fakeId,
      );
      const res = await this._http.post<IMessageThreadGetResponseDto>('Threads', dto).catch(console.error);

      if (res) {
        const thread = MessageThreadModelFactory.fromGetResponseDto(res.data, accountId);
        this.updateThread(threadIndex, thread);

        await this.selectThread({id: thread.id});
      }
    } else if (threadIndex > -1) {
      const fakeId = generateFakeMessageId(8);
      const dto = new MessagePostDto(this._selectedThreadId, accountId, text, attachmentObjectFileIds, fakeId);
      const res = await this._http.post<IMessageGetResponseDto>('Threads/messages', dto).catch(console.error);

      if (res) {
        const message = MessageModelFactory.fromGetResponse(res.data);

        this.updateThreadMessages(threadIndex, {...message, deliveryStatus: MessageDeliveryStatus.Sent});

        await timeout(500);

        const thread = this._threads[threadIndex];
        const existingMessage = thread.messages.find(
          (i) => (!!i.fakeId && i.fakeId === message.fakeId) || i.id === message.id,
        );

        if (existingMessage?.readStatus === MessageStatus.Sent) {
          this.updateThreadMessages(threadIndex, {...message, deliveryStatus: MessageDeliveryStatus.Delivered});
        }
      }
    }
  }

  subscribe(accountId: number): () => void {
    const unsubscribeThread = this._wss.subscribe('ThreadAdded', (data) => {
      const dto = data as unknown as IMessageThreadSocketDto;

      const thread = MessageThreadModelFactory.fromSocketDto(dto, accountId);

      const threadIndex = this._threads.findIndex((i) => i.recipient.id === thread.recipient.id);

      this.updateThread(threadIndex, thread);
    });

    const unsubscribeMessage = this._wss.subscribe('MessageAdded', (data) => {
      const dto = data as unknown as IMessageSocketDto;
      const message = MessageModelFactory.fromSocketDto(dto);
      const threadIndex = this._threads.findIndex((i) => i.id === message.threadId);

      this.updateThreadMessages(threadIndex, message);

      if (message.threadId === this._selectedThreadId) {
        this.readThread();
      }
    });

    const unsubscribeMessageStatus = this._wss.subscribe('ThreadStatusChanged', (data) => {
      const dto = data as unknown as IMessageStatusSocketDto;
      const readThread = this._threads.find((thread) => thread.id === dto.threadId);
      const threadIndex = this._threads.findIndex((thread) => thread.id === dto.threadId);

      if (readThread) {
        const updatedMessages = readThread.messages.map((m) => ({...m, readStatus: MessageStatus.Read}));
        const updatedThread = new MessageThreadModel(
          readThread.id,
          readThread.recipient,
          0,
          readThread.status,
          readThread.previewText,
          readThread.lastMessageDate,
          updatedMessages,
        );

        this.updateThread(threadIndex, updatedThread);

        if (readThread.id === this._selectedThreadId) {
          this.readThread();
        }
      }
    });

    return () => {
      unsubscribeThread();
      unsubscribeMessage();
      unsubscribeMessageStatus();
    };
  }

  closeThread(): void {
    this._selectedThreadId = null;
  }

  async selectThread(query: IMessageThreadQuery): Promise<void> {
    const {recipientId, id} = query;

    if (!id && !recipientId) {
      throw new Error('no thread filter options provided');
    }

    this._selectedThreadId =
      this._threads.find((i) => {
        if (id !== undefined) {
          return i.id === id;
        }

        if (recipientId !== undefined) {
          return i.recipient.id === recipientId;
        }

        throw new Error('no thread filter options provided');
      })?.id ?? null;

    await this.loadThreadMessages();

    await this.readThread();
  }
}

export {MessageService};
