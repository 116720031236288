import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import {Button, List, Modal, PageTitle, Spinner} from '../../../components';
import {CustomListItem} from '../../../components/custom-list-item';
import {DailyTaskActivityType} from '../../../enums';
import {useInjection} from '../../../ioc';
import type {Course} from '../../../patient-course/model/course';
import type {ICourseService} from '../../../patient-course/service';
import {COURSE_SERVICE} from '../../../patient-course/service';
import {CourseModal} from '../../../task/components/course-modal';
import {GoalStep3} from '../../../task/components/goal-modal/goal-steps';
import type {IWorksheet} from '../../../task/interfaces';
import type {IGoalService, ITaskService} from '../../../task/service';
import {GOAL_SERVICE, TASK_SERVICE} from '../../../task/service';
import type {IAuthConfigService} from '../../../utils/auth/config';
import {AUTH_CONFIG_SERVICE} from '../../../utils/auth/config';
import {mapLevelToGoalImage} from '../../helper';
import styles from './goal-journal-screen.module.scss';

interface IGoalJournalProps {
  authConfig: IAuthConfigService;
  accountService: IAccountService;
  goalService: IGoalService;
  courseService: ICourseService;
  taskService: ITaskService;
}

const GoalJournalScreen: FC<IGoalJournalProps> = ({
  accountService,
  authConfig,
  courseService,
  goalService,
  taskService,
}) => {
  // States
  const [course, setCourse] = useState<Course>({} as Course);
  const [isShowCourseModal, setIsShowCourseModal] = useState(false);
  const [isOpenWorksheetModal, setIsOpenWorksheetModal] = useState(false);

  const onLessonComplete = useCallback(
    (id: number) => {
      courseService.updateTask(id, false);
    },
    [courseService],
  );

  const onLessonBegin = useCallback(
    (id: number) => {
      courseService.updateTask(id, true);
    },
    [courseService],
  );

  const setFavoriteLesson = useCallback(
    (taskId: number, isFavorite: boolean) => {
      if (taskId) {
        courseService.setFavoriteLesson(taskId, isFavorite);
      }
    },
    [courseService],
  );

  const modalCourseHandler = useCallback(() => {
    setIsShowCourseModal(!isShowCourseModal);
  }, [isShowCourseModal]);

  const handleCloseWorksheetModal = () => {
    setIsOpenWorksheetModal(false);
  };

  const handleOpenWorksheetModal = (worksheet: IWorksheet) => {
    goalService.worksheet = worksheet;
    setIsOpenWorksheetModal(true);
  };

  const openCourse = (patCourseId: number, courseId: number) => {
    const findCourse = (courses: Course[]) =>
      courses.find((course) => course.patientCourseId === patCourseId && course.behaviorTypeId === courseId);

    const course = findCourse(courseService.inCompleted as Course[]) ?? findCourse(courseService.completed as Course[]);

    if (course) {
      setCourse(course);
      setIsShowCourseModal(true);
    }
  };

  const handleUpdate = useCallback(() => {
    goalService.loadWorksheets();
    setIsOpenWorksheetModal(false);
  }, [goalService]);

  useEffect(() => {
    setCourse(
      (prevCourse) =>
        courseService.courses?.find(
          (c) => c.patientCourseId === prevCourse.patientCourseId && c.behaviorTypeId === prevCourse.behaviorTypeId,
        ) || ({} as Course),
    );
  }, [courseService.courses]);

  useEffect(() => {
    goalService.loadWorksheets();
  }, [goalService]);

  useEffect(() => {
    handleCloseWorksheetModal();
  }, [goalService.archivedWorksheets]);

  useEffect(() => {
    taskService.load(DailyTaskActivityType.Lesson);
  }, [taskService]);

  if (goalService.isLoadingWorksheet) {
    return (
      <div className={styles.spinnerHolder}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <PageTitle className={styles.header}>Goal Journal</PageTitle>

      <div className={styles.listWrapper}>
        <List noStyle>
          {goalService.unArchivedWorksheets?.map((worksheet) => {
            const level = goalService.checkLevel(worksheet.totalScore);

            return (
              <CustomListItem
                hoverable
                card
                isGoal
                shadow
                isComplete={false}
                actions={null}
                image={mapLevelToGoalImage(level)}>
                <div className={styles.body}>
                  <div>
                    <h3
                      className={styles.title}
                      onClick={() => openCourse(worksheet.patientCourseId, worksheet.behaviorTypeId)}>
                      {worksheet.courseName}
                    </h3>
                    <p>{worksheet.goal}</p>
                  </div>
                  <div className={styles.actionHolder}>
                    <h3>
                      Score: <span className={styles.score}>{worksheet.totalScore}</span>
                    </h3>
                    <Button secondary radius bold onClick={() => handleOpenWorksheetModal(worksheet)}>
                      Worksheet
                    </Button>
                  </div>
                </div>
              </CustomListItem>
            );
          })}
        </List>
        <List noStyle collapse title="Archived">
          {goalService.archivedWorksheets?.map((worksheet) => {
            const level = goalService.checkLevel(worksheet.totalScore);

            return (
              <CustomListItem
                hoverable
                card
                isGoal
                shadow
                isComplete={false}
                actions={null}
                image={mapLevelToGoalImage(level)}>
                <div className={styles.body}>
                  <div>
                    <h3
                      className={styles.title}
                      onClick={() => openCourse(worksheet.patientCourseId, worksheet.behaviorTypeId)}>
                      {worksheet.courseName}
                    </h3>
                    <p>{worksheet.goal}</p>
                    {worksheet.goalAccomplished && <p className={styles.status}>Achieved</p>}
                  </div>
                  <div className={styles.actionHolder}>
                    <h3>
                      Score: <span className={styles.score}>{worksheet.totalScore}</span>
                    </h3>
                    <Button
                      lightGray
                      blackText
                      radius
                      bold
                      disabled
                      onClick={() => handleOpenWorksheetModal(worksheet)}>
                      Worksheet
                    </Button>
                  </div>
                </div>
              </CustomListItem>
            );
          })}
        </List>
      </div>

      {isOpenWorksheetModal && (
        <Modal closeIcon onClose={handleCloseWorksheetModal}>
          <GoalStep3 isGoalJournal onSubmit={handleUpdate} showPrevButton />
        </Modal>
      )}

      {isShowCourseModal && course && (
        <CourseModal
          course={course}
          lmsLink={authConfig.lmsCourseLink}
          onBegin={onLessonBegin}
          onChangeFavoriteItem={setFavoriteLesson}
          onClose={modalCourseHandler}
          onLessonComplete={onLessonComplete}
          tasks={course?.tasks}
          timeZone={accountService.timeZone}
          noStyle
        />
      )}
    </>
  );
};

const GoalJournalScreenObserver = observer(GoalJournalScreen);
const GoalJournalScreenInjected: FC = () => (
  <GoalJournalScreenObserver
    accountService={useInjection(ACCOUNT_SERVICE)}
    authConfig={useInjection(AUTH_CONFIG_SERVICE)}
    courseService={useInjection(COURSE_SERVICE)}
    goalService={useInjection(GOAL_SERVICE)}
    taskService={useInjection(TASK_SERVICE)}
  />
);

export {GoalJournalScreenInjected as GoalJournalScreen, GoalJournalScreenObserver};
