const EnumCommentUnionParseHelper = <EnumId = number | string>(id: EnumId, comment: string): EnumId | string => {
  const isCommentExists = !!comment?.length;

  return isCommentExists ? comment : id;
};

const EnumCommentSeparationParseHelper = <EnumId = number>(
  item: EnumId | string,
  commentItemId: EnumId,
): {
  id: EnumId;
  comment: string;
} => {
  const itemIsString = (i: EnumId | string): i is string => typeof i === 'string';

  return itemIsString(item)
    ? {
        comment: item,
        id: commentItemId,
      }
    : {
        id: item,
        comment: '',
      };
};
export {EnumCommentUnionParseHelper, EnumCommentSeparationParseHelper};
