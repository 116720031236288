import {ContainerModule} from 'inversify';
import type {IEncounterService} from '../service';
import {ENCOUNTER_SERVICE, EncounterService} from '../service';
import type {IEncounterRepository} from '../repository';
import {ENCOUNTER_REPOSITORY, EncounterRepository} from '../repository';

const EncounterModule = new ContainerModule((bind) => {
  bind<IEncounterRepository>(ENCOUNTER_REPOSITORY).to(EncounterRepository).inSingletonScope();
  bind<IEncounterService>(ENCOUNTER_SERVICE).to(EncounterService).inSingletonScope();
});

export {EncounterModule};
