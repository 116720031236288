import type {FC} from 'react';
import React from 'react';
import {formatDate} from '../../helpers';
import {observer} from 'mobx-react';
import type { IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';
import {useInjection} from '../../ioc';

interface IDateProps {
  date: Date;
  format?: string;
  lower?: boolean;
}

interface IService {
  account: IAccountService;
}

const Date: FC<IDateProps & IService> = ({date, format, lower, account}) => {
  const dateFormat = formatDate(date, format, account.timeZone);

  return lower ? <>{dateFormat.toLowerCase()}</> : <>{dateFormat}</>;
};

const ObservableDate = observer(Date);
const DateInjected: FC<IDateProps> = (props) => <ObservableDate account={useInjection(ACCOUNT_SERVICE)} {...props} />;
export {ObservableDate as DateWrapper, DateInjected};
