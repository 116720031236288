import {inject, injectable} from 'inversify';
import type {IDocumentsRepository} from '../repository';
import {DOCUMENTS_REPOSITORY} from '../repository';
import {makeAutoObservable} from 'mobx';
import type {IDocumentsFormDto} from '../components/documents-modal/documents-form.dto';
import type {DocumentDetailsModel, DocumentsModel} from '../model';
import {DocumentsModelFactory} from '../model';
import type {IDocumentsService} from './documents.service.interface';
import type {INotifierService} from '../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../notifier';
import type {IDownloadedDocumentItem} from '../../helpers';
import type { IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';

@injectable()
class DocumentsService implements IDocumentsService {
  constructor(
    @inject(DOCUMENTS_REPOSITORY) private readonly _repo: IDocumentsRepository,
    @inject(NOTIFIER_SERVICE) private readonly notifier: INotifierService,
    @inject(ACCOUNT_SERVICE) private readonly account: IAccountService,
  ) {
    makeAutoObservable(this);
  }

  private _selectedItem: DocumentDetailsModel | null = null;

  private _downloadedDocuments: Array<IDownloadedDocumentItem> = [];

  get downloadedDocuments(): Array<IDownloadedDocumentItem> {
    return this._downloadedDocuments;
  }

  get selectedItem(): DocumentDetailsModel | null {
    return this._selectedItem;
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _items: Array<DocumentsModel> = [];

  get items(): ReadonlyArray<DocumentsModel> {
    return this._items;
  }

  addLoadedDocument(id: string, isLoaded: boolean): void {
    this._downloadedDocuments.push({
      id,
      isError: !isLoaded,
    });
  }

  async load(): Promise<void> {
    const documents = await this._repo.list().catch(console.error);

    if (documents) {
      this._items = documents;
    }
  }

  async loadDetails(documentId: number): Promise<void> {
    this._isLoading = true;
    const item = await this._repo.getDetails({documentId}).catch(console.error);

    if (item) {
      this._selectedItem = item;
    }

    this._isLoading = false;
  }

  async create(formDto: IDocumentsFormDto, fileId: string): Promise<void> {
    const model = DocumentsModelFactory.fromFormDto(formDto, this.account.id, fileId);

    await this._repo
      .save(model)
      .then(() => {
        this.notifier.notify({
          text: 'New document has been uploaded',
          title: 'Success',
          type: NotifyMessageTypeEnum.Success,
        });
      })
      .catch(console.error);

    await this.load();
  }

  async deleteDocument(model: DocumentsModel): Promise<void> {
    await this._repo.remove(model).catch(console.error);
    await this.load();
  }
}

export {DocumentsService};
