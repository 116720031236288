import type {
  IMedicationsCourseDiscontinueGetResponseDto,
  IMedicationsCourseGetResponseDto,
  IMedicationsGetResponseDto,
} from '../repository';
import {
  isMedicationsCourseCustomMedicationGetResponseDto,
  isMedicationsCourseDiscontinueCustomMedicationGetResponseDto,
} from '../repository';
import {MedicationsModel} from './medications.model';
import type {IMedicationsCourseDiscontinueFormDto} from '../components';

class MedicationsModelFactory {
  static fromGetResponseDto(dto: IMedicationsGetResponseDto): MedicationsModel {
    let id: number;
    let isCustom: boolean;

    if (dto.medicationId !== null) {
      id = dto.medicationId;
      isCustom = false;
    } else if (dto.customMedicationId != null) {
      id = dto.customMedicationId;
      isCustom = true;
    } else {
      throw new Error('no id');
    }

    return new MedicationsModel(id, dto.medicationName, isCustom);
  }

  static fromCourseGetResponseDto(dto: IMedicationsCourseGetResponseDto): MedicationsModel {
    if (isMedicationsCourseCustomMedicationGetResponseDto(dto)) {
      return new MedicationsModel(dto.customMedicationId, dto.customMedicationName, true);
    } else {
      return new MedicationsModel(dto.medicationId, `${dto.medicationBrandName} (${dto.medicationGenericName})`);
    }
  }

  static fromDiscontinueCourseGetResponseDto(dto: IMedicationsCourseDiscontinueGetResponseDto): MedicationsModel {
    if (isMedicationsCourseDiscontinueCustomMedicationGetResponseDto(dto)) {
      return new MedicationsModel(dto.customMedicationId, dto.customMedicationName);
    } else {
      return new MedicationsModel(dto.medicationId, `${dto.medicationBrandName} (${dto.medicationGenericName})`);
    }
  }

  static fromCourseDiscontinueFormDto(_: IMedicationsCourseDiscontinueFormDto): MedicationsModel {
    return new MedicationsModel();
  }
}

export {MedicationsModelFactory};
