import {ContainerModule} from 'inversify';
import type {IEducationService} from '../service';
import {EDUCATION_SERVICE, EducationService} from '../service';
import {HTTP_SERVICE} from '../../utils/http';

const EducationModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IEducationService>(EDUCATION_SERVICE).to(EducationService).inSingletonScope();
});

export {EducationModule};
