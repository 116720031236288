import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE, PopupNotificationType} from '../../account';
import type {IAssessmentReportService} from '../../assessment-report/service';
import {ASSESSMENT_REPORT_SERVICE} from '../../assessment-report/service';
import type {ICompanyService} from '../../company';
import {COMPANY_SERVICE} from '../../company';
import {timeout} from '../../helpers/timeout.helper';
import type {ILayoutsService} from '../../layouts/service';
import {LAYOUTS_SERVICE} from '../../layouts/service';
import type {IMedicationsService} from '../../medications/service';
import {MEDICATIONS_SERVICE} from '../../medications/service';
import type {INotificationService} from '../../notifications/service';
import {NOTIFICATIONS_SERVICE} from '../../notifications/service';
import type {INotifierService} from '../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../notifier';
import type {IQuoteService} from '../../quote';
import {QUOTE_SERVICE} from '../../quote';
import {routes} from '../../routing';
import type {ITaskService} from '../../task/service';
import {TASK_SERVICE} from '../../task/service';
import type {IThriveSkillService} from '../../thrive-skill/service';
import {THRIVE_SKILL_SERVICE} from '../../thrive-skill/service';
import type {ITrellisService} from '../../trellis-visualization/service';
import {TRELLIS_SERVICE} from '../../trellis-visualization/service';
import type {IAuthService} from '../../utils/auth';
import {AUTH_SERVICE} from '../../utils/auth';
import type {ILocalStorageService} from '../../utils/local-storage';
import {LOCAL_STORAGE_SERVICE} from '../../utils/local-storage';
import type {IPopupService} from '../../utils/popup';
import {POPUP_SERVICE} from '../../utils/popup';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import type {IWssService} from '../../utils/wss';
import {WSS_SERVICE} from '../../utils/wss';
import type {IVaccinationService} from '../../vaccination/service';
import {VACCINATION_SERVICE} from '../../vaccination/service';
import type {IAppService} from './app.service.interface';

@injectable()
class AppService implements IAppService {
  public static LOCATION_STORAGE_KEY = 'inputLink';

  constructor(
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(LOCAL_STORAGE_SERVICE) private readonly _localStorage: ILocalStorageService,
    @inject(AUTH_SERVICE) private readonly _auth: IAuthService,
    @inject(WSS_SERVICE) private readonly _wss: IWssService,
    @inject(QUOTE_SERVICE) private readonly _quote: IQuoteService,
    @inject(NOTIFIER_SERVICE) private readonly _notifier: INotifierService,
    @inject(NOTIFICATIONS_SERVICE) private readonly _notifications: INotificationService,
    @inject(COMPANY_SERVICE) private readonly _company: ICompanyService,
    @inject(ASSESSMENT_REPORT_SERVICE) private readonly _assessmentReport: IAssessmentReportService,
    @inject(VACCINATION_SERVICE) private readonly _vaccination: IVaccinationService,
    @inject(TASK_SERVICE) private readonly _task: ITaskService,
    @inject(MEDICATIONS_SERVICE) private readonly _medications: IMedicationsService,
    @inject(LAYOUTS_SERVICE) private readonly _layouts: ILayoutsService,
    @inject(POPUP_SERVICE) private readonly _popup: IPopupService,
    @inject(ACCOUNT_SERVICE) private readonly _account: IAccountService,
    @inject(TRELLIS_SERVICE) private readonly _trellisService: ITrellisService,
    @inject(THRIVE_SKILL_SERVICE) private readonly _thriveSkill: IThriveSkillService,
  ) {
    makeAutoObservable(this);
  }

  private _isInitiated: boolean = false;

  get isInitiated(): boolean {
    return this._isInitiated;
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  async loadAfterOnboarding(patientId: number): Promise<void> {
    this._notifications.connectWss();
    this._vaccination.connectWss();
    this._task.connectWss();
    this._medications.connectWss();
    this._trellisService.connectWss();
    this._thriveSkill.connectWss();

    await this._assessmentReport.load();
  }

  async load(errorTimeoutMilliseconds = 3000): Promise<void> {
    if (!this._routing.isSameRoute(routes.version)) {
      this._isLoading = true;
      const error = await this._auth.init(async () => {
        await this._popup.loadPopupNotification(PopupNotificationType.PatientReadyForThriveFirstLogin);
      });

      if (!this._auth.isAuthenticated()) {
        this.saveLocation();

        if (error) {
          const {title, description} = error;

          this._notifier.notify({
            title: title === 'access_denied' ? 'Authorization error !' : title,
            text: description ?? '',
            type: NotifyMessageTypeEnum.Danger,
          });

          // able user to read the error message
          await timeout(errorTimeoutMilliseconds);

          await this._auth.logout();
        } else {
          await this._auth.login();
        }
      } else {
        this.restoreLocation();

        await Promise.all([
          this._wss.open(),
          this._quote.load(),
          this._company.load(),
          this._layouts.loadNavigationAccess(),
          this._account.getInfoDecision(),
          this._account.getMyExternalProviders(),
        ]);
      }
    }
  }

  finishInitiate(): void {
    if (this._auth.isAuthenticated()) {
      this._isInitiated = true;
      this._isLoading = false;
    }
  }

  saveLocation(): void {
    const locationFromStorage = this._localStorage.get(AppService.LOCATION_STORAGE_KEY);
    let location: string;

    if ((!!locationFromStorage && locationFromStorage !== this._routing.locationToSave) || !locationFromStorage) {
      location = this._routing.locationToSave;
    } else {
      location = locationFromStorage;
    }

    const locationStopList = [routes.default, routes.home, routes.myDashboard];
    const isNotInStopList = !locationStopList.includes(location);

    if (locationFromStorage !== location && isNotInStopList) {
      this._localStorage.set(AppService.LOCATION_STORAGE_KEY, location);
    }
  }

  restoreLocation(): void {
    const location = this._localStorage.get(AppService.LOCATION_STORAGE_KEY);

    if (location) {
      this._localStorage.delete(AppService.LOCATION_STORAGE_KEY);

      if (!this._routing.isSameRoute(location)) {
        this._routing.push(location);
      }
    }
  }

  addOnboardingRouteGuardListener(isOnboardingCompleted?: boolean): () => void {
    const notInStopList = this._routing.notIn([routes.diseaseInformation, routes.paymentInformation]);

    const notInOnboardingList = this._routing.notIn([
      routes.accountSetup,
      routes.diseaseInformation,
      routes.paymentInformation,
    ]);

    if (!isOnboardingCompleted && notInStopList) {
      this._routing.push(routes.accountSetup);
    }

    if (isOnboardingCompleted && !(notInStopList && notInOnboardingList)) {
      this._routing.push(routes.home);
    }

    const eventName = 'popstate';

    const listener = () => {
      if (this._routing.isSameRoute(routes.accountSetup)) {
        this._routing.push(routes.diseaseInformation);
      }
    };

    window.addEventListener(eventName, listener);

    return () => {
      window.removeEventListener(eventName, listener);
    };
  }
}

export {AppService};
