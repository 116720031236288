import type {AccountPopupNotificationInfo, PopupNotificationType} from '../../../account';
import type {IHttpService} from '../../http';
import {HTTP_SERVICE} from '../../http';
import {inject, injectable} from 'inversify';
import type {IPopupService} from './popup.service.interface';

@injectable()
class PopupService implements IPopupService {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  private _popupNotifications: Partial<Record<PopupNotificationType, AccountPopupNotificationInfo>> = {};

  getPopupNotification(type: PopupNotificationType): AccountPopupNotificationInfo | undefined {
    return this._popupNotifications[type];
  }

  async loadPopupNotification(type: PopupNotificationType): Promise<void> {
    try {
      const {data: dto} = await this._http.get<Array<AccountPopupNotificationInfo>>('popupNotification', {
        params: {popupNotificationType: type},
      });

      this._popupNotifications[type] = dto[0];
    } catch (error) {
      console.error(error);
    }
  }

  async updatePopupNotificationStatus(popupNotificationId: number, isRead: boolean): Promise<void> {
    try {
      await this._http.put(`popupNotification/updateStatus/${popupNotificationId}/${isRead}`, null);

      const notification = Object.values(this._popupNotifications).find(
        (n) => n.popupNotificationId === popupNotificationId,
      );

      if (notification) {
        notification.count += 1;
        notification.isRead = isRead;
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export {PopupService};
