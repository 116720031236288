import type {FC} from 'react';
import {useEffect, useMemo, useState} from 'react';
import styles from './avatar.module.scss';
import classNames from 'classnames';
import type {IFileStorageService} from '../../file-storage';
import {FILE_STORAGE_SERVICE} from '../../file-storage';
import {getFileDataUrl} from '../../file-storage/helpers';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';

interface IAvatarFile {
  src: string;
  fileId?: string;
}

type IAvatar = IAvatarFile | string;

interface IAvatarProps {
  id?: string;
  src?: IAvatar;
  size?: number | string;
  alt: string;
  className?: string;
  onClick?: () => void;
}

interface IService {
  fileStorage: IFileStorageService;
}

const isAvatarFile = (obj?: IAvatar): obj is IAvatarFile => !!obj && typeof obj !== 'string';

const AVATAR_COLORS = [styles.green, styles.red, styles.gray, styles.yellow];

const Avatar: FC<IAvatarProps & IService> = (props) => {
  const [src, setSrc] = useState<string | undefined>();

  useEffect(() => {
    if (isAvatarFile(props.src)) {
      if (props.src.fileId) {
        props.fileStorage.get(props.src.fileId).then((file) => setSrc(getFileDataUrl(file)));
      } else {
        setSrc(props.src.src);
      }
    } else if (props.src && !isAvatarFile(props.src)) {
      setSrc(props.src);
    } else {
      setSrc(undefined);
    }
  }, [props.src, props.fileStorage]);

  const color = useMemo(() => {
    const index: number = Math.floor((((props.alt.length / AVATAR_COLORS.length) % 1) * Math.pow(10, 2)) / 25);

    return AVATAR_COLORS[index];
  }, [props.alt.length]);

  const name = useMemo(
    () =>
      props.alt
        .split(' ')
        .map((i) => i[0])
        .slice(0, 2)
        .join('')
        .toUpperCase(),
    [props.alt],
  );

  const placeholder = useMemo(
    () => (
      <div
        id={props.id}
        className={classNames(styles.placeholder, props.className, color, {
          [styles.clickable]: !!props.onClick,
        })}
        style={{width: props.size, height: props.size}}
        onClick={props.onClick}
        data-testid={'test-avatar'}>
        <span>{name}</span>
      </div>
    ),
    [props.id, props.className, props.onClick, props.size, color, name],
  );

  return src ? (
    <img
      id={props.id}
      className={classNames(styles.root, props.className, {
        [styles.clickable]: !!props.onClick,
      })}
      height={props.size}
      width={props.size}
      src={src}
      alt={props.alt}
      onClick={props.onClick}
    />
  ) : (
    placeholder
  );
};

const ObservableAvatar = observer(Avatar);
const AvatarInjected: FC<IAvatarProps> = (props) => (
  <ObservableAvatar fileStorage={useInjection(FILE_STORAGE_SERVICE)} {...props} />
);

export {AvatarInjected as Avatar, ObservableAvatar};
export type {IAvatarFile, IAvatarProps, IAvatar};
