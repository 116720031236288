import type {TaskResponseDto, TaskSocketDto} from '../../dto';
import {TaskModel} from '../index';
import {shiftTimezone} from '../../../helpers';

class TaskModelFactory {
  static fromResponseDto(dto: TaskResponseDto): TaskModel {
    const task = new TaskModel(
      dto.patientDailyTaskActivityIntervalId,
      dto.text || dto.subject,
      dto.header || '',
      shiftTimezone(new Date(dto.startDate)),
      shiftTimezone(new Date(dto.endDate)),
      dto.doneDate ? shiftTimezone(new Date(dto.doneDate)) : (dto.doneDate as null),
      !!dto.doneDate,
      dto.patientDailyTaskId,
      dto.courseLink,
      dto.dailyTaskActivityType,
      !!dto.beginDate ? shiftTimezone(new Date(dto.beginDate)) : null,
      !!dto.firstCompletionDate ? shiftTimezone(new Date(dto.firstCompletionDate)) : null,
      dto.isFavoriteLesson,
      dto.lessonDisplayTitle,
      dto.lessonThumbnailObjectFileId ?? dto.categoryDefaultThumbnailPath,
      null,
      null,
      dto.behaviorType,
      dto.behaviorTypeName,
      dto.patientCourseId,
      dto.patientCourseName,
      dto.categoryId,
      dto.courseThumbnailObjectFileId ?? dto.categoryDefaultThumbnailPath,
      null,
      dto.skillId,
    );

    if (dto.assessmentThumbnailStaticPath) {
      task.assessmentThumbnailStaticPath = dto.assessmentThumbnailStaticPath;
    }

    return task;
  }

  static fromSocketDto(dto: TaskSocketDto): TaskModel {
    return new TaskModel(
      dto.patientDailyTaskActivityIntervalId,
      dto.text || dto.subject,
      dto.header || '',
      shiftTimezone(new Date(dto.startDate)),
      shiftTimezone(new Date(dto.endDate)),
      dto.doneDate ? shiftTimezone(new Date(dto.doneDate)) : (dto.doneDate as null),
      !!dto.doneDate,
      dto.patientDailyTaskId,
      dto.courseLink,
      dto.dailyTaskActivityType,
      !!dto.beginDate ? shiftTimezone(new Date(dto.beginDate)) : null,
      !!dto.firstCompletionDate ? shiftTimezone(new Date(dto.firstCompletionDate)) : null,
      dto.isFavoriteLesson,
    );
  }
}

export {TaskModelFactory};
