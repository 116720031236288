import {CrpExpression, LabTests, UnitsTypesEnum} from '../enums';
import type {IOption} from '../../types';
import {mapFromOptionsHelper} from '../../helpers';

const labTestOptions = mapFromOptionsHelper<LabTests>([
  {
    value: LabTests.CRP,
    displayName: 'CRP',
  },
  {
    value: LabTests.FecalCalprotectin,
    displayName: 'Fecal Calprotectin',
  },
  {
    value: LabTests.VitaminD,
    displayName: 'Vitamin D',
  },
  {
    value: LabTests.Hemoglobin,
    displayName: 'Hemoglobin',
  },
  {
    value: LabTests.Albumin,
    displayName: 'Albumin',
  },
]);

const expressionOptions = mapFromOptionsHelper<CrpExpression>([
  {
    value: CrpExpression.Blank,
    displayName: '',
  },
  {
    value: CrpExpression.Less,
    displayName: '<',
  },
  {
    value: CrpExpression.Equal,
    displayName: '=',
  },
  {
    value: CrpExpression.More,
    displayName: '>',
  },
]);

const unitTypesOptions = mapFromOptionsHelper<UnitsTypesEnum>([
  {
    value: UnitsTypesEnum.Blank,
    displayName: '',
  },
  {
    value: UnitsTypesEnum.MgPerLiter,
    displayName: 'mg/L',
  },
  {
    value: UnitsTypesEnum.GramPerDeciLiter,
    displayName: 'g/dL',
  },
  {
    value: UnitsTypesEnum.McgPerGram,
    displayName: 'mcg/g',
  },
  {
    value: UnitsTypesEnum.McgPerLiter,
    displayName: 'mcg/L',
  },
  {
    value: UnitsTypesEnum.MgPerDeciLiter,
    displayName: 'mg/dL',
  },
  {
    value: UnitsTypesEnum.NmolPerLiter,
    displayName: 'nmol/L',
  },
  {
    value: UnitsTypesEnum.NgPerMilliLiter,
    displayName: 'ng/ML',
  },
  {
    value: UnitsTypesEnum.Other,
    displayName: 'Other',
  },
]);

export {labTestOptions, expressionOptions, unitTypesOptions};
export type {IOption};
