import type React from 'react';
import type {IconTypeLegacy} from '../icon-legacy';

interface ButtonProps {
  label?: string;
  type: ButtonType;
  testId?: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
  icon?: IconTypeLegacy;
}

enum ButtonType {
  Common = 'common',
  Small = 'small',
  Link = 'link',
  BlockAction = 'block-action',
  BlockActionLight = 'block-action-light',
  CancelSmall = 'cancel-small',
  CancelCommon = 'cancel-common',
  OutlinedSmall = 'outlined-small',
  SecondarySmall = 'secondary-small',
  SeparateIcon = 'separate-icon',
  DottedSmall = 'dotted-small',
  Banner = 'banner',
  Icon = 'icon',
}

export type {ButtonProps};
export {ButtonType};
