enum MedicationsUnitsTypesEnum {
  BlankPerNull = 0,
  Gram = 1,
  Mg = 2,
  Ml = 3,
  MgPerKg = 4,
  UnitS = 5,
  Applicatorful = 6,
  Suppository = 7,
}

export {MedicationsUnitsTypesEnum};
