import type {IMessageAttachmentGetResponseDto} from '../dto/message-attachment-get-response.dto';
import type {IMessageAttachmentSocketDto} from '../dto/message-attachment-socket.dto';
import {MessageAttachmentModel} from './message-attachment.model';

class MessageAttachmentModelFactory {
  static fromGetResponse(dto: IMessageAttachmentGetResponseDto): MessageAttachmentModel {
    return new MessageAttachmentModel(
      dto.objectFileId,
      dto.fileName,
      new Date(dto.createdDate),
      dto.mimeType,
      dto.size,
    );
  }

  static fromSocketDto(dto: IMessageAttachmentSocketDto): MessageAttachmentModel {
    return new MessageAttachmentModel(
      dto.objectFileId,
      dto.fileName,
      new Date(dto.createdDate),
      dto.mimeType,
      dto.size,
    );
  }
}

export {MessageAttachmentModelFactory};
