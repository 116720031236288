import type {FC, MouseEventHandler} from 'react';
import React, {useCallback, useMemo} from 'react';
import styles from './medications-list-item.module.scss';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../../helpers';

interface IMedicationsListItemProps {
  id: number;
  discontinue?: Date | null;
  dose: string;
  medicationName: string;
  onEdit?: () => void;
  onInfo?: () => void;
  onDiscontinue?: () => void;
}

const MedicationsListItem: FC<IMedicationsListItemProps> = ({
  id,
  discontinue,
  dose,
  medicationName,
  onInfo,
  onDiscontinue,
  onEdit,
}) => {
  const date = useMemo(() => (discontinue ? format(discontinue, DEFAULT_FORMAT) : '—'), [discontinue]);
  const showDiscontinue: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      if (onDiscontinue) {
        onDiscontinue();
      }
    },
    [onDiscontinue],
  );

  const showEdit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      if (onEdit) {
        onEdit();
      }
    },
    [onEdit],
  );

  return (
    <tr className={styles.row} onClick={onInfo}>
      <td className={styles.field}>{medicationName}</td>
      <td className={styles.field}>{dose}</td>
      <td className={styles.field}>
        {!onDiscontinue ? (
          date
        ) : (
          <button className={styles.btn} onClick={showDiscontinue} data-testid="show-discontinue-button">
            Discontinue
          </button>
        )}
      </td>
      {onEdit && (
        <td className={styles.field}>
          <button className={styles.btn} onClick={showEdit} data-testid="edit-button">
            Edit
          </button>
        </td>
      )}
    </tr>
  );
};

export {MedicationsListItem};
export type {IMedicationsListItemProps};
