import type {FC, KeyboardEventHandler} from 'react';
import React, {useCallback} from 'react';
import styles from './checkable-panel.module.scss';
import classnames from 'classnames';

interface ICheckablePanelProps {
  id: string;
  name: string;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  fontSize?: string;
  containerClassName?: string;
  labelClassName?: string;
  callback?: () => void;
}

const CheckablePanel: FC<ICheckablePanelProps> = ({
  label,
  id,
  name,
  value,
  onChange,
  disabled,
  fontSize,
  containerClassName,
  labelClassName,
  callback,
}) => {
  const change = useCallback(() => {
    onChange(!value);
    if (callback) {
      callback();
    }
  }, [onChange, value, callback]);

  const onEnterPress: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        return change();
      }
    },
    [change],
  );

  return (
    <div className={classnames(styles.clickablePanel, containerClassName)}>
      <input
        className={styles.checkbox}
        type="checkbox"
        id={id}
        name={name}
        checked={value}
        onChange={change}
        onKeyPress={onEnterPress}
        data-testid={`checkbox-${name}`}
        disabled={disabled}
        tabIndex={-1}
      />
      <label
        className={classnames(styles.inputLabel, labelClassName)}
        htmlFor={id}
        style={{fontSize}}
        role="checkbox"
        aria-checked={value}
        tabIndex={0}
        onChange={change}
        onKeyPress={onEnterPress}>
        {label}
      </label>
    </div>
  );
};

export {CheckablePanel};
export type {ICheckablePanelProps};
