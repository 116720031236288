import styles from './survey-radio-button.module.scss';
import type {FC, KeyboardEventHandler} from 'react';
import classNames from 'classnames';

interface ISurveyRadioButtonOption {
  id: number | string;
  text: string;
}

interface ISurveyRadioButtonProps {
  name: string;
  value?: ISurveyRadioButtonOption;
  option: ISurveyRadioButtonOption;
  disabled?: boolean;
  isError?: boolean;
  onChange: (value: ISurveyRadioButtonOption) => void;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const SurveyRadioButton: FC<ISurveyRadioButtonProps> = (props) => {
  const onEnterPress: KeyboardEventHandler = (e) => {
    if (e.code === 'Enter') {
      return props.onChange(props.option);
    }
  };

  const title = props.ariaLabelTitle ?? props.name;
  const error = !!props.ariaLabelError ? `${props.ariaLabelError}.` : "";

  return (
  <div className={classNames(styles.optionWrapper, {[styles.optionHaveError]: props.isError})}>
    <input
      className={classNames(styles.option, {[styles.optionError]: props.isError})}
      type="radio"
      id={`survey-radio-button-${props.name}-${props.option.id}`}
      value={String(props.option.id)}
      name={props.name}
      aria-label={`${error} ${title}. Option: ${props.option.text}`}
      checked={props.option.id === props?.value?.id}
      data-testid={`survey-radio-button-${props.name}`}
      readOnly
      onChange={() => props.onChange(props.option)}
      tabIndex={-1}
      onFocus={() => document.getElementById(`survey-radio-button-label-${props.name}-${props.option.id}`)?.focus()}
    />
    <label
      className={styles.label}
      id={`survey-radio-button-label-${props.name}-${props.option.id}`}
      aria-label={`${error} ${title}. Option: ${props.option.text}`}
      aria-checked={props.option.id === props?.value?.id}
      onKeyPress={onEnterPress}
      tabIndex={props.disabled ? -1 : 0}
      role="radio"
      htmlFor={`survey-radio-button-${props.name}-${props.option.id}`}>
      {props.option.text}
    </label>
  </div>
  );
};

export {SurveyRadioButton};
export type {ISurveyRadioButtonOption, ISurveyRadioButtonProps};
