import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {ILabResultsRepository} from '../repository';
import {LAB_RESULTS_REPOSITORY, LabResultsRepository} from '../repository';
import type {ILabResultsService} from '../service';
import {LAB_RESULTS_SERVICE, LabResultsService} from '../service';

const LabResultsModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<ILabResultsRepository>(LAB_RESULTS_REPOSITORY).to(LabResultsRepository).inSingletonScope();

  bind<ILabResultsService>(LAB_RESULTS_SERVICE).to(LabResultsService).inSingletonScope();
});

export {LabResultsModule};
