import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {INotifierService} from '../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../notifier';
import {routes} from '../../routing';
import type {IGoalService} from '../../task/service';
import {GOAL_SERVICE} from '../../task/service';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import type {IWssService, SocketData} from '../../utils/wss';
import {WSS_SERVICE} from '../../utils/wss';
import type {ThriveSkillSocketDto} from '../dto/thrive-skill.dto';
import type {ThriveSkill} from '../model/thrive-skill';
import type {IThriveSkillRepository} from '../repository';
import {THRIVE_SKILL_REPOSITORY} from '../repository';
import type {IThriveSkillService} from './thrive-skill.service.interface';

@injectable()
class ThriveSkillService implements IThriveSkillService {
  constructor(
    @inject(GOAL_SERVICE) private readonly _goal: IGoalService,
    @inject(NOTIFIER_SERVICE) private readonly _notifier: INotifierService,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(THRIVE_SKILL_REPOSITORY) private readonly _repo: IThriveSkillRepository,
    @inject(WSS_SERVICE) private readonly _wss: IWssService,
  ) {
    makeAutoObservable(this);
  }

  private _isOptionsListLoading: boolean = false;

  get isOptionsListLoading(): boolean {
    return this._isOptionsListLoading;
  }

  private _thriveSkills: ThriveSkill[] = [];

  get thriveSkills(): ThriveSkill[] {
    return this._thriveSkills;
  }

  get defaultOptions(): ThriveSkill[] {
    return this._thriveSkills.map((thriveSkill) => ({...thriveSkill, value: false}));
  }

  private static isThriveSkillSocketData(data: SocketData): data is ThriveSkillSocketDto {
    return (
      !!(data as ThriveSkillSocketDto)?.courseId &&
      !!(data as ThriveSkillSocketDto)?.goal &&
      !!(data as ThriveSkillSocketDto)?.goalWorksheetId
    );
  }

  async loadThriveSkillsOptions(patientCourseId?: number): Promise<void> {
    this._isOptionsListLoading = true;
    const options = await this._repo.list();
    if (patientCourseId) {
      const selectedThriveSkills = await this._repo.getSelected(patientCourseId);
      selectedThriveSkills?.forEach((item) => {
        const itemIndex = options.findIndex((option) => option.id === item);
        if (itemIndex >= 0) {
          options[itemIndex].value = true;
        }
      });
    }

    this._thriveSkills = options;
    this._isOptionsListLoading = false;
  }

  async updateSkills(skillTypeIds: number[]): Promise<void> {
    await this._repo
      .updateSkills(skillTypeIds)
      .then(() => {
        this._notifier.notify({
          text: 'A new course has been added to your Roadmap based on your selections.',
          type: NotifyMessageTypeEnum.Success,
        });
        this._routing.push(`${routes.home}${routes.myRoadmap}`);
      })
      .catch(console.error);
  }

  async setWelcomeToThriveWatched(): Promise<void> {
    await this._repo
      .setWelcomeToThriveWatched()
      .then(() => this._routing.push(`${routes.home}${routes.thriveSkillSelection}`))
      .catch(console.error);
  }

  connectWss(): void {
    this._wss.subscribe('ThriveSkillCompleted', (response) => {
      if (ThriveSkillService.isThriveSkillSocketData(response)) {
        // This will automatically open modal in modal-manager component
        this._goal.reviewWorkflow = {
          courseId: response.courseId,
          title: response.goal,
          worksheetId: response.goalWorksheetId,
        };
      }
    });
  }
}

export {ThriveSkillService};
