class ProfileSettingsPutRequestDto {
  constructor(
    public receiveSms: boolean = true,
    public receivePush: boolean = true,
    public notificationLevel: number = 0,
    public timezone: string = '',
    public verificationCode: string | null = null,
  ) {}
}

export {ProfileSettingsPutRequestDto};
