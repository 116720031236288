import {datadogRum} from '@datadog/browser-rum';
import {injectable} from 'inversify';
import type {IDatadogService} from './datadog.service.interface';

declare var DATADOG_RUM_APPLICATION_ID: string;
declare var DATADOG_RUM_CLIENT_TOKEN: string;
declare var DATADOG_RUM_SERVICE: string;
declare var DATADOG_RUM_REPLAY_RATE: string;
declare var WEB_ENVIRONMENT: string;

@injectable()
class MonitoringService implements IDatadogService {
  public initializeRum(version: string) {
    const env = (process.env.REACT_APP_WEB_ENVIRONMENT?.toLowerCase() as string) || WEB_ENVIRONMENT.toLowerCase() || '';

    // Directly check the environment variable instead of hostname
    if (['dev', 'qa', 'stage', 'demo', 'prod'].includes(env)) {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID || DATADOG_RUM_APPLICATION_ID || '',
        clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || DATADOG_RUM_CLIENT_TOKEN || '',
        site: 'datadoghq.com',
        service: process.env.REACT_APP_DATADOG_RUM_SERVICE || DATADOG_RUM_SERVICE || '',
        env,
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: Number(process.env.REACT_APP_DATADOG_RUM_REPLAY_RATE || DATADOG_RUM_REPLAY_RATE || 0),
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadogRum.startSessionReplayRecording();
    }
  }
}

export {MonitoringService};
