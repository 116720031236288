import {isRadioButton} from '../../helpers';
import type {ILifeQualityItem} from './life-quality.model';
import {LifeQualityModel} from './life-quality.model';
import type {ILifeQualityFormDto} from '../form';

class LifeQualityModelFactory {
  static fromFormDto(dto: ILifeQualityFormDto): LifeQualityModel {
    const responses: Array<ILifeQualityItem> = Object.keys(dto).reduce((acc, fieldName) => {
      const dtoElement = dto[fieldName];

      const lifeQualityItem: ILifeQualityItem = {
        question: fieldName,
        value: String(dtoElement),
      };

      if (isRadioButton(dtoElement)) {
        lifeQualityItem.value = dtoElement?.id;
      }

      return [...acc, lifeQualityItem];
    }, [] as Array<ILifeQualityItem>);

    return new LifeQualityModel(responses);
  }
}

export {LifeQualityModelFactory};
