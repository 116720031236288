import type {IEncounterGetResponseDto} from '../dto';
import {EncounterModel, EncounterProviderModel} from './encounter.model';

class EncounterModelFactory {
  static fromGetResponseDto(dto: IEncounterGetResponseDto): EncounterModel {
    return new EncounterModel(
      dto.encounterId,
      new Date(dto.date),
      dto.encounterType,
      dto.encounterMethod,
      dto.status,
      new EncounterProviderModel(dto.providerId, dto.providerName, dto.providerTitle, dto.providerRole),
      dto.attachments ?? [],
      dto.addendumDates?.map((i) => new Date(i)),
      dto.isMy,
      dto.isPossibleViewSummary,
      dto.createdByFullName,
    );
  }
}

export {EncounterModelFactory};
