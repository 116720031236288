import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import styles from './profile.module.scss';
import classnames from 'classnames';
import type {AdditionalInfoModel, EmergencyContact as EmergencyContactModel} from '../../../models';
import type {IProfileSettingsFormDto} from './components';
import {
  ProfileEmergencyContact,
  ProfileEmergencyContactDto,
  ProfileHealthInsurance,
  ProfilePersonalInfo,
  ProfileSettings,
} from './components';
import type {IProfileSettingsService} from '../../../profile-settings';
import {PROFILE_SETTINGS_SERVICE} from '../../../profile-settings';
import {useInjection} from '../../../ioc';
import {DateWrapper, PageTitle, Preloader} from '../../../components';
import {FULL_FORMAT} from '../../../helpers';
import {ProfileTabsEnum} from './profile.enum';
import type {ICompanyService} from '../../../company';
import {COMPANY_SERVICE} from '../../../company';
import {useQuery} from '../../../hooks';
import type {
  HealthInsurance,
  IAccountService,
  InviteInterface,
  SearchInterface,
  UpdateMyExternalProvider,
} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import {MyProviders} from './components/my-providers';
import type {ShareInfoDecisionEnum} from '../../../enums';
import {PROFILE_VERIFICATION_SERVICE} from '../../../profile-verification';
import type {IProfileVerificationService} from '../../../profile-verification';
import {Link} from 'react-router-dom';
import {routes} from '../../../routing';

interface IServiceProps {
  profileSettingsService: IProfileSettingsService;
  profileVerificationService: IProfileVerificationService;
  company: ICompanyService;
  account: IAccountService;
}

const tabs = [
  {id: ProfileTabsEnum.Info, title: 'Personal Info'},
  {id: ProfileTabsEnum.Contact, title: 'Emergency Contact'},
  {id: ProfileTabsEnum.Insurance, title: 'Health Insurance Information'},
  {id: ProfileTabsEnum.Settings, title: 'Settings'},
  {id: ProfileTabsEnum.Providers, title: 'My Providers'},
];

const ProfileScreen: FC<IServiceProps> = ({account, profileSettingsService, profileVerificationService, company}) => {
  const {tabNumber} = useQuery<{tabNumber: number}>();
  const [activeTab, setActiveTab] = useState(tabNumber ? Number(tabNumber) : ProfileTabsEnum.Info);

  useEffect(() => {
    account.loadLanguages();
    account.loadCountries();
    profileSettingsService.load();
  }, [account, profileSettingsService]);

  useEffect(() => {
    if (
      account.id &&
      (profileSettingsService.isUserNameUpdated ||
        profileSettingsService.isEmailUpdated ||
        profileSettingsService.isEmailConfirmed)
    ) {
      account.load();
    }
  }, [
    account,
    profileSettingsService.isEmailConfirmed,
    profileSettingsService.isEmailUpdated,
    profileSettingsService.isUserNameUpdated,
  ]);

  const onPersonalInfoSubmit = useCallback(
    async (data: AdditionalInfoModel): Promise<boolean> => {
      const isPersonalInfoUpdateSuccessful = await account.updatePersonalInfo(data, true);

      return isPersonalInfoUpdateSuccessful ?? false;
    },
    [account],
  );

  const onEmergencySubmit = (data: EmergencyContactModel) => {
    account.updateEmergencyContact(data);
  };

  const onHealthInsuranceSubmit = (data: HealthInsurance) => account.updateHealthInsurance(data);

  const onProfileSettingsSubmit = useCallback(
    (dto: IProfileSettingsFormDto) => {
      profileSettingsService.sendSettings(dto).then(() => account.load());
    },
    [account, profileSettingsService],
  );

  const resetPassword = useCallback(() => {
    profileSettingsService.resetPassword();
  }, [profileSettingsService]);

  const resetPhone = useCallback(
    (code: string, phone: string) => {
      profileSettingsService.resetPhone(code, phone);
    },
    [profileSettingsService],
  );

  const getStates = useCallback(
    async (countryId: number) => {
      await account.loadStates(countryId);
    },
    [account],
  );

  const onUploadAvatar = (data: {uri: string; type: string; name: string}) =>
    account.uploadAvatar(data.uri, data.type, data.name);

  const onRemoveAvatar = () => account.removeAvatar();

  const formattedLastLoginDate = useMemo(() => {
    if (account.info?.lastLoginDate) {
      return <DateWrapper date={new Date(account.info?.lastLoginDate)} format={FULL_FORMAT} />;
    }
  }, [account.info?.lastLoginDate]);

  const myProvidersProps = {
    actions: {
      updateInfoDecision: (status: ShareInfoDecisionEnum) => account.updateInfoDecision(status),
      addProvider: (model: UpdateMyExternalProvider) => account.addExternalProvider(model),
      updateProvider: (model: UpdateMyExternalProvider) => account.updateExternalProvider(model),
      deleteProvider: (id: number) => account.deleteExternalProvider(id),
      providersSearch: (model: SearchInterface) => [
        account.searchForExternalProviders(model),
        account.searchForNPIProviders(model),
      ],
      inviteProvider: (model: InviteInterface) => account.inviteExternalProvider(model),
    },
    data: {
      isShowAllowModal: account.isShowProviderModal,
      isShowShareToggle: account.isEnabledPatientProviderInfoSharing,
      myExternalProviders: account.myExternalProviders,
      states: account.stateOptions,
      searchResult: account.externalProvidersSearchResult,
      searchNPIResult: account.npiProvidersSearchResult,
      isLoading: account.isLoading,
    },
    setActiveTab,
  };

  const requestCode = useCallback(async () => {
    await profileVerificationService.requestCode();
  }, [profileVerificationService]);

  if (activeTab !== Number(tabNumber)) {
    setActiveTab(Number(tabNumber));
  }

  return (
    <>
      <div className={styles.content}>
        <PageTitle className={styles.mainTitle}>My Profile</PageTitle>
        <p className={styles.lastLogin}>{formattedLastLoginDate && <>Last Login · {formattedLastLoginDate}</>}</p>
        <div className={styles.tabs}>
          {tabs.map((item) => (
            <Link
              to={`${routes.home}${routes.profile}?tabNumber=${item.id}`}
              title={item.title}
              className={styles.tabLink}>
              <div
                key={item.id}
                className={classnames(styles.tab, {
                  [styles.activeTab]: activeTab === item.id,
                })}>
                {item.title}
              </div>
            </Link>
          ))}
        </div>
        <div className={styles.separator} />
        {activeTab === ProfileTabsEnum.Info && (
          <ProfilePersonalInfo
            onSubmit={onPersonalInfoSubmit}
            onUploadAvatar={onUploadAvatar}
            onRemoveAvatar={onRemoveAvatar}
            patientPersonalInfo={account.info}
            patientAvatar={account.avatar}
            languageOptions={account.languageOptions}
            setActiveTab={setActiveTab}
            isEmailAddressChangeRequested={account.info?.isEmailAddressChangeRequested ?? false}
            countries={account.countryOptions}
            isLoading={account.isSaving}
            states={account.stateOptions}
            getStates={getStates}
            requestCode={requestCode}
          />
        )}
        {activeTab === ProfileTabsEnum.Contact && (
          <>
            {account.isSaving && <Preloader />}
            <ProfileEmergencyContact
              defaultValues={
                new ProfileEmergencyContactDto(
                  account.info?.emergencyContact?.firstName ?? '',
                  account.info?.emergencyContact?.lastName ?? '',
                  account.info?.emergencyContact?.email ?? '',
                  account.info?.emergencyContact?.phone ?? '',
                  account.info?.emergencyContact?.relationshipType ?? -1,
                  account.id,
                  account.info?.emergencyContact?.partnerInCareId ?? -1,
                  null,
                )
              }
              isProfileFormSaving={account.isSaving}
              onSubmit={(data) => {
                onEmergencySubmit(data);
              }}
              requestCode={requestCode}
            />
          </>
        )}
        {activeTab === ProfileTabsEnum.Insurance && (
          <>
            {account.isSaving && <Preloader />}
            <ProfileHealthInsurance
              requestCode={requestCode}
              onSubmit={onHealthInsuranceSubmit}
              patientPersonalInfo={account.info}
              isProfileFormSaving={account.isSaving}
            />
          </>
        )}
        {activeTab === ProfileTabsEnum.Settings &&
          profileSettingsService.currentTimeZone &&
          profileSettingsService.notificationType &&
          account.info && (
            <ProfileSettings
              currentTimeZone={profileSettingsService.currentTimeZone}
              notificationType={profileSettingsService.notificationType}
              areSmsReceived={profileSettingsService.areSmsReceived}
              arePushReceived={profileSettingsService.arePushReceived}
              timeZones={profileSettingsService.timeZones}
              isLoading={profileSettingsService.isLoading}
              onSubmit={onProfileSettingsSubmit}
              onResetPassword={resetPassword}
              onResetPhone={resetPhone}
              email={account.info.email}
              userName={account.info.userName}
              requestCode={requestCode}
              patientPersonalInfo={account.info}
              onPersonalInfoSubmit={onPersonalInfoSubmit}
            />
          )}
        {activeTab === ProfileTabsEnum.Providers && <MyProviders {...myProvidersProps} />}
      </div>
    </>
  );
};

const ProfileScreenObserver = observer(ProfileScreen);
const ProfileScreenInjected: FC = () => (
  <ProfileScreenObserver
    profileSettingsService={useInjection(PROFILE_SETTINGS_SERVICE)}
    profileVerificationService={useInjection(PROFILE_VERIFICATION_SERVICE)}
    company={useInjection(COMPANY_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
  />
);

export {ProfileScreenInjected as ProfileScreen};
