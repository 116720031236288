const YEAR_DEFAULT_COUNT = 12;

const getIntervalStartAndEndYears = (date: Date, yearRangeLength: number = YEAR_DEFAULT_COUNT): Array<number> => {
  const dateYear = date.getFullYear();
  let count = 0;

  while (!Number.isInteger((dateYear - count) / yearRangeLength)) {
    count++;
  }

  const rangeStart = dateYear - count + 1;

  return [rangeStart, rangeStart + (yearRangeLength - 1)];
};

export {getIntervalStartAndEndYears};
