import {HealthMonitorGraphTypes} from '../../../model';

class HealthMonitorPostRequestDto {
  constructor(
    public startDate: string | null = null,
    public endDate: string | null = null,
    public type: HealthMonitorGraphTypes = HealthMonitorGraphTypes.LabResultsCRP,
    public patientId: number = -1,
  ) {}
}

export {HealthMonitorPostRequestDto};
