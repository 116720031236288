import classNames from 'classnames';
import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';
import type {IAlertIndicatorService} from '../../alert-indicator';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';
import type {IAppService} from '../../app/service';
import {APP_SERVICE} from '../../app/service';
import type {IAssessmentReportService} from '../../assessment-report/service';
import {ASSESSMENT_REPORT_SERVICE} from '../../assessment-report/service';
import {LogoutIcon, SettingsIcon, UserIcon} from '../../assets/icons';
import type {ICompanyService} from '../../company';
import {COMPANY_SERVICE} from '../../company';
import {Overlay} from '../../components/overlay';
import {useInjection} from '../../ioc';
import type {IMessageService} from '../../message';
import {MESSAGE_SERVICE} from '../../message';
import {routes} from '../../routing';
import {ProfileTabsEnum} from '../../screens/private/profile/profile.enum';
import type {IGoalService, ITaskService} from '../../task/service';
import {GOAL_SERVICE, TASK_SERVICE} from '../../task/service';
import type {IAuthService} from '../../utils/auth';
import {AUTH_SERVICE} from '../../utils/auth';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import type {LocationState} from '../../utils/routing/service';
import type {ILayoutsService} from '../service';
import {LAYOUTS_SERVICE} from '../service';
import type {INavigationItem} from './components';
import {AdditionalNavigation, EmergencyCardModal, Footer, LeftNavPanel, RightPanel, TopNavPanel} from './components';
import {ModalManager} from './components/modal-manager';
import styles from './private-layout.module.scss';

interface IServices {
  app: IAppService;
  auth: IAuthService;
  alertIndicator: IAlertIndicatorService;
  company: ICompanyService;
  assessmentReport: IAssessmentReportService;
  task: ITaskService;
  goalService: IGoalService;
  routing: IRoutingService;
  messages: IMessageService;
  layouts: ILayoutsService;
  account: IAccountService;
}

const PrivateLayout: FC<IServices> = (props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isRightPanelOpen, setRightPanelOpen] = useState(false);
  const [visibleEmergencyCardModal, setVisibleEmergencyCardModal] = useState(false);
  const location = useLocation<LocationState>();

  useEffect(() => {
    props.layouts.toggleEmergencyModal = () => {
      setVisibleEmergencyCardModal((prevState) => !prevState);
    };
  }, [props.layouts]);

  useEffect(() => {
    if (props.account.id) {
      Promise.all([
        props.app.loadAfterOnboarding(props.account.id),
        props.account.loadTrellusTeam(),
        props.alertIndicator.load(),
      ]);
    } else {
      props.account.load();
    }
  }, [props.alertIndicator, props.app, props.routing, props.messages, props.account]);

  useEffect(() => {
    if (props.account.id && props.account.team) {
      props.messages.loadThreadsPreview(props.account.team, props.account.id);

      return props.messages.subscribe(props.account.id);
    }
  }, [props.account.id, props.account.team, props.messages]);

  useEffect(() => {
    props.layouts.checkLocation(location.pathname, location?.state?.forceRouting);
  }, [location, props.layouts]);

  const navItems: Array<INavigationItem> = useMemo(
    () => [
      {
        icon: UserIcon,
        title: 'My profile',
        link: `${routes.home}${routes.profile}?tabNumber=${ProfileTabsEnum.Info}`,
      },
      {
        icon: SettingsIcon,
        title: 'Settings',
        link: `${routes.home}${routes.profile}?tabNumber=${ProfileTabsEnum.Settings}`,
      },
      {
        icon: LogoutIcon,
        title: 'Log out',
        callBack: () => props.auth.logout(),
      },
    ],
    [props.auth],
  );
  const toggleMenu = useCallback(() => setMenuOpen((open) => !open), []);
  const toggleRightPanel = useCallback(() => setRightPanelOpen((open) => !open), []);
  const closeMenu = useCallback(() => setMenuOpen(false), []);
  const closeRightPanel = useCallback(() => setRightPanelOpen(false), []);
  const openEmergencyCardModal = useCallback(() => setVisibleEmergencyCardModal(true), []);

  return (
    <div className={styles.root}>
      <TopNavPanel
        className={styles.header}
        appName={'Trellus'}
        navItems={navItems}
        user={
          props.account.info
            ? {
                name: props.account.info.firstName,
                avatar: props.account.avatar ? `data:image/png;base64,${props.account.avatar}` : '',
              }
            : undefined
        }
        helpLink={props.account.info ? props.company?.companyInfo.helpLink : undefined}
        onMenuToggle={toggleMenu}
        onRightPanelOpen={toggleRightPanel}
        isRightPanelOpen={isRightPanelOpen}
        isRightSidebarAvailable={true}
        alertIndicator={props.alertIndicator}
      />
      <LeftNavPanel
        className={classNames(styles.leftSidebar, {
          [styles.mobileMenuOpen]: isMenuOpen,
          [styles.desktopMenuOpen]: isMenuOpen,
        })}
        open={isMenuOpen}
        onClose={closeMenu}
        showAssessmentReportPageLink={props.assessmentReport.isDataExists}
        glossaryOfMedicationMonitoringLink={props.company?.companyInfo.glossaryOfMedicationMonitoringLink}
        vaccinationReferenceGuideLink={props.company?.companyInfo.vaccinationReferenceGuideLink}
        alertIndicator={props.alertIndicator}
        onOpenEmergencyCardModal={openEmergencyCardModal}
      />
      <main className={styles.main}>{props.children}</main>
      <RightPanel
        className={classNames(styles.rightSidebar, {
          [styles.mobileRightPanelOpen]: isRightPanelOpen,
          [styles.desktopRightPanelOpen]: isRightPanelOpen,
        })}
        onClose={closeRightPanel}
      />
      <AdditionalNavigation />
      <Footer
        className={styles.footer}
        contactEmail={props.company?.companyInfo.contactEmail}
        contactPhone={props.company?.companyInfo.contactPhone}
        facebookLink={props.company?.companyInfo.facebookLink}
        twitterLink={props.company?.companyInfo.twitterLink}
        instagramLink={props.company?.companyInfo.instagramLink}
        linkedInLink={props.company?.companyInfo.linkedInLink}
        tikTokLink={props.company?.companyInfo.tikTokLink}
        youTubeLink={props.company?.companyInfo.youTubeLink}
        copyRight={props.company?.companyInfo.copyRight}
        appVersion={props.company?.companyInfo.informationalVersion}
        disclaimer={props.company?.companyInfo.disclaimer}
      />
      {(isMenuOpen || isRightPanelOpen) && <Overlay className={styles.overlay} onClose={closeMenu} />}
      <EmergencyCardModal
        title="My TrellusConnect Card"
        onClose={() => {
          setVisibleEmergencyCardModal(false);
        }}
        visible={visibleEmergencyCardModal}
      />

      <ModalManager />
    </div>
  );
};

const ObservablePrivateLayout = observer(PrivateLayout);
const InjectedPrivateLayout: FC = (props) => (
  <ObservablePrivateLayout
    {...props}
    app={useInjection(APP_SERVICE)}
    auth={useInjection(AUTH_SERVICE)}
    alertIndicator={useInjection(ALERT_INDICATOR_SERVICE)}
    company={useInjection(COMPANY_SERVICE)}
    assessmentReport={useInjection(ASSESSMENT_REPORT_SERVICE)}
    task={useInjection(TASK_SERVICE)}
    goalService={useInjection(GOAL_SERVICE)}
    routing={useInjection(ROUTING_SERVICE)}
    messages={useInjection(MESSAGE_SERVICE)}
    layouts={useInjection(LAYOUTS_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
  />
);

export {InjectedPrivateLayout, ObservablePrivateLayout as PrivateLayout};
