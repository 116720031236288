import type {FC} from 'react';
import React from 'react';
import styles from './medications-card-wrapper.module.scss';
import classNames from 'classnames';

interface IMedicationsFormWrapperProps {
  label?: string;
  className?: string;
  isError?: boolean;
}

const MedicationsCardWrapper: FC<IMedicationsFormWrapperProps> = ({label, className, isError, children}) => (
  <div className={classNames(styles.root, className)}>
    <div className={classNames(styles.label, isError && styles.errorLabel)}>{label}</div>
    {children}
  </div>
);

export {MedicationsCardWrapper};
