import type {FC} from 'react';
import React from 'react';
import {Spinner} from '../spinner';
import styles from './page-loader.module.scss';
import classNames from 'classnames';

interface IPageLoaderProps {
  removeMargin?: boolean;
}

const PageLoader: FC<IPageLoaderProps> = ({removeMargin}) => (
  <div className={classNames(styles.root, {[styles.removeMargin]: removeMargin})}>
    <Spinner />
    <span className={styles.text}>Loading, please wait</span>
  </div>
);

export {PageLoader};
