import type {FC, KeyboardEventHandler} from 'react';
import React, {useCallback} from 'react';
import styles from './radio.module.scss';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

interface IRadioProps {
  id: string;
  name: string;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  bold?: boolean;
  vertical?: boolean;
  subLabel?: string;
  classNameSubLabel?: string;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const Radio: FC<IRadioProps> = ({
  id,
  name,
  value,
  label,
  vertical,
  bold,
  onChange,
  subLabel,
  classNameSubLabel,
  ariaLabelTitle,
  ariaLabelError,
}) => {
  const change = useCallback(() => onChange(!value), [onChange, value]);

  const onEnterPress: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        return change();
      }
    },
    [change],
  );

  const title = ariaLabelTitle ?? name;
  const error = !!ariaLabelError ? `${ariaLabelError}.` : '';

  return (
    <>
      <input
        data-testid={id}
        className={styles.input}
        type="radio"
        id={id}
        name={name}
        checked={value}
        onChange={change}
        tabIndex={-1}
      />
      <label
        aria-label={`${error} ${title}. Option: ${label}`}
        aria-checked={value}
        role="radio"
        className={classNames(styles.label, {[styles.verticalLabel]: vertical, [styles.bold]: bold})}
        tabIndex={0}
        onKeyPress={onEnterPress}
        htmlFor={id}>
        {label}
      </label>
      {subLabel && (
        <span className={classNames([styles.subLabel, classNameSubLabel])}>{ReactHtmlParser(subLabel)}</span>
      )}
    </>
  );
};

export {Radio};
export type {IRadioProps};
