import type {FC} from 'react';
import React, {useMemo} from 'react';
import type {BodyMassIndexModel} from '../../../../model';
import {BodyMassIndexUnitEnum} from '../../../../model';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../../../../helpers';
import styles from './body-mass-index-list-item.module.scss';

interface IBodyMassIndexListItemProps {
  record: BodyMassIndexModel;
  selectedUnit: BodyMassIndexUnitEnum;
}

const BodyMassIndexListItem: FC<IBodyMassIndexListItemProps> = ({record, selectedUnit}) => {
  const items = useMemo(() => {
    let weight;

    switch (selectedUnit) {
      case BodyMassIndexUnitEnum.FtAndLb:
        weight = record.weightLb;
        break;
      case BodyMassIndexUnitEnum.CmAndKg:
        weight = record.weightKg;
        break;
      default:
        weight = record.weightLb;
        break;
    }

    return [format(record.date, DEFAULT_FORMAT), weight];
  }, [record.date, record.weightKg, record.weightLb, selectedUnit]);

  return (
    <tr>
      {items.map((i, index) => (
        <td key={index} className={styles.tableCell}>
          {i}
        </td>
      ))}
    </tr>
  );
};

export {BodyMassIndexListItem};
