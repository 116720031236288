class LabResultsCreateRequestDto {
  constructor(
    public patientId: number | null = null,
    public labDate: string | null = null,
    public labTestType: number | null = null,
    public comparisonOperatorType: number | null = null,
    public result: number | null = null,
    public resultType: number | null = null,
    public unitsType: number | null = null,
  ) {}
}

export {LabResultsCreateRequestDto};
