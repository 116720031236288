import type {FC} from 'react';
import React from 'react';
import styles from './social.module.scss';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TikTokIcon,
  TwitterIcon,
  YouTubeIcon,
} from '../../../../../../assets/icons';

interface IProps {
  facebookLink: string;
  twitterLink: string;
  instagramLink: string;
  linkedInLink: string;
  tikTokLink: string;
  youTubeLink: string;
  copyRight: string;
}

const Social: FC<IProps> = (props) => (
  <ul className={styles.socialList}>
    <li className={styles.socialListCaption}>Social media</li>
    <li className={styles.socialListItem}>
      <a href={props.twitterLink} className={styles.socialListLink} target="_blank" rel="noopener noreferrer">
        <TwitterIcon />
      </a>
      <a href={props.facebookLink} className={styles.socialListLink} target="_blank" rel="noopener noreferrer">
        <FacebookIcon />
      </a>
      <a href={props.linkedInLink} className={styles.socialListLink} target="_blank" rel="noopener noreferrer">
        <LinkedInIcon />
      </a>
      <a href={props.tikTokLink} className={styles.socialListLink} target="_blank" rel="noopener noreferrer">
        <TikTokIcon />
      </a>
      <a href={props.instagramLink} className={styles.socialListLink} target="_blank" rel="noopener noreferrer">
        <InstagramIcon />
      </a>
      <a href={props.youTubeLink} className={styles.socialListLink} target="_blank" rel="noopener noreferrer">
        <YouTubeIcon />
      </a>
    </li>
    <li className={styles.socialListItem}>{props.copyRight}</li>
  </ul>
);

export {Social};
