import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CloseIcon} from '../../../assets/icons';
import type {Control} from 'react-hook-form/dist/types/form';
import {Controller, useFieldArray} from 'react-hook-form';
import {AddAnotherButton, DatePicker} from '../../../components';
import styles from './survey-dynamic-section.module.scss';
import type {ISurveyElementResponse, ISurveyFormDto, ISurveySpecElement} from '../../index';

const DynamicDate: FC<{
  name: string;
  control: Control;
  index: number;
  onRemove: () => void;
  disAllowRemove?: boolean;
}> = ({control, name, onRemove, disAllowRemove}) => (
  <div className={styles.dateContainer}>
    <Controller
      name={name}
      control={control}
      rules={
        {
          // TODO valueAsDate: true,
        }
      }
      render={({value, name, onChange}) => (
        <DatePicker
          popperClassName={styles.reactDatepickerPopper}
          name={name}
          value={value}
          onChange={onChange}
          max={new Date()}
        />
      )}
    />
    {!disAllowRemove && <CloseIcon className={styles.closeIcon} onClick={onRemove} />}
  </div>
);

interface ISurveyDynamicSectionProps {
  element: ISurveySpecElement;
  elementValues: Array<ISurveyElementResponse>;
  control: Control;
}

const SurveyDynamicSection: FC<ISurveyDynamicSectionProps> = (props) => {
  const maxSectionCount = useMemo(
    () => props.element.metadata?.maxSubElementCount ?? 0,
    [props.element.metadata?.maxSubElementCount],
  );
  const {fields, append, remove} = useFieldArray<ISurveyFormDto>({
    control: props.control,
    name: props.element.subElements[0].name,
  });

  const [defaultValues] = useState<ISurveyFormDto>(
    props.element.subElements?.reduce(
      (obj, element) => ({
        ...obj,
        [element.name]: '',
      }),
      {},
    ) ?? {},
  );

  const dateAdd = useCallback(() => {
    append(defaultValues);
  }, [append, defaultValues]);

  const dateRemove = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  useEffect(() => {
    dateAdd();
  }, [dateAdd]);

  const dates = fields.map((field, index) => (
    <DynamicDate
      control={props.control}
      name={`${props.element.subElements[0].name}[${index}].${field.id}`}
      index={index}
      key={field.id}
      onRemove={() => dateRemove(index)}
      disAllowRemove={index === 0 || fields.length !== index + 1}
    />
  ));

  const showAddButton: boolean = useMemo(() => fields.length < maxSectionCount, [fields.length, maxSectionCount]);

  return (
    <div className={styles.root}>
      {dates}
      {showAddButton && <AddAnotherButton onClick={dateAdd} />}
    </div>
  );
};

export {SurveyDynamicSection};
export type {ISurveyDynamicSectionProps};
