import type {ChangeEvent, FC} from 'react';
import React, {useCallback, useMemo} from 'react';
import styles from './range-bar.module.scss';
import classNames from 'classnames';
import {generateLabels} from './helpers';
import {RangeBarLabelsType, RangeBarStylingType} from './enum';

interface IRangeBarProps {
  name: string;
  min?: number;
  max?: number;
  value?: number;
  values?: number[];
  onChange?: (value: number) => void;
  startLabel?: string;
  endLabel?: string;
  labels?: string[];
  step?: number;
  displayingType?: RangeBarLabelsType;
  stylingType?: RangeBarStylingType;
  classNameInput?: string;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const RangeBar: FC<IRangeBarProps> = (props) => {
  const max: number = useMemo(() => props.max ?? 10, [props.max]);
  const min: number = useMemo(() => props.min ?? 1, [props.min]);
  const value: number = useMemo(() => Number(props.value), [props.value]);
  const values: number[] = useMemo(
    () => props.values ?? new Array(max - min + 1).fill(min).map((i, index) => i + index),
    [props.values, max, min],
  );

  const indexValue = props.value && values.indexOf(Number(props.value));

  const labels = generateLabels(values, props.labels, props.startLabel, props.endLabel);

  const onChangeValue = useCallback(
    (value: number) => {
      props.onChange && props.onChange(value);
    },
    [props],
  );

  const currentProgress = useMemo(
    () =>
      props.stylingType === RangeBarStylingType.Slicing && (
        <div
          className={styles.currentProgress}
          style={{width: `calc(${indexValue ? (indexValue * 100) / (max - min) : 0}%)`}}
        />
      ),
    [indexValue, max, min, props.stylingType],
  );

  const items = useMemo(
    () =>
      values.map((value, index) => (
        <span key={value} className={styles.item}>
          {props.stylingType === RangeBarStylingType.Slicing && (
            <div className={styles.slicedMark} onClick={() => onChangeValue(value)} />
          )}
          {labels ? <span dangerouslySetInnerHTML={{__html: labels[index]}} /> : value}
        </span>
      )),
    [labels, onChangeValue, props.stylingType, values],
  );

  const title = props.ariaLabelTitle ?? props.name;
  const error = !!props.ariaLabelError ? `${props.ariaLabelError}.` : '';

  const isRtlDirection = values[0] > values[values.length - 1];

  return (
    <div
      className={classNames(styles.root, {
        [styles.stringType]: props.displayingType === RangeBarLabelsType.Strings,
      })}>
      <input
        className={classNames(styles.input, props.classNameInput, isRtlDirection && styles.rtl)}
        name={props.name}
        aria-label={`${error} ${title}`}
        type={'range'}
        max={max}
        min={min}
        value={value}
        step={props.step ?? 1}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeValue(Number(e.target.value))}
        data-testid={`range-bar-${props.name}`}
        autoComplete="off"
      />
      {currentProgress}
      <div className={styles.items}>{items}</div>
    </div>
  );
};

export {RangeBar};
export type {IRangeBarProps};
