import type {IHealthMonitorNavigationGetResponseDto} from '../repository/dto/health-monitor-navigation-get-response.dto';
import {HealthMonitorNavigationModel} from './health-monitor-navigation.model';

class HealthMonitorNavigationModelFactory {
  static fromPostResponseDto(dto: IHealthMonitorNavigationGetResponseDto): HealthMonitorNavigationModel {
    return new HealthMonitorNavigationModel(
      dto.value,
      dto.displayName,
      dto.displayOrder,
      dto.selectedByDefault,
      dto.periodFormatChange,
      dto.subElements.map((element) => HealthMonitorNavigationModelFactory.fromPostResponseDto(element)),
    );
  }
}

export {HealthMonitorNavigationModelFactory};
