import type {IDebounce} from './debounce.interface';

type DebounceTimerId = number;
/**
 * milliseconds
 */
type DebounceTimeout = number;
type DebounceCallback = Function;

class Debounce implements IDebounce {
  private _timerId: DebounceTimerId | null;
  private readonly _timeout: DebounceTimeout;
  private readonly _callback: DebounceCallback;

  constructor(timeout: DebounceTimeout, callback: DebounceCallback) {
    this._timerId = null;
    this._timeout = timeout;
    this._callback = callback;
  }

  start(): void {
    if (this._timerId === null) {
      this._timerId = setTimeout(this._callback, this._timeout);
    }
  }

  stop(): void {
    if (this._timerId !== null) {
      clearTimeout(this._timerId);
      this._timerId = null;
    }
  }

  reset(): void {
    this.stop();

    this.start();
  }
}

export {Debounce};
export type {DebounceCallback, DebounceTimeout};
