import type {FC} from 'react';
import React from 'react';
import {Spinner} from '../spinner';
import styles from './preloader.module.scss';

interface IProps {
  isSpinnerShown?: boolean;
}

const Preloader: FC<IProps> = ({isSpinnerShown}) =>
  <div className={styles.overlay}>
    {isSpinnerShown && (
      <Spinner/>
    )}
  </div>;

export {Preloader};
