import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  AssessmentItemWrapper,
  Button,
  Card,
  CollapseDescription,
  Error,
  PageLoader,
  RangeBar,
  RangeBarLabelsType,
  RangeBarStylingType,
  SecondaryModal,
  SurveyYesNoComponent,
} from '../../../components';
import styles from './resilience-assessment-survey.module.scss';
import type {IResilienceAssessmentService} from '../../service';
import {RESILIENCE_ASSESSMENT_SERVICE} from '../../service';
import {observer} from 'mobx-react';
import {useInjection} from '../../../ioc';
import {REQUIRED_FIELD_ERROR, scrollToError} from '../../../helpers';
import {useFormConfirmationDialog} from '../../../hooks';
import {ResilienceAssessmentQuestionType} from '../../model';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';

type IResilienceAssessmentSurveyDto = Record<string, string>;

interface IServiceProps {
  resilienceAssessmentService: IResilienceAssessmentService;
  account: IAccountService;
}

const ResilienceAssessmentSurvey: FC<IServiceProps> = ({resilienceAssessmentService, account}) => {
  const patientId = account.id;

  const [visibleCancelModal, setVisibleCancelModal] = useState(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const {getConfirmation} = useFormConfirmationDialog();

  useEffect(() => {
    resilienceAssessmentService.loadAssessments();
  }, [resilienceAssessmentService]);

  const defaultValues = useMemo(
    () =>
      resilienceAssessmentService.listQuestions?.length
        ? resilienceAssessmentService.listQuestions.reduce(
            (acc, question) => ({
              ...acc,
              [question.name]: question.defaultValue,
            }),
            {},
          )
        : {},
    [resilienceAssessmentService.listQuestions],
  );

  const defaultLoadedValues = useMemo(
    () =>
      resilienceAssessmentService?.lastFilledAssessment?.items?.length
        ? resilienceAssessmentService?.lastFilledAssessment?.items.reduce(
            (acc, item) => ({
              ...acc,
              [item.question]: item.value,
            }),
            defaultValues,
          )
        : defaultValues,
    [defaultValues, resilienceAssessmentService?.lastFilledAssessment?.items],
  );

  const {reset, handleSubmit, control, errors} = useForm<IResilienceAssessmentSurveyDto>({
    defaultValues: defaultLoadedValues,
  });

  const onSurveySubmit = useCallback(
    async (data: IResilienceAssessmentSurveyDto) => {
      setSubmitDisabled(true);
      const isConfirmed = await getConfirmation({});

      if (isConfirmed) {
        patientId && (await resilienceAssessmentService.createAssessment(data, patientId));
      } else {
        setSubmitDisabled(false);
      }
    },
    [getConfirmation, patientId, resilienceAssessmentService],
  );

  const onSurveyCancel = useCallback(() => {
    setVisibleCancelModal(true);
  }, [setVisibleCancelModal]);

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  useEffect(() => reset(defaultLoadedValues), [defaultLoadedValues, reset]);

  const resilienceAssessmentsCollapseDescription = useMemo(
    () =>
      resilienceAssessmentService.lastFilledAssessment?.lastUpdated &&
      !resilienceAssessmentService.isReadOnly && (
        <CollapseDescription>
          To help your Trellus Team in preparing for your Initial Visit, please answer these questions so we can
          understand where your Strengths lie, and where you may need a little extra support.
        </CollapseDescription>
      ),
    [resilienceAssessmentService.isReadOnly, resilienceAssessmentService.lastFilledAssessment?.lastUpdated],
  );

  const questions = resilienceAssessmentService.listQuestions?.map((question) => {
    switch (question.type) {
      case ResilienceAssessmentQuestionType.Range:
        return (
          <AssessmentItemWrapper title={question.displayName} key={question.name} swapColumnsOnMobile>
            <Controller
              control={control}
              name={question.name}
              render={({name, onChange, value}) => {
                const stringValue = value && value.toString();
                const countSlicing = question.options.length;
                const startLabel = question.options[0].displayName;
                const endLabel = question.options[countSlicing - 1].displayName;
                const minValue = Number(question.minValue);
                const maxValue = Number(question.maxValue);

                return !resilienceAssessmentService.isReadOnly ? (
                  <RangeBar
                    name={name}
                    value={stringValue}
                    onChange={(value) => onChange(value.toString())}
                    min={minValue}
                    max={maxValue}
                    stylingType={RangeBarStylingType.Slicing}
                    displayingType={RangeBarLabelsType.Strings}
                    labels={question.options.map((o) => o.displayName)}
                    startLabel={startLabel}
                    endLabel={endLabel}
                    values={question.options.map((o) => Number(o.value)) ?? []}
                  />
                ) : (
                  stringValue
                );
              }}
            />
          </AssessmentItemWrapper>
        );
      case ResilienceAssessmentQuestionType.YesNo:
        return (
          <AssessmentItemWrapper title={question.displayName} key={question.name}>
            <Controller
              control={control}
              name={question.name}
              rules={{required: question.required && REQUIRED_FIELD_ERROR}}
              render={({name, onChange, value}) =>
                !resilienceAssessmentService.isReadOnly ? (
                  <>
                    <SurveyYesNoComponent name={name} value={value} onChange={onChange} isError={!!errors[name]} />
                    {!!errors[name] && <Error errorMessage={errors[name]?.message ?? ''} />}
                  </>
                ) : (
                  <>{value === 'true' ? 'Yes' : 'No'}</>
                )
              }
            />
          </AssessmentItemWrapper>
        );
      default:
        return null;
    }
  });

  const onModalSubmit = useCallback(() => resilienceAssessmentService.navigateBack(), [resilienceAssessmentService]);

  return (
    <>
      {resilienceAssessmentsCollapseDescription}
      {!resilienceAssessmentService.isLoading && (
        <>
          <form onSubmit={handleSubmit(onSurveySubmit)}>
            <Card>{questions}</Card>
            {!resilienceAssessmentService.isReadOnly && (
              <div className={styles.buttonContainer}>
                <Button block className={styles.button} onClick={onSurveyCancel} testid={'survey-button-cancel'}>
                  Cancel
                </Button>
                <Button
                  block
                  type={'submit'}
                  className={styles.button}
                  disabled={isSubmitDisabled}
                  testid={'survey-button-submit'}>
                  Submit
                </Button>
              </div>
            )}
          </form>
          {visibleCancelModal && (
            <SecondaryModal
              text={'All answers will be lost. Do you want to cancel the assessment?'}
              onClose={() => setVisibleCancelModal(false)}
              onSubmit={onModalSubmit}
            />
          )}
        </>
      )}
      {resilienceAssessmentService?.isLoading && <PageLoader />}
    </>
  );
};

const ResilienceAssessmentSurveyObserver = observer(ResilienceAssessmentSurvey);
const ResilienceAssessmentSurveyInjected: FC = (props) => (
  <ResilienceAssessmentSurveyObserver
    resilienceAssessmentService={useInjection(RESILIENCE_ASSESSMENT_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
    {...props}
  />
);

export {ResilienceAssessmentSurveyInjected as ResilienceAssessmentSurvey};
