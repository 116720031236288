import {ContainerModule} from 'inversify';
import type {IVaccinationRepository} from '../repository';
import {VACCINATION_REPOSITORY, VaccinationRepository} from '../repository';
import type {IVaccinationService} from '../service';
import {VACCINATION_SERVICE, VaccinationService} from '../service';
import {HTTP_SERVICE} from '../../utils/http';

const VaccinationModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IVaccinationRepository>(VACCINATION_REPOSITORY).to(VaccinationRepository).inSingletonScope();

  bind<IVaccinationService>(VACCINATION_SERVICE).to(VaccinationService).inSingletonScope();
});

export {VaccinationModule};
