import {ContainerModule} from 'inversify';
import type {IPreScreeningService} from '../service';
import {PRE_SCREENING_SERVICE, PreScreeningService} from '../service';
import {HTTP_SERVICE} from '../../utils/http';
import type {IPreScreeningRepository} from '../repository';
import {PRE_SCREENING_REPOSITORY, PreScreeningRepository} from '../repository';

const PreScreeningModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IPreScreeningRepository>(PRE_SCREENING_REPOSITORY).to(PreScreeningRepository).inSingletonScope();

  bind<IPreScreeningService>(PRE_SCREENING_SERVICE).to(PreScreeningService).inSingletonScope();
});

export {PreScreeningModule};
