import type {FC} from 'react';
import React, {useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import type {ISurveyRadioButtonOption} from '../../../components';
import {Error, Input, InputType, SingleDropdown, SingleDropdownType, SurveyRadioButtonGroup} from '../../../components';
import {REQUIRED_FIELD_ERROR} from '../../../helpers';
import type {MedicationsReasonsDiscontinueModel} from '../../model';
import styles from './medications-reason-element.module.scss';
import {MedicationsReasonElementType} from './medications-reason-element-type.enum';
import type {IOption} from '../../../types';
import classnames from 'classnames';

interface IMedicationsSurveyElementProps {
  elementType: MedicationsReasonElementType;
  reasons: ReadonlyArray<MedicationsReasonsDiscontinueModel>;
  isSingleElement?: boolean;
}

type IReasonDropdownOption = IOption<string | number>;

const OTHER_REASON_LENGTH = 150;

const MedicationsReasonElement: FC<IMedicationsSurveyElementProps> = ({
  elementType,
  reasons,
  isSingleElement = true,
}) => {
  const [reasonSelect, setReasonSelect] = useState<string>('');

  const {control, formState} = useFormContext();

  const reasonElement = (value: number, name: string, onChange: (...event: any[]) => void) => {
    let options: ISurveyRadioButtonOption[] | IOption<string | number>[];

    switch (elementType) {
      case MedicationsReasonElementType.RadioButton: {
        options = reasons
          .filter((reason) => !reason.isObsolete)
          .map((reason) => ({
            id: reason.value,
            text: reason.description,
          }));

        const currentValue = options.find((option: ISurveyRadioButtonOption) => option.id === value);

        const change = (value: ISurveyRadioButtonOption) => {
          onChange(value.id);
          setReasonSelect(value.text);
        };

        return (
          <SurveyRadioButtonGroup key={value} name={name} value={currentValue} options={options} onChange={change} />
        );
      }
      case MedicationsReasonElementType.Dropdown: {
        options = reasons.map((reason) => ({
          value: reason.value,
          displayName: reason.description,
        }));

        const currentValue = options.find((option: IReasonDropdownOption) => option.value === value);

        const change = (value: IReasonDropdownOption) => {
          onChange(value.value);
          setReasonSelect(value.displayName);
        };

        return (
          <SingleDropdown
            name={name}
            ariaLabelTitle='Reason discontinued'
            ariaLabelError={formState.errors[name]?.message}
            options={options}
            value={currentValue}
            isSearchEnabled
            type={SingleDropdownType.Common}
            onChange={change}
            isError={formState.errors[name]?.message}
            className={classnames(styles.reasonsDropdown, isSingleElement ? styles.halfWidth : styles.fullWidth)}
          />
        );
      }
      default:
        return <></>;
    }
  };

  return (
    <>
      {}
      <Controller
        name={'reason'}
        control={control}
        rules={{required: REQUIRED_FIELD_ERROR}}
        render={({name, value, onChange}) => reasonElement(value, name, onChange)}
      />
      {!!formState.errors['reason']?.message && (
        <div className={styles.error}>
          <Error errorMessage={formState.errors['reason'].message} />
        </div>
      )}
      {reasonSelect === 'Other' && (
        <Controller
          name={'reasonOther'}
          control={control}
          rules={{required: REQUIRED_FIELD_ERROR}}
          render={({value, onChange, name}) => (
            <Input
              className={classnames(styles.input, {
                [styles.inputUnderDropdown]: elementType === MedicationsReasonElementType.Dropdown,
              })}
              name={name}
              ariaLabelTitle='Reason other'
              ariaLabelError={formState.errors[name]?.message}
              value={value || ''}
              onValueChanged={onChange}
              type={InputType.Default}
              maxLength={OTHER_REASON_LENGTH}
              isValid={!formState.errors[name]?.message}
            />
          )}
        />
      )}
      {!!formState.errors['reasonOther']?.message && (
        <div className={styles.error}>
          <Error errorMessage={formState.errors['reasonOther'].message} />
        </div>
      )}
    </>
  );
};

export {MedicationsReasonElement};
