import type {FC} from 'react';
import React, {useEffect, useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {parseFormulaToShowQuestion, REQUIRED_FIELD_ERROR, scrollToError} from '../../../helpers';
import {AssessmentItemWrapper, AssessmentRangeBar, Error, SurveyRadioButtonGroup} from '../../../components';
import {AssessmentQuestionType} from '../../../assessment';
import type {WorkProductivityQuestionModel} from '../../model';
import {SurveyNumericElement} from '../../../survey/components/numeric-element';

interface IWorkProductivityQuestionsProps {
  listQuestions: WorkProductivityQuestionModel[];
}

const WorkProductivityQuestions: FC<IWorkProductivityQuestionsProps> = ({listQuestions}) => {
  const {control, watch, errors} = useFormContext();

  const formValues = watch();

  const isShowQuestion = useMemo(
    () => (question: WorkProductivityQuestionModel) =>
      question?.visibleIf ? parseFormulaToShowQuestion(question.visibleIf, formValues) : true,
    [formValues],
  );

  const questions = useMemo(
    () =>
      listQuestions.map((question) => {
        switch (question.type) {
          case AssessmentQuestionType.RadioButton:
            const radioButtonOptions = question.options.map((option) => ({id: option.value, text: option.displayName}));

            return (
              isShowQuestion(question) && (
                <AssessmentItemWrapper title={question.displayName} key={question.name}>
                  <Controller
                    control={control}
                    name={question.name}
                    render={({name, onChange, value}) => (
                      <>
                        <SurveyRadioButtonGroup
                          name={name}
                          value={value}
                          onChange={onChange}
                          options={radioButtonOptions}
                          isError={!!errors[name]}
                        />
                        {!!errors[name] && <Error errorMessage={errors[name]?.message ?? ''} />}
                      </>
                    )}
                  />
                </AssessmentItemWrapper>
              )
            );
          case AssessmentQuestionType.Number:
            return (
              isShowQuestion(question) && (
                <AssessmentItemWrapper title={question.displayName} key={question.name}>
                  <Controller
                    control={control}
                    name={question.name}
                    rules={{required: question.required && REQUIRED_FIELD_ERROR}}
                    render={({name, onChange, value}) => (
                      <>
                        <SurveyNumericElement
                          name={name}
                          value={value}
                          onChange={onChange}
                          minValue={Number(question.minValue)}
                          maxValue={Number(question.maxValue)}
                          isInvalid={errors[name]?.message}
                          errorMessage={errors[name]?.message}
                        />
                      </>
                    )}
                  />
                </AssessmentItemWrapper>
              )
            );
          case AssessmentQuestionType.Range:
            return (
              isShowQuestion(question) && (
                <AssessmentItemWrapper title={question.displayName} key={question.name}>
                  <Controller
                    control={control}
                    name={question.name}
                    render={({name, onChange, value}) => (
                      <AssessmentRangeBar question={question} name={name} value={value} onChange={onChange} />
                    )}
                  />
                </AssessmentItemWrapper>
              )
            );
          case AssessmentQuestionType.FreeText:
            return (
              isShowQuestion(question) && (
                <AssessmentItemWrapper title={question.displayName} key={question.name} onlyTitle />
              )
            );
          default:
            return null;
        }
      }),
    [control, errors, isShowQuestion, listQuestions],
  );

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  return <>{questions}</>;
};

export {WorkProductivityQuestions};
