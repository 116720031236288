import {memo} from 'react';
import {ReactComponent as SunFlower} from '../../../../assets/trellis/Sunflower.svg';
import {ReactComponent as YellowFlower} from '../../../../assets/trellis/YellowFlower.svg';
import {ReactComponent as FlowerCourse1} from '../../../../assets/trellis/BlueFlower.svg';
import {ReactComponent as FlowerCourse2} from '../../../../assets/trellis/RedFlower.svg';
import {ReactComponent as FlowerCourse3} from '../../../../assets/trellis/OrangeFlower.svg';

// Define all flowers which might use so that we can reuse it for better performance
const UsedFlowers = () => (
  <svg data-testid="root" style={{display: 'none'}}>
    {/* Hidden SVG to store definitions */}
    <defs>
      <symbol id="flowerCourse1">
        {/* Defined the Blue Flax flower SVG here */}
        <FlowerCourse1 />
      </symbol>
      <symbol id="flowerCourse2">
        {/* Defined the Red Daisy flower SVG here */}
        <FlowerCourse2 />
      </symbol>
      <symbol id="flowerCourse3">
        {/* Defined the Orange Dahlia flower SVG here */}
        <FlowerCourse3 />
      </symbol>
      <symbol id="sunFlower">
        {/* Defined the SunFlower flower SVG here */}
        <SunFlower />
      </symbol>
      <symbol id="flowerEndOfVine">
        {/* Defined the Yellow flower SVG here */}
        <YellowFlower />
      </symbol>
    </defs>
  </svg>
);

export const UsedFlowersMemo = memo(UsedFlowers);
