import {Patterns} from '../../../../helpers/patterns';

const generateMask = (minValue: number, maxValue: number) => {
  const maxValueArr = Array.from(String(maxValue)).map((i) => Number(i));

  return maxValueArr.map((d, i) => {
    if (i === 0) {
      return minValue && minValue > 0 ? new RegExp(`[${minValue}-9]{1}$`, 'g') : Patterns.Numbers;
    }

    return Patterns.Numbers;
  });
};

export {generateMask};
