import type {TimeZone} from '../../index';

const getCurrentTimeZone = (timeZones: ReadonlyArray<TimeZone>, timeZoneValue: string): TimeZone => {
  const defaultTimeZoneValue = 'America/New_York';
  const defaultTimeZoneDisplayName = '(UTC-05:00) Eastern Time (US & Canada)';

  return (
    timeZones.find((timeZone) => timeZone.value === timeZoneValue) ?? {
      value: defaultTimeZoneValue,
      displayName: defaultTimeZoneDisplayName,
    }
  );
};

export {getCurrentTimeZone};
