import {AbstractException} from './abstract.exception';
import type {AxiosError} from 'axios';

class HttpException extends AbstractException {
  constructor(error: AxiosError) {
    if (!HttpException.isHttpError(error)) {
      throw new Error('invalid http error type');
    }

    super(HttpException.name, error.message);
  }

  static isHttpError(error: unknown): error is AxiosError {
    return typeof error === 'object' && error !== null && 'message' in error;
  }
}

export {HttpException};
