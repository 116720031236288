import {SurveySpecElementType} from '../../index';

const getFormulaElementValue = (value: any, elementType?: SurveySpecElementType) => {
  switch (elementType) {
    case SurveySpecElementType.Toggle:
      return value === 'true' ? true : value === 'false' ? false : undefined;
    case SurveySpecElementType.Dropdown:
      return value?.value;
    case SurveySpecElementType.Select:
      return value?.text;
    default:
      return value === null ? null : String(value);
  }
};

type CalculateFormula = <T extends {name: string | null; elementType?: SurveySpecElementType}>(
  formula: string,
  answers: any,
  elements: Array<T>,
  defaultBody?: string,
  treatUnknownFieldsAsUndefined?: boolean,
) => boolean | string | number | null;

const calculateFormula: CalculateFormula = (
  formula,
  answers,
  elements,
  defaultBody?,
  treatUnknownFieldsAsUndefined?,
) => {
  const defaultFunctionBody = 'return false';
  let functionBody = `return ${formula}`;

  Object.keys(answers).forEach((name) => {
    const element = elements.find((element) => element.name === name);
    const value = getFormulaElementValue(answers[name], element?.elementType);

    if (typeof value !== 'undefined') {
      const valueToReplaceWith = typeof value === 'string' ? `"${value}"` : value;
      functionBody = functionBody.replace(new RegExp(`\\\${${name}}`, 'g'), String(valueToReplaceWith));
    }
  });

  if (functionBody.includes('$')) {
    if (treatUnknownFieldsAsUndefined) {
      functionBody = functionBody.replace(new RegExp(`\\\${[a-z_]+}`, 'g'), 'undefined');
    } else {
      functionBody = defaultBody || defaultFunctionBody;
    }
  }

  functionBody = functionBody.replace(/"false"/g, 'false');
  functionBody = functionBody.replace(/"true"/g, 'true');
  functionBody = functionBody.replace(/"undefined"/g, 'undefined');

  // eslint-disable-next-line no-new-func
  return new Function(functionBody)();
};

export {calculateFormula};
export type {CalculateFormula};
