import {useEffect, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {AssessmentQuestionType} from '../../../assessment';
import type {ISurveyRadioButtonOption} from '../../../components';
import {AssessmentItemWrapper, Button, SurveyRadioButtonGroup, Error} from '../../../components';
import {formatDefaultValueForForm, REQUIRED_FIELD_ERROR} from '../../../helpers';
import type {GlobalImprovementScaleQuestionsModel, IGlobalImprovementScaleItem} from '../../model';
import type {IGlobalImprovementScaleFormDto} from '../../service';
import styles from './global-improvement-scale-form.module.scss';

interface IGlobalImprovementScaleFormProps {
  selectedAssessmentItems: IGlobalImprovementScaleItem[] | null;
  listQuestions: GlobalImprovementScaleQuestionsModel[];
  isSubmitDisabled: boolean;
  readonly: boolean;
  onSubmit: (data: IGlobalImprovementScaleFormDto) => Promise<void>;
  onCancel: () => void;
}

const GlobalImprovementScaleForm: React.FC<IGlobalImprovementScaleFormProps> = ({
  selectedAssessmentItems,
  listQuestions,
  isSubmitDisabled,
  readonly,
  onSubmit,
  onCancel,
}): JSX.Element => {
  const defaultValues = useMemo(
    () =>
      listQuestions?.reduce(
        (acc, question) => ({
          ...acc,
          [question.name]: formatDefaultValueForForm(
            listQuestions,
            question.name,
            question.defaultValue ?? question.minValue,
          ),
        }),
        {},
      ),
    [listQuestions],
  );

  const defaultLoadedValues = useMemo(
    () =>
      selectedAssessmentItems?.reduce(
        (acc, item) => ({
          ...acc,
          [item.question]: formatDefaultValueForForm(listQuestions, item.question, item.value),
        }),
        defaultValues,
      ),
    [defaultValues, listQuestions, selectedAssessmentItems],
  );

  const {control, reset, handleSubmit, errors} = useForm<IGlobalImprovementScaleFormDto>({
    defaultValues: defaultLoadedValues,
  });

  useEffect(() => reset(defaultLoadedValues), [defaultLoadedValues, reset]);

  const questions = useMemo(
    () =>
      listQuestions.map((question) => {
        switch (question.type) {
          case AssessmentQuestionType.RadioButton:
            const radioButtonOptions = question.options.map((option) => ({id: option.value, text: option.displayName}));

            return (
              <AssessmentItemWrapper title={question.displayName} key={question.name}>
                <Controller
                  control={control}
                  name={question.name}
                  rules={{validate: (value) => question.required && validateRadioButton(value)}}
                  render={({name, onChange, value}) =>
                    !readonly ? (
                      <>
                        <SurveyRadioButtonGroup
                          name={name}
                          value={value}
                          onChange={onChange}
                          options={radioButtonOptions}
                          isError={!!errors[name]}
                        />
                        {!!errors[name] && <Error errorMessage={errors[name]?.message ?? ''} />}
                      </>
                    ) : (
                      <>{value.text}</>
                    )
                  }
                />
              </AssessmentItemWrapper>
            );
          default:
            return null;
        }
      }),
    [control, listQuestions, readonly, errors],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {questions}
      {!readonly && (
        <div className={styles.buttonContainer}>
          <Button block className={styles.button} onClick={onCancel} testid="global-improvement-form-button-cancel">
            Cancel
          </Button>
          <Button
            block
            type="submit"
            className={styles.button}
            disabled={isSubmitDisabled}
            testid="global-improvement-form-button-submit">
            Submit
          </Button>
        </div>
      )}
    </form>
  );
};

function validateRadioButton(value: ISurveyRadioButtonOption) {
  if (!value?.id) {
    return REQUIRED_FIELD_ERROR;
  }
}

export {GlobalImprovementScaleForm};
