import type {FC} from 'react';
import {useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Card} from '../../../components';
import type {ISurveyElementResponse, ISurveyFormDto, ISurveySpecElement, SurveySpecModel} from '../../../survey';
import {flatElements, getDefaultValues, SurveyFormElement} from '../../../survey';
import {scrollToError} from '../../../helpers';
import {SpecialtyType} from '../../../enums';
import styles from './weekly-wellness-report-form.module.scss';

const GWB_SURVEY_IBD_ID = 1;
const GWB_SURVEY_IBS_ID = 72;
const HEALTHCARE_SURVEY_IBD_ID = 21;
const HEALTHCARE_SURVEY_IBS_ID = 77;

interface IWeeklyWellnessReportFormProps {
  spec: SurveySpecModel;
  onSubmit: (data: ISurveyFormDto) => void;
  specialtyType: string;
  readonly?: boolean;
  elementValues: Array<ISurveyElementResponse>;
  isOnBoarding?: boolean;
  isEverCompleted?: boolean;
}

const WeeklyWellnessReportForm: FC<IWeeklyWellnessReportFormProps> = (props) => {
  const surveyTypeId = props.specialtyType === SpecialtyType.IBD ? GWB_SURVEY_IBD_ID : GWB_SURVEY_IBS_ID;

  const flattenedElements: Array<ISurveySpecElement> = useMemo(
    () => (props.spec?.elements && flatElements(props.spec.elements)) ?? [],
    [props.spec],
  );

  const defaultValues = useMemo(
    () => (flattenedElements.length ? getDefaultValues(props.elementValues, flattenedElements) : {}),
    [flattenedElements, props.elementValues],
  );

  const {reset, ...formMethods} = useForm<ISurveyFormDto>({defaultValues});

  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  useEffect(() => {
    scrollToError(formMethods.errors);
  }, [formMethods.errors]);

  const weeklyWellnessReportElements = useMemo(
    () =>
      props.spec?.elements
        ?.filter((element) => element.surveyId === surveyTypeId)
        .map((element) => (
          <SurveyFormElement
            key={element.elementId}
            element={element}
            readonly={!!props.readonly}
            elementValues={props.elementValues}
            isOnBoarding={props.isOnBoarding}
            isEverCompleted={props.isEverCompleted}
            flattenedElements={flattenedElements}
          />
        )),
    [
      flattenedElements,
      props.elementValues,
      props.isEverCompleted,
      props.isOnBoarding,
      props.readonly,
      props.spec?.elements,
      surveyTypeId,
    ],
  );

  const healthcareElements = useMemo(
    () =>
      props.spec?.elements
        ?.filter(
          (element) => element.surveyId === HEALTHCARE_SURVEY_IBD_ID || element.surveyId === HEALTHCARE_SURVEY_IBS_ID,
        )
        .map((element) => (
          <SurveyFormElement
            key={element.elementId}
            element={element}
            readonly={!!props.readonly}
            elementValues={props.elementValues}
            isOnBoarding={props.isOnBoarding}
            isEverCompleted={props.isEverCompleted}
            flattenedElements={flattenedElements}
          />
        )),
    [
      flattenedElements,
      props.elementValues,
      props.isEverCompleted,
      props.isOnBoarding,
      props.readonly,
      props.spec?.elements,
    ],
  );

  return (
    <FormProvider reset={reset} {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>
        <Card>{weeklyWellnessReportElements}</Card>
        <section className={styles.boldSection} tabIndex={0}>
          <div>Have any of the following occurred?</div>
        </section>
        <Card>{healthcareElements}</Card>
        {!props.readonly && props.children}
      </form>
    </FormProvider>
  );
};

export {WeeklyWellnessReportForm};
