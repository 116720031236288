import type {FC} from 'react';
import React from 'react';
import styles from './body-mass-index-form-card-wrapper.module.scss';

interface IBodyMassIndexFormItemWrapperProps {
  label?: string;
  children: React.ReactNode;
}

const BodyMassIndexFormCardWrapper: FC<IBodyMassIndexFormItemWrapperProps> = ({label, children}) => (
  <div className={styles.root}>
    {label && <div className={styles.label}>{label}</div>}
    {children}
  </div>
);

export {BodyMassIndexFormCardWrapper};
