interface IHealthMonitorPoint {
  x: Date;
  y: number;
}

class HealthMonitorModel {
  constructor(
    public points: Array<IHealthMonitorPoint> = [],
    public lowerBorderLine: number | null = null,
    public upperBorderLine: number | null = null,
    public absenceMessage: string = '',
  ) {}
}

export {HealthMonitorModel};
export type {IHealthMonitorPoint};
