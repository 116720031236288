import {inject, injectable} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IHttpService} from '../../utils/http';
import type {ICourseRepository} from './course.repository.interface';
import type {CourseDto} from '../dto/course.dto';
import {Course} from '../model/course';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';

@injectable()
class CourseRepository implements ICourseRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async list(): Promise<Course[]> {
    return this._http
      .get<CourseDto[]>('PatientCourse', {})
      .then((data) => data.data.map((courseDto) => new Course(courseDto)));
  }
  async find(): Promise<Course | null> {
    throw new NotImplementedException();
  }
  async save(): Promise<void> {
    throw new NotImplementedException();
  }
  async remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async updateTask(taskId: number, begin: boolean, isSkipped = false): Promise<void> {
    await this._http.put(`patientDailyTask/updateStatus`, {
      begin,
      done: !begin,
      patientDailyTaskActivityIntervalId: taskId,
      isSkipped,
    });
  }
  async setFavoriteLesson(lessonId: number, isFavoriteLesson: boolean): Promise<void> {
    await this._http.put(`patientDailyTask/setfavoritelesson`, {
      patientDailyTaskActivityIntervalId: lessonId,
      isFavoriteLesson: isFavoriteLesson,
    });
  }
}

export {CourseRepository};
