import type {FC} from 'react';
import React, {useCallback, useMemo} from 'react';
import styles from './tracking-daily-week.module.scss';
import {CollapseDescription, WeekDayCard} from '../../../../components';
import classNames from 'classnames';
import {ArrowIcon} from '../../../../assets/icons';
import {addDays, differenceInCalendarDays, isAfter, isBefore, isSameDay, startOfDay, subDays} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {formatDate, unshiftTimezone} from '../../../../helpers';

interface ITrackingDailyWeekProps {
  isMessageConditionShown: boolean;
  weekDates: Date[];
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  selectedDate: Date;
  timeZone: string;
  registrationDate: string | null;
}

const TrackingDailyWeek: FC<ITrackingDailyWeekProps> = ({
  isMessageConditionShown,
  weekDates,
  setSelectedDate,
  selectedDate,
  timeZone,
  registrationDate,
}) => {
  const onIncrement = useCallback(
    (days: number) => {
      setSelectedDate((currentSelectedDate) => addDays(currentSelectedDate, days));
    },
    [setSelectedDate],
  );

  const onDecrement = useCallback(
    (days: number) => {
      setSelectedDate((currentSelectedDate) => subDays(currentSelectedDate, days));
    },
    [setSelectedDate],
  );

  const monthYearDateString = useMemo(() => <>{formatDate(unshiftTimezone(selectedDate))}</>, [selectedDate]);

  const startOfCurrentDay = startOfDay(utcToZonedTime(new Date(), timeZone ?? ''));

  const isRightButtonDisabled = useMemo(
    () => isAfter(selectedDate, startOfCurrentDay) || isSameDay(startOfCurrentDay, selectedDate),
    [selectedDate, startOfCurrentDay],
  );

  const registrationTimezonedDate = useMemo(
    () => (registrationDate ? utcToZonedTime(new Date(registrationDate), timeZone) : null),
    [registrationDate, timeZone],
  );

  const isLeftButtonDisabled = useMemo(
    () =>
      registrationTimezonedDate
        ? isSameDay(registrationTimezonedDate, selectedDate) || isBefore(selectedDate, registrationTimezonedDate)
        : true,
    [registrationTimezonedDate, selectedDate],
  );

  const onSelectDate = useCallback(
    (newDate: Date) => {
      onIncrement(differenceInCalendarDays(newDate, selectedDate));
    },
    [selectedDate, onIncrement],
  );

  return (
    <>
      <div className={styles.dailyHeader}>
        <span className={styles.dailyDate}>{monthYearDateString}</span>
      </div>
      {isMessageConditionShown && (
        <CollapseDescription>
          <p>
            Tell us how you're feeling! Tracking your symptoms over time can help identify any issues before they occur.
            We’ll use this information to alert you, your GI provider, and your Trellus Team when you may need
            additional support. Your answers should reflect how you felt over the last 24 hours. Please contact your
            healthcare provider for all medical advice and urgent medical matters.
          </p>
        </CollapseDescription>
      )}
      <section className={styles.week}>
        <button
          className={classNames(styles.weekButton, styles.weekButtonLeft)}
          data-testid={'tracking-daily-week-decrement'}
          disabled={isLeftButtonDisabled}
          onClick={() => onDecrement(1)}>
          <ArrowIcon />
        </button>
        {weekDates.map((date) => {
          const active = differenceInCalendarDays(selectedDate, date) === 0;
          const hoverable = isBefore(date, startOfCurrentDay) || isSameDay(startOfCurrentDay, date);

          return (
            <WeekDayCard
              active={active}
              key={date.toISOString()}
              date={date}
              hoverable={hoverable}
              onSelectDate={onSelectDate}
            />
          );
        })}

        <button
          className={styles.weekButton}
          onClick={() => onIncrement(1)}
          disabled={isRightButtonDisabled}
          data-testid={'tracking-daily-week-increment'}>
          <ArrowIcon />
        </button>
      </section>
    </>
  );
};

export {TrackingDailyWeek};
