import type {FC} from 'react';
import React from 'react';
import styles from './progress-bar-line.module.scss';
import classNames from 'classnames';

interface IProgressBarLineProps {
  progress: number;
  className?: string;

  onTransitionEnd?(): void;
}

const ProgressBarLine: FC<IProgressBarLineProps> = ({progress, className, onTransitionEnd}) => {
  const value = progress > 100 ? 100 : progress < 0 ? 0 : progress;

  return (
    <div className={classNames(styles.root, className)}>
      <div
        className={styles.progressBar}
        style={{width: `${value}%`}}
        onTransitionEnd={onTransitionEnd}
        data-testid="progressBar"
      />
    </div>
  );
};

export {ProgressBarLine};
export type {IProgressBarLineProps};
