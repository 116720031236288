import type {ISurveyRadioButtonOption} from '../survey-radio-button';
import {SurveyRadioButton} from '../survey-radio-button';
import styles from './survey-radio-button-group.module.scss';
import type {FC} from 'react';
import classNames from 'classnames';

interface ISurveyRadioButtonGroupProps {
  name: string;
  options: Array<ISurveyRadioButtonOption>;
  value?: ISurveyRadioButtonOption;
  isError?: boolean;
  onChange: (value: ISurveyRadioButtonOption) => void;
  className?: string;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const SurveyRadioButtonGroup: FC<ISurveyRadioButtonGroupProps> = (props) => (
  <div className={classNames(styles.buttonsContainer, props.className)} role="radiogroup">
    {props.options.map((option) => (
      <SurveyRadioButton
        key={String(option.id)}
        name={props.name}
        value={props.value}
        option={option}
        onChange={props.onChange}
        isError={props.isError}
        ariaLabelTitle={props.ariaLabelTitle}
        ariaLabelError={props.ariaLabelError}
      />
    ))}
  </div>
);

export {SurveyRadioButtonGroup};
export type {ISurveyRadioButtonGroupProps};
