import classNames from 'classnames';
import styles from './favorite-icon.module.scss';
import {Icon, IconType} from '../icon';
import React from 'react';
import type {MouseEventHandler} from 'react'

interface IFavoriteIconProps {
  size: number;
  isFilled: boolean;
  onClick: () => void;
}

const createButtonHandler = (callBack: Function): MouseEventHandler<HTMLButtonElement> =>
  (e) => {
    e.stopPropagation();
    callBack();
};

const FavoriteIcon: React.FC<IFavoriteIconProps> = (props) => (
    <span
      onClick={createButtonHandler(props.onClick)}
      className={classNames(styles.complete, {
        [styles.size1]: props.size === 1,
        [styles.size2]: props.size === 2,
        [styles.size3]: props.size === 3,
        [styles.size4]: props.size === 4,
      })}>
      <Icon type={props.isFilled ? IconType.FavoriteFilled: IconType.FavoriteEmpty} stroke={'white'} />
    </span>
  );

export {FavoriteIcon};
export type {IFavoriteIconProps};
