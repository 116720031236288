import type {IAlertIndicatorGetResponseDto} from '../repository';
import {AlertIndicatorModel} from './alert-indicator.model';

class AlertIndicatorModelFactory {
  static fromGetResponseDto(dto: IAlertIndicatorGetResponseDto): AlertIndicatorModel {
    return new AlertIndicatorModel(
        dto.messages,
        dto.notifications,
        dto.strengthsAssessment,
        dto.emotionalImpactScale,
        dto.selfEfficacyAssessment,
        dto.resilienceAssessment,
        dto.diseaseSeverityScreener,
        dto.chronicConditionChecklist,
    );
  }
}

export {AlertIndicatorModelFactory};
