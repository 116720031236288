import type {FC} from 'react';
import React, {useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Button, Card} from '../../components';
import styles from './work-productivity-form.module.scss';
import {WorkProductivityQuestions} from './questions';
import {formatDefaultValueForForm} from '../../helpers';
import type {IWorkProductivityFormDto} from './dto';
import type {WorkProductivityQuestionModel} from '../model';

interface IWorkProductivityFormProps {
  listQuestions: WorkProductivityQuestionModel[];
  onSubmit: (data: IWorkProductivityFormDto) => void;
  onCancel: () => void;
}

const WorkProductivityForm: FC<IWorkProductivityFormProps> = ({listQuestions, onSubmit, onCancel}) => {
  const defaultValues = useMemo(
    () =>
      listQuestions?.length
        ? listQuestions.reduce(
            (acc, question) => ({
              ...acc,
              [question.name]: formatDefaultValueForForm(listQuestions, question.name, question.defaultValue),
            }),
            {},
          )
        : {},
    [listQuestions],
  );

  const {reset, ...formMethods} = useForm<IWorkProductivityFormDto>({
    defaultValues,
  });

  const isSubmitDisabled = useMemo(
    () => formMethods.formState.isSubmitting || formMethods.formState.isSubmitSuccessful,
    [formMethods.formState.isSubmitSuccessful, formMethods.formState.isSubmitting],
  );

  return (
    <FormProvider {...formMethods} reset={reset}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Card>
          <WorkProductivityQuestions listQuestions={listQuestions} />
        </Card>
        <div className={styles.buttonContainer}>
          <Button block className={styles.button} onClick={onCancel} testid={'survey-button-cancel'}>
            Cancel
          </Button>
          <Button
            block
            type={'submit'}
            className={styles.button}
            disabled={isSubmitDisabled}
            testid={'survey-button-submit'}>
            Submit
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export {WorkProductivityForm};
