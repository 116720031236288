import React from 'react';
import classnames from 'classnames';
import type {ButtonProps} from './button.types';
import {ButtonType} from './button.types';
import {IconLegacy, IconTypeLegacy} from '../icon-legacy';
import './button.scss';

const Button: React.FC<ButtonProps> = (props: ButtonProps) => (
  <div
    data-testid={props.testId}
    className={classnames(
      'button',
      `button--${props.type}`,
      props.className,
      props.disabled ? 'button--disabled' : null,
    )}
    onClick={(event) => props.onClick(event)}>
    {props.type === ButtonType.SeparateIcon && props.label && (
      <div className={classnames('button__text', `button__text--${props.type}`)}>{props.label}</div>
    )}
    {props.icon && (
      <div
        className={classnames(
          'button__icon-container',
          props.icon === IconTypeLegacy.PatientInfo || props.icon === IconTypeLegacy.ClientInfo
            ? `button__icon-container--${props.icon}`
            : `button__icon-container--${props.type}`,
        )}>
        <IconLegacy
          type={props.icon}
          className={classnames('button__icon', `button__icon--${props.type}`, props.iconClassName)}
        />
      </div>
    )}
    {props.type !== ButtonType.SeparateIcon && props.label && (
      <div className={classnames('button__text', props.icon ? `button__text--with-icon` : null)}>{props.label}</div>
    )}
  </div>
);

export {Button};
