enum PredefinedTasks {
  None = 0,
  WeeklyWellnessReportSurvey = 2,
  DailySymptomTracker = 3,
  StrengthAssessment = 9,
  Vaccination = 15,
  PreVisitTask = 190,
  SelfEfficacy = 195,
  ResilienceAssessment = 196,
  EmotionalImpactScale = 198,
  MedicationsListUpdate = 200,
  DiseaseSeverityScreener = 201,
  ChronicConditionChecklist = 202,
  LifeQuality = 204,
  WorkProductivity = 205,
  GlobalImprovementScale = 206,
}

export {PredefinedTasks};
