import React from 'react';
import type {FC} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {PATTERN_USER_NAME_ERROR, REQUIRED_FIELD_ERROR} from '../../../../../../helpers';
import {Patterns} from '../../../../../../helpers/patterns';
import {Error, InputNumber, MaskedInput} from '../../../../../../components';
import styles from '../two-factor-auth-form.module.scss';
import {InputNumberTypes} from '../../../../../../components/input-number/input-number.component';
import classNames from 'classnames';
import {MaskedInputType} from '../../../../../../components/masked-input/masked-input.types';
import {MIN_CODE_LENGTH, MIN_CODE_LENGTH_ERROR_MESSAGE} from '../two-factor-auth-form.component';

interface IOwnProps {
  isLifetimeCodeTimer: string;
  getUserNumber: JSX.Element;
  serviceError: string | null;
}
export const NewPhoneWithOutVerification: FC<IOwnProps> = ({getUserNumber, isLifetimeCodeTimer, serviceError}) => {
  const {control, errors} = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={'code2'}
        rules={{
          required: REQUIRED_FIELD_ERROR,
          minLength: {
            value: MIN_CODE_LENGTH,
            message: MIN_CODE_LENGTH_ERROR_MESSAGE,
          },
          pattern: {
            value: Patterns.UserName,
            message: PATTERN_USER_NAME_ERROR,
          },
        }}
        render={({name, onChange, value}) => (
          <>
            {getUserNumber}
            <InputNumber
              className={styles.input}
              name={name}
              onChange={onChange}
              value={value}
              placeholder={'Please enter your code here'}
              isValid={!errors.code2?.message}
              maxLength={MIN_CODE_LENGTH}
              numberInputType={InputNumberTypes.Decimal}
              disabled={!isLifetimeCodeTimer}
            />
            {!!errors.code2?.message && (
              <Error
                errorMessage={'The code you entered is incorrect. Please try again'}
                name={name}
                className={styles.cardError}
              />
            )}
            {!isLifetimeCodeTimer && (
              <Error errorMessage={'The timeout period to enter the code has expired'} className={styles.cardError} />
            )}
            {serviceError && (
              <Error
                errorMessage={'The code you entered is incorrect. Please try again'}
                className={styles.cardError}
              />
            )}
          </>
        )}
      />
      <Controller
        control={control}
        name={'phone'}
        rules={{
          required: REQUIRED_FIELD_ERROR,
          pattern: {
            value: Patterns.PhoneFormat,
            message: 'Please enter a valid phone number',
          },
        }}
        render={({name, onChange, value}) => (
          <MaskedInput
            name={name}
            mask={Patterns.PhoneMask}
            label={value && 'New Alternative Phone *'}
            placeholder="New Alternative Phone *"
            className={classNames(['masked-simple', styles.input])}
            value={value ? (value.startsWith('+1') ? value : `+1-${value}`) : ''}
            onValueChanged={onChange}
            type={MaskedInputType.LabelInInput}
          />
        )}
      />
    </>
  );
};
