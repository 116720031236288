enum CookieModelSameSiteEnum {
  Strict = 'strict',
  Lax = 'lax',
}

class CookieModel {
  constructor(
    public name = '',
    public value = '',
    public expires = new Date(),
    public path = '',
    public domain = '',
    public secure = false,
    public httpOnly = false,
    public sameSite: CookieModelSameSiteEnum | undefined = undefined,
  ) {}

  toString(): string {
    let str = `${encodeURIComponent(this.name)}=${encodeURIComponent(this.value)}; `;

    str += `expires=${this.expires.toUTCString()}; `;

    if (this.path) {
      str += `path=${this.path}; `;
    }

    if (this.domain) {
      str += `domain=${this.domain}; `;
    }

    if (this.secure) {
      str += `secure; `;
    }

    if (this.httpOnly) {
      str += `httpOnly; `;
    }

    if (this.sameSite) {
      str += `samesite=${this.sameSite}; `;
    }

    return str.trim();
  }
}

export {CookieModel, CookieModelSameSiteEnum};
