import {NotificationModel} from './notifications.model';
import type {INotificationsGetResponseDtoRecord} from '../repository/dto';
import {parseRichText} from '../../helpers';
import type {NotificationSocketDto} from '../socket-dto';

class NotificationsModelFabric {
  static fromGetResponseDtoRecord(dto: INotificationsGetResponseDtoRecord): NotificationModel {
    return new NotificationModel(
      dto.notificationId,
      dto.userAccountId,
      parseRichText(dto.title),
      parseRichText(dto.text),
      dto.isRead,
      new Date(dto.notificationDate),
      new Date(dto.createdDate),
      new Date(dto.lastModifiedDate),
      dto.userName,
      dto.userRoleId,
      dto.flag,
    );
  }

  static fromSocketDto(dto: NotificationSocketDto) {
    return new NotificationModel(
      dto.notificationId,
      dto.userAccountId,
      parseRichText(dto.title),
      parseRichText(dto.text),
      dto.isRead,
      new Date(dto.notificationDate),
      new Date(dto.createdDate),
      new Date(dto.lastModifiedDate),
      dto.userName,
      dto.userRoleId,
      dto.flag,
    );
  }
}

export {NotificationsModelFabric};
