import {MessageThreadModel} from './message-thread.model';
import type {IMessageThreadGetResponseDto, IMessageThreadSocketDto} from '../dto';
import {RecipientType} from '../dto';
import {MessageThreadStatus} from './message-thread-status.enum';
import {MessageModelFactory} from './message.model.factory';
import {MessageThreadRecipientModelFactory} from './message-thread-recipient.model.factory';
import type {TrellusTeamMember} from '../../account';

class MessageThreadModelFactory {
  static fromGetResponseDto(dto: IMessageThreadGetResponseDto, accountId: number): MessageThreadModel {
    const dtoRecipient = dto.userAccounts.find(
      (r) => r.userAccountId !== accountId && r.recipientType === RecipientType.Main,
    );

    if (!dtoRecipient) {
      throw new Error('no recipient dto found');
    }

    const recipient = MessageThreadRecipientModelFactory.fromGetResponseDto(dtoRecipient);

    return new MessageThreadModel(
      dto.threadId,
      recipient,
      dto.countUnreadMessages,
      dto.countUnreadMessages ? MessageThreadStatus.Unread : MessageThreadStatus.Read,
      (dto.lastMessage.text || dto.lastMessage.attachments?.[0]?.fileName) ?? '',
      new Date(dto.lastMessage.createdDate),
      [],
      dto.readonly,
    );
  }

  static fromTrellusTeamMember(trellusTeamMember: TrellusTeamMember): MessageThreadModel {
    const recipient = MessageThreadRecipientModelFactory.fromTrellusTeamMember(trellusTeamMember);
    const id = -1 - recipient.id;

    return new MessageThreadModel(
      id,
      recipient,
      0,
      MessageThreadStatus.Read,
      '',
      null,
      [],
      !trellusTeamMember.openForCommunicationWithPatient,
    );
  }

  static fromSocketDto(dto: IMessageThreadSocketDto, id: number): MessageThreadModel {
    const dtoRecipient = dto.userAccounts.find((acc) => acc.userAccountId !== id);

    if (!dtoRecipient) {
      throw new Error('no recipient dto found');
    }

    const recipient = MessageThreadRecipientModelFactory.fromSocketDto(dtoRecipient);
    const lastMessage = MessageModelFactory.fromSocketDto(dto.lastMessage);

    return new MessageThreadModel(
      dto.threadId,
      recipient,
      1,
      dto.lastMessage.status,
      (dto.lastMessage.text || dto.lastMessage.attachments?.[0]?.fileName) ?? '',
      new Date(dto.lastMessage.createdDate),
      [lastMessage],
      dto.readonly,
    );
  }

  static merge(old: MessageThreadModel, actual: MessageThreadModel): MessageThreadModel {
    const unreadMessageCount =
      actual.messages[actual.messages.length - 1]?.userId === actual.recipient?.id ? actual.unreadMessageCount : 0;

    return new MessageThreadModel(
      actual.id,
      old.recipient,
      unreadMessageCount,
      actual.status,
      actual.previewText,
      actual.lastMessageDate,
      actual.messages,
      actual.readonly,
    );
  }
}

export {MessageThreadModelFactory};
