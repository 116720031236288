import type {FC} from 'react';
import React from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import type {MaskedInputProps} from './masked-input.types';
import {MaskedInputType} from './masked-input.types';
import styles from './masked-input.module.scss';
import {Error} from '../error';

const MaskedInput: FC<MaskedInputProps> = (params) => {
  const title = params.ariaLabelTitle ?? params.name;
  const error = !!params.ariaLabelError ? `${params.ariaLabelError}.` : "";

  return (
  <div
    className={classnames(
      styles.root,
      {[styles.labelInInput]: params.type === MaskedInputType.LabelInInput},
      params.isValid === false && params.type === MaskedInputType.LabelInInput && styles.invalid,
      params.className,
    )}>
    {(params.type === MaskedInputType.LabelInInput && !!params.value) ||
    (params.type === MaskedInputType.LabelInInput && !!params.label) ? (
      <label className={classnames(styles.label, params.labelClassName)}>{params.label}</label>
    ) : null}
    <InputMask
      className={classnames(
        styles.input,
        params.isValid === false && styles.invalid,
        params.type ? styles[params.type] : null,
        params.type === MaskedInputType.LabelInInput && !params.value ? styles.noLabel : null,
      )}
      name={params.name}
      aria-label={`${error} ${title}`}
      mask={params.mask}
      onChange={(e) => {
        if (params.onValueChanged) {
          params.onValueChanged(e.target.value);
        }
      }}
      value={params.value}
      placeholder={params.placeholder}
      disabled={params.disabled}
      readOnly={params.readOnly}
      alwaysShowMask={params.alwaysShowMask}
      maskPlaceholder={params.maskPlaceholder}
    />
    {params.errorMessage && (
      <div className={styles.errorField}>
        <Error errorMessage={params.errorMessage} />
      </div>
    )}
  </div>
  );
}

export {MaskedInput};
