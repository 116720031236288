import {BlueHydrangea, PinkHydrangea, YellowHydrangea} from '../../assets/goals';
import {mapTypeToMotivation} from '../../task/helper/mapper';
import type {IWorksheet} from '../../task/interfaces';
import {ELevelGoal} from '../../task/interfaces';

export const mapLevelToGoalImage = (level: ELevelGoal) => {
  const goalIcon: Record<ELevelGoal, string | undefined> = {
    [ELevelGoal.HARD]: PinkHydrangea,
    [ELevelGoal.MEDIUM]: YellowHydrangea,
    [ELevelGoal.EASY]: BlueHydrangea,
    [ELevelGoal.UNKNOWN]: undefined,
  };

  return goalIcon[level] || undefined;
};

export const mapWorksheetFormData = (worksheetData: IWorksheet) => ({
  adaptive: worksheetData.adaptive || '',
  adaptiveScore: worksheetData.adaptiveScore || 0,
  behaviorTypeId: worksheetData.behaviorTypeId || 0,
  effortLevel: worksheetData.effortLevel || 0,
  goal: worksheetData.goal || '',
  meaningful: worksheetData.meaningful || '',
  meaningfulScore: worksheetData.meaningfulScore || 0,
  patientCourseId: worksheetData.patientCourseId || 0,
  realistic: worksheetData.realistic || '',
  realisticScore: worksheetData.realisticScore || 0,
  specific: worksheetData.specific || '',
  specificScore: worksheetData.specificScore || 0,
  timeBound: worksheetData.timeBound || '',
  timeBoundScore: worksheetData.timeBoundScore || 0,
  totalScore: worksheetData.totalScore || 0,
  motivation: mapTypeToMotivation(worksheetData.type),
});
