import type {ILocalStorageService} from './local-storage.service.interface';
import {injectable} from 'inversify';

@injectable()
class LocalStorageService implements ILocalStorageService {
  get(key: string): string | null {
    let res = null;

    try {
      res = localStorage.getItem(key);
    } catch (e) {
      console.error(e);
    }

    return res;
  }

  getObject<T>(key: string): T | null {
    let res = null;

    const val = this.get(key);

    if (!!val) {
      res = JSON.parse(val);
    }

    return res;
  }

  set(key: string, val: string): void {
    try {
      localStorage.setItem(key, val);
    } catch (e) {
      console.error(e);
    }
  }

  setObject<T>(key: string, val: T): void {
    const valString = JSON.stringify(val);

    this.set(key, valString);
  }

  delete(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }

  clear(): void {
    try {
      localStorage.clear();
    } catch (e) {
      console.error(e);
    }
  }
}

export {LocalStorageService};
