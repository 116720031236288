enum DatePickerView {
  Wide = 1,
  Selector = 2,
  OnlyYear = 3,
}

enum DatePickerInputType {
  Default = 'Default',
  Small = 'Small',
  Wide = 'Wide',
  LabResults = 'LabResults',
  Medications = 'Medications',
}

export {DatePickerView, DatePickerInputType};
