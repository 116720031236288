enum EncounterTypeEnum {
  None,
  SuperVisit,
  FollowUpVisit,
  ReAssessment,
  InitialClinicalIntake,
  ClinicalFollowUp,
  LabResult,
  ProviderDiseaseSeverityScreener,
  PostEncounterActionPlan,
}

const EncounterTypeEnumLabelMap = new Map<EncounterTypeEnum, string>([
  [EncounterTypeEnum.None, 'None'],
  [EncounterTypeEnum.SuperVisit, 'Initial Visit'],
  [EncounterTypeEnum.FollowUpVisit, 'Follow-up Visit'],
  [EncounterTypeEnum.ReAssessment, 'Re-Assessment'],
  [EncounterTypeEnum.InitialClinicalIntake, 'Initial Clinical Intake'],
  [EncounterTypeEnum.ClinicalFollowUp, 'Clinical Follow Up'],
  [EncounterTypeEnum.LabResult, 'Lab Result'],
  [EncounterTypeEnum.ProviderDiseaseSeverityScreener, 'Provider Disease Severity Screener'],
  [EncounterTypeEnum.PostEncounterActionPlan, 'Post-Encounter Action Plan'],
]);

export {EncounterTypeEnum, EncounterTypeEnumLabelMap};
