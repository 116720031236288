import type {FC, KeyboardEventHandler} from 'react';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classnames from 'classnames';
import type {ISingleDropdownProps, SingleDropdownState} from './single-dropdown.types';
import {SingleDropdownType} from './single-dropdown.types';
import {TextEllipsis} from '../text-ellipsis';
import {DownIcon} from '../../assets/icons';
import type {IOption} from '../../types';
import './single-dropdown.scss';
import styles from './single-dropdown.module.scss';
import {Button, IconType} from '..';

const SingleDropdown: FC<ISingleDropdownProps> = (props) => {
  const [dropdownState, setDropdownState] = useState<SingleDropdownState>({
    isOpened: false,
    isFocused: false,
    value: props.value?.displayName,
  });

  const searchRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (dropdownState?.value !== props.value?.displayName) {
      setDropdownState({...dropdownState, value: props.value?.displayName});
    }
  }, [dropdownState, props.value?.displayName]);

  const clearQuery = useCallback(() => {
    if (props.isSearchEnabled && props.onQueryChanged) {
      props.onQueryChanged('');
    }
  }, [props]);

  const closeDropdown = useCallback(() => {
    props.onToggleDropdown && props.onToggleDropdown(false);

    setDropdownState({...dropdownState, isOpened: false});

    clearQuery();
  }, [clearQuery, dropdownState, props]);

  const toggleDropdown = useCallback(() => {
    if (props.disabled) {
      return;
    }

    if (!dropdownState.isOpened) {
      searchRef.current?.focus();
    }

    props.onToggleDropdown && props.onToggleDropdown(!dropdownState.isOpened);

    setDropdownState({...dropdownState, isOpened: !dropdownState.isOpened});

    clearQuery();
  }, [clearQuery, dropdownState, props]);

  const setFocus = useCallback(
    (focus: boolean) => {
      if (props.disabled) {
        return;
      }

      setDropdownState({...dropdownState, isFocused: focus});
    },
    [dropdownState, props.disabled],
  );

  const getInputClassName = useCallback(
    () =>
      classnames(
        'single-dropdown__input',
        `single-dropdown__input--${props.type}`,
        props.type === SingleDropdownType.Default && (dropdownState.isFocused || dropdownState.isOpened)
          ? `single-dropdown__input--${props.type}-opened`
          : null,
        !(props.query || props.value?.displayName) && 'single-dropdown__input--placeholder',
        dropdownState.isOpened && 'single-dropdown__input--dropdown-opened',
        props.isError && 'single-dropdown__input--dropdown-error',
      ),
    [dropdownState.isFocused, dropdownState.isOpened, props.isError, props.query, props.type, props.value?.displayName],
  );

  const getInputValue = useCallback((): string | undefined => {
    if (dropdownState.isOpened) {
      return props.query || (!!props.showInputValueAlways ? props.value?.displayName : '');
    }

    if (dropdownState.isFocused && props.isSearchEnabled) {
      toggleDropdown();

      return props.query;
    }

    return props.query || props.value?.displayName || props.placeholder || '';
  }, [
    dropdownState.isFocused,
    dropdownState.isOpened,
    props.isSearchEnabled,
    props.placeholder,
    props.query,
    props.showInputValueAlways,
    props.value?.displayName,
    toggleDropdown,
  ]);

  const fakeInput: JSX.Element = useMemo(
    () => (
      <div className={classnames(getInputClassName(), 'single-dropdown__input--fake')}>
        <TextEllipsis>{dropdownState.value ?? props.placeholder}</TextEllipsis>
      </div>
    ),
    [dropdownState.value, getInputClassName, props.placeholder],
  );

  const title = props.ariaLabelTitle ?? props.name;
  const error = !!props.ariaLabelError ? `${props.ariaLabelError}.` : '';

  const input = useMemo(
    () => (
      <div className="single-dropdown__input-container-input">
        <input
          name={props.name}
          aria-label={`${error} ${title}`}
          data-testid={`${props.name}-input`}
          className={getInputClassName()}
          value={getInputValue()}
          readOnly={true}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          autoComplete={'off'}
          tabIndex={-1}
        />
        {!(dropdownState.isFocused || dropdownState.isOpened) && fakeInput}
      </div>
    ),
    [
      dropdownState.isFocused,
      dropdownState.isOpened,
      fakeInput,
      getInputClassName,
      getInputValue,
      props.name,
      setFocus,
      title,
      error,
    ],
  );

  const search = useMemo(
    () => (
      <div className="single-dropdown__input-container-search">
        <input
          name={props.name}
          aria-label={`${error} ${title}`}
          ref={() => searchRef}
          className={getInputClassName()}
          value={getInputValue()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.onQueryChanged && props.onQueryChanged(e.target.value)
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          disabled={props.disabled}
          data-testid={`${props.name}-search`}
          autoComplete={'off'}
        />
        {!(dropdownState.isFocused || dropdownState.isOpened) && fakeInput}
      </div>
    ),
    [
      dropdownState.isFocused,
      dropdownState.isOpened,
      fakeInput,
      getInputClassName,
      getInputValue,
      props,
      setFocus,
      title,
      error,
    ],
  );

  const getStringValue = useCallback((option: IOption<string | number>) => {
    if (option && option.value) {
      return JSON.stringify(option.value);
    }

    return '';
  }, []);

  const onValueChanged = useCallback(
    (option) => {
      if (props.onChange) {
        props.onChange(option);
        closeDropdown();
      }
    },
    [closeDropdown, props],
  );

  const isNullOptionShown: boolean = useMemo(() => {
    if (props.query && props.isSearchEnabled) {
      return false;
    }

    return !!props.isNullable;
  }, [props.isNullable, props.isSearchEnabled, props.query]);

  const hiddenSelect = useMemo(
    () => (
      <select
        className="single-dropdown__form-select"
        name={props.name}
        aria-label={`${error} ${title}`}
        ref={props.inputRef}
        autoComplete={'off'}
        value={props.value && getStringValue(props.value)}
        onChange={() => {}}>
        {props.options.map((option, index) => (
          <option value={getStringValue(option)} key={index}>
            {option.displayName}
          </option>
        ))}
      </select>
    ),
    [getStringValue, props.inputRef, props.name, props.options, props.value, title, error],
  );

  const options = useMemo(() => {
    const options = [...props.options];

    if (
      props.isSearchEnabled &&
      props.value &&
      !options.find((i) => JSON.stringify(i) === JSON.stringify(props.value))
    ) {
      options.push(props.value);
    }

    return options.map((option, index) => {
      if (props.visibleOptionsCount && index + 1 > props.visibleOptionsCount) {
        return null;
      }

      const cnahgeValue = () => {
        onValueChanged(option);
        setDropdownState({...dropdownState, value: option.displayName});
      };

      const onEnterPress: KeyboardEventHandler = (e) => {
        if (e.code === 'Enter') {
          return cnahgeValue();
        }
      };

      return (
        <div
          className={classnames(
            'single-dropdown__option',
            `single-dropdown__option--${props.type}`,
            JSON.stringify(option) === JSON.stringify(props.value) && 'single-dropdown__option--selected',
          )}
          onKeyPress={onEnterPress}
          tabIndex={0}
          onClick={cnahgeValue}
          role="listitem"
          aria-label={`${error} ${title}. Option: ${option.displayName}`}
          data-testid={`${props.name}-option-${index}`}
          key={index}>
          <div className="single-dropdown__option-text" data-testid={`${props.name}-option`}>
            <TextEllipsis>{option.displayName}</TextEllipsis>
          </div>
        </div>
      );
    });
  }, [dropdownState, onValueChanged, props, title, error]);

  const nullOption = useMemo(
    () => (
      <div
        data-testid={`${props.name}-null`}
        className={classnames('single-dropdown__option', `single-dropdown__option--${props.type}`)}
        onClick={() => {
          onValueChanged(undefined);
          setDropdownState({...dropdownState, value: undefined});
        }}
      />
    ),
    [dropdownState, onValueChanged, props.name, props.type],
  );

  const noOptionsMessage = useMemo(
    () => (
      <div
        className={classnames(
          'single-dropdown__option',
          'single-dropdown__option--no-items',
          `single-dropdown__option--${props.type}`,
        )}
        key={null}>
        <div className="single-dropdown__option-text">{props.noOptionsMessage ?? 'No results'}</div>
      </div>
    ),
    [props.noOptionsMessage, props.type],
  );

  const icon = useMemo(
    () => (
      <div
        className={classnames(
          'single-dropdown__icon',
          `single-dropdown__icon--${props.type}`,
          dropdownState.isOpened && 'single-dropdown__icon--opened',
        )}>
        <DownIcon />
      </div>
    ),
    [dropdownState.isOpened, props.type],
  );

  const iconClose = useMemo(
    () => (
      <Button
        icon={IconType.CloseCircle}
        onClick={(e) => {
          e.stopPropagation();
          // setDropdownState({...dropdownState, value: undefined});
          onValueChanged('');
        }}
        iconSize={15.5}
        flat
        className={classnames('single-dropdown__icon')}
      />
    ),
    [onValueChanged],
  );

  const addNewMedication = useCallback(async () => {
    if (!!props.onAdd) {
      const displayName = getInputValue();

      const newMedicationValue = displayName ? await props?.onAdd(displayName) : null;

      if (newMedicationValue) {
        const newMedicationObj = {value: newMedicationValue, displayName};

        onValueChanged(newMedicationObj);
      }
    }
  }, [getInputValue, onValueChanged, props]);

  const onEnterPress: KeyboardEventHandler = (e) => {
    if (e.code === 'Enter') {
      return toggleDropdown();
    }
  };

  return (
    <div className={classnames('single-dropdown', props.disabled && 'single-dropdown--disabled', props.className)}>
      <OutsideClickHandler onOutsideClick={closeDropdown}>
        <div className="single-dropdown__main-container">
          {!!props.label ? (
            <label
              className={classnames(
                'single-dropdown__label',
                `single-dropdown__label--${props.type}`,
                props.labelClassName,
              )}>
              {props.label}
            </label>
          ) : null}
          <div
            className={classnames(
              'single-dropdown__input-container',
              props.type === SingleDropdownType.Default && (dropdownState.isFocused || dropdownState.isOpened)
                ? `single-dropdown__input-container--${props.type}`
                : null,
            )}
            aria-label={`${error} ${title}`}
            onClick={toggleDropdown}
            onKeyPress={onEnterPress}
            tabIndex={props.disabled ? -1 : 0}
            role="combobox"
            aria-controls={`${props.name}-dropdownContainer`}
            aria-expanded={dropdownState.isOpened}
            data-testid={`${props.name}-container`}>
            {props.isSearchEnabled ? search : input}
            <div
              className={classnames(
                styles.dropdownContainer,
                props.type && styles[`dropdownContainer${props.type.charAt(0).toUpperCase() + props.type.slice(1)}`],
                dropdownState.isOpened && styles.dropdownContainerOpened,
              )}
              role="list"
              id={`${props.name}-dropdownContainer`}>
              {!!props.onAdd && !props.options.length ? (
                <div className={styles.addItem} onClick={addNewMedication}>
                  <span>{props.addLabel ?? 'Add new item'}</span> {`"${getInputValue()}"`}
                </div>
              ) : null}

              {isNullOptionShown && nullOption}
              {options}
              {!isNullOptionShown && !props.options.length && noOptionsMessage}
            </div>
            {props.isCleanIcon ? iconClose : icon}
          </div>
        </div>
      </OutsideClickHandler>
      {hiddenSelect}
    </div>
  );
};

export {SingleDropdown};
