import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import styles from './strengths-assessment.module.scss';
import {StrengthsAssessmentForm} from '../form';
import type {IStrengthsAssessmentService} from '../service';
import {STRENGTHS_ASSESSMENT_SERVICE} from '../service';
import type {IStrengthsAssessmentFormDto} from '../dto';
import {useInjection} from '../../ioc';
import {StrengthAssessmentSubHeader} from './components';
import {Button, CollapseDescription, PageLoader, PageTitle} from '../../components';
import {useFormConfirmationDialog, useQuery} from '../../hooks';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';
import {SpecialtyType} from '../../enums';
import {
  allThingsIbdFormElements,
  allThingsIbsFormElements,
  trueIbdFormElements,
  trueIbsFormElements,
} from './strengths-assessment.questions';

interface IProps {
  strengthsAssessmentsService: IStrengthsAssessmentService;
  account: IAccountService;
}

const StrengthsAssessmentScreen: FC<IProps> = ({strengthsAssessmentsService, account}) => {
  const {id: strengthAssessmentId} = useQuery<{id: number}>();

  const {getConfirmation} = useFormConfirmationDialog();

  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const allThingsFormElements =
    account.info?.specialty?.name === SpecialtyType.IBD ? allThingsIbdFormElements : allThingsIbsFormElements;

  const trueFormElements =
    account.info?.specialty?.name === SpecialtyType.IBD ? trueIbdFormElements : trueIbsFormElements;

  const onSubmit = useCallback(
    async (data: IStrengthsAssessmentFormDto) => {
      setSubmitDisabled(true);
      const isConfirmed = await getConfirmation({});
      if (isConfirmed && account.id) {
        await strengthsAssessmentsService.createAssessment(data, account.id);
      } else {
        setSubmitDisabled(false);
      }
    },
    [account.id, getConfirmation, strengthsAssessmentsService],
  );

  useEffect(() => {
    if (strengthAssessmentId) {
      strengthsAssessmentsService.loadAssessmentById(strengthAssessmentId);
    } else {
      strengthsAssessmentsService.loadAssessments();
    }
  }, [strengthAssessmentId, strengthsAssessmentsService]);

  const strengthsAssessmentsCollapseDescription = useMemo(
    () =>
      !strengthAssessmentId &&
      !strengthsAssessmentsService.lastFilledAssessment?.lastUpdated &&
      !strengthsAssessmentsService.isLoading && (
        <CollapseDescription>
          We value gaining insight into your general outlook on life. Our goal is to recognize the strengths you already
          have, which we can then support you in applying to enhance your self-management. Please answer the questions
          below as best you can. 0 being not satisfied at all and 10 being completely satisfied.
        </CollapseDescription>
      ),
    [
      strengthAssessmentId,
      strengthsAssessmentsService.isLoading,
      strengthsAssessmentsService.lastFilledAssessment?.lastUpdated,
    ],
  );

  const onBack = useCallback(() => strengthsAssessmentsService.navigateToList(), [strengthsAssessmentsService]);

  const selectedAssessment =
    strengthsAssessmentsService.selectedAssessment || strengthsAssessmentsService.lastFilledAssessment;

  const readonly = !!strengthAssessmentId || strengthsAssessmentsService.isReadOnly;

  return (
    <>
      <div className={styles.header}>
        <PageTitle>Strengths Assessment</PageTitle>
        {readonly && !strengthsAssessmentsService.isLoading && (
          <Button className={styles.button} onClick={onBack}>
            Back
          </Button>
        )}
      </div>

      {strengthsAssessmentsCollapseDescription}
      {strengthsAssessmentsService.isLoading ? (
        <PageLoader />
      ) : (
        <>
          <StrengthAssessmentSubHeader date={selectedAssessment?.lastUpdated} />
          <StrengthsAssessmentForm
            onSubmit={onSubmit}
            readonly={readonly}
            lastFilledAssessment={selectedAssessment}
            allThingsFormElements={allThingsFormElements}
            trueFormElements={trueFormElements}
            isSubmitDisabled={isSubmitDisabled}
            specialtyType={account.info?.specialty?.name ?? ''}
          />
        </>
      )}
    </>
  );
};

const ObserverStrengthsAssessmentScreen = observer(StrengthsAssessmentScreen);
const InjectedStrengthsAssessmentScreenInjected: FC = () => (
  <ObserverStrengthsAssessmentScreen
    strengthsAssessmentsService={useInjection(STRENGTHS_ASSESSMENT_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
  />
);

export {InjectedStrengthsAssessmentScreenInjected as StrengthsAssessmentScreen};
