import type {IVaccinationFormElement} from '../../../vaccination-form.component';
import type {FC} from 'react';
import React, {Fragment, useCallback, useEffect, useMemo} from 'react';
import {SimpleVaccinationInput} from '../simple-vaccination-input';
import {Controller, useFieldArray, useFormContext} from 'react-hook-form';
import {AddAnotherButton, DatePicker, SingleDropdown} from '../../../../../components';
import {vaccines} from '../../../vaccination-form-elements';
import styles from './covid-vaccination.module.scss';
import {VaccinationRadioAnswers} from '../../../../enums';
import type {IOption} from '../../../../../types';
import {RedCloseIcon} from '../../../../../assets/icons';

type VaccinationDynamicSectionValue = {
  q_vaccination_record_covid19_vaccine: string;
  q_vaccination_record_covid19_date: string;
};

const sectionName = `covid`;

interface IProps {
  element: IVaccinationFormElement;
}

const CovidVaccinationInput: FC<IProps> = ({element}) => {
  const {control, watch, formState} = useFormContext();
  const {fields, append, remove} = useFieldArray<VaccinationDynamicSectionValue>({
    control,
    name: sectionName,
  });

  const defaultValues = useMemo(
    () => ({
      q_vaccination_record_covid19_vaccine: '',
      q_vaccination_record_covid19_date: '',
    }),
    [],
  );

  const sectionAdd = useCallback(() => {
    append(defaultValues);
  }, [append, defaultValues]);

  const sectionRemove = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  useEffect(() => {
    if (!!formState.dirtyFields[element.name] && !watch(sectionName).length) {
      sectionAdd();
    }
  }, [formState.dirtyFields, element.name, sectionAdd, watch]);

  return (
    <>
      <SimpleVaccinationInput element={element} />
      {watch(element.name) === VaccinationRadioAnswers.Yes &&
        fields.map((field, index) => (
          <Fragment key={field.id}>
            <div className={styles.vaccineContainer}>
              <Controller
                name={`${sectionName}.${index}.q_vaccination_record_covid19_vaccine` as const}
                control={control}
                render={({name, onChange, value}) => {
                  const options: Array<IOption<string>> = vaccines.map((vaccine) => ({
                    value: vaccine.value,
                    displayName: vaccine.label,
                  }));

                  const selectedValue = options.find((o) => o.value === value);

                  return (
                    <SingleDropdown
                      name={name}
                      ariaLabelTitle={element.ariaLabelTitle}
                      onChange={(option) => {
                        onChange(option.value);
                      }}
                      value={selectedValue}
                      className={styles.dropdown}
                      options={options}
                      disabled={watch(element.name) !== VaccinationRadioAnswers.Yes}
                    />
                  );
                }}
              />
              {index !== 0 && <RedCloseIcon className={styles.closeIcon} onClick={() => sectionRemove(index)} />}
            </div>
            <div className={styles.dates}>
              <div className={styles.dateContainer}>
                <Controller
                  name={`${sectionName}.${index}.q_vaccination_record_covid19_date` as const}
                  control={control}
                  render={({name, onChange, value}) => {
                    const currentValue = !!value ? new Date(value) : null;

                    return (
                      <DatePicker
                        name={name}
                        ariaLabelTitle={element.ariaLabelTitle}
                        value={currentValue}
                        onChange={onChange}
                        max={new Date()}
                        disabled={watch(element.name) !== VaccinationRadioAnswers.Yes}
                      />
                    );
                  }}
                />
              </div>
              {index === fields.length - 1 && <AddAnotherButton className={styles.addAnother} onClick={sectionAdd} />}
            </div>
          </Fragment>
        ))}
    </>
  );
};

export {CovidVaccinationInput};
export type {VaccinationDynamicSectionValue};
