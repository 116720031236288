import type {FC} from 'react';
import type {Control} from 'react-hook-form/dist/types/form';
import styles from './question.module.scss';
import {Controller} from 'react-hook-form';
import {RangeBar, RangeBarLabelsType, RangeBarStylingType} from '../../../components';
import type {IStrengthAssessmentFormElement} from '../strengths-assessment-form.component';
import type {StrengthsAssessmentModel} from '../../model';
import classNames from 'classnames';

const Question: FC<{
  formElement: IStrengthAssessmentFormElement;
  index: number;
  readOnly?: boolean;
  control: Control;
  lastFilledAssessment: StrengthsAssessmentModel | null;
  type?: RangeBarLabelsType;
}> = (props) => {
  const value: number | undefined = props.lastFilledAssessment?.items.find(
    (item) => item.question === props.formElement.name,
  )?.value;

  return (
    <div className={styles.field} key={props.index}>
      <div className={styles.label}>
        <div className={styles.index}>{`${props.index}.`}</div>
        {props.formElement.label}
      </div>
      <div
        className={classNames(styles.value, {
          [styles.numberType]: !props.readOnly && props.type !== RangeBarLabelsType.Strings,
        })}>
        {!props.readOnly ? (
          <Controller
            name={props.formElement.name}
            control={props.control}
            defaultValue={props.formElement.defaultValue}
            render={({onChange, value, name}) => (
              <RangeBar
                name={name}
                min={props.formElement.minValue}
                max={props.formElement.maxValue}
                value={value}
                onChange={onChange}
                stylingType={RangeBarStylingType.Slicing}
                displayingType={props.type}
                startLabel={props.formElement.startLabel}
                endLabel={props.formElement.endLabel}
                ariaLabelTitle={props.formElement.ariaLabelTitle}
                ariaLabelError={props.formElement.ariaLabelError}
              />
            )}
          />
        ) : (
          <>{value !== undefined ? value : ''}</>
        )}
      </div>
    </div>
  );
};

export {Question};
