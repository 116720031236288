import {DocumentType} from '../../enums';

class DocumentDetailsModel {
  constructor(
    public objectFileId = '',
    public labResultId = -1,
    public documentUploadDate: Date | null = null,
    public note = '',
    public documentType = DocumentType.LabResults,
    public otherNote = '',
    public mimeType = '',
    public cbc = {
      wbc: false,
      hemoglobin: false,
    },
    public bmp = {
      bun: false,
      creatinine: false,
    },
    public cmp = {
      albumin: false,
      alt: false,
      ast: false,
      bun: false,
      creatinine: false,
    },
    public liverFunction = {
      albumin: false,
      alt: false,
      ast: false,
    },
    public lipidPanel = {
      totalCholesterol: false,
      ldl: false,
      hdl: false,
      triglycerides: false,
    },
    public drugLevel = {
      cyclosporine: false,
      tacrolimus: false,
    },
    public renalFunction = {
      zun: false,
      creatinine: false,
    },
    public urinalysis = false,
    public tuberculosis = false,
  ) {}
}

export {DocumentDetailsModel};
