import type {MedicationsCourseModel, MedicationsModel, MedicationsReasonsDiscontinueModel} from '../model';
import {
  MedicationsCourseModelFactory,
  MedicationsModelFactory,
  MedicationsReasonsDiscontinueModelFactory,
} from '../model';
import type {IMedicationsRepository, IMedicationsRepositoryQuery} from './medications.repository.interface';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {inject, injectable} from 'inversify';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {
  IMedicationsCourseDiscontinueGetResponseDto,
  IMedicationsCourseGetResponseDto,
  IMedicationsGetResponseDto,
  IMedicationsReasonsDiscontinueGetResponseDto,
  MedicationPostRequestDto,
} from './dto';
import {MedicationsCourseDiscontinuePostRequestDtoFactory, MedicationsCoursePostRequestDtoFactory} from './dto';
import type {IPaginationResponseDto} from '../../types';
import {MedicationPostRequestDtoFactory} from './dto/medication-post-request.dto.factory';
import {MedicationsCoursePutRequestDtoFactory} from './dto/medications-course-put-request.dto.factory';

@injectable()
class MedicationsRepository implements IMedicationsRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  find(query?: Partial<IMedicationsRepositoryQuery>): Promise<MedicationsCourseModel | null> {
    throw new NotImplementedException();
  }

  async list(query?: Partial<IMedicationsRepositoryQuery>): Promise<MedicationsCourseModel[]> {
    if (query?.discontinued) {
      return this.getCoursesDiscontinue(query.limit, query.offset);
    } else {
      return this.getCourses();
    }
  }

  remove(domain: MedicationsCourseModel): Promise<void> {
    throw new NotImplementedException();
  }

  async save(domain: MedicationsCourseModel): Promise<void> {
    if (!domain.discontinue) {
      if (!!domain.id && domain.id > 0) {
        const dto = MedicationsCoursePutRequestDtoFactory.fromModel(domain);
        await this._http.put<IMedicationsCourseDiscontinueGetResponseDto>('Medication/course', dto);
      } else {
        const dto = MedicationsCoursePostRequestDtoFactory.fromModel(domain);
        await this._http.post<IMedicationsCourseDiscontinueGetResponseDto>('Medication/course', dto);
      }
    } else {
      const dto = MedicationsCourseDiscontinuePostRequestDtoFactory.fromModel(domain);

      await this._http.post<IMedicationsCourseDiscontinueGetResponseDto>('Medication/course/discontinue', dto);
    }
  }

  async listMedications(): Promise<MedicationsModel[]> {
    const {data} = await this._http.get<IMedicationsGetResponseDto[]>('Medication');

    return data.map((i) => MedicationsModelFactory.fromGetResponseDto(i));
  }

  async saveMedication(medication: MedicationsModel): Promise<number | null> {
    let res: number | null = null;

    if (medication.id < 0) {
      await this._http
        .post<never, MedicationPostRequestDto>('Medication', MedicationPostRequestDtoFactory.fromModel(medication))
        .then((data) => (res = data.data));
    }

    return res;
  }

  async getCourses(): Promise<MedicationsCourseModel[]> {
    const {data} = await this._http.get<IMedicationsCourseGetResponseDto[]>(`Medication/course`);

    return data?.map((i) => MedicationsCourseModelFactory.fromGetResponseDto(i)).sort((a, b) => a.id - b.id);
  }

  async getCoursesDiscontinue(limit?: number, offset?: number): Promise<MedicationsCourseModel[]> {
    const params = new URLSearchParams();

    if (offset !== undefined) {
      params.set('pageNumber', String(offset));
    }

    if (limit !== undefined) {
      params.set('pageSize', String(limit));
    }

    const paramsStr: string = params.toString();

    const {
      data: {records},
    } = await this._http.get<IPaginationResponseDto<IMedicationsCourseDiscontinueGetResponseDto>>(
      `Medication/course/discontinued?${paramsStr}`,
    );

    return records?.map((i) => MedicationsCourseModelFactory.fromDiscontinueResponseDto(i));
  }

  async getReasonsDiscontinue(): Promise<MedicationsReasonsDiscontinueModel[]> {
    const {data} = await this._http.get<IMedicationsReasonsDiscontinueGetResponseDto[]>(
      `Medication/course/discontinuereasons`,
    );

    return data?.map((i) => MedicationsReasonsDiscontinueModelFactory.fromGetResponseDto(i));
  }
}

export {MedicationsRepository};
