import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import styles from './notifications-list.module.scss';
import {Notification} from '../notification';
import {Card} from '../../../components';
import type {NotificationModel} from '../../model';
import {DEFAULT_FORMAT} from '../../../helpers';
import {CheckMarkSelected, CheckMarkWithCheck} from '../../../assets/icons';

interface INotificationListProps {
  notifications: Array<NotificationModel>;
  readStatus: boolean;
  readAllNotifications(status: boolean): void;

  expandNotification(id: number, isRead: boolean): void;
  expandedNotification?: number;
}

const NotificationList: FC<INotificationListProps> = (props) => {
  const [selectedList, setSelectedList] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (selectAll) {
      setSelectedList(props.notifications.map(({notificationId}) => notificationId));
    } else {
      setSelectedList([]);
    }
  }, [selectAll, props.notifications]);

  const selectHandler = (notificationId: number) => {
    const idIndex = selectedList.indexOf(notificationId);
    const selectedListCopy = [...selectedList];
    idIndex !== -1 ? selectedListCopy.splice(idIndex, 1) : selectedListCopy.push(notificationId);
    setSelectedList(selectedListCopy);
  };

  return (
    <Card borderLess>
      <div className={styles.title}>
        <div onClick={() => !!props.notifications.length && setSelectAll(!selectAll)} data-testid={'select-all'}>
          {selectAll ? <CheckMarkSelected /> : <CheckMarkWithCheck />}
        </div>{' '}
        {props.readStatus ? 'Read' : 'Unread'}
        {!props.readStatus && selectAll && !!props.notifications.length && (
          <p
            onClick={() => {
              props.readAllNotifications(!props.readStatus);
              setSelectAll(!selectAll);
            }}
            data-testid={'make-all-selected'}>
            Mark selected as read
          </p>
        )}
      </div>
      <div className={styles.items}>
        {props.notifications.map((notification) => (
          <Notification
            text={notification.text}
            title={notification.title}
            key={notification.notificationId}
            date={notification.notificationDate}
            isRead={notification.isRead}
            isSelected={selectedList.some((id) => id === notification.notificationId)}
            changeSelect={() => {
              selectHandler(notification.notificationId);
            }}
            id={notification.notificationId}
            dateOptions={{lowerCase: false, format: DEFAULT_FORMAT}}
            flag={notification.flag}
            notificationId={notification.notificationId}
            expandedNotification={props.expandedNotification}
            expandNotification={props.expandNotification}
          />
        ))}
        {!props.notifications.length && (
          <div className={styles.noNotificationsContainer} data-testid={'empty-container'}>
            {props.readStatus ? (
              <div className={styles.noNotificationsText} data-testid={'empty-read'}>
                No notifications match these criteria
              </div>
            ) : (
              <div className={styles.noNotificationsText} data-testid={'empty-unread'}>
                All caught up!
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export type {INotificationListProps};
export {NotificationList};
