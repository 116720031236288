import type {FC} from 'react';
import React from 'react';
import {LabTests} from '../../../enums';
import type {Control} from 'react-hook-form/dist/types/form';
import type {FieldErrors} from 'react-hook-form';
import {LabResultsCommonResultElement} from '../common-result-element';
import {LabResultsCrpResultElement} from '../crp-result-element';
import type {LabResultsFormDto} from '../../form-dto';

const LabResultsResultElement: FC<{
  testType: LabTests;
  control: Control;
  errors: FieldErrors<LabResultsFormDto>;
}> = (props) => {
  let elementToRender: JSX.Element = <></>;

  switch (props.testType) {
    case LabTests.CRP:
      elementToRender = <LabResultsCrpResultElement errors={props.errors} control={props.control} />;
      break;
    case LabTests.FecalCalprotectin:
    case LabTests.VitaminD:
    case LabTests.Hemoglobin:
    case LabTests.Albumin:
      elementToRender = (
        <LabResultsCommonResultElement errors={props.errors} type={props.testType} control={props.control} />
      );
      break;
    default:
      break;
  }

  return elementToRender;
};

export {LabResultsResultElement};
