import React from 'react';
import type {IconPropsLegacy} from './icon-legacy.types';
import {IconTypeLegacy} from './icon-legacy.types';

import {ReactComponent as File} from './icons/ic_file.svg';
import {ReactComponent as Close} from './icons/ic_close.svg';
import {ReactComponent as CloudDownloadBig} from './icons/ic_cloud-download-big.svg';
import {ReactComponent as CloudDownload} from './icons/ic_cloud-download.svg';
import {ReactComponent as ClientInfoIcon} from './icons/ic_client-info.svg';
import {ReactComponent as PatientInfoIcon} from './icons/ic_patient-info.svg';
import {ReactComponent as AttachmentIcon} from './icons/ic_att.svg';

const IconLegacy: React.FC<IconPropsLegacy> = (props) => {
  const {type, ...otherProps} = props;
  switch (type) {
    case IconTypeLegacy.File:
      return <File {...otherProps} />;
    case IconTypeLegacy.Close:
      return <Close {...otherProps} />;
    case IconTypeLegacy.CloudDownload:
      return <CloudDownload {...otherProps} />;
    case IconTypeLegacy.CloudDownloadBig:
      return <CloudDownloadBig {...otherProps} />;
    case IconTypeLegacy.ClientInfo:
      return <ClientInfoIcon {...otherProps} />;
    case IconTypeLegacy.PatientInfo:
      return <PatientInfoIcon {...otherProps} />;
    case IconTypeLegacy.Attachment:
      return <AttachmentIcon {...otherProps} />;
    default:
      return null;
  }
};

export {IconLegacy};
