import type {IMedicationsCourseDiscontinueGetResponseDto} from '../repository';
import {MedicationsCourseDiscontinueModel} from './medications-course-discontinue.model';
import {EnumCommentUnionParseHelper, shiftTimezone} from '../../helpers';
import type {IMedicationsCourseDiscontinueFormDto} from '../components';

class MedicationsCourseDiscontinueModelFactory {
  static fromGetResponseDto(dto: IMedicationsCourseDiscontinueGetResponseDto): MedicationsCourseDiscontinueModel {
    const reason = EnumCommentUnionParseHelper(dto.reason, dto.reasonOther);

    return new MedicationsCourseDiscontinueModel(
      dto.discontinuedDate ? shiftTimezone(new Date(dto.discontinuedDate)) : null,
      reason,
      dto.discontinuedBy,
      shiftTimezone(new Date(dto.lastUpdated)),
    );
  }

  static fromCourseDiscontinueFormDto(dto: IMedicationsCourseDiscontinueFormDto): MedicationsCourseDiscontinueModel {
    const reason = EnumCommentUnionParseHelper(dto.reason, dto.reasonOther);

    return new MedicationsCourseDiscontinueModel(dto.discontinued, reason);
  }
}

export {MedicationsCourseDiscontinueModelFactory};
