import type {EncounterRepositoryQuery, IEncounterRepository} from './encounter.repository.interface';
import {EncounterRepositoryQuerySort} from './encounter.repository.interface';
import type {EncounterDetailsModel, EncounterModel} from '../models';
import {EncounterDetailsModelFactory, EncounterModelFactory} from '../models';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {EncounterListRequestDto} from './dto';
import {format} from 'date-fns';
import {FULL_FORMAT} from '../../helpers';
import type {IPaginationResponseDto} from '../../types';
import type {IEncounterDetailsGetResponseDto, IEncounterGetResponseDto} from '../dto';

@injectable()
class EncounterRepository implements IEncounterRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  find(query?: Partial<EncounterRepositoryQuery>): Promise<EncounterModel | null> {
    throw new NotImplementedException();
  }

  async list(query?: Partial<EncounterRepositoryQuery>): Promise<EncounterModel[]> {
    if (!query?.patientId) {
      throw new Error('no patient id');
    }

    const dto = new EncounterListRequestDto(
      query.offset,
      query.limit,
      query.orderBy,
      query.sort === EncounterRepositoryQuerySort.ASC,
      query.patientId,
      query.authorId,
      query.updatedLast ? format(query.updatedLast, FULL_FORMAT) : undefined,
    );

    const response = await this._http.post<IPaginationResponseDto<IEncounterGetResponseDto>>(`Encounter/search`, dto);

    return response.data.records?.map((i) => EncounterModelFactory.fromGetResponseDto(i));
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  save(): Promise<void> {
    throw new NotImplementedException();
  }

  async findDetails(query?: Partial<{encounterId: number}>): Promise<EncounterDetailsModel> {
    if (!query?.encounterId) {
      throw new Error('no encounter details id');
    }

    const {data} = await this._http.get<IEncounterDetailsGetResponseDto>(`EncounterSummary/${query.encounterId}`);

    return EncounterDetailsModelFactory.fromGetResponseDto(data);
  }
}

export {EncounterRepository};
