import type {FC, KeyboardEventHandler} from 'react';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import styles from './chat-card.module.scss';
import {
  Avatar,
  Button,
  CardContent,
  FileUploaderErrorType,
  FileUploaderLegacy,
  FileUploaderType,
  Icon,
  IconType,
  RichTextComponent,
} from '../../../../../components';
import {IconTypeLegacy} from '../../../../../components/icon-legacy';
import {Chat} from '../chat';
import {Controller, useForm} from 'react-hook-form';
import {ArrowLeftIcon} from '../../../../../assets/icons';
import type {MessageModel, MessageThreadRecipientModel} from '../../../../../message';
import type {IFileStorageService} from '../../../../../file-storage';
import {FILE_STORAGE_SERVICE} from '../../../../../file-storage';
import {getFileDataUrl} from '../../../../../file-storage/helpers';
import {useInjection} from '../../../../../ioc';
import {FileStorageModel, FileType} from '../../../../../file-storage/model';
import {ButtonType} from '../../../../../components/button-legacy';
import type {INotifierService} from '../../../../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../../../../notifier';
import type {IPopupService} from '../../../../../utils/popup';
import {POPUP_SERVICE} from '../../../../../utils/popup';
import {PopupNotificationType} from '../../../../../account';
import {MessagingDisabledModal} from '../../../dashboard/components';
import {parseRichText} from '../../../../../helpers';
import {getNthWord} from '../../utils';

const MAX_FILE_SIZE_IN_MEGABYTES = 5;

interface IChatCardProps {
  messages: MessageModel[];
  recipient: MessageThreadRecipientModel;
  onSend: (message: string, attachmentObjectFileIds: Array<string>) => void;
  onClose: () => void;
  readonly: boolean;
  renderInput: boolean;
}

interface IFormData {
  text: string;
}

interface IService {
  fileStorage: IFileStorageService;
  notifier: INotifierService;
  popup: IPopupService;
}

const PLACEHOLDER_WORD_COUNT = 5;

const ChatCard: FC<IChatCardProps & IService> = ({
  messages,
  popup,
  onClose,
  onSend,
  recipient: {avatar, id, name, type},
  fileStorage,
  notifier,
  readonly = false,
  renderInput = true,
}) => {
  const {control, handleSubmit, reset, setValue} = useForm();
  const notification = popup.getPopupNotification(PopupNotificationType.PatientReadyForThriveMessagesPage);

  const [attachments, setAttachments] = useState<Array<{id: string; name: string; mimeType: string}>>([]);
  const [openMessagingDisabledModal, setOpenMessagingDisabledModal] = useState<boolean>(false);
  const bottomChatRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    bottomChatRef.current?.scrollIntoView &&
      bottomChatRef.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
  }, [bottomChatRef]);

  const onSubmit = (data: IFormData) => {
    if (!data.text && !attachments.length) {
      return;
    }
    onSend(
      data.text,
      attachments.map((a) => a.id),
    );
    setValue('text', '');
    reset();
    setAttachments([]);
  };

  const handleAttachmentAdd = useCallback(
    (file: File, content: string) => {
      fileStorage
        .upload(new FileStorageModel(content, file.name, file.type, '', FileType.MessageAttachment))
        .then((id: string) => {
          setAttachments([...attachments, {id, name: file.name, mimeType: file.type}]);
        });
    },
    [attachments, setAttachments, fileStorage],
  );

  const handleAttachmentRemove = useCallback(
    (id: string) => {
      setAttachments(attachments.filter((i) => i.id !== id));
    },
    [attachments, setAttachments],
  );

  const toggleModal = useCallback((value: boolean) => {
    setOpenMessagingDisabledModal(value);
  }, []);

  const submit = handleSubmit<IFormData>(onSubmit);

  const onEnterPress: KeyboardEventHandler = (e) => {
    if (e.code === 'Enter' && e.ctrlKey) {
      return submit();
    }
  };

  const renderAttachments = useMemo(
    () =>
      attachments.map((attachment) => {
        const fileData = fileStorage.files.get(attachment.id);

        return (
          <div className={styles.attachment} key={attachment.id}>
            {attachment.mimeType.indexOf('image') >= 0 ? (
              <img
                src={fileData ? getFileDataUrl(fileData) : attachment.name}
                alt={attachment.name}
                className={styles.imageAttachment}
              />
            ) : (
              <div className={styles.linkAttachment} onClick={() => fileStorage.download(attachment.id)}>
                {attachment.name}
              </div>
            )}
            <div
              data-testid={'removeAttachment'}
              className={styles.removeAttachment}
              onClick={() => handleAttachmentRemove(attachment.id)}
            />
          </div>
        );
      }),
    [fileStorage, attachments, handleAttachmentRemove],
  );

  useEffect(() => {
    setValue('text', '');
    reset();
    setAttachments([]);
  }, [id, reset, setValue]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, messages]);

  return (
    <div>
      {openMessagingDisabledModal && (
        <MessagingDisabledModal onClose={() => toggleModal(false)} checkboxShown={false}>
          {<RichTextComponent text={parseRichText(notification?.text ?? '')} />}
        </MessagingDisabledModal>
      )}
      <div className={classNames(styles.header)}>
        <CardContent>
          <div className={styles.headerContent}>
            <div className={styles.authorInfo}>
              <div className={styles.back} onClick={onClose}>
                <ArrowLeftIcon />
              </div>
              <Avatar className={styles.authorInfoAvatar} alt={name} src={avatar} size={35} />
              <p className={styles.authorInfoTitle}>{name}</p>
              <p className={styles.authorInfoType}>{type}</p>
            </div>
          </div>
        </CardContent>
      </div>
      <div className={classNames(styles.content)}>
        <div className={classNames(styles.contentWrapper, !renderInput && styles.contentWrapperNoInput)}>
          <CardContent>
            <Chat messages={messages} />
          </CardContent>
          <div ref={bottomChatRef} />
        </div>
        {renderInput && (
          <form onSubmit={submit}>
            <div className={styles.formContent}>
              {!!notification && readonly && <div onClick={() => toggleModal(true)} className={styles.mockInput} />}
              {!readonly && (
                <FileUploaderLegacy
                  className={styles.fileUpload}
                  accept={'.pdf, .jpg, .doc, .docx, .gif, .png'}
                  errorAcceptMessage={'Only PDF, JPG, DOC, DOCX, Gif, and PNG files can be uploaded.'}
                  maxSizeBytes={MAX_FILE_SIZE_IN_MEGABYTES * 1024 * 1024}
                  errorMaxSizeMessage={`All uploaded files must be less than ${MAX_FILE_SIZE_IN_MEGABYTES}mb. Please re-size the file and try again`}
                  dragText=""
                  uploadText=""
                  removeActionText=""
                  submitActionText=""
                  uploadNewFileActionText=""
                  onUploadFile={handleAttachmentAdd}
                  uploadedFile=""
                  type={FileUploaderType.Button}
                  buttonType={ButtonType.Icon}
                  iconType={IconTypeLegacy.Attachment}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onRemoveFile={() => {}}
                  onError={(errorType, message) => {
                    notifier.notify({
                      title: errorType === FileUploaderErrorType.WrongFileType ? 'Invalid file format' : 'Upload error',
                      text: message,
                      type: NotifyMessageTypeEnum.Danger,
                    });
                  }}>
                  <Icon width={24} height={24} type={IconType.Attachment} />
                </FileUploaderLegacy>
              )}
              <div className={styles.inputContainer}>
                <Controller
                  control={control}
                  name={'text'}
                  defaultValue={''}
                  render={({value, onChange}) => (
                    <textarea
                      data-testid={'textarea'}
                      rows={1}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      className={styles.input}
                      placeholder={
                        !!notification && readonly
                          ? `${getNthWord(notification.text, PLACEHOLDER_WORD_COUNT)}...`
                          : 'Type a message...'
                      }
                      aria-label="Type a message"
                      autoComplete={'off'}
                      onKeyPress={onEnterPress}
                      disabled={readonly}
                      spellCheck
                    />
                  )}
                />
                <div className={styles.attachments}>{renderAttachments}</div>
              </div>
              <Button
                className={styles.sendButton}
                type={'submit'}
                iconSize={24}
                icon={IconType.Send}
                disabled={readonly}
                flat
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

const ChatCardInjected: FC<IChatCardProps> = (props) => (
  <ChatCard
    notifier={useInjection(NOTIFIER_SERVICE)}
    fileStorage={useInjection(FILE_STORAGE_SERVICE)}
    popup={useInjection(POPUP_SERVICE)}
    {...props}
  />
);

export {ChatCard, ChatCardInjected};
export type {IChatCardProps};
