import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IMedicationsService} from '../service';
import {MEDICATIONS_SERVICE, MedicationsService} from '../service';
import type {IMedicationsRepository} from '../repository';
import {MEDICATIONS_REPOSITORY, MedicationsRepository} from '../repository';

const MedicationsModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IMedicationsRepository>(MEDICATIONS_REPOSITORY).to(MedicationsRepository).inSingletonScope();
  bind<IMedicationsService>(MEDICATIONS_SERVICE).to(MedicationsService).inSingletonScope();
});

export {MedicationsModule};
