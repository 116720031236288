import type {IProfileSettingsRepository} from './profile-settings.repository.interface';
import type {ProfileSettingsModel} from '../model';
import {ProfileSettingsModelFactory} from '../model';
import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {IProfileSettingsGetResponseDto, IProfileSettingsGetTimezonesResponseDto} from './dto';
import {ProfileSettingsPutRequestDtoFactory} from './dto';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';

@injectable()
class ProfileSettingsRepository implements IProfileSettingsRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(): Promise<ProfileSettingsModel | null> {
    const {data: settingsDto} = await this._http.get<IProfileSettingsGetResponseDto>(
      'UserAccountSettings/getcurrentuseraccountsettings',
    );

    const {data: timeZonesDto} = await this._http.get<Array<IProfileSettingsGetTimezonesResponseDto>>(
      'UserAccountSettings/gettimezonelist',
    );

    return settingsDto && timeZonesDto ? ProfileSettingsModelFactory.fromGetDtos(settingsDto, timeZonesDto) : null;
  }

  async save(model: ProfileSettingsModel): Promise<ProfileSettingsModel> {
    const putDto = ProfileSettingsPutRequestDtoFactory.fromModel(model);
    await this._http.put<IProfileSettingsGetResponseDto>('UserAccountSettings/updateuseraccountsettings', putDto);

    return model;
  }

  list(): Promise<ProfileSettingsModel[]> {
    throw new NotImplementedException();
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async requestResetPassword(): Promise<void> {
    await this._http.post<void>('UserAccount/forgotpassword', {});
  }

  async requestResetPhone(code: string, phone: string): Promise<void> {
    await this._http.post<void>(`Patient/updatephonenumber`, {
      phoneNumber: phone,
      verificationCode: code,
    });
  }

  async requestPhoneVerifying(): Promise<string> {
    const {data} = await this._http.get<{phone: string}>('Verify/info', {});

    return data?.phone;
  }

  async requestCode(): Promise<void> {
    await this._http.post<void>('Verify/code', {});
  }

  async requestUserNameValidation(userName: string): Promise<string> {
    const data = await this._http.post<void>(`UserAccount/usernameavailability?userName=${userName}`, {});

    return data.request?.response;
  }

  async sendUserName(userName: string, code: string): Promise<void> {
    await this._http.put<void>('UserAccount/username', {userName, code});
  }

  async requestEmailValidation(email: string): Promise<string> {
    const data = await this._http.post<void>(`UserAccount/emailavailability`, {email});

    return data.request?.response;
  }

  async sendNewEmail(email: string, code: string): Promise<void> {
    await this._http.put<void>('UserAccount/sendconfirmationlinkonnewemailaddress', {email, code});
  }

  async confirmEmail(token: string): Promise<void> {
    await this._http.put<void>('UserAccount/confirmnewemail', {token});
  }

  async updateWelcomeToThriveWatched(welcomeToThriveIsWatched = true): Promise<void> {
    await this._http.put(
      `UserAccountSettings/updatewelcometothrive?welcomeToThriveIsWatched=${welcomeToThriveIsWatched}`,
      {},
    );
  }
}

export {ProfileSettingsRepository};
