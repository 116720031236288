class MessageThreadPostDto {
  constructor(
    /**
     * array of account id
     */
    public recipients: number[] = [],
    public welcomeMessage = '',
    public attachmentObjectFileIds: string[] = [],
    public tempId: string = '',
  ) {}
}

export {MessageThreadPostDto};
