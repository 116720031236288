import type {LabResultsModel} from '../../model';
import {LabResultsFormDto} from './lab-results-form.dto';
import {CrpExpression, TuberculosisTypes, UnitsTypesEnum} from '../../enums';

class LabResultsFormDtoFactory {
  static fromModel(model: LabResultsModel): LabResultsFormDto {
    return new LabResultsFormDto(
      model.labDate ?? new Date(),
      model.labTest ?? undefined,
      model.comparison ?? CrpExpression.Blank,
      model.resultValue ?? 0,
      model.resultType ?? TuberculosisTypes.Negative,
      model.unitsType ?? UnitsTypesEnum.Blank,
    );
  }
}

export {LabResultsFormDtoFactory};
