import classNames from 'classnames';
import type { FC, SVGProps } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import type { IAlertIndicatorService } from '../../../../alert-indicator';
import { MenuIcon, NotificationIcon, RightSidebarIcon } from '../../../../assets/icons';
import { LogoImage } from '../../../../assets/logo';
import { Avatar, Card, Image } from '../../../../components';
import { routes } from '../../../../routing';
import styles from './top-nav-panel.module.scss';

import { TourGuide } from '../../../../tourguide';

interface INavigationItemAbstract {
  icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
}

interface INavigationItemLink extends INavigationItemAbstract {
  link: string;
}

interface INavigationItemButton extends INavigationItemAbstract {
  callBack: () => void;
}

type INavigationItem = INavigationItemLink | INavigationItemButton;

const isINavigationItemLink = (nav: INavigationItem): nav is INavigationItemLink => 'link' in nav;

interface IProps {
  className?: string;
  appName: string;
  user?: {
    name: string;
    avatar?: string;
  };
  navItems: Array<INavigationItem>;
  helpLink?: string;
  onMenuToggle: () => void;
  onRightPanelOpen: () => void;
  isRightPanelOpen: boolean;
  isRightSidebarAvailable: boolean;
  alertIndicator: IAlertIndicatorService;
}

const NavItem: FC<INavigationItem> = (navItemProps) => {
  const Icon = navItemProps.icon;

  return (
    <li className={styles.navListItem}>
      {isINavigationItemLink(navItemProps) ? (
        <Link
          className={classNames(styles.navListItemContainer, styles.navListItemLink)}
          to={navItemProps.link}
          title={navItemProps.title}>
          <Icon className={styles.navListItemIcon} /> {navItemProps.title}
        </Link>
      ) : (
        <button
          className={classNames(styles.navListItemContainer, styles.navListItemButton)}
          onClick={navItemProps.callBack}>
          <Icon className={styles.navListItemIcon} /> {navItemProps.title}
        </button>
      )}
    </li>
  );
};

const TopNavPanel: FC<IProps> = (props) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const openMenu = useCallback(() => setIsMenuOpened(true), []);
  const closeMenu = useCallback(() => setIsMenuOpened(false), []);

  const navItems = useMemo(() => props.navItems.map((i) => <NavItem key={i.title} {...i} />), [props.navItems]);

  return (
    <header className={classNames(props.className, styles.root)}>
      <button id="leftmenu-button" className={styles.menuButton} onClick={props.onMenuToggle}>
        <MenuIcon className={styles.menuIcon} />
      </button>
      <Link to={routes.home}>
        <Image className={styles.logo} src={LogoImage} alt={props.appName} />
      </Link>

      {!!props.user && (
        <div className={classNames(styles.profile, styles.navOpen)}>
          <div className={styles.userWrap}>
            <div
              className={classNames(styles.rightPanelIconWrap, {
                [styles.rightPanelActiveIconWrap]: props.isRightPanelOpen,
              })}
              onClick={props.onRightPanelOpen}>
              {props.isRightSidebarAvailable && <RightSidebarIcon />}
            </div>
            <TourGuide />
            <Link to={`${routes.home}${routes.notifications}`} className={styles.notificationLink}>
              <div id="notifications">
                <NotificationIcon />
                {!!props.alertIndicator?.hasUnreadNotifications && <div className={styles.bubble} />}
              </div>
            </Link>
            <Avatar
              id="profile-avatar"
              className={styles.profileAvatar}
              size={38}
              alt={props.user.name}
              src={props.user.avatar}
              onClick={openMenu}
            />
          </div>

          {isMenuOpened && (
            <nav className={styles.nav} onMouseLeave={closeMenu}>
              <Card className={styles.navCard} borderLess>
                <ul className={styles.navList}>{navItems}</ul>
              </Card>
            </nav>
          )}
        </div>
      )}
    </header>
  );
};

export { TopNavPanel };
export type { INavigationItem };

