interface IEncounterAssessmentAndPlanSection {
  targets: Array<string>;
  goals: string;
  interventions: Array<{
    option: string;
    description: string;
  }>;
}

class EncounterDetailsModel {
  constructor(
    public id = 0,
    public patientId = 0,
    public dateVisit = new Date(),
    public type = '',
    public providerFullName = '',
    public providerTitle = '',
    public progressNotes = '',
    public visitDuration = 0,
    public nextRecommendedVisitInfo = '',
    public skills: string[] = [],
    public assessmentAndPlan: IEncounterAssessmentAndPlanSection | null = null,
  ) {}
}

export {EncounterDetailsModel};
export type {IEncounterAssessmentAndPlanSection};
