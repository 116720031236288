interface IEmotionalImpactScaleItem {
  question: string;
  value: string;
}

class EmotionalImpactScaleModel {
  constructor(
    public id: number = -1,
    public patientId: number = -1,
    public items: IEmotionalImpactScaleItem[] = [],
    public lastUpdated: Date | null = null,
    public name: string = '',
    public date: string = '',
  ) {}
}

export {EmotionalImpactScaleModel};
export type {IEmotionalImpactScaleItem};
