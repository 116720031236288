import classNames from 'classnames';
import {uniqueId} from 'lodash';
import type {KeyboardEventHandler} from 'react';
import React, {useCallback} from 'react';
import styles from './toggle-switch.module.scss';

export interface IToggleSwitchProps {
  id?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  name?: string;
  optionLabels?: [];
  small?: boolean;
  disabled?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const Toggle: React.FC<IToggleSwitchProps> = ({
  id,
  name,
  value,
  onChange,
  optionLabels = ['On', 'Off'],
  small,
  disabled,
  inputRef,
  ariaLabelTitle,
  ariaLabelError,
}) => {
  id = id ?? uniqueId('id-');

  const onEnterPress: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        onChange(!value);
      }
    },
    [value, onChange],
  );

  const title = ariaLabelTitle ?? name;
  const error = !!ariaLabelError ? `${ariaLabelError}.` : '';

  return (
    <div
      className={classNames(styles.toggleSwitch, {
        [styles.smallSwitch]: small,
        [styles.switchOn]: value,
        [styles.switchOff]: !value,
        [styles.Disabled]: disabled,
      })}>
      <input
        id={id}
        type="checkbox"
        name={name}
        className={classNames(styles.toggleSwitchCheckbox)}
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
        ref={inputRef}
        tabIndex={-1}
      />
      {id ? (
        <div
          aria-label={`${error} ${title}. Option: ${value}`}
          className={classNames(styles.toggleSwitchLabel, {[styles.Disabled]: disabled})}
          role="checkbox"
          aria-checked={value}
          onKeyPress={onEnterPress}
          onClick={() => onChange(!value)}
          tabIndex={disabled ? -1 : 0}>
          <span
            className={classNames(styles.toggleSwitchInner, {[styles.toggleSwitchDisabled]: disabled})}
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
          />
          <span className={classNames(styles.toggleSwitchSwitch, {[styles.toggleSwitchDisabled]: disabled})} />
        </div>
      ) : null}
    </div>
  );
};

const ToggleSwitch = React.forwardRef((props: IToggleSwitchProps, ref?: React.Ref<HTMLInputElement>) => (
  <Toggle inputRef={ref} {...props} />
));

export {ToggleSwitch};
