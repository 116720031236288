import {ContainerModule} from 'inversify';
import type {IBodyMassIndexService} from '../service';
import {BODY_MASS_INDEX_SERVICE, BodyMassIndexService} from '../service';
import type {IBodyMassIndexRepository} from '../repository';
import {BODY_MASS_INDEX_REPOSITORY, BodyMassIndexRepository} from '../repository';
import {HTTP_SERVICE} from '../../utils/http';

const BodyMassIndexModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IBodyMassIndexRepository>(BODY_MASS_INDEX_REPOSITORY).to(BodyMassIndexRepository).inSingletonScope();

  bind<IBodyMassIndexService>(BODY_MASS_INDEX_SERVICE).to(BodyMassIndexService).inSingletonScope();
});

export {BodyMassIndexModule};
