import {ContainerModule} from 'inversify';
import {MonitoringService} from '../datadog/datadog.service';
import type {IDatadogService} from '../datadog/datadog.service.interface';
import {DATADOG_SERVICE} from '../datadog/datadog.service.provider';

const ServicesModule = new ContainerModule((bind) => {
  bind<IDatadogService>(DATADOG_SERVICE).to(MonitoringService).inSingletonScope();
  // Add other services
});

export {ServicesModule};
