import {useMemo} from 'react';

interface IGraphicValues {
  markerPosition: number;
}

const parseGraphicValues = (min: number, max: number, val: number): IGraphicValues => {
  const total = Math.abs(max) + Math.abs(min);
  /**
   * avoid marker walks through the borders
   */
  const saveValue = val > max ? max : val < min ? min : val;
  const markerPosition = (saveValue / total) * 100;

  return {markerPosition};
};

const useGraphicValues = (min: number, max: number, val: number): IGraphicValues =>
  useMemo(() => parseGraphicValues(min, max, val), [max, min, val]);

export {useGraphicValues, parseGraphicValues};
