import {EMotivationType} from '../interfaces';

export const mapTypeToMotivation = (type: number) => {
  const typeMap: Record<number, EMotivationType> = {
    0: EMotivationType.CARROT,
    1: EMotivationType.STICK,
  };

  return typeMap[type] || undefined;
};
