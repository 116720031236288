import type {FC} from 'react';
import React from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import styles from './medications-course-discontinue-form.module.scss';
import {Button, CardActions, DatePicker, DatePickerInputType, DatePickerView, Error} from '../../../components';
import {MedicationsReasonElement} from '../reason-element';
import {MedicationsCardRow} from '../card-row';
import {MedicationsCardWrapper} from '../card-wrapper';
import type {IMedicationsCourseDiscontinueFormDto} from './medications-course-discontinue-form.dto';
import type {MedicationsReasonsDiscontinueModel} from '../../model';
import {MedicationsReasonElementType} from '../reason-element/medications-reason-element-type.enum';
import {REQUIRED_FIELD_ERROR} from '../../../helpers';

interface IMedicationsCourseDiscontinueFormProps {
  reasons: ReadonlyArray<MedicationsReasonsDiscontinueModel>;
  onClose: () => void;
  onSubmit: (data: IMedicationsCourseDiscontinueFormDto) => void;
}

const MedicationsCourseDiscontinueForm: FC<IMedicationsCourseDiscontinueFormProps> = ({reasons, onClose, onSubmit}) => {
  const {reset, ...formMethods} = useForm<IMedicationsCourseDiscontinueFormDto>({mode: 'onChange'});

  return (
    <FormProvider {...formMethods} reset={reset}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className={styles.formElements}>
          <MedicationsCardRow>
            <MedicationsCardWrapper label={'Date of discontinue'} className={styles.discontinuedDate}>
              <Controller
                name={'discontinued'}
                control={formMethods.control}
                rules={{required: REQUIRED_FIELD_ERROR}}
                render={({value, name, onChange}) => (
                  <DatePicker
                    name={name}
                    value={value}
                    onChange={onChange}
                    inputType={DatePickerInputType.Medications}
                    view={DatePickerView.Selector}
                    numberYearsFuture={1}
                    ariaLabelError={formMethods.formState.errors['discontinued']?.message}
                    ariaLabelTitle={'Date of discontinue'}
                  />
                )}
              />
              {!!formMethods.formState.errors['discontinued']?.message && (
                <div className={styles.error}>
                  <Error errorMessage={formMethods.formState.errors['discontinued'].message} />
                </div>
              )}
            </MedicationsCardWrapper>
          </MedicationsCardRow>
          <MedicationsCardRow>
            <MedicationsCardWrapper>
              <MedicationsReasonElement elementType={MedicationsReasonElementType.RadioButton} reasons={reasons} />
            </MedicationsCardWrapper>
          </MedicationsCardRow>
        </div>
        <CardActions>
          <Button flat onClick={onClose}>
            Cancel
          </Button>
          <Button bold disabled={!formMethods.formState.isValid} type="submit">
            Confirm
          </Button>
        </CardActions>
      </form>
    </FormProvider>
  );
};

export {MedicationsCourseDiscontinueForm};
