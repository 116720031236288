import type {FC} from 'react';
import React from 'react';
import type {BodyMassIndexModel, BodyMassIndexUnitEnum} from '../../model';
import {Card, CardContent} from '../../../components';
import {BodyMassIndexListItem} from './components';
import styles from './body-mass-index-list.module.scss';

interface IBodyMassIndexListProps {
  records: BodyMassIndexModel[];
  selectedUnit: BodyMassIndexUnitEnum;
}

const headerItems = ['Date', 'Weight'].map((i) => (
  <th key={i} className={styles.tableHeader}>
    {i}
  </th>
));

const BodyMassIndexList: FC<IBodyMassIndexListProps> = ({records, selectedUnit}) =>
  !!records.length ? (
    <Card>
      <table className={styles.table}>
        <thead>
          <tr>{headerItems}</tr>
        </thead>
        <tbody>
          {records.map((record) => (
            <BodyMassIndexListItem key={record.id} record={record} selectedUnit={selectedUnit} />
          ))}
        </tbody>
      </table>
    </Card>
  ) : (
    <CardContent className={styles.noResultsContainer} center gapY={3}>
      <div className={styles.noResultsMessage}>You don’t have weight tracking data</div>
    </CardContent>
  );

export {BodyMassIndexList};
