import type {ChronicConditionChecklistDiagnosedGetResponseDto} from '../repository';
import {ChronicConditionChecklistDiagnosedModel} from './chronic-condition-checklist-diagnosed.model';

class ChronicConditionChecklistDiagnosedModelFactory {
  static fromGetResponseDto(
    dto: ChronicConditionChecklistDiagnosedGetResponseDto,
  ): ChronicConditionChecklistDiagnosedModel {
    return new ChronicConditionChecklistDiagnosedModel(dto.question, dto.displayName);
  }
}

export {ChronicConditionChecklistDiagnosedModelFactory};
