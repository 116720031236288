import type {IVaccinationFormElement} from '../../../vaccination-form.component';
import type {FC} from 'react';
import React from 'react';
import {SimpleVaccinationInput} from '../simple-vaccination-input';
import {Controller, useFormContext} from 'react-hook-form';
import {DatePicker} from '../../../../../components';
import {VaccinationRadioAnswers} from '../../../../enums';
import styles from './date-vaccination-input.module.scss';

const DateVaccinationInput: FC<{
  element: IVaccinationFormElement;
  errorMessage?: string;
}> = (props) => {
  const {control, watch} = useFormContext();

  return (
    <>
      <SimpleVaccinationInput element={props.element} />
      {!!props.element.dateSelection1Name &&
        watch(props.element.name) === VaccinationRadioAnswers.Yes &&
        (props.element.dateSelection2Name ? (
          <div className={styles.dates}>
            <div className={styles.dateContainer}>
              <div className={styles.doseTitle}>First dose</div>
              <Controller
                name={props.element.dateSelection1Name}
                control={control}
                render={({name, onChange, value}) => {
                  const currentValue = !!value ? new Date(value) : null;

                  return (
                    <DatePicker
                      name={name}
                      ariaLabelTitle={props.element.ariaLabelTitle}
                      value={currentValue}
                      onChange={onChange}
                      max={new Date()}
                      disabled={watch(props.element.name) !== VaccinationRadioAnswers.Yes}
                      format={'MMM-yyyy'}
                      showMonthPicker
                    />
                  );
                }}
              />
            </div>
            <div>
              <div className={styles.doseTitle}>Second dose</div>
              <Controller
                name={props.element.dateSelection2Name}
                control={control}
                render={({name, onChange, value}) => {
                  const currentValue = !!value ? new Date(value) : null;

                  return (
                    <DatePicker
                      name={name}
                      ariaLabelTitle={props.element.ariaLabelTitle}
                      value={currentValue}
                      onChange={onChange}
                      max={new Date()}
                      disabled={watch(props.element.name) !== VaccinationRadioAnswers.Yes}
                      format={'MMM-yyyy'}
                      showMonthPicker
                    />
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <Controller
            name={props.element.dateSelection1Name}
            control={control}
            render={({name, onChange, value}) => {
              const currentValue = !!value ? new Date(value) : null;

              return (
                <DatePicker
                  name={name}
                  ariaLabelTitle={props.element.ariaLabelTitle}
                  value={currentValue}
                  onChange={onChange}
                  max={new Date()}
                  format={'MMM-yyyy'}
                  showMonthPicker
                  disabled={watch(props.element.name) !== VaccinationRadioAnswers.Yes}
                />
              );
            }}
          />
        ))}
    </>
  );
};

export {DateVaccinationInput};
