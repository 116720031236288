enum DocumentType {
  LabResults = 0,
  MedicalRecords = 1,
  Other = 2,
  PreVisit = 3,
  EndoscopyColonoscopy = 4,
  ImagingReports = 5,
  ProviderVisitNotes = 6,
}

const DocumentTypeStrings = new Map<DocumentType, string>([
  [DocumentType.LabResults, 'Lab Reports'],
  [DocumentType.MedicalRecords, 'Medical Records'],
  [DocumentType.Other, 'Other'],
  [DocumentType.PreVisit, 'Pre–Visit Form'],
  [DocumentType.EndoscopyColonoscopy, 'Endoscopy/Colonoscopy Reports'],
  [DocumentType.ImagingReports, 'Imaging Reports'],
  [DocumentType.ProviderVisitNotes, 'Provider Visit Notes'],
]);

const DocumentDateStrings = new Map<DocumentType, string>([
  [DocumentType.LabResults, 'Lab Date'],
  [DocumentType.MedicalRecords, 'Record Date'],
  [DocumentType.Other, 'Date'],
  [DocumentType.PreVisit, 'Date'],
  [DocumentType.EndoscopyColonoscopy, 'Date'],
  [DocumentType.ImagingReports, 'Date'],
  [DocumentType.ProviderVisitNotes, 'Date'],
]);

export {DocumentType, DocumentTypeStrings, DocumentDateStrings};
