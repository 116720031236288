import type {IFileStorageService} from './file-storage.service.interface';
import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import {FileStorageModel} from '../model';
import type {IFileStorageRepository} from '../respository';
import {FILE_STORAGE_REPOSITORY} from '../respository';

@injectable()
class FileStorageService implements IFileStorageService {
  constructor(@inject(FILE_STORAGE_REPOSITORY) private readonly _repo: IFileStorageRepository) {
    makeAutoObservable(this);
  }

  private _files: Map<string, FileStorageModel> = new Map();

  get files(): Map<string, FileStorageModel> {
    return this._files;
  }

  async download(objectFileId: string): Promise<void> {
    const file = await this.get(objectFileId);
    if (!file.content) {
      throw Error('download file error');
    }

    const linkSource = `data:application/octet-stream;base64,${file.content}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = file.fileName;

    downloadLink.click();
  }

  async get(objectFileId: string): Promise<FileStorageModel> {
    let fileModelToReturn: FileStorageModel | null;

    const savedFileModel = this._files.get(objectFileId);

    if (savedFileModel) {
      fileModelToReturn = savedFileModel;
    } else {

      // Edge case when file is served from static storage.
      if (objectFileId.endsWith("svg")) {
        const assessmentModel = new FileStorageModel();
        assessmentModel.fileName = `STATIC_FILE_${objectFileId}`;

        return assessmentModel;
      }
      const fileModel = await this._repo.find({fileId: objectFileId}).catch(console.error);
      if (fileModel) {
        this._files.set(objectFileId, fileModel);
        fileModelToReturn = fileModel;
      } else {
        fileModelToReturn = new FileStorageModel();
        // Edge case when file is served from static storage.
        fileModelToReturn.fileName = `STATIC_FILE_${objectFileId}`;
      }
    }

    return fileModelToReturn;
  }

  async upload(file: FileStorageModel): Promise<string> {
    let objectId = '';

    const newFileModel = await this._repo.save(file).catch(console.error);
    if (newFileModel) {
      this._files.set(newFileModel.objectId, newFileModel);

      objectId = newFileModel.objectId;
    }

    return objectId;
  }
}

export {FileStorageService};
