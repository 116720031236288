import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {WorkProductivityModel, WorkProductivityQuestionModel} from '../model';
import {WorkProductivityQuestionModelFactory} from '../model';
import type {IWorkProductivityQuestionsGetResponseDto} from './dto';
import {IWorkProductivitySaveRequestDto} from './dto';
import type {IWorkProductivityRepository} from './work-productivity.repository.interface';

@injectable()
class WorkProductivityRepository implements IWorkProductivityRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  find(): Promise<WorkProductivityModel | null> {
    throw new NotImplementedException();
  }

  list(): Promise<WorkProductivityModel[]> {
    throw new NotImplementedException();
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async save(model: WorkProductivityModel): Promise<void> {
    const postDto = new IWorkProductivitySaveRequestDto(model.items);

    await this._http.post<number>('WorkProductivityQuestionnaire', postDto);
  }

  async listQuestions(): Promise<WorkProductivityQuestionModel[]> {
    const {data} = await this._http.get<IWorkProductivityQuestionsGetResponseDto[]>(
      'WorkProductivityQuestionnaire/getsurveyforpatient',
    );

    return data.map((dto) => WorkProductivityQuestionModelFactory.fromGetDto(dto));
  }
}

export {WorkProductivityRepository};
