import type {FC} from 'react';
import styles from './bubble.module.scss';
import classNames from 'classnames';

enum BubbleType {
  Bottom = 'Bottom',
  Tooltip = 'Tooltip',
}

interface IBubbleProps {
  type: BubbleType;
  className?: string;
}

const Bubble: FC<IBubbleProps> = (props) => (
    <div
      className={classNames(styles.root, props.className, {
        [styles.bottom]: props.type === BubbleType.Bottom,
        [styles.tooltip]: props.type === BubbleType.Tooltip,
      })}>
      {props.children}
    </div>
  );

export {Bubble, BubbleType};
export type {IBubbleProps};
