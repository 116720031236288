import type {FC} from 'react';
import {useMemo} from 'react';
import {Card, CardColorEnum, CardContent, Icon, IconType} from '../../../components';
import classNames from 'classnames';
import styles from './survey-wrapper.module.scss';

const SurveyWrapper: FC<{
  title: string | JSX.Element[];
  errorMessage?: string;
  isConditionElement?: boolean;
  className?: string;
}> = (props) => {
  const error = useMemo(
    () =>
      !!props.errorMessage && (
        <div className={styles.errorText}>
          <Icon type={IconType.AlertCircle} />
          <span>{props.errorMessage}</span>
        </div>
      ),
    [props.errorMessage],
  );

  return (
    <div
      className={classNames(styles.root, props.className, {
        [styles.rootCondition]: props.isConditionElement,
      })}>
      <Card
        borderLess
        className={classNames({
          [styles.error]: props.errorMessage,
        })}
        color={props.isConditionElement ? CardColorEnum.GreySecondary : undefined}>
        <CardContent gapX={2} gapY={2} className={styles.cardContent}>
          <div
            className={classNames(styles.questionWrapper, {
              [styles.conditionWrapper]: props.isConditionElement,
            })}>
            <h3
              className={classNames(styles.questionTitle, {
                [styles.questionTitleCondition]: props.isConditionElement,
              })}>
              {props.title}
            </h3>
            <div className={styles.subElements}>{props.children}</div>
          </div>
        </CardContent>
      </Card>
      {error}
    </div>
  );
};

export {SurveyWrapper};
