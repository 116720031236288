import type {IResilienceAssessmentRepository} from './resilience-assessment.repository.interface';
import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {ResilienceAssessmentModel, ResilienceAssessmentQuestionModel} from '../model';
import {ResilienceAssessmentModelFactory, ResilienceAssessmentQuestionModelFactory} from '../model';
import type {IResilienceAssessmentGetDto, IResilienceAssessmentQuestionsGetResponseDto} from './dto';
import {IResilienceAssessmentSaveRequestDto} from './dto';

@injectable()
class ResilienceAssessmentRepository implements IResilienceAssessmentRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  find(): Promise<ResilienceAssessmentModel | null> {
    throw new NotImplementedException();
  }

  async list(): Promise<ResilienceAssessmentModel[]> {
    const {data} = await this._http.get<IResilienceAssessmentGetDto[]>('Resilience/getpatientresilienceassessments');

    return data.map((dto) => ResilienceAssessmentModelFactory.fromGetDto(dto));
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async save(model: ResilienceAssessmentModel): Promise<void> {
    const postDto = new IResilienceAssessmentSaveRequestDto(model.patientId, model.items);
    await this._http.post<number>('Resilience/addpatientresilienceassessment', postDto);
  }

  async listQuestions(): Promise<ResilienceAssessmentQuestionModel[]> {
    const {data} = await this._http.get<IResilienceAssessmentQuestionsGetResponseDto[]>(
      'Resilience/getsurveyforpatient',
    );

    return data.map((dto) => ResilienceAssessmentQuestionModelFactory.fromGetDto(dto));
  }
}

export {ResilienceAssessmentRepository};
