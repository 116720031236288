import type {FC} from 'react';
import React, {useCallback} from 'react';
import {inject, observer} from 'mobx-react';
import {useInjection} from '../../../../../../../ioc';
import type {IProfileSettingsService} from '../../../../../../../profile-settings';
import {PROFILE_SETTINGS_SERVICE} from '../../../../../../../profile-settings';
import {TwoFactorAuthForm as TwoFactorAuth} from '../../../two-factor-auth/two-factor-auth-form.component';

interface ITwoFactorAuthFormProps {
  onClose: () => void;
  isVisible: boolean;
}

interface IServiceProps {
  profileSettingsService: IProfileSettingsService;
}

const TwoFactorAuthForm: FC<IServiceProps & ITwoFactorAuthFormProps> = ({
  profileSettingsService,
  onClose,
  isVisible,
}) => {
  const onFormSubmit = useCallback(
    async (code: string) => {
      await profileSettingsService.send(code);
    },
    [profileSettingsService],
  );

  return <TwoFactorAuth onClose={onClose} isVisible={isVisible} onSubmit={onFormSubmit} variant={'simple'} />;
};

const TwoFactorAuthFormObserver = observer(TwoFactorAuthForm);
const TwoFactorAuthFormObserverLegacyInjected = inject((profileSettingsService) => profileSettingsService)(
  TwoFactorAuthFormObserver,
) as unknown as FC<IServiceProps>;
const TwoFactorAuthFormInjected: FC<ITwoFactorAuthFormProps> = (props) => (
  <TwoFactorAuthFormObserverLegacyInjected {...props} profileSettingsService={useInjection(PROFILE_SETTINGS_SERVICE)} />
);

export {TwoFactorAuthFormInjected as TwoFactorAuthForm};
