interface IInputProps {
  label?: string;
  value?: string;
  type?: InputType;
  testId?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  onValueChanged?: (value: string) => void;
  name?: string;
  isValid?: boolean;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  withClear?: boolean;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

enum InputType {
  Common = 'common',
  Small = 'small',
  WithSmallLabel = 'smallLabel',
  Default = 'default',
  LabelInInput = 'labelInInput',
}

export type {IInputProps};
export {InputType};
