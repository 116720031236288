import classNames from 'classnames';
import type {FC, MouseEvent, ReactNode, Ref} from 'react';
import {forwardRef, useEffect} from 'react';
import styles from './overlay.module.scss';

interface IOverlayProps {
  onClose?: () => void;
  className?: string;
  hideOverflow?: boolean;
  zIndex?: number;
  children?: ReactNode;
  ref?: Ref<HTMLDivElement>;
}

const Overlay: FC<IOverlayProps> = forwardRef((props, ref) => {
  const onClick = (e: MouseEvent): void => {
    e.stopPropagation();

    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    if (props.hideOverflow) {
      const [body] = document.getElementsByTagName('body');

      body.classList.add(styles.body);

      return () => {
        body.classList.remove(styles.body);
      };
    }
  }, [props.hideOverflow]);

  return (
    <div
      ref={ref}
      className={classNames(styles.root, props.className)}
      onClick={onClick}
      style={{zIndex: props.zIndex}}>
      <div className={styles.wrapper}>{props.children}</div>
    </div>
  );
});

export {Overlay};
export type {IOverlayProps};
