import type {FC} from 'react';
import React from 'react';
import classNames from 'classnames';
import styles from './empty-rectangle.module.scss';

interface IEmptyRectangleProps {
  className?: string;
}

const EmptyRectangle: FC<IEmptyRectangleProps> = ({children, className}) => <div className={classNames(styles.container, className)}>{children}</div>;

export {EmptyRectangle};
export type {IEmptyRectangleProps};
