import {VaccinationCreateRequestDto} from './vaccination-create-request.dto';
import type {VaccinationModel} from '../../../model';

class VaccinationCreateRequestDtoFabric {
  static fromModel(model: VaccinationModel): VaccinationCreateRequestDto {
    return new VaccinationCreateRequestDto(model.patientId, model.items);
  }
}

export {VaccinationCreateRequestDtoFabric};
