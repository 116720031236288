import {ProviderType, UserRoleEnum} from '../../enums';
import type {IAvatar} from '../../components';
import {RecipientType} from '../dto';

class MessageThreadRecipientModel {
  constructor(
    public id = 0,
    public role = UserRoleEnum.Doctor,
    public firstName = '',
    public lastName = '',
    public title = '',
    public type = '',
    public providerTypeId = ProviderType.Physician,
    public avatar: IAvatar = '',
    public openForCommunicationWithPatient = true,
    public recipientType = RecipientType.Main,
  ) {}

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

export {MessageThreadRecipientModel};
