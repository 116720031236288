import type {FC} from 'react';
import React, {useMemo, useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {AssessmentItemWrapper, Button, Card} from '../../../components';
import type {IChronicConditionChecklistFormDto} from '../../service';
import type {ChronicConditionChecklistDiagnosedModel, IChronicConditionChecklistItem} from '../../model';
import {ChronicConditionChecklistDiagnosed} from './components';
import styles from './chronic-condition-checklist-form.module.scss';
import classNames from 'classnames';
import {scrollToError} from '../../../helpers';

interface IChronicConditionChecklistFormProps {
  selectedAssessmentItems: IChronicConditionChecklistItem[] | null;
  diagnoses: ChronicConditionChecklistDiagnosedModel[];
  readonly: boolean;
  onSubmit: (data: IChronicConditionChecklistFormDto) => void;
  onCancel: () => void;
}

const DIAGNOSES_LIST_READONLY_EMPTY = 'Not Provided';

const ChronicConditionChecklistForm: FC<IChronicConditionChecklistFormProps> = ({
  selectedAssessmentItems,
  diagnoses,
  readonly,
  onSubmit,
  onCancel,
}) => {
  const defaultValues = useMemo(
    () =>
      readonly && selectedAssessmentItems?.length
        ? selectedAssessmentItems.reduce(
            (acc, {question, value}) => ({
              ...acc,
              [question]: value,
            }),
            {},
          )
        : {},
    [readonly, selectedAssessmentItems],
  );

  const {reset, ...formMethods} = useForm<IChronicConditionChecklistFormDto>({defaultValues});

  const questions = useMemo(() => {
    if (readonly && !selectedAssessmentItems?.length) {
      return <div className={styles.notProvided}>{DIAGNOSES_LIST_READONLY_EMPTY}</div>;
    }

    return diagnoses.map((diagnosed) => (
      <ChronicConditionChecklistDiagnosed
        key={diagnosed.name}
        name={diagnosed.name}
        title={diagnosed.title}
        readonly={readonly}
      />
    ));
  }, [diagnoses, readonly, selectedAssessmentItems?.length]);

  useEffect(() => {
    scrollToError(formMethods.errors);
  }, [formMethods.errors]);

  return (
    <FormProvider {...formMethods} reset={reset}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Card>
          <AssessmentItemWrapper
            title={
              'Have you ever been diagnosed with the following in addition to your diagnosis? (Please check all that apply)'
            }>
            <div className={classNames({[styles.questionsReadonly]: readonly})}> {questions}</div>
          </AssessmentItemWrapper>
        </Card>
        {!readonly && (
          <div className={styles.buttonContainer}>
            <Button block className={styles.button} onClick={onCancel} testid={'survey-button-cancel'}>
              Cancel
            </Button>
            <Button block type={'submit'} className={styles.button} testid={'survey-button-submit'}>
              Submit
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export {ChronicConditionChecklistForm};
