import type {FC} from 'react';
import React from 'react';
import styles from './page-title.module.scss';
import classNames from 'classnames';

interface IPageTitleProps {
  className?: string;
}

const PageTitle: FC<IPageTitleProps> = (props) => <h2 className={classNames(styles.title, props.className)}>{props.children}</h2>;

export {PageTitle};
export type {IPageTitleProps};
