class MessagePostDto {
  constructor(
    public threadId = -1,
    public authorId = -1,
    public text = '',
    public attachmentObjectFileIds: string[] = [],
    public tempId: string = '',
  ) {}
}

export {MessagePostDto};
