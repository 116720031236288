import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IProfileSettingsRepository} from '../repository';
import { PROFILE_SETTINGS_REPOSITORY, ProfileSettingsRepository} from '../repository';
import type {IProfileSettingsService} from '../serivce';
import { PROFILE_SETTINGS_SERVICE, ProfileSettingsService} from '../serivce';

const ProfileSettingsModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IProfileSettingsRepository>(PROFILE_SETTINGS_REPOSITORY).to(ProfileSettingsRepository).inSingletonScope();

  bind<IProfileSettingsService>(PROFILE_SETTINGS_SERVICE).to(ProfileSettingsService).inSingletonScope();
});

export {ProfileSettingsModule};
