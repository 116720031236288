import type {TrellusTeamMember} from '../../../model';
import {teamTitleAbbreviation} from './team-title-abbreviation';

const modifyTeamTitles = (providers: TrellusTeamMember[]): TrellusTeamMember[] =>
  providers.map((provider) => ({
    ...provider,
    providerTitle: teamTitleAbbreviation.get(provider.providerTitle) ?? provider.providerTitle,
  }));

export {modifyTeamTitles};
