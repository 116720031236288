interface IGlobalImprovementScaleItem {
  question: string;
  value: string;
  dynamicSectionIndex?: string;
}

class GlobalImprovementScaleModel {
  constructor(
    public patientId: number = -1,
    public items: IGlobalImprovementScaleItem[] = [],
    public id: number = 0,
    public lastUpdated: Date | null = null,
    public name: string = '',
  ) {}
}

export {GlobalImprovementScaleModel};
export type {IGlobalImprovementScaleItem};
