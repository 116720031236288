import type {MessageModel} from '../../../../../../message';
import {formatDate} from '../../../../../../helpers';

const DATE_MASK = 'dd.MM.yyyy';

const groupMessagesByDateHelper = (messages: MessageModel[], timeZone: string): ReadonlyMap<Date, MessageModel[]> => {
  const map = new Map<Date, MessageModel[]>();
  const dates = Array.from(new Set(messages.map((i) => formatDate(i.createdAt, DATE_MASK, timeZone))));

  dates.forEach((dateString) => {
    let originalDate: Date = new Date(dateString);

    const items = messages.filter((message) => {
      const isSame = formatDate(message.createdAt, DATE_MASK, timeZone) === dateString;

      if (isSame) {
        originalDate = message.createdAt;
      }

      return isSame;
    });

    map.set(originalDate, items);
  });

  return map;
};

export {groupMessagesByDateHelper};
