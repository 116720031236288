import {StrengthsAssessmentCreateRequestDto} from './strengths-assessment-create-request.dto';
import type {IStrengthsAssessmentFormDto} from '../strengths-assessment-form.dto';

class StrengthsAssessmentCreateRequestDtoFactory {
  static fromFormDto(dto: IStrengthsAssessmentFormDto, patientId: number): StrengthsAssessmentCreateRequestDto {
    return new StrengthsAssessmentCreateRequestDto(
      patientId,
      Object.keys(dto).map((i) => ({
        question: i,
        // @ts-ignore
        value: dto[i],
      })),
    );
  }
}

export {StrengthsAssessmentCreateRequestDtoFactory};
