import type {ChangeEventHandler, FC, KeyboardEventHandler} from 'react';
import {useCallback} from 'react';
import styles from './search-input.module.scss';
import classNames from 'classnames';
import {ReactComponent as SearchIcon} from '../../assets/icons/ic_search.svg';

interface ISearchInputProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
}

const SearchInput: FC<ISearchInputProps> = (props) => {
  const onKeyUp: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.code === 'Escape') {
        props.onChange('');
      }
    },
    [props],
  );

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => props.onChange(e.target.value);

  return (
    <div className={styles.root}>
      <input
        data-testid={'search-input'}
        type={'text'}
        className={classNames(props.className, styles.input)}
        value={props.value}
        onChange={onChange}
        placeholder={props.placeholder}
        aria-label={props.placeholder ?? "search"}
        autoComplete={'off'}
        onKeyUp={onKeyUp}
      />
      {!props.value && <SearchIcon className={styles.icon} />}
    </div>
  );
};

export {SearchInput};
export type {ISearchInputProps};
