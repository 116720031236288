import {CookieModel} from '../models';
import type {ICookieService} from './cookie.service.interface';
import {subMinutes} from 'date-fns';
import {injectable} from 'inversify';

@injectable()
class CookieService implements ICookieService {
  set(cookie: CookieModel): void {
    document.cookie = cookie.toString();
  }

  delete(name: string): void {
    this.set(new CookieModel(name, '', subMinutes(new Date(), 1)));
  }
}

export {CookieService};
