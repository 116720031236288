import type {IFileStorageGetRequestDto} from '../respository/dto';
import {FileStorageModel} from './file-storage.model';

class FileStorageModelFactory {
  static fromGetDto(dto: IFileStorageGetRequestDto, fileId: string): FileStorageModel {
    return new FileStorageModel(dto.content, dto.filename, dto.fileType, fileId);
  }
}

export {FileStorageModelFactory};
