import type {IAuthError, IAuthService, InitialCallback} from './auth.service.interface';
import type {Environment, IAuthConfigService} from '../config';
import {AUTH_CONFIG_SERVICE} from '../config';
import {inject, injectable} from 'inversify';
import type {User} from '@auth0/auth0-spa-js';
import {Auth0Client} from '@auth0/auth0-spa-js';
import type {IRoutingService} from '../../routing';
import {ROUTING_SERVICE} from '../../routing';
import {makeAutoObservable} from 'mobx';
import type {IDebounce} from '../../debounce';
import {Debounce} from '../../debounce';
import type {ILocalStorageService} from '../../local-storage';
import {LOCAL_STORAGE_SERVICE} from '../../local-storage';
import {LOCATION_STORAGE_IS_FIRST_LOGIN_KEY} from '../../../helpers';

@injectable()
class AuthService implements IAuthService {
  private _user: User | null = null;
  private _auth0: Auth0Client | null = null;
  private _tokenUpdateDebounce: IDebounce;
  private _sessionWarningDebounce: IDebounce;

  constructor(
    @inject(AUTH_CONFIG_SERVICE) private readonly _config: IAuthConfigService,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(LOCAL_STORAGE_SERVICE) private readonly _localStorage: ILocalStorageService,
  ) {
    makeAutoObservable(this);

    this._sessionWarningDebounce = new Debounce(_config.sessionWarningTimeout, () => {
      if (this.isAuthenticated()) {
        this.showSessionWarning();
      }
    });
    this._tokenUpdateDebounce = new Debounce(_config.tokenUpdateTimeout, () => this.getToken(true));
  }

  private _isSessionWarningShown: boolean = false;

  get isSessionWarningShown(): boolean {
    return this._isSessionWarningShown;
  }

  isAuthenticated(): boolean {
    return !!this._user;
  }

  showSessionWarning() {
    this._isSessionWarningShown = true;
  }

  async getToken(ignoreCache?: boolean): Promise<string | null> {
    this._sessionWarningDebounce.stop();

    const token = (await this._auth0?.getTokenSilently({ignoreCache})) ?? null;
    this._user = (await this._auth0?.getUser()) ?? null;

    this._sessionWarningDebounce.start();

    return token;
  }

  async updateToken(): Promise<void> {
    this._tokenUpdateDebounce.reset();
  }

  async prolongSession(): Promise<void> {
    await this.getToken(true);

    this._isSessionWarningShown = false;
  }

  async init(callback?: InitialCallback): Promise<IAuthError | void> {
    const {domain, redirectUri, clientId, audience} = this._config;

    this._auth0 = new Auth0Client({
      domain,
      redirect_uri: redirectUri,
      audience,
      client_id: clientId,
      useRefreshTokens: true,
    });

    const urlParams = new URL(window.location.href).searchParams;
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    let error = urlParams.get('error');
    let errorDescription = urlParams.get('error_description');

    if (error) {
      return {title: error, description: errorDescription};
    }

    if (code && state) {
      // handle auth state from auth0 redirect
      await this._auth0
        .handleRedirectCallback()
        .then(() => {
          urlParams.delete('code');
          urlParams.delete('state');

          this._routing.replace({search: urlParams.toString()});
        })
        .catch((e) => {
          error = 'Failed on handle auth state';
          errorDescription = e?.message;
        });

      callback && callback();
    } else {
      // silently login from cache
      await this._auth0.checkSession().catch((e) => {
        error = 'Failed on session check';
        errorDescription = e?.message;
      });
    }

    if (error) {
      return {title: error, description: errorDescription};
    }

    this._user = (await this._auth0.getUser()) ?? null;

    this._sessionWarningDebounce.start();
  }

  async login(): Promise<void> {
    return this._auth0?.loginWithRedirect();
  }

  async logout(): Promise<void> {
    const {audience} = this._config;
    const token = await this.getToken().catch(console.error);

    this._sessionWarningDebounce.stop();

    if (token) {
      await fetch(`${audience}/UserAccount/logout`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).catch(console.error);
    }

    this._localStorage.delete(LOCATION_STORAGE_IS_FIRST_LOGIN_KEY);

    return this._auth0?.logout({returnTo: window.location.origin});
  }

  getEnvironment(): Environment {
    return this._config.environment;
  }
}

export {AuthService};
