import type {Control} from 'react-hook-form/dist/types/form';
import type {FC} from 'react';
import React, {useMemo} from 'react';
import {SurveyNotification} from '../survey-notification';
import {IsRenderMedicationLinkHelper} from './helpers';
import type {ISurveyElementResponse, ISurveyFormDto, ISurveySpecElement} from '../../index';

interface ISurveyStaticElementProps {
  element: ISurveySpecElement;
  control: Control;
  readonly: boolean;
  elementValues: Array<ISurveyElementResponse>;
  isOnBoarding?: boolean;
  currentFormValues: ISurveyFormDto;
  flattenedElements: Array<ISurveySpecElement>;
}

const SurveyStaticElement: FC<ISurveyStaticElementProps> = (props) => {
  const isRenderMedicationLink = useMemo(
    () => IsRenderMedicationLinkHelper(props.element, props.currentFormValues, props.flattenedElements),
    [props.currentFormValues, props.element, props.flattenedElements],
  );

  if (!props.isOnBoarding && !props.readonly) {
    return isRenderMedicationLink ? <SurveyNotification /> : <></>;
  }

  return <></>;
};

export {SurveyStaticElement};
export type {ISurveyStaticElementProps};
