import type {ResilienceAssessmentModel, ResilienceAssessmentQuestionModel} from '../model';
import {ResilienceAssessmentModelFactory} from '../model';
import type {
  IResilienceAssessmentService,
  ResilienceAssessmentFormDto,
} from './resilience-assessment.service.interface';
import {inject, injectable} from 'inversify';
import type {IResilienceAssessmentRepository} from '../repository';
import {RESILIENCE_ASSESSMENT_REPOSITORY} from '../repository';
import {makeAutoObservable} from 'mobx';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import {routes} from '../../routing';
import {getSurvey} from '../../strengths-assessment/helper';
import type {IAlertIndicatorService} from '../../alert-indicator';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';
import type {ITaskService} from '../../task/service';
import {TASK_SERVICE} from '../../task/service';
import {PredefinedTasks} from '../../enums/predefined-tasks';

@injectable()
class ResilienceAssessmentService implements IResilienceAssessmentService {
  private _surveys: ResilienceAssessmentModel[] = [];

  constructor(
    @inject(RESILIENCE_ASSESSMENT_REPOSITORY) private readonly _repo: IResilienceAssessmentRepository,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(ALERT_INDICATOR_SERVICE) private readonly _alertIndicator: IAlertIndicatorService,
    @inject(TASK_SERVICE) private readonly _tasks: ITaskService,
  ) {
    makeAutoObservable(this);
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _lastFilledAssessment: ResilienceAssessmentModel | null = null;

  get lastFilledAssessment(): ResilienceAssessmentModel | null {
    return this._lastFilledAssessment;
  }

  private _listQuestions: ResilienceAssessmentQuestionModel[] = [];

  get listQuestions(): ResilienceAssessmentQuestionModel[] {
    return this._listQuestions;
  }

  get isReadOnly(): boolean {
    return !!this._lastFilledAssessment?.lastUpdated;
  }

  async loadAssessments(): Promise<void> {
    this._isLoading = true;

    await this.loadQuestions();

    this._surveys = await this._repo.list();

    await this.fillLastFilledAssessment();

    this._isLoading = false;
  }

  async loadQuestions(): Promise<void> {
    if (!this._listQuestions.length) {
      this._listQuestions = await this._repo.listQuestions();
    }
  }

  async fillLastFilledAssessment(): Promise<void> {
    const firstAssessment = getSurvey<ResilienceAssessmentModel>(this._surveys);

    if (!this._tasks.activeTasks.length) {
      await this._tasks.load();
    }

    if (firstAssessment?.lastUpdated) {
      this._lastFilledAssessment = this._tasks.isTaskComplete(PredefinedTasks.ResilienceAssessment)
        ? firstAssessment
        : null;
    } else {
      this._lastFilledAssessment = null;
    }
  }

  async createAssessment(formDto: ResilienceAssessmentFormDto, patientId: number): Promise<void> {
    const model = ResilienceAssessmentModelFactory.fromFormDto(formDto, patientId);

    await this._repo
      .save(model)
      .then(() => {
        this._alertIndicator.load();

        if (this._routing.length === 1) {
          this.navigateBack();
        } else {
          this._routing.goBack();
        }
      })
      .catch(console.error);
  }

  navigateBack(): void {
    this._routing.push(`${routes.home}${routes.assessmentReport}`);
  }
}

export {ResilienceAssessmentService};
