import type {FC} from 'react';
import React from 'react';
import styles from './spinner.module.scss';

const Spinner: FC = () => (
  <div className={styles.loading}>
    <div />
  </div>
);

export {Spinner};
