import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {
  ISurveyGetLastResponseInfoResponseDto,
  ISurveyGetResponsesResponseDto,
  ISurveyPostResponseDto,
  ISurveySpecGetResponseDto,
} from './dto';
import {
  SurveyGetLastResponseInfoRequestDtoFactory,
  SurveyGetResponseDto,
  SurveyGetResponsesRequestDtoFactory,
  SurveyPostDtoFactory,
} from './dto';

import type {ISurveyQuery, ISurveyRepository} from './survey.repository.interface';
import type {SurveyModel, SurveySpecModel} from '../model';
import {SurveyModelFactory} from '../model/survey.model.factory';

@injectable()
class SurveyRepository implements ISurveyRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(query: Partial<ISurveyQuery> | undefined): Promise<SurveyModel | null> {
    const model: SurveyModel | null = null;

    if (query?.patientId && query?.type) {
      let specDto: SurveySpecModel | null = null;
      let responsesDto: Array<ISurveyGetResponsesResponseDto> = [];
      let lastResponseInfoDto: ISurveyGetLastResponseInfoResponseDto | null = null;

      await Promise.all([
        this._http
          .get<ISurveySpecGetResponseDto>(`survey/?surveyType=${query?.type}`)
          .then(({data}) => (specDto = data)),
        this._http
          .post<Array<ISurveyGetResponsesResponseDto>>(
            `/patientsurvey/getforpatient`,
            SurveyGetResponsesRequestDtoFactory.fromQuery(query),
          )
          .then(({data}) => (responsesDto = data)),
        this._http
          .post<ISurveyGetLastResponseInfoResponseDto>(
            `/patientsurvey/lastsurveyresponseinfo`,
            SurveyGetLastResponseInfoRequestDtoFactory.fromQuery(query),
          )
          .then(({data}) => (lastResponseInfoDto = data)),
      ]);

      const getDto = new SurveyGetResponseDto(specDto, responsesDto, lastResponseInfoDto);

      return SurveyModelFactory.fromGetDto(getDto);
    }

    return model;
  }

  list(): Promise<SurveyModel[]> {
    throw new Error('Not implemented');
  }

  remove(): Promise<void> {
    throw new Error('Not implemented');
  }

  async save(model: SurveyModel): Promise<SurveyModel> {
    const postDto = SurveyPostDtoFactory.fromModel(model);

    if (model.surveyResponseId) {
      await this._http.put<ISurveyPostResponseDto>(`patientsurvey/${model.surveyResponseId}`, postDto);
    } else {
      await this._http.post<ISurveyPostResponseDto>('patientsurvey', postDto);
    }

    return model;
  }
}

export {SurveyRepository};
