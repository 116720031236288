import type {FC} from 'react';
import {DateWrapper} from '../../../../components';
import styles from './emotional-impact-scale-sub-header.module.scss';

interface IEmotionalImpactScaleSubHeaderProps {
  date?: Date | null;
}

const EmotionalImpactScaleSubHeader: FC<IEmotionalImpactScaleSubHeaderProps> = ({date}) =>
  date ? (
    <div className={styles.subHeader}>
      Last updated · <DateWrapper date={date}/>
    </div>
  ) : null;

export {EmotionalImpactScaleSubHeader};
