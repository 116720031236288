import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IResilienceAssessmentRepository} from '../repository';
import {RESILIENCE_ASSESSMENT_REPOSITORY, ResilienceAssessmentRepository} from '../repository';
import type {IResilienceAssessmentService} from '../service';
import {RESILIENCE_ASSESSMENT_SERVICE, ResilienceAssessmentService} from '../service';

const ResilienceAssessmentModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IResilienceAssessmentRepository>(RESILIENCE_ASSESSMENT_REPOSITORY)
    .to(ResilienceAssessmentRepository)
    .inSingletonScope();

  bind<IResilienceAssessmentService>(RESILIENCE_ASSESSMENT_SERVICE).to(ResilienceAssessmentService).inSingletonScope();
});

export {ResilienceAssessmentModule};
