import type {FC} from 'react';
import React from 'react';
import styles from './card-actions.module.scss';
import classNames from 'classnames';

interface ICardActionsProps {
  borderTop?: boolean;
  bigIndent?: boolean;
  className?: string;
}

const CardActions: FC<ICardActionsProps> = ({borderTop = true, bigIndent, className, children}) => (
  <div className={classNames(styles.root, className, {[styles.borderTop]: borderTop, [styles.bigIndent]: bigIndent})}>
    {children}
  </div>
);

export {CardActions};
export type {ICardActionsProps};
