import type {MouseEvent} from 'react';

interface IconPropsLegacy {
  type: IconTypeLegacy;
  className?: string;
  onClick?: (event: MouseEvent<SVGSVGElement>) => void;
}

enum IconTypeLegacy {
  Close = 'close',
  CloudDownload = 'cloud-download',
  CloudDownloadBig = 'cloud-download-big',
  File = 'file',
  ClientInfo = 'ClientInfo',
  PatientInfo = 'PatientInfo',
  Attachment = 'Attachment',
}

export {IconTypeLegacy};
export type {IconPropsLegacy};
