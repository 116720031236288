import type {ISurveyFormDto} from '../../../../model';

const replaceRepeatItems = (fromWhereRemove: string[], removedItems: string[]) => {
  const result = [];

  for (let i = 0; i < fromWhereRemove.length; i++) {
    if (removedItems.indexOf(fromWhereRemove[i]) === -1) {
      result.push(fromWhereRemove[i]);
    }
  }

  return result;
};

const IsRelatedQuestionAllowsRenderHelper = (name: string, dto: ISurveyFormDto): boolean => {
  const removedItems = ['ibs', 'dynamic', 'section', 'date'];

  const entityName = replaceRepeatItems(name.split('_'), removedItems).join('_');
  const questionFieldName = `${entityName}_question`;
  const key = Object.keys(dto).find((i) => i.includes(questionFieldName));

  let value: boolean;

  if (key) {
    value = dto[key] === 'true';
  } else {
    value = false;
  }

  return value;
};

export {IsRelatedQuestionAllowsRenderHelper};
