import type {FC} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import styles from './filter-item.module.scss';
import classNames from 'classnames';

const FilterItem: FC<{
  value: number;
  legend: string;
  available: (number | null)[] | null;
  onFilter: (arg0: number) => void;
  paramsFilter: (number | null)[] | null;
}> = ({value, legend, onFilter, paramsFilter, children}) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (paramsFilter?.includes(value)) {
      setActive(true);
    }
  }, [paramsFilter, value]);

  const onClickFilter = useCallback(
    (value) => {
      onFilter(value);
      setActive((prevState) => !prevState);
    },
    [onFilter],
  );

  return (
    <button
      className={classNames(styles.flagButton, {[styles.flagAction]: active})}
      onClick={() => onClickFilter(value)}
      data-testid={'filter-item-button'}>
      {children} <span className={styles.flagTitle}>{legend}</span>
    </button>
  );
};

export {FilterItem};
