import type {ButtonHTMLAttributes, FC} from 'react';
import React, {useMemo} from 'react';
import styles from './button.module.scss';
import classNames from 'classnames';
import {Icon, IconType} from '../icon';
import {Spinner} from '../spinner';

enum ButtonSizeEnum {
  Big = 'big',
}

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  block?: boolean;
  flat?: boolean;
  slim?: boolean;
  bold?: boolean;
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  gray?: boolean;
  radius?: boolean;
  prependInsideIcon?: string | number;
  round?: boolean;
  blackText?: boolean;
  lightGray?: boolean;
  icon?: number;
  iconSize?: number | string;
  size?: string;
  testid?: string;
  isLoading?: boolean;
  lowercase?: boolean;
}

const Button: FC<IButtonProps> = (props) => {
  const content = useMemo(() => {
    let actualContent;

    if (!!props.icon) {
      actualContent = <Icon width={props.iconSize} height={props.iconSize} type={props.icon} />;
    } else if (!!props.prependInsideIcon) {
      actualContent = (
        <span className={styles.contentWrapper}>
          <Icon type={props.prependInsideIcon} />
          {props.children}
        </span>
      );
    } else {
      actualContent = <>{props.children}</>;
    }

    return actualContent;
  }, [props.iconSize, props.children, props.icon, props.prependInsideIcon]);

  const disabled = useMemo(() => (props.isLoading ? false : props.disabled), [props.disabled, props.isLoading]);

  return (
    <button
      title={props.title}
      disabled={disabled}
      className={classNames(styles.root, props.className, {
        [styles.block]: props.block,
        [styles.slim]: props.slim,
        [styles.bold]: props.bold,
        [styles.big]: props.size === ButtonSizeEnum.Big,
        [styles.primary]: props.primary,
        [styles.secondary]: props.secondary,
        [styles.success]: props.success,
        [styles.gray]: props.gray,
        [styles.lightGray]: props.lightGray,
        [styles.colorBlack]: props.blackText,
        [styles.radius]: props.radius,
        [styles.flat]: props.flat,
        [styles.round]: props.round,
        [styles.addIcon]: Number(props.icon) === IconType.Plus,
        [styles.lowerCase]: props.lowercase,
      })}
      onClick={props.onClick}
      data-testid={props.testid}
      type={props.type ?? 'button'}>
      <span
        className={classNames(styles.loadingWrapperNormal, {
          [styles.loadingWrapperLoad]: props.isLoading,
        })}>
        <Spinner />
      </span>
      {content}
    </button>
  );
};

export {Button, ButtonSizeEnum};
export type {IButtonProps};
