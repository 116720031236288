import type {IChronicConditionChecklistFormDto} from '../service';
import type {ChronicConditionChecklistGetResponseDto} from '../repository';
import type {IChronicConditionChecklistItem} from './chronic-condition-checklist.model';
import {ChronicConditionChecklistModel} from './chronic-condition-checklist.model';

class ChronicConditionChecklistModelFactory {
  static fromFormDto(dto: IChronicConditionChecklistFormDto): ChronicConditionChecklistModel {
    const items: Array<IChronicConditionChecklistItem> = Object.keys(dto).reduce(
      (acc, fieldName) => [
        ...acc,
        {
          question: fieldName,
          value: dto[fieldName],
        },
      ],
      [] as IChronicConditionChecklistItem[],
    );

    return new ChronicConditionChecklistModel(items);
  }

  static fromGetResponseDto(dto: ChronicConditionChecklistGetResponseDto): ChronicConditionChecklistModel {
    const items = dto.items.map((i: IChronicConditionChecklistItem) => ({
      question: i.question,
      value: i.value,
    }));

    return new ChronicConditionChecklistModel(items, dto.id, new Date(dto.lastUpdated), dto.name);
  }
}

export {ChronicConditionChecklistModelFactory};
