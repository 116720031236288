interface ISelfEfficacyAssessmentItem {
  question: string;
  value: string;
}

class SelfEfficacyAssessmentModel {
  constructor(
    public patientId: number = -1,
    public items: ISelfEfficacyAssessmentItem[] = [],
    public id: number = 0,
    public lastUpdated: Date | null = null,
    public name: string = '',
  ) {}
}

export {SelfEfficacyAssessmentModel};
export type {ISelfEfficacyAssessmentItem};
