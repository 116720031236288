import type {FC} from 'react';
import React from 'react';
import type {INotifierService} from '../service';
import {NOTIFIER_SERVICE} from '../service';
import classNames from 'classnames';
import styles from './notifier.module.scss';
import {observer} from 'mobx-react';
import {NotifyMessageTypeEnum} from '../notifier-message-type.enum';
import {useInjection} from '../../ioc';
import {EmailParser} from './email-parser';
import {CloseIconBold} from '../../assets/icons';
import type {ICompanyService} from '../../company';
import {COMPANY_SERVICE} from '../../company';

interface INotifierProps {
  notifierService: INotifierService;
  companyService: ICompanyService;
}

const Notifier: FC<INotifierProps> = ({notifierService, companyService}) => {
  const contactEmail = companyService.companyInfo.contactEmail || 'support@trellushealth.com';

  const close = () => notifierService.clear();

  return (
    notifierService.message && (
      <div className={styles.root} role="alert">
        <div
          className={classNames(styles.container, {
            [styles.containerDanger]: notifierService.message.type === NotifyMessageTypeEnum.Danger,
            [styles.containerSuccess]: notifierService.message.type === NotifyMessageTypeEnum.Success,
          })}>
          <div className={styles.contentPart}>
            {notifierService.message.title && <p className={styles.title}>{notifierService.message.title}</p>}
            <span className={styles.text}>
              {notifierService.message.text.includes(contactEmail) ? (
                <EmailParser className={styles.link} text={notifierService.message.text} email={contactEmail} />
              ) : (
                notifierService.message.text
              )}
            </span>
            {notifierService.message.callback && (
              <button onClick={notifierService.message.callback} className={styles.redirectButton}>
                {notifierService.message.callbackText}
              </button>
            )}
          </div>

          <button className={styles.closeButton} onClick={close} title="Close" data-testid="closeButton">
            <CloseIconBold />
          </button>
        </div>
      </div>
    )
  );
};

const ObservableNotifier = observer(Notifier);
const InjectedNotifier: FC = () => (
  <ObservableNotifier
    notifierService={useInjection<INotifierService>(NOTIFIER_SERVICE)}
    companyService={useInjection<ICompanyService>(COMPANY_SERVICE)}
  />
);

export {ObservableNotifier as Notifier, InjectedNotifier};
