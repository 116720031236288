import {AssessmentReportScoreModelIconKeyEnum} from '../../../model/assessment-report-score.model';
import type {FC} from 'react';
import React, {useMemo} from 'react';
import {BarriersIcon, EmotionIcon, IndependenceIcon, NutritionIcon, SymptomsIcon} from '../../../../assets/icons';

const IconMap: ReadonlyMap<AssessmentReportScoreModelIconKeyEnum, FC> = new Map([
  [AssessmentReportScoreModelIconKeyEnum.Emotion, EmotionIcon],
  [AssessmentReportScoreModelIconKeyEnum.Barriers, BarriersIcon],
  [AssessmentReportScoreModelIconKeyEnum.Nutrition, NutritionIcon],
  [AssessmentReportScoreModelIconKeyEnum.Independence, IndependenceIcon],
  [AssessmentReportScoreModelIconKeyEnum.Symptoms, SymptomsIcon],
]);

const EmptyIcon: FC = () => <></>;

const useIcon = (key: AssessmentReportScoreModelIconKeyEnum): FC => useMemo(() => IconMap.get(key) ?? EmptyIcon, [key]);

export {useIcon};
