import type {FC} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import {PageLoader} from '../../components';
import {TrackingDailyReadonlyContext} from './tracking-daily-survey.context';
import {shiftToUTCEndOfDay} from '../../helpers';
import {useFormConfirmationDialog} from '../../hooks';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';
import type {ISurveyFormDto, ISurveyService} from '../../survey';
import {SURVEY_SERVICE, SurveyPeriodType, SurveyType} from '../../survey';
import {TrackingDailyForm} from './form';

interface ITrackingDailySurveyProps {
  date: Date;
  isLoading: boolean;
  isOnboarding?: boolean;
  onSubmit?: () => void;
  blankBackground?: boolean;
}

interface IServiceProps {
  surveyService: ISurveyService;
  account: IAccountService;
}

const TrackingDailySurvey: FC<ITrackingDailySurveyProps & IServiceProps> = ({
  surveyService,
  date,
  isLoading,
  isOnboarding,
  blankBackground,
  account,
}) => {
  const patientId = account.id;
  const timeZone = account.timeZone;
  const {getConfirmation} = useFormConfirmationDialog();
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const loadSurvey = useCallback(
    () => surveyService.load(SurveyType.SymptomTracking, patientId, SurveyPeriodType.Day, shiftToUTCEndOfDay(date)),
    [surveyService, patientId, date],
  );

  useEffect(() => {
    loadSurvey();
  }, [loadSurvey]);

  const survey = surveyService.getSurvey(SurveyType.SymptomTracking, shiftToUTCEndOfDay(date));

  const readonly = surveyService.isReadonly(date, SurveyType.SymptomTracking, timeZone);

  const onSubmit = useCallback(
    async (data: ISurveyFormDto): Promise<void> => {
      setSubmitDisabled(true);

      const isConfirmed = await getConfirmation({});

      if (isConfirmed) {
        await surveyService.send(patientId, SurveyType.SymptomTracking, data, true, false, new Date());
      } else {
        setSubmitDisabled(false);
      }
    },
    [getConfirmation, patientId, surveyService],
  );

  return !!survey?.spec ? (
    <TrackingDailyReadonlyContext.Provider value={readonly}>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <TrackingDailyForm
          spec={survey.spec}
          onSubmit={onSubmit}
          elementValues={survey.responses}
          isLoading={isLoading}
          isSubmitDisabled={isSubmitDisabled}
          readonly={readonly}
          isOnBoarding={isOnboarding}
          isEverCompleted={!!survey.lastCompletedDate}
          blankBackground={blankBackground}
        />
      )}
    </TrackingDailyReadonlyContext.Provider>
  ) : (
    <PageLoader />
  );
};
const TrackingDailySurveyObserver = observer(TrackingDailySurvey);
const TrackingDailySurveyInjected: FC<ITrackingDailySurveyProps> = (props) => (
  <TrackingDailySurveyObserver
    surveyService={useInjection(SURVEY_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
    {...props}
  />
);

export {TrackingDailySurveyInjected as TrackingDailySurvey};
