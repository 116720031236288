enum RangeBarLabelsType {
  Numbers,
  Strings,
}

enum RangeBarStylingType {
  Line,
  Slicing,
}

export {RangeBarLabelsType, RangeBarStylingType};
