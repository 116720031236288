import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './datepicker-header-selector.module.scss';
import {getMonth, getYear} from 'date-fns';
import {range} from 'lodash';
import classNames from 'classnames';
import {DownIcon} from '../../../../assets/icons';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  DropdownActivatorBehaviorType,
  DropdownPositionXEnum,
  DropdownPositionYEnum,
  useDropdown,
} from '../../../dropdown';

const MIN_YEAR = 1900;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

interface ISelectorProps {
  currentDate: number | string;
  data: number[] | string[];
  onChange: (val: number | string) => void;
}

interface IDatepickerHeaderProps {
  currentDate: Date;
  numberYearsFuture?: number;
  changeMonth: (value: number) => void;
  changeYear: (value: number) => void;
}

const Selector: FC<ISelectorProps> = ({currentDate, onChange, data}) => {
  const [value, setValue] = useState<number | string>(currentDate);
  const {Dropdown, isOpenDropdown, closeDropdown} = useDropdown();
  const selectedOptionRef = useRef<HTMLDivElement>(null);

  const change = useCallback(
    (option) => {
      onChange(option);
      setValue(option);
    },
    [onChange],
  );

  const onEnterPress = useCallback(
    (e, option) => {
      if (e.code === 'Enter') {
        return change(option);
      }
    },
    [change],
  );

  const options = useMemo(
    () =>
      data
        .map((option) => (
          <div
            key={option}
            ref={option === value ? selectedOptionRef : null}
            onClick={() => change(option)}
            tabIndex={0}
            onKeyPress={(e) => onEnterPress(e, option)}
            className={classNames(styles.option, {[styles.active]: option === value})}>
            {option}
          </div>
        ))
        .reverse(),
    [change, onEnterPress, data, value],
  );

  useEffect(() => {
    selectedOptionRef.current?.scrollIntoView({block: 'nearest'});
  }, [isOpenDropdown]);

  return (
    <div className={styles.selector}>
      <Dropdown
        activatorBehaviorType={DropdownActivatorBehaviorType.Click}
        positionX={DropdownPositionXEnum.Left}
        positionY={DropdownPositionYEnum.Datepicker}
        activator={({onOpen}) => (
          <div
            className={classNames(styles.toggle, {[styles.active]: isOpenDropdown})}
            onMouseDown={onOpen}
            onKeyPress={(e) => (e.code === 'Enter' ? onOpen() : null)}
            tabIndex={0}>
            {currentDate} <DownIcon />
          </div>
        )}>
        <OutsideClickHandler onOutsideClick={closeDropdown}>
          <div className={styles.select}>
            <div className={styles.options}>{options}</div>
          </div>
        </OutsideClickHandler>
      </Dropdown>
    </div>
  );
};

const DatepickerHeaderSelector: FC<IDatepickerHeaderProps> = ({
  currentDate,
  changeMonth,
  changeYear,
  numberYearsFuture,
}) => {
  const onChangeMonth = useCallback((value) => changeMonth(months.indexOf(String(value))), [changeMonth]);
  const currentMonth = useMemo(() => months[getMonth(currentDate)], [currentDate]);

  const onChangeYear = useCallback((value) => changeYear(+value), [changeYear]);
  const currentYear = useMemo(() => getYear(currentDate), [currentDate]);

  const years = useMemo(
    () => range(MIN_YEAR, getYear(new Date()) + (numberYearsFuture ? numberYearsFuture + 1 : 1), 1),
    [numberYearsFuture],
  );

  return (
    <div className={styles.root} data-testid="date-picker-header-selector">
      <Selector onChange={onChangeMonth} currentDate={currentMonth} data={months} />

      <Selector onChange={onChangeYear} currentDate={currentYear} data={years} />
    </div>
  );
};

export {DatepickerHeaderSelector};
