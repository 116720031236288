import {SurveyRadioButton} from '../survey-radio-button';
import styles from './survey-yes-no.module.scss';
import type {FC} from 'react';

const YES_NO_OPTIONS = [
  {id: 'true', text: 'Yes'},
  {id: 'false', text: 'No'},
];

interface ISurveyYesNoComponentProps {
  name: string;
  value: string;
  isError?: boolean;
  onChange: (value: string) => void;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const SurveyYesNoComponent: FC<ISurveyYesNoComponentProps> = (props) => (
  <div className={styles.buttonsContainer} role="radiogroup">
    {YES_NO_OPTIONS.map((option) => {
      const value = YES_NO_OPTIONS.find((option) => option.id === props.value);

      return (
        <SurveyRadioButton
          key={option.id}
          name={props.name}
          value={value}
          option={option}
          onChange={(value) => props.onChange(String(value.id))}
          isError={props.isError}
          ariaLabelTitle={props.ariaLabelTitle}
          ariaLabelError={props.ariaLabelError}
        />
      );
    })}
  </div>
);

export {SurveyYesNoComponent};
export type {ISurveyYesNoComponentProps};
