import type {AccountModel} from '../../../model';
import type {IAccountPutRequestDto} from './account-put-request.dto';

class AccountPutRequestDtoFactory {
  static fromModel(model: AccountModel): IAccountPutRequestDto {
    return {
      patientId: model.id,
      firstName: model.firstName,
      lastName: model.lastName,
      dateOfBirth: model.dateOfBirth,
      phone: model.phone,
      alternatePhone: model.alternatePhone,
      country: model.country,
      zipCode: model.zipCode,
      state: model.state,
      city: model.city,
      address: model.address,
      verificationCode: model.verificationCode,
      language: {
        id: model.language.id,
        text: model.language.text,
        isNeedInterpreter: model.language.isNeedInterpreter,
      },
      emergencyContact: {
        firstName: model.emergencyContact?.firstName,
        lastName: model.emergencyContact?.lastName,
        patientId: model.emergencyContact?.patientId,
        email: model.emergencyContact?.email,
        phone: model.emergencyContact?.phone,
        partnerInCareId: model.emergencyContact?.partnerInCareId,
        relationshipType: model.emergencyContact?.relationshipType,
      },
      insuranceCompany: model.insuranceCompany,
      insurancePhone: model.insurancePhone,
      memberId: model.memberId,
      plan: model.plan,
      sex: model.sex,
      objectFileId: model.objectFileId,
    };
  }
}

export {AccountPutRequestDtoFactory};
