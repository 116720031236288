import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {ChronicConditionChecklistDiagnosedModel, ChronicConditionChecklistModel} from '../model';
import {ChronicConditionChecklistDiagnosedModelFactory} from '../model';
import type {ChronicConditionChecklistDiagnosedGetResponseDto, ChronicConditionChecklistGetResponseDto} from './dto';
import {ChronicConditionChecklistSaveRequestDto} from './dto';
import type {
  IChronicConditionChecklistQuery,
  IChronicConditionChecklistRepository,
} from './chronic-condition-checklist.repository.interface';
import {ChronicConditionChecklistModelFactory} from '../model/chronic-condition-checklist.model.factory';

@injectable()
class ChronicConditionChecklistRepository implements IChronicConditionChecklistRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(query?: Partial<IChronicConditionChecklistQuery>): Promise<ChronicConditionChecklistModel | null> {
    let model: ChronicConditionChecklistModel | null = null;

    if (query?.id) {
      const {data} = await this._http.get<ChronicConditionChecklistGetResponseDto>(
        `ChronicConditionChecklist/${query?.id}`,
      );

      model = ChronicConditionChecklistModelFactory.fromGetResponseDto(data);
    }

    return model;
  }

  async list(): Promise<ChronicConditionChecklistModel[]> {
    const {data} = await this._http.get<ChronicConditionChecklistGetResponseDto[]>(`ChronicConditionChecklist`);

    return data?.map((i) => ChronicConditionChecklistModelFactory.fromGetResponseDto(i)) ?? [];
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async save(model: ChronicConditionChecklistModel): Promise<void> {
    const postDto = new ChronicConditionChecklistSaveRequestDto(model.items);

    await this._http.post<number>('ChronicConditionChecklist', postDto);
  }

  async listDiagnoses(): Promise<ChronicConditionChecklistDiagnosedModel[]> {
    const {data} = await this._http.get<ChronicConditionChecklistDiagnosedGetResponseDto[]>(
      `ChronicConditionChecklist/diagnoses`,
    );

    return data?.map((i) => ChronicConditionChecklistDiagnosedModelFactory.fromGetResponseDto(i)) ?? [];
  }
}

export {ChronicConditionChecklistRepository};
