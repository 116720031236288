import type {IPreScreeningService} from './pre-screening.service.interface';
import type {IPreScreeningTask} from '../model';
import { PreScreeningModel} from '../model';
import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {IPreScreeningRepository} from '../repository';
import {PRE_SCREENING_REPOSITORY} from '../repository';

@injectable()
class PreScreeningService implements IPreScreeningService {
  private _model: PreScreeningModel = new PreScreeningModel();

  constructor(@inject(PRE_SCREENING_REPOSITORY) private readonly _repo: IPreScreeningRepository) {
    makeAutoObservable(this);
  }

  get tasks(): Array<IPreScreeningTask> {
    return this._model.tasks;
  }

  async loadTasks(): Promise<void> {
    await this._repo
      .find()
      .then((model) => (this._model.tasks = model?.tasks ?? []))
      .catch(console.error);
  }
}

export {PreScreeningService};
