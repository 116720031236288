import type {ProfileSettingsModel} from '../../../model';
import {ProfileSettingsPutRequestDto} from './profile-settings-put-request.dto';

class ProfileSettingsPutRequestDtoFactory {
  static fromModel(model: ProfileSettingsModel): ProfileSettingsPutRequestDto {
    return new ProfileSettingsPutRequestDto(
      model.areSmsReceived,
      model.arePushReceived,
      Number(model.notificationType),
      model.selectedTimeZone?.value ?? '',
      model.verificationCode,
    );
  }
}

export {ProfileSettingsPutRequestDtoFactory};
