import type {FC} from 'react';
import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import styles from './tracking-daily-screen.module.scss';
import {PageTitle, Preloader} from '../../components';
import {eachDayOfInterval, lastDayOfWeek, startOfDay, subDays} from 'date-fns';
import {TrackingDailySurvey} from '../survey';
import {utcToZonedTime} from 'date-fns-tz';
import type {ISurveyService} from '../../survey/service';
import {SURVEY_SERVICE} from '../../survey/service';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';
import {TrackingDailyWeek} from './components';
import {SurveyType} from '../../survey';

interface ITrackingDailyScreenProps {
  survey: ISurveyService;
  account: IAccountService;
}

const TrackingDailyScreen: FC<ITrackingDailyScreenProps> = ({survey, account}) => {
  const timeZone = account.timeZone;

  const startOfCurrentDay = startOfDay(utcToZonedTime(new Date(), timeZone ?? ''));

  const [selectedDate, setSelectedDate] = useState(startOfCurrentDay);

  const lastDayOfWeekDate = lastDayOfWeek(selectedDate, {weekStartsOn: 4});

  const firstDayOfWeekDate = subDays(lastDayOfWeekDate, 6);
  const weekDates: Date[] = eachDayOfInterval({
    start: firstDayOfWeekDate,
    end: lastDayOfWeekDate,
  });

  const isMessageConditionShown =
    !survey.isReadonly(selectedDate, SurveyType.SymptomTracking, timeZone) && !survey.isLoading;

  return (
    <div className={styles.root}>
      <PageTitle className={styles.symptomTitle}>Daily Symptoms</PageTitle>
      {survey.isSavingForm && <Preloader />}
      <TrackingDailyWeek
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        weekDates={weekDates}
        isMessageConditionShown={isMessageConditionShown}
        timeZone={account.timeZone}
        registrationDate={account.info?.registrationDate ?? null}
      />
      <TrackingDailySurvey date={selectedDate} isLoading={survey.isLoading} blankBackground />
    </div>
  );
};

const TrackingDailyScreenObserver = observer(TrackingDailyScreen);
const TrackingDailyScreenInjected: FC = () => (
  <TrackingDailyScreenObserver survey={useInjection(SURVEY_SERVICE)} account={useInjection(ACCOUNT_SERVICE)} />
);

export {TrackingDailyScreenInjected as TrackingDailyScreen};
