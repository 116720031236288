import classNames from 'classnames';
import type {FC} from 'react';
import {useCallback, useMemo, useState} from 'react';
import {BlackCalendarIcon, FlagIcon, InfoIcon, ListIcon} from '../../../assets/icons';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
  IconType,
  List,
  ListItem,
  Modal,
} from '../../../components';
import {CourseIframe} from '../../../course-iframe/components';
import styles from './task-modal-card.module.scss';

enum TaskModalCardDateTestIdEnum {
  Header = 'Header',
  Actions = 'Actions',
  CompleteButton = 'CompleteButton',
  CancelButton = 'CancelButton',
}

enum TaskModalCardStatusEnum {
  Success = 'Success',
  Danger = 'Danger',
}

enum TaskModalCardTypeEnum {
  Lesson = 'Lesson',
  General = 'General',
}

const TaskModalCard: FC<{
  onClose: () => void;
  onComplete: (begin?: boolean) => void;
  date: string;
  behavior: string;
  statusText: string;
  status: string;
  complete: boolean;
  title: string;
  courseLink?: string;
  lmsLink?: string;
  type?: TaskModalCardTypeEnum;
  beginDate?: Date | null;
}> = (props) => {
  const [iframeSrc, setIframeSrc] = useState<string | undefined>('');

  const completeButtonLabel = useMemo(() => (!!props.courseLink ? 'complete lesson' : 'complete'), [props.courseLink]);

  const areActionsShown = props.type === TaskModalCardTypeEnum.Lesson;
  const isLesson = props.type === TaskModalCardTypeEnum.Lesson;

  const buttonLabel = !!props.beginDate ? 'Restart Lesson' : 'Begin Lesson';

  const closeIframe = useCallback(() => {
    setIframeSrc('');
    props.onClose();
  }, [props]);

  const handleComplete = useCallback(() => {
    props.onComplete();
  }, [props]);

  const handleBegin = useCallback(() => {
    setIframeSrc(props.courseLink);
    props.onComplete(true);
  }, [props]);

  const actions = useMemo(
    () => (
      <>
        {areActionsShown && (
          <CardActions data-testid={TaskModalCardDateTestIdEnum.Actions}>
            <Button bold data-testid={TaskModalCardDateTestIdEnum.CancelButton} flat onClick={props.onClose}>
              cancel
            </Button>
            <Button
              bold
              data-testid={TaskModalCardDateTestIdEnum.CompleteButton}
              onClick={handleComplete}
              disabled={props.complete || !props.beginDate}>
              {completeButtonLabel}
            </Button>
          </CardActions>
        )}
      </>
    ),
    [completeButtonLabel, areActionsShown, props.complete, props.beginDate, props.onClose, handleComplete],
  );

  return (
    <Modal onClose={props.onClose}>
      <CourseIframe src={iframeSrc} lmsLink={props.lmsLink} onClose={closeIframe} onComplete={handleComplete} />
      <Card className={styles.card}>
        <CardHeader
          data-testid={TaskModalCardDateTestIdEnum.Header}
          metaText={'Lesson'}
          title={props.title}
          closable
          onClose={props.onClose}>
          {props.courseLink && (
            <>
              <Button className={styles.taskCourseButton} bold onClick={handleBegin}>
                <FlagIcon className={styles.taskCourseButtonIcon} /> {buttonLabel}
              </Button>
              <p className={styles.taskCourseText}>
                When you reach the end of the course, please close the window to return here and complete the lesson to
                remove it from your lesson list.
              </p>
              <div className={styles.taskCourseTipContainer}>
                <InfoIcon className={styles.taskCourseTipIcon} />
                <p className={styles.taskCourseTip}>
                  After completing this course click the “Complete Lesson” button to complete this lesson
                </p>
              </div>
            </>
          )}
        </CardHeader>
        <CardContent>
          <div className={styles.content}>
            <List noStyle>
              <ListItem>
                <div className={styles.listItemTitle}>
                  <BlackCalendarIcon className={styles.listItemIcon} />
                  <span>Date</span>
                </div>
              </ListItem>
              {props.behavior && (
                <ListItem>
                  <div className={styles.listItemTitle}>
                    <ListIcon className={styles.listItemIcon} />
                    <span>Behavior</span>
                  </div>
                </ListItem>
              )}
              {!isLesson && (
                <ListItem>
                  <div className={styles.listItemTitle}>
                    {props.status === TaskModalCardStatusEnum.Success && (
                      <span className={styles.complete}>
                        <Icon type={IconType.Checkmark} stroke={'white'} />
                      </span>
                    )}
                    {props.status === TaskModalCardStatusEnum.Danger && <Icon type={IconType.AlertCircle} />}
                    {props.status === '' && <Icon type={IconType.Clock} />}
                    <span
                      className={classNames(styles.statusText, {
                        [styles.danger]: props.status === TaskModalCardStatusEnum.Danger,
                        [styles.success]: props.status === TaskModalCardStatusEnum.Success,
                      })}>
                      Status
                    </span>
                  </div>
                </ListItem>
              )}
            </List>

            <List noStyle>
              <ListItem>{props.date}</ListItem>
              {props.behavior && <ListItem>{props.behavior}</ListItem>}
              {!isLesson && (
                <ListItem>
                  <span
                    className={classNames({
                      [styles.danger]: props.status === TaskModalCardStatusEnum.Danger,
                      [styles.success]: props.status === TaskModalCardStatusEnum.Success,
                    })}>
                    {props.statusText}
                  </span>
                </ListItem>
              )}
            </List>
          </div>
        </CardContent>
        {actions}
      </Card>
    </Modal>
  );
};

export {TaskModalCard, TaskModalCardDateTestIdEnum, TaskModalCardStatusEnum, TaskModalCardTypeEnum};
