import type {IDocumentDetailsGetResponseDto} from '../repository';
import {DocumentDetailsModel} from './document-details.model';
import {shiftTimezone} from '../../helpers';

class DocumentDetailsModelFactory {
  static fromDetailsGetResponseDto(dto: IDocumentDetailsGetResponseDto): DocumentDetailsModel {
    return new DocumentDetailsModel(
      dto.objectFileId,
      dto.labResultId,
      shiftTimezone(new Date(dto.documentUploadDate)),
      dto.note,
      dto.documentType,
      dto.otherNote,
      dto.mimeType,
      dto.cbc,
      dto.bmp,
      dto.cmp,
      dto.liverFunction,
      dto.lipidPanel,
      dto.drugLevel,
      dto.renalFunction,
      dto.urinalysis,
      dto.tuberculosis,
    );
  }
}

export {DocumentDetailsModelFactory};
