import type {FC} from 'react';
import React from 'react';
import styles from './radio-buttons-image.module.scss';
import type {Control} from 'react-hook-form/dist/types/form';
import type {FieldErrors} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {REQUIRED_FIELD_ERROR} from '../../../helpers';
import {Error} from '../../../components';
import type {ISurveyElementResponse, ISurveySpecElement} from '../../index';
import {RadioButtonsImageItem} from './components';

interface IPropsRadioLadder {
  element: ISurveySpecElement;
  control: Control;
  readonly: boolean;
  errors?: FieldErrors<Record<string, any>>;
  elementValues: ISurveyElementResponse[];
}

const RadioButtonsImage: FC<IPropsRadioLadder> = ({element, control, readonly, errors, elementValues}) => {
  const selectedValue = elementValues.find((elementValue) => elementValue.name === element.name)?.elementOptionId;

  const isError = errors && errors[element.name]?.message;

  return (
    <div className={styles.root}>
      <div className={styles.options}>
        {readonly ? (
          <div className={styles.readonly}>
            {element.options?.map((option) => {
              const value = {
                id: option.elementOptionId,
                text: option.value,
              };

              return option.elementOptionId === selectedValue ? (
                <RadioButtonsImageItem
                  element={element}
                  option={option}
                  value={value}
                  key={option.elementOptionId}
                  readonly
                />
              ) : null;
            })}
          </div>
        ) : (
          !!element.options.length && (
            <Controller
              name={element.name}
              control={control}
              rules={{
                required: element.isRequired ? REQUIRED_FIELD_ERROR : undefined,
              }}
              render={({value, onChange}) => (
                <>
                  {element.options.map((option) => (
                    <RadioButtonsImageItem
                      element={element}
                      option={option}
                      value={value}
                      onChange={onChange}
                      isError={isError}
                      key={option.elementOptionId}
                    />
                  ))}

                  {isError && <Error errorMessage={errors[element.name].message} />}
                </>
              )}
            />
          )
        )}
      </div>
    </div>
  );
};

export {RadioButtonsImage};
