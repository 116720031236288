const checkFormElementIsShown = (
  fieldName: string,
  age?: number,
  areHerpesZosterRelatedMedicationsActive?: boolean,
): boolean => {
  let isShown = true;

  switch (fieldName) {
    case 'q_vaccination_record_hpv':
      isShown = typeof age !== 'number' || (age >= 9 && age <= 26);
      break;
    case 'q_vaccination_record_herpes':
      isShown = areHerpesZosterRelatedMedicationsActive || (typeof age === 'number' && age >= 50);
      break;
    default:
      break;
  }

  return isShown;
};

export {checkFormElementIsShown};
