import type {FC} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import {Button, PageLoader, PageTitle, SecondaryModal} from '../../components';
import type {ILifeQualityFormDto} from '../form';
import {LifeQualityForm} from '../form';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import type {ILifeQualityService} from '../service';
import {LIFE_QUALITY_SERVICE} from '../service';
import styles from './life-quality-screen.module.scss';

interface IServiceProps {
  lifeQualityScreenService: ILifeQualityService;
}

const LifeQualityScreen: FC<IServiceProps> = ({lifeQualityScreenService}) => {
  const [visibleCancelModal, setVisibleCancelModal] = useState(false);

  useEffect(() => {
    lifeQualityScreenService.loadQuestions();
  }, [lifeQualityScreenService]);

  const onSurveyCancel = useCallback(() => {
    setVisibleCancelModal(true);
  }, [setVisibleCancelModal]);

  const onBack = useCallback(() => lifeQualityScreenService.navigateToBack(), [lifeQualityScreenService]);

  const onSubmit = useCallback(
    (data: ILifeQualityFormDto) => {
      lifeQualityScreenService.createAssessment(data);
    },
    [lifeQualityScreenService],
  );

  return (
    <>
      <div className={styles.header}>
        <PageTitle>Quality of Life Scale</PageTitle>
        {!lifeQualityScreenService.isLoading && (
          <Button className={styles.button} onClick={onBack}>
            Back
          </Button>
        )}
      </div>
      {lifeQualityScreenService.isLoading ? (
        <PageLoader />
      ) : (
        <>
          <LifeQualityForm
            listQuestions={lifeQualityScreenService.listQuestions}
            onSubmit={onSubmit}
            onCancel={onSurveyCancel}
          />
        </>
      )}
      {visibleCancelModal && (
        <SecondaryModal
          text={'All answers will be lost. Do you want to cancel the assessment?'}
          onClose={() => setVisibleCancelModal(false)}
          onSubmit={onBack}
        />
      )}
    </>
  );
};

const LifeQualityScreenObserver = observer(LifeQualityScreen);

const LifeQualityScreenInjected: FC = () => (
  <LifeQualityScreenObserver lifeQualityScreenService={useInjection(LIFE_QUALITY_SERVICE)} />
);

export {LifeQualityScreenInjected as LifeQualityScreen};
