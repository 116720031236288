import {AssessmentReportScoreModel, AssessmentReportScoreModelIconKeyEnum} from './assessment-report-score.model';
import {AssessmentReportOverallScoreModel} from './assessment-report-overall-score.model';

class AssessmentReportModel {
  constructor(
    public date = new Date(),
    public overallScore = new AssessmentReportOverallScoreModel(),
    public symptomsScore = new AssessmentReportScoreModel(
      'My Symptoms',
      'There are physical symptoms or medical complications that interfere with my life​',
      AssessmentReportScoreModelIconKeyEnum.Symptoms,
    ),
    public independenceScore = new AssessmentReportScoreModel(
      'My Independence',
      'I need help understanding and managing the various demands of my disease​',
      AssessmentReportScoreModelIconKeyEnum.Independence,
    ),
    public nutritionScore = new AssessmentReportScoreModel(
      'My Nutrition',
      'There is a concern related to my ability to eat, my relationship with food or my nutritional lab values ​',
      AssessmentReportScoreModelIconKeyEnum.Nutrition,
    ),
    public barriersScore = new AssessmentReportScoreModel(
      'My Barriers to Care',
      'There are life circumstances that affect my ability to implement the recommendations of my providers​',
      AssessmentReportScoreModelIconKeyEnum.Barriers,
    ),
    public emotionsScore = new AssessmentReportScoreModel(
      'My Emotions',
      'My emotional health has been affected by my condition or there is a clear connection between my emotions and symptoms.​',
      AssessmentReportScoreModelIconKeyEnum.Emotion,
    ),
  ) {}
}

export {AssessmentReportModel};
