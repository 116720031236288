import type {IMessageThreadRecipientGetResponseDto, IMessageThreadSocketDtoUserAccount} from '../dto';
import {MessageThreadRecipientModel} from './message-thread-recipient.model';
import {UserRoleEnum} from '../../enums';
import {providerTitle} from '../../enums/provider-title';
import type {TrellusTeamMember} from '../../account';

class MessageThreadRecipientModelFactory {
  static fromGetResponseDto(dto: IMessageThreadRecipientGetResponseDto): MessageThreadRecipientModel {
    return new MessageThreadRecipientModel(
      dto.userAccountId,
      dto.userRoleId,
      dto.firstName,
      dto.lastName,
      providerTitle.get(dto.providerTitle),
      dto.providerType,
      dto.providerTypeId,
      {fileId: dto.logoObjectFileId, src: ''},
      true,
      dto.recipientType,
    );
  }

  static fromSocketDto(dto: IMessageThreadSocketDtoUserAccount): MessageThreadRecipientModel {
    return new MessageThreadRecipientModel(
      dto.userAccountId,
      undefined,
      dto.firstName,
      dto.lastName,
      '',
      '',
      undefined,
      {
        fileId: dto.logoObjectFileId,
        src: '',
      },
    );
  }

  static fromTrellusTeamMember(dto: TrellusTeamMember): MessageThreadRecipientModel {
    return new MessageThreadRecipientModel(
      dto.providerId,
      dto.isTriadMember ? UserRoleEnum.Admin : UserRoleEnum.Provider,
      dto.firstName,
      dto.lastName,
      providerTitle.get(dto.providerTitle),
      dto.providerType,
      dto.providerTypeId,
      dto.avatar ? `data:image/png;base64,${dto.avatar}` : '',
      dto.openForCommunicationWithPatient,
    );
  }
}

export {MessageThreadRecipientModelFactory};
