import type {FC} from 'react';
import React from 'react';
import {Button, Card, CardContent, RangeBarLabelsType} from '../../components';
import styles from './strengths-assessment-form.module.scss';
import {useForm} from 'react-hook-form';
import {Question} from './question';
import type {IStrengthsAssessmentFormDto} from '../dto';
import type {StrengthsAssessmentModel} from '../model';
import {SectionTitle} from './section-title';
import {SpecialtyType} from '../../enums';

interface IStrengthsAssessmentFormProps {
  onSubmit: (data: IStrengthsAssessmentFormDto) => void;
  readonly?: boolean;
  allThingsFormElements: IStrengthAssessmentFormElement[];
  trueFormElements: IStrengthAssessmentFormElement[];
  lastFilledAssessment: StrengthsAssessmentModel | null;
  isSubmitDisabled: boolean;
  specialtyType: string;
}

interface IStrengthAssessmentFormElement {
  defaultValue: number;
  name: string;
  label: string;
  minValue: number;
  maxValue: number;
  startLabel?: string;
  endLabel?: string;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const defaultValuesIBD = {
  q_strengths_assessment_how_satisfied_with_life: 0,
  q_strengths_assessment_avoid_situations: 0,
  q_strengths_assessment_rid_of_ibd_symptoms: 0,
  q_strengths_assessment_able_to_adapt: 0,
  q_strengths_assessment_tend_to_bounce: 0,
  q_strengths_assessment_expect_the_best: 0,
  q_strengths_assessment_have_someone_trust: 0,
  q_strengths_assessment_feel_grateful_each_day: 0,
};

const defaultValuesIBS = {
  q_ibs_strengths_assessment_how_satisfied_with_life: 0,
  q_ibs_strengths_assessment_avoid_situations: 0,
  q_ibs_strengths_assessment_rid_of_symptoms: 0,
  q_ibs_strengths_assessment_able_to_adapt: 0,
  q_ibs_strengths_assessment_tend_to_bounce: 0,
  q_ibs_strengths_assessment_expect_the_best: 0,
  q_ibs_strengths_assessment_have_someone_trust: 0,
  q_ibs_strengths_assessment_feel_grateful_each_day: 0,
};

const StrengthsAssessmentForm: FC<IStrengthsAssessmentFormProps> = ({
  onSubmit,
  readonly,
  allThingsFormElements,
  trueFormElements,
  lastFilledAssessment,
  isSubmitDisabled,
  specialtyType,
}) => {
  const {control, handleSubmit} = useForm<IStrengthsAssessmentFormDto>({
    defaultValues: specialtyType === SpecialtyType.IBD ? defaultValuesIBD : defaultValuesIBS,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardContent gap={0}>
          <div className={styles.fields}>
            <SectionTitle
              label={'All things considered'}
              subLabel={'Score with a value from 0—10'}
              additionalSubLabel={'0 indicates no satisfaction and 10 being most satisfied'}
            />
            {allThingsFormElements.map((i, index) => (
              <Question
                key={i.name}
                control={control}
                formElement={i}
                index={index + 1}
                readOnly={readonly}
                lastFilledAssessment={lastFilledAssessment}
              />
            ))}
            <SectionTitle
              label={'How true of you is each statement?'}
              subLabel={'Score with a value from (Always True) — (Never True)'}
            />
            {trueFormElements.map((i, index) => (
              <Question
                key={i.name}
                control={control}
                formElement={i}
                index={index + allThingsFormElements.length + 1}
                readOnly={readonly}
                lastFilledAssessment={lastFilledAssessment}
                type={RangeBarLabelsType.Strings}
              />
            ))}
          </div>
        </CardContent>
      </Card>
      {!readonly && (
        <div className={styles.actions}>
          <Button className={styles.submit} type={'submit'} disabled={isSubmitDisabled}>
            {`Save`}
          </Button>
        </div>
      )}
    </form>
  );
};

export type {IStrengthAssessmentFormElement};
export {StrengthsAssessmentForm};
