import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {
  CircleRemoveRedIcon,
  DocumentImgIcon,
  DocumentPdfIcon,
  DocumentWordIcon,
  DownloadIcon,
  GreenCheckboxIcon,
  RedCloseIcon,
} from '../../../assets/icons';
import styles from './reports.module.scss';
import type {IDocumentsService} from '../../index';
import {DOCUMENTS_SERVICE, DocumentsModal, DocumentsModalDownload, ObserverDocumentModal} from '../../index';
import {CollapseDescription, DateWrapper, PageTitle, SecondaryModal} from '../../../components';
import {useInjection} from '../../../ioc';
import type {IFileStorageService} from '../../../file-storage';
import {FILE_STORAGE_SERVICE} from '../../../file-storage';
import {DocumentTypeStrings} from '../../../enums';
import type {DocumentsModel} from '../../model';

const UPLOADED_BY_SELF = 'Me';

interface IProps {
  documents: IDocumentsService;
  fileStorage: IFileStorageService;
}

const fileIcon = (mimeType: string) => {
  switch (mimeType) {
    case 'image/png':
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/gif':
      return <DocumentImgIcon />;
    case 'application/pdf':
      return <DocumentPdfIcon />;
    case 'application/doc':
    case 'application/docx':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <DocumentWordIcon />;
    default:
      break;
  }
};

const addOtherNote = (otherNote: string) => (otherNote ? `. ${otherNote}` : '');

const ReportsScreen: FC<IProps> = ({documents, fileStorage}) => {
  const [visibleLabResultsModal, setVisibleLabResultsModal] = useState(false);
  const [visibleObserverDocsModal, setVisibleObserverDocsModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<null | DocumentsModel>(null);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    documents.load();
  }, [documents]);

  const noneResult = useMemo(
    () => (
      <div className={styles.noneResult}>
        <div className={styles.noneResultText}>You don’t have any files.</div>
        <button className={styles.btnUploadResult} onClick={() => setVisibleLabResultsModal(true)}>
          Upload
        </button>
      </div>
    ),
    [],
  );

  const modal = useMemo(() => <DocumentsModal onClose={() => setVisibleLabResultsModal(false)} />, []);

  const handleDownloadModal = useCallback((item) => {
    setIsDownloadModalVisible(true);
    setSelectedDocument(item);
  }, []);

  const handlerOpenDocument = useCallback(
    (item) => {
      setVisibleObserverDocsModal(true);
      setSelectedDocument(item);
      documents.loadDetails(item.id);
    },
    [documents],
  );

  const handlerCloseDocument = useCallback(() => {
    setVisibleObserverDocsModal(false);
    setSelectedDocument(null);
  }, []);

  const closeDownloadModal = useCallback(() => {
    setIsDownloadModalVisible(false);
    setSelectedDocument(null);
  }, []);

  const handleDocumentDownload = useCallback(
    (fileId) => {
      fileStorage
        .download(fileId)
        .then(() => {
          documents.addLoadedDocument(fileId, true);
        })
        .catch((e) => {
          console.error(e);
          documents.addLoadedDocument(fileId, false);
        });
    },
    [documents, fileStorage],
  );

  const onDownload = useCallback(
    (document: DocumentsModel) => {
      handleDocumentDownload(document.fileId);
      closeDownloadModal();
    },
    [closeDownloadModal, handleDocumentDownload],
  );

  const downloadModal = useMemo(
    () =>
      selectedDocument ? (
        <DocumentsModalDownload document={selectedDocument} onClose={closeDownloadModal} onDownload={onDownload} />
      ) : null,
    [closeDownloadModal, onDownload, selectedDocument],
  );

  const observerDocsModal = useMemo(
    () =>
      selectedDocument && documents.selectedItem && !documents.isLoading ? (
        <ObserverDocumentModal
          document={selectedDocument}
          documentDetail={documents.selectedItem}
          documentIcon={fileIcon(selectedDocument.mimeType)}
          title={'Document'}
          onClose={handlerCloseDocument}
          onDownload={handleDownloadModal}
        />
      ) : null,
    [selectedDocument, documents.selectedItem, documents.isLoading, handlerCloseDocument, handleDownloadModal],
  );

  const getIcon = useCallback(
    (item: DocumentsModel) => {
      const document = documents.downloadedDocuments?.find((doc) => doc.id === item.fileId);

      if (!!document) {
        const icon = !document?.isError ? <GreenCheckboxIcon /> : <CircleRemoveRedIcon />;

        return <span className={styles.iconType}>{icon}</span>;
      }

      return <span className={styles.mimeType}>{fileIcon(item.mimeType)}</span>;
    },
    [documents.downloadedDocuments],
  );

  const deleteModal = useMemo(() => {
    const onClose = () => {
      setIsDeleteModalVisible(false);
    };

    const onSubmit = () => {
      selectedDocument && documents.deleteDocument(selectedDocument);
      setIsDeleteModalVisible(false);
    };

    return (
      <SecondaryModal onClose={onClose} title={'Are you sure you want to remove uploaded file?'} onSubmit={onSubmit} />
    );
  }, [documents, selectedDocument]);

  return (
    <>
      <div className={styles.labHeader}>
        <div className={styles.topContent}>
          <PageTitle>Reports</PageTitle>
          <button className={styles.actionBtn} onClick={() => setVisibleLabResultsModal(true)}>
            Upload
          </button>
        </div>
      </div>
      <CollapseDescription>
        <p>
          We know it can be hard to keep track of all your procedures and lab results over time. We wanted to make it
          easier for you to find your key reports and be able to share them with any member of your healthcare team.
        </p>
      </CollapseDescription>

      {!!documents?.items.length ? (
        <table className={styles.labTable}>
          <thead>
            <tr className={styles.labTableHeader}>
              <th className={styles.labTableHeaderItem}>File</th>
              <th className={styles.labTableHeaderItem}>Uploaded by</th>
              <th className={styles.labTableHeaderItem}>Uploaded Date</th>
              <th className={styles.labTableHeaderItem}>Type</th>
              <th className={styles.labTableHeaderItem} />
              <th className={styles.labTableHeaderItem} />
            </tr>
          </thead>
          <tbody className={styles.labTableBody}>
            {documents?.items.map((item, key) => (
              <tr key={key} className={styles.labTableBodyItem} onClick={() => handlerOpenDocument(item)}>
                <td className={styles.labTableBodyItemCell}>
                  {getIcon(item)}
                  <div className={styles.filename}>{item.fileName}</div>
                </td>
                <td className={styles.labTableBodyItemCell}>
                  <div className={styles.createdBy}>
                    <div className={styles.createdByName}>{item.createdByFullName}</div>
                    <div className={styles.createdByType}>{item.createdByType}</div>
                  </div>
                </td>
                <td className={styles.labTableBodyItemCell}>
                  {item.created ? <DateWrapper date={item.created} /> : ''}
                </td>
                <td className={styles.labTableBodyItemCell}>
                  {DocumentTypeStrings.get(item.type) + addOtherNote(item.otherNote)}
                </td>
                <td className={styles.labTableBodyItemCell}>
                  <button
                    data-testid={`Download-${item.id}`}
                    type="button"
                    className={styles.labTableButton}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDownloadModal(item);
                    }}>
                    <DownloadIcon className={styles.downloadIcon} />
                    Download
                  </button>
                </td>
                <td className={styles.labTableBodyItemCell}>
                  {item.createdByFullName === UPLOADED_BY_SELF && (
                    <RedCloseIcon
                      className={styles.closeIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDocument(item);
                        setIsDeleteModalVisible(true);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        noneResult
      )}

      {visibleLabResultsModal && modal}
      {visibleObserverDocsModal && observerDocsModal}
      {isDownloadModalVisible && downloadModal}
      {isDeleteModalVisible && deleteModal}
    </>
  );
};

const ObserverReportsScreen = observer(ReportsScreen);
const InjectedReportsScreen: FC = () => (
  <ObserverReportsScreen documents={useInjection(DOCUMENTS_SERVICE)} fileStorage={useInjection(FILE_STORAGE_SERVICE)} />
);

export {InjectedReportsScreen as ReportsScreen};
