import type {FC} from 'react';
import {ReactComponent as TrellisBuild1} from '../../../assets/trellis-build/NewTrellisBuild-01.svg';
import {ReactComponent as TrellisBuild2} from '../../../assets/trellis-build/NewTrellisBuild-02.svg';
import {ReactComponent as TrellisBuild3} from '../../../assets/trellis-build/NewTrellisBuild-03.svg';
import {ReactComponent as TrellisBuild4} from '../../../assets/trellis-build/NewTrellisBuild-04.svg';
import {ReactComponent as TrellisBuild5} from '../../../assets/trellis-build/NewTrellisBuild-05.svg';
import {ReactComponent as TrellisBuild6} from '../../../assets/trellis-build/NewTrellisBuild-06.svg';

export const Build: FC<{stage?: number}> = ({stage = 0}) => {
  if (stage > 5) {
    return <TrellisBuild6 />;
  }

  switch (stage) {
    case 1:
      return <TrellisBuild2 />;
    case 2:
      return <TrellisBuild3 />;
    case 3:
      return <TrellisBuild4 />;
    case 4:
      return <TrellisBuild5 />;
    case 5:
      return <TrellisBuild6 />;
    default:
      return <TrellisBuild1 />;
  }
};
