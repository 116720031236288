import type {FC} from 'react';
import {useEffect, useMemo} from 'react';
import {Button, Card, CardContent} from '../../components';
import styles from './vaccination-form.module.scss';
import {VaccinationFormElement} from './form-element';
import {FormProvider, useForm} from 'react-hook-form';
import {getVaccinationFormElements} from './vaccination-form-elements';
import type {VaccinationFormDto} from './form-dto';
import {checkFormElementIsShown} from './helpers';

interface IVaccinationOption {
  label: string;
  value: string;
}

interface IVaccinationFormElement {
  name: string;
  label: string;
  yesScreenMessage?: string;
  noScreenMessage?: string;
  options: Array<IVaccinationOption>;
  tipMessage?: string;
  isUploadLinkNeeded?: boolean;
  dateSelection1Name?: string;
  dateSelection2Name?: string;
  covidVaccineName?: string;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

interface IVaccinationFormProps {
  onSubmit: (dto: VaccinationFormDto) => void;
  age?: number;
  areHerpesZosterRelatedMedicationsActive?: boolean;
  defaultValues: VaccinationFormDto;
  isNewForm: boolean;
  isIbdSpecialty: boolean;
}

const VaccinationForm: FC<IVaccinationFormProps> = (props) => {
  const {reset, ...formMethods} = useForm<VaccinationFormDto>({
    defaultValues: props.defaultValues,
    mode: 'onChange',
  });

  const elements = useMemo(
    () =>
      getVaccinationFormElements(props.isIbdSpecialty).map((element) =>
        checkFormElementIsShown(element.name, props.age, props.areHerpesZosterRelatedMedicationsActive) ? (
          <VaccinationFormElement key={element.name} element={element} />
        ) : null,
      ),
    [props.age, props.areHerpesZosterRelatedMedicationsActive, props.isIbdSpecialty],
  );

  useEffect(() => {
    reset(props.defaultValues);
  }, [props.defaultValues, reset]);

  return (
    <FormProvider {...formMethods} reset={reset}>
      <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>
        <Card>
          <CardContent gap={0}>
            <div className={styles.fields}>
              <div className={styles.fieldsTitle} tabIndex={0}>
                Do you have a record of receiving the following vaccination?
              </div>
              {elements}
            </div>
          </CardContent>
        </Card>
        <div className={styles.actions}>
          <Button
            type="submit"
            disabled={
              (props.isNewForm
                ? !formMethods.formState.isValid
                : !(formMethods.formState.isValid && formMethods.formState.isDirty)) ||
              formMethods.formState.isSubmitted
            }
            className={styles.submit}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export {VaccinationForm};
export type {IVaccinationFormElement, IVaccinationOption, IVaccinationFormProps};
