import type {FC} from 'react';
import React from 'react';
import type {IOption} from '../../../types';
import styles from './unit-dropdown-item.module.scss';

interface IUnitDropdownItemProps {
  unit: IOption;
  onClick: (item: IOption) => void;
}

const UnitDropdownItem: FC<IUnitDropdownItemProps> = ({unit, onClick}) => (
  <div
    className={styles.item}
    onClick={() => {
      onClick(unit);
    }}
    data-testid={`UnitDropdownItem${unit.value}`}>
    {unit.displayName}
  </div>
);

export {UnitDropdownItem};
