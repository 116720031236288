import type {FC} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import {useFormConfirmationDialog, useQuery} from '../../hooks';
import styles from './chronic-condition-checklist-survey.module.scss';
import {Button, CollapseDescription, PageLoader, PageTitle, SecondaryModal} from '../../components';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import type {IChronicConditionChecklistFormDto, IChronicConditionChecklistService} from '../service';
import {CHRONIC_CONDITION_CHECKLIST_SERVICE} from '../service';
import {ChronicConditionChecklistForm, ChronicConditionChecklistSubHeader} from './components';

interface IServiceProps {
  chronicConditionChecklist: IChronicConditionChecklistService;
}

const ChronicConditionChecklistSurvey: FC<IServiceProps> = ({chronicConditionChecklist}) => {
  const [visibleCancelModal, setVisibleCancelModal] = useState(false);

  const {id: assessmentId} = useQuery<{id: number}>();

  const {getConfirmation} = useFormConfirmationDialog();

  useEffect(() => {
    if (assessmentId) {
      chronicConditionChecklist.loadAssessmentById(assessmentId);
    } else {
      chronicConditionChecklist.loadAssessments();
    }
  }, [assessmentId, chronicConditionChecklist]);

  const selectedAssessment =
    chronicConditionChecklist.selectedAssessment || chronicConditionChecklist.lastFilledAssessment;

  const readonly = !!assessmentId || chronicConditionChecklist.isReadonly;

  const onSurveyCancel = useCallback(() => {
    setVisibleCancelModal(true);
  }, [setVisibleCancelModal]);

  const onBack = useCallback(() => chronicConditionChecklist.navigateToList(), [chronicConditionChecklist]);

  const onSubmit = useCallback(
    async (data: IChronicConditionChecklistFormDto) => {
      const isConfirmed = await getConfirmation({});

      if (isConfirmed) {
        chronicConditionChecklist.createAssessment(data);
      }
    },
    [chronicConditionChecklist, getConfirmation],
  );

  return (
    <>
      <div className={styles.header}>
        <PageTitle>Chronic Condition Checklist</PageTitle>
        {readonly && !chronicConditionChecklist.isLoading && (
          <Button className={styles.button} onClick={onBack}>
            Back
          </Button>
        )}
      </div>
      {chronicConditionChecklist.isLoading ? (
        <PageLoader />
      ) : (
        <>
          <ChronicConditionChecklistSubHeader date={selectedAssessment?.lastUpdated} />
          {!readonly && (
            <CollapseDescription>
              <p>
                We understand that having one condition can be hard enough, so it would be helpful to keep track of
                other health issues you may have or are experiencing so we can ensure we support you every step of the
                way.
              </p>
              <p>Please check all that apply on the list below</p>
            </CollapseDescription>
          )}
          <ChronicConditionChecklistForm
            selectedAssessmentItems={selectedAssessment?.items ?? null}
            diagnoses={chronicConditionChecklist.listDiagnoses}
            readonly={readonly}
            onSubmit={onSubmit}
            onCancel={onSurveyCancel}
          />
        </>
      )}
      {visibleCancelModal && (
        <SecondaryModal
          text={'All answers will be lost. Do you want to cancel the survey?'}
          onClose={() => setVisibleCancelModal(false)}
          onSubmit={onBack}
        />
      )}
    </>
  );
};

const ChronicConditionChecklistSurveyObserver = observer(ChronicConditionChecklistSurvey);

const ChronicConditionChecklistSurveyInjected: FC = () => (
  <ChronicConditionChecklistSurveyObserver
    chronicConditionChecklist={useInjection(CHRONIC_CONDITION_CHECKLIST_SERVICE)}
  />
);

export {ChronicConditionChecklistSurveyInjected as ChronicConditionChecklistSurvey};
