import React, {forwardRef, useMemo, useCallback} from 'react';
import type {KeyboardEventHandler} from 'react';
import styles from './datepicker-input.module.scss';
import classNames from 'classnames';
import {BlackCalendarIcon, GreyCalendarIcon} from '../../../../assets/icons';
import {DatePickerInputType} from '../../datepicker.enum';

interface IDatepickerInputProps {
  name?: string;
  label?: string;
  value: string;
  disabled?: boolean;
  type?: DatePickerInputType;
  isValid?: boolean;
  onClick?: () => void;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const DatepickerInput = forwardRef<HTMLInputElement, IDatepickerInputProps>(
  ({name, label, value, disabled, type, isValid, onClick, ariaLabelError, ariaLabelTitle}, ref) => {
    const onEnterPress: KeyboardEventHandler = useCallback(
      (e) => {
        if (e.code === 'Enter') {
          return onClick && onClick();
        }
      },
      [onClick],
    );

    const calendarIcon = useMemo(() => {
      const calendarClassName = classNames(styles.calendarIcon, {
        [styles.calendarIconDefault]: type === DatePickerInputType.Default,
        [styles.calendarIconSmall]: type === DatePickerInputType.Small,
        [styles.calendarIconWide]: type === DatePickerInputType.Wide,
        [styles.calendarIconLabResults]: type === DatePickerInputType.LabResults,
        [styles.calendarIconMedications]: type === DatePickerInputType.Medications,
      });

      return disabled || type === DatePickerInputType.Medications ? (
        <GreyCalendarIcon className={calendarClassName} onClick={onClick} />
      ) : (
        <BlackCalendarIcon
          className={calendarClassName}
          onClick={onClick}
          onKeyPress={onEnterPress}
          role="button"
          tabIndex={0}
        />
      );
    }, [disabled, onClick, type, onEnterPress]);

    const title = ariaLabelTitle ?? name;
    const error = !!ariaLabelError ? `${ariaLabelError}.` : '';

    return (
      <div
        className={classNames(styles.inputWrapper, {
          [styles.inputWrapperDisabled]: disabled,
          [styles.inputWrapperLabResults]: type === DatePickerInputType.LabResults,
          [styles.inputWrapperMedications]: type === DatePickerInputType.Medications,
        })}>
        <input
          ref={ref}
          name={name ?? 'date-picker-input'}
          aria-label={`${error} ${title}`}
          type="text"
          data-testid="date-picker-input"
          className={classNames(styles.input, {
            [styles.inputDisabled]: disabled,
            [styles.typeDefault]: type === DatePickerInputType.Default,
            [styles.typeSmall]: type === DatePickerInputType.Small,
            [styles.typeWide]: type === DatePickerInputType.Wide,
            [styles.typeLabResults]: type === DatePickerInputType.LabResults,
            [styles.typeMedications]: type === DatePickerInputType.Medications,
            [styles.isValid]: isValid === false,
            [styles.isValidBackground]:
              isValid === false && (type === DatePickerInputType.Medications || type === DatePickerInputType.Default),
          })}
          value={value}
          onClick={onClick}
          tabIndex={0}
          onKeyPress={onEnterPress}
          onChange={() => null}
          placeholder={label}
          autoComplete="off"
          role="listbox"
          readOnly
        />
        {calendarIcon}
      </div>
    );
  },
);

export {DatepickerInput};
