import type {FC} from 'react';
import React, {useMemo} from 'react';
import {Avatar, DateWrapper} from '../../../../../components';
import styles from './message-thread.module.scss';
import classNames from 'classnames';
import type {MessageThreadModel} from '../../../../../message';
import {MERIDIEM_TIME_FORMAT} from '../../../../../helpers';

interface IMessageThreadProps {
  thread: MessageThreadModel;
}

const MessageThread: FC<IMessageThreadProps> = ({thread}) => {
  const metaDate = useMemo(
    () =>
      !!thread.previewText && (
        <>
          {thread.lastMessageDate && (
            <span className={styles.date}>
              <DateWrapper date={thread.lastMessageDate} format={MERIDIEM_TIME_FORMAT} />
            </span>
          )}
          <p className={styles.text}>{thread.previewText}</p>
        </>
      ),
    [thread.lastMessageDate, thread.previewText],
  );

  return (
    <div
      className={classNames(styles.root, {
        [styles.rootWithoutText]: !thread.previewText,
      })}>
      {thread.hasNewMessages && (
        <div className={styles.newMessages}>
          <span className={styles.newMessagesSpan} />
        </div>
      )}
      <Avatar className={styles.avatar} src={thread.recipient.avatar} alt={thread.recipient.name} size={40} />
      <p className={styles.title}>{thread.recipient.name}</p>
      <p className={styles.type}>{thread.recipient.type}</p>
      {metaDate}
    </div>
  );
};

export {MessageThread};
export type {IMessageThreadProps};
