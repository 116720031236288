import type {ISurveyGetResponsesResponseDtoElementResponse} from '../repository';
import type {ISurveyElementResponse} from './survey.model';

class SurveyResponsesFactory {
  static fromDtoResponses(dto: ISurveyGetResponsesResponseDtoElementResponse): ISurveyElementResponse {
    const response: ISurveyElementResponse = {
      elementId: dto.elementId,
      name: dto.name,
      value: dto.value,
    };

    if (dto.dynamicSectionIndex) {
      response.dynamicSectionIndex = dto.dynamicSectionIndex;
    }

    if (dto.elementOptionId) {
      response.elementOptionId = dto.elementOptionId;
    }

    if (dto.elementOptionName) {
      response.elementOptionName = dto.elementOptionName;
    }

    if (dto.comment) {
      response.comment = dto.comment;
    }

    return response;
  }
}

export {SurveyResponsesFactory};
