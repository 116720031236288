import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {INotificationsRepository} from '../repository';
import {NOTIFICATIONS_REPOSITORY, NotificationsRepository} from '../repository';
import type {INotificationService} from '../service';
import {NOTIFICATIONS_SERVICE, NotificationsService} from '../service';

const NotificationsModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<INotificationsRepository>(NOTIFICATIONS_REPOSITORY).to(NotificationsRepository).inSingletonScope();

  bind<INotificationService>(NOTIFICATIONS_SERVICE).to(NotificationsService).inSingletonScope();
});

export {NotificationsModule};
