import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IGlobalImprovementScaleRepository} from '../repository';
import {GLOBAL_IMPROVEMENT_SCALE_REPOSITORY, GlobalImprovementScaleRepository} from '../repository';
import type {IGlobalImprovementScaleService} from '../service';
import {GLOBAL_IMPROVEMENT_SCALE_SERVICE, GlobalImprovementScaleService} from '../service';

const GlobalImprovementScaleModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IGlobalImprovementScaleRepository>(GLOBAL_IMPROVEMENT_SCALE_REPOSITORY)
    .to(GlobalImprovementScaleRepository)
    .inSingletonScope();

  bind<IGlobalImprovementScaleService>(GLOBAL_IMPROVEMENT_SCALE_SERVICE)
    .to(GlobalImprovementScaleService)
    .inSingletonScope();
});

export {GlobalImprovementScaleModule};
