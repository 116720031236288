import type {
  ISelfEfficacyAssessmentQuery,
  ISelfEfficacyAssessmentRepository,
} from './self-efficacy-assessment.repository.interface';
import {inject, injectable} from 'inversify';
import type {SelfEfficacyAssessmentModel, SelfEfficacyAssessmentQuestionsModel} from '../model';
import {SelfEfficacyAssessmentModelFactory, SelfEfficacyAssessmentQuestionsModelFactory} from '../model';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {ISelfEfficacyAssessmentGetResponseDto, ISelfEfficacyAssessmentQuestionsGetResponseDto} from './dto';
import {ISelfEfficacyAssessmentSaveRequestDto} from './dto';

@injectable()
class SelfEfficacyAssessmentRepository implements ISelfEfficacyAssessmentRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(query?: Partial<ISelfEfficacyAssessmentQuery>): Promise<SelfEfficacyAssessmentModel | null> {
    let model: SelfEfficacyAssessmentModel | null = null;

    if (query?.id) {
      const {data: dto} = await this._http.get<ISelfEfficacyAssessmentGetResponseDto>(`SelfEfficacy/${query.id}`);

      model = SelfEfficacyAssessmentModelFactory.fromGetDto(dto);
    }

    return model;
  }

  async list(): Promise<SelfEfficacyAssessmentModel[]> {
    const response = await this._http.get<ISelfEfficacyAssessmentGetResponseDto[]>(`SelfEfficacy`);

    return response.data?.map((i) => SelfEfficacyAssessmentModelFactory.fromGetDto(i)) ?? [];
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async save(model: SelfEfficacyAssessmentModel): Promise<void> {
    const postDto = new ISelfEfficacyAssessmentSaveRequestDto(model.patientId, model.items);
    await this._http.post<number>('SelfEfficacy/selfefficacyassessment', postDto);
  }

  async listQuestions(): Promise<SelfEfficacyAssessmentQuestionsModel[]> {
    const {data} = await this._http.get<ISelfEfficacyAssessmentQuestionsGetResponseDto[]>(
      'SelfEfficacy/selfefficacysurvey',
    );

    return data.map((dto) => SelfEfficacyAssessmentQuestionsModelFactory.fromGetDto(dto));
  }
}

export {SelfEfficacyAssessmentRepository};
