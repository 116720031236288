import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IPreVisitService} from '../service';
import {PREVISIT_SERVICE, PreVisitService} from '../service';

const PreVisitModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IPreVisitService>(PREVISIT_SERVICE).to(PreVisitService).inSingletonScope();
});

export {PreVisitModule};
