import type {IAccountDiseaseGetRequestDto} from '../repository';
import {AccountDiseaseModel} from './account-disease.model';

class AccountDiseaseModelFactory {
  static fromDto(dto: IAccountDiseaseGetRequestDto): AccountDiseaseModel {
    return new AccountDiseaseModel(dto.disease?.id ?? null, dto.diagnosisElapsedTime, dto.specialty?.id ?? null);
  }
}

export {AccountDiseaseModelFactory};
