import type {FieldErrors} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {Error, InputNumber, SingleDropdown} from '../../../../components';
import type {FC} from 'react';
import React, {useMemo} from 'react';
import type {Control} from 'react-hook-form/dist/types/form';
import {LabTests, UnitsTypesEnum} from '../../../enums';
import {unitTypesOptions} from '../../../options';
import styles from './lab-results-common-result-element.module.scss';
import type {LabResultsFormDto} from '../../form-dto';
import {optionsFromMapHelper, REQUIRED_FIELD_ERROR} from '../../../../helpers';
import {InputNumberTypes} from '../../../../components/input-number/input-number.component';

const MAX_VALUE = 99999.9;

const LabResultsCommonResultElement: FC<{control: Control; type: LabTests; errors: FieldErrors<LabResultsFormDto>}> = (
  props,
) => {
  const options: Array<{value: number; displayName: string}> = useMemo(() => {
    const unitOptions = optionsFromMapHelper(unitTypesOptions);

    switch (props.type) {
      case LabTests.VitaminD:
        return unitOptions.filter(
          (option) =>
            option.value === UnitsTypesEnum.Blank ||
            option.value === UnitsTypesEnum.Other ||
            option.value === UnitsTypesEnum.NgPerMilliLiter ||
            option.value === UnitsTypesEnum.NmolPerLiter,
        );
      case LabTests.Hemoglobin:
      case LabTests.Albumin:
        return unitOptions.filter(
          (option) =>
            option.value === UnitsTypesEnum.Blank ||
            option.value === UnitsTypesEnum.Other ||
            option.value === UnitsTypesEnum.GramPerDeciLiter,
        );
      case LabTests.FecalCalprotectin:
        return unitOptions.filter(
          (option) =>
            option.value === UnitsTypesEnum.Blank ||
            option.value === UnitsTypesEnum.Other ||
            option.value === UnitsTypesEnum.McgPerGram,
        );
      default:
        return [];
    }
  }, [props.type]);

  return (
    <>
      <div className={styles.root}>
        <Controller
          name={'value'}
          control={props.control}
          rules={{
            required: REQUIRED_FIELD_ERROR,
            validate: (value) => (value !== null && value !== undefined) || REQUIRED_FIELD_ERROR,
          }}
          render={({value, onChange, name}) => (
            <InputNumber
              className={styles.numeric}
              name={name}
              ariaLabelTitle="Result value"
              ariaLabelError={props.errors['value']?.message}
              onChange={onChange}
              value={value}
              isValid={!props.errors['value']?.message}
              numberInputType={InputNumberTypes.Decimal}
              maxValue={MAX_VALUE}
            />
          )}
        />
        <Controller
          name={'unitType'}
          control={props.control}
          rules={{
            required: REQUIRED_FIELD_ERROR,
            validate: (value) => value !== UnitsTypesEnum.Blank || REQUIRED_FIELD_ERROR,
          }}
          render={({value, onChange, name}) => {
            const currentValue = options.find((option) => option.value === value);

            return (
              <SingleDropdown
                className={styles.unitsDropdown}
                name={name}
                ariaLabelTitle="Result unit type"
                ariaLabelError={props.errors['unitType']?.message}
                onChange={(option) => onChange(option.value)}
                value={currentValue}
                options={options}
                showInputValueAlways
                isError={!!props.errors['unitType']?.message}
              />
            );
          }}
        />
      </div>
      {(!!props.errors['value']?.message && <Error errorMessage={props.errors['value'].message} />) ||
        (!!props.errors['unitType']?.message && <Error errorMessage={props.errors['unitType'].message} />)}
    </>
  );
};

export {LabResultsCommonResultElement};
