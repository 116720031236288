import type {FC} from 'react';
import React, {useMemo} from 'react';
import styles from './encounter-details.module.scss';
import {Card, CardContent, CardHeader} from '../../../../../components';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../../../../helpers';
import {EncounterDetailsFollowUp} from '../';
import {providerTitle} from '../../../../../enums/provider-title';
import type {IEncounterAssessmentAndPlanSection} from '../../../../../encounter/models';
import {EncounterAssessmentAndPlan} from '../encounter-assessment-and-plan';

interface IEncounterDetailsProps {
  dateVisit: Date;
  nextRecommendedVisitInfo: string;
  providerFullName: string;
  providerTitle: string;
  type: string;
  visitDuration: number;
  onClose: () => void;
  assessmentAndPlan: IEncounterAssessmentAndPlanSection | null;
}

const EncounterDetails: FC<IEncounterDetailsProps> = ({
  dateVisit,
  nextRecommendedVisitInfo,
  providerFullName,
  providerTitle: providerTitleProp,
  type,
  visitDuration,
  onClose,
  assessmentAndPlan,
}) => {
  const date = useMemo(() => `Date · ${format(dateVisit, DEFAULT_FORMAT)}`, [dateVisit]);
  const provider = useMemo(
    () => `${providerFullName}, ${providerTitle.get(String(providerTitleProp))}`,
    [providerFullName, providerTitleProp],
  );

  const isAssessmentAndPlanSectionVisible = useMemo(
    () =>
      !!assessmentAndPlan?.goals || !!assessmentAndPlan?.targets.length || !!assessmentAndPlan?.interventions.length,
    [assessmentAndPlan?.goals, assessmentAndPlan?.interventions.length, assessmentAndPlan?.targets.length],
  );

  return (
    <Card className={styles.card}>
      <CardHeader
        title="Encounter Summary"
        closable
        noBorder
        onClose={onClose}
        className={styles.headerRootClassName}
        titleLarge
      />
      <CardContent className={styles.contentRootClassName}>
        <div className={styles.date}>{date}</div>
        <div className={styles.details}>
          <div>
            <div className={styles.detailLabel}>Encounter type</div>
            <div className={styles.detailValue}>{type}</div>
          </div>
          <div>
            <div className={styles.detailLabel}>Provider</div>
            <div className={styles.detailValue}>{provider}</div>
          </div>
        </div>
        {isAssessmentAndPlanSectionVisible && <EncounterAssessmentAndPlan assessmentAndPlan={assessmentAndPlan} />}
        <EncounterDetailsFollowUp nextRecommendedVisitInfo={nextRecommendedVisitInfo} visitDuration={visitDuration} />
      </CardContent>
    </Card>
  );
};

export {EncounterDetails};
export type {IEncounterDetailsProps};
