import type {FC} from 'react';
import React from 'react';
import styles from './medications-card-row.module.scss';
import classNames from 'classnames';

const MedicationsCardRow: FC<{className?: string}> = ({children, className}) => (
  <div className={classNames(styles.root, className)}>{children}</div>
);

export {MedicationsCardRow};
