import type {FC} from 'react';
import {Icon, IconType} from '../../../../components';
import {formatDate, LESSON_COMPLETE_DATE} from '../../../../helpers';
import styles from './complete.module.scss';

interface IOwnProps {
  completeDate: Date;
  showRead?: boolean;
  timeZone: string;
}

export const Complete: FC<IOwnProps> = ({showRead = false, completeDate, timeZone}) => (
  <div className={styles.complete}>
    <Icon type={IconType.CheckmarkGreen} className={styles.success} />
    <div className={styles.column}>
      <p>
        <b>{showRead ? 'Read' : 'Completed'}</b>
      </p>
      {formatDate(completeDate, LESSON_COMPLETE_DATE, timeZone)}
    </div>
  </div>
);
