import React, {useState} from 'react';
import styles from './rate.module.scss';
import classnames from 'classnames';

type RateValue = number;

interface IRateProps {
  name: string;
  max: RateValue;
  value: RateValue;

  onChange(value: RateValue): void;
  onSubmitStars: () => void;
}

const Rate: React.FC<IRateProps> = ({max, onChange, value, onSubmitStars}) => {
  const [hover, setHover] = useState(0);

  return (
    <ul className={styles.root}>
      <div className="star-rating">
        {[...Array(max)].map((_, index) => {
          index += 1;

          return (
            <button
              type="button"
              key={index}
              className={classnames(styles.button, index <= (hover || value) ? styles.on : styles.off)}
              onClick={() => {
                onChange(index);
                onSubmitStars();
              }}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(value)}>
              <span className="star">&#9733;</span>
            </button>
          );
        })}
      </div>
    </ul>
  );
};
export {Rate};
export type {IRateProps, RateValue};
