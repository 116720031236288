enum UnitsTypesEnum {
  Blank = 0,
  MgPerLiter = 1,
  MgPerDeciLiter = 2,
  McgPerLiter = 3,
  McgPerGram = 4,
  NgPerMilliLiter = 5,
  NmolPerLiter = 6,
  GramPerDeciLiter = 7,
  Other = 8,
}

export {UnitsTypesEnum};
