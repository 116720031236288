import type {HealthMonitorGraphTypes} from './health-monitor-graph-types.enum';

class HealthMonitorNavigationModel {
  constructor(
    public value: HealthMonitorGraphTypes | null = null,
    public displayName: string = '',
    public displayOrder: number = 0,
    public selectedByDefault: boolean = false,
    public periodFormatChange: boolean = false,
    public subElements: HealthMonitorNavigationModel[] = [],
  ) {}
}

export {HealthMonitorNavigationModel};
