import {MedicationsUnitsTypesEnum} from './medications-units-types.enum';
import {MedicationsFrequenciesTypesEnum} from './medications-frequencies-types.enum';
import {MedicationsModel} from './medications.model';
import type {MedicationsCourseDiscontinueModel} from './medications-course-discontinue.model';
import {frequencyTypesOptions, unitTypesOptions} from './medications-options';
import {isNumber} from 'lodash';

class MedicationsCourseModel {
  constructor(
    public id = -1,
    public patientId = -1,
    public medication = new MedicationsModel(),
    public discontinue: MedicationsCourseDiscontinueModel | null = null,
    public start: Date | null = null,
    public end: Date | null = null,
    public dose: number | null = null,
    public unit = MedicationsUnitsTypesEnum.BlankPerNull,
    public frequency: MedicationsFrequenciesTypesEnum | string = MedicationsFrequenciesTypesEnum.BlankPerNull,
    public frequencyOther: string = '',
    public isDiscontinued: boolean = false,
    public discontinuedDate: Date | null = null,
    public reason: number | string = 0,
    public reasonOther: string = '',
    public createdBy = '',
    public created: Date | null = null,
    public updatedBy = '',
    public lastUpdated: Date | null = null,
  ) {}

  get doseStr(): string {
    const DASH_DECORATE_EMPTY = '—';

    const dose = (this.dose && this.dose !== -1) || this.dose === 0 ? this.dose : '';
    const unit = this.unit ? unitTypesOptions.get(this.unit) : DASH_DECORATE_EMPTY;
    const frequency = typeof this.frequency === 'number' ? frequencyTypesOptions.get(this.frequency) : this.frequency;
    const separatorComma = unit && frequency ? ', ' : '';

    return (isNumber(dose) && unit) || frequency ? `${dose} ${unit}${separatorComma}${frequency}` : DASH_DECORATE_EMPTY;
  }
}

export {MedicationsCourseModel};
