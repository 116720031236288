import {useCallback, useEffect, useState} from 'react';
import {differenceInMilliseconds} from 'date-fns';

const isDocumentHidden = (): boolean => document.hidden;

const DOCUMENT_HIDDEN_STATUS_CHANGE_EVENT = 'visibilitychange';

const useDocumentHiddenStatus = () => {
  const [isTabInactive, setIsTabInactive] = useState(isDocumentHidden);
  const listener = useCallback(() => {
    setIsTabInactive(isDocumentHidden);
  }, []);

  useEffect(() => {
    document.addEventListener(DOCUMENT_HIDDEN_STATUS_CHANGE_EVENT, listener, false);

    return () => {
      document.removeEventListener(DOCUMENT_HIDDEN_STATUS_CHANGE_EVENT, listener, false);
    };
  }, [listener]);

  return isTabInactive;
};

type UseTabInactivityStatusCallback = (inactivityTimeMilliseconds: number) => void;

const useTabInactivityStatus = (onRevive: UseTabInactivityStatusCallback) => {
  const [inactivityStart, setInactivityStart] = useState<Date>();
  const isTabInactive = useDocumentHiddenStatus();

  const commitInactivityStatus = useCallback(() => {
    if (!inactivityStart) {
      setInactivityStart(new Date());
    }
  }, [inactivityStart]);

  const clearInactivityStatus = useCallback(() => setInactivityStart(undefined), []);

  const commitReviveStatus = useCallback(() => {
    if (inactivityStart) {
      const inactivityEnd = new Date();

      const inactivitySeconds = Math.abs(differenceInMilliseconds(inactivityStart, inactivityEnd));

      onRevive(inactivitySeconds);

      clearInactivityStatus();
    }
  }, [clearInactivityStatus, inactivityStart, onRevive]);

  useEffect(() => {
    if (isTabInactive) {
      commitInactivityStatus();
    } else {
      commitReviveStatus();
    }
  }, [commitInactivityStatus, commitReviveStatus, isTabInactive]);
};

export {useTabInactivityStatus};
export type {UseTabInactivityStatusCallback};
