import type {FC} from 'react';
import React, {useCallback, useMemo} from 'react';
import {Card, CardContent} from '../../components';
import styles from './assessments-table.module.scss';
import type {IAssessmentsTableRow} from './components';
import {AssessmentsTableRow} from './components';

interface IAssessmentsTableProps {
  header: string[];
  rows: IAssessmentsTableRow[];
  onClickRow?: (id: number) => void;
  noResultsMessage?: string;
}

const NO_RESULT_MESSAGE_DEFAULT = 'You don’t have data';

const AssessmentsTable: FC<IAssessmentsTableProps> = ({
  header,
  rows,
  onClickRow,
  noResultsMessage = NO_RESULT_MESSAGE_DEFAULT,
}) => {
  const tableHeader = useMemo(
    () =>
      header.map((i) => (
        <th key={i} className={styles.tableHeader}>
          {i}
        </th>
      )),
    [header],
  );

  const onClick = useCallback(
    (id) => {
      if (onClickRow) {
        onClickRow(id);
      }
    },
    [onClickRow],
  );

  const tableBody = useMemo(
    () => rows.map((row) => <AssessmentsTableRow key={row.id} row={row} onClick={onClick} />),
    [onClick, rows],
  );

  return !!rows.length ? (
    <Card>
      <table className={styles.table}>
        <thead>
          <tr>{tableHeader}</tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </table>
    </Card>
  ) : (
    <CardContent className={styles.noResultsContainer} center gapY={3}>
      <div className={styles.noResultsMessage}>{noResultsMessage}</div>
    </CardContent>
  );
};

export {AssessmentsTable};
