import type {IDocumentsRepository, IDocumentsRepositoryQuery} from './documents.repository.interface';
import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {DocumentDetailsModel, DocumentsModel} from '../model';
import {DocumentDetailsModelFactory, DocumentsModelFactory} from '../model';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {IDocumentDetailsGetResponseDto, IDocumentsGetResponseDto} from './dto';
import {DocumentsPostRequestDtoFactory} from './dto';

@injectable()
class DocumentsRepository implements IDocumentsRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  find(query?: Partial<IDocumentsRepositoryQuery>): Promise<DocumentsModel | null> {
    throw new NotImplementedException();
  }

  async list(query?: Partial<IDocumentsRepositoryQuery>): Promise<DocumentsModel[]> {
    const {data} = await this._http.get<IDocumentsGetResponseDto[]>(`LabResult/getforpatient`);

    return data.map((i) => DocumentsModelFactory.fromGetResponseDto(i));
  }

  async getDetails(query?: Partial<IDocumentsRepositoryQuery>): Promise<DocumentDetailsModel> {
    if (!query?.documentId) {
      throw new Error('no document id');
    }

    const {data} = await this._http.get<IDocumentDetailsGetResponseDto>(
      `LabResult/getdocumentfiledata?labResultId=${query?.documentId}`,
    );

    return DocumentDetailsModelFactory.fromDetailsGetResponseDto(data);
  }

  async remove(domain: DocumentsModel): Promise<void> {
    if (domain.id) {
      await this._http.delete(`LabResult/${domain.id}`);
    } else {
      throw new Error(`can't delete document without it's id`);
    }
  }

  async save(domain: DocumentsModel): Promise<void> {
    const dto = DocumentsPostRequestDtoFactory.fromModel(domain);

    await this._http.post<IDocumentsGetResponseDto>('LabResult', dto);
  }
}

export {DocumentsRepository};
