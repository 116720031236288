import React, {forwardRef} from 'react';
import styles from './print-layout.module.scss';

interface PrintStyles {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  width?: number;
  height?: number;
  borderWidth?: number;
  padding?: number;
}

const PrintLayout = forwardRef<HTMLDivElement, {children?: React.ReactNode; styles?: PrintStyles}>(
  (props, ref) => (
      <div
        className={styles.print}
        ref={ref}
        style={{
          top: Number(props.styles?.top) | 0,
          right: Number(props.styles?.right) | 0,
          bottom: Number(props.styles?.bottom) | 0,
          left: Number(props.styles?.left) | 0,
          width: Number(props.styles?.width),
          height: Number(props.styles?.height),
          borderWidth: Number(props.styles?.borderWidth),
          padding: Number(props.styles?.padding),
        }}>
        {props.children}
      </div>
    ),
);

export {PrintLayout};
