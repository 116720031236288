import {AssessmentReportModel} from './assessment-report.model';
import type {IAssessmentReportGetResponseDto} from '../dto';
import {AssessmentReportScoreModelFactory} from './assessment-report-score.model.factory';
import {AssessmentReportOverallScoreModelFactory} from './assessment-report-overall-score.model.factory';

class AssessmentReportModelFactory {
  static fromGetResponseDtoRecord(dto: IAssessmentReportGetResponseDto): AssessmentReportModel {
    const overallScore = AssessmentReportOverallScoreModelFactory.fromGetResponseDto(dto.overallScore);
    const assessmentReportModel = new AssessmentReportModel(new Date(dto.date), overallScore);

    assessmentReportModel.symptomsScore = AssessmentReportScoreModelFactory.mergeWithGetResponseDto(
      assessmentReportModel.symptomsScore,
      dto.symptomsScore,
    );
    assessmentReportModel.independenceScore = AssessmentReportScoreModelFactory.mergeWithGetResponseDto(
      assessmentReportModel.independenceScore,
      dto.independenceScore,
    );
    assessmentReportModel.nutritionScore = AssessmentReportScoreModelFactory.mergeWithGetResponseDto(
      assessmentReportModel.nutritionScore,
      dto.nutritionScore,
    );
    assessmentReportModel.barriersScore = AssessmentReportScoreModelFactory.mergeWithGetResponseDto(
      assessmentReportModel.barriersScore,
      dto.barriersScore,
    );
    assessmentReportModel.emotionsScore = AssessmentReportScoreModelFactory.mergeWithGetResponseDto(
      assessmentReportModel.emotionsScore,
      dto.emotionsScore,
    );

    return assessmentReportModel;
  }
}

export {AssessmentReportModelFactory};
