import type {FC} from 'react';
import React, {useCallback, useEffect} from 'react';
import styles from './emotional-impact-scale-list.module.scss';
import type {IAssessmentsTableRow} from '../../components';
import {AssessmentsTable, Button, PageLoader, PageTitle} from '../../components';
import type {IEmotionalImpactScaleService} from '../service';
import {EMOTIONAL_IMPACT_SCALE_SERVICE} from '../service';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../helpers';

interface IServiceProps {
  emotionalImpactScaleService: IEmotionalImpactScaleService;
}

const EmotionalImpactScaleList: FC<IServiceProps> = ({emotionalImpactScaleService}) => {
  const assessmentTableHeader = ['Date', 'Name'];

  const assessmentTableRows: IAssessmentsTableRow[] = emotionalImpactScaleService.assessments.map((item) => {
    const date = item.lastUpdated ? format(item.lastUpdated, DEFAULT_FORMAT) : '';

    return {
      id: item.id,
      cells: [date, item.name],
    };
  });

  const onReassessmentClick = useCallback(() => {
    emotionalImpactScaleService.navigateToAssessment();
  }, [emotionalImpactScaleService]);

  const navigateCallback = useCallback(
    (id) => emotionalImpactScaleService.navigateToAssessment(id),
    [emotionalImpactScaleService],
  );

  useEffect(() => {
    emotionalImpactScaleService.loadAssessments();
  }, [emotionalImpactScaleService]);

  return (
    <>
      <div className={styles.headerContainer}>
        <PageTitle>Emotional Impact Scale</PageTitle>
        {!emotionalImpactScaleService.isReadonly && (
          <Button className={styles.button} onClick={onReassessmentClick} testid={'start-reassessment-button'}>
            {emotionalImpactScaleService.assessments.length ? 'Start the reassessment' : 'Start the assessment'}
          </Button>
        )}
      </div>
      {emotionalImpactScaleService.isLoading ? (
        <PageLoader />
      ) : (
        <AssessmentsTable
          header={assessmentTableHeader}
          rows={assessmentTableRows}
          onClickRow={navigateCallback}
          noResultsMessage={'Reminder to complete the Emotional Impact Scale'}
        />
      )}
    </>
  );
};

const EmotionalImpactScaleListObserver = observer(EmotionalImpactScaleList);

const EmotionalImpactScaleListInjected: FC = () => (
  <EmotionalImpactScaleListObserver emotionalImpactScaleService={useInjection(EMOTIONAL_IMPACT_SCALE_SERVICE)} />
);

export {EmotionalImpactScaleListInjected as EmotionalImpactScaleList};
