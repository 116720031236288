import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {Filter, FilterItem, NotificationList} from '../components';
import styles from './notifications.module.scss';
import {DownIcon, FlagGreen, FlagRed, FlagWhite, FlagYellow, UpIcon} from '../../assets/icons';
import type {INotificationService} from '../service';
import {NOTIFICATIONS_SERVICE} from '../service';
import {useInjection} from '../../ioc';
import {NotificationsFlags, NotificationsType} from '../model/enum';
import {useVisible} from '../../hooks';
import {PageTitle, Preloader} from '../../components';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';
import type {IAlertIndicatorService} from '../../alert-indicator';

interface IProps {
  notifications: INotificationService;
  account: IAccountService;
  alertIndicator: IAlertIndicatorService;
}

const filters = [
  {value: NotificationsFlags.HIGHEST_RISK, icon: <FlagRed />, legend: 'Time Sensitive'},
  {value: NotificationsFlags.MEDIUM_RISK, icon: <FlagYellow />, legend: 'Important'},
  {value: NotificationsFlags.LOW_RISK, icon: <FlagGreen />, legend: 'Informational'},
  {value: NotificationsFlags.NEUTRAL, icon: <FlagWhite />, legend: 'Health Maintenance'},
];

const NotificationScreen: FC<IProps> = ({notifications, account, alertIndicator}) => {
  const [readStatus, setReadStatus] = useVisible(false);
  const [visibleFilter, setVisibleFilter] = useVisible(false);
  const [paramsFilter, setParamsFilter] = useState<Array<number>>([]);
  const [expNotification, setExpNotification] = useState<undefined | number>();

  const expandNotificationHandler = (notificationId: number, isRead: boolean) => {
    setExpNotification(notificationId);
    !isRead && notifications.changeNotificationStatus(notificationId);
  };

  const readAllNotifications = (status: boolean) => {
    notifications
      .changeNotificationsStatus(
        notifications.notifications.map((notification) => notification.notificationId),
        status,
      )
      .then(() => {
        status && alertIndicator.load();
      });
  };

  const getNotifications = useCallback(
    (appendCollection?: boolean) => {
      if (account.id) {
        notifications.load(account.id, readStatus, paramsFilter, appendCollection);
      }
    },
    [account.id, notifications, readStatus, paramsFilter],
  );

  const onScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (e.currentTarget.offsetHeight + e.currentTarget.scrollTop === e.currentTarget.scrollHeight) {
        getNotifications(true);
      }
    },
    [getNotifications],
  );

  const onSetParamFilter = useCallback(
    (number) => {
      if (paramsFilter.includes(number)) {
        setParamsFilter(paramsFilter.filter((num) => num !== number));
      } else {
        setParamsFilter([...new Set([...paramsFilter, number])]);
      }
    },
    [paramsFilter],
  );

  const permissionFlags = useMemo(() => notifications.permissionFlags, [notifications.permissionFlags]);

  useEffect(() => {
    getNotifications();

    return () => {
      notifications.clear();
    };
  }, [getNotifications, notifications]);

  return (
    <>
      {notifications.isLoading && <Preloader />}
      <div className={styles.notificationPanel}>
        <PageTitle>Notifications</PageTitle>
        <div className={styles.notificationControl}>
          <button className={styles.notificationButton} onClick={setReadStatus}>
            View {readStatus ? NotificationsType.UNREAD : NotificationsType.READ}
          </button>
          <button className={styles.notificationButton} onClick={setVisibleFilter}>
            Filter {visibleFilter ? <UpIcon /> : <DownIcon />}
          </button>
        </div>
      </div>
      {visibleFilter && (
        <Filter>
          {filters.map((flag) => (
            <FilterItem
              value={flag.value}
              legend={flag.legend}
              onFilter={onSetParamFilter}
              available={permissionFlags}
              key={flag.value}
              paramsFilter={paramsFilter}>
              {flag.icon}
            </FilterItem>
          ))}
        </Filter>
      )}
      <div className={styles.scroll} onScroll={(e) => onScroll(e)}>
        <NotificationList
          readAllNotifications={readAllNotifications}
          readStatus={readStatus}
          expandedNotification={expNotification}
          expandNotification={expandNotificationHandler}
          notifications={notifications.notifications}
        />
      </div>
    </>
  );
};

const ObservableNotificationScreen = observer(NotificationScreen);
const NotificationScreenInjected: FC = () => (
  <ObservableNotificationScreen
    notifications={useInjection(NOTIFICATIONS_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
    alertIndicator={useInjection(ALERT_INDICATOR_SERVICE)}
  />
);

export {NotificationScreenInjected as NotificationScreen};
