import React, {createRef} from 'react';
import type {FileUploaderProps, FileUploaderState} from './file-uploader.types';
import {FileUploaderErrorType, FileUploaderType} from './file-uploader.types';
import classnames from 'classnames';
import {ConfirmDialog} from '../confirm-dialog-legacy';
import {Button, ButtonType} from '../button-legacy';
import {IconLegacy, IconTypeLegacy} from '../icon-legacy';
import {ErrorList} from '../error-list-legacy';
import {FileUploaderLegacyHelper} from './file-uploader.helper';
import './file-uploader.scss';
import ReactModal from 'react-modal';

class FileUploaderLegacy extends React.Component<FileUploaderProps, FileUploaderState> {
  state: FileUploaderState = {
    acceptableError: '',
    base64: '',
    isFileUploaded: false,
    sizeError: '',
    uploadedFile: this.props.uploadedFile,
    isPopupShown: false,
  };
  fileInputRef = createRef<HTMLInputElement>();

  componentWillReceiveProps(props: FileUploaderProps) {
    if (props.uploadedFile !== this.state.uploadedFile) {
      this.setUploadedFile(props.uploadedFile);
    }
  }

  componentDidUpdate() {
    if (this.state.acceptableError && this.props.onError) {
      this.props.onError(FileUploaderErrorType.WrongFileType, this.state.acceptableError);
      this.onRemove();
    }

    if (this.state.sizeError && this.props.onError) {
      this.props.onError(FileUploaderErrorType.WrongFileSize, this.state.sizeError);
      this.onRemove();
    }
  }

  dragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  dragEnter = (e: React.DragEvent) => {
    e.preventDefault();
  };

  dragLeave = (e: React.DragEvent) => {
    e.preventDefault();
  };

  fileDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.uploadFile(files);
  };

  filesSelected = () => {
    const refs = this.fileInputRef as any;
    const files = refs.current.files;
    this.uploadFile(files);
  };

  checkAcceptFile = (file: File) => {
    const acceptTypes = FileUploaderLegacyHelper.getAcceptTypes(this.props.accept),
      fileType = FileUploaderLegacyHelper.getFileType(file);

    return !acceptTypes.includes(`.${fileType}`);
  };

  uploadFile = (files: FileList) => {
    this.onRemove();

    const isAcceptError = this.checkAcceptFile(files[0]);
    if (isAcceptError) {
      return this.setAcceptableError(this.props.errorAcceptMessage);
    }

    if (!!this.props.maxSizeBytes && this.props.maxSizeBytes < files[0].size) {
      return this.setSizeError(this.props.errorMaxSizeMessage);
    }

    if (files.length) {
      this.setIsFileUploaded(true);
      FileUploaderLegacyHelper.toDataUrl(files[0]).then((result: string) => {
        this.setBase64(result);
        const preparedBase64 = FileUploaderLegacyHelper.getPreparedDataUrl(result);
        this.onRemove();

        return this.props.onUploadFile(files[0], preparedBase64);
      });
    }
  };

  onRemove = () => {
    this.setState({isPopupShown: false});
    this.setIsFileUploaded(false);
    this.setBase64('');
    this.setAcceptableError('');
    this.setSizeError('');
    this.props.onRemoveFile();
    this.setUploadedFile(undefined);
  };

  fileInputClicked = () => {
    const refs = this.fileInputRef as any;
    refs.current.click();
  };

  setAcceptableError = (error: string) => {
    this.setState({
      acceptableError: error,
      isPopupShown: true,
    });
  };

  setSizeError = (error: string) => {
    this.setState({
      sizeError: error,
      isPopupShown: true,
    });
  };

  setIsFileUploaded = (isUploaded: boolean) => {
    this.setState({
      isFileUploaded: isUploaded,
    });
  };

  setBase64 = (base64: string) => {
    this.setState({
      base64: base64,
    });
  };

  setUploadedFile = (val: undefined | string) => {
    this.setState({
      uploadedFile: val,
    });
  };

  render() {
    const type = this.props.type ?? FileUploaderType.Image;

    switch (type) {
      case FileUploaderType.Image:
        return this.renderImageFileUploader();
      case FileUploaderType.Button:
        return this.renderButtonFileUploader();
      default:
        return null;
    }
  }

  private renderImageFileUploader() {
    if (this.state.acceptableError) {
      return (
        <ReactModal isOpen={this.state.isPopupShown} className={'file-uploader__dialog'} ariaHideApp={false}>
          <ConfirmDialog
            title={this.state.acceptableError}
            text={''}
            dismissLabel={this.props.submitActionText}
            onConfirm={() => {}}
            onDismiss={this.onRemove}
          />
        </ReactModal>
      );
    }

    if (this.state.sizeError) {
      return (
        <ReactModal isOpen={this.state.isPopupShown} className={'file-uploader__dialog'} ariaHideApp={false}>
          <ConfirmDialog
            title={this.state.sizeError}
            text={''}
            dismissLabel={this.props.submitActionText}
            onConfirm={() => {}}
            onDismiss={this.onRemove}
          />
        </ReactModal>
      );
    }

    return (
      <div className={classnames('file-uploader', this.props.className)}>
        {this.props.label ? <label className={'file-uploader__label'}>{this.props.label}</label> : null}
        <div className="file-uploader__file-uploader">
          {!this.state.isFileUploaded && !this.state.uploadedFile && (
            <div
              className="file-uploader__drop-container"
              onDragOver={this.dragOver}
              onDragEnter={this.dragEnter}
              onDragLeave={this.dragLeave}
              onDrop={this.fileDrop}
              onClick={this.fileInputClicked}>
              <div className="file-uploader__drop-message">
                <div
                  className={classnames(
                    'file-uploader__cloud-download',
                    this.props.iconType === IconTypeLegacy.CloudDownloadBig
                      ? 'file-uploader__cloud-download--auto'
                      : null,
                  )}>
                  <IconLegacy type={this.props.iconType ?? IconTypeLegacy.CloudDownload} />
                </div>
                <span>{this.props.dragText}</span>
                {this.props.uploadText && <span className="file-uploader__upload-link">{this.props.uploadText}</span>}
              </div>
              <input
                aria-label='file uploader'
                ref={this.fileInputRef}
                className="file-uploader__file-input"
                onChange={this.filesSelected}
                accept={this.props.accept}
                type="file"
              />
            </div>
          )}
          {(this.state.isFileUploaded || !!this.state.uploadedFile) && (
            <div className="file-uploader__with-file">
              <div className="file-uploader__image-content">
                <img alt={''} src={this.state.base64 || this.state.uploadedFile} />
              </div>
              <div className="file-uploader__buttons-content">
                <div className="file-uploader__buttons-content-item" onClick={this.onRemove}>
                  {this.props.removeActionText}
                </div>
                <div className="file-uploader__buttons-content-item" onClick={this.fileInputClicked}>
                  <span>{this.props.uploadNewFileActionText}</span>
                  <input
                    aria-label='file uploader'
                    ref={this.fileInputRef}
                    className="file-uploader__file-input"
                    onChange={this.filesSelected}
                    accept={this.props.accept}
                    type="file"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  private renderButtonFileUploader() {
    const errors: Array<string> = [
      ...(this.state.acceptableError ? [this.state.acceptableError] : []),
      ...(this.state.sizeError ? [this.state.sizeError] : []),
    ];

    return (
      <div className={classnames('file-uploader', this.props.className, 'file-uploader--button')}>
        <input
          aria-label='file uploader'
          ref={this.fileInputRef}
          className="file-uploader__file-input"
          onChange={this.filesSelected}
          accept={this.props.accept}
          type="file"
        />
        {!this.props.onError && <ErrorList className="file-uploader__errors" errors={errors} />}
        {this.props.children ? (
          <div onClick={this.fileInputClicked}>{this.props.children}</div>
        ) : (
          <Button
            className="file-uploader__button"
            onClick={this.fileInputClicked}
            label={this.props.uploadText}
            type={this.props.buttonType ?? ButtonType.Small}
            icon={this.props.iconType}
          />
        )}
      </div>
    );
  }
}

export {FileUploaderLegacy};
