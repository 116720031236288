import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, CollapseDescription, PageLoader, PageTitle, SecondaryModal} from '../../components';
import styles from './emotional-impact-scale-screen.module.scss';
import {EmotionalImpactScaleForm} from '../form';
import {useFormConfirmationDialog, useQuery} from '../../hooks';
import type {IEmotionalImpactScaleFormDto, IEmotionalImpactScaleService} from '../service';
import {EMOTIONAL_IMPACT_SCALE_SERVICE} from '../service';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import {EmotionalImpactScaleSubHeader} from './components';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';

interface IServiceProps {
  emotionalImpactScaleService: IEmotionalImpactScaleService;
  account: IAccountService;
}

const EmotionalImpactScaleScreen: FC<IServiceProps> = ({emotionalImpactScaleService, account}) => {
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [visibleCancelModal, setVisibleCancelModal] = useState<boolean>(false);

  const {getConfirmation} = useFormConfirmationDialog();

  const {id: emotionalImpactScaleId} = useQuery<{id: number}>();

  const patientId = account.id;

  const onSubmit = useCallback(
    async (data: IEmotionalImpactScaleFormDto) => {
      setSubmitDisabled(true);
      const isConfirmed = await getConfirmation({});

      if (isConfirmed) {
        patientId && (await emotionalImpactScaleService.createAssessment(data, patientId));
      } else {
        setSubmitDisabled(false);
      }
    },
    [getConfirmation, patientId, emotionalImpactScaleService],
  );

  useEffect(() => {
    if (emotionalImpactScaleId) {
      emotionalImpactScaleService.loadAssessmentById(emotionalImpactScaleId);
    } else {
      emotionalImpactScaleService.loadAssessments();
    }
  }, [emotionalImpactScaleId, emotionalImpactScaleService]);

  const onCancel = useCallback(() => {
    setVisibleCancelModal(true);
  }, [setVisibleCancelModal]);

  const onBack = useCallback(() => {
    emotionalImpactScaleService.navigateToList();
  }, [emotionalImpactScaleService]);

  const onModalClose = useCallback(() => {
    setVisibleCancelModal(false);
  }, []);

  const readonly = useMemo(
    () => !!emotionalImpactScaleId || emotionalImpactScaleService.isReadonly,
    [emotionalImpactScaleId, emotionalImpactScaleService.isReadonly],
  );

  const selectedAssessment =
    emotionalImpactScaleService.selectedAssessment || emotionalImpactScaleService.lastFilledAssessment;

  return (
    <>
      <div className={styles.header}>
        <PageTitle>Emotional Impact Scale</PageTitle>
        {readonly && !emotionalImpactScaleService.isLoading && (
          <Button className={styles.button} onClick={onBack}>
            Back
          </Button>
        )}
      </div>
      {emotionalImpactScaleService.isLoading ? (
        <PageLoader />
      ) : (
        <>
          {readonly && <EmotionalImpactScaleSubHeader date={selectedAssessment?.lastUpdated} />}
          {!readonly && (
            <CollapseDescription>
              <p>
                We'd like to understand the emotional burden your condition has on you. Please indicate how often you
                have been bothered by the feelings or symptoms listed below during the past 7 days.
              </p>
            </CollapseDescription>
          )}
          <EmotionalImpactScaleForm
            selectedAssessmentItems={selectedAssessment?.items ?? null}
            listQuestions={emotionalImpactScaleService.listQuestions}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitDisabled={isSubmitDisabled}
            readonly={readonly}
          />
        </>
      )}
      {visibleCancelModal && (
        <SecondaryModal
          text={'All answers will be lost. Do you want to cancel the survey?'}
          onClose={onModalClose}
          onSubmit={onBack}
          centerText
        />
      )}
    </>
  );
};

const EmotionalImpactScaleScreenObserver = observer(EmotionalImpactScaleScreen);

const EmotionalImpactScaleScreenInjected: FC = () => (
  <EmotionalImpactScaleScreenObserver
    emotionalImpactScaleService={useInjection(EMOTIONAL_IMPACT_SCALE_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
  />
);

export {EmotionalImpactScaleScreenInjected as EmotionalImpactScaleScreen};
