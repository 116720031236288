class CompanyModel {
  constructor(
    public privacyPolicyLink = '',
    public termsAndConditionsLink = '',
    public nonDiscriminationsLink = '',
    public contactEmail = '',
    public contactPhone = '',
    public facebookLink = '',
    public twitterLink = '',
    public instagramLink = '',
    public linkedInLink = '',
    public tikTokLink = '',
    public youTubeLink = '',
    public helpLink = '',
    public copyRight = '',
    public informationalVersion = '',
    public disclaimer = '',
    public paymentManagementLink = '',
    public lmsLink = '',
    public calendarManagementLink = '',
    public glossaryOfMedicationMonitoringLink = '',
    public vaccinationReferenceGuideLink = '',
  ) {}
}

export {CompanyModel};
