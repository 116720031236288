import type {FC} from 'react';
import React, {useCallback, useEffect, useLayoutEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import styles from './encounters.module.scss';
import type {IEncounterService} from '../../../encounter';
import {ENCOUNTER_SERVICE} from '../../../encounter';
import {DEFAULT_FORMAT} from '../../../helpers';
import {useInjection} from '../../../ioc';
import {DatePicker, DatePickerInputType, Modal, PageLoader, PageTitle, useButtonDropdown} from '../../../components';
import {format} from 'date-fns';
import type {IOption} from '../../../types';
import {EncounterCreator, EncounterDetails, EncountersTableRow} from './components';
import type {EncounterModel} from '../../../encounter/models';
import type { IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';

interface IEncountersProps {
  encounters: IEncounterService;
  account: IAccountService;
}

const EncountersScreen: FC<IEncountersProps> = ({encounters, account}) => {
  const personDropdown = useButtonDropdown();
  const dateDropdown = useButtonDropdown();

  useEffect(() => {
    if (account.id) {
      encounters.init(account.team, account.id);
    }
  }, [encounters, account.id, account.team]);

  useEffect(() => {
    if (account.id) {
      encounters.load();
    }
  }, [account.id, encounters]);

  useLayoutEffect(() => {
    encounters.clearFilters();
  }, [encounters]);

  const now = useMemo(() => new Date(), []);

  const updatedLastFilter = useMemo(
    () =>
      encounters.encounterFilter.updatedLast ? format(encounters.encounterFilter.updatedLast, DEFAULT_FORMAT) : '',
    [encounters.encounterFilter.updatedLast],
  );

  const openEncounterDetails = useCallback(
    (item: EncounterModel) => {
      encounters.loadEncounterDetails(item.id);
    },
    [encounters],
  );

  const closeEncounterDetails = useCallback(() => {
    encounters.clearEncounterDetails();
  }, [encounters]);

  const encounterDetails = useMemo(
    () =>
      encounters.selectedEncounterDetails && !encounters.isLoadingEncounterDetails ? (
        <Modal onClose={closeEncounterDetails}>
          <EncounterDetails
            dateVisit={encounters.selectedEncounterDetails.dateVisit}
            nextRecommendedVisitInfo={encounters.selectedEncounterDetails.nextRecommendedVisitInfo}
            providerFullName={encounters.selectedEncounterDetails.providerFullName}
            providerTitle={encounters.selectedEncounterDetails.providerTitle}
            type={encounters.selectedEncounterDetails.type}
            visitDuration={encounters.selectedEncounterDetails.visitDuration}
            onClose={closeEncounterDetails}
            assessmentAndPlan={encounters.selectedEncounterDetails.assessmentAndPlan}
          />
        </Modal>
      ) : null,
    [encounters.isLoadingEncounterDetails, encounters.selectedEncounterDetails, closeEncounterDetails],
  );

  const items = useMemo(
    () =>
      encounters.encounters.map((encounter) => (
        <EncountersTableRow
          key={encounter.id}
          isCreatedByPatient={encounter.isCreatedByPatient}
          date={encounter.date}
          type={encounter.type}
          createdByFullName={encounter.createdByFullName}
          method={encounter.method}
          onEncounterDetailsView={() => openEncounterDetails(encounter)}
          isPossibleViewSummary={encounter.isPossibleViewSummary}
        />
      )),
    [encounters.encounters, openEncounterDetails],
  );

  const selectPerson = useCallback(
    (item: IOption) => {
      encounters.filterEncounters({authorId: item.value});
      personDropdown.close();
    },
    [encounters, personDropdown],
  );

  const encounterCreators = useMemo(
    () =>
      encounters.encounterAuthorOptions.map((item, i) => (
        <EncounterCreator key={i} creator={item} onClick={selectPerson} />
      )),
    [encounters.encounterAuthorOptions, selectPerson],
  );

  const clearPersonFilter = useCallback(() => {
    encounters.filterEncounters({authorId: null});
  }, [encounters]);

  const selectDate = useCallback(
    (date: Date) => {
      encounters.filterEncounters({updatedLast: date});
      dateDropdown.close();
    },
    [dateDropdown, encounters],
  );

  const resetDate = useCallback(() => {
    encounters.filterEncounters({updatedLast: null});
  }, [encounters]);

  return (
    <>
      <div className={styles.encounterHeader}>
        <div className={styles.topContent}>
          <PageTitle>Encounters</PageTitle>
        </div>
      </div>
      {encounters.isLoading ? (
        <PageLoader />
      ) : (
        <>
          <div className={styles.filter}>
            <personDropdown.ButtonDropdown
              label="Created by"
              value={encounters.encounterFilter.authorName ?? undefined}
              onReset={clearPersonFilter}
              shadow>
              {encounterCreators}
            </personDropdown.ButtonDropdown>
            <dateDropdown.ButtonDropdown label="Date" onReset={resetDate} value={updatedLastFilter}>
              <DatePicker
                name={'date'}
                value={encounters.encounterFilter.updatedLast}
                onChange={selectDate}
                inputType={DatePickerInputType.LabResults}
                max={now}
                inline
              />
            </dateDropdown.ButtonDropdown>
          </div>

          {items.length ? (
            <div className={styles.grid}>
              <div className={styles.gridHeader}>
                <div>DATE</div>
                <div>ENCOUNTER TYPE</div>
                <div>CREATED BY</div>
                <div>METHOD</div>
                <div>SUMMARY</div>
              </div>
              <div>{items}</div>
            </div>
          ) : (
            <div className={styles.empty}>
              {encounters.areFiltered()
                ? 'No encounters match the criteria. Please modify or clear your filters and try again'
                : 'You have no Encounters yet'}
            </div>
          )}
          {encounters.selectedEncounterDetails && encounterDetails}
        </>
      )}
    </>
  );
};

const EncountersScreenObserver = observer(EncountersScreen);

const EncountersScreenInjected = () => (
  <EncountersScreenObserver encounters={useInjection(ENCOUNTER_SERVICE)} account={useInjection(ACCOUNT_SERVICE)} />
);

export {EncountersScreenInjected as EncountersScreen};
