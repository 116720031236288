import {memo} from 'react';
import {DateWrapper} from '../../../../components';
import styles from './chronic-condition-checklist-sub-header.module.scss';

interface IChronicConditionChecklistSubHeaderProps {
  date?: Date | null;
}

const ChronicConditionChecklistSubHeader = memo<IChronicConditionChecklistSubHeaderProps>(({date}) => {
  const formattedLastUpdatedDate = date ? <DateWrapper date={date} /> : null;

  return formattedLastUpdatedDate ? (
    <div className={styles.subHeader}>Last updated · {formattedLastUpdatedDate}</div>
  ) : null;
});

export {ChronicConditionChecklistSubHeader};
export type {IChronicConditionChecklistSubHeaderProps};
