import type {IPopUpMetaData} from '../../components/pop-up-modal/interface/pop-up-modal.interface';

export interface IVinePath {
  path: string;
  segments: ISegment[];
  totalLength: number;
  startingPositionX: number;
  reversedPath?: boolean; // Used for growing wine which starts from end to start
}

interface ISegment {
  length: number;
  subSegments: IBranch[][]; // Array of subSegments and inside multiple branches
}

export interface IBranch {
  branchPath: string;
  leafType: EEventType;
  coordinates?: IItemCoordinates;
  leafPath?: string;
  priority?: 'branch' | 'leaf';
  eventTriggers: IEvent[]; // Array of event
}

export interface IItemCoordinates {
  x: number;
  y: number;
  angle?: number;
  title?: string;
  numericalIndicator?: number;
}

export interface ICourseEvent {
  behaviorTypeId: number;
  behaviorTypeName: string;
  completionDate?: Date;
  skills?: ISkillEvent[];
}

export interface ISkillEvent {
  skillTypeId: number;
  skillTypeName: string;
  completionDate: Date;
  lessons?: ILessons[];
}

interface ILessons {
  courseId: string;
  courseName: string;
  completionDate?: Date;
}

export interface IEvent {
  name: string;
  completionDate?: Date;
  vineIndex?: number;
  type?: EEventType;
}

export interface ISegmentAndSubsegmentIndexes {
  segmentIndex: number;
  subSegmentIndex: number;
  hasFinished: boolean;
}

export enum EEventType {
  GreenLeaf = 'greenLeaf',
  Flower = 'flower',
  Leaf = 'leaf',
  EndOfCategory = 'endOfCategory',
  EndOfVine = 'endOfVine',
  WeeklyWellness = 'weeklyWellness',
  SelfEfficacy = 'selfEfficacy',
}

export interface IEventMatrix {
  startDate?: Date;
  categories: IEvent[];
  flowers: IEvent[][][]; // array of segments (month) which have array of subSegments (part of month)
  leafs: IEvent[][][]; // array of segments (month) which have array of subSegments (part of month)
}

export enum ETrellisPopUpType {
  LoggedFiveInTwoWeeks = 1,
  LoggedInFourWeeks = 2,
  LoggedAfterPause = 3,
  CommonLessonCompleted = 4,
  JumpStartLessonCompleted = 5,
  BootcampLessonCompleted = 6,
  WWFourConsecutiveCompletions = 7,
  WWOverallImprovement = 8,
  WWHappinessScoreImproved = 9,
  WWHappinessLadderImproved = 10,
  SkillStreak = 11,
  CourseStreak = 12,
  CategoryStreak = 13,
}

export interface ITrellisPopUpInformation {
  type: ETrellisPopUpType;
  rewardText: string;
  streaksCount: number;
  meta: IPopUpMetaData;
}

export class TrellisModel {
  constructor(public matrix?: IEventMatrix, public vinePaths: IVinePath[] = []) {}
}
