import type {IMedicationsReasonsDiscontinueGetResponseDto} from '../repository';
import {MedicationsReasonsDiscontinueModel} from './medications-reasons-discontinue.model';

class MedicationsReasonsDiscontinueModelFactory {
  static fromGetResponseDto(dto: IMedicationsReasonsDiscontinueGetResponseDto): MedicationsReasonsDiscontinueModel {
    return new MedicationsReasonsDiscontinueModel(dto.order, dto.value, dto.description, dto.isObsolete);
  }
}

export {MedicationsReasonsDiscontinueModelFactory};
