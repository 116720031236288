import type {FC} from 'react';
import React, {useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {REQUIRED_FIELD_ERROR} from '../../../helpers';
import {
  Error,
  RangeBar,
  RangeBarLabelsType,
  RangeBarStylingType,
  SingleDropdown,
  SurveyRadioButtonGroup,
  SurveyYesNoComponent,
  TextArea,
} from '../../../components';
import {SurveyDynamicSection} from '../dynamic-section';
import {SurveyNumericElement} from '../numeric-element';
import {SurveyReadonlyElement} from '../readonly-element';
import {SurveyWrapper} from '../wrapper';
import {RadioLadder} from '../radio-ladder';
import {SurveySection} from '../section';
import {IsRelatedQuestionAllowsRenderHelper} from './helpers';
import {SurveyStaticElement} from '../static-element';
import classNames from 'classnames';
import type {ISurveyElementResponse, ISurveySpecElement} from '../../index';
import {calculateFormula} from '../../helpers';
import {SurveySpecElementType} from '../../model';
import styles from './survey-form-element.module.scss';
import {RadioButtonsImage} from '../radio-button-image';

interface ISurveyFormElementProps {
  element: ISurveySpecElement;
  title?: string | undefined;
  readonly: boolean;
  elementValues: Array<ISurveyElementResponse>;
  isOnBoarding?: boolean;
  hasNoWrapper?: boolean;
  isEverCompleted?: boolean;
  flattenedElements: Array<ISurveySpecElement>;
  isChildren?: boolean;
}

const Wrapper: FC<{
  error?: string;
  elementId: number;
  title?: string | JSX.Element[];
  hasNoWrapper?: boolean;
  isConditionElement?: boolean;
  visibleIf?: boolean;
}> = ({error, elementId, hasNoWrapper, children, title, isConditionElement, visibleIf}) => (
  <div className={classNames({[styles.wrapper]: !isConditionElement && !hasNoWrapper})}>
    {!!hasNoWrapper ? (
      <>
        {!isConditionElement && <div className={styles.wrapperTitle}>{title}</div>}
        {children}
      </>
    ) : (
      <SurveyWrapper key={elementId} title={title || ''} errorMessage={error} isConditionElement={isConditionElement}>
        {children}
      </SurveyWrapper>
    )}
  </div>
);

const getPossibleValue = (elementValues: Array<ISurveyElementResponse>, name: string) =>
  elementValues.find((elementValue) => elementValue.name === name)?.value;

const SurveyFormElement: FC<ISurveyFormElementProps> = (props) => {
  const {
    control,
    formState: {errors},
    watch,
  } = useFormContext();
  const currentFormValues = watch();

  const possibleValueElement = getPossibleValue(props.elementValues, props.element.name);

  const isElementToBeRendered = useMemo(
    () =>
      (!!possibleValueElement || // if field has submitted value
        !props.element.metadata?.formType || // if field is meant to be displayed only after first submit
        !props.readonly) && // if course-form in active state
      (!!props.element.visibleIf
        ? Boolean(calculateFormula(props.element.visibleIf, currentFormValues, props.flattenedElements)) ||
          !!possibleValueElement
        : true), // if conditionally rendered field
    [
      currentFormValues,
      possibleValueElement,
      props.element.metadata?.formType,
      props.element.visibleIf,
      props.flattenedElements,
      props.readonly,
    ],
  );

  const title = props.isChildren
    ? possibleValueElement || !props.readonly
      ? props.element.text
      : ''
    : props.element.text || props.title;

  if (props.isOnBoarding && !!props.element.metadata?.formType) {
    return <></>;
  } else {
    switch (props.element.elementType) {
      case SurveySpecElementType.Hidden:
        return (
          <Controller
            render={({name, value, onChange}) => (
              <input
                type="hidden"
                name={name}
                value={value}
                onChange={onChange}
                data-testid={`gwb-hidden-${props.element.name}`}
              />
            )}
            name={props.element.name}
            control={control}
          />
        );
      case SurveySpecElementType.Toggle:
        const dynamicName = `${props.element.name.replace('_question', '')}_date`;

        const readonlyToggle = (value: string) =>
          props.elementValues.length ? (
            props.elementValues.find((elementValue) => elementValue.name === dynamicName)?.value ? (
              <></>
            ) : !!props.element.visibleIf ? (
              <Wrapper elementId={props.element.elementId} isConditionElement title={title} hasNoWrapper>
                <SurveyReadonlyElement
                  elementValues={props.elementValues}
                  name={props.element.name}
                  type={props.element.elementType}
                  isConditionElement
                />
              </Wrapper>
            ) : (
              <SurveyReadonlyElement
                elementValues={props.elementValues}
                name={props.element.name}
                type={props.element.elementType}
              />
            )
          ) : (
            <SurveyReadonlyElement
              elementValues={[
                {
                  elementId: props.element.elementId,
                  name: props.element.name,
                  value,
                },
              ]}
              name={props.element.name}
              type={props.element.elementType}
            />
          );

        const toggle = (
          <Controller
            name={props.element.name}
            control={control}
            rules={{
              required: props.element.isRequired ? REQUIRED_FIELD_ERROR : undefined,
            }}
            render={({value, onChange, name}) =>
              !props.readonly ? (
                <>
                  <SurveyYesNoComponent
                    name={name}
                    value={value || props.element.metadata?.defaultValue}
                    onChange={onChange}
                    isError={!!errors[props.element.name]?.message}
                    ariaLabelTitle={props.element.text || props.title}
                    ariaLabelError={!!errors[props.element.name]?.message ? errors[props.element.name].message : ""}
                  />
                  {!!errors[props.element.name]?.message && (
                    <Error errorMessage={errors[props.element.name].message} name={props.element.name} />
                  )}
                </>
              ) : (
                readonlyToggle(value)
              )
            }
          />
        );

        return isElementToBeRendered ? (
          <Wrapper
            title={title}
            elementId={props.element.elementId}
            hasNoWrapper={props.hasNoWrapper && !props.element.visibleIf}
            isConditionElement={props.isChildren && !!props.element.visibleIf}>
            {toggle}
          </Wrapper>
        ) : (
          <></>
        );
      case SurveySpecElementType.Numeric:
        const maxValue = props.element.metadata?.maxValue ? Number(props.element.metadata?.maxValue) : null;
        const minValue = props.element.metadata?.minValue ? Number(props.element.metadata?.minValue) : null;

        const validateValue = (value: number) => {
          if (!!maxValue && !!minValue) {
            return (
              (minValue <= value && maxValue >= value) ||
              `Only digits allowed, min value is ${minValue}, max value is ${maxValue}.`
            );
          }

          return true;
        };

        return isElementToBeRendered ? (
          <Wrapper
            title={title}
            elementId={props.element.elementId}
            hasNoWrapper={props.hasNoWrapper && !props.element.visibleIf}
            isConditionElement={props.isChildren && !!props.element.visibleIf}>
            {props.readonly ? (
              <SurveyReadonlyElement
                elementValues={props.elementValues}
                name={props.element.name}
                type={props.element.elementType}
              />
            ) : (
              <Controller
                control={control}
                name={props.element.name}
                rules={{
                  required: props.element.isRequired ? REQUIRED_FIELD_ERROR : undefined,
                  validate: validateValue,
                }}
                defaultValue={''}
                render={(propsElement) => (
                  <div className={styles.numeric}>
                    <SurveyNumericElement
                      {...propsElement}
                      isInvalid={!!errors[props.element.name]?.message}
                      minValue={minValue}
                      maxValue={maxValue}
                      ariaLabelTitle={props.element.text || props.title}
                      ariaLabelError={!!errors[props.element.name]?.message ? errors[props.element.name].message : ""}
                    />
                    {!!errors[props.element.name]?.message && (
                      <Error errorMessage={errors[props.element.name].message} name={props.element.name} />
                    )}
                  </div>
                )}
              />
            )}
          </Wrapper>
        ) : (
          <></>
        );
      case SurveySpecElementType.DynamicSection:
        const isRelatedQuestionAllowsRender = IsRelatedQuestionAllowsRenderHelper(
          props.element.name,
          currentFormValues,
        );

        return !props.isOnBoarding ? (
          !props.readonly ? (
            isRelatedQuestionAllowsRender ? (
              <SurveyDynamicSection element={props.element} elementValues={props.elementValues} control={control} />
            ) : (
              <></>
            )
          ) : !!props.element?.subElements ? (
            <SurveyReadonlyElement
              elementValues={props.elementValues}
              name={props.element?.subElements[0].name}
              type={props.element.elementType}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        );
      case SurveySpecElementType.Section:
        return isElementToBeRendered ? (
          <SurveySection
            element={props.element}
            elementValues={props.elementValues}
            title={props.title}
            readonly={props.readonly}
            isOnBoarding={props.isOnBoarding}
            isEverCompleted={props.isEverCompleted}
            flattenedElements={props.flattenedElements}
          />
        ) : (
          <></>
        );
      case SurveySpecElementType.Text:
        return isElementToBeRendered ? (
          <Wrapper
            title={title}
            elementId={props.element.elementId}
            hasNoWrapper={props.hasNoWrapper && !props.element.visibleIf}
            isConditionElement={props.isChildren && !!props.element.visibleIf}>
            {props.readonly ? (
              <SurveyReadonlyElement
                elementValues={props.elementValues}
                name={props.element?.name}
                type={props.element.elementType}
              />
            ) : (
              <Controller
                name={props.element.name}
                control={control}
                rules={{
                  required: props.element.isRequired ? REQUIRED_FIELD_ERROR : undefined,
                }}
                defaultValue={''}
                render={({value, onChange}) => (
                  <div className={styles.notesContent}>
                    <TextArea
                      name={props.element.name}
                      classNameInput={styles.textArea}
                      value={value}
                      onChange={onChange}
                      isValid={!errors[props.element.name]}
                      ariaLabelTitle={props.element.text || props.title}
                      ariaLabelError={!!errors[props.element.name]?.message ? errors[props.element.name].message : ""}
                    />
                    {!!errors[props.element.name]?.message && (
                      <div className={styles.errorText}>
                        <Error errorMessage={errors[props.element.name].message} name={props.element.name} />
                      </div>
                    )}
                  </div>
                )}
              />
            )}
          </Wrapper>
        ) : (
          <></>
        );
      case SurveySpecElementType.Dropdown:
        const dropdownOptions = props.element.options?.map((option) => ({
          value: option.value,
          displayName: option.value,
        }));

        const possibleValueDropdown = getPossibleValue(props.elementValues, props.element.name);

        const isDisable =
          (!!possibleValueDropdown || !props.element.metadata?.formType || !props.readonly) &&
          !!props.element.disabledIf
            ? Boolean(
                calculateFormula(props.element.disabledIf, currentFormValues, props.flattenedElements, 'return true'),
              )
            : !!possibleValueDropdown;

        return (
          <Wrapper
            title={title}
            elementId={props.element.elementId}
            hasNoWrapper={props.hasNoWrapper || !props.element.visibleIf}
            isConditionElement={props.isChildren && !!props.element.visibleIf}>
            {props.readonly ? (
              <SurveyReadonlyElement
                elementValues={props.elementValues}
                name={props.element?.name}
                type={props.element.elementType}
              />
            ) : (
              <Controller
                name={props.element.name}
                control={control}
                rules={{
                  required: !isDisable && props.element.isRequired ? REQUIRED_FIELD_ERROR : undefined,
                }}
                defaultValue={''}
                render={({value, onChange}) => (
                  <>
                    <SingleDropdown
                      name={props.element.name}
                      value={value}
                      onChange={onChange}
                      options={dropdownOptions}
                      isValid={!errors[props.element.name]}
                      disabled={isDisable}
                      className={styles.dropDown}
                      ariaLabelTitle={props.element.text || props.title}
                      ariaLabelError={!!errors[props.element.name]?.message ? errors[props.element.name].message : ""}
                    />
                    {!!errors[props.element.name]?.message && (
                      <div className={styles.errorText}>
                        <Error errorMessage={errors[props.element.name].message} name={props.element.name} />
                      </div>
                    )}
                  </>
                )}
              />
            )}
          </Wrapper>
        );
      case SurveySpecElementType.Select:
        const options = props.element.options?.map((option) => ({
          id: option.elementOptionId,
          text: option.value,
        }));

        return (
          <Wrapper title={title} hasNoWrapper={props.hasNoWrapper} elementId={props.element.elementId}>
            {props.readonly ? (
              <SurveyReadonlyElement
                elementValues={props.elementValues}
                name={props.element?.name}
                type={props.element.elementType}
              />
            ) : (
              !!options && (
                <Controller
                  name={props.element.name}
                  control={control}
                  rules={{
                    required: props.element.isRequired ? REQUIRED_FIELD_ERROR : undefined,
                  }}
                  render={({value, onChange}) => (
                    <div>
                      <SurveyRadioButtonGroup
                        name={props.element.name}
                        options={options}
                        value={value}
                        onChange={onChange}
                        isError={errors[props.element.name]?.message}
                        ariaLabelTitle={props.element.text || props.title}
                        ariaLabelError={!!errors[props.element.name]?.message ? errors[props.element.name].message : ""}
                      />
                      {!!errors[props.element.name]?.message && (
                        <Error errorMessage={errors[props.element.name].message} name={props.element.name} />
                      )}
                    </div>
                  )}
                />
              )
            )}
          </Wrapper>
        );
      case SurveySpecElementType.Range:
        const min = Number(props.element.options[0].value);
        const max = Number(props.element.options[props.element.options.length - 1].value);

        const rangeBarMin = min > max ? max : min;
        const rangeBarMax = min < max ? max : min;

        const defaultValue = min > max ? max : min;

        const rangeBarValues = props.element.options.map((option) => Number(option.value));

        const rangeBarLabels = props.element.options.filter((i) => i.metaData).map((option) => String(option.metaData));

        const rangeBarStep = Math.abs(
          Number(rangeBarValues[Math.round(rangeBarValues.length / 2)]) -
            Number(rangeBarValues[Math.floor(rangeBarValues.length / 2)]),
        );

        const displayingType = rangeBarLabels?.length ? RangeBarLabelsType.Strings : RangeBarLabelsType.Numbers;

        const stylingType =
          rangeBarLabels?.length && rangeBarLabels.length !== rangeBarValues.length
            ? RangeBarStylingType.Slicing
            : RangeBarStylingType.Line;

        const value = props.elementValues.find((elementValue) => elementValue.name === props.element.name)?.value;

        const readonlyValue =
          props.readonly && rangeBarLabels?.length && rangeBarLabels.length === rangeBarValues.length
            ? rangeBarValues.map((item, index) => (item === Number(value) ? rangeBarLabels[index] : null))
            : value;

        return isElementToBeRendered ? (
          <Wrapper
            title={title}
            elementId={props.element.elementId}
            hasNoWrapper={props.hasNoWrapper && !props.element.visibleIf}
            isConditionElement={props.isChildren && !!props.element.visibleIf}>
            {props.readonly ? (
              <div className={styles.readonlyRange}>{readonlyValue}</div>
            ) : (
              !!props.element && (
                <Controller
                  name={props.element.name}
                  control={control}
                  rules={{
                    required: props.element.isRequired ? REQUIRED_FIELD_ERROR : undefined,
                  }}
                  defaultValue={defaultValue}
                  render={({value, onChange, name}) => (
                    <RangeBar
                      name={name}
                      min={rangeBarMin}
                      max={rangeBarMax}
                      value={value}
                      values={rangeBarValues}
                      labels={rangeBarLabels}
                      step={rangeBarStep}
                      onChange={onChange}
                      displayingType={displayingType}
                      stylingType={stylingType}
                      ariaLabelTitle={props.element.text || props.title}
                      ariaLabelError={!!errors[props.element.name]?.message ? errors[props.element.name].message : ""}
                    />
                  )}
                />
              )
            )}
          </Wrapper>
        ) : (
          <></>
        );
      case SurveySpecElementType.RadioButtonImage:
        return (
          <Wrapper title={title} hasNoWrapper={props.hasNoWrapper} elementId={props.element.elementId}>
            <RadioButtonsImage
              element={props.element}
              control={control}
              readonly={props.readonly}
              errors={errors}
              elementValues={props.elementValues}
            />
          </Wrapper>
        );
      case SurveySpecElementType.LadderSection:
        const ladderOptions = props.element.options?.map((option) => ({
          id: option.elementOptionId,
          text: option.value,
        }));

        const ladderSectionTitle = props.element.text?.split('\\n').map((item, index) => <p key={index}>{item}</p>);

        return (
          <Wrapper
            title={ladderSectionTitle || title}
            hasNoWrapper={props.hasNoWrapper}
            elementId={props.element.elementId}>
            <RadioLadder
              element={props.element}
              control={control}
              readonly={props.readonly}
              radioOptions={ladderOptions}
              errors={errors}
              elementValues={props.elementValues}
              ariaLabelTitle={props.element.text || props.title}
              ariaLabelError={!!errors[props.element.name]?.message ? errors[props.element.name].message : ""}
            />
          </Wrapper>
        );
      case SurveySpecElementType.StaticSection: {
        return (
          <SurveyStaticElement
            element={props.element}
            control={control}
            elementValues={props.elementValues}
            readonly={props.readonly}
            isOnBoarding={props.isOnBoarding}
            currentFormValues={currentFormValues}
            flattenedElements={props.flattenedElements}
          />
        );
      }
      default:
        return <></>;
    }
  }
};

export {SurveyFormElement, getPossibleValue};
