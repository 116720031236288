import type {FC} from 'react';
import React, {useMemo} from 'react';
import {parseFormulaToShowQuestion} from '../../../helpers';
import {Controller, useFormContext} from 'react-hook-form';
import {AssessmentQuestionType} from '../../../assessment';
import type {SelfEfficacyAssessmentQuestionsModel} from '../../model';
import {AssessmentItemWrapper, AssessmentRangeBar} from '../../../components';

interface ISelfEfficacyAssessmentQuestionsProps {
  listQuestions: SelfEfficacyAssessmentQuestionsModel[];
  readonly: boolean;
}

const SelfEfficacyAssessmentQuestions: FC<ISelfEfficacyAssessmentQuestionsProps> = ({listQuestions, readonly}) => {
  const {control, watch} = useFormContext();

  const formValues = watch();

  const isShowQuestion = useMemo(
    () => (question: SelfEfficacyAssessmentQuestionsModel) =>
      question?.visibleIf ? parseFormulaToShowQuestion(question.visibleIf, formValues) : true,
    [formValues],
  );

  const questions = useMemo(
    () =>
      listQuestions.map((question) => {
        switch (question.type) {
          case AssessmentQuestionType.Range:
            return (
              isShowQuestion(question) && (
                <AssessmentItemWrapper title={question.displayName} key={question.name}>
                  <Controller
                    control={control}
                    name={question.name}
                    render={({name, onChange, value}) => (
                      <AssessmentRangeBar
                        question={question}
                        name={name}
                        value={value}
                        onChange={onChange}
                        readonly={readonly}
                        ariaLabelTitle={question.displayName}
                        ariaLabelError=''
                      />
                    )}
                  />
                </AssessmentItemWrapper>
              )
            );
          default:
            return null;
        }
      }),
    [control, isShowQuestion, listQuestions, readonly],
  );

  return <>{questions}</>;
};

export {SelfEfficacyAssessmentQuestions};
