import styles from './lab-results-crp-result-element.module.scss';
import type {FC} from 'react';
import React from 'react';
import type {Control} from 'react-hook-form/dist/types/form';
import type {FieldErrors} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {Error, InputNumber, SingleDropdown} from '../../../../components';
import {expressionOptions, unitTypesOptions} from '../../../options';
import {CrpExpression, UnitsTypesEnum} from '../../../enums';
import type {LabResultsFormDto} from '../../form-dto';
import {optionsFromMapHelper, REQUIRED_FIELD_ERROR} from '../../../../helpers';
import {InputNumberTypes} from '../../../../components/input-number/input-number.component';

const crpOptions = optionsFromMapHelper(unitTypesOptions).filter(
  (option) =>
    option.value === UnitsTypesEnum.Blank ||
    option.value === UnitsTypesEnum.Other ||
    option.value === UnitsTypesEnum.MgPerLiter ||
    option.value === UnitsTypesEnum.MgPerDeciLiter ||
    option.value === UnitsTypesEnum.McgPerLiter,
);

const MAX_VALUE = 99999.9;

const LabResultsCrpResultElement: FC<{
  control: Control;
  errors: FieldErrors<LabResultsFormDto>;
}> = (props) => (
  <>
    <div className={styles.root}>
      <Controller
        name={'expression'}
        control={props.control}
        rules={{
          required: REQUIRED_FIELD_ERROR,
          validate: (value) => value !== CrpExpression.Blank || REQUIRED_FIELD_ERROR,
        }}
        render={({value, onChange, name}) => {
          const options = optionsFromMapHelper(expressionOptions);
          const currentValue = options.find((option) => option.value === value);

          return (
            <SingleDropdown
              className={styles.expressionDropdown}
              name={name}
              ariaLabelTitle="Result expression"
              ariaLabelError={props.errors['expression']?.message}
              onChange={(option) => onChange(option.value)}
              value={currentValue}
              options={options}
              showInputValueAlways
              isError={!!props.errors['expression']?.message}
            />
          );
        }}
      />
      <Controller
        name={'value'}
        control={props.control}
        rules={{
          required: REQUIRED_FIELD_ERROR,
          validate: (value) => (value !== null && value !== undefined) || REQUIRED_FIELD_ERROR,
        }}
        render={({value, onChange, name}) => (
          <InputNumber
            className={styles.numeric}
            name={name}
            ariaLabelTitle="Result value"
            ariaLabelError={props.errors['value']?.message}
            onChange={onChange}
            value={value}
            isValid={!props.errors['value']?.message}
            numberInputType={InputNumberTypes.Decimal}
            maxValue={MAX_VALUE}
          />
        )}
      />
      <Controller
        name={'unitType'}
        control={props.control}
        rules={{
          required: REQUIRED_FIELD_ERROR,
          validate: (value) => value !== UnitsTypesEnum.Blank || REQUIRED_FIELD_ERROR,
        }}
        render={({value, onChange, name}) => {
          const currentValue = crpOptions.find((option) => option.value === value);

          return (
            <SingleDropdown
              className={styles.unitsDropdown}
              name={name}
              ariaLabelTitle="Result unit type"
              ariaLabelError={props.errors['unitType']?.message}
              onChange={(option) => onChange(option.value)}
              value={currentValue}
              options={crpOptions}
              showInputValueAlways
              isError={!!props.errors['unitType']?.message}
            />
          );
        }}
      />
    </div>
    {(!!props.errors['value']?.message && <Error errorMessage={props.errors['value'].message} />) ||
      (!!props.errors['expression']?.message && <Error errorMessage={props.errors['expression'].message} />) ||
      (!!props.errors['unitType']?.message && <Error errorMessage={props.errors['unitType'].message} />)}
  </>
);

export {LabResultsCrpResultElement};
