import type {IRepository} from '../../types';
import type {EncounterDetailsModel, EncounterModel} from '../models';

enum EncounterRepositoryQuerySort {
  DESC,
  ASC,
}

type EncounterRepositoryQuery = {
  sort: EncounterRepositoryQuerySort;
  orderBy: string;
  patientId: number;
  authorId: number;
  updatedLast: Date;
  offset: number;
  limit: number;
};

interface IEncounterRepository extends IRepository<EncounterModel, EncounterRepositoryQuery> {
  findDetails(query?: Partial<{encounterId: number}>): Promise<EncounterDetailsModel>;
}

export {EncounterRepositoryQuerySort};
export type {EncounterRepositoryQuery, IEncounterRepository};
