import {routes} from '../../routing';

enum NavigationIds {
  DailySymptomTracker = 1,
  WeeklyWellnessReport = 2,
  Assessments = 3,
  StrengthAssessment = 4,
  EmotionalImpactScale = 5,
  SelfEfficacyAssessment = 6,
  Resilience5Assessment = 7,
  DiseaseSeverityScreener = 8,
  ChronicConditionChecklist = 21,
  TrellusTracker = 9,
  LabResults = 10,
  Medications = 11,
  AddWeight = 12,
  VaccinationRecord = 13,
  Tasks = 14,
  MyCurriculum = 15,
  Notifications = 16,
  Messages = 17,
  MyCareTools = 18,
  Encounters = 19,
  Reports = 20,
  MyTrellusConnectCard = 22,
  GlossaryOfMedicationMonitoring = 26,
  VaccinationReferenceGuide = 27,
  SymptomSeverityScale = 28,
  MyGoalJournal = 29,
}

type NavigationRoutes = Exclude<
  NavigationIds,
  | NavigationIds.MyCareTools
  | NavigationIds.TrellusTracker
  | NavigationIds.Assessments
  | NavigationIds.MyCurriculum
  | NavigationIds.MyGoalJournal
  | NavigationIds.MyTrellusConnectCard
  | NavigationIds.GlossaryOfMedicationMonitoring
  | NavigationIds.VaccinationReferenceGuide
>;

const navigationRoutes: Record<NavigationRoutes, Array<string>> = {
  [NavigationIds.DailySymptomTracker]: [routes.dailySymptomTracker],
  [NavigationIds.WeeklyWellnessReport]: [routes.weeklyWellnessReport],
  [NavigationIds.StrengthAssessment]: [routes.strengthsAssessment, routes.strengthsAssessmentsList],
  [NavigationIds.EmotionalImpactScale]: [routes.emotionalImpactScale, routes.emotionalImpactScale],
  [NavigationIds.SelfEfficacyAssessment]: [routes.selfEfficacy, routes.selfEfficacyList],
  [NavigationIds.Resilience5Assessment]: [routes.resilienceAssessment],
  [NavigationIds.DiseaseSeverityScreener]: [routes.diseaseSeverityScreener, routes.diseaseSeverityScreenerList],
  [NavigationIds.ChronicConditionChecklist]: [routes.chronicConditionChecklist, routes.chronicConditionChecklists],
  [NavigationIds.LabResults]: [routes.labResultsTracking],
  [NavigationIds.Medications]: [routes.medications],
  [NavigationIds.AddWeight]: [routes.addWeight],
  [NavigationIds.VaccinationRecord]: [routes.vaccination],
  [NavigationIds.Tasks]: [routes.tasks],
  [NavigationIds.Notifications]: [routes.notifications],
  [NavigationIds.Messages]: [routes.messages],
  [NavigationIds.Encounters]: [routes.encounters],
  [NavigationIds.Reports]: [routes.reports],
  [NavigationIds.SymptomSeverityScale]: [routes.symptomSeverityScale, routes.symptomSeverityScaleList],
};

const getRouteByLocation = (location: string): number | undefined => {
  let routeId;
  const keys: Array<NavigationRoutes> = Object.keys(navigationRoutes).map(Number);

  keys.forEach((key) => {
    if (navigationRoutes[key].find((route) => location.includes(route))) {
      routeId = key;
    }
  });

  return routeId;
};

export {NavigationIds, getRouteByLocation};
