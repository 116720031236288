import type {FC} from 'react';
import React, {useMemo} from 'react';
import {RangeBar, RangeBarLabelsType, RangeBarStylingType} from '../index';
import type {SelfEfficacyAssessmentQuestionsModel} from '../../self-efficacy-assessment/model';

interface IAssessmentsRangeBarProps {
  question: SelfEfficacyAssessmentQuestionsModel;
  name: string;
  value: number;
  readonly?: boolean;
  onChange: (value: string) => void;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const AssessmentRangeBar: FC<IAssessmentsRangeBarProps> = ({question, name, value, readonly, onChange, ariaLabelTitle, ariaLabelError}) => {
  const min = Number(question.options[0].value);
  const max = Number(question.options[question.options.length - 1].value);

  const rangeBarMin = min > max ? max : min;
  const rangeBarMax = min < max ? max : min;

  const rangeBarLabels = question.options.filter((i) => i.displayName).map((option) => option.displayName);

  const rangeBarValues = question.options.map((option) => Number(option.value));

  const rangeBarStep = Math.abs(
    Number(rangeBarValues[Math.round(rangeBarValues.length / 2)]) -
      Number(rangeBarValues[Math.floor(rangeBarValues.length / 2)]),
  );

  const readonlyValue = useMemo(() => {
    const countStringValues = question.options.filter((i) => i.displayName).length;

    const isShowDigitalValue = countStringValues !== question.options.length;

    if (!isShowDigitalValue) {
      return value && question.options.find((i) => i.value === String(value))?.displayName;
    }

    return value;
  }, [question.options, value]);

  return (
    <>
      {!readonly ? (
        <RangeBar
          name={name}
          value={value}
          values={rangeBarValues}
          step={rangeBarStep}
          onChange={(value) => onChange(value.toString())}
          min={rangeBarMin}
          max={rangeBarMax}
          stylingType={RangeBarStylingType.Slicing}
          displayingType={RangeBarLabelsType.Strings}
          labels={rangeBarLabels}
          ariaLabelTitle={ariaLabelTitle}
          ariaLabelError={ariaLabelError}
        />
      ) : (
        readonlyValue
      )}
    </>
  );
};

export {AssessmentRangeBar};
