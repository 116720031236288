import type {FC} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import {Button, CollapseDescription, PageLoader, PageTitle, SecondaryModal} from '../../components';
import type {IDiseaseSeverityScreenerFormDto} from '../form';
import {DiseaseSeverityScreenerForm} from '../form';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import type {IDiseaseSeverityScreenerService} from '../service';
import {DISEASE_SEVERITY_SCREENER_SERVICE} from '../service';
import {useQuery} from '../../hooks';
import {DiseaseSeverityScreenerSubHeader} from './components';
import styles from './disease-severity-screener-screen.module.scss';
import {SpecialtyType} from '../../enums';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';

interface IServiceProps {
  diseaseSeverityScreenerService: IDiseaseSeverityScreenerService;
  account: IAccountService;
}

const PAGE_TITLE_DEFAULT = 'Disease Severity Screener';
const PAGE_TITLE_IBS = 'Symptom Severity Scale';

const DiseaseSeverityScreenerScreen: FC<IServiceProps> = ({diseaseSeverityScreenerService, account}) => {
  const [visibleCancelModal, setVisibleCancelModal] = useState(false);

  const {id: assessmentId} = useQuery<{id: number}>();

  const isIbs = account.info?.specialty?.name === SpecialtyType.IBS;
  const pageTitle = isIbs ? PAGE_TITLE_IBS : PAGE_TITLE_DEFAULT;

  useEffect(() => {
    if (assessmentId) {
      diseaseSeverityScreenerService.loadAssessmentById(assessmentId);
    } else {
      diseaseSeverityScreenerService.loadAssessments();
    }

    return () => diseaseSeverityScreenerService.clearAssessment();
  }, [assessmentId, diseaseSeverityScreenerService]);

  const selectedAssessment =
    diseaseSeverityScreenerService.selectedAssessment || diseaseSeverityScreenerService.lastFilledAssessment;

  const readonly = !!assessmentId || diseaseSeverityScreenerService.isReadonly;

  const onSurveyCancel = useCallback(() => {
    setVisibleCancelModal(true);
  }, [setVisibleCancelModal]);

  const onBack = useCallback(
    () => diseaseSeverityScreenerService.navigateToList(isIbs),
    [diseaseSeverityScreenerService, isIbs],
  );

  const onSubmit = useCallback(
    (data: IDiseaseSeverityScreenerFormDto) => {
      diseaseSeverityScreenerService.createAssessment(data, isIbs);
    },
    [diseaseSeverityScreenerService, isIbs],
  );

  return (
    <>
      <div className={styles.header}>
        <PageTitle>{pageTitle}</PageTitle>
        {readonly && !diseaseSeverityScreenerService.isLoading && (
          <Button className={styles.button} onClick={onBack}>
            Back
          </Button>
        )}
      </div>
      {diseaseSeverityScreenerService.isLoading ? (
        <PageLoader />
      ) : (
        <>
          <DiseaseSeverityScreenerSubHeader date={selectedAssessment?.lastUpdated} />
          {!readonly && (
            <CollapseDescription>
              <p>
                Your disease severity means that there are certain characteristics of your personal disease that are
                associated with the need for advanced therapies or higher risk of needing surgery. It can change over
                time, and you will be able to update it twice a year if necessary.
              </p>
              <p> Please select your diagnosis and then fill in the remaining information</p>
            </CollapseDescription>
          )}
          <DiseaseSeverityScreenerForm
            selectedAssessmentItems={selectedAssessment?.items ?? null}
            listQuestions={diseaseSeverityScreenerService.listQuestions}
            readonly={readonly}
            onSubmit={onSubmit}
            onCancel={onSurveyCancel}
          />
        </>
      )}
      {visibleCancelModal && (
        <SecondaryModal
          text={'All answers will be lost. Do you want to cancel the assessment?'}
          onClose={() => setVisibleCancelModal(false)}
          onSubmit={onBack}
        />
      )}
    </>
  );
};

const DiseaseSeverityScreenerScreenObserver = observer(DiseaseSeverityScreenerScreen);

const DiseaseSeverityScreenerScreenInjected: FC = () => (
  <DiseaseSeverityScreenerScreenObserver
    diseaseSeverityScreenerService={useInjection(DISEASE_SEVERITY_SCREENER_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
  />
);

export {DiseaseSeverityScreenerScreenInjected as DiseaseSeverityScreenerScreen};
