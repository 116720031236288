import {ContainerModule} from 'inversify';
import type {IHttpService} from './http.service.interface';
import {HTTP_SERVICE} from './http.service.provider';
import {HttpService} from './http.service';
import type {IHttpConfigService} from './config';
import {HttpConfigService, HTTP_CONFIG_SERVICE} from './config';

const HttpModule = new ContainerModule((bind) => {
  bind<IHttpConfigService>(HTTP_CONFIG_SERVICE).to(HttpConfigService).inSingletonScope();
  bind<IHttpService>(HTTP_SERVICE).to(HttpService).inSingletonScope();
});

export {HttpModule};
