import type {ITrellisPopUpInformation} from '../../../trellis-visualization/model';
import {ETrellisPopUpType} from '../../../trellis-visualization/model';
import type {ISocketData} from '../service';

export const isLoginWebsocket = (type: string, data: ITrellisPopUpInformation[]) =>
  type === 'TrellisStreakCompleted' &&
  data.some(
    ({type}) =>
      type === ETrellisPopUpType.LoggedAfterPause ||
      type === ETrellisPopUpType.LoggedInFourWeeks ||
      type === ETrellisPopUpType.LoggedFiveInTwoWeeks,
  );

export const storeUnreadMessages = (data: ISocketData) => {
  // Store the message in session storage
  const existingMessages = JSON.parse(sessionStorage.getItem('unhandledWsMessages') || '[]');
  existingMessages.push(data);

  if (existingMessages.length) {
    sessionStorage.setItem('unhandledWsMessages', JSON.stringify(existingMessages));
  }
};

export const processUnhandledAchievementMessages = (): ITrellisPopUpInformation[] => {
  // Retrieve unhandled messages from session storage
  const unhandledWsMessages: ISocketData[] = JSON.parse(sessionStorage.getItem('unhandledWsMessages') || '[]');

  // Filter messages with type "TrellisStreakCompleted" and flatten the data arrays
  const achievementMessages: ITrellisPopUpInformation[] = unhandledWsMessages
    .filter(({type}) => type === 'TrellisStreakCompleted')
    .flatMap((socketData) => socketData.data as ITrellisPopUpInformation[]); // Use flatMap to flatten the nested arrays

  // Get the rest of the messages
  const otherMessages = unhandledWsMessages.filter(({type}) => type !== 'TrellisStreakCompleted');

  // Store the rest of the messages back into session storage
  if (otherMessages.length) {
    sessionStorage.setItem('unhandledWsMessages', JSON.stringify(otherMessages));
  } else {
    sessionStorage.removeItem('unhandledWsMessages');
  }

  // Return only the "TrellisStreakCompleted" messages
  return achievementMessages || [];
};
