import {inject, injectable} from 'inversify';
import type {IMedicationsService} from './medications.service.interface';
import type {MedicationsCourseModel, MedicationsReasonsDiscontinueModel} from '../model';
import {MedicationsCourseModelFactory, MedicationsModel} from '../model';
import {makeAutoObservable} from 'mobx';
import type {IMedicationsCourseDiscontinueFormDto, IMedicationsCourseFormDto} from '../components';
import type {INotifierService} from '../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../notifier';
import type {
  IMedicationsCourseDiscontinueGetResponseDto,
  IMedicationsCourseGetResponseDto,
  IMedicationsRepository,
} from '../repository';
import {MEDICATIONS_REPOSITORY} from '../repository';
import type {MedicationFormDto} from '../dto';
import type {IWssService, SocketData} from '../../utils/wss';
import {WSS_SERVICE} from '../../utils/wss';
import {MedicationCourseFormFactory} from '../dto/medications-course-form.dto.factory';
import {MedicationCourseForm} from '../dto/medications-course-form.dto';

@injectable()
class MedicationsService implements IMedicationsService {
  private _courseDiscontinuedNextPageNumber: number | null = 1;
  private _courseDiscontinuedPageSize = 10;
  private _selectedCourseId: number | null = null;

  constructor(
    @inject(MEDICATIONS_REPOSITORY) private readonly _repo: IMedicationsRepository,
    @inject(NOTIFIER_SERVICE) private readonly _notifier: INotifierService,
    @inject(WSS_SERVICE) private readonly _wss: IWssService,
  ) {
    makeAutoObservable(this);
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  get selectedCourse(): MedicationsCourseModel | null {
    return this._selectedCourseId ? this._courseArr.find(({id}) => id === this._selectedCourseId) ?? null : null;
  }

  get selectedCourseDiscontinue(): MedicationsCourseModel | null {
    const course = this.selectedCourse;

    return course?.discontinue ? course : null;
  }

  private _courseArr: Array<MedicationsCourseModel> = [];

  get courseArr(): ReadonlyArray<MedicationsCourseModel> {
    return this._courseArr.filter(({discontinue}) => !discontinue);
  }

  get courseDiscontinuedArr(): ReadonlyArray<MedicationsCourseModel> {
    return this._courseArr.filter(({discontinue}) => !!discontinue);
  }

  private _medications: Array<MedicationsModel> = [];

  get medications(): ReadonlyArray<MedicationsModel> {
    return this._medications;
  }

  private _reasonsDiscontinue: Array<MedicationsReasonsDiscontinueModel> = [];

  get reasonsDiscontinue(): ReadonlyArray<MedicationsReasonsDiscontinueModel> {
    return this._reasonsDiscontinue;
  }

  private static isMedicationsCourseSocketData(data: SocketData): data is IMedicationsCourseGetResponseDto {
    return (data as IMedicationsCourseGetResponseDto)?.medicationCourseId !== undefined;
  }

  private static isMedicationsCourseDiscontinuedSocketData(
    data: SocketData,
  ): data is IMedicationsCourseDiscontinueGetResponseDto {
    return (data as IMedicationsCourseDiscontinueGetResponseDto)?.medicationCourseId !== undefined;
  }

  selectCourse(courseId: number) {
    this._selectedCourseId = courseId;
  }

  clearCourseSelection() {
    this._selectedCourseId = null;
  }

  async load(rewrite?: boolean): Promise<void> {
    if (!this._isLoading && (this._courseDiscontinuedNextPageNumber || rewrite)) {
      this._isLoading = true;

      await this.loadReasonsDiscontinue();

      const courses = await this._repo.list().catch(console.error);

      if (courses) {
        if (rewrite) {
          this._courseArr = courses;
          this._courseDiscontinuedNextPageNumber = 1;
        } else {
          this._courseArr = [...this.courseDiscontinuedArr, ...courses];
        }
      }

      if (this._courseDiscontinuedNextPageNumber !== null) {
        const coursesDiscontinued = await this._repo
          .list({
            discontinued: true,
            offset: this._courseDiscontinuedNextPageNumber,
            limit: this._courseDiscontinuedPageSize,
          })
          .catch(console.error);

        if (coursesDiscontinued) {
          this._courseArr = [...this._courseArr, ...coursesDiscontinued];

          if (coursesDiscontinued.length < this._courseDiscontinuedPageSize) {
            this._courseDiscontinuedNextPageNumber = null;
          } else {
            this._courseDiscontinuedNextPageNumber++;
          }
        }
      }

      if (!this._medications.length) {
        const data = await this._repo.listMedications().catch(console.error);

        if (data) {
          this._medications = data;
        }
      }

      this._isLoading = false;
    }
  }

  async courseCreate(formDto: IMedicationsCourseFormDto, patientId: number, id?: number): Promise<void> {
    const model = MedicationsCourseModelFactory.fromFormDto(formDto, patientId, this._medications, id);

    await this._repo
      .save(model)
      .then(async () => {
        this._notifier.notify({
          text: !!id ? 'Medication successfully edited' : 'New medication successfully added',
          title: 'Success',
          type: NotifyMessageTypeEnum.Success,
        });

        await this.load(!!id);

        this.clearCourseSelection();
      })
      .catch(console.error);
  }

  async createMedication(dto: MedicationFormDto): Promise<string> {
    const medication = new MedicationsModel(-1, dto.name);

    const newMedicationId = await this._repo.saveMedication(medication).catch(console.error);

    let newMedicationValue: string = '';

    await this._repo
      .listMedications()
      .then((data) => {
        this._medications = data;
        newMedicationValue = data.filter((item) => item.id === Number(newMedicationId))[0].safeId;
      })
      .catch(console.error);

    return newMedicationValue;
  }

  async courseDiscontinue(formDto: IMedicationsCourseDiscontinueFormDto, patientId: number): Promise<void> {
    if (this._selectedCourseId) {
      const model = MedicationsCourseModelFactory.fromDiscontinueFormDto(formDto, this._selectedCourseId);

      await this._repo
        .save(model)
        .then(async () => {
          this._notifier.notify({
            text: 'Medication discontinued',
            title: 'Success',
            type: NotifyMessageTypeEnum.Success,
          });

          await this.load(true);

          this.clearCourseSelection();
        })
        .catch(console.error);
    }
  }

  async loadReasonsDiscontinue(): Promise<void> {
    await this._repo
      .getReasonsDiscontinue()
      .then((data) => (this._reasonsDiscontinue = data))
      .catch(console.error);
  }

  getFormDtoById(dto?: MedicationsCourseModel): MedicationCourseForm {
    return !!dto?.id ? MedicationCourseFormFactory.fromGetResponseDto(dto) : new MedicationCourseForm();
  }

  connectWss(): void {
    this._wss.subscribe('MedicationCourseAdded', (response) => {
      if (
        MedicationsService.isMedicationsCourseSocketData(response) &&
        !this._courseArr.some((medicationCourse) => medicationCourse.id === response.medicationCourseId)
      ) {
        if (response.isDiscontinued) {
          this._courseArr.push(MedicationsCourseModelFactory.fromDiscontinueResponseDto(response));
        } else {
          this._courseArr.push(MedicationsCourseModelFactory.fromGetResponseDto(response));
        }
      }
    });

    this._wss.subscribe('MedicationCourseDiscontinued', (response) => {
      if (MedicationsService.isMedicationsCourseDiscontinuedSocketData(response)) {
        const course = this._courseArr.find((i) => i.id === response.medicationCourseId);

        if (course) {
          const courseIndex = this._courseArr.indexOf(course);

          if (courseIndex !== -1) {
            this._courseArr = [
              ...this._courseArr.slice(0, courseIndex),
              MedicationsCourseModelFactory.fromDiscontinueResponseDto(response),
              ...this._courseArr.slice(courseIndex + 1),
            ];
          }
        }
      }
    });
  }
}

export {MedicationsService};
