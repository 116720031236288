class VaccinationFormDto {
  constructor(
    public q_vaccination_record_varicella_as_child: string = '',
    public q_vaccination_record_varicella_immunity: string = '',
    public q_vaccination_record_flu: string = '',
    public q_vaccination_record_flu_date: string = '',
    public q_vaccination_record_menacwy: string = '',
    public q_vaccination_record_menb: string = '',
    public q_vaccination_record_mmr: string = '',
    public q_vaccination_record_hepatitis_a: string = '',
    public q_vaccination_record_hepatitis_b: string = '',
    public q_vaccination_record_hepatitis_b_immunity: string = '',
    public q_vaccination_record_herpes: string = '',
    public q_vaccination_record_herpes_date: string = '',
    public q_vaccination_record_herpes_second_date: string = '',
    public q_vaccination_record_hpv: string = '',
    public q_vaccination_record_hpv_date: string = '',
    public q_vaccination_record_ppsv23: string = '',
    public q_vaccination_record_pcv13: string = '',
    public q_vaccination_record_covid19: string = '',
    public covid: Array<{q_vaccination_record_covid19_vaccine: string; q_vaccination_record_covid19_date: string}> = [],
    public q_vaccination_record_rsv: string = '',
  ) {}
}

export {VaccinationFormDto};
