import type {IResilienceAssessmentQuestionsGetResponseDto} from '../repository';
import {ResilienceAssessmentQuestionModel} from './resilience-assessment-question.model';

class ResilienceAssessmentQuestionModelFactory {
  static fromGetDto(dto: IResilienceAssessmentQuestionsGetResponseDto): ResilienceAssessmentQuestionModel {
    return new ResilienceAssessmentQuestionModel(
      dto.name,
      dto.displayName,
      dto.displayOrder,
      dto.isRequired,
      dto.defaultValue,
      dto.type,
      dto.options,
      dto.minValue,
      dto.maxValue,
    );
  }
}

export {ResilienceAssessmentQuestionModelFactory};
