import {inject, injectable} from 'inversify';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import type {IAlertIndicatorService} from '../../alert-indicator';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';
import {makeAutoObservable} from 'mobx';
import type {ILifeQualityRepository} from '../repository';
import {LIFE_QUALITY_REPOSITORY} from '../repository';
import {routes} from '../../routing';
import type {LifeQualityQuestionModel} from '../model';
import {LifeQualityModelFactory} from '../model';
import type {ILifeQualityFormDto} from '../form';
import type {ILifeQualityService} from './life-quality.service.interface';
import {PredefinedTasks} from '../../enums';
import type {ITaskService} from '../../task/service';
import {TASK_SERVICE} from '../../task/service';

@injectable()
class LifeQualityService implements ILifeQualityService {
  private _hasLifeQualityTaskActive: boolean = false;

  constructor(
    @inject(LIFE_QUALITY_REPOSITORY) private readonly _repo: ILifeQualityRepository,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(ALERT_INDICATOR_SERVICE) private readonly _alertIndicator: IAlertIndicatorService,
    @inject(TASK_SERVICE) private readonly _task: ITaskService,
  ) {
    makeAutoObservable(this);
  }

  private _isLoading: boolean = true;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _listQuestions: LifeQualityQuestionModel[] = [];

  get listQuestions(): LifeQualityQuestionModel[] {
    return this._listQuestions;
  }

  async checkLifeQualityTaskActive(): Promise<void> {
    if (!this._task.activeTasks.length) {
      await this._task.load();
    }

    const hasLifeQualityTaskActive = this._task.activeTasks.some(
      (task) => !task.complete && task.patientDailyTaskId === PredefinedTasks.LifeQuality,
    );

    if (hasLifeQualityTaskActive) {
      this._hasLifeQualityTaskActive = true;
    } else {
      this.navigateToBack();
    }
  }

  async loadQuestions(): Promise<void> {
    await this.checkLifeQualityTaskActive();

    if (this._hasLifeQualityTaskActive && !this._listQuestions.length) {
      this._listQuestions = await this._repo.listQuestions();
    }

    this._isLoading = false;
  }

  async createAssessment(formDto: ILifeQualityFormDto): Promise<void> {
    const model = LifeQualityModelFactory.fromFormDto(formDto);

    await this._repo
      .save(model)
      .then(() => {
        this._alertIndicator.load();

        this.navigateToBack();
      })
      .catch(console.error);
  }

  navigateToBack(): void {
    this._routing.push(`${routes.home}${routes.myDashboard}`);
  }
}

export {LifeQualityService};
