import {ContainerModule} from 'inversify';
import type {ITaskRepository} from '../repositoty';
import {TASK_REPOSITORY, TaskRepository} from '../repositoty';
import {GoalRepository} from '../repositoty/goal/goal.repository';
import type {IGoalRepository} from '../repositoty/goal/goal.repository.interface';
import {GOAL_REPOSITORY} from '../repositoty/goal/goal.repository.provider';
import type {ITaskService} from '../service';
import {TASK_SERVICE, TaskService} from '../service';
import {GoalService} from '../service/goal/goal.service';
import type {IGoalService} from '../service/goal/goal.service.interface';
import {GOAL_SERVICE} from '../service/goal/goal.service.provider';

const TaskModule = new ContainerModule((bind) => {
  bind<ITaskService>(TASK_SERVICE).to(TaskService).inSingletonScope();
  bind<IGoalService>(GOAL_SERVICE).to(GoalService).inSingletonScope();

  bind<ITaskRepository>(TASK_REPOSITORY).to(TaskRepository).inSingletonScope();
  bind<IGoalRepository>(GOAL_REPOSITORY).to(GoalRepository).inSingletonScope();
});

export {TaskModule};
