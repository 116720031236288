import type {FC} from 'react';
import React from 'react';
import {AllowBaseModal} from './allow-base';
import type {MyExternalProvider} from '../../../../../../../account';

interface IOwnProps {
  onClose(): void;
  onSubmit(model: MyExternalProvider): void;
  provider: MyExternalProvider;
}

export const UpdateExternalProviderModal: FC<IOwnProps> = ({provider, onSubmit, ...props}) => (
  <AllowBaseModal {...props} onSubmit={() => onSubmit(provider)}>
    <p>
      Are you sure you want to {provider.isSharingInfo ? 'allow' : 'forbid'} <b>{provider.providerName}</b> to see my
      data?
    </p>
  </AllowBaseModal>
);
