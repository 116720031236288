import type {
  IStrengthsAssessmentQuery,
  IStrengthsAssessmentRepository,
} from './strengths-assessment.repository.interface';
import type {StrengthsAssessmentModel} from '../model';
import {StrengthsAssessmentModelFactory} from '../model';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {inject, injectable} from 'inversify';
import type {StrengthsAssessmentCreateRequestDto, StrengthsAssessmentGetResponseDto} from '../dto';

@injectable()
class StrengthsAssessmentRepository implements IStrengthsAssessmentRepository {
  constructor(@inject(HTTP_SERVICE) private readonly http: IHttpService) {}

  async find(query?: Partial<IStrengthsAssessmentQuery>): Promise<StrengthsAssessmentModel | null> {
    let model: StrengthsAssessmentModel | null = null;

    if (query?.id) {
      const response = await this.http.get<StrengthsAssessmentGetResponseDto>(`/StrengthsAssessment/${query?.id}`);

      model = StrengthsAssessmentModelFactory.fromGetResponseDto(response.data);
    }

    return model;
  }

  async list(): Promise<StrengthsAssessmentModel[]> {
    const response = await this.http.get<StrengthsAssessmentGetResponseDto[]>('/StrengthsAssessment');

    return response.data?.map((i) => StrengthsAssessmentModelFactory.fromGetResponseDto(i)) ?? [];
  }

  async save(
    dtoOrModel: StrengthsAssessmentModel | StrengthsAssessmentCreateRequestDto,
  ): Promise<StrengthsAssessmentModel> {
    const response = await this.http.post<StrengthsAssessmentGetResponseDto>('/StrengthsAssessment', dtoOrModel);

    return StrengthsAssessmentModelFactory.fromGetResponseDto(response.data);
  }
}

export {StrengthsAssessmentRepository};
