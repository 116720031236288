import type {AxisDataType, ExtendedAxisDataType} from '../health-monitor-line-chart.component';
import type {IOption} from '../../../../types';
import type {IHealthMonitorPoint} from '../../../model';
import type {
  HealthMonitorFatigueLabels,
  HealthMonitorMyLifeIsGoingWellLabels,
  HealthMonitorPhysicalHealthLabels,
} from '../enums';

type ConfigType = Required<AxisDataType> & {labels?: Array<IOption<number>>};

const isExtendedAxisDataType = <T extends {}>(
  axis: AxisDataType | ExtendedAxisDataType<T>,
): axis is ExtendedAxisDataType<T> => (axis as ExtendedAxisDataType<T>).labels !== undefined;

const getAxisesConfig = (
  points: Array<IHealthMonitorPoint>,
  chartData:
    | AxisDataType
    | ExtendedAxisDataType<
        HealthMonitorMyLifeIsGoingWellLabels | HealthMonitorFatigueLabels | HealthMonitorPhysicalHealthLabels
      >,
): ConfigType => {
  const resultConfig: ConfigType = {
    labels: isExtendedAxisDataType(chartData) ? chartData.labels : undefined,
    min: 0,
    max: 0,
    range: chartData.range ?? 0,
    tick: chartData.tick,
  };

  const values = [...points.map((point) => point.y)];
  const min = Math.min(...values);
  const max = Math.max(...values);

  // get max depending on points data
  if (max !== undefined && chartData.max !== undefined) {
    resultConfig.max = max > chartData.max ? max : chartData.max;
  } else {
    const maxes = [max, chartData.max];
    resultConfig.max = Math.ceil(maxes.every((m) => m === undefined) ? 0 : maxes.find((m) => m !== undefined) ?? 0);
  }

  // get min depending on points data
  if (min !== undefined && chartData.min !== undefined) {
    resultConfig.min = min < chartData.min ? min : chartData.min;
  } else {
    const mins = [min, chartData.min];
    resultConfig.min = Math.floor(mins.every((m) => m === undefined) ? 0 : mins.find((m) => m !== undefined) ?? 0);
  }

  const newRange = (resultConfig.max - resultConfig.min) / chartData.tick;

  // set new range if max points value greater than default max value
  if (newRange > chartData.range && Number.isFinite(chartData.range)) {
    resultConfig.range = newRange;
    resultConfig.max = newRange * chartData.tick + resultConfig.min;
  } else if (resultConfig.tick > (resultConfig.max - resultConfig.min) / resultConfig.range) {
    resultConfig.max = resultConfig.min + resultConfig.tick;
  }

  return resultConfig;
};

export {getAxisesConfig};
