import type {FC, MouseEventHandler} from 'react';
import styles from './visible.module.scss';
import classNames from 'classnames';

interface IVisibleProps {
  on?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  dataTestId?: string;
}

const Visible: FC<IVisibleProps> = ({dataTestId, on, className, onClick, children}) => (
  <div data-testid={dataTestId} className={classNames(className, {[styles.hidden]: !on})} onClick={onClick}>
    {children}
  </div>
);

export {Visible};
export type {IVisibleProps};
