import {BlueHydrangea, PinkHydrangea, YellowHydrangea} from '../../assets/goals';
import type {ILevelBody} from '../interfaces/goal.interface';
import {ELevelGoal} from '../interfaces/goal.interface';

export const levelMapping: Record<ELevelGoal, ILevelBody> = {
  [ELevelGoal.UNKNOWN]: {
    minScore: 0,
    maxScore: 0,
    level: 0,
  },
  [ELevelGoal.EASY]: {
    minScore: 22,
    maxScore: 25,
    level: 1,
    description: 'EASY goal',
    icon: BlueHydrangea,
  },
  [ELevelGoal.MEDIUM]: {
    minScore: 16,
    maxScore: 21,
    level: 2,
    description: 'MEDIUM goal',
    icon: YellowHydrangea,
  },
  [ELevelGoal.HARD]: {
    minScore: 10,
    maxScore: 15,
    level: 3,
    description: 'HARD goal',
    icon: PinkHydrangea,
  },
};
