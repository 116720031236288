import type {IBodyMassIndexRepository} from './body-mass-index.repository.interface';
import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {BodyMassIndexModel} from '../model';
import {BodyMassIndexModelFactory} from '../model';
import type {BodyMassIndexGetResponseDto, BodyMassIndexSaveRequestDto} from './dto';

@injectable()
class BodyMassIndexRepository implements IBodyMassIndexRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  find(): Promise<void> {
    throw new NotImplementedException();
  }

  async list(): Promise<BodyMassIndexModel[]> {
    const response = await this._http.get<BodyMassIndexGetResponseDto[]>('PatientMetrics/getweight');

    return response.data?.map((i) => BodyMassIndexModelFactory.fromGetResponseDto(i)) ?? [];
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async save(dto: BodyMassIndexSaveRequestDto): Promise<BodyMassIndexModel> {
    const {data} = await this._http.post<BodyMassIndexModel>('PatientMetrics/addweight', dto);

    return data;
  }
}

export {BodyMassIndexRepository};
