import type {FC} from 'react';
import styles from './footer.module.scss';
import classNames from 'classnames';

interface IFooterProps {
  disclaimer: string;
  copyright: string;
  className?: string;
}

const links = [
  {title: 'Terms of Use', href: 'https://trellushealth.com/terms-of-use'},
  {title: 'Privacy Policy', href: 'https://trellushealth.com/privacy-policy'},
];

const Footer: FC<IFooterProps> = ({disclaimer, copyright, className}) => (
  <footer className={classNames(className, styles.root)}>
    <span className={styles.text}>{copyright}</span>
    <div className={styles.links}>
      {links.map(({title, href}) => (
        <a className={styles.text} href={href} target="_blank" rel="noreferrer noopener">
          {title}
        </a>
      ))}
    </div>
    <section className={styles.disclaimer}>{disclaimer}</section>
  </footer>
);

export {Footer};
