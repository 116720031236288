import type {VaccinationQuestion} from './vaccination.model';
import {VaccinationModel} from './vaccination.model';
import type {IVaccinationGetResponseDto} from '../repository/dto';
import type {VaccinationFormDto} from '../form';
import type {VaccinationSocketDto} from '../socket-dto';

class VaccinationModelFactory {
  static fromGetResponseDto(dto: IVaccinationGetResponseDto): VaccinationModel {
    return new VaccinationModel(
      dto.id,
      dto.patientId,
      !!dto.lastModifiedDate ? new Date(dto.lastModifiedDate) : null,
      dto.items,
    );
  }

  static fromFormDto(dto: VaccinationFormDto, patientId: number, recordId?: number): VaccinationModel {
    const flattenedResponses: Array<VaccinationQuestion> = Object.keys(dto).reduce((acc, question) => {
      if (question.indexOf('date') === -1 && question !== 'covid') {
        return [
          ...acc,
          {
            question,
            // @ts-ignore
            value: dto[question] ? String(`${question}_${dto[question]}`) : '',
            dynamicSectionIndex: null,
          } as VaccinationQuestion,
        ];
      } else if (question === 'covid') {
        const dynamicAnswers: VaccinationQuestion[] = dto[question]
          .map((r, i) => [
            {
              question: 'q_vaccination_record_covid19_vaccine',
              dynamicSectionIndex: i,
              value: r['q_vaccination_record_covid19_vaccine']
                ? String(`q_vaccination_record_covid19_vaccine_${r['q_vaccination_record_covid19_vaccine']}`)
                : '',
            },
            {
              question: 'q_vaccination_record_covid19_date',
              dynamicSectionIndex: i,
              value: r['q_vaccination_record_covid19_date'],
            },
          ])
          .flat(1)
          .filter((ans) => !!ans.value);

        return [...acc, ...dynamicAnswers];
      } else {
        return [
          ...acc,
          {
            question: question,
            // @ts-ignore
            value: dto[question],
            dynamicSectionIndex: null,
          } as VaccinationQuestion,
        ];
      }
    }, [] as Array<VaccinationQuestion>);

    return new VaccinationModel(recordId, patientId, new Date(), flattenedResponses);
  }

  static fromSocketDto(dto: VaccinationSocketDto): VaccinationModel {
    return new VaccinationModel(
      dto.Id,
      dto.PatientId,
      new Date(dto.LastModifiedDate),
      dto.Items.map((item) => ({
        question: item.Question,
        value: item.Value,
        dynamicSectionIndex: item.DynamicSectionIndex,
      })),
    );
  }
}

export {VaccinationModelFactory};
