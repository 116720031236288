const downloadFile = (name: string, content: string, fileType: string): void => {
  const linkSource = `data:${fileType};base64,${content}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = `${name}`;
  downloadLink.click();
};

const fileSizeLabel = (fileSize: number): string => {
  let calcFileSize = fileSize / 1024;

  if (calcFileSize < 1024) {
    return `${calcFileSize.toFixed(1)} Kb`;
  }

  calcFileSize = calcFileSize / 1024;

  if (calcFileSize < 1024) {
    return `${calcFileSize.toFixed(1)} Mb`;
  }

  calcFileSize = calcFileSize / 1024;

  return `${calcFileSize.toFixed(1)} Gb`;
};

interface IDownloadedDocumentItem {
  id: string;
  isError: boolean;
}

export type {IDownloadedDocumentItem};
export {downloadFile, fileSizeLabel};
