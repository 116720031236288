import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import type {IGlobalImprovementScaleRepository} from '../repository';
import {GLOBAL_IMPROVEMENT_SCALE_REPOSITORY} from '../repository';
import type {GlobalImprovementScaleModel, GlobalImprovementScaleQuestionsModel} from '../model';
import {GlobalImprovementScaleModelFactory} from '../model';
import {routes} from '../../routing';
import {getSurvey} from '../../strengths-assessment/helper';
import type {IAlertIndicatorService} from '../../alert-indicator';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';
import type {
  IGlobalImprovementScaleService,
  IGlobalImprovementScaleFormDto,
} from './global-improvement-scale.service.interface';
import type {ITaskService} from '../../task/service';
import {TASK_SERVICE} from '../../task/service';
import {PredefinedTasks} from '../../enums/predefined-tasks';

@injectable()
class GlobalImprovementScaleService implements IGlobalImprovementScaleService {
  constructor(
    @inject(GLOBAL_IMPROVEMENT_SCALE_REPOSITORY) private readonly _repo: IGlobalImprovementScaleRepository,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(ALERT_INDICATOR_SERVICE) private readonly _alertIndicator: IAlertIndicatorService,
    @inject(TASK_SERVICE) private readonly _tasks: ITaskService,
  ) {
    makeAutoObservable(this);
  }

  private _listAssessments: Array<GlobalImprovementScaleModel> = [];

  get listAssessments(): Array<GlobalImprovementScaleModel> {
    return this._listAssessments;
  }

  private _selectedAssessment: GlobalImprovementScaleModel | null = null;

  get selectedAssessment(): GlobalImprovementScaleModel | null {
    return this._selectedAssessment;
  }

  private _lastFilledAssessment: GlobalImprovementScaleModel | null = null;

  get lastFilledAssessment(): GlobalImprovementScaleModel | null {
    return this._lastFilledAssessment;
  }

  private _listQuestions: GlobalImprovementScaleQuestionsModel[] = [];

  get listQuestions(): GlobalImprovementScaleQuestionsModel[] {
    return this._listQuestions;
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  get isReadonly(): boolean {
    return !!this._lastFilledAssessment?.lastUpdated || this._isLoading;
  }

  async loadAssessmentById(id: number): Promise<void> {
    this._isLoading = true;

    await this.loadQuestions();

    const model = await this._repo.find({id}).catch(console.error);

    this._isLoading = false;

    this._selectedAssessment = model ?? null;
  }

  async loadAssessments(): Promise<void> {
    this._isLoading = true;

    await this.loadQuestions();

    const models = await this._repo.list().catch(console.error);

    this._listAssessments = models ?? [];

    await this.fillLastFilledAssessment();

    this._isLoading = false;
  }

  async loadQuestions(): Promise<void> {
    if (!this._listQuestions.length) {
      this._listQuestions = await this._repo.listQuestions();
    }
  }

  async createAssessment(formDTO: IGlobalImprovementScaleFormDto, patientId: number): Promise<void> {
    const model = GlobalImprovementScaleModelFactory.fromFormDto(formDTO, patientId);

    await this._repo
      .save(model)
      .then(() => {
        this._alertIndicator.load();

        if (this._routing.length === 1) {
          this.navigateToList();
        } else {
          this._routing.goBack();
        }
      })
      .catch(console.error);
  }

  async fillLastFilledAssessment(): Promise<void> {
    const first = getSurvey<GlobalImprovementScaleModel>(this._listAssessments);

    if (!this._tasks.activeTasks.length) {
      await this._tasks.load();
    }

    if (first?.lastUpdated) {
      this._lastFilledAssessment = this._tasks.isTaskComplete(PredefinedTasks.ChronicConditionChecklist) ? first : null;
    } else {
      this._lastFilledAssessment = null;
    }
  }

  navigateToAssessment(id?: number): void {
    this._routing.push(`${routes.home}${routes.globalImprovementScale}${id ? `?id=${id}` : ''}`);
  }

  navigateToList(): void {
    this._routing.push(`${routes.home}${routes.myDashboard}`);
  }
}

export {GlobalImprovementScaleService};
