import React, {useContext, useMemo} from 'react';
import type {Container, interfaces} from 'inversify';

const InversifyContext = React.createContext<{container: Container | null}>({container: null});

type Props = {
  container: Container;
};

const InjectionProvider: React.FC<Props> = (props) => <InversifyContext.Provider value={{container: props.container}}>{props.children}</InversifyContext.Provider>;

const useInjection = <T extends unknown>(identifier: interfaces.ServiceIdentifier<T>) => {
  const {container} = useContext(InversifyContext);
  if (!container) {
    throw new Error();
  }

  return useMemo(() => container.get<T>(identifier), [container, identifier]);
};

export {useInjection, InjectionProvider};
