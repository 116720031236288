import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {ISurveyRepository} from '../repository';
import {SURVEY_REPOSITORY, SurveyRepository} from '../repository';
import type {ISurveyService} from '../service';
import {SURVEY_SERVICE, SurveyService} from '../service/';

const SurveyModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<ISurveyRepository>(SURVEY_REPOSITORY).to(SurveyRepository).inSingletonScope();

  bind<ISurveyService>(SURVEY_SERVICE).to(SurveyService).inSingletonScope();
});

export {SurveyModule};
