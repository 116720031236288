import type {IHealthMonitorService} from './health-monitor.service.interface';
import type {HealthMonitorGraphTypes, HealthMonitorModel, HealthMonitorNavigationModel} from '../model';
import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {IHealthMonitorRepository} from '../repository';
import {HEALTH_MONITOR_REPOSITORY} from '../repository';
import {formatISO} from 'date-fns';

@injectable()
class HealthMonitorService implements IHealthMonitorService {
  constructor(@inject(HEALTH_MONITOR_REPOSITORY) private readonly repo: IHealthMonitorRepository) {
    makeAutoObservable(this);
  }

  private _data: HealthMonitorModel | null = null;

  get data(): HealthMonitorModel | null {
    return this._data;
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _navigationList: HealthMonitorNavigationModel[] = [];

  get navigationList(): HealthMonitorNavigationModel[] {
    return this._navigationList;
  }

  async load(patientId: number, graphType: HealthMonitorGraphTypes, startDate?: Date, endDate?: Date): Promise<void> {
    if (patientId && graphType) {
      this._isLoading = true;
      await this.repo
        .find({
          patientId,
          type: graphType,
          startDate: startDate ? formatISO(startDate) : undefined,
          endDate: endDate ? formatISO(endDate) : undefined,
        })
        .then((result) => {
          if (!!result) {
            this._data = result;
          }
        })
        .catch(console.error)
        .finally(() => (this._isLoading = false));
    }
  }

  async loadNavigationList(): Promise<void> {
    this._isLoading = true;

    if (!this._navigationList.length) {
      await this.repo
        .getNavigationList()
        .then((result) => {
          this._navigationList = result;
        })
        .finally(() => (this._isLoading = false));
    }
  }
}

export {HealthMonitorService};
