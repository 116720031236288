import {ContainerModule} from 'inversify';
import {COURSE_SERVICE, CourseService} from '../service';
import type {ICourseService} from '../service';
import {COURSE_REPOSITORY, CourseRepository} from '../repository';
import type {ICourseRepository} from '../repository';

const CourseModule = new ContainerModule((bind) => {
  bind<ICourseService>(COURSE_SERVICE).to(CourseService).inSingletonScope();

  bind<ICourseRepository>(COURSE_REPOSITORY).to(CourseRepository).inSingletonScope();
});

export {CourseModule};
