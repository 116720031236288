import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {IHealthMonitorQuery, IHealthMonitorRepository} from './health-monitor.repository.interface';
import type {HealthMonitorModel, HealthMonitorNavigationModel} from '../model';
import {HealthMonitorModelFactory, HealthMonitorNavigationModelFactory} from '../model';
import type {IHealthMonitorPostResponseDto} from './dto';
import {HealthMonitorPostRequestFactoryDto} from './dto';
import type {IHealthMonitorNavigationGetResponseDto} from './dto/health-monitor-navigation-get-response.dto';

@injectable()
class HealthMonitorRepository implements IHealthMonitorRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(query?: Partial<IHealthMonitorQuery>): Promise<HealthMonitorModel | null> {
    let model: HealthMonitorModel | null = null;

    const postDto = query ? HealthMonitorPostRequestFactoryDto.fromQuery(query) : null;

    if (!!postDto) {
      const {data} = await this._http.post<IHealthMonitorPostResponseDto>('/HealthMonitor', postDto);

      model = HealthMonitorModelFactory.fromPostResponseDto(data);
    }

    return model;
  }

  list(query?: Partial<IHealthMonitorQuery>): Promise<HealthMonitorModel[]> {
    throw new Error('Not implemented');
  }

  remove(domain: HealthMonitorModel): Promise<void> {
    throw new Error('Not implemented');
  }

  save(domain: HealthMonitorModel): Promise<HealthMonitorModel> {
    throw new Error('Not implemented');
  }

  async getNavigationList(): Promise<HealthMonitorNavigationModel[]> {
    const {data} = await this._http.get<IHealthMonitorNavigationGetResponseDto[]>('/HealthMonitor');

    return data?.map((i) => HealthMonitorNavigationModelFactory.fromPostResponseDto(i)) ?? [];
  }
}

export {HealthMonitorRepository};
