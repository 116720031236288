class StrengthsAssessmentCreateRequestDto {
  constructor(
    public patientId = -1,
    public items: Array<{
      question: string;
      value: number;
    }> = [],
  ) {}
}

export {StrengthsAssessmentCreateRequestDto};
