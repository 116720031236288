import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IDocumentsService} from '../service';
import {DOCUMENTS_SERVICE, DocumentsService} from '../service';
import type {IDocumentsRepository} from '../repository';
import {DOCUMENTS_REPOSITORY, DocumentsRepository} from '../repository';

const DocumentsModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IDocumentsRepository>(DOCUMENTS_REPOSITORY).to(DocumentsRepository).inSingletonScope();
  bind<IDocumentsService>(DOCUMENTS_SERVICE).to(DocumentsService).inSingletonScope();
});

export {DocumentsModule};
