import type {IEducationCourseRateFormDto} from '../components';
import type {IEducationService} from './education.service.interface';
import {inject, injectable} from 'inversify';
import type {TaskModel} from '../../task';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {EducationCourseRatePostRequestDtoFactory} from './dto';
import type {CourseTask} from '../../patient-course/model/course-task';

@injectable()
class EducationService implements IEducationService {
  constructor(@inject(HTTP_SERVICE) private readonly http: IHttpService) {}

  async rateCourse(task: TaskModel | CourseTask, dto: IEducationCourseRateFormDto): Promise<void> {
    this.http.post('/CourseRating', EducationCourseRatePostRequestDtoFactory.fromTask(task, dto));
  }
}

export {EducationService};
