import type {FC} from 'react';
import React, {useState} from 'react';
import {AllowBaseModal} from './allow-base';
import styles from './allow-modal.module.scss';
import {ToggleSwitch} from '../../../../../../../components';
import type {ExternalProvider} from '../../../../../../../account';

interface IOwnProps {
  onClose(): void;
  onSubmit(model: ExternalProvider, isSharingInfo: boolean): void;
  provider: ExternalProvider;
  isShowShareToggle: boolean;
}

export const AddExternalProviderModal: FC<IOwnProps> = ({provider, onSubmit, isShowShareToggle, ...props}) => {
  const [toggle, setToggle] = useState(false);

  return (
    <AllowBaseModal {...props} onSubmit={() => onSubmit(provider, toggle)}>
      <ul className={styles.providerList}>
        <li>The following provider will be added to your profile</li>
        <li>
          {provider.firstName} {provider.lastName} {provider.title}
        </li>
        <li>{provider.providerState}</li>
        <li>{provider.providerOrganizationName}</li>
        {isShowShareToggle && (
          <li>
            Allow this provider to see my data <ToggleSwitch value={toggle} small onChange={(val) => setToggle(val)} />
          </li>
        )}
      </ul>
    </AllowBaseModal>
  );
};
