import type {FC} from 'react';
import React from 'react';
import styles from './assessment-report-item.module.scss';
import {ProgressBarLine} from '../../../components';
import type {AssessmentReportScoreModel} from '../../model/assessment-report-score.model';
import {useIcon} from './icon';
import classNames from 'classnames';

interface IAssessmentReportItemProps {
  score: AssessmentReportScoreModel;
  isPrint?: boolean;
}

const AssessmentReportItem: FC<IAssessmentReportItemProps> = ({score, isPrint}) => {
  const Icon = useIcon(score.iconKey);

  return (
    <div className={classNames(styles.container, {[styles.print]: isPrint})}>
      <div className={styles.containerHeader}>
        <span>
          <Icon />
        </span>
        <span className={styles.containerHeaderTitle}>{score.title}</span>
      </div>
      <div className={styles.containerDescription}>{score.description}</div>
      <ProgressBarLine progress={score.completedPercent} className={styles.progressBar} />
      <div className={styles.barLineSubtitle}>
        <span>Opportunity</span>
        <span>Strength</span>
      </div>
    </div>
  );
};

export {AssessmentReportItem};
