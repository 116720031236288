import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styles from './profile-emergency-contact.module.scss';
import {Patterns} from '../../../../../helpers/patterns';
import {getClearedPhoneValue, PATTERN_EMAIL_ERROR, phoneFormatter, REQUIRED_FIELD_ERROR} from '../../../../../helpers';
import {RelationshipType} from '../../../../../enums';
import {Button, Card, CardContent, Error, Input, MaskedInput, SingleDropdown} from '../../../../../components';
import type {ProfileEmergencyContactDto} from './dto';
import {TwoFactorAuthForm} from '../two-factor-auth';
import {isEqual, pick} from 'lodash';

export const relationOptions = [
  {
    value: RelationshipType.Child,
    displayName: 'Child',
  },
  {
    value: RelationshipType.Friend,
    displayName: 'Friend',
  },
  {
    value: RelationshipType.Parent,
    displayName: 'Parent',
  },
  {
    value: RelationshipType.Spouse,
    displayName: 'Spouse',
  },
  {
    value: RelationshipType.Sibling,
    displayName: 'Sibling',
  },
];

interface IProfileEmergencyContactProps {
  onSubmit: (data: ProfileEmergencyContactDto) => void;
  defaultValues: ProfileEmergencyContactDto;
  isProfileFormSaving?: boolean;
  requestCode(): Promise<void>;
}

const ProfileEmergencyContact: FC<IProfileEmergencyContactProps> = ({
  defaultValues,
  onSubmit,
  isProfileFormSaving,
  requestCode,
}) => {
  const {
    control,
    errors,
    handleSubmit,
    reset,
    formState: {isSubmitSuccessful},
    getValues,
  } = useForm<ProfileEmergencyContactDto>({
    defaultValues: defaultValues,
  });

  const [isEditMode, setEditMode] = useState(false);
  const [submittedData, setSubmittedData] = useState({});
  const [visibleTwoFactorModal, setVisibleTwoFactorModal] = useState(false);

  const visibleTwoFactorModalHandler = useCallback(
    async (isOpen?: boolean) => {
      isOpen && (await requestCode());
      setVisibleTwoFactorModal(!visibleTwoFactorModal);
    },
    [requestCode, visibleTwoFactorModal],
  );

  const onProfileEmergencyContactSubmit = (data: ProfileEmergencyContactDto) => {
    isEqual(pick(defaultValues, Object.keys(data)), data) ? setEditMode(false) : visibleTwoFactorModalHandler(true);
  };

  const submitHandler = (code: string) => {
    const data = getValues();
    setSubmittedData(data);
    onSubmit({
      ...data,
      phone: getClearedPhoneValue(data.phone) ?? '',
      verificationCode: code,
    });
    setEditMode(false);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({...submittedData});
    }
  }, [isSubmitSuccessful, submittedData, reset]);

  const actions = useMemo(
    () => (
      <div className={styles.actions}>
        {isEditMode ? (
          <>
            <div className={styles.cancel} onClick={() => setEditMode(false)}>
              Cancel
            </div>
            <Button className={styles.button} type={'submit'} block>
              Save Profile
            </Button>
          </>
        ) : (
          <Button className={styles.button} block onClick={() => setEditMode(true)} isLoading={isProfileFormSaving}>
            Edit Profile
          </Button>
        )}
      </div>
    ),
    [isEditMode, isProfileFormSaving],
  );

  return (
    <Card>
      <form onSubmit={handleSubmit(onProfileEmergencyContactSubmit)}>
        {actions}
        <CardContent gap={0}>
          <div className={styles.fields}>
            <div className={styles.field}>
              <div className={styles.label}>First Name</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="firstName"
                    rules={{required: true}}
                    control={control}
                    render={({value, onChange, name}) => (
                      <div>
                        <Input
                          className={styles.inputField}
                          name={name}
                          value={value}
                          onValueChanged={onChange}
                          isValid={!errors.firstName}
                        />
                        {errors.firstName && (
                          <div className={styles.errorField}>
                            <Error errorMessage={REQUIRED_FIELD_ERROR} />
                          </div>
                        )}
                      </div>
                    )}
                  />
                ) : (
                  <>{defaultValues.firstName || ''}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Last Name</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="lastName"
                    rules={{required: true}}
                    control={control}
                    render={({value, onChange, name}) => (
                      <div>
                        <Input
                          className={styles.inputField}
                          name={name}
                          value={value}
                          onValueChanged={onChange}
                          isValid={!errors.lastName}
                        />
                        {errors.lastName && (
                          <div className={styles.errorField}>
                            <Error errorMessage={REQUIRED_FIELD_ERROR} />
                          </div>
                        )}
                      </div>
                    )}
                  />
                ) : (
                  <>{defaultValues.lastName || ''}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Email</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="email"
                    rules={{
                      required: REQUIRED_FIELD_ERROR,
                      pattern: {
                        value: Patterns.Email,
                        message: PATTERN_EMAIL_ERROR,
                      },
                    }}
                    control={control}
                    render={({value, onChange, name}) => (
                      <div>
                        <Input
                          className={styles.inputField}
                          name={name}
                          value={value}
                          onValueChanged={onChange}
                          isValid={!errors.email}
                        />
                        {errors.email && (
                          <div className={styles.errorField}>
                            <Error errorMessage={errors?.email?.message ?? ''} />
                          </div>
                        )}
                      </div>
                    )}
                  />
                ) : (
                  <>{defaultValues.email || ''}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Phone Number</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="phone"
                    rules={{
                      required: REQUIRED_FIELD_ERROR,
                      pattern: {
                        value: Patterns.PhoneFormat,
                        message: 'Please enter a valid phone number',
                      },
                    }}
                    control={control}
                    render={({value, onChange, name}) => (
                      <MaskedInput
                        className={styles.inputField}
                        mask={Patterns.PhoneMask}
                        name={name}
                        value={value?.startsWith('+1') ? value : `+1-${value}`}
                        onValueChanged={onChange}
                        isValid={!errors.phone}
                        errorMessage={errors.phone ? errors.phone.message : ''}
                      />
                    )}
                  />
                ) : (
                  <>{(defaultValues.phone && phoneFormatter(defaultValues.phone)) || ''}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Relation</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="relationshipType"
                    control={control}
                    rules={{
                      validate: (value) => relationOptions.some((o) => o.value === value) || REQUIRED_FIELD_ERROR,
                    }}
                    render={({onChange, name, value}) => {
                      const selectedValue = relationOptions.find((option) => option.value === value);

                      return (
                        <div className={styles.dropdown}>
                          <SingleDropdown
                            name={name}
                            value={selectedValue}
                            onChange={(relationOption) => onChange(relationOption.value)}
                            options={relationOptions}
                            isValid={!!errors.relationshipType}
                            isError={!!errors.relationshipType}
                          />
                          {!!errors.relationshipType && <Error errorMessage={REQUIRED_FIELD_ERROR} />}
                        </div>
                      );
                    }}
                  />
                ) : (
                  <>
                    {relationOptions.find((option) => Number(defaultValues.relationshipType) === option.value)
                      ?.displayName || ''}
                  </>
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </form>
      <TwoFactorAuthForm
        onClose={visibleTwoFactorModalHandler}
        onSubmit={submitHandler}
        variant={'simple'}
        isVisible={visibleTwoFactorModal}
      />
    </Card>
  );
};

export {ProfileEmergencyContact};
export type {IProfileEmergencyContactProps};
