import type {FC} from 'react';
import {useMemo} from 'react';
import {isSameDay, subDays} from 'date-fns';
import styles from './chat.module.scss';
import {ChatMessage} from '../chat-message';
import type {MessageModel} from '../../../../../message';
import {groupMessagesByDateHelper} from './group-messages-by-date';
import {DateWrapper} from '../../../../../components';
import type {IAccountService} from '../../../../../account';
import {ACCOUNT_SERVICE} from '../../../../../account';
import {useInjection} from '../../../../../ioc';

interface IChatProps {
  messages: MessageModel[];
}

interface IService {
  account: IAccountService;
}

const Chat: FC<IChatProps & IService> = ({messages, account}) => {
  const messagesGroupedByDate = useMemo(
    () => groupMessagesByDateHelper(messages, account.timeZone || ''),
    [account.timeZone, messages],
  );

  const messagesGroupedByDateElements = useMemo(
    () =>
      Array.from(messagesGroupedByDate.keys()).map((dateGroup, index) => {
        const items = messagesGroupedByDate.get(dateGroup)?.map((message) => {
          const isRecipientMessage = message.userId !== account.id;

          return <ChatMessage key={message.id} isRecipient={isRecipientMessage} message={message} />;
        });

        const dateNow = new Date();
        const isToday = isSameDay(dateGroup, dateNow);
        const isYesterday = isSameDay(dateGroup, subDays(dateNow, 1));
        const dateString = <DateWrapper date={dateGroup} />;
        const date = isToday ? 'Today' : isYesterday ? 'Yesterday' : dateString;

        return (
          <div className={styles.dateGroup} key={`${dateString.key}_${index}`}>
            <p className={styles.dateGroupTitle}>{date}</p>.<div className={styles.messages}>{items}</div>
          </div>
        );
      }),
    [messagesGroupedByDate, account.id],
  );

  return <div className={styles.root}>{messagesGroupedByDateElements}</div>;
};

const InjectedChat: FC<IChatProps> = (props) => <Chat {...props} account={useInjection(ACCOUNT_SERVICE)} />;
export {InjectedChat as Chat};
export type {IChatProps};
