import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {ITrellisService} from '../service/';
import {TRELLIS_SERVICE, TrellisService} from '../service/';

const TrellisModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<ITrellisService>(TRELLIS_SERVICE).to(TrellisService).inSingletonScope();
});

export {TrellisModule};
