import React from 'react';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import DateInput from './date-input';
import type {DateSelectorProps, IDateSelectorState} from './date-selector-legacy.types';
import {DateSelectorLegacyTypes} from './date-selector-legacy.types';
import {months, years} from './date.helper';
import {ArrowIcon, GreyCalendarIcon} from '../../assets/icons';
import moment from 'moment';
import './date-selector-legacy.scss';

const defaultFormat = 'MM/DD/YYYY';

class DateSelectorLegacy extends React.Component<DateSelectorProps, IDateSelectorState> {
  format = this.props.format || defaultFormat;

  state: IDateSelectorState = {
    currentInputValue: '',
    oldValue: '',
  };

  type = this.props.type ?? DateSelectorLegacyTypes.Common;

  isDateValidForSpecificFormat = (date: string, format: string): boolean => moment(date, format, true).isValid();

  isValidDate = (date: Date): boolean => date instanceof Date && !Number.isNaN(date.getTime());

  getInputValue = (): string => {
    let result: string = this.state.currentInputValue;

    if (!!this.props.value) {
      const currentValue = moment(this.props.value).format(this.format);

      if (currentValue !== this.state.oldValue) {
        this.setState((state) => ({
          oldValue: state.currentInputValue,
          currentInputValue: currentValue,
        }));

        result = currentValue;
      }
    }

    return result;
  };

  render() {
    const inputValue = this.getInputValue();

    return (
      <div className={classnames('date-selector', `date-selector--${this.type}`, this.props.className)}>
        {!!this.props.label ? (
          <label
            className={classnames(
              'date-selector__label',
              `date-selector__label--${this.props.type}`,
              this.props.labelClassName,
            )}>
            {this.props.label}
          </label>
        ) : null}
        <div>
          <DatePicker
            highlightDates={this.props.highlightDates}
            name={this.props.name}
            // @ts-ignore
            formatWeekDay={this.props.formatWeekDay}
            selected={this.isDateValidForSpecificFormat(inputValue, this.format) ? moment(inputValue).toDate() : null}
            disabledKeyboardNavigation
            onChange={(value) => {
              if (!this.props.nonclickable) {
                // @ts-ignore
                this.props.onChange && this.props.onChange(value);
                // @ts-ignore
                this.setState({currentInputValue: moment(value).format(this.format)});
              }
            }}
            disabled={this.props.disabled}
            popperPlacement={
              this.props.type === DateSelectorLegacyTypes.Inline ||
              this.props.type === DateSelectorLegacyTypes.InlineWithSmallLabel
                ? 'bottom-end'
                : 'bottom-start'
            }
            customInput={
              <DateInput
                inputRef={this.props.inputRef}
                isValid={this.props.isValid}
                disabled={this.props.disabled}
                inputValue={inputValue}
                setInputValue={(value) => {
                  this.setState({currentInputValue: value});

                  const date = new Date(value);

                  if (this.props.onChange) {
                    if (this.isValidDate(date)) {
                      this.props.onChange(date);
                    } else {
                      // @ts-ignore
                      this.props.onChange(null);
                    }
                  }
                }}
                testId={`${this.props.testId}-input`}
                customIcon={this.props.customIcon}
              />
            }
            inline={this.props.isInlineCalendar}
            renderCustomHeader={({date, changeYear, changeMonth, decreaseMonth, increaseMonth}) =>
              this.props.customHeader ? (
                this.props.customHeader(date, changeYear, changeMonth, decreaseMonth, increaseMonth)
              ) : (
                <div className="react-datepicker__selectors">
                  <div className="react-datepicker__select-content">
                    <select
                      value={months[date.getMonth()]}
                      onChange={({target: {value}}) => changeMonth(months.indexOf(value))}
                      data-testid={`${this.props.testId}-month-container`}>
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <GreyCalendarIcon />
                  </div>
                  <div className="react-datepicker__select-content">
                    <select
                      value={date.getFullYear()}
                      // @ts-ignore
                      onChange={({target: {value}}) => changeYear(value)}
                      data-testid={`${this.props.testId}-year-container`}>
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <ArrowIcon />
                  </div>
                </div>
              )
            }
          />
        </div>
      </div>
    );
  }
}

export {DateSelectorLegacy};
