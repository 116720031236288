import {EncounterTypeEnum} from './encounter-type.enum';
import {EncounterMethodTypeEnum} from './encounter-method.enum';
import {EncounterStatusTypeEnum} from './encounter-status-type.enum';

class EncounterProviderModel {
  constructor(public id = 0, public name = '', public title = '', public role = '') {}
}

class EncounterModel {
  constructor(
    public id = 0,
    public date = new Date(),
    public type = EncounterTypeEnum.None,
    public method = EncounterMethodTypeEnum.None,
    public status = EncounterStatusTypeEnum.Submitted,
    public provider: EncounterProviderModel | null = null,
    public attachments: string[] = [],
    public addendumDates: Date[] = [],
    public isCreatedByPatient: boolean = false,
    public isPossibleViewSummary: boolean = false,
    public createdByFullName: string = '',
  ) {}
}

export {EncounterModel, EncounterProviderModel};
