import type {DocumentsModel} from '../../model';
import {DocumentDetailsPostRequestDto, DocumentsPostRequestDto} from './documents-post-request.dto';
import {formatDateApi} from '../../../helpers';

class DocumentsPostRequestDtoFactory {
  static fromModel(model: DocumentsModel): DocumentsPostRequestDto {
    return new DocumentsPostRequestDto(
      model.patientId,
      model.fileId,
      model.type,
      model.upload ? formatDateApi(model.upload) : '',
      new DocumentDetailsPostRequestDto(
        model.fileId,
        -1,
        model.upload ? formatDateApi(model.upload) : '',
        model.details?.note,
        model.type,
        model.otherNote,
        '',
        model.details?.cbc,
        model.details?.bmp,
        model.details?.cmp,
        model.details?.liverFunction,
        model.details?.lipidPanel,
        model.details?.drugLevel,
        model.details?.renalFunction,
        model.details?.urinalysis,
        model.details?.tuberculosis,
      ),
    );
  }
}

export {DocumentsPostRequestDtoFactory};
