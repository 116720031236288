import type {
  IDiseaseSeverityScreenerQuery,
  IDiseaseSeverityScreenerRepository,
} from './disease-severity-screener.repository.interface';
import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {DiseaseSeverityScreenerModel, DiseaseSeverityScreenerQuestionModel} from '../model';
import {DiseaseSeverityScreenerModelFactory, DiseaseSeverityScreenerQuestionModelFactory} from '../model';
import type {IDiseaseSeverityScreenerGetResponseDto, IDiseaseSeverityScreenerQuestionsGetResponseDto} from './dto';
import {IDiseaseSeverityScreenerSaveRequestDto} from './dto';

@injectable()
class DiseaseSeverityScreenerRepository implements IDiseaseSeverityScreenerRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(query?: Partial<IDiseaseSeverityScreenerQuery>): Promise<DiseaseSeverityScreenerModel | null> {
    let model: DiseaseSeverityScreenerModel | null = null;

    if (query?.id) {
      const {data} = await this._http.get<IDiseaseSeverityScreenerGetResponseDto>(
        `DiseaseSeverityScreener/${query?.id}`,
      );

      model = DiseaseSeverityScreenerModelFactory.fromGetResponseDto(data);
    }

    return model;
  }

  async list(): Promise<DiseaseSeverityScreenerModel[]> {
    const response = await this._http.get<IDiseaseSeverityScreenerGetResponseDto[]>(`DiseaseSeverityScreener`);

    return response.data?.map((i) => DiseaseSeverityScreenerModelFactory.fromGetResponseDto(i)) ?? [];
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async save(model: DiseaseSeverityScreenerModel): Promise<void> {
    const postDto = new IDiseaseSeverityScreenerSaveRequestDto(model.items);

    await this._http.post<number>('DiseaseSeverityScreener', postDto);
  }

  async listQuestions(): Promise<DiseaseSeverityScreenerQuestionModel[]> {
    const {data} = await this._http.get<IDiseaseSeverityScreenerQuestionsGetResponseDto[]>(
      'DiseaseSeverityScreener/getsurveyforpatient',
    );

    return data.map((dto) => DiseaseSeverityScreenerQuestionModelFactory.fromGetDto(dto));
  }
}

export {DiseaseSeverityScreenerRepository};
