import type React from 'react';
import type {IconTypeLegacy} from '../icon-legacy';
import type {ButtonType} from '../button-legacy';

interface FileUploaderProps {
  label?: string;
  className?: string;
  accept: string;
  maxSizeBytes?: number;
  onUploadFile: (file: File, base64: string) => void;
  onRemoveFile: () => void;
  onError?: (errorType: FileUploaderErrorType, message: string) => void;
  errorAcceptMessage: string;
  errorMaxSizeMessage: string;
  inputRef?: React.Ref<HTMLInputElement>;
  uploadedFile?: string;
  submitActionText: string;
  dragText: string;
  uploadText?: string;
  removeActionText: string;
  uploadNewFileActionText: string;
  type?: FileUploaderType;
  buttonType?: ButtonType;
  iconType?: IconTypeLegacy | undefined;
}

interface FileUploaderState {
  isFileUploaded: boolean;
  base64: string;
  acceptableError: string;
  sizeError: string;
  uploadedFile: undefined | string;
  isPopupShown: boolean;
}

enum FileUploaderType {
  Image = 'image',
  Button = 'button',
}

enum FileUploaderErrorType {
  WrongFileType,
  WrongFileSize,
}

export type {FileUploaderProps, FileUploaderState};
export {FileUploaderType, FileUploaderErrorType};
