import {inject, injectable} from 'inversify';
import type {
  IDiseaseSeverityScreenerFormDto,
  IDiseaseSeverityScreenerService,
} from './disease-severity-screener.service.interface';
import type {IDiseaseSeverityScreenerRepository} from '../repository';
import {DISEASE_SEVERITY_SCREENER_REPOSITORY} from '../repository';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import {makeAutoObservable} from 'mobx';
import {routes} from '../../routing';
import type {DiseaseSeverityScreenerModel, DiseaseSeverityScreenerQuestionModel} from '../model';
import {DiseaseSeverityScreenerModelFactory} from '../model';
import {getSurvey} from '../../strengths-assessment/helper';
import type {IAlertIndicatorService} from '../../alert-indicator';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';
import type {ITaskService} from '../../task/service';
import {TASK_SERVICE} from '../../task/service';
import {PredefinedTasks} from '../../enums/predefined-tasks';

@injectable()
class DiseaseSeverityScreenerService implements IDiseaseSeverityScreenerService {
  constructor(
    @inject(DISEASE_SEVERITY_SCREENER_REPOSITORY) private readonly _repo: IDiseaseSeverityScreenerRepository,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(ALERT_INDICATOR_SERVICE) private readonly _alertIndicator: IAlertIndicatorService,
    @inject(TASK_SERVICE) private readonly _tasks: ITaskService,
  ) {
    makeAutoObservable(this);
  }

  private _lastFilledAssessment: DiseaseSeverityScreenerModel | null = null;

  get lastFilledAssessment(): DiseaseSeverityScreenerModel | null {
    return this._lastFilledAssessment;
  }

  private _listAssessments: DiseaseSeverityScreenerModel[] = [];

  get listAssessments(): DiseaseSeverityScreenerModel[] {
    return this._listAssessments;
  }

  private _selectedAssessment: DiseaseSeverityScreenerModel | null = null;

  get selectedAssessment(): DiseaseSeverityScreenerModel | null {
    return this._selectedAssessment;
  }

  private _listQuestions: DiseaseSeverityScreenerQuestionModel[] = [];

  get listQuestions(): DiseaseSeverityScreenerQuestionModel[] {
    return this._listQuestions;
  }

  private _isLoading: boolean = true;

  get isLoading(): boolean {
    return this._isLoading;
  }

  get isReadonly(): boolean {
    return !!this._lastFilledAssessment?.lastUpdated || this._isLoading;
  }

  async loadAssessmentById(id: number): Promise<void> {
    this._isLoading = true;

    await this.loadQuestions();

    const model = await this._repo.find({id}).catch(console.error);

    this._selectedAssessment = model ?? null;

    this._isLoading = false;
  }

  clearAssessment() {
    this._selectedAssessment = null;
  }

  async loadAssessments(): Promise<void> {
    this._isLoading = true;

    await this.loadQuestions();

    const models = await this._repo.list().catch(console.error);

    this._listAssessments = models ?? [];

    await this.fillLastFilledAssessment();

    this._isLoading = false;
  }
  async loadQuestions(): Promise<void> {
    if (!this._listQuestions.length) {
      this._listQuestions = await this._repo.listQuestions();
    }
  }

  async createAssessment(formDto: IDiseaseSeverityScreenerFormDto, isIbs: boolean): Promise<void> {
    const model = DiseaseSeverityScreenerModelFactory.fromFormDto(formDto);

    await this._repo
      .save(model)
      .then(() => {
        this._alertIndicator.load();

        if (this._routing.length === 1) {
          this.navigateToList(isIbs);
        } else {
          this._routing.goBack();
        }
      })
      .catch(console.error);
  }

  async fillLastFilledAssessment(): Promise<void> {
    const first = getSurvey<DiseaseSeverityScreenerModel>(this._listAssessments);

    if (!this._tasks.activeTasks.length) {
      await this._tasks.load();
    }

    if (first?.lastUpdated) {
      this._lastFilledAssessment = this._tasks.isTaskComplete(PredefinedTasks.DiseaseSeverityScreener) ? first : null;
    } else {
      this._lastFilledAssessment = null;
    }
  }

  navigateToAssessment(isIbs: boolean, id?: number): void {
    const page = isIbs ? routes.symptomSeverityScale : routes.diseaseSeverityScreener;

    this._routing.push(`${routes.home}${page}${id ? `?id=${id}` : ''}`);
  }

  navigateToList(isIbs: boolean): void {
    const page = isIbs ? routes.symptomSeverityScaleList : routes.diseaseSeverityScreenerList;

    this._routing.push(`${routes.home}${page}`);
  }
}

export {DiseaseSeverityScreenerService};
