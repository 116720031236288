import type {FC} from 'react';
import styles from './vaccination-form-element-input.module.scss';
import type {IVaccinationFormElement} from '../vaccination-form.component';
import {CovidVaccinationInput, DateVaccinationInput, SimpleVaccinationInput} from './components';

const VaccinationFormElementInput: FC<{
  element: IVaccinationFormElement;
}> = (props) => {
  let elementToRender: JSX.Element | null;

  if (!!props.element.covidVaccineName) {
    elementToRender = <CovidVaccinationInput element={props.element} />;
  } else if (!!props.element.dateSelection1Name) {
    elementToRender = <DateVaccinationInput element={props.element} />;
  } else {
    elementToRender = <SimpleVaccinationInput element={props.element} />;
  }

  return <div className={styles.root}>{elementToRender}</div>;
};

export {VaccinationFormElementInput};
