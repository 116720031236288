import {formatWithOptions} from 'date-fns/fp';
import {enUS} from 'date-fns/locale';
import {format, formatInTimeZone, getTimezoneOffset, utcToZonedTime} from 'date-fns-tz';
import {DATE_API_FORMAT, DEFAULT_FORMAT} from '../constants';
import {addMinutes, endOfDay, startOfDay, subMinutes} from 'date-fns';

const MINUTE = 60 * 1000;

const formatDateApi = (date: Date): string => formatWithOptions({locale: enUS}, DATE_API_FORMAT)(date);

const formatDateApiUTC = (date: Date): string => formatDateWithTimeZone(date, DATE_API_FORMAT);

const formatDateTimeApi = (date: Date): string => formatWithOptions({locale: enUS}, "yyyy-MM-dd'T'HH:mm")(date);

const formatFullDateTime = (date: Date): string => formatWithOptions({locale: enUS}, "yyyy-MM-dd'T'HH:mm:ss")(date);

const formatFullDateTimeUTC = (date: Date): string => formatDateWithTimeZone(date, "yyyy-MM-dd'T'HH:mm:ss");

const formatWithMillisecondsUTC = (date: Date): string => formatDateWithTimeZone(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

const formatDate = (date: Date, dateFormat?: string, timeZone?: string, needUpperCase = false) => {
  const newDate = format(utcToZonedTime(date, timeZone ?? ''), dateFormat || DEFAULT_FORMAT, {
    timeZone,
  });

  return needUpperCase ? newDate.toUpperCase() : newDate;
};

const formatDateWithTimeZone = (date: Date, format: string = DEFAULT_FORMAT, timeZone: string = 'UTC') =>
  formatInTimeZone(date, timeZone, format, {locale: enUS});

const shiftToUTCEndOfDay = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const endOfDateDay = endOfDay(date);

  return subMinutes(endOfDateDay, offset);
};

const shiftTimezone = (date: Date = new Date()) => addMinutes(date, new Date(date).getTimezoneOffset());
const unshiftTimezone = (date: Date = new Date()) => subMinutes(date, new Date(date).getTimezoneOffset());

const unShiftDateByStringTimeZone = (date: Date, timeZone?: string): Date => {
  const timeZoneOffsetInMinutes = timeZone ? getTimezoneOffset(timeZone) / MINUTE : 0;

  return subMinutes(date, timeZoneOffsetInMinutes);
};

const shiftDateByStringTimeZone = (date: Date, timeZone?: string): Date => {
  const timeZoneOffsetInMinutes = timeZone ? getTimezoneOffset(timeZone) / MINUTE : 0;

  return addMinutes(date, timeZoneOffsetInMinutes);
};

const dateIsPast = (date: Date): boolean => startOfDay(date).getTime() < startOfDay(new Date()).getTime();

export {
  formatDateTimeApi,
  formatDateApi,
  formatDateApiUTC,
  formatDate,
  formatDateWithTimeZone,
  formatFullDateTime,
  shiftToUTCEndOfDay,
  shiftTimezone,
  unShiftDateByStringTimeZone,
  shiftDateByStringTimeZone,
  unshiftTimezone,
  formatWithMillisecondsUTC,
  formatFullDateTimeUTC,
  dateIsPast,
};
