interface IWorkProductivityItem {
  question: string;
  value: string;
}

class WorkProductivityModel {
  constructor(
    public items: IWorkProductivityItem[] = [],
    public patientId: number = -1,
    public id: number = -1,
    public lastUpdated: Date | null = null,
    public name: string = '',
  ) {}
}

export {WorkProductivityModel};
export type {IWorkProductivityItem};
