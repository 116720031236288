import {inject, injectable} from 'inversify';
import type {ILayoutsService, NavigationListAccess} from './layouts.service.interface';
import {makeAutoObservable} from 'mobx';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {ILayoutsGetNavigationDto} from './dto';
import {NavigationItemsModelFactory} from './navigation-items.model.factory';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import {routes} from '../../routing';
import {getRouteByLocation} from './navigation-ids';
import type {NavigationIds} from './navigation-ids';

@injectable()
class LayoutsService implements ILayoutsService {
  private _navigationList: Array<NavigationListAccess> = [];

  constructor(
    @inject(HTTP_SERVICE) private readonly _http: IHttpService,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
  ) {
    makeAutoObservable(this);
  }

  private _toggleEmergencyModal = (): void => {};

  get toggleEmergencyModal(): () => void {
    return this._toggleEmergencyModal;
  }

  set toggleEmergencyModal(value: () => void) {
    this._toggleEmergencyModal = value;
  }

  async loadNavigationAccess(): Promise<void> {
    try {
      const {data: navigationDto} = await this._http.get<ILayoutsGetNavigationDto>('NavigationMenu');
      this._navigationList = NavigationItemsModelFactory.fromGetDto(navigationDto);
    } catch {
      this._navigationList = [];
    }
  }

  getNavigationLabel(id: NavigationIds): string | undefined {
    return this._navigationList.find((nav) => nav.id === id)?.displayName;
  }

  getNavigationVisibility(id: NavigationIds): boolean {
    return this._navigationList.find((nav) => nav.id === id)?.isActive ?? false;
  }

  checkLocation(location: string, forceRouting?: boolean): void {
    const nav = this._navigationList.find((nav) => getRouteByLocation(location) === nav.id);

    if (nav && !nav?.isActive && !forceRouting) {
      this._routing.push(routes.home);
    }
  }
}

export {LayoutsService};
