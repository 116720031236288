import type {IStrengthsAssessmentService} from './strengths-assessment.service.interface';
import type {StrengthsAssessmentModel} from '../model';
import {inject, injectable} from 'inversify';
import type {IStrengthsAssessmentRepository} from '../repository';
import {STRENGTHS_ASSESSMENT_REPOSITORY} from '../repository';
import type {IStrengthsAssessmentFormDto} from '../dto';
import {StrengthsAssessmentCreateRequestDtoFactory} from '../dto';
import {makeAutoObservable} from 'mobx';
import {getSurvey} from '../helper';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import {routes} from '../../routing';
import type {IAlertIndicatorService} from '../../alert-indicator';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';
import type {ITaskService} from '../../task/service';
import {TASK_SERVICE} from '../../task/service';
import {PredefinedTasks} from '../../enums/predefined-tasks';

@injectable()
class StrengthsAssessmentService implements IStrengthsAssessmentService {
  constructor(
    @inject(STRENGTHS_ASSESSMENT_REPOSITORY) private readonly repo: IStrengthsAssessmentRepository,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(ALERT_INDICATOR_SERVICE) private readonly _alertIndicator: IAlertIndicatorService,
    @inject(TASK_SERVICE) private readonly _tasks: ITaskService,
  ) {
    makeAutoObservable(this);
  }

  private _assessments: StrengthsAssessmentModel[] = [];

  get assessments(): StrengthsAssessmentModel[] {
    return this._assessments;
  }

  private _isLoading: boolean = true;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _lastFilledAssessment: StrengthsAssessmentModel | null = null;

  get lastFilledAssessment(): StrengthsAssessmentModel | null {
    return this._lastFilledAssessment;
  }

  get isReadOnly(): boolean {
    return !!this._lastFilledAssessment?.lastUpdated || this._isLoading;
  }

  private _selectedAssessment: StrengthsAssessmentModel | null = null;

  get selectedAssessment(): StrengthsAssessmentModel | null {
    return this._selectedAssessment;
  }

  async loadAssessmentById(id: number): Promise<void> {
    this._isLoading = true;
    const model = await this.repo.find({id}).catch(console.error);

    this._selectedAssessment = model ?? null;
    this._isLoading = false;
  }

  async createAssessment(dto: IStrengthsAssessmentFormDto, patientId: number): Promise<void> {
    const createDto = StrengthsAssessmentCreateRequestDtoFactory.fromFormDto(dto, patientId);

    await this.repo.save(createDto).then((createdAssessment) => {
      this._alertIndicator.load();

      this._assessments = [...this._assessments, createdAssessment];

      if (this._routing.length === 1) {
        this.navigateToList();
      } else {
        this._routing.goBack();
      }

      this.fillLastFilledAssessment();
    });
  }

  async loadAssessments(): Promise<void> {
    this._isLoading = true;
    this._assessments = await this.repo.list();
    this._isLoading = false;

    await this.fillLastFilledAssessment();
  }

  async fillLastFilledAssessment(): Promise<void> {
    const first = getSurvey<StrengthsAssessmentModel>(this._assessments);

    if (!this._tasks.activeTasks.length) {
      await this._tasks.load();
    }

    if (first?.lastUpdated) {
      this._lastFilledAssessment = this._tasks.isTaskComplete(PredefinedTasks.StrengthAssessment) ? first : null;
    } else {
      this._lastFilledAssessment = null;
    }
  }

  navigateToAssessment(id?: number): void {
    this._routing.push(`${routes.home}${routes.strengthsAssessment}${id ? `?id=${id}` : ''}`);
  }

  navigateToList(): void {
    this._routing.push(`${routes.home}${routes.strengthsAssessmentsList}`);
  }
}

export {StrengthsAssessmentService};
