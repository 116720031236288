enum ErrorListItemColor {
  Default = 'Default',
  Red = 'Red',
  Green = 'Green',
}

enum ErrorListItemFontSize {
  Default = 'Default',
  Small = 'Small',
}

export {ErrorListItemColor, ErrorListItemFontSize};
