import type {FC} from 'react';
import React from 'react';
import type {IEncounterAssessmentAndPlanSection} from '../../../../../encounter/models';
import styles from './encounter-assessment-and-plan.module.scss';
import classNames from 'classnames';

const EncounterAssessmentAndPlan: FC<{assessmentAndPlan: IEncounterAssessmentAndPlanSection | null}> = ({
  assessmentAndPlan,
}) => (
  <div className={styles.details}>
    <div>
      <div className={styles.detailLabel}>Assessment and Plan</div>
      {!!assessmentAndPlan?.targets.length && (
        <>
          <div className={styles.subHeader}>Assessment/targets identified</div>
          <ul className={classNames(styles.detailValue, styles.listItemValue)}>
            {assessmentAndPlan.targets.map((target) => (
              <li key={target}>• {target}</li>
            ))}
          </ul>
        </>
      )}
      {assessmentAndPlan?.goals && (
        <>
          <div className={styles.subHeader}>Goals</div>
          <div className={styles.detailValue}>{assessmentAndPlan.goals}</div>
        </>
      )}
      {!!assessmentAndPlan?.interventions?.length && (
        <>
          <div className={styles.subHeader}>Interventions</div>
          <ul className={classNames(styles.detailValue, styles.listItemValue)}>
            {assessmentAndPlan.interventions.map((intervention) => (
              <li key={intervention.option}>
                • {intervention.option}
                {intervention.description && `. ${intervention.description}`}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  </div>
);

export {EncounterAssessmentAndPlan};
