import type {FC} from 'react';
import React from 'react';
import styles from './assessment-item-wrapper.module.scss';
import classNames from 'classnames';

interface IAssessmentItemWrapperProps {
  title: string;
  name?: string;
  onlyTitle?: boolean;
  swapColumnsOnMobile?: boolean;
}

const AssessmentItemWrapper: FC<IAssessmentItemWrapperProps> = ({
  title,
  name,
  children,
  onlyTitle,
  swapColumnsOnMobile,
}) => (
  <div
    className={classNames(styles.root, {
      [styles.oneColumn]: onlyTitle,
      [styles.swapColumnsOnMobile]: swapColumnsOnMobile,
    })}
    data-testid={`AssessmentItemWrapper_${name}`}>
    <div className={classNames(styles.label, {[styles.onlyTitle]: onlyTitle})}>{title}</div>
    {!onlyTitle && <div className={styles.value}>{children}</div>}
  </div>
);

export {AssessmentItemWrapper};
export type {IAssessmentItemWrapperProps};
