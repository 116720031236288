import type {FC} from 'react';
import React, {useMemo} from 'react';
import styles from './medications-frequency-element.module.scss';
import {Controller, useFormContext} from 'react-hook-form';
import {optionsFromMapHelper, REQUIRED_FIELD_ERROR} from '../../../helpers';
import {Error, Input, InputType, SingleDropdown} from '../../../components';
import {
  frequencyTypesOptions,
  frequencyTypesOptionsForMedicationSkyrizi,
  MedicationsFrequenciesTypesEnum,
} from '../../model';

const optionsDefault = optionsFromMapHelper(frequencyTypesOptions);
const optionsForMedicationSkyrizi = optionsFromMapHelper(frequencyTypesOptionsForMedicationSkyrizi);

const medicationSkyrizi = 'Skyrizi® (risankizumab-rzaa)';

const MedicationsFrequencyElement: FC = () => {
  const {control, formState, errors, watch} = useFormContext();

  const medicationSafeId = watch('medicationSafeId');

  const options = useMemo(
    () => (medicationSafeId?.includes(medicationSkyrizi) ? optionsForMedicationSkyrizi : optionsDefault),
    [medicationSafeId],
  );

  return (
    <div className={styles.root}>
      <Controller
        name={'frequency'}
        control={control}
        render={({name, onChange, value}) => {
          const currentValue = options.find((option) => option.value === value);

          return (
            <SingleDropdown
              className={styles.frequencyDropdown}
              name={name}
              ariaLabelTitle='Frequency'
              onChange={(option) => {
                onChange(option.value);
              }}
              value={currentValue}
              options={options}
              showInputValueAlways
            />
          );
        }}
      />
      {watch('frequency') === MedicationsFrequenciesTypesEnum.Other && (
        <>
          <Controller
            name={'frequencyOther'}
            control={control}
            rules={{required: REQUIRED_FIELD_ERROR}}
            render={({value, onChange, name}) => (
              <Input
                className={styles.input}
                name={name}
                ariaLabelTitle='Frequency other'
                value={value || ''}
                isValid={!errors[name]?.message}
                onValueChanged={onChange}
                type={InputType.Default}
                maxLength={100}
              />
            )}
          />
          {!!formState.errors['frequencyOther']?.message && (
            <div className={styles.error}>
              <Error errorMessage={formState.errors['frequencyOther'].message} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export {MedicationsFrequencyElement};
