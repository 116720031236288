import {CompanyModel} from './company.model';
import type {ICompanyRecordGetResponseDto} from '../dto';

class CompanyModelFactory {
  static fromGetResponseDtoRecord(dto: ICompanyRecordGetResponseDto): CompanyModel {
    return new CompanyModel(
      dto.privacyPolicyLink,
      dto.termsAndConditionsLink,
      dto.nonDiscriminationsLink,
      dto.contactEmail,
      dto.contactPhone,
      dto.facebookLink,
      dto.twitterLink,
      dto.instagramLink,
      dto.linkedInLink,
      dto.tikTokLink,
      dto.youTubeLink,
      dto.helpLink,
      dto.copyRight,
      dto.informationalVersion,
      dto.disclaimer,
      dto.paymentManagementLink,
      dto.lmsLink,
      dto.calendarManagementLink,
      dto.glossaryOfMedicationMonitoringLink,
      dto.vaccinationReferenceGuideLink,
    );
  }
}

export {CompanyModelFactory};
