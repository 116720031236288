import type {FC} from 'react';
import React, {useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Button, Card} from '../../../components';
import type {ISurveyElementResponse, ISurveyFormDto, ISurveySpecElement, SurveySpecModel} from '../../../survey';
import {flatElements, getDefaultValues, SurveyFormElement} from '../../../survey';
import {scrollToError} from '../../../helpers';
import styles from './tracking-daily-form.module.scss';
import classNames from 'classnames';

interface ITrackingDailyFormProps {
  spec: SurveySpecModel;
  onSubmit: (data: ISurveyFormDto) => void;
  elementValues: Array<ISurveyElementResponse>;
  isLoading: boolean;
  isSubmitDisabled: boolean;
  readonly?: boolean;
  isOnBoarding?: boolean;
  isEverCompleted?: boolean;
  blankBackground?: boolean;
}

const TrackingDailyForm: FC<ITrackingDailyFormProps> = ({
  spec,
  onSubmit,
  elementValues,
  isLoading,
  isSubmitDisabled,
  readonly,
  isOnBoarding,
  isEverCompleted,
  blankBackground,
}) => {
  const flattenedElements: Array<ISurveySpecElement> = useMemo(
    () => (spec?.elements && flatElements(spec.elements)) ?? [],
    [spec?.elements],
  );

  const defaultValues = useMemo(
    () => (flattenedElements.length ? getDefaultValues(elementValues, flattenedElements, isEverCompleted) : {}),
    [flattenedElements, elementValues, isEverCompleted],
  );

  const {reset, ...formMethods} = useForm<ISurveyFormDto>({defaultValues});

  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  useEffect(() => {
    scrollToError(formMethods.errors);
  }, [formMethods.errors]);

  const elements = useMemo(
    () =>
      spec?.elements.map((element) => (
        <SurveyFormElement
          key={element.elementId}
          element={element}
          readonly={!!readonly}
          elementValues={elementValues}
          isOnBoarding={isOnBoarding}
          isEverCompleted={isEverCompleted}
          flattenedElements={flattenedElements}
        />
      )),
    [elementValues, flattenedElements, isEverCompleted, isOnBoarding, readonly, spec?.elements],
  );

  return (
    <FormProvider reset={reset} {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Card>{elements}</Card>
        {!readonly && (
          <div className={classNames(styles.buttonContainer, {[styles.blankBackground]: blankBackground})}>
            <Button block type={'submit'} className={styles.submit} isLoading={isLoading} disabled={isSubmitDisabled}>
              {`Save`}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export {TrackingDailyForm};
