import type {FC, FormEvent} from 'react';
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import styles from './payment-information.module.scss';
import {Button, ErrorListItem, ErrorListItemColor, ErrorListItemFontSize, Input, InputType} from '../../../components';
import {Controller, useForm} from 'react-hook-form';
import classNames from 'classnames';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import {useInjection} from '../../../ioc';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {AlertCircleIcon} from '../../../assets/icons';
import type {StripeCardElementChangeEvent} from '@stripe/stripe-js';

interface IPromocodeFrom {
  promocode: string | null;
}

interface IService {
  account: IAccountService;
}

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const PaymentInformationForm: FC<IService> = ({account}) => {
  const stripe = useStripe();
  const elements = useElements();
  const {handleSubmit, reset, control} = useForm<IPromocodeFrom>();
  const [isCardFilled, setIsCardFilled] = useState<boolean>(false);

  useEffect(() => {
    reset({
      promocode: account.promocode,
    });
  }, [account.promocode, reset]);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return false;
    }

    const result = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          name: `${account.info?.firstName} ${account.info?.lastName}`,
        },
      },
    });

    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
    } else {
      // The payment has been processed!
      if (result.paymentMethod.id) {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        // setPaymentMethodId(result.paymentMethod.id);
        await account.setPaymentInformation({
          paymentMethod: result.paymentMethod.id,
        });
        await account.completeStripeSetup();
      }
    }
  };

  const onValidate = React.useCallback(
    async (data: IPromocodeFrom): Promise<void> => {
      await account.validateCoupon(data.promocode!);
    },
    [account],
  );

  const onCardElementChange = (event: StripeCardElementChangeEvent) => {
    setIsCardFilled(event?.complete);
  };

  if (!stripe || !elements) {
    // Stripe.js hasn't yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    return null;
  }

  return (
    <>
      <form autoComplete="off" className={styles.promocodeWrapper} onSubmit={handleSubmit(onValidate)}>
        <div className={styles.promocodeField}>
          <Controller
            name="promocode"
            control={control}
            render={({value, onChange, name}) => (
              <Input
                name={name}
                value={value}
                className={classNames('input-simple', styles.input)}
                onValueChanged={onChange}
                placeholder={'Promocode'}
                label={'Promocode'}
                isValid={!account.errors.get('promocode')}
                type={InputType.LabelInInput}
              />
            )}
          />
          <Button
            className={classNames(styles.promocodeValidate, {[styles.promocodeValidatePending]: account.isSaving})}
            type="submit">
            Validate
          </Button>
        </div>
        {!!account.errors.get('promocode') ? (
          <ErrorListItem
            message={account.errors.get('promocode')!}
            icon={<AlertCircleIcon />}
            color={ErrorListItemColor.Red}
            fontSize={ErrorListItemFontSize.Small}
          />
        ) : (
          <></>
        )}
        {!!account.paymentInfo?.promocodeId && 'Your coupon was successfully applied'}
      </form>
      <form autoComplete="off" className={styles.form} onSubmit={onSubmit}>
        <div className={styles.cardElement}>
          <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onCardElementChange} />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={classNames(styles.submit, {[styles.submitPending]: account.isSaving})}
            type={'submit'}
            isLoading={account.isSaving}
            disabled={!isCardFilled || !stripe}>
            Done
          </Button>
        </div>
      </form>
    </>
  );
};

const PaymentInformationFormObserver = observer(PaymentInformationForm);
const PaymentInformationFormInjected: FC = () => (
  <PaymentInformationFormObserver account={useInjection(ACCOUNT_SERVICE)} />
);

export {PaymentInformationFormInjected as PaymentInformationForm};
