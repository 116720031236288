import type {ISurvey} from './survey.model';
import {SurveyModel} from './survey.model';
import type {SurveyGetResponseDto} from '../repository';
import {SurveySpecModelFactory} from './survey-spec.model.factory';
import {SurveyResponsesFactory} from './survey-responses.factory';
import {shiftTimezone} from '../../helpers';

class SurveyModelFactory {
  static fromGetDto(getDto: SurveyGetResponseDto): SurveyModel {
    const survey: ISurvey = {
      surveyId: getDto.specDto?.surveyId,
      spec: getDto.specDto ? SurveySpecModelFactory.fromSpecDto(getDto.specDto) : null,
      lastCompletedDate: getDto.lastResponseInfo?.date ? shiftTimezone(new Date(getDto.lastResponseInfo.date)) : null,
      responses:
        getDto.responsesDto && getDto.responsesDto[0]
          ? getDto.responsesDto[0].surveyResponse.elementResponses.map((elResp) =>
              SurveyResponsesFactory.fromDtoResponses(elResp),
            )
          : [],
      formResponses: null,
      lastModifiedDate:
        getDto.responsesDto && getDto.responsesDto[0]
          ? shiftTimezone(new Date(getDto.responsesDto[0].surveyResponse.lastModifiedDate))
          : null,
    };

    const date = getDto.responsesDto[0]?.date ? shiftTimezone(new Date(getDto.responsesDto[0].date)) : null;

    return {...new SurveyModel(-1, [survey]), date};
  }
}

export {SurveyModelFactory};
