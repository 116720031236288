import {useState, useCallback} from 'react';

const useVisible = (status: boolean = false) => {
  const [visible, setVisible] = useState<boolean>(status);

  const trigger = useCallback(() => {
    setVisible((prevState) => !prevState);
  }, []);

  return [visible, trigger] as const;
};

export {useVisible};
