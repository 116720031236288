import type {FC} from 'react';
import React, {useCallback, useMemo} from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleChevronLeftIcon,
  DoubleChevronRightIcon,
} from '../../../../assets/icons';
import styles from './datepicker-wide-header.module.scss';
import {DateWrapper} from '../../../date';

interface IDatepickerHeaderProps {
  currentDate: Date;
  disableDecreaseMonth?: boolean;
  disableIncreaseMonth?: boolean;
  disableDecreaseYear?: boolean;
  disableIncreaseYear?: boolean;
  decreaseMonth: () => void;
  onMonthDecrease: () => void;
  increaseMonth: () => void;
  onMonthIncrease: () => void;
  decreaseYear: () => void;
  increaseYear: () => void;
}

const DatepickerHeaderWide: FC<IDatepickerHeaderProps> = (props) => {
  const currentDateTitle = useMemo(
    () => <DateWrapper date={props.currentDate} format={'MMMM yyyy'} />,
    [props.currentDate],
  );

  const monthDecrease = useCallback(() => {
    props.decreaseMonth();
    props.onMonthDecrease();
  }, [props]);

  const monthIncrease = useCallback(() => {
    props.increaseMonth();
    props.onMonthIncrease();
  }, [props]);

  return (
    <div className={styles.root} data-testid="date-picker-wide-header">
      <div className={styles.calendarArrowsContainer}>
        <button
          type="button"
          className={styles.calendarArrows}
          onClick={props.decreaseYear}
          disabled={props.disableDecreaseYear}>
          <DoubleChevronLeftIcon />
        </button>
        <button
          type="button"
          data-testid={'monthDecrease'}
          className={styles.calendarArrows}
          onClick={monthDecrease}
          disabled={props.disableDecreaseMonth}>
          <ChevronLeftIcon className={styles.calendarArrowsLeft} />
        </button>
      </div>

      <span className={styles.title}>{currentDateTitle}</span>

      <div className={styles.calendarArrowsContainer}>
        <button
          type="button"
          data-testid={'monthIncrease'}
          className={styles.calendarArrows}
          onClick={monthIncrease}
          disabled={props.disableIncreaseMonth}>
          <ChevronRightIcon className={styles.calendarArrowsRight} />
        </button>
        <button
          type="button"
          className={styles.calendarArrows}
          onClick={props.increaseYear}
          disabled={props.disableIncreaseYear}>
          <DoubleChevronRightIcon />
        </button>
      </div>
    </div>
  );
};

export {DatepickerHeaderWide};
