import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {IWssService, SocketData} from '../../utils/wss';
import {WSS_SERVICE} from '../../utils/wss';
import type {IPotData} from '../components/pot/pot.types';
import type {
  IBootcampLessonsResponseDto,
  IEventMatrixResponseDto,
  IPopUpDataSocketDto,
  IStreakResponseDto,
} from '../dto';
import {getSegmentAndSubSegmentIndex, vinePathsTemplate} from '../helper';
import type {
  IBranch,
  ICourseEvent,
  IEvent,
  IEventMatrix,
  ITrellisPopUpInformation,
  IVinePath,
} from '../model/trellis.model';
import {EEventType} from '../model/trellis.model';
import type {ITrellisService} from './trellis.service.interface';

export const NUMBER_OF_SEGMENTS = 8;
export const NUMBER_OF_SUBSEGMENTS = 2;
export const MAX_BOOTCAMP_LESSONS = 6;

@injectable()
class TrellisService implements ITrellisService {
  _completedBootcampLessons: ICourseEvent[] = [];
  _matrix: IEventMatrix = {} as IEventMatrix;
  _vineData: IVinePath[] = [];
  _canShowPopUp: boolean = false;
  _popUpData: ITrellisPopUpInformation[] = [];
  _potsFlowersData: IPotData = {} as IPotData;
  _startDate?: Date;

  constructor(
    @inject(HTTP_SERVICE) private readonly http: IHttpService,
    @inject(WSS_SERVICE) private readonly _wss: IWssService,
  ) {
    makeAutoObservable(this);
  }

  get canShowPopUp(): boolean {
    return this._canShowPopUp;
  }

  set canShowPopUp(value: boolean) {
    this._canShowPopUp = value;
  }

  get popUpData(): ITrellisPopUpInformation[] {
    return this._popUpData;
  }

  get startDate(): Date | undefined {
    return this._startDate;
  }

  get vineData(): IVinePath[] {
    return this._vineData;
  }

  get potsFlowersData(): IPotData {
    return this._potsFlowersData;
  }

  get completedBootcampLessonsCount(): number {
    let counter = 0;

    this._completedBootcampLessons.forEach((lesson) => {
      lesson.skills?.forEach((skill) => {
        skill.lessons?.forEach((course) => {
          if (course.completionDate) {
            counter++;
          }
        });
      });
    });

    return counter;
  }

  private static isPopUpSocketData(data: SocketData): data is IPopUpDataSocketDto[] {
    return (data as any)[0]?.type !== undefined && (data as any)[0]?.rewardText !== undefined;
  }

  processVineData = () => {
    const deepCopyVine = (vine: IVinePath) => JSON.parse(JSON.stringify(vine));

    this._vineData = [];

    if (this._startDate) {
      for (const [index] of this._matrix?.categories.entries() || []) {
        const vinePathIndex = index % vinePathsTemplate.length;
        const vineTemplate = vinePathsTemplate[vinePathIndex];
        this._vineData.push(deepCopyVine(vineTemplate)); // Deep copy
      }

      this.distributeMatrixAcrossVines();
    }
  };

  fetchMatrixData = async () => {
    try {
      this._matrix = (await this.http.get<IEventMatrixResponseDto>('/PatientTrellis/getpatienttrellis')).data;

      this._startDate = this._matrix.startDate;
    } catch (err) {
      console.error(err);
    }
  };

  loadPotsFlowerData = async () => {
    const {data: dto} = await this.http.get<IStreakResponseDto>('/PatientTrellis/getstreaks');

    this._potsFlowersData = dto;
  };

  distributeMatrixAcrossVines() {
    const vinePaths = this._vineData;
    const matrix = this._matrix;

    if (!matrix?.startDate) {
      return [];
    }

    const updateBranchWithEvent = (branch: IBranch, event: IEvent, eventType: EEventType) => {
      branch.leafType = eventType;
      branch.eventTriggers.push({...event, type: eventType});
    };

    const getLastFilledBranchIndexesForSubSegment = (
      vinePaths: IVinePath[],
      targetSegmentIndex: number,
      targetSubSegmentIndex: number,
    ) => {
      let vineIndex = 0;
      let branchIndex = 0;

      // Check if we are at the last segment and subsegment
      const isLastSegment = targetSegmentIndex === NUMBER_OF_SEGMENTS - 1;
      const isLastSubSegment = targetSubSegmentIndex === NUMBER_OF_SUBSEGMENTS - 1;

      for (const [index, vinePath] of vinePaths.entries()) {
        const subSegment = vinePath.segments[targetSegmentIndex].subSegments[targetSubSegmentIndex];
        let subSegmentLength = 0;

        if (isLastSegment && isLastSubSegment) {
          subSegmentLength = subSegment.length - 2;
        } else {
          subSegmentLength = subSegment.length - 1;
        }

        if (branchIndex <= subSegmentLength) {
          vineIndex = index;
          branchIndex = subSegmentLength;
        }
      }

      return {vineIndex, branchIndex};
    };

    const checkAllVinesForFreeSpot = (
      vinePaths: IVinePath[],
      segmentIndex: number,
      subSegmentIndex: number,
      branchIndex: number,
    ) =>
      vinePaths.some((vine) => {
        const subSegment = vine.segments[segmentIndex].subSegments[subSegmentIndex];

        return branchIndex < subSegment.length && subSegment[branchIndex].leafType === 'greenLeaf';
      });

    const addEventToLastBranch = (eventType: EEventType, event: IEvent, segmentIndex = 0, subSegmentIndex = 0) => {
      const {vineIndex, branchIndex} = getLastFilledBranchIndexesForSubSegment(
        vinePaths,
        segmentIndex,
        subSegmentIndex,
      );

      const lastBranch = vinePaths[vineIndex].segments[segmentIndex].subSegments[subSegmentIndex][branchIndex];
      lastBranch.eventTriggers.push({...event, type: eventType});
    };

    const addHigherPriorityEvents = (categories: IEvent[], startDate: Date, eventType?: EEventType) => {
      for (const [index, category] of categories.entries()) {
        if (category.completionDate) {
          // Find the appropriate vine, segment, and subsegment
          const vine = vinePaths[index];
          const {segmentIndex, subSegmentIndex} = getSegmentAndSubSegmentIndex(
            startDate,
            new Date(category.completionDate),
          );
          const subSegment = vine?.segments[segmentIndex]?.subSegments[subSegmentIndex];

          // Find the first available branch
          const branch = subSegment?.find(
            (branch) => branch.leafType === 'greenLeaf' || branch.eventTriggers.length === 0,
          );

          // Update the branch with the event
          if (branch) {
            updateBranchWithEvent(
              branch,
              {
                name: category.name,
                type: EEventType.EndOfCategory, // Assuming this is the event type for category completion
                completionDate: category.completionDate,
              },
              EEventType.EndOfCategory,
            );
          }
        }
      }
    };

    const getMaxBranches = (vinePaths: IVinePath[], segmentIndex: number, subSegmentIndex: number) =>
      Math.max(...vinePaths.map((vine) => vine.segments[segmentIndex].subSegments[subSegmentIndex]?.length));

    const distributeEvents = (events: IEvent[][][], eventType: EEventType) => {
      // Iterate over each segment and its subsegments
      for (const [segmentIndex, segmentEvents] of events.entries()) {
        for (const [subSegmentIndex, subSegmentEvents] of segmentEvents.entries()) {
          // Determine the direction of filling (left-to-right or right-to-left)
          let forward = subSegmentIndex % 2 === 0;
          let vineIndex = forward ? 0 : vinePaths.length - 1;
          let branchIndex = 0;
          let allFreeSpotsFilled = false;
          const maxBranches = getMaxBranches(vinePaths, segmentIndex, subSegmentIndex);

          // Iterate over each event in the current subsegment
          for (const event of subSegmentEvents) {
            let placed = false;

            while (!placed) {
              const vine = vinePaths[vineIndex];
              const subSegment = vine.segments[segmentIndex].subSegments[subSegmentIndex];

              // Check if the current branch index is within the range of the current subsegment's branches
              if (branchIndex < subSegment.length) {
                const branch = subSegment[branchIndex];

                // Place the event if the spot is free (greenLeaf) or no event is triggered yet
                if (!allFreeSpotsFilled && branch.leafType === 'greenLeaf') {
                  updateBranchWithEvent(branch, event, eventType);
                  placed = true;

                  // Update the last added index for the current event type
                  // lastAddedIndex = {vineIndex, branchIndex};
                } else if (allFreeSpotsFilled) {
                  // Add the event to the last branch if all spots are filled
                  addEventToLastBranch(eventType, event, segmentIndex, subSegmentIndex);
                  placed = true;
                }
              } else if (allFreeSpotsFilled) {
                // Add the event to the last branch if all spots are filled
                addEventToLastBranch(eventType, event, segmentIndex, subSegmentIndex);
                placed = true;
              }

              // Update vineIndex based on the direction
              vineIndex += forward ? 1 : -1;

              // Change direction and update branchIndex if we reach the end or start of the vine array
              if (vineIndex >= vinePaths.length || vineIndex < 0) {
                vineIndex = forward ? vinePaths.length - 1 : 0;
                branchIndex++;
                forward = !forward;
              }

              // Check if all spots are filled and update the branchIndex accordingly
              if (branchIndex >= maxBranches) {
                // Check if all spots are filled only if they haven't been marked as filled yet
                if (!allFreeSpotsFilled) {
                  allFreeSpotsFilled = !checkAllVinesForFreeSpot(vinePaths, segmentIndex, subSegmentIndex, branchIndex);
                } else {
                  // If all spots are already filled, break out of the loop
                  break;
                }
              }
            }
          }
        }
      }
    };

    // Step 1: Fill before all
    addHigherPriorityEvents(matrix?.categories || [], matrix?.startDate, EEventType.EndOfCategory);
    // Step 2: Fill remaining spots
    distributeEvents(matrix?.flowers || [], EEventType.Flower);
    // Step 3: Fill remaining spots
    distributeEvents(matrix?.leafs || [], EEventType.Leaf);

    return vinePaths;
  }

  fetchBootcampLessonsData = async () => {
    this._completedBootcampLessons = (
      await this.http.get<IBootcampLessonsResponseDto[]>('/PatientTrellis/getcompletedbootcamplessons')
    ).data;
  };

  connectWss(): void {
    this._wss.subscribe('TrellisStreakCompleted', (data) => {
      if (TrellisService.isPopUpSocketData(data)) {
        let popUps: ITrellisPopUpInformation[] = [];

        for (const popUpData of data as IPopUpDataSocketDto[]) {
          popUps = [...popUps, popUpData];
        }

        this._popUpData = popUps;
      }
    });
  }
}

export {TrellisService};
