import type {MedicationsFrequenciesTypesEnum, MedicationsUnitsTypesEnum} from '../../model';

interface IMedicationsCourseDiscontinueCommonGetResponseDto {
  medicationCourseId: number;
  patientId: number;
  startDate: string;
  endDate: string;
  dose: number;
  unit: MedicationsUnitsTypesEnum;
  frequency: MedicationsFrequenciesTypesEnum;
  frequencyOther: string;
  discontinuedDate: string | null;
  reason: number;
  reasonOther: string;
  discontinuedBy: string;
  lastUpdated: string;
}

interface IMedicationsCourseDiscontinueDefaultMedicationGetResponseDto
  extends IMedicationsCourseDiscontinueCommonGetResponseDto {
  medicationId: number;
  medicationBrandName: string;
  medicationGenericName: string;
}

interface IMedicationsCourseDiscontinueCustomMedicationGetResponseDto
  extends IMedicationsCourseDiscontinueCommonGetResponseDto {
  customMedicationId: number;
  customMedicationName: string;
}

type IMedicationsCourseDiscontinueGetResponseDto =
  | IMedicationsCourseDiscontinueCustomMedicationGetResponseDto
  | IMedicationsCourseDiscontinueDefaultMedicationGetResponseDto;

const isMedicationsCourseDiscontinueCustomMedicationGetResponseDto = (
  dto: IMedicationsCourseDiscontinueGetResponseDto,
): dto is IMedicationsCourseDiscontinueCustomMedicationGetResponseDto =>
  'customMedicationId' in dto && dto.customMedicationId !== null;

export {isMedicationsCourseDiscontinueCustomMedicationGetResponseDto};
export type {IMedicationsCourseDiscontinueGetResponseDto};
