import React from 'react';
import {Card, CardContent} from '../../../../../components';
import styles from './no-data-message.module.scss';

const NoDataMessage: React.FC = ({children}): JSX.Element => (
  <Card borderLess className={styles.emptyListCard}>
    <CardContent className={styles.emptyListContentWrapper}>
      <div className={styles.emptyListContent}>
        <h4 className={styles.emptyListMessage}>{children}</h4>
      </div>
    </CardContent>
  </Card>
);

export {NoDataMessage};
