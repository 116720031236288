enum SurveySpecElementType {
  Text = 0,
  Select = 1,
  Checkbox = 2,
  Range = 3,
  Toggle = 4,
  Date = 5,
  Dropdown = 6,
  Numeric = 7,
  Combobox = 12,
  Section = 100,
  Hidden = 101,
  DynamicSection = 200,
  StaticSection = 300,
  Link = 301,
  LadderSection = 600,
  RadioButtonImage = 601,
  Comment = 700,
}

export {SurveySpecElementType};
