import type {FC} from 'react';
import React, {useCallback, useMemo, useState} from 'react';
import {
  TaskModalCard,
  TaskModalCardStatusEnum,
  TaskModalCardTypeEnum,
} from '../task-modal-card/task-modal-card.component';
import type {TaskModel} from '../../model';
import {DEFAULT_FORMAT} from '../../../helpers';
import {EducationCourseRateModal} from '../../../education';
import {format} from 'date-fns';

const TaskModal: FC<{
  selectedTask: TaskModel | null;
  onClose: () => void;
  onComplete: (begin?: boolean) => void;
  timeZone?: string;
  type?: TaskModalCardTypeEnum;
  lmsLink: string;
}> = ({selectedTask, onComplete, onClose, timeZone, lmsLink, type = TaskModalCardTypeEnum.Lesson}) => {
  const [isShowRate, setIsShowRate] = useState(false);
  const {status, statusText} = useMemo(() => {
    let statusText = 'In progress';
    let status = '';

    if (selectedTask) {
      const dateDiffInDays: number = selectedTask.getDiffInDays(timeZone);
      const isDateExpired: boolean = dateDiffInDays > 0;

      if (!selectedTask.complete && isDateExpired) {
        statusText = `Incomplete · ${dateDiffInDays} days ago`;
        status = TaskModalCardStatusEnum.Danger;
      } else if (selectedTask.complete) {
        statusText = 'Completed';
        status = TaskModalCardStatusEnum.Success;
      }
    }

    return {statusText, status};
  }, [selectedTask, timeZone]);

  const complete = useCallback(
    (begin?: boolean) => {
      if (!!selectedTask?.courseLink && !begin) {
        setIsShowRate(true);
      } else {
        onComplete(begin);
      }
    },
    [onComplete, selectedTask?.courseLink],
  );

  const closeRate = useCallback(() => {
    setIsShowRate(false);
    onComplete();
  }, [onComplete]);

  return (
    selectedTask && (
      <>
        <TaskModalCard
          onClose={onClose}
          onComplete={complete}
          date={format(selectedTask.dateStart, DEFAULT_FORMAT)}
          beginDate={selectedTask.beginDate}
          title={selectedTask.title}
          statusText={statusText}
          status={status}
          behavior={selectedTask.category}
          complete={selectedTask.complete}
          courseLink={selectedTask.courseLink}
          lmsLink={lmsLink}
          type={type}
        />
        {isShowRate && <EducationCourseRateModal onClose={closeRate} task={selectedTask} />}
      </>
    )
  );
};

export {TaskModal};
