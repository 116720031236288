import React, {forwardRef} from 'react';
import {InputNumber, MaskedInput} from '../../../components';
import {InputNumberTypes} from '../../../components/input-number/input-number.component';
import styles from './survey-numeric-element.module.scss';
import {generateMask} from './helpers';

const INPUT_MAX_LENGTH_DEFAULT = 3;
const INPUT_MAX_VALUE_DEFAULT = 999;
const INPUT_MIN_VALUE_DEFAULT = 0;

const SurveyNumericElement = forwardRef<
  HTMLInputElement,
  {
    name: string;
    value: string;
    onChange: (value: string) => void;
    isInvalid?: boolean;
    minValue?: number | null;
    maxValue?: number | null;
    errorMessage?: string;
    ariaLabelTitle?: string;
    ariaLabelError?: string;
  }
>((props, ref) =>
  props.minValue && props.maxValue ? (
    <MaskedInput
      value={props.value}
      className={styles.maskedInput}
      isValid={!props.isInvalid}
      onValueChanged={props.onChange}
      maskPlaceholder={''}
      mask={generateMask(props.minValue, props.maxValue)}
      errorMessage={props.errorMessage}
    />
  ) : (
    <InputNumber
      ref={ref}
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      maxLength={props.maxValue ? String(props.maxValue).length : INPUT_MAX_LENGTH_DEFAULT}
      className={styles.numeric}
      isValid={!props.isInvalid}
      numberInputType={InputNumberTypes.Integer}
      minValue={props.minValue || INPUT_MIN_VALUE_DEFAULT}
      maxValue={props.maxValue || INPUT_MAX_VALUE_DEFAULT}
      errorMessage={props.errorMessage}
      ariaLabelTitle={props.ariaLabelTitle}
      ariaLabelError={props.ariaLabelError}
    />
  ),
);

export {SurveyNumericElement};
