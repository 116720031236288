import type {RichText} from '../../types';

class NotificationModel {
  constructor(
    public notificationId = 0,
    public userAccountId = 0,
    public title: RichText = [],
    public text: RichText = [],
    public isRead = false,
    public notificationDate = new Date(),
    public createdDate = new Date(),
    public lastModifiedDate = new Date(),
    public userName = '',
    public userRoleId = 0,
    public flag: number | null = null,
  ) {}
}

export {NotificationModel};
