interface IDiseaseSeverityScreenerItem {
  question: string;
  value: string;
}

class DiseaseSeverityScreenerModel {
  constructor(
    public items: IDiseaseSeverityScreenerItem[] = [],
    public patientId: number = -1,
    public id: number = -1,
    public lastUpdated: Date | null = null,
    public name: string = '',
  ) {}
}

export {DiseaseSeverityScreenerModel};
export type {IDiseaseSeverityScreenerItem};
