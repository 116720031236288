import {isRadioButton} from '../../helpers';
import type {IWorkProductivityItem} from './work-productivity.model';
import {WorkProductivityModel} from './work-productivity.model';
import type {IWorkProductivityFormDto} from '../form';

class WorkProductivityModelFactory {
  static fromFormDto(dto: IWorkProductivityFormDto): WorkProductivityModel {
    const responses: Array<IWorkProductivityItem> = Object.keys(dto).reduce((acc, fieldName) => {
      const dtoElement = dto[fieldName];

      const workProductivityItem: IWorkProductivityItem = {
        question: fieldName,
        value: String(dtoElement),
      };

      if (isRadioButton(dtoElement)) {
        workProductivityItem.value = dtoElement?.id;
      }

      return [...acc, workProductivityItem];
    }, [] as Array<IWorkProductivityItem>);

    return new WorkProductivityModel(responses);
  }
}

export {WorkProductivityModelFactory};
