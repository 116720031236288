import type {AxisDataType, ExtendedAxisDataType} from '../components/line-chart';
import {HealthMonitorGraphTypes} from '../model';
import {
  HealthMonitorFatigueLabels,
  HealthMonitorIbsSss,
  HealthMonitorMyLifeIsGoingWellLabels,
  HealthMonitorPhysicalHealthLabels,
} from '../components/line-chart/enums';
import {SpecialtyType} from '../../enums';

type ChartAxisDataType = {
  [HealthMonitorGraphTypes.LabResultsCRP]: {Y: AxisDataType};
  [HealthMonitorGraphTypes.LabResultsFecalCalprotectin]: {Y: AxisDataType};
  [HealthMonitorGraphTypes.LabResultsVitaminD]: {Y: AxisDataType};
  [HealthMonitorGraphTypes.LabResultsHemoglobin]: {Y: AxisDataType};
  [HealthMonitorGraphTypes.LabResultsAlbumin]: {Y: AxisDataType};
  [HealthMonitorGraphTypes.Weight]: {Y: AxisDataType};
  [HealthMonitorGraphTypes.WwbMyLifeIsGoingWell]: {Y: ExtendedAxisDataType<HealthMonitorMyLifeIsGoingWellLabels>};
  [HealthMonitorGraphTypes.WwbIbdPain]: {Y: AxisDataType};
  [HealthMonitorGraphTypes.GwbFatigue]: {Y: AxisDataType | ExtendedAxisDataType<HealthMonitorFatigueLabels>};
  [HealthMonitorGraphTypes.GwbPhysicalHealth]: {
    Y: AxisDataType | ExtendedAxisDataType<HealthMonitorPhysicalHealthLabels>;
  };
  [HealthMonitorGraphTypes.GwbSleepQuality]: {Y: ExtendedAxisDataType<HealthMonitorPhysicalHealthLabels>};
  [HealthMonitorGraphTypes.AbdominalBloating]: {Y: AxisDataType};
  [HealthMonitorGraphTypes.AbdominalPain]: {Y: AxisDataType};
  [HealthMonitorGraphTypes.AbdominalDiscomfort]: {Y: AxisDataType};
  [HealthMonitorGraphTypes.IbsSss]: {Y: ExtendedAxisDataType<HealthMonitorIbsSss>};
};

const chartAxisData = (patientSpecialty: string): ChartAxisDataType => ({
  [HealthMonitorGraphTypes.LabResultsCRP]: {
    Y: {
      min: 0,
      max: 50,
      range: 5,
      tick: 10,
    },
  },
  [HealthMonitorGraphTypes.LabResultsFecalCalprotectin]: {
    Y: {
      min: 0,
      max: 1000,
      range: 100,
      tick: 10,
    },
  },
  [HealthMonitorGraphTypes.LabResultsVitaminD]: {
    Y: {
      min: 0,
      max: 100,
      range: 10,
      tick: 10,
    },
  },
  [HealthMonitorGraphTypes.LabResultsHemoglobin]: {
    Y: {
      min: 0,
      max: 20,
      range: 1,
      tick: 20,
    },
  },
  [HealthMonitorGraphTypes.LabResultsAlbumin]: {
    Y: {
      min: 0,
      max: 10,
      range: 1,
      tick: 10,
    },
  },
  [HealthMonitorGraphTypes.Weight]: {
    Y: {
      range: 1,
      tick: 8,
    },
  },
  [HealthMonitorGraphTypes.WwbMyLifeIsGoingWell]: {
    Y: {
      min: 0,
      max: 1,
      range: 0.1,
      tick: 9,
      labels: [
        {
          displayName: 'Worst possible life',
          value: HealthMonitorMyLifeIsGoingWellLabels.StronglyDisagree,
        },
        {
          displayName: 'Best possible life',
          value: HealthMonitorMyLifeIsGoingWellLabels.StronglyAgree,
        },
      ],
    },
  },
  [HealthMonitorGraphTypes.WwbIbdPain]: {
    Y: {
      min: 0,
      max: 10,
      range: 1,
      tick: 10,
    },
  },
  [HealthMonitorGraphTypes.GwbFatigue]:
    patientSpecialty === SpecialtyType.IBS
      ? {
          Y: {
            min: 0,
            max: 10,
            range: 2.5,
            tick: 4,
            labels: [
              {
                displayName: 'None',
                value: HealthMonitorFatigueLabels.None,
              },
              {
                displayName: 'Mild',
                value: HealthMonitorFatigueLabels.Mild * 10,
              },
              {
                displayName: 'Moderate',
                value: HealthMonitorFatigueLabels.Moderate * 10,
              },
              {
                displayName: 'Severe',
                value: HealthMonitorFatigueLabels.Severe * 10,
              },
              {
                displayName: 'Very Severe',
                value: HealthMonitorFatigueLabels.VerySevere * 10,
              },
            ],
          },
        }
      : {
          Y: {
            min: 0,
            max: 1,
            range: 0.25,
            tick: 4,
            labels: [
              {
                displayName: 'None',
                value: HealthMonitorFatigueLabels.None,
              },
              {
                displayName: 'Mild',
                value: HealthMonitorFatigueLabels.Mild,
              },
              {
                displayName: 'Moderate',
                value: HealthMonitorFatigueLabels.Moderate,
              },
              {
                displayName: 'Severe',
                value: HealthMonitorFatigueLabels.Severe,
              },
              {
                displayName: 'Very Severe',
                value: HealthMonitorFatigueLabels.VerySevere,
              },
            ],
          },
        },
  [HealthMonitorGraphTypes.GwbPhysicalHealth]:
    patientSpecialty === SpecialtyType.IBS
      ? {
          Y: {
            min: 0,
            max: 10,
            range: 2.5,
            tick: 4,
            labels: [
              {
                displayName: 'Poor',
                value: HealthMonitorPhysicalHealthLabels.Poor,
              },
              {
                displayName: 'Fair',
                value: HealthMonitorPhysicalHealthLabels.Fair * 10,
              },
              {
                displayName: 'Good',
                value: HealthMonitorPhysicalHealthLabels.Good * 10,
              },
              {
                displayName: 'Very Good',
                value: HealthMonitorPhysicalHealthLabels.VeryGood * 10,
              },
              {
                displayName: 'Excellent',
                value: HealthMonitorPhysicalHealthLabels.Excellent * 10,
              },
            ],
          },
        }
      : {
          Y: {
            min: 0,
            max: 1,
            range: 0.25,
            tick: 4,
            labels: [
              {
                displayName: 'Poor',
                value: HealthMonitorPhysicalHealthLabels.Poor,
              },
              {
                displayName: 'Fair',
                value: HealthMonitorPhysicalHealthLabels.Fair,
              },
              {
                displayName: 'Good',
                value: HealthMonitorPhysicalHealthLabels.Good,
              },
              {
                displayName: 'Very Good',
                value: HealthMonitorPhysicalHealthLabels.VeryGood,
              },
              {
                displayName: 'Excellent',
                value: HealthMonitorPhysicalHealthLabels.Excellent,
              },
            ],
          },
        },
  [HealthMonitorGraphTypes.GwbSleepQuality]: {
    Y: {
      min: 0,
      max: 1,
      range: 0.25,
      tick: 4,
      labels: [
        {
          displayName: 'Poor',
          value: HealthMonitorPhysicalHealthLabels.Poor,
        },
        {
          displayName: 'Fair',
          value: HealthMonitorPhysicalHealthLabels.Fair,
        },
        {
          displayName: 'Good',
          value: HealthMonitorPhysicalHealthLabels.Good,
        },
        {
          displayName: 'Very Good',
          value: HealthMonitorPhysicalHealthLabels.VeryGood,
        },
        {
          displayName: 'Excellent',
          value: HealthMonitorPhysicalHealthLabels.Excellent,
        },
      ],
    },
  },
  [HealthMonitorGraphTypes.AbdominalBloating]: {
    Y: {
      min: 0,
      max: 10,
      range: 1,
      tick: 10,
    },
  },
  [HealthMonitorGraphTypes.AbdominalPain]: {
    Y: {
      min: 0,
      max: 10,
      range: 1,
      tick: 10,
    },
  },
  [HealthMonitorGraphTypes.AbdominalDiscomfort]: {
    Y: {
      min: 0,
      max: 10,
      range: 1,
      tick: 10,
    },
  },
  [HealthMonitorGraphTypes.IbsSss]: {
    Y: {
      min: 2,
      max: 4,
      range: 1,
      tick: 2,
      labels: [
        {
          displayName: 'Mild',
          value: HealthMonitorIbsSss.Mild,
        },
        {
          displayName: 'Moderate',
          value: HealthMonitorIbsSss.Moderate,
        },
        {
          displayName: 'Severe',
          value: HealthMonitorIbsSss.Severe,
        },
      ],
    },
  },
});

export {chartAxisData};
export type {AxisDataType, ExtendedAxisDataType, ChartAxisDataType};
