import type {INotificationsRepository, NotificationsQuery} from './notifications.repository.interface';
import type {NotificationModel} from '../model';
import {NotificationsModelFabric} from '../model';
import type {INotificationsGetResponseDto} from './dto';
import {NotificationsPutRequestDtoFabric} from './dto';
import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';

@injectable()
class NotificationsRepository implements INotificationsRepository {
  constructor(@inject(HTTP_SERVICE) private readonly http: IHttpService) {}

  find(query: Partial<NotificationsQuery> | undefined): Promise<NotificationModel | null> {
    throw new Error('Not implemented');
  }

  async list(query: Partial<NotificationsQuery> | undefined): Promise<NotificationModel[]> {
    let model: NotificationModel[] = [];

    if (query?.userAccountId) {
      const params = {
        userAccountId: query.userAccountId,
        pageNumber: query.pageNumber,
        pageSize: query.pageSize,
        endDate: query.endDate,
        isRead: query.isRead,
        startDate: query.startDate,
        flags: query.flags,
        isAscending: false,
        orderBy: 'flag',
      };

      await this.http
        .post<INotificationsGetResponseDto>(`/Notification/search`, params)
        .then((response) => {
          model = response?.data?.records.map((dataElement) =>
            NotificationsModelFabric.fromGetResponseDtoRecord(dataElement),
          );
        })
        .catch(console.error);
    }

    return model;
  }

  remove(domain: NotificationModel): Promise<void> {
    throw new Error('Not implemented');
  }

  async save(domain: NotificationModel): Promise<void> {
    const model = NotificationsPutRequestDtoFabric.fromModel(domain);
    await this.http.put(`/Notification/updateStatuses`, model).catch(console.error);
  }

  async updateManyStatuses(notificationIds: number[], isRead: boolean) {
    await this.http.put(`/Notification/updateStatuses`, {notificationIds, isRead}).catch(console.error);
  }
}

export {NotificationsRepository};
