import type {RichText} from '../../types';
import type {MessageAttachmentModel} from './message-attachment.model';
import {MessageStatus} from './message-status.enum';
import {MessageDeliveryStatus} from './message-delivery-status.enum';

class MessageModel {
  constructor(
    public id = 0,
    public userId = 0,
    public threadId = 0,
    public text: RichText = [],
    public createdAt = new Date(),
    public attachments: MessageAttachmentModel[] = [],
    public isAutoReply = false,
    public readStatus: MessageStatus = MessageStatus.Sent,
    public deliveryStatus: MessageDeliveryStatus = MessageDeliveryStatus.Sent,
    public fakeId?: string,
    public firstName?: string,
    public providerType?: string,
  ) {}
}

export {MessageModel};
