import type {FC} from 'react';
import {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import styles from './expiring-clock.module.scss';
import type {AnimationItem} from 'lottie-web';
import lottie from 'lottie-web';
import clockData from '../expiring-warning-modal/clock.json';
import type {UseTabInactivityStatusCallback} from '../../utils/inactivity-status';
import {useTabInactivityStatus} from '../../utils/inactivity-status';

const ANIMATION_DElAY = 2 * 1000;
const ANIMATION_LENGTH = ANIMATION_DElAY + 1000 * 60;
const ANIMATION_EVENT_NAME = 'complete';

const Clock = forwardRef<HTMLDivElement>((_, ref) => <div ref={ref} className={styles.clock} />);

const useExpiringClock = ({
  name,
  onAnimationCompleted,
  waitForStart = false,
  shadowPlay = false,
}: {
  name: string;
  onAnimationCompleted?: () => void;
  waitForStart?: boolean;
  shadowPlay?: boolean;
}) => {
  const clock = useRef<HTMLDivElement>(null);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const clearTimer = useCallback(() => {
    if (timerId !== undefined) {
      clearTimeout(timerId);
      setTimerId(undefined);
    }
  }, [timerId]);
  const completeAnimation = useCallback(() => {
    clearTimer();

    if (onAnimationCompleted) {
      onAnimationCompleted();
    }
  }, [clearTimer, onAnimationCompleted]);

  useEffect(() => {
    if (!timerId) {
      setTimerId(() => setTimeout(completeAnimation, ANIMATION_LENGTH));
    }

    return () => clearTimer();
  }, [clearTimer, completeAnimation, timerId]);

  const [clockAnimation, setClockAnimation] = useState<AnimationItem>();
  const [isAnimationShadowPlayed, setIsAnimationShadowPlayed] = useState(false);

  const commitShadowAnimationPlay = useCallback(() => setIsAnimationShadowPlayed(true), []);

  const inactivityStatusCallback: UseTabInactivityStatusCallback = useCallback(
    (inactivityTimeMilliseconds) => {
      if (shadowPlay && clockAnimation && !isAnimationShadowPlayed) {
        clockAnimation.goToAndPlay(inactivityTimeMilliseconds + ANIMATION_DElAY, false, name);

        commitShadowAnimationPlay();
      }
    },
    [clockAnimation, commitShadowAnimationPlay, isAnimationShadowPlayed, name, shadowPlay],
  );

  useTabInactivityStatus(inactivityStatusCallback);

  const startAnimation = useCallback(() => {
    const clockElement = clock.current;

    if (!!clockElement && !clockAnimation) {
      setClockAnimation(() =>
        lottie.loadAnimation({
          container: clockElement,
          renderer: 'svg',
          loop: false,
          autoplay: true,
          animationData: clockData,
          name,
        }),
      );
    }
  }, [clockAnimation, name]);

  const stopAnimation = useCallback(() => {
    if (clockAnimation) {
      clockAnimation.destroy();

      setIsAnimationShadowPlayed(false);
      setClockAnimation(undefined);
    }
  }, [clockAnimation]);

  useEffect(() => {
    if (!waitForStart) {
      startAnimation();
    }

    clockAnimation?.addEventListener(ANIMATION_EVENT_NAME, completeAnimation);

    return () => {
      clockAnimation?.removeEventListener(ANIMATION_EVENT_NAME, completeAnimation);

      stopAnimation();
    };
  }, [stopAnimation, startAnimation, waitForStart, clockAnimation, completeAnimation]);

  const ClockWrapper: FC = useCallback(() => <Clock ref={clock} />, []);

  return {Clock: ClockWrapper, stopAnimation, startAnimation};
};

export {useExpiringClock};
