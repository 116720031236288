import {useCallback, useEffect, useState} from 'react';
import {useInjection} from '../../ioc';
import type {ITrellisPopUpInformation} from '../../trellis-visualization/model';
import type {ITrellisService} from '../../trellis-visualization/service';
import {TRELLIS_SERVICE} from '../../trellis-visualization/service';
import {processUnhandledAchievementMessages} from '../../utils/wss/utils';

export const useAchievements = () => {
  const trellisService: ITrellisService = useInjection(TRELLIS_SERVICE);
  const tourCompleted = localStorage.getItem('tourState') === 'true';

  const [achievements, setAchievements] = useState<ITrellisPopUpInformation[]>([]);
  const [currentAchievementIndex, setCurrentAchievementIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    const nextIndex = currentAchievementIndex + 1;

    if (nextIndex >= achievements.length) {
      setIsModalOpen(false);
    }
    setCurrentAchievementIndex(nextIndex);
  }, [currentAchievementIndex, achievements.length]);

  useEffect(() => {
    if (trellisService.popUpData.length > 0) {
      setAchievements((prevState) => [...prevState, ...trellisService.popUpData]);
      setIsModalOpen(true);
    } else if (tourCompleted || trellisService.canShowPopUp) {
      const unhandledMessages = processUnhandledAchievementMessages();
      if (unhandledMessages.length) {
        setAchievements((prevState) => [...prevState, ...unhandledMessages]);
        setIsModalOpen(true);
      }
    }
  }, [tourCompleted, trellisService.canShowPopUp, trellisService.popUpData]);

  useEffect(() => {
    const callTrellisData = async () => {
      await trellisService.fetchMatrixData();
      trellisService.processVineData();
      await trellisService.loadPotsFlowerData();
      await trellisService.fetchBootcampLessonsData();
    };

    if (isModalOpen) {
      callTrellisData();
    }
  }, [isModalOpen, trellisService]);

  return {achievements, currentAchievementIndex, isModalOpen, handleModalClose};
};
