import type {FC} from 'react';
import {useMemo} from 'react';
import {getPossibleValue, SurveyFormElement} from '../form-element';
import styles from './survey-section.module.scss';
import {NOT_PROVIDED_TEXT} from '../../../helpers';
import type {ISurveyElementResponse, ISurveySpecElement} from '../../index';
import {SurveySpecElementType} from '../../index';
import {SurveyWrapper} from '../wrapper';

interface ISurveySectionProps {
  element: ISurveySpecElement;
  title?: string | undefined;
  readonly: boolean;
  elementValues: Array<ISurveyElementResponse>;
  isOnBoarding?: boolean;
  isEverCompleted?: boolean;
  flattenedElements: Array<ISurveySpecElement>;
}

const SurveySection: FC<ISurveySectionProps> = (props) => {
  const isRootSection = useMemo(() => props.element.metadata?.sectionType === 1, [props.element.metadata?.sectionType]);
  const hasValuesInSubElements = useMemo(
    () =>
      !!props.element.subElements
        ? props.element.subElements
            .map((subElement) => getPossibleValue(props.elementValues, subElement.name))
            .some((value) => !!value)
        : false,
    [props.element.subElements, props.elementValues],
  );

  const elements = useMemo(
    () =>
      !!props.element.subElements
        ? props.element.subElements.map((subElement) => (
            <SurveyFormElement
              key={subElement.elementId}
              title={props.element.text || props.title}
              readonly={props.readonly}
              elementValues={props.elementValues}
              element={subElement}
              isOnBoarding={props.isOnBoarding}
              hasNoWrapper={!isRootSection}
              isEverCompleted={props.isEverCompleted}
              flattenedElements={props.flattenedElements}
              isChildren={true}
            />
          ))
        : [],
    [
      isRootSection,
      props.element.subElements,
      props.element.text,
      props.elementValues,
      props.flattenedElements,
      props.isEverCompleted,
      props.isOnBoarding,
      props.readonly,
      props.title,
    ],
  );

  const hasAnySubmittedValues = useMemo(
    () =>
      props.elementValues.some(
        (elementValue) =>
          !!elementValue.value &&
          props.flattenedElements.some(
            (element) => elementValue.name === element.name && element.elementType !== SurveySpecElementType.Hidden,
          ),
      ),
    [props.elementValues, props.flattenedElements],
  );

  const isHealthcareTouchpointsExtraElementsFilled = useMemo(
    () =>
      props.elementValues
        .filter((elementValue) =>
          props.flattenedElements.some((element) => element.name === elementValue.name && !!element.metadata?.formType),
        )
        .some((element) => !!element.value),
    [props.elementValues, props.flattenedElements],
  );

  return (
    <>
      {(!props.readonly && hasValuesInSubElements) || !props.element.metadata?.formType ? (
        isRootSection ? (
          <div className={styles.root} key={props.element.elementId}>
            {elements}
          </div>
        ) : (
          <SurveyWrapper className={styles.root} title={props.element.text}>
            {elements}
          </SurveyWrapper>
        )
      ) : props.readonly &&
        props.isEverCompleted &&
        (!hasAnySubmittedValues || isHealthcareTouchpointsExtraElementsFilled) ? (
        <SurveyWrapper className={styles.root} title={props.element.text}>
          <div className={styles.notProvided}>{hasAnySubmittedValues ? NOT_PROVIDED_TEXT : ''}</div>
        </SurveyWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export {SurveySection};
