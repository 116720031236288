import type {FC, MouseEventHandler} from 'react';
import React, {useCallback, useMemo, useState} from 'react';
import styles from './button-dropdown.module.scss';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import {ArrowDownIcon, ArrowUpIcon, CrossIcon} from '../../assets/icons';
import {Visible} from '../visible';

interface IButtonDropdownBaseProps {
  label: string;
  value?: string;
  onReset?: () => void;
  shadow?: boolean;
}

interface IButtonDropdownProps extends IButtonDropdownBaseProps {
  onClose: () => void;
  onToggle: () => void;
  onOpen: () => void;
  isOpen: boolean;
}

const useSwitcher = (defaultValue = false) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const toggle = useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {isOpen, toggle, open, close};
};

enum ButtonDropdownDataTestIdEnum {
  CHILDREN_WRAPPER = 'children-wrapper',
  ACTIVATOR = 'activator',
  BUTTON = 'button',
}

const createButtonHandler =
  (callBack: Function): MouseEventHandler<HTMLButtonElement> =>
  (e) => {
    e.stopPropagation();
    callBack();
  };

const ButtonDropdown: FC<IButtonDropdownProps> = ({
  isOpen,
  onToggle,
  onClose,
  onOpen,
  label,
  value,
  onReset,
  shadow = false,
  children,
}) => {
  const isSelected = useMemo(() => !!value, [value]);

  const displayValue = useMemo(() => (isSelected ? value : label), [isSelected, label, value]);

  const button = useMemo(
    () =>
      isSelected && onReset ? (
        <button
          className={styles.icon}
          onClick={createButtonHandler(onReset)}
          data-testid={ButtonDropdownDataTestIdEnum.BUTTON}>
          <CrossIcon />
        </button>
      ) : isOpen ? (
        <button
          className={styles.icon}
          onClick={createButtonHandler(onClose)}
          data-testid={ButtonDropdownDataTestIdEnum.BUTTON}>
          <ArrowUpIcon />
        </button>
      ) : (
        <button
          className={styles.icon}
          onClick={createButtonHandler(onOpen)}
          data-testid={ButtonDropdownDataTestIdEnum.BUTTON}>
          <ArrowDownIcon />
        </button>
      ),
    [isOpen, isSelected, onClose, onOpen, onReset],
  );

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div className={styles.root}>
        <div
          data-testid={ButtonDropdownDataTestIdEnum.ACTIVATOR}
          className={classNames(styles.content, styles.unselectable, {[styles.contentActive]: isOpen})}
          onClick={onToggle}>
          <div className={styles.value}>{displayValue}</div>
          <div className={classNames({[styles.iconSelected]: isSelected})}>{button}</div>
        </div>
        <Visible
          dataTestId={ButtonDropdownDataTestIdEnum.CHILDREN_WRAPPER}
          className={classNames(styles.dropdown, {
            [styles.dropdownShadow]: shadow,
          })}
          on={isOpen}>
          {children}
        </Visible>
      </div>
    </OutsideClickHandler>
  );
};

const useButtonDropdown = () => {
  const switcher = useSwitcher();

  const ButtonDropdownWrapper: FC<IButtonDropdownBaseProps> = ({children, ...props}) => (
    <ButtonDropdown
      {...props}
      isOpen={switcher.isOpen}
      onOpen={switcher.open}
      onClose={switcher.close}
      onToggle={switcher.toggle}>
      {children}
    </ButtonDropdown>
  );

  return {
    ...switcher,
    ButtonDropdown: ButtonDropdownWrapper,
  };
};

export {ButtonDropdown, ButtonDropdownDataTestIdEnum, useButtonDropdown};
export type {IButtonDropdownBaseProps, IButtonDropdownProps};
