import {CrpExpression, LabTests, TuberculosisTypes, UnitsTypesEnum} from '../../enums';

class LabResultsFormDto {
  constructor(
    public labDate: Date = new Date(),
    public labTest: LabTests = LabTests.CRP,
    public expression: CrpExpression = CrpExpression.Blank,
    public value: number = 0,
    public valueType: TuberculosisTypes = TuberculosisTypes.Negative,
    public unitType: UnitsTypesEnum = UnitsTypesEnum.Blank,
  ) {}
}

export {LabResultsFormDto};
