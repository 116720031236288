import classNames from 'classnames';
import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useEffect, useState} from 'react';
import {Button} from '../../../../../components';
import {useInjection} from '../../../../../ioc';
import {levelMapping} from '../../../../helper/levelsConfig';
import type {ILevelBody} from '../../../../interfaces/goal.interface';
import {ELevelGoal} from '../../../../interfaces/goal.interface';
import type {IGoalService} from '../../../../service';
import {GOAL_SERVICE} from '../../../../service';
import goalStyles from '../goal-steps.module.scss';
import styles from './goal-step-4.module.scss';

interface IStep4Prop {
  onNext?: () => void;
  onSubmit?: () => void;
}

interface IServiceProps {
  goalService: IGoalService;
}

const GoalStep4: FC<IStep4Prop & IServiceProps> = ({goalService, onNext}) => {
  const [description, setDescription] = useState('');
  const [level, setLevel] = useState<ILevelBody>();
  const [totalScore, setTotalScore] = useState<number>();

  useEffect(() => {
    setLevel(levelMapping[goalService.level || ELevelGoal.UNKNOWN]);
    setTotalScore(goalService.totalScore);
    setDescription(goalService.goalWorksheet.goal || '_____________________');
  }, [goalService.goalWorksheet.goal, goalService.level, goalService.totalScore]);

  return (
    <div className={goalStyles.modalContent}>
      {totalScore ? (
        <>
          <div className={styles.header}>
            <span className={styles.flowerIcon}>
              <img src={level?.icon} alt="Goal flower" />
            </span>
            <h3>
              Goal: <u>{description}</u>. Total Score: <u>{totalScore || '__'}</u>
            </h3>
          </div>
          <div className={styles.body}>
            <p>
              You will be going through a series of lessons and learning skills that will help you reclaim your life.
              We’ll check in at the end of each to reflect on how these move you closer to achieving your goals.
            </p>
          </div>
        </>
      ) : (
        <div className={styles.header}>
          <h3>Your goal worksheet has not been set. Please try again.</h3>
        </div>
      )}

      <div className={styles.footer}>
        <Button className={classNames(goalStyles.button, styles.button)} onClick={onNext}>
          OK
        </Button>
      </div>
    </div>
  );
};

const GoalStep4Observer = observer(GoalStep4);
const GoalStep4Injected: FC<IStep4Prop> = (props) => (
  <GoalStep4Observer {...props} goalService={useInjection(GOAL_SERVICE)} />
);

export {GoalStep4Injected as GoalStep4, GoalStep4Observer};
