import {ContainerModule} from 'inversify';
import type {IStrengthsAssessmentRepository} from '../repository';
import {STRENGTHS_ASSESSMENT_REPOSITORY, StrengthsAssessmentRepository} from '../repository';
import type {IStrengthsAssessmentService} from '../service';
import {STRENGTHS_ASSESSMENT_SERVICE, StrengthsAssessmentService} from '../service';
import {HTTP_SERVICE} from '../../utils/http';

const StrengthsAssessmentModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IStrengthsAssessmentRepository>(STRENGTHS_ASSESSMENT_REPOSITORY)
    .to(StrengthsAssessmentRepository)
    .inSingletonScope();

  bind<IStrengthsAssessmentService>(STRENGTHS_ASSESSMENT_SERVICE).to(StrengthsAssessmentService).inSingletonScope();
});

export {StrengthsAssessmentModule};
