import type {FC} from 'react';
import {useCallback, useEffect, useMemo} from 'react';
import styles from './vaccination.module.scss';
import type {VaccinationFormDto} from '../form';
import {VaccinationForm} from '../form';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import type {IVaccinationService} from '../service';
import {VACCINATION_SERVICE} from '../service';
import {differenceInYears} from 'date-fns';
import {CollapseDescription, DateWrapper, PageLoader, PageTitle} from '../../components';
import type {IMedicationsService} from '../../medications/service';
import {MEDICATIONS_SERVICE} from '../../medications/service';
import {FULL_FORMAT} from '../../helpers';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';
import {SpecialtyType} from '../../enums';

interface IProps {
  vaccination: IVaccinationService;
  medications: IMedicationsService;
  account: IAccountService;
}

const VaccinationScreen: FC<IProps> = ({account, vaccination, medications}) => {
  useEffect(() => {
    Promise.all([vaccination.loadLastRecord(), medications.load()]);
  }, [vaccination, medications]);

  const age: number | undefined = useMemo(() => {
    if (!!account.info?.dateOfBirth) {
      return differenceInYears(new Date(), new Date(account.info.dateOfBirth));
    }
  }, [account.info?.dateOfBirth]);

  const onSubmit = useCallback(
    (data: VaccinationFormDto) => {
      vaccination.sendRecord(data);
    },
    [vaccination],
  );

  const defaultValues = vaccination.getRecordFormDto();

  const areHerpesZosterRelatedMedicationsActive = medications.courseArr.some(
    (medicationModel) =>
      medicationModel.medication.name.includes('Xeljanz') || medicationModel.medication.name.includes('Rinvoq'),
  );

  const isIbdSpecialty = account.info?.specialty?.name === SpecialtyType.IBD;

  return (
    <>
      <PageTitle className={styles.header}>Vaccinations</PageTitle>
      {!!vaccination.record?.lastModifiedDate && (
        <div className={styles.subHeader}>
          Last updated · <DateWrapper date={vaccination.record.lastModifiedDate} format={FULL_FORMAT} />
        </div>
      )}
      {vaccination.isLoading || medications.isLoading ? (
        <PageLoader />
      ) : (
        <>
          <CollapseDescription>
            <p>
              Your vaccination status can have an impact on your condition and medication management. It’s important
              that you and your healthcare team are aware of your vaccine history. This is especially important when
              starting or taking certain medications.
            </p>

            <p>Please answer the questions below, and provide updates when things change.</p>

            <p>
              Trellus has also created an{' '}
              <a
                href="https://content.trellushealth.net/ibd-vaccinations-safety-checklist/"
                target="_blank"
                rel="noreferrer noopener">
                IBD Vaccination Reference Guide
              </a>{' '}
              to help you stay up to date with the latest guidance on health maintenance and infection prevention.
            </p>
          </CollapseDescription>
          <VaccinationForm
            onSubmit={onSubmit}
            age={age}
            defaultValues={defaultValues}
            isNewForm={!vaccination.record?.lastModifiedDate}
            areHerpesZosterRelatedMedicationsActive={areHerpesZosterRelatedMedicationsActive}
            isIbdSpecialty={isIbdSpecialty}
          />
        </>
      )}
    </>
  );
};

const ObservableVaccinationScreen = observer(VaccinationScreen);
const InjectedVaccinationScreen: FC = () => (
  <ObservableVaccinationScreen
    vaccination={useInjection(VACCINATION_SERVICE)}
    medications={useInjection(MEDICATIONS_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
  />
);

export {InjectedVaccinationScreen as VaccinationScreen};
