import type {
  IEmotionalImpactScaleFormDto,
  IEmotionalImpactScaleService,
} from './emotional-impact-scale.service.interface';
import {inject, injectable} from 'inversify';
import type {IEmotionalImpactScaleRepository} from '../repository';
import {EMOTIONAL_IMPACT_SCALE_REPOSITORY} from '../repository';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import {makeAutoObservable} from 'mobx';
import type {EmotionalImpactScaleModel, EmotionalImpactScaleQuestionModel} from '../model';
import {EmotionalImpactScaleModelFactory} from '../model';
import {routes} from '../../routing';
import {getSurvey} from '../../strengths-assessment/helper';
import type {IAlertIndicatorService} from '../../alert-indicator';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';
import type {ITaskService} from '../../task/service';
import {TASK_SERVICE} from '../../task/service';
import {PredefinedTasks} from '../../enums/predefined-tasks';

@injectable()
class EmotionalImpactScaleService implements IEmotionalImpactScaleService {
  constructor(
    @inject(EMOTIONAL_IMPACT_SCALE_REPOSITORY) private readonly _repo: IEmotionalImpactScaleRepository,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(ALERT_INDICATOR_SERVICE) private readonly _alertIndicator: IAlertIndicatorService,
    @inject(TASK_SERVICE) private readonly _tasks: ITaskService,
  ) {
    makeAutoObservable(this);
  }

  private _assessments: EmotionalImpactScaleModel[] = [];

  get assessments(): EmotionalImpactScaleModel[] {
    return this._assessments;
  }

  private _selectedAssessment: EmotionalImpactScaleModel | null = null;

  get selectedAssessment(): EmotionalImpactScaleModel | null {
    return this._selectedAssessment;
  }

  private _lastFilledAssessment: EmotionalImpactScaleModel | null = null;

  get lastFilledAssessment(): EmotionalImpactScaleModel | null {
    return this._lastFilledAssessment;
  }

  private _listQuestions: EmotionalImpactScaleQuestionModel[] = [];

  get listQuestions(): EmotionalImpactScaleQuestionModel[] {
    return this._listQuestions;
  }

  private _isLoading: boolean = true;

  get isLoading(): boolean {
    return this._isLoading;
  }

  get isReadonly(): boolean {
    return !!this._lastFilledAssessment?.lastUpdated || this._isLoading;
  }

  async loadAssessments(): Promise<void> {
    this._isLoading = true;

    await this.loadQuestions();

    this._assessments = await this._repo.list();

    await this.fillLastFilledAssessment();

    this._isLoading = false;
  }

  async loadAssessmentById(id: number): Promise<void> {
    this._isLoading = true;

    await this.loadQuestions();

    const model = await this._repo.find({id}).catch(console.error);

    this._selectedAssessment = model ?? null;

    this._isLoading = false;
  }

  async loadQuestions(): Promise<void> {
    if (!this._listQuestions.length) {
      this._listQuestions = await this._repo.listQuestions();
    }
  }

  async createAssessment(formDto: IEmotionalImpactScaleFormDto, patientId: number): Promise<void> {
    const model = EmotionalImpactScaleModelFactory.fromFormDto(formDto, patientId);

    await this._repo
      .save(model)
      .then(() => {
        this._alertIndicator.load();

        if (this._routing.length === 1) {
          this.navigateToList();
        } else {
          this._routing.goBack();
        }
      })
      .catch(console.error);
  }

  async fillLastFilledAssessment(): Promise<void> {
    const first = getSurvey<EmotionalImpactScaleModel>(this._assessments);

    if (!this._tasks.activeTasks.length) {
      await this._tasks.load();
    }

    if (first?.lastUpdated) {
      this._lastFilledAssessment = this._tasks.isTaskComplete(PredefinedTasks.EmotionalImpactScale) ? first : null;
    } else {
      this._lastFilledAssessment = null;
    }
  }

  navigateToAssessment(id?: number): void {
    this._routing.push(`${routes.home}${routes.emotionalImpactScale}${id ? `?id=${id}` : ''}`);
  }

  navigateToList(): void {
    this._routing.push(`${routes.home}${routes.emotionalImpactScaleList}`);
  }
}

export {EmotionalImpactScaleService};
