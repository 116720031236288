import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IDiseaseSeverityScreenerRepository} from '../repository';
import {DISEASE_SEVERITY_SCREENER_REPOSITORY, DiseaseSeverityScreenerRepository} from '../repository';
import type {IDiseaseSeverityScreenerService} from '../service';
import {DISEASE_SEVERITY_SCREENER_SERVICE, DiseaseSeverityScreenerService} from '../service';

const DiseaseSeverityScreenerModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IDiseaseSeverityScreenerRepository>(DISEASE_SEVERITY_SCREENER_REPOSITORY)
    .to(DiseaseSeverityScreenerRepository)
    .inSingletonScope();

  bind<IDiseaseSeverityScreenerService>(DISEASE_SEVERITY_SCREENER_SERVICE)
    .to(DiseaseSeverityScreenerService)
    .inSingletonScope();
});

export {DiseaseSeverityScreenerModule};
