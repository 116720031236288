import type {IResilienceAssessmentItem} from './resilience-assessment.model';
import {ResilienceAssessmentModel} from './resilience-assessment.model';
import type {ResilienceAssessmentFormDto} from '../service';
import type {IResilienceAssessmentGetDto} from '../repository';

class ResilienceAssessmentModelFactory {
  static fromFormDto(dto: ResilienceAssessmentFormDto, patientId: number): ResilienceAssessmentModel {
    const responses: Array<IResilienceAssessmentItem> = Object.keys(dto).reduce(
      (acc, fieldName) => [
        ...acc,
        {
          question: fieldName,
          dynamicSectionIndex: null,
          value: dto[fieldName],
        },
      ],
      [] as Array<IResilienceAssessmentItem>,
    );

    return new ResilienceAssessmentModel(patientId, responses);
  }

  static fromGetDto(dto: IResilienceAssessmentGetDto): ResilienceAssessmentModel {
    return new ResilienceAssessmentModel(dto.patientId, dto.items, new Date(dto.lastUpdated));
  }
}

export {ResilienceAssessmentModelFactory};
