import {calculateFormula} from '../../../../helpers';
import type {ISurveyFormDto, ISurveySpecElement} from '../../../../index';

const IsRenderMedicationLinkHelper = (
  element: ISurveySpecElement,
  currentFormValues: ISurveyFormDto,
  flattenedElements: ISurveySpecElement[],
): boolean =>
  element.visibleIf && element.metadata?.sectionKey === 'MedicationPageLink'
    ? Boolean(calculateFormula(element.visibleIf, currentFormValues, flattenedElements))
    : false;

export {IsRenderMedicationLinkHelper};
