import {Sex} from '../enums';

const phoneFormatter = (number: string): string => {
  if (!number) {
    return '';
  }

  return number.replace(/(\d{3})(\d{3})(\d{4})/, '+1-$1-$2-$3');
};

const toDefaultPhoneFormat = (phone: string): string => phone.replace(/(\d{3})(\d{3})(\d{4})/, '+1-$1-$2-$3');

const sexFormatter = (sex: Sex): string => {
  switch (sex) {
    case Sex.Female:
      return 'Female';
    default:
      return 'Male';
  }
};

export {phoneFormatter, sexFormatter, toDefaultPhoneFormat};
