import type {FC, KeyboardEventHandler, SVGProps} from 'react';
import React, {useCallback, useMemo, useState} from 'react';
import styles from './nav-item.module.scss';
import classNames from 'classnames';
import {Link, useLocation} from 'react-router-dom';
import {ArrowIcon} from '../../../../../assets/icons';
import {NavItemType} from './nav-item-type.enum';

interface INavLink {
  icon: FC<SVGProps<SVGSVGElement>>;
  activeIcon: FC<SVGProps<SVGSVGElement>>;
  isVisible: boolean;
  type?: NavItemType;
  label?: string;
  className?: string;
  notificationBubble?: boolean;
  forceShowLabel?: boolean;
  route?: string;
  action?: () => void;
}

interface INavGroup {
  label?: string;
  subItems: INavLink[];
  forceShowLabel?: boolean;
  isVisible: boolean;
  type?: NavItemType;
  notificationBubble?: boolean;
}

type INavItem = INavGroup | INavLink;

const isINavLink = (nav: INavItem): nav is INavLink => 'route' in nav;

const NavLink: FC<INavLink> = ({type = NavItemType.InternalLink, ...props}) => {
  const {pathname} = useLocation();
  const isExact = useMemo(() => props.route && pathname.includes(props.route.toLowerCase()), [pathname, props.route]);
  const Icon = useMemo(() => (isExact ? props.activeIcon : props.icon), [props.activeIcon, props.icon, isExact]);

  const navItem = useMemo(() => {
    switch (type) {
      case NavItemType.InternalLink:
        return (
          props.route && (
            <Link
              className={classNames(props.className, styles.navItem, styles.navLink, styles.content, {
                [styles.navLinkActive]: isExact,
                [styles.forceShowLabel]: !!props.forceShowLabel,
              })}
              to={props.route}>
              <div className={styles.icon}>
                <Icon />
                {!!props.notificationBubble && <div className={styles.bubble} />}
              </div>
              <span className={styles.label}>{props.label}</span>
            </Link>
          )
        );
      case NavItemType.ExternalLink:
        return (
          props.route && (
            <a
              href={props.route}
              target="_blank"
              rel="noreferrer"
              className={classNames(props.className, styles.navItem, styles.navLink, styles.content, {
                [styles.forceShowLabel]: !!props.forceShowLabel,
              })}>
              <span className={styles.icon}>
                <Icon />
              </span>
              <span className={styles.label}>{props.label}</span>
            </a>
          )
        );
      case NavItemType.Button:
        return (
          <div
            className={classNames(props.className, styles.navItem, styles.navLink, styles.content, {
              [styles.forceShowLabel]: !!props.forceShowLabel,
            })}
            onClick={props.action}>
            <span className={styles.icon}>
              <Icon />
            </span>
            <span className={styles.label}>{props.label}</span>
          </div>
        );
      default:
        return <></>;
    }
  }, [
    Icon,
    isExact,
    props.action,
    props.className,
    props.forceShowLabel,
    props.label,
    props.notificationBubble,
    props.route,
    type,
  ]);

  return <>{navItem}</>;
};

const NavGroup: FC<INavGroup> = (props) => {
  const {pathname} = useLocation();
  const subItems = props.subItems.map((item) => item.route && item.route.toLowerCase());
  const subMenuIsOpen = useMemo(() => subItems.includes(pathname), [pathname, subItems]);

  const [open, setOpen] = useState(subMenuIsOpen);

  const toggleDropDown = useCallback(() => setOpen((isOpen) => !isOpen), []);

  const onEnterPress: KeyboardEventHandler = (e) => {
    if (e.code === 'Enter') {
      return toggleDropDown();
    }
  };

  return (
    <div className={classNames(styles.navItem)}>
      <div
        className={classNames(styles.content, {
          [styles.forceShowLabel]: !!props.forceShowLabel,
        })}
        tabIndex={0}
        role='menuitem'
        onKeyPress={onEnterPress}
        onClick={toggleDropDown}
        data-testid={'ToggleDropdown'}>
        <div className={styles.icon}>
          <ArrowIcon
            className={classNames({
              [styles.navGroupIconDown]: open,
            })}
            id={props.label}
          />
          {!!props.notificationBubble && <div className={styles.bubble} />}
        </div>
        <span className={styles.label}>{props.label}</span>
      </div>
      {open && (
        <div className={styles.subItemWrap}>
          {props.subItems
            ?.filter((item) => item.isVisible)
            .map((item) => (
              <NavLink forceShowLabel={props.forceShowLabel} {...item} key={item.label} className={styles.subNavItem} />
            ))}
        </div>
      )}
    </div>
  );
};

const NavItem: FC<INavItem> = (props) => (isINavLink(props) ? <NavLink {...props} /> : <NavGroup {...props} />);

export {NavItem};
export type {INavItem, INavLink, INavGroup};
