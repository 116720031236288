import type {FC} from 'react';
import React from 'react';
import styles from './app-version.module.scss';

interface IProps {
  appVersion: string;
}

const AppVersion: FC<IProps> = ({appVersion}) => <span className={styles.version}>Trellus Health V{appVersion}</span>;

export {AppVersion};
