import {addMinutes, differenceInCalendarDays} from 'date-fns';
import {shiftTimezone} from '../../../helpers';
import {getTimezoneOffset} from 'date-fns-tz';
import {DailyTaskActivityType} from '../../../enums';
import type {FileStorageModel} from '../../../file-storage/model';

export const MINUTE = 60 * 1000;

class TaskModel {
  constructor(
    public id = -1,
    public title = '',
    public category = '',
    public dateStart = new Date(),
    public dateEnd = new Date(),
    public doneDate: Date | null = null,
    public complete = false,
    public patientDailyTaskId = -1,
    public courseLink = '',
    public dailyTaskActivityType = DailyTaskActivityType.General,
    public beginDate: Date | null = null,
    public firstCompletionDate: Date | null = null,
    public isFavoriteLesson: boolean = false,
    public lessonDisplayTitle: string = '',
    public lessonThumbnailObjectFileId: null | string = null,
    public lessonThumbnailObjectFile: null | FileStorageModel = null,
    public assessmentThumbnailStaticPath: null | string = null,
    public behaviorType: null | number = null,
    public behaviorTypeName: null | string = null,
    public patientCourseId: null | number = null,
    public patientCourseName: null | string = null,
    public categoryId = -1,
    public courseThumbnailObjectFileId: null | string = null,
    public courseThumbnailObjectFile: null | FileStorageModel = null,
    public skillId: number | null = null,
    public isSkippable = false,
    public featuredContent = false,
    public skipDate: Date | null = null,
  ) {}

  getDiffInDays(timeZone?: string, dateNow: Date = shiftTimezone(new Date())): number {
    const timeZoneOffsetInMinutes = timeZone ? getTimezoneOffset(timeZone) / MINUTE : 0;
    const date = addMinutes(dateNow, timeZoneOffsetInMinutes);

    return differenceInCalendarDays(date, this.dateStart);
  }
}

export {TaskModel};
