import type {TaskModel} from '../../../task';
import type {IEducationCourseRateFormDto} from '../../components';
import {EducationCourseRatePostRequestDto} from './education-course-rate-post-request.dto';
import type {CourseTask} from '../../../patient-course/model/course-task';

class EducationCourseRatePostRequestDtoFactory {
  static fromTask(task: TaskModel | CourseTask, dto: IEducationCourseRateFormDto): EducationCourseRatePostRequestDto {
    return new EducationCourseRatePostRequestDto(task.id, dto.rate, dto.notes);
  }
}

export {EducationCourseRatePostRequestDtoFactory};
