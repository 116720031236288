import type {FC} from 'react';
import React from 'react';
import {AllowBaseModal} from './allow-base';
import type {MyExternalProvider} from '../../../../../../../account';

interface IOwnProps {
  onClose(): void;
  onSubmit(providerId: number): void;
  provider: MyExternalProvider;
}

export const DeleteExternalProviderModal: FC<IOwnProps> = ({provider, onSubmit, ...props}) => (
  <AllowBaseModal {...props} onSubmit={() => onSubmit(provider.providerId)}>
    <p>
      Are you sure you want to remove <b>{provider.providerName}</b> from your “My Providers” list?
    </p>
  </AllowBaseModal>
);
