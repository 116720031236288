import {formatDateApi, formatFullDateTime} from '../../../../helpers';
import type {ISurveyElementResponse, ISurveyFormDto, ISurveyFormValue, ISurveySpecElement} from '../../../index';
import {SurveySpecElementType} from '../../../index';

const isString = (response?: ISurveyFormValue | {displayName: any; value: any}): response is string =>
  !!response && typeof response === 'string';

const isComboboxValue = (
  response?: string | null | {displayName: any; value: any} | Date,
): response is {displayName: any; value: any} =>
  !!response && typeof response === 'object' && 'displayName' in response && 'value' in response;

const isArray = (
  response?: ISurveyFormValue,
): response is Array<string | null | {displayName: any; value: any} | Date> => !!response && Array.isArray(response);

const isDate = (response?: ISurveyFormValue | {displayName: any; value: any}): response is Date =>
  !!response && response instanceof Date;

const isDynamicSectionDate = (response?: ISurveyFormValue | {displayName: any; value: any}): boolean =>
  !!response && Object.values(response)[0] instanceof Date;

const isSelect = (response?: ISurveyFormValue): response is {id: number; text: string} =>
  !!response && typeof response === 'object' && 'id' in response && 'text' in response;

const isDropdownValue = (response?: ISurveyFormValue): response is {value: string} =>
  !!response && typeof response === 'object' && 'value' in response;

const getDropdownValue = (answer: ISurveyFormValue) =>
  isString(answer) ? answer : isDropdownValue(answer) ? answer.value : '';

const getElementResponses = (
  elements: Array<ISurveySpecElement>,
  answers: ISurveyFormDto | null,
): Array<ISurveyElementResponse> => {
  let result: Array<ISurveyElementResponse> = [];

  if (answers !== null) {
    elements.forEach((element) => {
      const answer = answers[element.name];
      switch (element.elementType) {
        case SurveySpecElementType.Section:
          result = result.concat(getElementResponses(element.subElements, answers));
          break;
        case SurveySpecElementType.DynamicSection:
          element.subElements.forEach((subElement) => {
            const subElementAnswers = answers[subElement.name];
            if (isArray(subElementAnswers)) {
              subElementAnswers.forEach((subElementAnswer, index) => {
                switch (subElement.elementType) {
                  case SurveySpecElementType.Dropdown:
                    if (!!subElementAnswer) {
                      const answerValue = getDropdownValue(subElementAnswer);

                      result.push({
                        elementId: subElement.elementId,
                        name: subElement.name,
                        value: answerValue,
                        elementOptionId: subElement.options.find((option) => option.value === answerValue)
                          ?.elementOptionId,
                        dynamicSectionIndex: String(index),
                      });
                    }
                    break;
                  case SurveySpecElementType.Date:
                    const dynamicValue =
                      subElementAnswer && isDynamicSectionDate(subElementAnswer)
                        ? Object.values(subElementAnswer)[0]
                        : subElementAnswer;

                    if (dynamicValue && isDate(dynamicValue)) {
                      result.push({
                        value: formatFullDateTime(dynamicValue),
                        name: subElement.name,
                        dynamicSectionIndex: String(index),
                        elementId: subElement.elementId,
                      });
                    }
                    break;
                  case SurveySpecElementType.Text:
                    result.push({
                      value: isString(subElementAnswer) ? subElementAnswer : '',
                      name: subElement.name,
                      dynamicSectionIndex: String(index),
                      elementId: subElement.elementId,
                    });
                    break;
                  default:
                    break;
                }
              });
            }
          });
          break;
        case SurveySpecElementType.Select:
        case SurveySpecElementType.LadderSection:
          if (isSelect(answer)) {
            result.push({
              elementId: element.elementId,
              name: element.name,
              value: answer.text,
              elementOptionId: answer.id,
            });
          }
          break;
        case SurveySpecElementType.RadioButtonImage:
          if (isSelect(answer)) {
            result.push({
              elementId: element.elementId,
              name: element.name,
              value: answer.text,
              elementOptionId: answer.id,
            });
          }
          break;
        case SurveySpecElementType.Text:
        case SurveySpecElementType.Toggle:
        case SurveySpecElementType.Numeric:
        case SurveySpecElementType.Hidden:
        case SurveySpecElementType.Checkbox:
          if (!!answer) {
            result.push({
              elementId: element.elementId,
              name: element.name,
              value: isString(answer) ? answer : String(answer),
            });
          }
          break;
        case SurveySpecElementType.Range:
          if (!!String(answer)) {
            result.push({
              elementId: element.elementId,
              name: element.name,
              value: isString(answer) ? answer : String(answer),
            });
          }
          break;
        case SurveySpecElementType.Date:
          if (isDate(answer)) {
            result.push({
              elementId: element.elementId,
              name: element.name,
              value: formatDateApi(answer),
            });
          }
          break;
        case SurveySpecElementType.Dropdown:
          if (!!answer) {
            const answerValue = getDropdownValue(answer);

            result.push({
              elementId: element.elementId,
              name: element.name,
              value: answerValue,
              elementOptionId: element.options?.find((option) => option.value === answerValue)?.elementOptionId,
            });
          }
          break;
        case SurveySpecElementType.Combobox:
          if (isArray(answer)) {
            answer.forEach((answerValue) => {
              if (isComboboxValue(answerValue)) {
                result.push({
                  elementId: element.elementId,
                  name: element.name,
                  value: answerValue.displayName,
                  elementOptionId: Number(answerValue.value),
                });
              }
            });
          }
          break;
        default:
          break;
      }
    });
  }

  return result;
};

export {isString, isDate, getElementResponses, isArray, isDynamicSectionDate, getDropdownValue};
