import styles from './course-iframe.module.scss';
import {useCallback, useEffect, useMemo} from 'react';

interface CourseIframeProps {
  src?: string;
  lmsLink?: string;
  onClose: () => void;
  onComplete: () => void;
}

// message listeners don't work but are here for the time we inevitabely have to re-write this the correct way.
const CourseIframe: React.FC<CourseIframeProps> = ({src, lmsLink, onClose, onComplete}) => {
  const lmsCourseSrc = useMemo(() => {
    const domain = /.*courses.*trellushealth\.net\//;
    const baseSrc = src ?? '';

    const domainMatch = baseSrc.match(domain);

    if (domainMatch) {
      return `/external-courses/${baseSrc.slice(domainMatch[0].length)}`;
    }

    return baseSrc;
  }, [src]);

  const addListener = useCallback(
    (closeIframeElementSelector: string, timeout: number) => {
      const iframe = document.querySelector('iframe');

      const interval = setInterval(() => {
        if (iframe && iframe.contentDocument) {
          const closeButton = iframe.contentDocument.querySelector(closeIframeElementSelector);

          if (closeButton) {
            closeButton.addEventListener('click', onClose);

            clearInterval(interval);
          }
        } else {
          clearInterval(interval);
        }
      }, timeout);
    },
    [onClose],
  );

  useEffect(() => {
    const listener = function (e: MessageEvent) {
      if (e) {
        if (e.origin === lmsLink && e.data === 'closeIframe') {
          onClose();
        }
        if (e.origin === lmsLink && e.data === 'completeIframe') {
          onComplete();
        }
      }
    };

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [onClose, onComplete, lmsLink]);

  useEffect(() => {
    if (src) {
      // for close
      addListener('.courseExit__button', 500);
      //for complete
      addListener('.courseExit__button', 100);
    }
  }, [src, addListener]);

  if (!src) {
    return null;
  }

  return (
    <div onClick={onClose} className={styles.wrapper}>
      <iframe title="lms" className={styles.iframe} src={lmsCourseSrc} />
      <div className={styles.closeHack}>Exit lesson</div>
    </div>
  );
};

export {CourseIframe};
