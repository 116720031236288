import type {IPreScreeningGetResponseDto} from '../repository/dto';
import type {IPreScreeningTask} from './pre-screening.model';
import {PreScreeningModel} from './pre-screening.model';

class PreScreeningModelFactory {
  static fromGetDto(dtos: IPreScreeningGetResponseDto[]): PreScreeningModel {
    const tasks: IPreScreeningTask[] = dtos.map((dto) => ({
      text: dto.subject,
      id: dto.type,
      isCompleted: dto.isDone,
    }));

    return new PreScreeningModel(tasks);
  }
}

export {PreScreeningModelFactory};
