import type {IVaccinationFormElement, IVaccinationOption} from './vaccination-form.component';
import {VaccinationRadioAnswers} from '../enums';

const safetyGuideLink =
  'As a reference for you, we\'ve created an <a rel="noopener noreferrer" target="_blank" href="https://content.trellushealth.net/ibd-vaccinations-safety-checklist/">IBD Vaccination Reference Guide</a>.';

const reportGuideLink =
  '<a class="vaccination-form-element-label_link__1eVqB" target="_blank" rel="noopener noreferrer" href="/home/reports">Reports</a>';

const vaccines: Array<IVaccinationOption> = [
  {
    value: 'pfizer',
    label: 'Pfizer',
  },
  {
    value: 'moderna',
    label: 'Moderna',
  },
  {
    value: 'johnson',
    label: 'Johnson & Johnson',
  },
  {
    value: 'astrazeneca',
    label: 'AstraZeneca',
  },
  {
    value: 'novovax',
    label: 'Novovax',
  },
];

const vaccinationOptions: Array<IVaccinationOption> = [
  {
    label: 'Yes',
    value: VaccinationRadioAnswers.Yes,
  },
  {
    label: 'No',
    value: VaccinationRadioAnswers.No,
  },
];

const vaccinationOptionsWithUnknown: Array<IVaccinationOption> = [
  ...vaccinationOptions,
  {
    label: 'Unknown',
    value: VaccinationRadioAnswers.Unknown,
  },
];

const getVaccinationFormElements = (isIbdSpecialty: boolean): Array<IVaccinationFormElement> => [
  {
    name: 'q_vaccination_record_varicella_as_child',
    label: 'Have you had Chicken Pox previously or have you received the Chicken Pox (Varicella) Vaccine? ',
    ariaLabelTitle: 'Have you had Chicken Pox previously or have you received the Chicken Pox (Varicella) Vaccine?',
    yesScreenMessage:
      'Even if you have had chicken pox or received the Varicella (chicken pox) vaccine, your immunity may have worn off. ' +
      `This is a live vaccine and should not be administered when taking certain IBD medications. ` +
      `It is recommended to speak with your GI or primary care provider before receiving the Varicella vaccine. ` +
      `<br />${safetyGuideLink} `,
    noScreenMessage:
      'Varicella (chicken pox) is a live vaccine and should not be administered when taking certain IBD medications. ' +
      'It is recommended to speak with your GI or primary care provider before receiving the Varicella vaccine. ' +
      `<br />${safetyGuideLink} `,
    options: vaccinationOptionsWithUnknown,
  },
  {
    name: 'q_vaccination_record_varicella_immunity',
    label: 'Do you have documented immunity to chickenpox (varicella) based on a blood test?',
    ariaLabelTitle: 'Do you have documented immunity to chickenpox (varicella) based on a blood test?',
    yesScreenMessage:
      'Please upload the lab results indicating your immune status. This would be a VZV IgG lab test result. ' +
      'To keep track of all your results you can upload  them  to your personal care vault.',
    noScreenMessage:
      'Please talk to your doctor or provider about checking your Varicella immunity (test name is VZV IgG). ' +
      'Varicella (chicken pox) is a live vaccine and should not be administered when taking certain IBD medications. ' +
      'It is recommended to speak with your GI or primary care provider before receiving the Varicella vaccine.' +
      `<br />${safetyGuideLink} `,
    options: vaccinationOptionsWithUnknown,
    isUploadLinkNeeded: true,
  },
  {
    name: 'q_vaccination_record_flu',
    label: 'Annual flu Shot',
    ariaLabelTitle: 'Annual flu Shot',
    options: vaccinationOptions,
    tipMessage:
      'Getting the flu shot is the best way to protect you from getting the flu. ' +
      'Remember to get the flu shot ( non-live) and not the nasal mist (live vaccine). ' +
      'If possible, members of your household should also opt for the flu shot and avoid the nasal mist. ' +
      'It is recommended to speak to your GI or primary care provider for more information. ' +
      `<br />${safetyGuideLink} `,
    dateSelection1Name: 'q_vaccination_record_flu_date',
  },
  {
    name: 'q_vaccination_record_menacwy',
    label: 'Meningococcal Groups A, C, W, Y (MenACWY)',
    ariaLabelTitle: 'Meningococcal Groups A, C, W, Y (MenACWY)',
    options: vaccinationOptionsWithUnknown,
    tipMessage:
      'Meningococcal A,C,W,Y (MenACWY) is typically given at 11-12 years, and 16 years of age. ' +
      'This vaccine is recommended for first-year college students who live in residential housing. ' +
      'It is recommended to speak to your GI or primary care provider for more information. ' +
      `<br />${safetyGuideLink} `,
  },
  {
    name: 'q_vaccination_record_menb',
    label: 'Meningococcal Group B (MenB)',
    ariaLabelTitle: 'Meningococcal Group B (MenB)',
    options: vaccinationOptionsWithUnknown,
    tipMessage:
      'Meningococcal group B (MenB) is an inactived (non-live) vaccine and may be recommended for those over 16. ' +
      'It is recommended to speak to your GI or primary care provider for more information. ' +
      `<br />${safetyGuideLink} `,
  },
  {
    name: 'q_vaccination_record_mmr',
    label: 'Measles, Mumps, or Rubella (MMR)',
    ariaLabelTitle: 'Measles, Mumps, or Rubella (MMR)',
    options: vaccinationOptionsWithUnknown,
    tipMessage:
      'Getting the MMR vaccine protects you against Measles, Mumps, and Rubella. ' +
      'This is especially important for women of child-bearing age. ' +
      'MMR vaccine is not recommended when taking certain IBD medications, so check with your GI or primary care provider. ' +
      `<br />${safetyGuideLink} `,
  },
  {
    name: 'q_vaccination_record_hepatitis_a',
    label: 'Hepatitis A',
    ariaLabelTitle: 'Hepatitis A',
    options: vaccinationOptionsWithUnknown,
    tipMessage:
      'The CDC recommends the HepA vaccine for special situations such as prior to traveling to specific countries. ' +
      'It is recommended to speak to your GI or primary care provider for more information. ' +
      `<br />${safetyGuideLink} `,
  },
  {
    name: 'q_vaccination_record_hepatitis_b',
    label: 'Hepatitis B',
    ariaLabelTitle: 'Hepatitis B',
    options: vaccinationOptionsWithUnknown,
    noScreenMessage:
      'You can protect yourself against Hepatitis B infections/reinfections by receiving the Hepatitis B vaccine, especially if you are on certain IBD medications. ' +
      'If you are starting or already receiving any Anti-TNF (Remicade® and infliximab biosimilars, Humira®, Cimzia®, Simponi®).  ' +
      'It is recommended to speak to your GI or primary care provider for more information. ' +
      `<br />${safetyGuideLink} `,
  },
  {
    name: 'q_vaccination_record_hepatitis_b_immunity',
    label: 'Do you have documented immunity to Hepatitis B based on a blood test?',
    ariaLabelTitle: 'Do you have documented immunity to Hepatitis B based on a blood test?',
    options: vaccinationOptionsWithUnknown,
    yesScreenMessage:
      'Please upload the lab results indicating vaccine immune status. This would be an Anti-HBs or HBs Ab lab test result. ' +
      `To keep track of all your results you can upload them to your Care Tools under ${reportGuideLink}`,
    noScreenMessage:
      'You can protect yourself against Hepatitis B infections/reinfections by receiving the Hepatitis B vaccine, especially if you are on certain IBD medications. ' +
      'If you are starting or already receiving any Anti-TNF (Remicade® and infliximab biosimilars, Humira®, Cimzia®, Simponi®). ' +
      'It is recommended to speak to your GI or primary care provider for more information. ' +
      `<br />${safetyGuideLink} `,
  },
  {
    name: 'q_vaccination_record_herpes',
    label: 'Herpes Zoster',
    ariaLabelTitle: 'Herpes Zoster',
    options: vaccinationOptionsWithUnknown,
    noScreenMessage:
      'Getting the Shingrix (Herpes Zoster) vaccine is the best way to protect yourself against getting shingles when you are on certain ' +
      'IBD therapies (Xeljanz®, Rinvoq®, Zeposia®) above age 50 or above the age of 18 and are or will be at increased risk of ' +
      'Herpes Zoster due to immunodeficiency or immunosuppression caused by known disease or therapy. ' +
      'Shingrix is an inactivated (non-live) vaccine. It is recommended to speak to your GI or primary care provider for more information. ' +
      `<br />${safetyGuideLink} `,
    dateSelection1Name: 'q_vaccination_record_herpes_date',
    dateSelection2Name: 'q_vaccination_record_herpes_second_date',
  },
  {
    name: 'q_vaccination_record_hpv',
    label: 'Human papillomavirus (HPV)',
    ariaLabelTitle: 'Human papillomavirus (HPV)',
    options: vaccinationOptionsWithUnknown,
    tipMessage:
      'The CDC recommends the HPV vaccine for everyone between the ages of 9 and 26 and some adults age 27 to 45 years. ' +
      'It is recommended to speak to your GI or primary care provider for more information.' +
      `<br />${safetyGuideLink} `,
    noScreenMessage:
      'Getting the HPV vaccine can protect you against getting the human papilloma virus. ' +
      'it is recommended to speak to your GI or primary care provider for more information. ' +
      `<br />${safetyGuideLink} `,
    dateSelection1Name: 'q_vaccination_record_hpv_date',
  },
  {
    name: 'q_vaccination_record_ppsv23',
    label: 'Pneumococcal polysaccharide (PPSV23)',
    ariaLabelTitle: 'Pneumococcal polysaccharide (PPSV23)',
    options: vaccinationOptionsWithUnknown,
    tipMessage:
      'PPSV23 (Pneumovax® 23) is a routine vaccination for those with chronic medical conditions, like IBD, that can protect you against pneumonia. ' +
      'It is recommended to speak to your GI or primary care provider for more information. ' +
      `<br />${safetyGuideLink} `,
    noScreenMessage:
      'Protect yourself from Pneumonia. Please talk to your doctor or provider about whether you should get the Pneumovax® 23 vaccine. ' +
      'Note: If you receive the Pneumovax® 23 before your Prevnar 13® or Prevnar 15® vaccine, the earliest you should receive Prevnar 13® or Prevnar® 15® vaccine is one year later. ' +
      'Penumovax 23® is not recommened if you have received Prevnar 20®. ' +
      'It is recommended to speak to your GI or primary care provider for more information. ' +
      `<br />${safetyGuideLink} `,
  },
  {
    name: 'q_vaccination_record_pcv13',
    label: isIbdSpecialty ? 'Pneumococcal conjugate (PCV13/15/20)' : 'Pneumococcal conjugate (PCV13)',
    ariaLabelTitle: isIbdSpecialty ? 'Pneumococcal conjugate (PCV13/15/20)' : 'Pneumococcal conjugate (PCV13)',
    options: vaccinationOptionsWithUnknown,
    tipMessage:
      'PCV13 (Prevnar 13®) and PCV15 (Prevnar 15®) and PCV20 (Prevnar 20®)is a routine vaccination for those with chronic medical conditions, ' +
      'like IBD, that can protect you against pneumonia. PCV15 and PCV20 are approved for those 18 years and older. ' +
      'It is recommended you speak to your GI or primary care doctors for more information.' +
      `<br />${safetyGuideLink} `,
    noScreenMessage:
      'Protect yourself from Pneumonia. Please speak to your doctor or provider about whether you are a good candidate for the Prevnar 13® (PCV13) Prevnar 15® (PCV15)or Prevnar 20® (PCV20) vaccine. ' +
      'PCV15 and PCV20 are only approved for those 18 years and older. ' +
      'Note: If you receive the Prevnar 13® or Prevnar 15® before your Pneumovax® 23 vaccine, the earliest you should receive ' +
      'Pneumovax® 23 is 8 weeks later. It is recommended that if you receive Prevnar20®,  ' +
      'Pneumovax®23 vaccine will not need to be adminstered. It is recommended to speak to your GI or primary care provider for more information on the Prevnar vaccines. ' +
      `<br />${safetyGuideLink} `,
  },
  {
    name: 'q_vaccination_record_covid19',
    label: 'SARS-CoV-2 (COVID-19)',
    ariaLabelTitle: 'SARS-CoV-2 (COVID-19)',
    options: vaccinationOptions,
    tipMessage:
      'Getting the COVID-19 vaccination can protect you from severe symptoms of COVID. ' +
      'It is recommended you speak to your GI or primary care doctors for more information. ' +
      `<br />${safetyGuideLink} `,
    noScreenMessage:
      'It is recommended to speak to your GI or primary care provider for more information on the COVID-19 vaccine.' +
      `<br />${safetyGuideLink} `,
    covidVaccineName: 'q_vaccination_record_covid19_vaccine',
    dateSelection1Name: 'q_vaccination_record_covid19_date',
  },
  {
    name: 'q_vaccination_record_rsv',
    label: 'RSV Vaccine',
    ariaLabelTitle: 'RSV Vaccine',
    options: vaccinationOptions,
    tipMessage:
      'The FDA and CDC have approved RSV vaccines for individuals ages 60 or older, infants and toddlers, ' +
      'and to pregnant women between weeks 32 to 36 to protect both the parent and fetus. ' +
      `<br />${safetyGuideLink} `,
  },
];

export {getVaccinationFormElements, vaccines};
