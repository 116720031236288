import type {FC} from 'react';
import styles from './lab-results-form-wrapper.module.scss';

const LabResultsFormWrapper: FC<{label: string}> = ({label, children}) => (
  <div className={styles.root}>
    <div className={styles.label}>{label}</div>
    {children}
  </div>
);

export {LabResultsFormWrapper};
