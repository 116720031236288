import type {EncounterDetailsModel, EncounterModel} from '../models';
import type {EncounterFilter, EncounterFilterQuery, IEncounterService} from './encounter.service.interface';
import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {IOption} from '../../types';
import type {IEncounterRepository} from '../repository';
import {ENCOUNTER_REPOSITORY, EncounterRepositoryQuerySort} from '../repository';
import type {TrellusTeamMember} from '../../account';

const ENCOUNTER_AUTHOR_RESET_ID = 0;

@injectable()
class EncounterService implements IEncounterService {
  private _patientId: number = -1;
  private _isLoading: boolean = true;

  constructor(@inject(ENCOUNTER_REPOSITORY) private readonly _repo: IEncounterRepository) {
    makeAutoObservable(this);
  }

  private _isLoadingEncounterDetails: boolean = false;

  get isLoadingEncounterDetails(): boolean {
    return this._isLoadingEncounterDetails;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _selectedEncounterDetails: EncounterDetailsModel | null = null;

  get selectedEncounterDetails(): EncounterDetailsModel | null {
    return this._selectedEncounterDetails;
  }

  private _encounterFilter: EncounterFilterQuery = {updatedLast: null, authorId: null};

  get encounterFilter(): EncounterFilter {
    const filter: EncounterFilter = {updatedLast: this._encounterFilter.updatedLast, authorName: null};

    if (this._encounterFilter.authorId !== null) {
      const author = this._encounterAuthorOptions.find((i) => i.value === this._encounterFilter.authorId);

      filter.authorName = author?.displayName ?? null;
    }

    return filter;
  }

  private _encounterAuthorOptions: IOption[] = [];

  get encounterAuthorOptions(): readonly IOption[] {
    return this._encounterAuthorOptions;
  }

  private _encounters: EncounterModel[] = [];

  get encounters(): readonly EncounterModel[] {
    return this._encounters;
  }

  async filterEncounters(query: Partial<EncounterFilterQuery>): Promise<void> {
    if (query.authorId !== undefined) {
      if (query.authorId === ENCOUNTER_AUTHOR_RESET_ID) {
        this._encounterFilter.authorId = null;
      } else {
        this._encounterFilter.authorId = query.authorId;
      }
    }

    if (query.updatedLast !== undefined) {
      this._encounterFilter.updatedLast = query.updatedLast;
    }

    return this.load();
  }

  clearFilters(): void {
    this._encounterFilter.authorId = null;
    this._encounterFilter.updatedLast = null;
  }

  async load(): Promise<void> {
    this._isLoading = true;

    return this._repo
      .list({
        patientId: this._patientId,
        authorId: this._encounterFilter.authorId ?? undefined,
        updatedLast: this._encounterFilter.updatedLast ?? undefined,
        offset: 1,
        limit: 1000,
        orderBy: 'encounterId',
        sort: EncounterRepositoryQuerySort.DESC,
      })
      .then((items) => {
        this._encounters = items;
      })
      .catch(console.error)
      .finally(() => {
        this._isLoading = false;
      });
  }

  async loadEncounterDetails(encounterId: number): Promise<void> {
    this._isLoadingEncounterDetails = true;
    const item = await this._repo.findDetails({encounterId}).catch(console.error);

    if (item) {
      this._selectedEncounterDetails = item;
    }

    this._isLoadingEncounterDetails = false;
  }

  areFiltered(): boolean {
    return this._encounterFilter.updatedLast !== null || this._encounterFilter.authorId !== null;
  }

  clearEncounterDetails(): void {
    this._selectedEncounterDetails = null;
  }

  init(list: TrellusTeamMember[], accountId: number): void {
    this._patientId = accountId;

    const listCreatedBy: IOption[] =
      list.map((item) => ({
        displayName: `${item.firstName} ${item.lastName}, ${item.providerTitle}`,
        value: item.providerId,
      })) || [];

    this._encounterAuthorOptions = [
      {value: ENCOUNTER_AUTHOR_RESET_ID, displayName: ''},
      ...listCreatedBy.filter((item) => item.value !== this._patientId),
      {value: this._patientId, displayName: 'Me'},
    ];
  }
}

export {EncounterService};
