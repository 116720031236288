import {observer} from 'mobx-react';
import type {FC, ReactElement} from 'react';
import {useCallback, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import type {IAccountService} from '../../../../../account';
import {ACCOUNT_SERVICE} from '../../../../../account';
import {ListItem} from '../../../../../components';
import {useInjection} from '../../../../../ioc';
import MultiStepModal from '../../../../../multi-step-modal/multi-step-modal.component';
import type {Course as CourseModel} from '../../../../../patient-course/model/course';
import type {CourseTask} from '../../../../../patient-course/model/course-task';
import {routes} from '../../../../../routing';
import type {TaskModel} from '../../../../../task';
import {CourseModal} from '../../../../../task/components/course-modal';
import {GoalStep1, GoalStep2, GoalStep3, GoalStep4} from '../../../../../task/components/goal-modal/goal-steps';
import {Complete} from '../../../../../task/components/task-actions/complete';
import type {IGoalService} from '../../../../../task/service';
import {GOAL_SERVICE} from '../../../../../task/service';
import styles from './course.module.scss';

interface ICourseProps {
  completeDate?: Date | null;
  course: CourseModel;
  isComplete?: boolean;
  lmsLink?: string;
  tasks: TaskModel[] | CourseTask[];
  timeZone: string;

  onBegin: (id: number) => void;
  onChangeFavoriteItem: (taskId: number, isFavorite: boolean) => void;
  onLessonComplete: (id: number, begin?: boolean, skip?: boolean) => void;
}

interface IServiceProps {
  goalService: IGoalService;
  accountService: IAccountService;
}

const Course: FC<ICourseProps & IServiceProps> = ({
  course,
  tasks,
  isComplete,
  timeZone,
  completeDate,
  goalService,
  accountService,
  ...props
}) => {
  const thriveCustomCourse =
    accountService.info?.readyForThrive && course.categoryId === 7 && course.behaviorTypeId === null;
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowGoalsModal, setIsShowGoalsModal] = useState(false);

  const changeGoalState = () => {
    course.hasGoal = true;
  };

  const goalSteps: ReactElement[] = thriveCustomCourse
    ? [
        <GoalStep3
          courseTypeId={course.behaviorTypeId}
          patientCourseId={course.patientCourseId}
          onSubmit={changeGoalState}
        />,
        <GoalStep4 />,
      ]
    : [
        <GoalStep1 title={course.behaviorTypeName} />,
        <GoalStep2 />,
        <GoalStep3
          courseTypeId={course.behaviorTypeId}
          patientCourseId={course.patientCourseId}
          onSubmit={changeGoalState}
          showPrevButton
        />,
        <GoalStep4 />,
      ];

  const modalHandler = useCallback(() => {
    setIsShowModal(!isShowModal);
  }, [isShowModal]);

  const modalGoalsHandler = useCallback(async () => {
    if (!isShowGoalsModal && course.behaviorTypeId) {
      await goalService.loadPossibleGoals(course.behaviorTypeId);
    } else {
      goalService.clearGoals();
    }

    setIsShowModal(!isShowModal);
    setIsShowGoalsModal(!isShowGoalsModal);
  }, [isShowModal, isShowGoalsModal, course, goalService]);

  const actions = useMemo(() => {
    const completeAction = completeDate ? <Complete completeDate={completeDate} timeZone={timeZone} /> : null;
    const changeSelectionAction = course?.canChangeSkills ? (
      <Link
        to={`${routes.home}${routes.thriveSkillSelection}?id=${course.patientCourseId}`}
        className={styles.changeLink}>
        Change Selection
      </Link>
    ) : null;

    return completeAction ?? changeSelectionAction;
  }, [completeDate, course?.canChangeSkills, course.patientCourseId, timeZone]);

  return (
    <>
      <ListItem
        title={course.behaviorTypeName}
        hoverable
        card
        isCourse
        isLesson
        onClick={modalHandler}
        isComplete={isComplete}
        actions={actions}
        image={course.courseImage ?? course.categoryDefaultThumbnailPath}
      />
      {isShowModal && (
        <CourseModal
          tasks={tasks}
          onClose={modalHandler}
          course={course}
          timeZone={timeZone}
          onClickGoalModal={modalGoalsHandler}
          {...props}
        />
      )}

      <MultiStepModal isOpen={isShowGoalsModal} onClose={modalGoalsHandler} steps={goalSteps} />
    </>
  );
};

const ObservableCourse = observer(Course);
const CourseInjected: FC<ICourseProps> = (props) => (
  <ObservableCourse
    {...props}
    goalService={useInjection(GOAL_SERVICE)}
    accountService={useInjection(ACCOUNT_SERVICE)}
  />
);

export {CourseInjected as Course, ObservableCourse};
export type {ICourseProps};
