import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import type {Tour} from 'react-shepherd';
import {ShepherdTour, ShepherdTourContext} from 'react-shepherd';
import {QuestionIcon} from '../assets/icons';
import {useInjection} from '../ioc';
import type {ITrellisService} from '../trellis-visualization/service';
import {TRELLIS_SERVICE} from '../trellis-visualization/service';
import {getTour} from './steps';
import './tourguide.scss';

interface IPropsTourGuide {
  trellisService: ITrellisService;
}

const TourGuide: FC<IPropsTourGuide> = ({trellisService}) => {
  const tourState = localStorage.getItem('tourState');
  const {pathname} = useLocation();

  // Shepherd Tour options
  const tourOptions: Tour.TourOptions = {
    defaultStepOptions: {
      modalOverlayOpeningRadius: 15,
      cancelIcon: {
        enabled: true,
      },
      scrollTo: {
        behavior: 'smooth',
        block: 'center',
      },
    },
    useModalOverlay: true,
  };

  // Shepherd Tour Button
  function Button() {
    const tour = useContext(ShepherdTourContext);

    useEffect(() => {
      if (tour && !tourState) {
        tour.on('complete', () => {
          localStorage.setItem('tourState', 'true');
          trellisService.canShowPopUp = true;
        });

        tour.on('cancel', () => {
          localStorage.setItem('tourState', 'true');
          trellisService.canShowPopUp = true;
        });

        tour.start();
      }
    }, [tour]);

    return (
      <button className="button restart-guidetour-button" onClick={tour?.start}>
        <QuestionIcon />
      </button>
    );
  }

  const tourSteps = getTour(pathname);

  if (tourSteps) {
    return (
      <ShepherdTour steps={getTour(pathname)} tourOptions={tourOptions}>
        <Button />
      </ShepherdTour>
    );
  } else {
    return null;
  }
};

const ObservableTourGuide = observer(TourGuide);
const TourGuideInjected: FC = () => <ObservableTourGuide trellisService={useInjection(TRELLIS_SERVICE)} />;

export {TourGuideInjected as TourGuide};
