import React from 'react';
import classnames from 'classnames';
import {Button, ButtonType} from '../button-legacy';
import {IconLegacy, IconTypeLegacy} from '../icon-legacy';
import './confirm-dialog.scss';

export type ConfirmDialogProps = {
  title: string;
  text: string;
  subText?: string;
  confirmLabel?: string;
  dismissLabel?: string;
  onConfirm: () => void;
  onDismiss: () => void;
};

const ConfirmDialog = (props: ConfirmDialogProps) => (
  <div className={classnames('confirm-dialog')}>
    <div className={'confirm-dialog__header'}>
      {props.title}
      <IconLegacy className={'confirm-dialog__close'} type={IconTypeLegacy.Close} onClick={props.onDismiss} />
    </div>
    <div className={'confirm-dialog__body'}>
      <span>{props.text}</span>
      <span className={'sub-text'}>{props.subText}</span>
    </div>
    <div className={'confirm-dialog__footer'}>
      {!!props.confirmLabel ? (
        <Button
          className={'confirm-dialog__button'}
          type={ButtonType.Small}
          label={props.confirmLabel}
          onClick={props.onConfirm}
        />
      ) : null}
      {!!props.dismissLabel ? (
        <Button
          className={'confirm-dialog__button'}
          type={ButtonType.Small}
          label={props.dismissLabel}
          onClick={props.onDismiss}
        />
      ) : null}
    </div>
  </div>
);

export {ConfirmDialog};
