import {withRouter} from 'react-router-dom';
import {useEffect} from 'react';

const ScrollToTop = withRouter(({history}) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return null;
});

export {ScrollToTop};
