import React from 'react';
import type {FC} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {PATTERN_USER_NAME_ERROR, REQUIRED_FIELD_ERROR} from '../../../../../../helpers';
import {Patterns} from '../../../../../../helpers/patterns';
import {Error, InputNumber} from '../../../../../../components';
import styles from '../two-factor-auth-form.module.scss';
import {InputNumberTypes} from '../../../../../../components/input-number/input-number.component';
import {MIN_CODE_LENGTH, MIN_CODE_LENGTH_ERROR_MESSAGE} from '../two-factor-auth-form.component';

interface IOwnProps {
  isLifetimeCodeTimer: string;
  getUserNumber: JSX.Element;
  serviceError: string | null;
}
export const SimpleVariant: FC<IOwnProps> = ({isLifetimeCodeTimer, getUserNumber, serviceError}) => {
  const {control, errors} = useFormContext();

  return (
    <Controller
      control={control}
      name={'code'}
      rules={{
        required: REQUIRED_FIELD_ERROR,
        minLength: {
          value: MIN_CODE_LENGTH,
          message: MIN_CODE_LENGTH_ERROR_MESSAGE,
        },
        pattern: {
          value: Patterns.UserName,
          message: PATTERN_USER_NAME_ERROR,
        },
      }}
      render={({name, onChange, value}) => (
        <>
          {getUserNumber}
          <InputNumber
            className={styles.input}
            name={name}
            onChange={onChange}
            value={value}
            placeholder={'Please enter your code here'}
            isValid={!errors.code?.message}
            maxLength={MIN_CODE_LENGTH}
            numberInputType={InputNumberTypes.Decimal}
            disabled={!isLifetimeCodeTimer}
          />
          {!!errors.code?.message && (
            <Error
              errorMessage={'The code you entered is incorrect. Please try again'}
              name={name}
              className={styles.cardError}
            />
          )}
          {!isLifetimeCodeTimer && (
            <Error errorMessage={'The timeout period to enter the code has expired'} className={styles.cardError} />
          )}
          {serviceError && (
            <Error errorMessage={'The code you entered is incorrect. Please try again'} className={styles.cardError} />
          )}
        </>
      )}
    />
  );
};
