import type {FileStorageModel} from '../../../model';
import {FileStoragePostRequestDto} from './file-storage-post-request.dto';

class FileStoragePostRequestDtoFactory {
  static fromModel(model: FileStorageModel): FileStoragePostRequestDto {
    return new FileStoragePostRequestDto(model.content, model.fileName, model.mimeType, model.objectFileType);
  }
}

export {FileStoragePostRequestDtoFactory};
