import React from 'react';
import type {FC} from 'react';
import styles from '../two-factor-auth-form.module.scss';
import {Button, CardContent, Icon, IconType} from '../../../../../../components';
import classNames from 'classnames';

interface IOwnProps {
  handleLogout(): void;
  isLogoutShow: boolean;
}
export const Confirmed: FC<IOwnProps> = ({handleLogout, isLogoutShow}) => (
  <>
    <p className={styles.cardConfirmed}>Confirmed!</p>
    <div className={styles.icon}>
      <Icon type={IconType.CheckmarkCompletedIcon} />
    </div>
    {isLogoutShow && (
      <CardContent className={styles.cardContent}>
        <p className={classNames(styles.cardInfoMessage, styles.centerCardInfo)}>
          You will need to reconfirm your account to apply this change. Please log back in to confirm.
        </p>
        <Button className={styles.cardButton} onClick={() => handleLogout()} primary={true}>
          Log Out
        </Button>
      </CardContent>
    )}
  </>
);
