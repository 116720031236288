import type {BodyMassIndexModel} from '../model';
import {BodyMassIndexUnitEnum} from '../model';
import {makeAutoObservable} from 'mobx';
import {inject, injectable} from 'inversify';
import type {IBodyMassIndexService} from './body-mass-index.service.interface';
import type {BodyMassIndexFormDto} from '../form/dto';
import {getLbFromKg} from '../helper';
import type {IBodyMassIndexRepository} from '../repository';
import {BODY_MASS_INDEX_REPOSITORY} from '../repository';
import type {IBodyMassIndexSaveConvertedDto} from '../repository/dto';
import {BodyMassIndexSaveRequestDtoFactory} from '../repository/dto';
import type {INotifierService} from '../../notifier';
import {NOTIFIER_SERVICE, NotifyMessageTypeEnum} from '../../notifier';

@injectable()
class BodyMassIndexService implements IBodyMassIndexService {
  constructor(
    @inject(BODY_MASS_INDEX_REPOSITORY) private readonly _repo: IBodyMassIndexRepository,
    @inject(NOTIFIER_SERVICE) private readonly _notifier: INotifierService,
  ) {
    makeAutoObservable(this);
  }

  private _records: BodyMassIndexModel[] = [];

  get records(): BodyMassIndexModel[] {
    return this._records;
  }

  private _selectedUnit = BodyMassIndexUnitEnum.FtAndLb;

  get selectedUnit(): BodyMassIndexUnitEnum {
    return this._selectedUnit;
  }

  private _isLoading: boolean = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private static convertUnits(formDto: BodyMassIndexFormDto): IBodyMassIndexSaveConvertedDto {
    switch (formDto.unit) {
      case BodyMassIndexUnitEnum.FtAndLb:
        return {weight: Number(formDto.weight)};
      case BodyMassIndexUnitEnum.CmAndKg:
        return {weight: Number(getLbFromKg(formDto.weight))};
      default:
        return {weight: Number(formDto.weight)};
    }
  }

  async selectUnit(unit: BodyMassIndexUnitEnum): Promise<void> {
    this._selectedUnit = unit;
  }

  async loadRecords(): Promise<void> {
    this._isLoading = true;
    this._records = await this._repo.list();
    this._isLoading = false;
  }

  async send(formDto: BodyMassIndexFormDto): Promise<void> {
    const convertedData = BodyMassIndexService.convertUnits(formDto);

    const postDto = BodyMassIndexSaveRequestDtoFactory.fromModel(convertedData);

    await this._repo
      .save(postDto)
      .then(() => {
        this._notifier.notify({
          text: 'Your weight successfully saved',
          title: 'Success',
          type: NotifyMessageTypeEnum.Success,
        });

        this.loadRecords();
      })
      .catch((e) => {
        console.error(e);
        this._notifier.notify({
          text: 'An internal error happened! Please try again after a few minutes…',
          title: 'Failed!',
          type: NotifyMessageTypeEnum.Danger,
        });
      });
  }
}

export {BodyMassIndexService};
