import type {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import styles from './tasks.module.scss';
import {List, PageTitle} from '../../../components';
import {TaskListItem} from '../../../task/components/task-list-item';
import type {TaskModel} from '../../../task';
import {TaskModal} from '../../../task/components/task-modal';
import {PreVisitTaskModal} from '../../../components/pre-visit-task';
import {DailyTaskActivityType, ModalType, PredefinedTasks} from '../../../enums';
import {routes} from '../../../routing';
import {useInjection} from '../../../ioc';
import type {IPreVisitService} from '../../../pre-visit';
import {PREVISIT_SERVICE} from '../../../pre-visit';
import type {ITaskService} from '../../../task/service';
import {TASK_SERVICE} from '../../../task/service';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import type {IRoutingService} from '../../../utils/routing';
import {ROUTING_SERVICE} from '../../../utils/routing';
import type {IAuthConfigService} from '../../../utils/auth/config';
import {AUTH_CONFIG_SERVICE} from '../../../utils/auth/config';

interface IProps {
  preVisit: IPreVisitService;
  task: ITaskService;
  account: IAccountService;
  routing: IRoutingService;
  authConfig: IAuthConfigService;
}

const TasksScreen: FC<IProps> = ({preVisit, task, account, routing, authConfig}) => {
  const [openModalType, setOpenModalType] = useState<ModalType>(ModalType.None);
  const [selectedTask, setSelectedTask] = useState<TaskModel | null>(null);
  const [courseTask, setCourseTask] = useState<TaskModel | null>(null);

  const checkOpenModalType = useCallback((types: ModalType[]) => types.includes(openModalType), [openModalType]);

  useEffect(() => {
    if (account.id) {
      task.load(DailyTaskActivityType.General);
      preVisit.load(account.id);
    }
  }, [task, preVisit, account.id]);

  const onTaskComplete = useCallback(() => {
    if (courseTask?.id) {
      task.completeAndRefresh(courseTask?.id);
      setCourseTask(null);
    }
  }, [courseTask?.id, task]);

  const taskSelect = useCallback(
    (task: TaskModel) => {
      switch (task.patientDailyTaskId) {
        case PredefinedTasks.PreVisitTask:
          setOpenModalType(ModalType.PreVisitTask);
          setSelectedTask(task);
          break;
        case PredefinedTasks.WeeklyWellnessReportSurvey:
          routing.push(`${routes.home}${routes.weeklyWellnessReport}`);
          break;
        case PredefinedTasks.DailySymptomTracker:
          routing.push(`${routes.home}${routes.dailySymptomTracker}`);
          break;
        case PredefinedTasks.ResilienceAssessment:
          routing.push(`${routes.home}${routes.resilienceAssessment}`, {forceRouting: true});
          break;
        case PredefinedTasks.StrengthAssessment:
          routing.push(`${routes.home}${routes.strengthsAssessment}`, {forceRouting: true});
          break;
        case PredefinedTasks.Vaccination:
          routing.push(`${routes.home}${routes.vaccination}`);
          break;
        case PredefinedTasks.EmotionalImpactScale:
          routing.push(`${routes.home}${routes.emotionalImpactScale}`);
          break;
        case PredefinedTasks.SelfEfficacy:
          routing.push(`${routes.home}${routes.selfEfficacy}`, {forceRouting: true});
          break;
        case PredefinedTasks.DiseaseSeverityScreener:
          routing.push(`${routes.home}${routes.diseaseSeverityScreener}`, {forceRouting: true});
          break;
        case PredefinedTasks.ChronicConditionChecklist:
          routing.push(`${routes.home}${routes.chronicConditionChecklist}`, {forceRouting: true});
          break;
        case PredefinedTasks.WorkProductivity:
          routing.push(`${routes.home}${routes.workProductivity}`);
          break;
        case PredefinedTasks.LifeQuality:
          routing.push(`${routes.home}${routes.lifeQuality}`);
          break;
        case PredefinedTasks.GlobalImprovementScale:
          routing.push(`${routes.home}${routes.globalImprovementScale}`);
          break;
        case PredefinedTasks.MedicationsListUpdate:
          routing.push(`${routes.home}${routes.medications}`);
          break;
        default:
          setCourseTask(task);
      }
    },
    [routing],
  );

  const taskListComplete = !!task.completeTasks?.length && (
    <List noStyle title={'Completed'}>
      {task.completeTasks?.map((i) => (
        <TaskListItem key={i.id} task={i} timeZone={account.timeZone} />
      ))}
    </List>
  );

  const taskListIncomplete = !!task.incompleteTasks?.length && (
    <List noStyle title={'Incomplete'}>
      {task.incompleteTasks.map((i) => (
        <TaskListItem key={i.id} task={i} timeZone={account.timeZone} onClick={() => taskSelect(i)} />
      ))}
    </List>
  );

  const closeModal = useCallback(() => {
    setOpenModalType(ModalType.None);
    setSelectedTask(null);
  }, []);

  const downloadPreVisitFile = useCallback(() => preVisit.downloadPreVisitFile(), [preVisit]);

  const completePreVisitTask = useCallback(() => {
    if (selectedTask?.id) {
      task.completeAndRefresh(selectedTask.id);
      task.showPreVisitTaskBanner();
      closeModal();
    }
  }, [closeModal, selectedTask?.id, task]);

  return (
    <>
      <TaskModal
        timeZone={account.timeZone}
        selectedTask={courseTask}
        onClose={() => setCourseTask(null)}
        onComplete={onTaskComplete}
        lmsLink={authConfig.lmsCourseLink}
      />
      {checkOpenModalType([ModalType.PreVisitTask]) && !!selectedTask && (
        <PreVisitTaskModal
          selectedTask={selectedTask}
          title={selectedTask.title}
          onPreVisitFileDownload={downloadPreVisitFile}
          onClose={closeModal}
          onComplete={completePreVisitTask}
          isSymptomTrackingComplete={preVisit.preVisitInfo.isSymptomTrackingComplete}
          isWeeklyWellnessReportComplete={preVisit.preVisitInfo.isWeeklyWellnessReportComplete}
          isLabResultComplete={preVisit.preVisitInfo.isLabResultComplete}
          timeZone={account.timeZone}
        />
      )}
      <section className={styles.section}>
        <div className={styles.sectionHeader}>
          <PageTitle>My To-Do list</PageTitle>
        </div>
        {taskListIncomplete}
        {taskListComplete}
      </section>
    </>
  );
};

const ObservableTasksScreen = observer(TasksScreen);
const TasksScreenInjected: FC = () => (
  <ObservableTasksScreen
    preVisit={useInjection(PREVISIT_SERVICE)}
    task={useInjection(TASK_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
    routing={useInjection(ROUTING_SERVICE)}
    authConfig={useInjection(AUTH_CONFIG_SERVICE)}
  />
);

export {TasksScreenInjected as TasksScreen};
