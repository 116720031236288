import type {IStrengthAssessmentFormElement} from '../form';

const allThingsIbdFormElements: Array<IStrengthAssessmentFormElement> = [
  {
    defaultValue: 0,
    name: 'q_strengths_assessment_how_satisfied_with_life',
    label: 'How satisfied are you with your life as a whole these days?',
    ariaLabelTitle: 'How satisfied are you with your life as a whole these days?',
    minValue: 0,
    maxValue: 10,
  },
];

const allThingsIbsFormElements: Array<IStrengthAssessmentFormElement> = [
  {
    defaultValue: 0,
    name: 'q_ibs_strengths_assessment_how_satisfied_with_life',
    label: 'How satisfied are you with your life as a whole these days?',
    ariaLabelTitle: 'How satisfied are you with your life as a whole these days?',
    minValue: 0,
    maxValue: 10,
  },
];

const trueIbdFormElements: Array<IStrengthAssessmentFormElement> = [
  {
    defaultValue: 0,
    name: 'q_strengths_assessment_avoid_situations',
    label: `I avoid putting myself in situations where my symptoms might worsen`,
    ariaLabelTitle: `I avoid putting myself in situations where my symptoms might worsen`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_strengths_assessment_rid_of_ibd_symptoms',
    label: `I need to concentrate on getting rid of my symptoms`,
    ariaLabelTitle: `I need to concentrate on getting rid of my symptoms`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_strengths_assessment_able_to_adapt',
    label: `I am able to adapt when changes occur`,
    ariaLabelTitle: `I am able to adapt when changes occur`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_strengths_assessment_tend_to_bounce',
    label: `I tend to bounce back after illness, injury or other hardships`,
    ariaLabelTitle: `I tend to bounce back after illness, injury or other hardships`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_strengths_assessment_expect_the_best',
    label: `In uncertain times, I usually expect the best`,
    ariaLabelTitle: `In uncertain times, I usually expect the best`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_strengths_assessment_have_someone_trust',
    label: `I have someone I trust to talk with about my problems`,
    ariaLabelTitle: `I have someone I trust to talk with about my problems`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_strengths_assessment_feel_grateful_each_day',
    label: `I feel grateful for each day`,
    ariaLabelTitle: `I feel grateful for each day`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
];

const trueIbsFormElements: Array<IStrengthAssessmentFormElement> = [
  {
    defaultValue: 0,
    name: 'q_ibs_strengths_assessment_avoid_situations',
    label: `I avoid putting myself in situations where my symptoms might worsen`,
    ariaLabelTitle: `I avoid putting myself in situations where my symptoms might worsen`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_ibs_strengths_assessment_rid_of_symptoms',
    label: `I need to concentrate on getting rid of my symptoms`,
    ariaLabelTitle: `I need to concentrate on getting rid of my symptoms`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_ibs_strengths_assessment_able_to_adapt',
    label: `I am able to adapt when changes occur`,
    ariaLabelTitle: `I am able to adapt when changes occur`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_ibs_strengths_assessment_tend_to_bounce',
    label: `I tend to bounce back after illness, injury or other hardships`,
    ariaLabelTitle: `I tend to bounce back after illness, injury or other hardships`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_ibs_strengths_assessment_expect_the_best',
    label: `In uncertain times, I usually expect the best`,
    ariaLabelTitle: `In uncertain times, I usually expect the best`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_ibs_strengths_assessment_have_someone_trust',
    label: `I have someone I trust to talk with about my problems`,
    ariaLabelTitle: `I have someone I trust to talk with about my problems`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
  {
    defaultValue: 0,
    name: 'q_ibs_strengths_assessment_feel_grateful_each_day',
    label: `I feel grateful for each day`,
    ariaLabelTitle: `I feel grateful for each day`,
    minValue: 0,
    maxValue: 6,
    startLabel: 'Never<br/> True',
    endLabel: 'Always<br/> True',
  },
];

export {allThingsIbdFormElements, allThingsIbsFormElements, trueIbdFormElements, trueIbsFormElements};
