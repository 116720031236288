import type {FC} from 'react';
import React, {useEffect, useMemo} from 'react';
import type {DiseaseSeverityScreenerQuestionModel} from '../../model';
import {Controller, useFormContext} from 'react-hook-form';
import {parseFormulaToShowQuestion, REQUIRED_FIELD_ERROR, scrollToError, shiftTimezone} from '../../../helpers';
import type {ISurveyRadioButtonOption} from '../../../components';
import {
  AssessmentItemWrapper,
  AssessmentRangeBar,
  DatePicker,
  DatePickerInputType,
  DatePickerView,
  Error,
  SingleDropdown,
  SurveyRadioButtonGroup,
} from '../../../components';
import classnames from 'classnames';
import styles from './disease-severity-screener-questions.module.scss';
import {AssessmentQuestionType} from '../../../assessment';

interface IDiseaseSeverityScreenerQuestionsProps {
  listQuestions: DiseaseSeverityScreenerQuestionModel[];
  readonly: boolean;
}

const DiseaseSeverityScreenerQuestions: FC<IDiseaseSeverityScreenerQuestionsProps> = ({listQuestions, readonly}) => {
  const {control, watch, errors} = useFormContext();

  const formValues = watch();

  const validateRadioButton = (value: ISurveyRadioButtonOption) => {
    if (!value.id) {
      return REQUIRED_FIELD_ERROR;
    }
  };

  const isShowQuestion = useMemo(
    () => (question: DiseaseSeverityScreenerQuestionModel) =>
      question?.visibleIf ? parseFormulaToShowQuestion(question.visibleIf, formValues) : true,
    [formValues],
  );

  const questions = useMemo(
    () =>
      listQuestions.map((question) => {
        switch (question.type) {
          case AssessmentQuestionType.Dropdown:
            const selectedValue = watch(question.name);

            const subTitle = selectedValue?.displayName ? `${selectedValue?.displayName} Questionnaire` : null;

            return (
              isShowQuestion(question) && (
                <React.Fragment key={question.name}>
                  <AssessmentItemWrapper title={question.displayName} key={question.name}>
                    <Controller
                      control={control}
                      name={question.name}
                      rules={{required: question.required && REQUIRED_FIELD_ERROR}}
                      render={({name, onChange, value}) =>
                        !readonly ? (
                          <>
                            <SingleDropdown
                              options={question.options}
                              onChange={onChange}
                              name={name}
                              value={value}
                              className={classnames({[styles.singleDropdownError]: !!errors[name]})}
                              ariaLabelTitle={question.displayName}
                              ariaLabelError={!!errors[name]?.message ? errors[name]?.message : ''}
                            />
                            {!!errors[name] && (
                              <Error errorMessage={errors[name]?.message ?? ''} className={styles.errorText} />
                            )}
                          </>
                        ) : (
                          <>{value?.displayName}</>
                        )
                      }
                    />
                  </AssessmentItemWrapper>
                  {subTitle && <AssessmentItemWrapper title={subTitle} key={subTitle} onlyTitle />}
                </React.Fragment>
              )
            );
          case AssessmentQuestionType.RadioButton:
            const radioButtonOptions = question.options.map((option) => ({id: option.value, text: option.displayName}));

            return (
              isShowQuestion(question) && (
                <AssessmentItemWrapper title={question.displayName} key={question.name}>
                  <Controller
                    control={control}
                    name={question.name}
                    rules={{
                      validate: (value) => question.required && validateRadioButton(value),
                    }}
                    render={({name, onChange, value}) =>
                      !readonly ? (
                        <>
                          <SurveyRadioButtonGroup
                            name={name}
                            value={value}
                            onChange={onChange}
                            options={radioButtonOptions}
                            isError={!!errors[name]}
                            ariaLabelTitle={question.displayName}
                            ariaLabelError={!!errors[name]?.message ? errors[name]?.message : ''}
                          />
                          {!!errors[name] && <Error errorMessage={errors[name]?.message ?? ''} />}
                        </>
                      ) : (
                        <>{value.text}</>
                      )
                    }
                  />
                </AssessmentItemWrapper>
              )
            );
          case AssessmentQuestionType.DatePicker:
            return (
              isShowQuestion(question) && (
                <AssessmentItemWrapper title={question.displayName} key={question.name}>
                  <Controller
                    control={control}
                    name={question.name}
                    rules={{required: question.required && REQUIRED_FIELD_ERROR}}
                    render={({name, onChange, value}) =>
                      !readonly ? (
                        <>
                          <div className={styles.datePicker}>
                            <DatePicker
                              value={value}
                              onChange={onChange}
                              min={shiftTimezone(new Date(String(question.minValue)))}
                              max={shiftTimezone(new Date(String(question.maxValue)))}
                              showYearPicker
                              format={'yyyy'}
                              view={DatePickerView?.OnlyYear}
                              inputType={DatePickerInputType?.Default}
                              isValid={!errors[name]?.message}
                              ariaLabelTitle={question.displayName}
                              ariaLabelError={!!errors[name]?.message ? errors[name]?.message : ''}
                            />
                          </div>
                          {!!errors[name] && <Error errorMessage={errors[name]?.message ?? ''} />}
                        </>
                      ) : (
                        <>{value}</>
                      )
                    }
                  />
                </AssessmentItemWrapper>
              )
            );
          case AssessmentQuestionType.Range:
            return (
              isShowQuestion(question) && (
                <AssessmentItemWrapper title={question.displayName} key={question.name}>
                  <Controller
                    control={control}
                    name={question.name}
                    render={({name, onChange, value}) => (
                      <AssessmentRangeBar
                        question={question}
                        name={name}
                        value={value}
                        onChange={onChange}
                        readonly={readonly}
                        ariaLabelTitle={question.displayName}
                        ariaLabelError={!!errors[name]?.message ? errors[name]?.message : ''}
                      />
                    )}
                  />
                </AssessmentItemWrapper>
              )
            );
          default:
            return null;
        }
      }),
    [control, errors, isShowQuestion, listQuestions, readonly, watch],
  );

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  return <>{questions}</>;
};

export {DiseaseSeverityScreenerQuestions};
