import type {FC} from 'react';
import React, {useMemo} from 'react';
import styles from './encounter-details-follow-up.module.scss';
import classNames from 'classnames';
import {ClockIcon, DateIcon} from '../../../../../assets/icons';

interface IEncounterDetailsFollowUpProps {
  visitDuration: number;
  nextRecommendedVisitInfo: string;
}

const VISIT_INFO_NOT_PROVIDED_TEXT = 'Not Provided';
const VISIT_INFO_APPOINTMENT_TEXT = 'Next recommended appointment visit was not specified';
const ABSENT_VISIT_WEEKS_TEXT = '0 weeks';
const ABSENT_VISIT_PROVIDER_TEXT = 'None';

const EncounterDetailsFollowUp: FC<IEncounterDetailsFollowUpProps> = ({visitDuration, nextRecommendedVisitInfo}) => {
  const visitDurationValue = useMemo(
    () =>
      `Duration of the visit${
        Boolean(visitDuration) ? ` ${visitDuration} minutes` : `: ${VISIT_INFO_NOT_PROVIDED_TEXT}`
      }`,
    [visitDuration],
  );
  const nextRecommendedVisitInfoValue = useMemo(
    () =>
      Boolean(nextRecommendedVisitInfo) &&
      !nextRecommendedVisitInfo.includes(ABSENT_VISIT_WEEKS_TEXT) &&
      !nextRecommendedVisitInfo.includes(ABSENT_VISIT_PROVIDER_TEXT)
        ? nextRecommendedVisitInfo
        : VISIT_INFO_APPOINTMENT_TEXT,
    [nextRecommendedVisitInfo],
  );

  return (
    <div className={styles.followUp}>
      <div className={styles.detailLabel}>Follow–Up</div>
      <div className={classNames(styles.detailValue, styles.followUpValue)}>
        <span className={styles.followUpSubValue}>
          <span className={styles.followUpIcon}>
            <ClockIcon />
          </span>
          <span>{visitDurationValue}</span>
        </span>

        <span className={styles.followUpSubValue}>
          <span className={styles.followUpIcon}>
            <DateIcon />
          </span>
          <span>{nextRecommendedVisitInfoValue}</span>
        </span>
      </div>
    </div>
  );
};

export {EncounterDetailsFollowUp};
export type {IEncounterDetailsFollowUpProps};
