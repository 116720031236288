import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import type {DropzoneOptions, FileError, FileRejection} from 'react-dropzone';
import {useDropzone} from 'react-dropzone';
import {ItemFileUploader} from './components';
import {getPreparedDataUrl} from './file-uploader.helper';
import styles from './file-uploader.module.scss';
import {AddFileIcon} from '../../assets/icons';

interface IFile {
  file: File;
  errors: FileError[];
  base64?: string;
}

interface IFileUploaderProps {
  accept: string;
  multiFile?: boolean;
  maxSizeBytes?: number;
  errorMessageMaxSize?: string;
  errorMessageAccept?: string;
  dragTextBefore: string;
  dragTextAfter?: string;
  uploadText?: string;
  onSetFiles?: (files: IFile[]) => void;
  filesUpload: IFile[];
}

const FileUploader: FC<IFileUploaderProps> = ({
  accept,
  multiFile = false,
  maxSizeBytes,
  errorMessageMaxSize,
  errorMessageAccept,
  dragTextBefore,
  dragTextAfter,
  uploadText,
  onSetFiles,
  filesUpload,
}) => {
  const [files, setFiles] = useState<IFile[]>(filesUpload);

  const onDrop = useCallback(
    (accFiles: File[], rejFiles: FileRejection[]) => {
      const mappedAcc = accFiles.map((file) => ({file, errors: [], base64: ''}));
      setFiles((curr) => [...curr, ...mappedAcc, ...rejFiles]);
    },
    [setFiles],
  );

  const onDelete = useCallback((file: File) => {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
  }, []);

  const setBase64 = useCallback((file, base64) => {
    setFiles((curr) =>
      curr.map((item) => {
        if (item.file === file) {
          return {
            file: item.file,
            errors: item.errors,
            base64: getPreparedDataUrl(base64),
          };
        }

        return item;
      }),
    );
  }, []);

  const dropzoneOptions = useMemo(() => {
    const options: DropzoneOptions = {
      onDrop,
      accept,
    };

    if (maxSizeBytes) {
      options.maxSize = maxSizeBytes;
    }

    if (!multiFile) {
      options.maxFiles = 1;
    }

    return options;
  }, [accept, maxSizeBytes, multiFile, onDrop]);

  const {getRootProps, getInputProps} = useDropzone(dropzoneOptions);

  useEffect(() => {
    onSetFiles && onSetFiles(files);
  }, [files, onSetFiles]);

  useEffect(() => {
    if (!filesUpload.length) {
      setFiles([]);
    }
  }, [filesUpload.length]);

  return (
    <>
      {(multiFile || !files.length) && (
        <div {...getRootProps()}>
          <input {...getInputProps()} multiple={multiFile} />
          <div className={styles.dropZone}>
            <div className={styles.dropZoneIcon}>
              <div className={styles.icon}>
                <AddFileIcon />
              </div>
            </div>
            <div className={styles.dropZoneText}>
              {dragTextBefore} {!!uploadText && <span>{uploadText}</span>} {dragTextAfter}
            </div>
          </div>
        </div>
      )}

      {files.map((fileWrapper, index) => (
        <ItemFileUploader
          key={index}
          file={fileWrapper.file}
          onDelete={onDelete}
          setBase64={setBase64}
          errors={fileWrapper.errors}
          errorMessageMaxSize={errorMessageMaxSize}
          errorMessageAccept={errorMessageAccept}
        />
      ))}
    </>
  );
};

export type {IFile, IFileUploaderProps};
export {FileUploader};
