import type {FC} from 'react';
import React, {useCallback, useMemo} from 'react';
import styles from './assessments-table-row.module.scss';

interface IAssessmentsTableRow {
  id: number;
  cells: string[];
}

interface IAssessmentsTableRowProps {
  row: IAssessmentsTableRow;
  onClick: (id: number) => void;
}

const AssessmentsTableRow: FC<IAssessmentsTableRowProps> = ({row, onClick}) => {
  const rows = useMemo(
    () =>
      row.cells.map((cell, index) => (
        <td key={`${cell}_${index}`} className={styles.tableCell}>
          {cell}
        </td>
      )),
    [row.cells],
  );

  const click = useCallback(() => {
    onClick(row.id);
  }, [onClick, row.id]);

  return (
    <tr className={styles.tableRow} onClick={click} data-testid={`AssessmentsTableRow${row.id}`}>
      {rows}
    </tr>
  );
};

export {AssessmentsTableRow};
export type {IAssessmentsTableRow};
