import type {FC} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Button, CardActions, CardContent, Error, MaskedInput, SingleDropdown} from '../../components';
import type {IOption} from '../../types';
import styles from './body-mass-index-form.module.scss';
import {BodyMassIndexUnitEnum} from '../model';
import {BodyMassIndexFormCardRow, BodyMassIndexFormCardWrapper} from './components';
import type {BodyMassIndexFormDto} from './dto';
import {REQUIRED_FIELD_ERROR, scrollToError} from '../../helpers';
import {getKgFromLb, getLbFromKg} from '../helper';

interface IBodyMassIndexFormProps {
  units: IOption[];
  onSubmit: (data: any) => void;
  onClose: () => void;
}

const UNIT_FIELD_NAME = 'unit';
const WEIGHT_FIELD_NAME = 'weight';

const BodyMassIndexForm: FC<IBodyMassIndexFormProps> = ({units, onSubmit, onClose}) => {
  const [selectedUnit, setSelectedUnit] = useState<BodyMassIndexUnitEnum>(BodyMassIndexUnitEnum.FtAndLb);

  const {control, watch, setValue, handleSubmit, errors} = useForm<BodyMassIndexFormDto>({
    defaultValues: {unit: BodyMassIndexUnitEnum.FtAndLb},
  });

  const unit = watch(UNIT_FIELD_NAME);
  const weight = watch(WEIGHT_FIELD_NAME);

  useEffect(() => {
    if (selectedUnit !== unit) {
      setSelectedUnit(unit);

      switch (unit) {
        case BodyMassIndexUnitEnum.FtAndLb:
          weight && setValue(WEIGHT_FIELD_NAME, getLbFromKg(weight));
          break;
        case BodyMassIndexUnitEnum.CmAndKg:
          weight && setValue(WEIGHT_FIELD_NAME, getKgFromLb(weight));
          break;
        default:
          break;
      }
    }

    scrollToError(errors);
  }, [selectedUnit, setValue, unit, weight, errors]);

  const actions = useMemo(
    () => (
      <CardActions>
        <Button flat onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" onSubmit={handleSubmit(onSubmit)}>
          Save
        </Button>
      </CardActions>
    ),
    [handleSubmit, onClose, onSubmit],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <CardContent className={styles.content}>
        <BodyMassIndexFormCardWrapper>
          <Controller
            name={UNIT_FIELD_NAME}
            control={control}
            render={({name, value, onChange}) => {
              const currentValue = units.find((option) => option.value === value);

              return (
                <SingleDropdown
                  name={name}
                  className={styles.unitDropdown}
                  onChange={(option) => onChange(option.value)}
                  value={currentValue}
                  options={units}
                  showInputValueAlways
                />
              );
            }}
          />
        </BodyMassIndexFormCardWrapper>
        <BodyMassIndexFormCardRow>
          <BodyMassIndexFormCardWrapper label={'Weight'}>
            <Controller
              name={WEIGHT_FIELD_NAME}
              control={control}
              rules={{
                required: REQUIRED_FIELD_ERROR,
              }}
              render={({name, onChange, value}) => (
                <MaskedInput
                  name={name}
                  mask={'999.99'}
                  value={value}
                  onValueChanged={onChange}
                  maskPlaceholder={''}
                  className={styles.inputNumber}
                  isValid={!errors[WEIGHT_FIELD_NAME]}
                  ariaLabelError={!!errors[WEIGHT_FIELD_NAME]?.message ? errors[WEIGHT_FIELD_NAME]?.message : ''}
                />
              )}
            />
            {!!errors[WEIGHT_FIELD_NAME]?.message && (
              <div className={styles.errorText}>
                <Error errorMessage={errors[WEIGHT_FIELD_NAME]?.message ?? ''} name={WEIGHT_FIELD_NAME} />
              </div>
            )}
          </BodyMassIndexFormCardWrapper>
        </BodyMassIndexFormCardRow>
      </CardContent>
      {actions}
    </form>
  );
};

export {BodyMassIndexForm};
