import React from 'react';
import {TaskActions} from '../task-actions';
import {TaskModel} from '../../model';
import type {CourseTask} from '../../../patient-course/model/course-task';
import {DailyTaskActivityType} from '../../../enums';
import {ListItem} from '../../../components';

interface ITaskListItemProps {
  task: TaskModel | CourseTask;
  timeZone: string;
  showFavoriteIcon?: boolean;
  isLesson?: boolean;
  lmsLink?: string;

  onChangeFavoriteItem?: (taskId: number, isFavorite: boolean) => void;
  onLessonComplete?: (id: number, begin?: boolean, skip?: boolean) => void;
  onBegin?: (id: number) => void;
  onClick?: () => void;
}

const TaskListItem: React.FC<ITaskListItemProps> = (props) => {
  const dateDiffInDays: number = props.task.getDiffInDays(props.timeZone);
  const isDateExpired: boolean = dateDiffInDays > 0;
  const date: string =
    props.task.dailyTaskActivityType === DailyTaskActivityType.Lesson
      ? ''
      : isDateExpired
      ? `${dateDiffInDays} days ago`
      : '';

  if (props.task instanceof TaskModel) {
    return (
      <ListItem
        key={props.task.id}
        hoverable
        card
        title={props.task.lessonDisplayTitle || props.task.title}
        metaText={props.task.category}
        date={date}
        dateDanger={isDateExpired}
        isComplete={props.task.complete}
        onChangeFavoriteItem={props.onChangeFavoriteItem}
        isFavorite={props.task.isFavoriteLesson}
        id={props.task.id}
        showFavoriteIcon={props.showFavoriteIcon}
        image={props.task.lessonThumbnailObjectFile}
        isLesson={props.isLesson}
        onClick={props.onClick}
        actions={
          <TaskActions
            beginDate={props.task.beginDate}
            complete={props.task.complete}
            completeDate={props.task.complete ? props.task.doneDate : null}
            courseLink={props.task.courseLink}
            id={props.task.id}
            isSkippable={props.task.isSkippable}
            lmsLink={props.lmsLink}
            onBegin={props.onBegin}
            onComplete={props.onLessonComplete}
            task={props.task}
            timeZone={props.timeZone}
          />
        }
      />
    );
  }

  return (
    <ListItem
      key={props.task.id}
      hoverable
      card
      title={props.task.taskSubject}
      metaText={props.task.skillTypeName}
      date={date}
      dateDanger={isDateExpired}
      isComplete={props.task.complete}
      onChangeFavoriteItem={props.onChangeFavoriteItem}
      isFavorite={props.task.isFavoriteLesson}
      id={props.task.id}
      showFavoriteIcon={props.showFavoriteIcon}
      image={props.task.courseThumbnailObjectFile}
      isLesson={props.isLesson}
      onClick={props.onClick}
      actions={
        <TaskActions
          beginDate={props.task.beginDate}
          complete={props.task.complete}
          completeDate={props.task.complete ? props.task.doneDate : null}
          courseLink={props.task.courseLink}
          id={props.task.id}
          isSkippable={props.task.isSkippable}
          lmsLink={props.lmsLink}
          onBegin={props.onBegin}
          onComplete={props.onLessonComplete}
          task={props.task}
          timeZone={props.timeZone}
        />
      }
    />
  );
};

export {TaskListItem};
