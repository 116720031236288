import type {FC} from 'react';
import React, {useMemo} from 'react';
import styles from './datepicker-header-only-year.module.scss';
import {ChevronLeftIcon, ChevronRightIcon} from '../../../../assets/icons';
import classnames from 'classnames';
import {getIntervalStartAndEndYears} from './helper';

interface IDatepickerHeaderOnlyYearProps {
  currentDate: Date;
  decreaseYear: () => void;
  increaseYear: () => void;
  minDate: Date | null | undefined;
  maxDate: Date | null | undefined;
  yearRangeLength?: number;
}

const DatepickerHeaderOnlyYear: FC<IDatepickerHeaderOnlyYearProps> = (props) => {
  const intervalStartAndEndYears = useMemo(
    () => getIntervalStartAndEndYears(props.currentDate, props.yearRangeLength),
    [props.currentDate, props.yearRangeLength],
  );

  const isYearDecreaseDisabled = useMemo(
    () => (props.minDate ? intervalStartAndEndYears[0] < props.minDate.getFullYear() : false),
    [intervalStartAndEndYears, props.minDate],
  );

  const isYearIncreaseDisabled = useMemo(
    () => (props.maxDate ? intervalStartAndEndYears[1] > new Date().getFullYear() : false),
    [intervalStartAndEndYears, props.maxDate],
  );

  return (
    <div className={styles.root} data-testid="date-picker-only-year-header">
      <button
        type="button"
        data-testid={'yearDecrease'}
        className={classnames(styles.calendarArrows, {
          [styles.disabledArrow]: isYearDecreaseDisabled,
        })}
        onClick={props.decreaseYear}
        disabled={isYearDecreaseDisabled}>
        <ChevronLeftIcon />
      </button>
      <span className={styles.title}>{intervalStartAndEndYears.join(' - ')}</span>
      <button
        type="button"
        data-testid={'yearIncrease'}
        className={classnames(styles.calendarArrows, {
          [styles.disabledArrow]: isYearIncreaseDisabled,
        })}
        onClick={props.increaseYear}
        disabled={isYearIncreaseDisabled}>
        <ChevronRightIcon />
      </button>
    </div>
  );
};

export {DatepickerHeaderOnlyYear};
