import type {
  IEmotionalImpactScaleQuery,
  IEmotionalImpactScaleRepository
} from './emotional-impact-scale.repository.interface';
import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {EmotionalImpactScaleModel, EmotionalImpactScaleQuestionModel} from '../model';
import {EmotionalImpactScaleModelFactory, EmotionalImpactScaleQuestionModelFactory} from '../model';
import type {IEmotionalImpactScaleGetResponseDto, IEmotionalImpactScaleQuestionsGetResponseDto} from './dto';
import {IEmotionalImpactScaleSaveRequestDto} from './dto';

@injectable()
class EmotionalImpactScaleRepository implements IEmotionalImpactScaleRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {
  }

  async find(query?: Partial<IEmotionalImpactScaleQuery>): Promise<EmotionalImpactScaleModel | null> {
    let model: EmotionalImpactScaleModel | null = null;

    if (query?.id) {
      const response = await this._http.get<IEmotionalImpactScaleGetResponseDto>(`NIHPromis/${query.id}`);

      model = EmotionalImpactScaleModelFactory.fromGetResponseDto(response.data);
    }

    return model;
  }

  async list(): Promise<EmotionalImpactScaleModel[]> {
    const response = await this._http.get<IEmotionalImpactScaleGetResponseDto[]>('NIHPromis');

    return response.data?.map((i) => EmotionalImpactScaleModelFactory.fromGetResponseDto(i)) ?? [];
  }

  remove(): Promise<void> {
    throw new NotImplementedException();
  }

  async save(model: EmotionalImpactScaleModel): Promise<void> {
    const postDto = new IEmotionalImpactScaleSaveRequestDto(model.patientId, model.items);

    await this._http.post<number>('NIHPromis', postDto);
  }

  async listQuestions(): Promise<EmotionalImpactScaleQuestionModel[]> {
    const {data} = await this._http.get<IEmotionalImpactScaleQuestionsGetResponseDto[]>('NIHPromis/getsurveyforpatient');

    return data.map((dto) => EmotionalImpactScaleQuestionModelFactory.fromGetDto(dto));
  }
}

export {EmotionalImpactScaleRepository};
