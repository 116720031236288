import type {IProfileVerificationService} from './profile-verification.service.interface';
import {inject, injectable} from 'inversify';
import {PROFILE_VERIFICATION_REPOSITORY} from '../repository';
import type {IProfileVerificationRepository} from '../repository';

@injectable()
class ProfileVerificationService implements IProfileVerificationService {
  constructor(@inject(PROFILE_VERIFICATION_REPOSITORY) private readonly _repo: IProfileVerificationRepository) {}

  private _isSend: boolean = false;
  private _isLoading: boolean = false;
  private _isSuccess: boolean = false;
  private _error: string | null = null;
  private _phone: string | null = null;

  get isSend() {
    return this._isSend;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isSuccess() {
    return this._isSuccess;
  }

  get error() {
    return this._error;
  }

  get phone() {
    return this._phone;
  }

  clear() {
    this._isSend = false;
    this._isLoading = false;
    this._isSuccess = false;
    this._error = null;
  }

  async requestCode() {
    if (!this._isSend) {
      return this._repo
        .requestCode()
        .then((data) => {
          this._isSend = true;

          return data;
        })
        .catch(() => {
          this._isSend = false;
        });
    }
  }

  loadStart() {
    this._isLoading = true;
  }

  setSuccess() {
    this._isLoading = false;
    this._isSuccess = true;
  }

  setError(errorMessage: string) {
    this._isLoading = false;
    this._error = errorMessage;
  }

  verifyCode(code: string) {
    return this._repo.verifyCode(code);
  }

  async sendCodeToPhone(phone: string) {
    await this._repo.sendCodeToPhone(phone);
  }
  async getPhone() {
    const {phone} = await this._repo.getUserInfo();
    this._phone = phone;
  }
}

export {ProfileVerificationService};
