import type {MedicationsFrequenciesTypesEnum, MedicationsUnitsTypesEnum} from '../../model';

interface IMedicationsCourseCommonGetResponseDto {
  medicationCourseId: number;
  patientId: number;
  startDate: string;
  endDate: string;
  dose: number;
  unit: MedicationsUnitsTypesEnum;
  frequency: MedicationsFrequenciesTypesEnum;
  frequencyOther: string;
  createdBy: string;
  created: string;
  createdById: number;
  medicationClass: null;
  isDiscontinued: boolean;
  discontinuedDate: string | null;
  reason: number;
  reasonOther: string;
  discontinuedBy: string;
  lastUpdated: string;
  updatedBy: string;
  updatedById: number;
}

interface IMedicationsCourseDefaultMedicationGetResponseDto extends IMedicationsCourseCommonGetResponseDto {
  medicationId: number;
  medicationBrandName: string;
  medicationGenericName: string;
  medicationName: string;
}

interface IMedicationsCourseCustomMedicationGetResponseDto extends IMedicationsCourseCommonGetResponseDto {
  customMedicationId: number;
  customMedicationName: string;
}

type IMedicationsCourseGetResponseDto =
  | IMedicationsCourseDefaultMedicationGetResponseDto
  | IMedicationsCourseCustomMedicationGetResponseDto;

const isMedicationsCourseCustomMedicationGetResponseDto = (
  dto: IMedicationsCourseGetResponseDto,
): dto is IMedicationsCourseCustomMedicationGetResponseDto =>
  'customMedicationId' in dto && dto.customMedicationId !== null;

export {isMedicationsCourseCustomMedicationGetResponseDto};
export type {IMedicationsCourseGetResponseDto};
