import type {ISurveySpecElement, SurveySpecModel} from './survey-spec.model';
import type {ISurveySpecElementGetResponseDto, ISurveySpecGetResponseDto} from '../repository';

class SurveySpecModelFactory {
  static fromSpecDto(dto: ISurveySpecGetResponseDto): SurveySpecModel {
    return {
      surveyId: dto.surveyId,
      title: dto.title,
      surveyType: dto.surveyType,
      elements: dto.elements.map((element) => SurveySpecModelFactory.getSurveyElementFromDtoElement(element)),
    };
  }

  private static getSurveyElementFromDtoElement(dtoElement: ISurveySpecElementGetResponseDto): ISurveySpecElement {
    return {
      elementId: dtoElement.elementId,
      surveyId: dtoElement.surveyId,
      name: dtoElement.name,
      text: dtoElement.text,
      elementType: dtoElement.elementType,
      displayOrder: dtoElement.displayOrder,
      isRequired: dtoElement.isRequired,
      visibleIf: dtoElement.visibleIf,
      disabledIf: dtoElement.disabledIf,
      metadata: dtoElement.metadata,
      options: dtoElement.options,
      notProvidedIf: dtoElement.notProvidedIf,
      subElements: dtoElement.subElements.map((element) =>
        SurveySpecModelFactory.getSurveyElementFromDtoElement(element),
      ),
    };
  }
}

export {SurveySpecModelFactory};
