import type {FC} from 'react';
import React, {useMemo} from 'react';
import styles from './card.module.scss';
import classNames from 'classnames';

interface ICardProps {
  className?: string;
  chipText?: string;
  onClick?: () => void;
  onClickCapture?: () => void;
  onKeyPress?: (event: any) => void;
  borderLess?: boolean;
  color?: CardColorEnum | string;
  border?: CardBorderDirectionEnum | string;
  tabIndex?: number;
  role?: string;
  isLesson?: boolean;
}

enum CardColorEnum {
  Danger = 'Danger',
  Success = 'Success',
  Secondary = 'Secondary',
  Background = 'Background',
  LightYellow = 'LightYellow',
  GreySecondary = 'GreySecondary',
}

enum CardBorderDirectionEnum {
  Top = 'top',
  Left = 'left',
  Right = 'right',
  Bottom = 'bottom',
}

const Card: FC<ICardProps> = (props) => {
  const chip = useMemo(
    () => (
      <>
        {!!props.chipText && (
          <div className={styles.chip}>
            <span className={styles.chipText}>{props.chipText}</span>
          </div>
        )}
      </>
    ),
    [props.chipText],
  );

  return (
    <div
      className={classNames(styles.root, props.className, {
        [styles.borderLess]: props.borderLess,
        [styles.borderTop]: props.border === CardBorderDirectionEnum.Top,
        [styles.borderLeft]: props.border === CardBorderDirectionEnum.Left,
        [styles.borderRight]: props.border === CardBorderDirectionEnum.Right,
        [styles.borderBottom]: props.border === CardBorderDirectionEnum.Bottom,
        [styles.chipped]: !!props.chipText,
        [styles.colorBackground]: props.color === CardColorEnum.Background,
        [styles.colorDanger]: props.color === CardColorEnum.Danger,
        [styles.colorSecondary]: props.color === CardColorEnum.Secondary,
        [styles.colorGreySecondary]: props.color === CardColorEnum.GreySecondary,
        [styles.colorSuccess]: props.color === CardColorEnum.Success,
        [styles.colorLightYellow]: props.color === CardColorEnum.LightYellow,
        [styles.borderLessonColor]: props.isLesson,
        [styles.borderTaskColor]: props.isLesson === false,
      })}
      onClickCapture={props.onClickCapture}
      onClick={props.onClick}
      onKeyPress={props.onKeyPress}
      tabIndex={props.tabIndex}
      role={props.role}>
      {chip}
      {props.children}
    </div>
  );
};

export {Card, CardColorEnum, CardBorderDirectionEnum};
export type {ICardProps};
