import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styles from './profile-health-insurance.module.scss';
import {Patterns} from '../../../../../helpers/patterns';
import {getClearedPhoneValue, phoneFormatter} from '../../../../../helpers';
import {Button, Card, CardContent, Input, MaskedInput} from '../../../../../components';
import type {AccountModel} from '../../../../../account';
import {TwoFactorAuthForm} from '../two-factor-auth';
import {isEqual, pick} from 'lodash';

interface IProfileHealthInsuranceForm {
  insuranceCompany: string;
  insurancePhone: string;
  plan: string;
  memberId: string;
  verificationCode: string;
}

interface IProfileHealthInsuranceProps {
  onSubmit: (data: IProfileHealthInsuranceForm) => void;
  patientPersonalInfo: AccountModel | null;
  isProfileFormSaving?: boolean;
  requestCode(): Promise<void>;
}

const ProfileHealthInsurance: FC<IProfileHealthInsuranceProps> = ({
  onSubmit,
  patientPersonalInfo,
  isProfileFormSaving,
  requestCode,
}) => {
  const defaultValues = useMemo(
    () => ({
      insuranceCompany: patientPersonalInfo?.insuranceCompany ?? '',
      memberId: patientPersonalInfo?.memberId ?? '',
      plan: patientPersonalInfo?.plan ?? '',
      insurancePhone: patientPersonalInfo?.insurancePhone ?? '',
    }),
    [patientPersonalInfo],
  );

  const {
    control,
    errors,
    handleSubmit,
    reset,
    formState: {isSubmitSuccessful},
    getValues,
  } = useForm<IProfileHealthInsuranceForm>({
    defaultValues,
  });

  const [isEditMode, setEditMode] = useState(false);
  const [submittedData, setSubmittedData] = useState({});
  const [visibleTwoFactorModal, setVisibleTwoFactorModal] = useState(false);

  const visibleTwoFactorModalHandler = useCallback(
    async (isOpen?: boolean) => {
      isOpen && (await requestCode());
      setVisibleTwoFactorModal(!visibleTwoFactorModal);
    },
    [requestCode, visibleTwoFactorModal],
  );

  const onProfileHealthInsuranceSubmit = (data: IProfileHealthInsuranceForm) => {
    isEqual(pick(defaultValues, Object.keys(data)), data) ? setEditMode(false) : visibleTwoFactorModalHandler(true);
  };

  const submitHandler = (code: string) => {
    const data = getValues();
    setSubmittedData(data);
    onSubmit({
      ...data,
      verificationCode: code,
      insurancePhone: getClearedPhoneValue(data.insurancePhone) ?? '',
    });
    setEditMode(false);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({...submittedData});
    }
  }, [isSubmitSuccessful, submittedData, reset]);

  const actions = useMemo(
    () => (
      <div className={styles.actions}>
        {isEditMode ? (
          <>
            <div className={styles.cancel} onClick={() => setEditMode(false)}>
              Cancel
            </div>
            <Button className={styles.button} type={'submit'} block>
              Save Profile
            </Button>
          </>
        ) : (
          <Button className={styles.button} block onClick={() => setEditMode(true)} isLoading={isProfileFormSaving}>
            Edit Profile
          </Button>
        )}
      </div>
    ),
    [isEditMode, isProfileFormSaving],
  );

  return (
    <Card>
      <form onSubmit={handleSubmit(onProfileHealthInsuranceSubmit)}>
        {actions}
        <CardContent gap={0}>
          <div className={styles.fields}>
            <div className={styles.field}>
              <div className={styles.label}>Insurance Company</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="insuranceCompany"
                    control={control}
                    render={({onChange, value, name}) => (
                      <Input
                        className={styles.inputField}
                        name={name}
                        onValueChanged={onChange}
                        value={value}
                        isValid={!errors.insuranceCompany}
                      />
                    )}
                  />
                ) : (
                  <>{patientPersonalInfo?.insuranceCompany ?? ''}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Plan</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="plan"
                    control={control}
                    render={({onChange, value, name}) => (
                      <Input
                        className={styles.inputField}
                        name={name}
                        onValueChanged={onChange}
                        value={value}
                        isValid={!errors.plan}
                      />
                    )}
                  />
                ) : (
                  <>{patientPersonalInfo?.plan ?? ''}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Member ID</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="memberId"
                    control={control}
                    render={({onChange, value, name}) => (
                      <Input
                        className={styles.inputField}
                        name={name}
                        onValueChanged={onChange}
                        value={value}
                        isValid={!errors.memberId}
                      />
                    )}
                  />
                ) : (
                  <>{patientPersonalInfo?.memberId ?? ''}</>
                )}
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Insurance Phone</div>
              <div className={styles.value}>
                {isEditMode ? (
                  <Controller
                    name="insurancePhone"
                    control={control}
                    rules={{
                      pattern: {
                        value: Patterns.PhoneFormat,
                        message: 'Please enter a valid phone number',
                      },
                    }}
                    render={({onChange, value, name}) => (
                      <MaskedInput
                        className={styles.inputField}
                        mask={Patterns.PhoneMask}
                        name={name}
                        onValueChanged={onChange}
                        value={value?.startsWith('+1') ? value : `+1-${value}`}
                        isValid={!errors.insurancePhone}
                        errorMessage={!!errors.insurancePhone ? errors.insurancePhone.message : ''}
                      />
                    )}
                  />
                ) : (
                  <>
                    {(patientPersonalInfo?.insurancePhone && phoneFormatter(patientPersonalInfo?.insurancePhone)) || ''}
                  </>
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </form>
      <TwoFactorAuthForm
        onClose={visibleTwoFactorModalHandler}
        onSubmit={submitHandler}
        variant={'simple'}
        isVisible={visibleTwoFactorModal}
      />
    </Card>
  );
};

export {ProfileHealthInsurance};
export type {IProfileHealthInsuranceForm, IProfileHealthInsuranceProps};
