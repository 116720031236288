import styles from './onboarding-video-layout.module.scss';
import type {FC} from 'react';
import React, {useEffect} from 'react';
import {Footer} from '../private-layout/components';
import type {ICompanyService} from '../../company';
import {COMPANY_SERVICE} from '../../company';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import {Image} from '../../components';
import {LogoImage} from '../../assets/logo';

interface IServiceProps {
  company: ICompanyService;
}

const OnboardingVideoLayout: FC<IServiceProps> = ({company, children}) => {
  useEffect(() => {
    company.load();
  }, [company]);

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <Image className={styles.logo} src={LogoImage} alt="trellus" />
      </header>
      <div className={styles.main}>{children}</div>
      <Footer
        className={styles.footer}
        contactEmail={company?.companyInfo.contactEmail}
        contactPhone={company?.companyInfo.contactPhone}
        facebookLink={company?.companyInfo.facebookLink}
        twitterLink={company?.companyInfo.twitterLink}
        instagramLink={company?.companyInfo.instagramLink}
        linkedInLink={company?.companyInfo.linkedInLink}
        tikTokLink={company?.companyInfo.tikTokLink}
        youTubeLink={company?.companyInfo.youTubeLink}
        copyRight={company?.companyInfo.copyRight}
        appVersion={company?.companyInfo.informationalVersion}
        disclaimer={company?.companyInfo.disclaimer}
        light
        onBoarding
      />
    </div>
  );
};

const OnboardingVideoLayoutObserver = observer(OnboardingVideoLayout);

const OnboardingVideoLayoutInjected: FC = (props) => (
  <OnboardingVideoLayoutObserver {...props} company={useInjection(COMPANY_SERVICE)} />
);

export {OnboardingVideoLayoutInjected as OnboardingVideoLayout};
