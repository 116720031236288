import type {FC} from 'react';
import React, {useMemo} from 'react';
import classNames from 'classnames';
import styles from './chat-message.module.scss';
import {Card, CardContent, DateWrapper, RichTextComponent} from '../../../../../components';
import {ChatMessageAttachment} from '../chat-message-attachment';
import {TIME_FORMAT} from '../../../../../helpers';
import type {MessageModel} from '../../../../../message';
import {MessageDeliveryStatus, MessageStatus} from '../../../../../message';
import {MessageDeliveredIcon, MessageReadIcon, MessageSentIcon} from '../../../../../assets/icons';

interface IChatMessageProps {
  isRecipient: boolean;
  message: MessageModel;
}

const ChatMessage: FC<IChatMessageProps> = (props) => {
  const attachments = useMemo(
    () =>
      props.message.attachments?.map((attachment) => (
        <ChatMessageAttachment key={attachment.id} attachment={attachment} isRecipient={props.isRecipient} />
      )),
    [props.isRecipient, props.message.attachments],
  );

  const StatusIcon = useMemo(() => {
    if (props.isRecipient) {
      return null;
    } else if (props.message.readStatus === MessageStatus.Read) {
      return MessageReadIcon;
    } else if (props.message.deliveryStatus === MessageDeliveryStatus.Delivered) {
      return MessageDeliveredIcon;
    } else {
      return MessageSentIcon;
    }
  }, [props.isRecipient, props.message.deliveryStatus, props.message.readStatus]);

  const authorInfo = useMemo(
    () => (
      <>
        {props.message.firstName ? (
          <p className={styles.authorInfo}>
            {props.message.providerType
              ? `${props.message.firstName} (${props.message.providerType})`
              : props.message.firstName}
          </p>
        ) : null}
      </>
    ),
    [props.message.firstName, props.message.providerType],
  );

  const message = useMemo(
    () =>
      !!props.message.text.length && (
        <Card className={classNames(props.isRecipient ? styles.wrapperRecipient : styles.wrapper)} borderLess>
          <CardContent className={classNames(props.isRecipient ? styles.contentRecipient : styles.content)}>
            {StatusIcon && <StatusIcon className={styles.icon} />}
            {authorInfo}
            <p className={styles.date}>
              <DateWrapper date={props.message.createdAt} format={TIME_FORMAT} />
            </p>
            <p className={styles.text}>
              {props.message.isAutoReply ? (
                <i>
                  <RichTextComponent text={props.message.text} />
                </i>
              ) : (
                <RichTextComponent text={props.message.text} />
              )}
            </p>
          </CardContent>
        </Card>
      ),
    [StatusIcon, authorInfo, props.isRecipient, props.message.createdAt, props.message.isAutoReply, props.message.text],
  );

  return (
    <>
      {message}
      {attachments}
    </>
  );
};

export {ChatMessage};
