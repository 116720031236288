interface IDataPdf {
  content: string;
  fileType: string;
}

const FILE_TYPE_DEFAULT = 'application/pdf';

const pdfConverter = (dataPDF: IDataPdf): string | null => {
  const byteArray = Uint8Array.from(
    atob(dataPDF.content)
      .split('')
      .map((char) => char.charCodeAt(0)),
  );

  const blob = new Blob([byteArray], {type: dataPDF.fileType || FILE_TYPE_DEFAULT});

  return blob ? URL.createObjectURL(blob) : null;
};

export {pdfConverter};
export type {IDataPdf};
