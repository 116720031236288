import React from 'react';
import type {FC, MouseEvent} from 'react';
import {CheckMarkCircleEmpty, CheckMarkSelected} from '../../../../assets/icons';
import styles from './notification-select.module.scss';

interface INotificationSelectProps {
  isSelected: boolean;
  changeSelect: () => void;
}

const NotificationSelect: FC<INotificationSelectProps> = ({isSelected, changeSelect}) => {
  const checkMarkClickHandler = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    changeSelect();
  };

  return (
    <div className={styles.icon} onClick={checkMarkClickHandler}>
      {isSelected ? <CheckMarkSelected /> : <CheckMarkCircleEmpty />}
    </div>
  );
};

export {NotificationSelect};
