import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import {levelMapping} from '../../helper/levelsConfig';
import type {
  ICreateGoalWorksheetModel,
  IGoal,
  IGoalWorksheetForm,
  IPopUpReviewWorkflow,
  IUpdateReviewWorkflow,
  IWorksheet,
} from '../../interfaces/goal.interface';
import {ELevelGoal} from '../../interfaces/goal.interface';
import type {CreateGoalWorksheetModel, GoalModel, WorksheetModel} from '../../model/goal/goal.model';
import {GoalModelFactory} from '../../model/goal/goal.model.factory';
import type {IGoalRepository} from '../../repositoty/goal/goal.repository.interface';
import {GOAL_REPOSITORY} from '../../repositoty/goal/goal.repository.provider';
import type {IGoalService} from './goal.service.interface';

@injectable()
class GoalService implements IGoalService {
  constructor(@inject(GOAL_REPOSITORY) private readonly _repo: IGoalRepository) {
    makeAutoObservable(this);
  }

  private _reviewWorkflow: IPopUpReviewWorkflow = {} as IPopUpReviewWorkflow;
  private _archivedWorksheets: WorksheetModel[] = [];
  private _goal: GoalModel = {} as GoalModel;
  private _goalWorksheet: CreateGoalWorksheetModel = {} as CreateGoalWorksheetModel;
  private _loading = false;
  private _loadingWorksheet = false;
  private _totalScore = 0;
  private _unArchivedWorksheets: WorksheetModel[] = [];
  private _worksheet: WorksheetModel = {} as WorksheetModel;
  private _worksheets: WorksheetModel[] = [];

  get isLoading(): boolean {
    return this._loading;
  }

  get isLoadingWorksheet(): boolean {
    return this._loadingWorksheet;
  }

  get reviewWorkflow(): IPopUpReviewWorkflow {
    return this._reviewWorkflow;
  }

  set reviewWorkflow(value: IPopUpReviewWorkflow) {
    this._reviewWorkflow = value;
  }

  get worksheet(): IWorksheet {
    return this._worksheet;
  }

  set worksheet(worksheet: IWorksheet) {
    this._worksheet = worksheet;
  }

  get worksheets(): IWorksheet[] {
    return this._worksheets;
  }

  get archivedWorksheets(): IWorksheet[] {
    return this._archivedWorksheets;
  }

  get unArchivedWorksheets(): IWorksheet[] {
    return this._unArchivedWorksheets;
  }

  get goalWorksheet(): ICreateGoalWorksheetModel {
    return this._goalWorksheet;
  }

  get goal(): IGoal {
    return this._goal;
  }

  get totalScore(): number {
    return this._totalScore;
  }

  get level(): ELevelGoal {
    if (
      this._totalScore >= levelMapping[ELevelGoal.HARD].minScore &&
      this._totalScore <= levelMapping[ELevelGoal.HARD].maxScore
    ) {
      return ELevelGoal.HARD; // Hard goal, Level 3 (Pink flower)
    } else if (
      this._totalScore >= levelMapping[ELevelGoal.MEDIUM].minScore &&
      this._totalScore <= levelMapping[ELevelGoal.MEDIUM].maxScore
    ) {
      return ELevelGoal.MEDIUM; // Medium goal, Level 2 (Yellow flower)
    } else if (
      this._totalScore >= levelMapping[ELevelGoal.EASY].minScore &&
      this._totalScore <= levelMapping[ELevelGoal.EASY].maxScore
    ) {
      return ELevelGoal.EASY; // Easy goal, Level 1 (Blue flower)
    }

    return ELevelGoal.UNKNOWN;
  }

  checkLevel(goalLevel: number): ELevelGoal {
    if (goalLevel >= levelMapping[ELevelGoal.HARD].minScore && goalLevel <= levelMapping[ELevelGoal.HARD].maxScore) {
      return ELevelGoal.HARD; // Hard goal, Level 3 (Pink flower)
    } else if (
      goalLevel >= levelMapping[ELevelGoal.MEDIUM].minScore &&
      goalLevel <= levelMapping[ELevelGoal.MEDIUM].maxScore
    ) {
      return ELevelGoal.MEDIUM; // Medium goal, Level 2 (Yellow flower)
    } else if (
      goalLevel >= levelMapping[ELevelGoal.EASY].minScore &&
      goalLevel <= levelMapping[ELevelGoal.EASY].maxScore
    ) {
      return ELevelGoal.EASY; // Easy goal, Level 1 (Blue flower)
    }

    return ELevelGoal.UNKNOWN;
  }

  clearGoals(): void {
    this._goal = {} as GoalModel;
    this._goalWorksheet = {} as CreateGoalWorksheetModel;
  }

  async loadPossibleGoals(courseId: number): Promise<void> {
    try {
      this._loading = true;
      this._goal = await this._repo.getPossibleGoals(courseId);
      this._loading = false;
    } catch (err) {
      this._loading = false;
      console.error(err);
    }
  }

  async createWorksheet(goalWorksheet: IGoalWorksheetForm): Promise<boolean> {
    try {
      const createGoalWorksheetModel = GoalModelFactory.fromWorksheetFormDto(goalWorksheet);
      await this._repo.create(createGoalWorksheetModel);

      this._totalScore = goalWorksheet.totalScore;
      this._goalWorksheet = createGoalWorksheetModel;

      return true;
    } catch (err) {
      console.error(err);

      return false;
    }
  }

  async loadWorksheets(): Promise<void> {
    try {
      this._loadingWorksheet = true;
      this._worksheets = await this._repo.getWorksheets();
      this._unArchivedWorksheets = this._worksheets.filter((worksheet) => !worksheet.isArchived);
      this._archivedWorksheets = this._worksheets.filter((worksheet) => worksheet.isArchived);
      this._loadingWorksheet = false;
    } catch (err) {
      this._loadingWorksheet = false;
      console.error(err);
    }
  }

  async updateWorksheet(worksheet: IGoalWorksheetForm): Promise<boolean> {
    try {
      const updateGoalWorksheetModel = GoalModelFactory.fromWorksheetFormDto(worksheet);
      this._loadingWorksheet = true;
      await this._repo.update({...this._worksheet, ...updateGoalWorksheetModel});
      this._loadingWorksheet = false;

      return true;
    } catch (err) {
      this._loadingWorksheet = false;
      console.error(err);

      return false;
    }
  }

  async updateReviewWorkflow(reviewWorkflow: IUpdateReviewWorkflow): Promise<boolean> {
    try {
      const updateGoalWorksheetModel = GoalModelFactory.fromReviewWorkflowFormDto(reviewWorkflow);
      await this._repo.updateReviewWorkflow(updateGoalWorksheetModel);

      return true;
    } catch (err) {
      console.error(err);

      return false;
    }
  }
}

export {GoalService};
