import type {IInputProps} from '../input';
import {Input} from '../input';
import React, {forwardRef} from 'react';
import {Patterns} from '../../helpers/patterns';
import {Error} from '../error';
import styles from './input-number.module.scss';
import classNames from 'classnames';

enum InputNumberTypes {
  Integer = 0,
  Decimal = 1,
  DecimalWith3Digits = 2,
}

interface IInputNumberProps extends IInputProps {
  onChange: (value: string) => void;
  errorMessage?: string;
  numberInputType: InputNumberTypes;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

const InputNumber = forwardRef<HTMLInputElement, IInputNumberProps>((props, ref) => {
  const onInputNumberChange = (value: string) => {
    const maxValueCheck = props.maxValue && value !== '' ? +value <= props.maxValue : true;
    const minValueCheck = props.minValue && value !== '' ? +value >= props.minValue : true;
    if (
      (maxValueCheck &&
        minValueCheck &&
        ((props.numberInputType === InputNumberTypes.Integer && Patterns.Number.test(value)) ||
          (props.numberInputType === InputNumberTypes.Decimal && Patterns.DecimalNumber.test(value)) ||
          (props.numberInputType === InputNumberTypes.DecimalWith3Digits &&
            Patterns.DecimalNumberWith3Digits.test(value)))) ||
      value === ''
    ) {
      props.onChange(value);
    }
  };

  const getValue = (value: string): string => {
    let matchedValuesArray;
    if (props.numberInputType === InputNumberTypes.Decimal) {
      matchedValuesArray = value.match(Patterns.DecimalNumber);
    } else if (props.numberInputType === InputNumberTypes.DecimalWith3Digits) {
      matchedValuesArray = value.match(Patterns.DecimalNumberWith3Digits);
    } else {
      matchedValuesArray = value.match(Patterns.Number);
    }

    return (matchedValuesArray && matchedValuesArray[0]) ?? '';
  };

  return (
    <div className={classNames(styles.root, props.className)}>
      <Input
        {...props}
        name={props.name}
        ariaLabelTitle={props.ariaLabelTitle}
        ariaLabelError={props.ariaLabelError}
        ref={ref}
        value={getValue(String(props.value) ?? '')}
        isValid={props.isValid && !props.errorMessage}
        onValueChanged={onInputNumberChange}
        data-testid={`input-number-${props.name}`}
        disabled={props.disabled}
        minValue={props.minValue}
        maxValue={props.maxValue}
      />
      {!!props.errorMessage && <Error errorMessage={props.errorMessage} />}
    </div>
  );
});

export {InputNumber, InputNumberTypes};
export type {IInputNumberProps};
