import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import styles from './thrive-skills-selection.module.scss';
import {Button, PageLoader, PageTitle} from '../../../components';
import {useInjection} from '../../../ioc';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {CheckablePanel} from '../../../components/checkable-panel';
import {THRIVE_SKILL_SERVICE} from '../../../thrive-skill/service';
import type {IThriveSkillService} from '../../../thrive-skill/service';
import {useQuery} from '../../../hooks';

interface IProps {
  thriveSkill: IThriveSkillService;
}

interface ISkillOption {
  value: boolean;
  id: number;
}

const thriveSkillsFieldName = 'thrive-skill';

const ThriveSkillsSelectionScreen: FC<IProps> = ({thriveSkill}) => {
  const skillsValues = useMemo(() => thriveSkill?.thriveSkills, [thriveSkill?.thriveSkills]);
  const [isSubmitAvaliable, setIsSubmitAvailable] = useState(false);
  const [isCheckDisabled, setIsCheckDisabled] = useState(false);

  const methods = useForm({});

  const {handleSubmit, control, watch, setValue} = methods;

  const {fields} = useFieldArray({
    control,
    name: thriveSkillsFieldName,
  });

  const {id: patientCourseId} = useQuery<{id: number}>();

  useEffect(() => {
    thriveSkill?.loadThriveSkillsOptions(patientCourseId);
  }, [thriveSkill, patientCourseId]);

  useEffect(() => {
    setValue(
      thriveSkillsFieldName,
      skillsValues?.map((item) => ({[item.id]: item.value})),
    );
    const checkedLength = skillsValues?.filter((item) => item.value)?.length;
    if (checkedLength > 0 && checkedLength <= 3) {
      setIsSubmitAvailable(true);
      if (checkedLength === 3) {
        setIsCheckDisabled(true);
      }
    }
  }, [skillsValues, setValue]);

  const onSubmit = () => {
    const result = (watch(thriveSkillsFieldName) as unknown as ISkillOption[])
      ?.filter((item) => item.value)
      ?.map((item) => item.id);
    thriveSkill.updateSkills(result);
  };

  const onCheck = useCallback(() => {
    const selectedSkillsLength = (watch(thriveSkillsFieldName) as unknown as ISkillOption[])?.filter(
      (item) => item.value,
    )?.length;
    const result = selectedSkillsLength > 0 && selectedSkillsLength <= 3;
    setIsSubmitAvailable(result);
    setIsCheckDisabled(selectedSkillsLength === 3 ? true : false);
  }, [watch]);

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <PageTitle>Thrive Essentials</PageTitle>
      </div>
      {thriveSkill?.isOptionsListLoading ? (
        <PageLoader />
      ) : (
        <div className={styles.sectionContent}>
          <div className={styles.sectionDescription}>Select up to 3</div>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.skillForm}>
            <div className={styles.skillsContainer}>
              {fields?.map((skill, index) => (
                <div key={skill.id} className={styles.skill}>
                  <Controller
                    name={`${thriveSkillsFieldName}.${index}.value`}
                    control={control}
                    defaultValue={skillsValues[index]?.value}
                    key={`${skill.id}-value`}
                    render={({name, value, onChange}) => (
                      <>
                        <div>{value}</div>
                        <div className={styles.checkboxContainer}>
                          <CheckablePanel
                            id={name}
                            value={value}
                            name={name}
                            label={skillsValues[index]?.name}
                            onChange={onChange}
                            callback={onCheck}
                            labelClassName={styles.skillLabel}
                            disabled={isCheckDisabled && value === false}
                          />
                        </div>
                      </>
                    )}
                  />
                  <Controller
                    name={`${thriveSkillsFieldName}.${index}.id`}
                    control={control}
                    key={`${skill.id}-id`}
                    defaultValue={skillsValues[index]?.id}
                    render={() => <></>}
                  />
                </div>
              ))}
            </div>
            <Button type={'submit'} testid="submit-button" disabled={!isSubmitAvaliable}>
              Save
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

const ObservableThriveSkillsSelectionScreen = observer(ThriveSkillsSelectionScreen);

const ThriveSkillsSelectionScreenInjected: FC = () => (
  <ObservableThriveSkillsSelectionScreen thriveSkill={useInjection(THRIVE_SKILL_SERVICE)} />
);

export {ThriveSkillsSelectionScreenInjected as ThriveSkillsSelectionScreen};
