import type {IHealthMonitorService} from '../../service';
import {HEALTH_MONITOR_SERVICE} from '../../service';
import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useInjection} from '../../../ioc';
import type {HealthMonitorGraphTypes, HealthMonitorModel} from '../../model';
import {HealthMonitorPeriodTypes} from '../../model';
import styles from './health-monitor.module.scss';
import type {AxisDataType, ChartAxisDataType, ExtendedAxisDataType} from '../../options';
import {chartAxisData, healthMonitorPeriodOptions} from '../../options';
import classNames from 'classnames';
import {Card, PageLoader} from '../../../components';
import {add, differenceInMilliseconds, differenceInWeeks, intervalToDuration, isAfter, sub} from 'date-fns';
import {
  DropdownActivatorBehaviorType,
  DropdownPositionXEnum,
  DropdownPositionYEnum,
  useDropdown,
} from '../../../components/dropdown';
import {ChevronLeftIcon} from '../../../assets/icons';
import OutsideClickHandler from 'react-outside-click-handler';
import {HealthMonitorNavigationBar} from '../navigation-bar';
import {getYAxisLabelMargin} from '../line-chart/get-y-axis-label-margin';
import ReactResizeDetector from 'react-resize-detector';
import {HealthMonitorLineChart} from '../line-chart';
import type {
  HealthMonitorFatigueLabels,
  HealthMonitorMyLifeIsGoingWellLabels,
  HealthMonitorPhysicalHealthLabels,
} from '../line-chart/enums';
import {healthMonitorChartLabels} from './health-monitor-labels';
import {observer} from 'mobx-react';

const X_LABELS_NUMBER = window.innerWidth >= 1920 ? 12 : 9;
const NO_RESULT_MESSAGE_DEFAULT = 'Looks like we don’t have any prior record of your data.';

interface IProps {
  patientId: number;
  patientSpecialty: string;
}

interface IServiceProps {
  healthMonitorService: IHealthMonitorService;
}

const cutDate = (date?: Date): Date | undefined =>
  !!date ? sub(date, {hours: date.getHours(), minutes: date.getUTCMinutes(), seconds: date.getSeconds()}) : date;

const getSpecificChartDataY = (
  optionTabValue: HealthMonitorGraphTypes,
  chartData: ChartAxisDataType,
):
  | AxisDataType
  | ExtendedAxisDataType<
      HealthMonitorMyLifeIsGoingWellLabels | HealthMonitorFatigueLabels | HealthMonitorPhysicalHealthLabels
    > => chartData[optionTabValue].Y;

const HealthMonitor: FC<IProps & IServiceProps> = ({patientId, patientSpecialty, healthMonitorService}) => {
  const [switchGraphType, setSwitchGraphType] = useState<string>();
  const [graphType, setGraphType] = useState<HealthMonitorGraphTypes>();

  const [period, setPeriod] = useState<HealthMonitorPeriodTypes>(HealthMonitorPeriodTypes.Month);

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [yAxisLabel, setYAxisLabel] = useState<string | undefined>();

  const [chartData, setChartData] = useState<HealthMonitorModel | null>(null);
  const [specificChartData, setSpecificChartData] = useState<
    | AxisDataType
    | ExtendedAxisDataType<
        HealthMonitorMyLifeIsGoingWellLabels | HealthMonitorFatigueLabels | HealthMonitorPhysicalHealthLabels
      >
  >();

  const {Dropdown, isOpenDropdown, closeDropdown} = useDropdown();

  const setDates = useCallback((formattedStartDate?: Date, formattedEndDate?: Date): void => {
    const endDate = formattedEndDate ?? new Date();
    const startDate = formattedStartDate ?? new Date();

    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  const switchGraphTypeDefault = useMemo(
    () => healthMonitorService.navigationList.find((i) => i.selectedByDefault),
    [healthMonitorService.navigationList],
  );

  const graphTypeDefault = useMemo(
    () =>
      healthMonitorService.navigationList
        .find((i) => i.displayName === switchGraphTypeDefault?.displayName)
        ?.subElements.find((i) => i.selectedByDefault),
    [healthMonitorService.navigationList, switchGraphTypeDefault],
  );

  useEffect(() => {
    healthMonitorService.loadNavigationList();

    if (switchGraphTypeDefault?.displayName && graphTypeDefault?.value) {
      setSwitchGraphType(switchGraphTypeDefault.displayName);
      setGraphType(graphTypeDefault.value);
      setSpecificChartData(getSpecificChartDataY(graphTypeDefault.value, chartAxisData(patientSpecialty)));
    }
  }, [graphTypeDefault, healthMonitorService, patientSpecialty, switchGraphTypeDefault]);

  useEffect(() => {
    if (graphType) {
      healthMonitorService.load(patientId, graphType).then(() => {
        setChartData(healthMonitorService.data);
        setSpecificChartData(getSpecificChartDataY(graphType, chartAxisData(patientSpecialty)));
        setYAxisLabel(healthMonitorChartLabels.get(graphType));
      });
    }
  }, [graphType, healthMonitorService, patientId, patientSpecialty, period]);

  useEffect(() => {
    const points = chartData?.points.sort((p1, p2) => (differenceInMilliseconds(p1.x, p2.x) > 0 ? 1 : -1)) ?? [];

    const firstPoint = points[0];
    const lastPoint = points[points.length - 1];

    const isPointsRangeWider =
      firstPoint &&
      lastPoint &&
      isAfter(
        sub(lastPoint.x, {
          months: period === HealthMonitorPeriodTypes.Month ? X_LABELS_NUMBER - 1 : 0,
          weeks: period === HealthMonitorPeriodTypes.Week ? X_LABELS_NUMBER - 1 : 0,
        }),
        firstPoint.x,
      );

    if (points.length > 1 && isPointsRangeWider) {
      const lastDate = lastPoint.x;
      const formattedStartDate = cutDate(
        sub(lastDate, {
          months: period === HealthMonitorPeriodTypes.Month ? X_LABELS_NUMBER - 1 : 0,
          weeks: period === HealthMonitorPeriodTypes.Week ? X_LABELS_NUMBER - 1 : 0,
        }),
      );

      setDates(formattedStartDate, lastDate);
    } else if (firstPoint?.x && lastPoint?.x) {
      const diff = intervalToDuration({start: firstPoint.x, end: lastPoint.x});

      const formattedStartDate = sub(lastPoint.x, {
        months:
          period === HealthMonitorPeriodTypes.Month
            ? (diff.months || 0) + (diff.weeks || diff.days || diff.hours || diff.minutes ? 1 : 0)
            : 0,
        weeks:
          period === HealthMonitorPeriodTypes.Week
            ? differenceInWeeks(lastPoint.x, firstPoint.x) + (diff.days || diff.hours || diff.minutes ? 1 : 0)
            : 0,
      });

      const formattedEndDate = formattedStartDate
        ? cutDate(
            add(formattedStartDate, {
              months: period === HealthMonitorPeriodTypes.Month ? X_LABELS_NUMBER - 1 : 0,
              weeks: period === HealthMonitorPeriodTypes.Week ? X_LABELS_NUMBER - 1 : 0,
            }),
          )
        : undefined;

      setDates(formattedStartDate, formattedEndDate);
    }
  }, [chartData, period, setDates]);

  const onSwitchOptionClick = useCallback(
    (option) => {
      if (option.value !== switchGraphType) {
        setSwitchGraphType(option.value);

        let defaultGraphType = healthMonitorService.navigationList.find((i) => i.displayName === option.value);

        if (defaultGraphType?.subElements.length) {
          defaultGraphType = defaultGraphType.subElements.find((i) => i.selectedByDefault);
        }

        if (defaultGraphType?.value) {
          setGraphType(defaultGraphType.value);
        }
      }
    },
    [healthMonitorService.navigationList, switchGraphType],
  );

  const navigationBar = useMemo(() => {
    const options = healthMonitorService.navigationList.map((option) => ({
      value: option.displayName,
      displayName: option.displayName,
    }));

    if (switchGraphType) {
      return (
        <HealthMonitorNavigationBar
          key={switchGraphType}
          graphType={switchGraphType}
          onOptionClick={(option) => onSwitchOptionClick(option)}
          options={options}
        />
      );
    }

    return null;
  }, [healthMonitorService.navigationList, onSwitchOptionClick, switchGraphType]);

  const navigationSubBar = useMemo(
    () =>
      healthMonitorService.navigationList
        .filter((elementNav) => elementNav.displayName === switchGraphType)
        .map((elementNav) => {
          const options = elementNav.subElements
            .filter((option) => !!option.value)
            .map((option) => ({
              value: Number(option.value),
              displayName: option.displayName,
            }));

          if (graphType) {
            return (
              <HealthMonitorNavigationBar
                key={graphType}
                graphType={graphType}
                onOptionClick={(option) => {
                  if (option.value !== graphType) {
                    setGraphType(Number(option.value));
                  }
                }}
                options={options}
                isShifted
              />
            );
          }

          return null;
        }),

    [graphType, healthMonitorService.navigationList, switchGraphType],
  );

  const onOptionClick = useCallback(
    (option) => {
      if (option.value !== period) {
        setPeriod(option.value);
        closeDropdown();
      }
    },
    [closeDropdown, period],
  );

  const noResultMessage = useMemo(() => {
    if (chartData) {
      return (
        <>
          {chartData.absenceMessage ? (
            chartData.absenceMessage.split('\\n').map((text, index) => <p key={`${text}_${index}`}>{text}</p>)
          ) : (
            <p>{NO_RESULT_MESSAGE_DEFAULT}</p>
          )}
        </>
      );
    }
  }, [chartData]);

  const message = useMemo(
    () => (
      <div className={styles.noData}>
        {healthMonitorService.isLoading ? <PageLoader removeMargin /> : noResultMessage}
      </div>
    ),
    [healthMonitorService.isLoading, noResultMessage],
  );

  const chart = useMemo(
    () => (
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({width}) => (
          <div className={styles.chartContainer}>
            {!healthMonitorService.isLoading ? (
              chartData?.points?.length &&
              graphType &&
              specificChartData && (
                <HealthMonitorLineChart
                  yAxisLabel={yAxisLabel}
                  points={chartData?.points.sort((p1, p2) => (differenceInMilliseconds(p1.x, p2.x) > 0 ? 1 : -1)) ?? []}
                  lowerBorderLine={healthMonitorService.data?.lowerBorderLine}
                  upperBorderLine={healthMonitorService.data?.upperBorderLine}
                  startDate={startDate}
                  endDate={endDate}
                  tickRange={period}
                  xLabelsNumber={X_LABELS_NUMBER}
                  chartData={specificChartData}
                  dimensions={{
                    width: !!width ? width - getYAxisLabelMargin(graphType) - 20 : 0,
                    height: 400 + (yAxisLabel ? 30 : 0),
                    margins: {
                      top: 10,
                      right: 15,
                      bottom: 30,
                      left: getYAxisLabelMargin(graphType),
                    },
                  }}
                />
              )
            ) : (
              <div className={styles.loaderContainer}>
                <PageLoader removeMargin />
              </div>
            )}
          </div>
        )}
      />
    ),
    [
      chartData?.points,
      endDate,
      graphType,
      healthMonitorService.data?.lowerBorderLine,
      healthMonitorService.data?.upperBorderLine,
      healthMonitorService.isLoading,
      period,
      specificChartData,
      startDate,
      yAxisLabel,
    ],
  );

  const isShowDropdown = useMemo(
    () =>
      healthMonitorService.navigationList?.find((navItem) => navItem.displayName === switchGraphType)
        ?.periodFormatChange,
    [healthMonitorService.navigationList, switchGraphType],
  );

  return (
    <div>
      {navigationBar}
      <Card>
        {navigationSubBar}
        {isShowDropdown && (
          <div className={styles.dropdownArea}>
            <Dropdown
              activatorBehaviorType={DropdownActivatorBehaviorType.Click}
              positionX={DropdownPositionXEnum.Left}
              positionY={DropdownPositionYEnum.ReverseTop}
              activator={({onOpen}) => (
                <button className={styles.dropdownButton} onClick={onOpen}>
                  {healthMonitorPeriodOptions.find((option) => option.value === period)?.displayName}
                  <ChevronLeftIcon className={isOpenDropdown ? styles.chevronUp : styles.chevronDown} />
                </button>
              )}>
              <OutsideClickHandler onOutsideClick={closeDropdown}>
                <div className={styles.optionsContainer}>
                  {healthMonitorPeriodOptions.map((option) => (
                    <div
                      className={classNames(styles.option, {
                        [styles.optionInactive]: option.value === period,
                      })}
                      key={option.value}
                      onClick={() => onOptionClick(option)}>
                      {option.displayName}
                    </div>
                  ))}
                </div>
              </OutsideClickHandler>
            </Dropdown>
          </div>
        )}
        {!!healthMonitorService.data?.points?.length ? chart : message}
      </Card>
    </div>
  );
};

const HealthMonitorObserver = observer(HealthMonitor);

const InjectedHealthMonitor: FC<IProps> = (props) => (
  <HealthMonitorObserver {...props} healthMonitorService={useInjection(HEALTH_MONITOR_SERVICE)} />
);

export {InjectedHealthMonitor as HealthMonitor, cutDate};
