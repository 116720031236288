import type {IItemCoordinates} from '../../../model';
import {NumericalIndicator} from '../../../../components/numerical-indicator/numerical-indicator.component';

// Blue Flax flower
export const FlowerCourseCompletion1 = ({x, y, title, numericalIndicator}: IItemCoordinates) => (
  <svg data-testid="course-flower" viewBox="-42 -25 125 125" width="100" height="100" x={x - 43} y={y - 28}>
    <title>{title ?? 'Completed course'}</title>
    <use href="#flowerCourse1" />
    <NumericalIndicator numericalIndicator={numericalIndicator} x={13} y={-8} />
  </svg>
);

// Red Daisy flower
export const FlowerCourseCompletion2 = ({x, y, title, numericalIndicator}: IItemCoordinates) => (
  <svg data-testid="course-flower" viewBox="-42 -25 125 125" width="100" height="100" x={x - 43} y={y - 28}>
    <title>{title ?? 'Completed course'}</title>
    <use href="#flowerCourse2" />
    <NumericalIndicator numericalIndicator={numericalIndicator} x={13} y={-8} />
  </svg>
);

// Orange Dahlia flower
export const FlowerCourseCompletion3 = ({x, y, title, numericalIndicator}: IItemCoordinates) => (
  <svg data-testid="course-flower" viewBox="-42 -25 125 125" width="100" height="100" x={x - 43} y={y - 28}>
    <title>{title ?? 'Completed course'}</title>
    <use href="#flowerCourse3" />
    <NumericalIndicator numericalIndicator={numericalIndicator} x={13} y={-8} />
  </svg>
);

// White Daisy
export const FlowerSelfEfficacy = ({x, y, title}: IItemCoordinates) => <></>; // TODO: Ask for it

// Pink Dahlias
export const FlowerWeeklyWellness = ({x, y, title}: IItemCoordinates) => (
  <svg viewBox="-42 -25 125 125" width="100" height="100" x={x - 43} y={y - 28}>
    {/* TODO: TBD */}
  </svg>
);

// Sunflower
export const FlowerEndOfCategory = ({x, y, title, numericalIndicator = 0}: IItemCoordinates) => (
  <svg data-testid="end-of-category-flower" viewBox="-42 -25 125 125" width="100" height="100" x={x - 43} y={y - 28}>
    <title>{title ?? 'End of category'}</title>
    <use href="#sunFlower" />
    <NumericalIndicator numericalIndicator={numericalIndicator} x={16} y={-8} />
  </svg>
);

export const FlowerEndOfVine = ({x, y, title}: IItemCoordinates) => (
  <svg data-testid="end-of-vine-flower" viewBox="-42 -25 125 125" width="100" height="100" x={x - 43} y={y - 28}>
    <title>{title ?? 'End of eight months period'}</title>
    <use href="#flowerEndOfVine" />
  </svg>
);
