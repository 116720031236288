const TEL_LENGTH = 10;
const MAX_FILE_SIZE = 5242880;
const APPOINTMENT_MAX_DAY = 180;
const DAYS_IN_YEAR = 365;
const MONTH_IN_MILLISECONDS = 2678400000;
const DEFAULT_LANGUAGE_ID = 1;
const DEFAULT_COUNTRY_ID = 1;
const MEDICATION_DISCONTINUED_REASON_OTHER_ID = 4;
const DEFAULT_FORMAT = 'dd–MMM–yyyy';
const FULL_FORMAT = 'dd-MMM-yyyy, hh:mm a';
const DATE_FORMAT = 'MMMM yyyy';
const UPCOMING_DATE = 'dd MMMM';
const MERIDIEM_TIME_FORMAT = 'hh:mm a';
const DATE_API_FORMAT = 'yyyy-MM-dd';
const RIGHT_PANEL_APPOINTMENT_FORMAT = `dd MMMM'· 'p`;
const LESSON_COMPLETE_DATE = 'dd MMM yyyy';
const CLIENT_TYPE_DTC = 1;
const CLIENT_TYPE_B2B = 2;

const REQUIRED_FIELD_ERROR = 'This field is required';
const PATTERN_EMAIL_ERROR = 'Incorrect E-Mail format';
const PATTERN_USER_NAME_ERROR = "You can use only alphanumeric characters and @^$.!-#+`'~_";
const TIME_FORMAT = 'p';
const TRELLUS_LINK = 'https://content.trellus.app';
const NOT_PROVIDED_TEXT = 'Not provided';

const LOCATION_STORAGE_IS_FIRST_LOGIN_KEY = 'isFirstLogin';

const THRIVE_SKILL_ID = 62;
const FEATURED_CONTENT_SKILL_ID = 63;

export {
  APPOINTMENT_MAX_DAY,
  CLIENT_TYPE_DTC,
  DATE_API_FORMAT,
  DATE_FORMAT,
  DAYS_IN_YEAR,
  DEFAULT_COUNTRY_ID,
  DEFAULT_FORMAT,
  DEFAULT_LANGUAGE_ID,
  FEATURED_CONTENT_SKILL_ID,
  FULL_FORMAT,
  LESSON_COMPLETE_DATE,
  LOCATION_STORAGE_IS_FIRST_LOGIN_KEY,
  MAX_FILE_SIZE,
  MEDICATION_DISCONTINUED_REASON_OTHER_ID,
  MERIDIEM_TIME_FORMAT,
  MONTH_IN_MILLISECONDS,
  NOT_PROVIDED_TEXT,
  PATTERN_EMAIL_ERROR,
  PATTERN_USER_NAME_ERROR,
  REQUIRED_FIELD_ERROR,
  RIGHT_PANEL_APPOINTMENT_FORMAT,
  TEL_LENGTH,
  THRIVE_SKILL_ID,
  TIME_FORMAT,
  TRELLUS_LINK,
  UPCOMING_DATE,
  CLIENT_TYPE_B2B,
};
