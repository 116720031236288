import React from 'react';
import {Spinner} from '../../../components';
import style from './app-loader.module.scss';
import styles from '../../../components/page-loader/page-loader.module.scss';

const AppLoader = () => (
  <div className={style.root}>
    <Spinner />
    <span className={styles.text}>Loading, please wait</span>
  </div>
);

export {AppLoader};
