enum AssessmentQuestionType {
  Undefined = 'Undefined',
  Dropdown = 'DropDown',
  RadioButton = 'Radiobutton',
  DatePicker = 'DatePicker',
  Range = 'Range',
  Number = 'Number',
  FreeText = 'FreeText',
}

export {AssessmentQuestionType};
