interface VaccinationQuestion {
  question: string;
  value: string;
  dynamicSectionIndex: number | null;
}

class VaccinationModel {
  constructor(
    public id: number | null = null,
    public patientId: number | null = null,
    public lastModifiedDate: Date | null = null,
    public items: Array<VaccinationQuestion> = [],
  ) {}
}

export {VaccinationModel};

export type {VaccinationQuestion};
