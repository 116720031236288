import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import type {IAccountService} from '../../../../account';
import {ACCOUNT_SERVICE} from '../../../../account';
import {Button, Error, Input, Radio, TextArea} from '../../../../components';
import {REQUIRED_FIELD_ERROR} from '../../../../helpers';
import {useInjection} from '../../../../ioc';
import {routes} from '../../../../routing';
import type {IGoalReviewWorkflow} from '../../../interfaces';
import type {IGoalService} from '../../../service';
import {GOAL_SERVICE} from '../../../service';
import styles from './review-workflow.module.scss';

interface IProps {
  onClose: () => void;
}

interface IServiceProps {
  goalService: IGoalService;
  accountService: IAccountService;
}

const RADIO_OPTIONS = (id: string) => [
  {
    id: `${id}-yes`,
    value: true,
    label: 'YES',
  },
  {
    id: `${id}-no`,
    value: false,
    label: 'NO',
  },
];

const GoalReviewWorkflow = ({goalService, accountService, onClose}: IProps & IServiceProps) => {
  const history = useHistory();

  const title = goalService.reviewWorkflow.title;

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    setError,
    formState: {errors},
  } = useForm<IGoalReviewWorkflow>({
    defaultValues: {
      goalAccomplished: undefined,
      feelings: '',
      shareResults: undefined,
      continueWorking: undefined,
      sendMessage: undefined,
    },
  });

  const goalAccomplished = watch('goalAccomplished');

  const selectCoachThread = useCallback(async () => {
    const elevateCoach = accountService.team.filter((person) => person.providerType === 'Elevate Coach');
    const targetUrl =
      elevateCoach?.length === 1
        ? `${routes.home}${routes.messages}?id=${elevateCoach[0].providerId}`
        : `${routes.home}${routes.messages}`;

    history.replace(targetUrl);
  }, [accountService.team, history]);

  const validateForm = useCallback(
    (data: IGoalReviewWorkflow) => {
      let isValid = true;
      clearErrors();

      if (data.goalAccomplished) {
        if (!data.feelings?.trim()) {
          setError('feelings', {type: 'required', message: REQUIRED_FIELD_ERROR});
          isValid = false;
        }
        if (data.shareResults === undefined) {
          setError('shareResults', {type: 'required', message: REQUIRED_FIELD_ERROR});
          isValid = false;
        }
      } else if (data.goalAccomplished === false) {
        if (data.continueWorking === undefined) {
          setError('continueWorking', {type: 'required', message: REQUIRED_FIELD_ERROR});
          isValid = false;
        } else if (data.continueWorking === false && data.sendMessage === undefined) {
          setError('sendMessage', {type: 'required', message: REQUIRED_FIELD_ERROR});
          isValid = false;
        }
      } else {
        setError('goalAccomplished', {type: 'required', message: REQUIRED_FIELD_ERROR});
        isValid = false;
      }

      return isValid;
    },
    [clearErrors, setError],
  );

  const onSubmit = useCallback(
    async (data: IGoalReviewWorkflow) => {
      if (validateForm(data)) {
        const {sendMessage, ...restData} = data;

        const processedData = {
          feelings: restData.feelings ?? null,
          goalAccomplished: restData.goalAccomplished ?? false,
          continueWorking: restData.continueWorking ?? false,
          shareResults: restData.shareResults ?? false,
        };

        const payload = {
          ...processedData,
          goalWorksheetId: goalService.reviewWorkflow.worksheetId,
          courseId: goalService.reviewWorkflow.courseId,
        };

        const updateResult = await goalService.updateReviewWorkflow(payload);
        onClose();

        if (sendMessage) {
          selectCoachThread();
        } else if (!restData.continueWorking && updateResult) {
          await goalService.loadWorksheets();
        }
      }
    },
    [goalService, onClose, selectCoachThread, validateForm],
  );

  const renderQuestions = useCallback(() => {
    let questions = (
      <Controller
        control={control}
        name="goalAccomplished"
        render={({value, name, onChange}) => (
          <div className={styles.questionWrapper}>
            <h3>Did you accomplish your goals?</h3>
            <div className={styles.radioInputOptions}>
              {RADIO_OPTIONS('accomplish').map((option) => (
                <div className={styles.radioInput}>
                  <Radio
                    vertical
                    id={option.id}
                    key={option.id}
                    label={option.label}
                    name={name}
                    onChange={() => onChange(option.value)}
                    value={option.value === value}
                  />
                </div>
              ))}
            </div>

            {errors.goalAccomplished?.message && <Error errorMessage={errors.goalAccomplished.message} />}
          </div>
        )}
      />
    );

    if (goalAccomplished === true) {
      questions = (
        <>
          {questions}
          <Controller
            control={control}
            name="feelings"
            render={({value, onChange, name}) => (
              <div className={styles.questionWrapper}>
                <h3>How do you feel about that?</h3>
                <TextArea className={styles.inputField} name={name} value={value} onChange={onChange} />
                {errors.feelings?.message && <Error errorMessage={errors.feelings.message} />}
              </div>
            )}
          />

          <Controller
            control={control}
            name="shareResults"
            render={({value, name, onChange}) => (
              <div className={styles.questionWrapper}>
                <h3>Do you want to share your results?</h3>
                <p className={styles.description}>
                  The details of the goal will remain private. You will share only the result of your goal accomplished.
                </p>
                <div className={styles.radioInputOptions}>
                  {RADIO_OPTIONS('share').map((option) => (
                    <div className={styles.radioInput} key={option.id}>
                      <Radio
                        vertical
                        id={option.id}
                        key={option.id}
                        label={option.label}
                        name={name}
                        onChange={() => onChange(option.value)}
                        value={option.value === value}
                      />
                    </div>
                  ))}
                </div>
                {errors.shareResults?.message && <Error errorMessage={errors.shareResults.message} />}
              </div>
            )}
          />
        </>
      );
    } else if (goalAccomplished === false) {
      questions = (
        <>
          {questions}
          <Controller
            control={control}
            name="continueWorking"
            render={({value, name, onChange}) => (
              <>
                <div className={styles.questionWrapper}>
                  <h3>Would you like to continue working on your goal in your Goal Journal?</h3>
                  <div className={styles.radioInputOptions}>
                    {RADIO_OPTIONS('continue').map((option) => (
                      <div className={styles.radioInput} key={option.id}>
                        <Radio
                          vertical
                          id={option.id}
                          key={option.id}
                          label={option.label}
                          name={name}
                          onChange={() => onChange(option.value)}
                          value={option.value === value}
                        />
                      </div>
                    ))}
                  </div>
                  {errors.continueWorking?.message && <Error errorMessage={errors.continueWorking.message} />}
                </div>

                {value === false && (
                  <Controller
                    control={control}
                    name="sendMessage"
                    render={({value, name, onChange}) => (
                      <div className={styles.questionWrapper}>
                        <h3>Do you want to send a message your Coach?</h3>
                        <div className={styles.radioInputOptions}>
                          {RADIO_OPTIONS('message').map((option) => (
                            <div className={styles.radioInput} key={option.id}>
                              <Radio
                                vertical
                                id={option.id}
                                key={option.id}
                                label={option.label}
                                name={name}
                                onChange={() => onChange(option.value)}
                                value={option.value === value}
                              />
                            </div>
                          ))}
                        </div>
                        {errors.sendMessage?.message && <Error errorMessage={errors.sendMessage.message} />}
                      </div>
                    )}
                  />
                )}
              </>
            )}
          />
        </>
      );
    }

    return questions;
  }, [
    control,
    goalAccomplished,
    errors.continueWorking,
    errors.goalAccomplished,
    errors.feelings,
    errors.sendMessage,
    errors.shareResults,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.modalContent}>
      <div className={styles.modalHeader}>
        <h2>Goal Review</h2>
      </div>
      <div className={styles.modalBody}>
        <div className={styles.titleHolder}>
          <p>Goal:</p>
          <Input className={styles.inputTitle} value={title} disabled />
        </div>
        <div className={styles.questions}>{renderQuestions()}</div>
      </div>
      <div className={styles.modalFooter}>
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

const GoalReviewWorkflowObserver = observer(GoalReviewWorkflow);
const GoalReviewWorkflowInjected: FC<IProps> = (props) => (
  <GoalReviewWorkflowObserver
    {...props}
    goalService={useInjection(GOAL_SERVICE)}
    accountService={useInjection(ACCOUNT_SERVICE)}
  />
);

export {GoalReviewWorkflowInjected as GoalReviewWorkflow, GoalReviewWorkflowObserver};
