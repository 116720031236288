import type {IPreVisitRecordGetResponseDto} from '../dto';
import {PreVisitModel} from './pre-visit.model';

class PreVisitModelFabric {
  static fromResponseDto(dto: IPreVisitRecordGetResponseDto): PreVisitModel {
    return new PreVisitModel(dto.isSymptomsFilled, dto.isGWBFilled, dto.isLabResultsFilled);
  }
}

export {PreVisitModelFabric};
