const removeGetParam = (param: string) => {
  const params = new URLSearchParams(document.location.search);
  if (params.has(param)) {
    params.delete(param);

    const questionAndHashUrlPart = `?${params}${document.location.hash}`;

    const url = document.location.toString();
    const cleanUri = url.substring(0, url.indexOf('?'));

    window.history.replaceState(null, '', questionAndHashUrlPart === '?' ? cleanUri : questionAndHashUrlPart);
  }
};

const addGetParam = (param: string, value: string) => {
  const params = new URLSearchParams(document.location.search);
  if (!params.has(param)) {
    params.append(param, value);
    window.history.replaceState(null, '', `?${params}${document.location.hash}`);
  }
};

const isUrl = (value: string) => {
  try {
    new URL(value);

    return true;
  } catch {
    return false;
  }
};

export {removeGetParam, addGetParam, isUrl};
