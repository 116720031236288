import type {IRoutingService} from './routing.service.interface';
import type {
  Href,
  LocationDescriptor,
  LocationDescriptorObject,
  LocationListener,
  LocationState,
  Path,
  TransitionPromptHook,
  UnregisterCallback,
} from 'history';
import {createBrowserHistory} from 'history';
import {injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';

type BrowserRouterConfirmationCallback = (answer: boolean) => void;

@injectable()
class RoutingService implements IRoutingService {
  private _confirmNavigationCallback: BrowserRouterConfirmationCallback | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  private _confirmNavigationMessage = '';

  get confirmNavigationMessage(): string {
    return this._confirmNavigationMessage;
  }

  private _isShowConfirmation = false;

  get isShowConfirmation(): boolean {
    return this._isShowConfirmation;
  }

  private readonly _history = createBrowserHistory({
    getUserConfirmation: (m, c) => {
      this._confirmNavigationMessage = m;
      this._confirmNavigationCallback = c;
      this._isShowConfirmation = true;
    },
  });
  action = this._history.action;
  length = this._history.length;
  location = this._history.location;

  get locationToSave(): string {
    return window.location.pathname + window.location.search;
  }

  confirmationAnswer = (answer: boolean): void => {
    if (this._confirmNavigationCallback) {
      this._confirmNavigationCallback(answer);
    }

    this._isShowConfirmation = false;
  };

  isSameRoute(route: string): boolean {
    return route === this.locationToSave;
  }

  notIn(wrongRoutes: string[]): boolean {
    return !wrongRoutes.includes(this.locationToSave);
  }

  block = (prompt?: boolean | string | TransitionPromptHook<LocationState>): UnregisterCallback =>
    this._history.block(prompt);

  createHref = (location: LocationDescriptorObject<LocationState>): Href => this._history.createHref(location);

  go = (n: number): void => {
    this._history.go(n);
  };

  goBack = (): void => {
    this._history.goBack();
  };

  goForward = (): void => {
    this._history.goForward();
  };

  listen = (listener: LocationListener<LocationState>): UnregisterCallback => this._history.listen(listener);

  push = (path: Path | LocationDescriptor<LocationState>, state?: LocationState): void =>
    typeof path === 'string' ? this._history.push(path, state) : this._history.push(path);

  replace = (path: Path | LocationDescriptor<LocationState>, state?: LocationState) =>
    typeof path === 'string' ? this._history.replace(path, state) : this._history.replace(path);
}

export {RoutingService};
