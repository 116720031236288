import type {FC} from 'react';
import {useCallback, useEffect} from 'react';
import {
  AccountSetupScreen,
  DashboardScreen,
  DiseaseInformationScreen,
  EncountersScreen,
  MessagesScreen,
  MyRoadmapScreen,
  NotificationScreen,
  PaymentInformationScreen,
  ProfileScreen,
  TasksScreen,
  TrellusTeamMemberProfileScreen,
  Version,
} from '../screens';
import {ReportsScreen} from '../documents';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {routes} from '../routing';
import {useInjection} from '../ioc';
import {observer} from 'mobx-react';
import {Notifier} from '../notifier';
import type {IAppService} from './service';
import {APP_SERVICE} from './service';
import type {IAuthService} from '../utils/auth';
import {AUTH_SERVICE} from '../utils/auth';
import {StrengthsAssessmentList, StrengthsAssessmentScreen} from '../strengths-assessment';
import {VaccinationScreen} from '../vaccination';
import {LabResultsScreen as LabResultsTrackingScreen} from '../lab-results';
import {MedicationsScreen} from '../medications';
import {AppLoader, ScrollToTop} from './components';
import {ConfirmNavigation, ExpiringWarningModal} from '../components';
import {TrackingDailyScreen} from '../tracking-daily';
import {ResilienceAssessmentScreen} from '../resilience-assessment';
import {SelfEfficacyAssessmentList, SelfEfficacyAssessmentScreen} from '../self-efficacy-assessment';
import {DiseaseSeverityScreenerList, DiseaseSeverityScreenerScreen} from '../disease-severity-screener';
import type {IProfileSettingsService} from '../profile-settings';
import {PROFILE_SETTINGS_SERVICE} from '../profile-settings';
import {ConfirmationDialogProvider} from '../hooks';
import {BodyMassIndexScreen} from '../body-mass-index';
import {ChronicConditionChecklistScreen, ChronicConditionChecklistSurvey} from '../chronic-condition-checklist';
import {PrivateLayout} from '../layouts';
import type {IAccountService} from '../account';
import {ACCOUNT_SERVICE} from '../account';
import {EmotionalImpactScaleList, EmotionalImpactScaleScreen} from '../emotional-impact-scale';
import {WeeklyWellnessReportScreen} from '../weekly-wellness-report';
import {WorkProductivityScreen} from '../work-productivity';
import {LifeQualityScreen} from '../life-quality';
import {GlobalImprovementScaleScreen} from '../global-improvement-scale';
import type {IAuthConfigService} from '../utils/auth/config';
import {AUTH_CONFIG_SERVICE} from '../utils/auth/config';
import {CourseIframe} from '../course-iframe/components';
import type {ICompanyService} from '../company';
import {COMPANY_SERVICE} from '../company';
import type {IDatadogService} from '../services/datadog';
import {DATADOG_SERVICE} from '../services/datadog';
import {GoalJournalScreen} from '../goal-journal/components/screen';
import {ThriveSkillsSelectionScreen} from '../screens/private/thrive-skills-selection';

interface IProps {
  app: IAppService;
  auth: IAuthService;
  profileSettingsService: IProfileSettingsService;
  account: IAccountService;
  authConfig: IAuthConfigService;
  company: ICompanyService;
  monitoring: IDatadogService;
}

const Home: FC = () => {
  const {url} = useRouteMatch();

  const modifiedUrl = url.endsWith('/') ? url.slice(0, -1) : url;

  return (
    <ConfirmationDialogProvider>
      <PrivateLayout>
        <Switch>
          <Route path={`${url}${routes.myDashboard}`} component={DashboardScreen} />
          <Route path={`${url}${routes.myRoadmap}`} component={MyRoadmapScreen} />
          <Route path={`${url}${routes.notifications}`} component={NotificationScreen} />
          <Route path={`${url}${routes.messages}`} component={MessagesScreen} />
          <Route path={`${url}${routes.encounters}`} component={EncountersScreen} />
          <Route path={`${url}${routes.reports}`} component={ReportsScreen} exact />
          <Route path={`${url}${routes.goalJournal}`} component={GoalJournalScreen} />
          <Route path={`${url}${routes.thriveSkillSelection}`} component={ThriveSkillsSelectionScreen} />
          <Route path={`${url}${routes.dailySymptomTracker}`} component={TrackingDailyScreen} />
          <Route path={`${url}${routes.weeklyWellnessReport}`} component={WeeklyWellnessReportScreen} />
          <Route path={`${url}${routes.profile}`} component={ProfileScreen} />
          <Route path={`${url}${routes.tasks}`} component={TasksScreen} />
          <Route path={`${url}${routes.trellusTeamMemberProfile}`} component={TrellusTeamMemberProfileScreen} />
          <Route path={`${url}${routes.strengthsAssessment}`} component={StrengthsAssessmentScreen} />
          <Route path={`${url}${routes.strengthsAssessmentsList}`} component={StrengthsAssessmentList} />
          <Route path={`${url}${routes.vaccination}`} component={VaccinationScreen} />
          <Route path={`${url}${routes.labResultsTracking}`} component={LabResultsTrackingScreen} />
          <Route path={`${url}${routes.resilienceAssessment}`} component={ResilienceAssessmentScreen} />
          <Route path={`${url}${routes.resilienceAssessment}`} component={ResilienceAssessmentScreen} />
          <Route path={`${url}${routes.medications}`} component={MedicationsScreen} />
          {/* <Route path={`${url}${routes.pdf}`} component={PdfViewer} /> */}
          <Route path={`${url}${routes.emotionalImpactScale}`} component={EmotionalImpactScaleScreen} />
          <Route path={`${url}${routes.emotionalImpactScaleList}`} component={EmotionalImpactScaleList} />
          <Route path={`${url}${routes.selfEfficacy}`} component={SelfEfficacyAssessmentScreen} />
          <Route path={`${url}${routes.selfEfficacyList}`} component={SelfEfficacyAssessmentList} />
          <Route path={`${url}${routes.addWeight}`} component={BodyMassIndexScreen} />
          <Route path={`${url}${routes.diseaseSeverityScreener}`} component={DiseaseSeverityScreenerScreen} />
          <Route path={`${url}${routes.diseaseSeverityScreenerList}`} component={DiseaseSeverityScreenerList} />
          <Route path={`${url}${routes.symptomSeverityScale}`} component={DiseaseSeverityScreenerScreen} />
          <Route path={`${url}${routes.symptomSeverityScaleList}`} component={DiseaseSeverityScreenerList} />
          <Route path={`${url}${routes.chronicConditionChecklists}`} component={ChronicConditionChecklistScreen} />
          <Route path={`${url}${routes.chronicConditionChecklist}`} component={ChronicConditionChecklistSurvey} />
          <Route path={`${url}${routes.workProductivity}`} component={WorkProductivityScreen} />
          <Route path={`${url}${routes.lifeQuality}`} component={LifeQualityScreen} />
          <Route path={`${url}${routes.globalImprovementScale}`} component={GlobalImprovementScaleScreen} />
          <Route path={url} exact>
            <Redirect to={{pathname: `${modifiedUrl}${routes.myDashboard}`, search: window.location.search}} />
          </Route>
        </Switch>
      </PrivateLayout>
    </ConfirmationDialogProvider>
  );
};

const App: FC<IProps> = ({app, auth, profileSettingsService, account, authConfig, company, monitoring}) => {
  const confirmEmail = useCallback(() => {
    profileSettingsService.confirmEmail();
  }, [profileSettingsService]);

  useEffect(() => {
    if (!app.isInitiated && !app.isLoading) {
      app
        .load()
        .then(() => account.load())
        .then(() => app.finishInitiate())
        .catch((e) => {
          console.error(e);

          return auth.logout();
        });
    }
  }, [account, app, app.isInitiated, app.isLoading, auth]);

  useEffect(() => {
    if (app.isInitiated) {
      return app.addOnboardingRouteGuardListener(account.isConfirmed);
    }
  }, [account.isConfirmed, app, app.isInitiated]);

  useEffect(() => {
    if (company.companyInfo.informationalVersion) {
      monitoring.initializeRum(company.companyInfo.informationalVersion);
    }
  }, [company.companyInfo, monitoring]);

  const sessionContinue = useCallback(() => auth.prolongSession(), [auth]);
  const sessionFinish = useCallback(() => auth.logout(), [auth]);

  if (app.isLoading) {
    return (
      <>
        <Notifier />
        <AppLoader />
      </>
    );
  }

  if (account.completeOnBoardingTaskUrl.length > 0) {
    return (
      <CourseIframe
        src={account.completeOnBoardingTaskUrl}
        lmsLink={authConfig.lmsCourseLink}
        onClose={() => {
          account.completeOnBoardingTaskUrl = '';
        }}
        onComplete={() => {
          account.completeOnBoardingTaskUrl = '';
        }}
      />
    );
  }

  if (!app.isInitiated) {
    return (
      <>
        <Notifier />
        <Switch>
          <Route path={routes.version} component={Version} />
        </Switch>
      </>
    );
  }

  return (
    <>
      <ConfirmNavigation />
      {auth.isSessionWarningShown ? (
        <ExpiringWarningModal onSessionContinue={sessionContinue} onSessionExpired={sessionFinish} />
      ) : null}
      <ScrollToTop />
      <Notifier />
      <Switch>
        <Route path={routes.home} component={Home} />
        <Route path={routes.version} component={Version} />
        <Route path={routes.diseaseInformation} component={DiseaseInformationScreen} />
        <Route path={routes.paymentInformation} component={PaymentInformationScreen} />
        <Route path={routes.accountSetup} component={AccountSetupScreen} />
        <Route path={routes.confirmEmail}>{confirmEmail}</Route>
        <Route>
          <Redirect to={{pathname: routes.home, search: window.location.search}} />
        </Route>
      </Switch>
    </>
  );
};

const ObservableApp = observer(App);
const InjectableApp: FC = () => (
  <ObservableApp
    app={useInjection(APP_SERVICE)}
    auth={useInjection(AUTH_SERVICE)}
    profileSettingsService={useInjection(PROFILE_SETTINGS_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
    authConfig={useInjection(AUTH_CONFIG_SERVICE)}
    company={useInjection(COMPANY_SERVICE)}
    monitoring={useInjection(DATADOG_SERVICE)}
  />
);

export {InjectableApp as App};
