import type {FC} from 'react';
import styles from './vaccination-form-element-label.module.scss';
import {InfoYellowIcon} from '../../../assets/icons';
import {Link} from 'react-router-dom';
import {routes} from '../../../routing';
import {Bubble, BubbleType, ExpandableText} from '../../../components';
import {VaccinationRadioAnswers} from '../../enums';
import ReactHtmlParser from 'react-html-parser';

const VaccinationFormElementLabel: FC<{
  value: string;
  yesMessage?: string;
  noMessage?: string;
  label: string;
  tipMessage?: string;
  isUploadLinkNeeded?: boolean;
}> = (props) => {
  let message;

  switch (props.value) {
    case VaccinationRadioAnswers.Yes:
      message = props.yesMessage;
      break;
    case VaccinationRadioAnswers.No:
    case VaccinationRadioAnswers.Unknown:
      message = props.noMessage;
      break;
    default:
      break;
  }

  return (
    <div className={styles.root}>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{props.label}</div>
        {props.tipMessage && (
          <div className={styles.icon}>
            <InfoYellowIcon />
            <Bubble className={styles.bubble} type={BubbleType.Tooltip}>
              {ReactHtmlParser(props.tipMessage)}
            </Bubble>
          </div>
        )}
      </div>
      {!!message && <ExpandableText text={message} />}
      {props.isUploadLinkNeeded && props.value === VaccinationRadioAnswers.Yes && (
        <div className={styles.upload}>
          Please upload your documents{' '}
          <Link
            className={styles.link}
            to={`${routes.home}${routes.reports}`}
            target="_blank"
            rel="noopener noreferrer">
            here
          </Link>
        </div>
      )}
    </div>
  );
};

export {VaccinationFormElementLabel};
