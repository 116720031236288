enum FileType {
  AvatarImage = 1,
  MessageAttachment = 2,
  Document = 3,
}

class FileStorageModel {
  constructor(
    public content: string = '',
    public fileName: string = '',
    public mimeType: string = '',
    public objectId: string = '',
    public objectFileType?: FileType,
  ) {}

  getLink(): string {
    return `data:${this.mimeType};base64, ${encodeURI(this.content)}`;
  }
}

export {FileStorageModel, FileType};
