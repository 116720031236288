import type {FC} from 'react';
import React from 'react';
import {Avatar, Card, CardContent} from '../../components';
import {Link} from 'react-router-dom';
import styles from './quote.module.scss';
import {routes} from '../../routing';
import {ProfileTabsEnum} from '../../screens/private/profile/profile.enum';

interface IQuoteProps {
  user?: {
    name: string;
    avatar?: string;
  };
  textAfterAddressingToUser?: string;
  isFirstLogin?: boolean;
  isShowProviderTitle: boolean;
}

const Quote: FC<IQuoteProps> = ({user, textAfterAddressingToUser, isFirstLogin, isShowProviderTitle}) => {
  const actualTextAfterAddressingToUser = textAfterAddressingToUser ?? "it's a great day to Elevate!";

  // TODO: Background not exported correctly.
  return (
    <Card border={'top'} borderLess>
      <CardContent className={styles.root}>
        <Avatar className={styles.avatar} size={58} src={user?.avatar} alt={user?.name ? user.name : ''} />
        <span className={styles.greeting}>
          {user?.name}, {actualTextAfterAddressingToUser}
        </span>
        {isShowProviderTitle && (
          <Link
            className={styles.titleProvider}
            to={`${routes.home}${routes.profile}?tabNumber=${ProfileTabsEnum.Providers}`}
            title={'Click here to share my information with my provider'}>
            Click here to share my information with my provider
          </Link>
        )}
      </CardContent>
    </Card>
  );
};

export {Quote};
