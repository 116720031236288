import type {FC} from 'react';
import React, {useMemo} from 'react';
import {NotificationsFlags} from '../../../../model/notifications-flags.enum';
import styles from '../../notification.module.scss';
import {FlagGreen, FlagRed, FlagWhite, FlagYellow} from '../../../../../assets/icons';

const NotificationFlag: FC<{flagId?: number | null}> = ({flagId}) =>
  useMemo(() => {
    let flagItem;

    switch (flagId) {
      case NotificationsFlags.NEUTRAL:
        flagItem = (
          <div className={styles.flag}>
            <FlagWhite />
          </div>
        );
        break;
      case NotificationsFlags.LOW_RISK:
        flagItem = (
          <div className={styles.flag}>
            <FlagGreen />
          </div>
        );
        break;
      case NotificationsFlags.MEDIUM_RISK:
        flagItem = (
          <div className={styles.flag}>
            <FlagYellow />
          </div>
        );
        break;
      case NotificationsFlags.HIGHEST_RISK:
        flagItem = (
          <div className={styles.flag}>
            <FlagRed />
          </div>
        );
        break;
      default:
        flagItem = null;
    }

    return flagItem;
  }, [flagId]);

export {NotificationFlag};
