import {mapFromOptionsHelper} from '../../helpers';
import {MedicationsFrequenciesTypesEnum} from './medications-frequencies-types.enum';
import {MedicationsUnitsTypesEnum} from './medications-units-types.enum';

const unitTypesOptions = mapFromOptionsHelper<MedicationsUnitsTypesEnum>([
  {
    value: MedicationsUnitsTypesEnum.BlankPerNull,
    displayName: '',
  },
  {
    value: MedicationsUnitsTypesEnum.Gram,
    displayName: 'g',
  },
  {
    value: MedicationsUnitsTypesEnum.Mg,
    displayName: 'mg',
  },
  {
    value: MedicationsUnitsTypesEnum.Ml,
    displayName: 'ml',
  },
  {
    value: MedicationsUnitsTypesEnum.MgPerKg,
    displayName: 'mg/kg',
  },
  {
    value: MedicationsUnitsTypesEnum.UnitS,
    displayName: 'unit(s)',
  },
  {
    value: MedicationsUnitsTypesEnum.Applicatorful,
    displayName: 'applicatorful',
  },
  {
    value: MedicationsUnitsTypesEnum.Suppository,
    displayName: 'suppository',
  },
]);

const frequencyTypesOptions = mapFromOptionsHelper<MedicationsFrequenciesTypesEnum>([
  {
    value: MedicationsFrequenciesTypesEnum.BlankPerNull,
    displayName: '',
  },
  {
    value: MedicationsFrequenciesTypesEnum.Daily,
    displayName: 'Daily',
  },
  {
    value: MedicationsFrequenciesTypesEnum.TwiceDaily,
    displayName: 'Twice Daily',
  },
  {
    value: MedicationsFrequenciesTypesEnum.Weekly,
    displayName: 'Weekly',
  },
  {
    value: MedicationsFrequenciesTypesEnum.TwiceWeekly,
    displayName: 'Twice weekly',
  },
  {
    value: MedicationsFrequenciesTypesEnum.PRN,
    displayName: 'PRN',
  },
  {
    value: MedicationsFrequenciesTypesEnum.EveryTwoWeeks,
    displayName: 'Every 2 weeks',
  },
  {
    value: MedicationsFrequenciesTypesEnum.EveryFourWeeks,
    displayName: 'Every 4 weeks',
  },
  {
    value: MedicationsFrequenciesTypesEnum.EverySixWeeks,
    displayName: 'Every 6 weeks',
  },
  {
    value: MedicationsFrequenciesTypesEnum.EveryEightWeeks,
    displayName: 'Every 8 weeks',
  },
  {
    value: MedicationsFrequenciesTypesEnum.Other,
    displayName: 'Other',
  },
]);

const frequencyTypesOptionsForMedicationSkyrizi = mapFromOptionsHelper<MedicationsFrequenciesTypesEnum>([
  {
    value: MedicationsFrequenciesTypesEnum.EveryFourWeeks,
    displayName: 'Every 4 weeks',
  },
  {
    value: MedicationsFrequenciesTypesEnum.EveryEightWeeks,
    displayName: 'Every 8 weeks',
  },
]);

export {unitTypesOptions, frequencyTypesOptions, frequencyTypesOptionsForMedicationSkyrizi};
