import type {FC} from 'react';
import React from 'react';
import {GreenCheckboxIcon, GreyCheckboxIcon} from '../../../../assets/icons';
import styles from './tracking.module.scss';
import classNames from 'classnames';
import type {IPreVisitTask} from '../../pre-visit-task-modal.component';
import {Link} from 'react-router-dom';
import {routes} from '../../../../routing';

enum Actions {
  DONE = 'Done',
  NEEDED = 'Action Needed',
}

const Tracking: FC<IPreVisitTask> = (props) => (
  <ul className={styles.trackingList}>
    <li className={styles.trackingListTitle}>Pre–visit tracking</li>
    <li className={styles.trackingListItem}>
      <Link to={`${routes.home}${routes.dailySymptomTracker}`} target="_blank" rel="noopener noreferrer">
        Symptom Tracker
      </Link>
      <span className={classNames(styles.checkboxWrap, {[styles.checkboxWrapDone]: props.isSymptomTrackingComplete})}>
        {props.isSymptomTrackingComplete ? Actions.DONE : Actions.NEEDED}
        {props.isSymptomTrackingComplete ? (
          <GreenCheckboxIcon className={styles.checkbox} />
        ) : (
          <GreyCheckboxIcon className={styles.checkbox} />
        )}
      </span>
    </li>
    <li className={styles.trackingListItem}>
      <Link to={`${routes.home}${routes.weeklyWellnessReport}`} target="_blank" rel="noopener noreferrer">
        Weekly Wellness
      </Link>
      <span
        className={classNames(styles.checkboxWrap, {[styles.checkboxWrapDone]: props.isWeeklyWellnessReportComplete})}>
        {props.isWeeklyWellnessReportComplete ? Actions.DONE : Actions.NEEDED}
        {props.isWeeklyWellnessReportComplete ? (
          <GreenCheckboxIcon className={styles.checkbox} />
        ) : (
          <GreyCheckboxIcon className={styles.checkbox} />
        )}
      </span>
    </li>
    <li className={styles.trackingListItem}>
      <div className={styles.trackingListGroup}>
        <Link to={`${routes.home}${routes.labResultsTracking}`} target="_blank" rel="noopener noreferrer">
          Lab Results
        </Link>
        <span>
          If you have lab results that you haven't uploaded yet, please don't forget to do it before appointment
        </span>
      </div>
    </li>
  </ul>
);

export {Tracking};
