import type {FC, ReactElement} from 'react';
import {cloneElement, useState} from 'react';
import {CloseIcon} from '../assets/icons';
import {Modal} from '../components';
import styles from './multi-step-modal.module.scss';
import StepperControl from './stepper-control/stepper-control.component';

interface IMultiStepForm {
  isOpen: boolean;
  steps: ReactElement[];
  includeStepControl?: boolean;

  onClose: () => void;
}

const MultiStepModal: FC<IMultiStepForm> = ({isOpen, steps, includeStepControl = false, onClose}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const onNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      onSubmit();
    }
  };

  const onPrevious = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  // Injecting onNext and onPrevious props dynamically to each step
  const renderCurrentStep = () =>
    cloneElement(steps[currentStep], {
      ...(currentStep < steps.length && {onNext}),
      ...(currentStep > 0 && {onPrevious}), // Only add onPrevious if not the first step
    });

  const onSubmit = () => {
    onClose();
    setCurrentStep(0);
  };

  return (
    <>
      {isOpen && (
        <Modal onClose={onSubmit}>
          <div className={styles.stepContent}>
            <button className={styles.closeButton} onClick={onSubmit}>
              <CloseIcon />
            </button>
            {renderCurrentStep()}
          </div>
          {includeStepControl && (
            <StepperControl
              currentStep={currentStep}
              stepsLength={steps.length}
              onNext={onNext}
              onPrevious={onPrevious}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default MultiStepModal;
