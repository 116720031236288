import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './tooltip.module.scss';
import classNames from 'classnames';

interface ITooltipProps {
  text: string;
  className?: string;
  effect?: 'float' | 'solid';
  delayShow?: number;
  place?: 'top' | 'right' | 'bottom' | 'left';
  disable?: boolean;
}

const Tooltip: React.FC<ITooltipProps> = ({children, ...props}) => (
  <div className={styles.root} data-tip={props.text}>
    {children}
    <ReactTooltip
      disable={props.disable}
      className={classNames(styles.tooltip, props.className)}
      effect={props.effect}
      delayShow={props.delayShow}
      place={props.place}
    />
  </div>
);

export {Tooltip};
export type {ITooltipProps};
