enum PopupNotificationType {
  PatientReadyForThriveFirstLogin = 1,
  PatientReadyForThriveMessagesPage = 2,
}

interface AccountPopupNotificationInfo {
  popupNotificationId: number;
  userAccountId: number;
  popupNotificationType: PopupNotificationType;
  text: string;
  isRead: boolean;
  count: number;
  maxCount: number;
  createdDate: string;
  lastModifiedDate: string;
}

export {PopupNotificationType};
export type {AccountPopupNotificationInfo};
