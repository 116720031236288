import type {FC} from 'react';
import React from 'react';
import styles from './warning.module.scss';
import {WarnCircleIcon} from '../../assets/icons';
import classnames from 'classnames';

interface IWarningProps {
  iconVisible?: boolean;
  className?: string;
}

const Warning: FC<IWarningProps> = ({iconVisible = true, className, children}) => (
  <div className={classnames(styles.root, className)}>
    {iconVisible && <WarnCircleIcon className={styles.icon} />}
    {children}
  </div>
);

export {Warning};
