import type {FC} from 'react';
import React, {useMemo, useRef} from 'react';
import styles from './assessment-report-overall-score.module.scss';
import {EmptyRectangle} from '../../../components';
import {useGraphicValues} from './graphic-values';
import classNames from 'classnames';

interface IAssessmentReportOverallScoreProps {
  progress: number;
  minValue?: number;
  maxValue?: number;
  isPrint?: boolean;
}

const AssessmentReportOverallScore: FC<IAssessmentReportOverallScoreProps> = ({
  minValue = 0,
  maxValue = 0,
  progress = 0,
  isPrint = false,
}) => {
  const {markerPosition} = useGraphicValues(minValue, maxValue, progress);
  const textElem = useRef<HTMLParagraphElement>(null);
  const textWidth = useMemo(() => (textElem.current?.offsetWidth ?? 114) / 2, []);

  return (
    <EmptyRectangle className={classNames(styles.root, {[styles.print]: isPrint})}>
      <h4 className={styles.title}>Overall Resilience</h4>
      <div className={styles.progressBar}>
        <div className={styles.progressLine} />
        <div className={styles.slider}>
          <div className={styles.sliderTriangleWrapper} style={{width: `calc(${markerPosition}% + 13px)`}}>
            <div className={styles.sliderTriangle} />
          </div>
          <div className={styles.sliderTextWrapper} style={{width: `calc(${markerPosition}% + ${textWidth}px)`}}>
            <p ref={textElem} className={styles.sliderText}>
              Your resilience
            </p>
          </div>
        </div>
      </div>
    </EmptyRectangle>
  );
};

export {AssessmentReportOverallScore};
export type {IAssessmentReportOverallScoreProps};
