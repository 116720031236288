import type {IWorkProductivityService} from './work-productivity.service.interface';
import {inject, injectable} from 'inversify';
import type {IRoutingService} from '../../utils/routing';
import {ROUTING_SERVICE} from '../../utils/routing';
import type {IAlertIndicatorService} from '../../alert-indicator';
import {ALERT_INDICATOR_SERVICE} from '../../alert-indicator';
import {makeAutoObservable} from 'mobx';
import type {IWorkProductivityRepository} from '../repository';
import {WORK_PRODUCTIVITY_REPOSITORY} from '../repository';
import {routes} from '../../routing';
import type {WorkProductivityQuestionModel} from '../model';
import {WorkProductivityModelFactory} from '../model';
import type {IWorkProductivityFormDto} from '../form';
import type {ITaskService} from '../../task/service';
import {TASK_SERVICE} from '../../task/service';
import {PredefinedTasks} from '../../enums';

@injectable()
class WorkProductivityService implements IWorkProductivityService {
  private _hasWorkProductivityTaskActive: boolean = false;

  constructor(
    @inject(WORK_PRODUCTIVITY_REPOSITORY) private readonly _repo: IWorkProductivityRepository,
    @inject(ROUTING_SERVICE) private readonly _routing: IRoutingService,
    @inject(ALERT_INDICATOR_SERVICE) private readonly _alertIndicator: IAlertIndicatorService,
    @inject(TASK_SERVICE) private readonly _task: ITaskService,
  ) {
    makeAutoObservable(this);
  }

  private _isLoading: boolean = true;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _listQuestions: WorkProductivityQuestionModel[] = [];

  get listQuestions(): WorkProductivityQuestionModel[] {
    return this._listQuestions;
  }

  async checkWorkProductivityTaskActive(): Promise<void> {
    if (!this._task.activeTasks.length) {
      await this._task.load();
    }

    const hasWorkProductivityTaskActive = this._task.activeTasks.some(
      (task) => !task.complete && task.patientDailyTaskId === PredefinedTasks.WorkProductivity,
    );

    if (hasWorkProductivityTaskActive) {
      this._hasWorkProductivityTaskActive = true;
    } else {
      this.navigateToBack();
    }
  }

  async loadQuestions(): Promise<void> {
    await this.checkWorkProductivityTaskActive();

    if (this._hasWorkProductivityTaskActive && !this._listQuestions.length) {
      this._listQuestions = await this._repo.listQuestions();
    }

    this._isLoading = false;
  }

  async createAssessment(formDto: IWorkProductivityFormDto): Promise<void> {
    const model = WorkProductivityModelFactory.fromFormDto(formDto);

    await this._repo
      .save(model)
      .then(() => {
        this._alertIndicator.load();

        this.navigateToBack();
      })
      .catch(console.error);
  }

  navigateToBack(): void {
    this._routing.push(`${routes.home}${routes.myDashboard}`);
  }
}

export {WorkProductivityService};
