import type {FC} from 'react';
import React from 'react';
import {Button, Card, CardContent, IconType, List, SearchInput} from '../../../../../components';
import {MessageThread} from '../message-thread';
import classNames from 'classnames';
import styles from './messages-thread-list-card.module.scss';
import type {MessageThreadModel} from '../../../../../message';

const MessagesThreadListCard: FC<{
  threads: readonly MessageThreadModel[];
  onSelect: (threadId: number) => void;
  onSearch: (threadQuery: string) => void;
  threadQuery: string;
  selectedThread: MessageThreadModel | null;
}> = (props) => {
  const threads = props.threads?.map((thread) => {
    const selectThread = () => props.onSelect(thread.id);

    return (
      <li className={styles.hoverable} key={thread.id} onClick={selectThread}>
        <Card borderLess className={styles.hoverable}>
          <CardContent>
            <MessageThread thread={thread} />
          </CardContent>
        </Card>
      </li>
    );
  });

  const noThreads = !props.threads.length;

  return (
    <div className={classNames(styles.root, {[styles.rootVisible]: !!props.selectedThread})}>
      <div className={classNames(styles.header)}>
        <CardContent gap={2} gapY={1}>
          <div
            className={classNames(styles.headerControls, {
              [styles.headerControlsExtended]: !!props.threadQuery,
            })}>
            {!!props.threadQuery && (
              <Button onClick={() => props.onSearch('')} flat icon={IconType.ArrowLeft} iconSize={'23px'} />
            )}
            <SearchInput placeholder={'Search'} value={props.threadQuery} onChange={props.onSearch} />
          </div>
        </CardContent>
      </div>
      <div className={classNames(styles.content)}>
        {!noThreads && <List noStyle>{threads}</List>}
        {noThreads && <p className={styles.emptyListText}>Nothing Found</p>}
      </div>
    </div>
  );
};

export {MessagesThreadListCard};
