const uploadFile = (file: File, onProgress: (percentage: number) => void): Promise<unknown> =>
  new Promise((res, rej) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => res(reader.result);

    reader.onerror = (error) => rej(error);

    reader.onloadend = (e) => {
      if (e.lengthComputable) {
        const percentage = (e.loaded / e.total) * 100;
        onProgress(Math.round(percentage));
      }
    };
  });

const getPreparedDataUrl = (dataUrl: string): string => String(dataUrl).split(',')[1];

export {uploadFile, getPreparedDataUrl};
