import type {AssessmentQuestionModel} from '../../assessment';
import {AssessmentQuestionType} from '../../assessment';

const formatDefaultValueForForm = (listQuestions: AssessmentQuestionModel[], name: string, value: string | null) => {
  const type = listQuestions.find((question) => question.name === name)?.type;

  switch (type) {
    case AssessmentQuestionType.Dropdown:
      return {displayName: value, value};
    case AssessmentQuestionType.RadioButton:
      let displayName = value;

      if (value === 'true' || value === 'false') {
        displayName = value === 'true' ? 'Yes' : 'No';
      }

      return {id: value, text: displayName};
    case AssessmentQuestionType.Range:
      return value ?? '0';
    default:
      return value;
  }
};

export {formatDefaultValueForForm};
