import classNames from 'classnames';
import type {FC, ReactNode} from 'react';
import React, {useCallback, useMemo} from 'react';
import {getFileDataUrl} from '../../file-storage/helpers';
import type {FileStorageModel} from '../../file-storage/model';
import {Card} from '../card';
import {CardContent} from '../card-content';
import {FavoriteIcon} from '../favorite-icon';
import styles from './custom-list-item.module.scss';

enum CustomListItemDataTestIdEnum {
  Date = 'expired',
  Card = 'card',
  Title = 'Title',
}

interface ICustomListItemProps {
  id?: number;
  title?: string;
  metaText?: string;
  card?: boolean;
  date?: string | JSX.Element;
  dateDanger?: boolean;
  isComplete?: boolean;
  hoverable?: boolean;
  color?: string;
  highlight?: boolean;
  isFavorite?: boolean;
  showFavoriteIcon?: boolean;
  image?: null | FileStorageModel | string;
  isLesson?: boolean;
  isCourse?: boolean;
  shadow?: boolean;
  isGoal?: boolean;
  actions?: ReactNode;

  onClick?: () => void;
  onChangeFavoriteItem?: (taskId: number, isFavorite: boolean) => void;
}

const CustomListItem: FC<ICustomListItemProps> = (props) => {
  const onChangeFavoriteItem = useCallback(() => {
    if (props.onChangeFavoriteItem && props?.id) {
      props.onChangeFavoriteItem(props.id, !props.isFavorite);
    }
  }, [props]);

  const favorite = useMemo(
    () =>
      !!props?.showFavoriteIcon ? (
        <FavoriteIcon size={1} onClick={onChangeFavoriteItem} isFilled={props.isFavorite ?? false} />
      ) : null,
    [props.showFavoriteIcon, props.isFavorite, onChangeFavoriteItem],
  );

  const statusGroup = useMemo(
    () => (!!props?.showFavoriteIcon ? <div className={classNames(styles.statusGroup)}>{favorite}</div> : <></>),
    [favorite, props?.showFavoriteIcon],
  );

  const onClick = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [props]);

  const Wrapper: React.FC = useCallback(
    (wrapperProps) => {
      if (props.card) {
        return (
          <Card
            data-testid={CustomListItemDataTestIdEnum.Card}
            className={classNames({
              [styles.hoverable]: props.hoverable,
              [styles.highlight]: props.highlight,
              [styles.success]: props.isComplete,
              [styles.shadow]: props.shadow,
              [styles.gray]: (!props.isComplete && props.isLesson && !props.isCourse) || props.isGoal,
            })}
            color={props.color}
            border="top"
            isLesson={props.isLesson}>
            <CardContent className={styles.wrapper}>{wrapperProps.children}</CardContent>
          </Card>
        );
      } else {
        return <>{wrapperProps.children}</>;
      }
    },
    [
      props.card,
      props.color,
      props.highlight,
      props.hoverable,
      props.isLesson,
      props.isComplete,
      props.isCourse,
      props.isGoal,
      props.shadow,
    ],
  );

  const getImage = useMemo(() => {
    if (typeof props.image === 'string') {
      return <img src={props.image} alt="List item" className={styles.image} />;
    }
    if (props.image?.fileName.startsWith('STATIC_FILE_')) {
      return <img src={props.image?.fileName.replace('STATIC_FILE_', '')} alt="List item" className={styles.image} />;
    }

    if (!!props.image) {
      return <img src={getFileDataUrl(props.image)} alt="List item" className={styles.image} />;
    }

    return <></>;
  }, [props.image]);

  const actions = useMemo(() => (props.actions ? props.actions : null), [props.actions]);

  return (
    <li
      onClick={onClick}
      className={classNames({
        [styles.hoverable]: !props.card && props.hoverable,
      })}>
      <Wrapper>
        <div
          data-testid={CustomListItemDataTestIdEnum.Title}
          className={classNames(styles.content, {
            [styles.contentWithThreeColumns]: true,
          })}>
          <div className={classNames(styles.withImage, props.isCourse && styles.courseImage)}>{getImage}</div>
          {actions}
          {statusGroup}
        </div>
        <div className={styles.body}>{props.children}</div>
      </Wrapper>
    </li>
  );
};

export {CustomListItem, CustomListItemDataTestIdEnum};
export type {ICustomListItemProps};
