import type {FC} from 'react';
import React from 'react';
import {Checkbox} from '../../../../../components';
import styles from './chronic-condition-checklist-diagnosed.module.scss';
import {Controller, useFormContext} from 'react-hook-form';

interface IChronicConditionChecklistDiagnosedProps {
  name: string;
  title: string;
  readonly: boolean;
}

const ChronicConditionChecklistDiagnosed: FC<IChronicConditionChecklistDiagnosedProps> = ({name, title, readonly}) => {
  const {control} = useFormContext();

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      defaultValue={false}
      render={({name, value, onChange}) =>
        readonly ? (
          !!value ? (
            <div className={styles.readonly}>{title}</div>
          ) : (
            <></>
          )
        ) : (
          <Checkbox id={name} name={name} label={title} value={value} onChange={onChange} className={styles.checkbox} />
        )
      }
    />
  );
};

export {ChronicConditionChecklistDiagnosed};
