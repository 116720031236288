import {HealthMonitorGraphTypes} from '../../../model';

const getYAxisLabelMargin = (type: HealthMonitorGraphTypes): number => {
  switch (type) {
    case HealthMonitorGraphTypes.WwbMyLifeIsGoingWell:
      return 150;
    case HealthMonitorGraphTypes.GwbSleepQuality:
      return 100;
    case HealthMonitorGraphTypes.GwbFatigue:
    case HealthMonitorGraphTypes.IbsSss:
      return 90;
    case HealthMonitorGraphTypes.GwbPhysicalHealth:
      return 80;
    case HealthMonitorGraphTypes.Weight:
      return 60;
    default:
      return 45;
  }
};

export {getYAxisLabelMargin};
