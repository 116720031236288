import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './medication-screen.module.scss';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CollapseDescription,
  EmptyRectangle,
  Modal,
  PageLoader,
  PageTitle,
} from '../../components';
import type {IMedicationsService} from '../service';
import {MEDICATIONS_SERVICE} from '../service';
import type {IMedicationsCourseDiscontinueFormDto, IMedicationsCourseFormDto, MedicationOption} from '../components';
import {
  MedicationsCourseDiscontinueForm,
  MedicationsCourseForm,
  MedicationsInfoCurrent,
  MedicationsInfoDiscontinue,
  MedicationsList,
  MedicationsListItemTypeEnum,
} from '../components';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';
import {TASK_SERVICE} from '../../task/service';
import type {ITaskService} from '../../task/service';
import {NoMedicationsToAddConfirmModal} from '../components/no-medications-to-add';
import {PredefinedTasks} from '../../enums';

interface IProps {
  medications: IMedicationsService;
  account: IAccountService;
  task: ITaskService;
}

const useModal = (initialShow?: boolean, onClose?: () => void) => {
  const [show, setShow] = useState(initialShow);
  const close = useCallback(() => (onClose ? [setShow(false), onClose()] : setShow(false)), [onClose]);
  const open = useCallback(() => setShow(true), []);
  const toggle = useCallback(() => setShow((state) => !state), []);

  const Template: FC<{title: string; classNameContent?: string}> = useCallback(
    ({title, classNameContent, children}) =>
      show ? (
        <Modal onClose={close}>
          <Card className={styles.cardModal}>
            <CardHeader title={title} closable titleLarge noBorder onClose={close} />
            <CardContent className={classNameContent}>{children}</CardContent>
          </Card>
        </Modal>
      ) : (
        <></>
      ),
    [close, show],
  );

  return {ModalTemplate: Template, modalClose: close, modalOpen: open, modalToggle: toggle, isModalOpen: show};
};

const MedicationsScreen: FC<IProps> = ({medications, account, task}) => {
  const clearCourseSelection = useCallback(() => {
    medications.clearCourseSelection();
  }, [medications]);

  const {ModalTemplate: ModalCourseCreateForm, ...modalCourseCreateForm} = useModal(false, clearCourseSelection);
  const {ModalTemplate: ModalCourseDiscontinueForm, ...modalCourseDiscontinueForm} = useModal(
    false,
    clearCourseSelection,
  );
  const {
    ModalTemplate: ModalNoMedicationsToAdd,
    modalClose: modalNoMedicationsToAddClose,
    modalOpen: modalNoMedicationsToAddOpen,
  } = useModal(false, clearCourseSelection);
  const {ModalTemplate: ModalCourseInfo, modalOpen: modalCurrentInfoOpen} = useModal(false, clearCourseSelection);
  const {ModalTemplate: ModalCourseDiscontinueInfo, modalOpen: modalDiscontinueInfoOpen} = useModal(
    false,
    clearCourseSelection,
  );

  const loadCourses = useCallback(() => {
    medications.load();
  }, [medications]);

  const loadTasks = useCallback(() => {
    task.load();
  }, [task]);

  useEffect(() => {
    loadCourses();
    loadTasks();
  }, [loadCourses, loadTasks]);

  const course = medications.selectedCourse;
  const courseDiscontinue = medications.selectedCourseDiscontinue;

  const createCourse = useCallback(
    (data: IMedicationsCourseFormDto) => {
      if (account.id) {
        medications.courseCreate(data, account.id, course?.id);
        modalCourseCreateForm.modalClose();
      }
    },
    [account.id, medications, modalCourseCreateForm, course?.id],
  );

  const createCustomMedication = useCallback(
    (medication: string) => medications.createMedication({name: medication}),
    [medications],
  );

  const discontinueSubmit = useCallback(
    (data: IMedicationsCourseDiscontinueFormDto) => {
      if (account.id) {
        medications.courseDiscontinue(data, account.id);
        modalCourseDiscontinueForm.modalClose();
      }
    },
    [account.id, medications, modalCourseDiscontinueForm],
  );

  const showCourse = useCallback(
    (id: number, type: MedicationsListItemTypeEnum) => {
      medications.selectCourse(id);

      switch (type) {
        case MedicationsListItemTypeEnum.CURRENT: {
          modalCurrentInfoOpen();
          break;
        }
        case MedicationsListItemTypeEnum.DISCONTINUE: {
          modalDiscontinueInfoOpen();
          break;
        }
        case MedicationsListItemTypeEnum.ON_DISCONTINUE: {
          modalCourseDiscontinueForm.modalOpen();
          break;
        }
        case MedicationsListItemTypeEnum.EDIT: {
          modalCourseCreateForm.modalOpen();
          break;
        }
        default:
          break;
      }
    },
    [medications, modalCurrentInfoOpen, modalDiscontinueInfoOpen, modalCourseCreateForm, modalCourseDiscontinueForm],
  );

  const courses = useMemo(
    () =>
      medications.courseArr.map(({id, doseStr, medication}) => ({
        dose: doseStr,
        id,
        medicationName: medication.name,
      })),
    [medications.courseArr],
  );

  const coursesDiscontinued = useMemo(
    () =>
      medications.courseDiscontinuedArr.map(({medication, discontinue, id, doseStr}) => ({
        medicationName: medication.name,
        discontinue: discontinue?.date ?? null,
        id,
        dose: doseStr,
      })),
    [medications.courseDiscontinuedArr],
  );

  const medicationOptions: ReadonlyArray<MedicationOption> = useMemo(
    () =>
      medications.medications.map(({safeId, name}) => ({
        value: safeId,
        displayName: name,
      })),
    [medications.medications],
  );

  const defaultValuesEdit = useMemo(
    () => (!!course?.id ? medications.getFormDtoById(course) : null),
    [course, medications],
  );

  const reasonStr = useMemo(() => {
    const reason = courseDiscontinue?.discontinue?.reason;

    if (typeof reason === 'number') {
      return medications.reasonsDiscontinue.find((i) => reason === i.value)?.description ?? '';
    }

    return reason ?? '';
  }, [courseDiscontinue?.discontinue?.reason, medications.reasonsDiscontinue]);

  const activeMedicationTaskId = useMemo(
    () => task.incompleteTasks.find((task) => task.patientDailyTaskId === PredefinedTasks.MedicationsListUpdate)?.id,
    [task.incompleteTasks],
  );

  const completeMedicationTask = useCallback(() => {
    if (activeMedicationTaskId) {
      task.completeAndRefresh(activeMedicationTaskId);
      modalNoMedicationsToAddClose();
    }
  }, [task, activeMedicationTaskId, modalNoMedicationsToAddClose]);

  return (
    <>
      <div className={styles.medicationsHeader}>
        <div className={styles.topContent}>
          <PageTitle>Medications</PageTitle>
          {!medications.isLoading && (
            <Button className={styles.actionBtn} onClick={modalCourseCreateForm.modalOpen}>
              Add medication
            </Button>
          )}
        </div>
      </div>
      {medications.isLoading && <PageLoader />}
      {!medications.isLoading && (
        <>
          <CollapseDescription>
            Remembering all the medications you're currently or were previously prescribed can be challenging, and
            maintaining a medication history can prove invaluable during your healthcare appointments. It also helps us
            send you important reminders to help you manage your health on your medications.
          </CollapseDescription>

          {medications.courseArr.length || medications.courseDiscontinuedArr.length ? (
            <MedicationsList
              currentItems={courses}
              discontinuedItems={coursesDiscontinued}
              onItemClick={showCourse}
              onLoad={loadCourses}
            />
          ) : (
            <EmptyRectangle className={styles.noneMedications}>
              <div className={styles.noneMedicationsText}>You don’t have any medications.</div>
              <button className={styles.noneMedicationsButton} onClick={modalCourseCreateForm.modalOpen}>
                Add medication
              </button>
              {activeMedicationTaskId && (
                <button className={styles.noneMedicationsButton} onClick={modalNoMedicationsToAddOpen}>
                  No medication to add
                </button>
              )}
            </EmptyRectangle>
          )}

          <ModalCourseCreateForm
            title={course?.id ? 'Edit Medication' : 'Add Medications'}
            classNameContent={styles.cardContentModal}>
            <MedicationsCourseForm
              onClose={modalCourseCreateForm.modalClose}
              onSubmit={createCourse}
              onSubmitNewMedication={createCustomMedication}
              medications={medicationOptions}
              defaultValues={defaultValuesEdit}
            />
          </ModalCourseCreateForm>

          <ModalNoMedicationsToAdd title={''} classNameContent={styles.cardContentModal}>
            <NoMedicationsToAddConfirmModal onClose={modalNoMedicationsToAddClose} onSubmit={completeMedicationTask} />
          </ModalNoMedicationsToAdd>

          <ModalCourseDiscontinueForm
            title={'Choose the reason for discontinuation'}
            classNameContent={styles.cardContentModal}>
            <MedicationsCourseDiscontinueForm
              reasons={medications.reasonsDiscontinue}
              onClose={modalCourseDiscontinueForm.modalClose}
              onSubmit={discontinueSubmit}
            />
          </ModalCourseDiscontinueForm>

          {course?.created && (
            <ModalCourseInfo title={'Current Medication'} classNameContent={styles.cardContentModalInfo}>
              <MedicationsInfoCurrent
                name={course.medication.name}
                created={course.created}
                author={course.createdBy}
                dose={course.doseStr}
                end={course.end}
                start={course.start}
                updated={course.lastUpdated}
                editor={course.updatedBy}
              />
            </ModalCourseInfo>
          )}

          {courseDiscontinue?.discontinue && (
            <ModalCourseDiscontinueInfo
              title={'Discontinued Medication'}
              classNameContent={styles.cardContentModalInfo}>
              <MedicationsInfoDiscontinue
                dose={courseDiscontinue.doseStr}
                end={courseDiscontinue.end}
                start={courseDiscontinue.start}
                name={courseDiscontinue.medication.name}
                discontinue={courseDiscontinue.discontinue?.date}
                discontinuedBy={courseDiscontinue.discontinue.author}
                lastUpdated={courseDiscontinue.discontinue.lastUpdated}
                reason={reasonStr}
              />
            </ModalCourseDiscontinueInfo>
          )}
        </>
      )}
    </>
  );
};

const ObservableMedicationsScreen = observer(MedicationsScreen);
const InjectedMedicationsScreen: FC = () => (
  <ObservableMedicationsScreen
    medications={useInjection(MEDICATIONS_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
    task={useInjection(TASK_SERVICE)}
  />
);

export {InjectedMedicationsScreen as MedicationsScreen};
