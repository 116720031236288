import type {FC} from 'react';
import React, {useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Button, Card} from '../../components';
import type {IDiseaseSeverityScreenerFormDto} from './dto';
import type {DiseaseSeverityScreenerQuestionModel, IDiseaseSeverityScreenerItem} from '../model';
import styles from './disease-severity-screener-form.module.scss';
import type {IOption} from '../../types';
import {DiseaseSeverityScreenerQuestions} from './questions';
import {formatDefaultValueForForm} from '../../helpers';
import {AssessmentQuestionType} from '../../assessment';

interface IDiseaseSeverityScreenerFormProps {
  selectedAssessmentItems: IDiseaseSeverityScreenerItem[] | null;
  listQuestions: DiseaseSeverityScreenerQuestionModel[];
  readonly: boolean;
  onSubmit: (data: IDiseaseSeverityScreenerFormDto) => void;
  onCancel: () => void;
}

const DiseaseSeverityScreenerForm: FC<IDiseaseSeverityScreenerFormProps> = ({
  selectedAssessmentItems,
  listQuestions,
  readonly,
  onSubmit,
  onCancel,
}) => {
  const defaultValues = useMemo(
    () =>
      listQuestions?.length
        ? listQuestions.reduce(
            (acc, question) => ({
              ...acc,
              [question.name]: formatDefaultValueForForm(listQuestions, question.name, question.defaultValue),
            }),
            {},
          )
        : {},
    [listQuestions],
  );

  const defaultLoadedValues = useMemo(
    () =>
      selectedAssessmentItems?.length
        ? selectedAssessmentItems?.reduce(
            (acc, item) => ({
              ...acc,
              [item.question]: formatDefaultValueForForm(listQuestions, item.question, item.value),
            }),
            defaultValues,
          )
        : defaultValues,
    [defaultValues, listQuestions, selectedAssessmentItems],
  );

  const {reset, ...formMethods} = useForm<IDiseaseSeverityScreenerFormDto>({
    defaultValues: defaultLoadedValues,
  });

  const isIbdFirstQuestionSelected = useMemo(() => {
    const firstQuestionName = listQuestions.find((q) => q.displayOrder === 1);

    if (firstQuestionName?.type === AssessmentQuestionType.Dropdown) {
      const selectedValue = formMethods.watch(firstQuestionName?.name) as IOption<string>;

      return !selectedValue?.displayName;
    }

    return false;
  }, [listQuestions, formMethods]);

  const isSubmitDisabled = useMemo(
    () => isIbdFirstQuestionSelected || formMethods.formState.isSubmitting || formMethods.formState.isSubmitSuccessful,
    [formMethods.formState.isSubmitSuccessful, formMethods.formState.isSubmitting, isIbdFirstQuestionSelected],
  );

  return (
    <FormProvider {...formMethods} reset={reset}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Card>
          <DiseaseSeverityScreenerQuestions listQuestions={listQuestions} readonly={readonly} />
        </Card>
        {!readonly && (
          <div className={styles.buttonContainer}>
            <Button block className={styles.button} onClick={onCancel} testid={'survey-button-cancel'}>
              Cancel
            </Button>
            <Button
              block
              type={'submit'}
              className={styles.button}
              disabled={isSubmitDisabled}
              testid={'survey-button-submit'}>
              Submit
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export {DiseaseSeverityScreenerForm};
