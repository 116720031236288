export enum ELevelGoal {
  UNKNOWN = 0,
  EASY = 1,
  MEDIUM = 2,
  HARD = 3,
}

export interface ILevelBody {
  minScore: number;
  maxScore: number;
  level: number;
  description?: string;
  icon?: string;
}

export interface IGoalWorksheetForm {
  adaptive: string;
  adaptiveScore: number;
  effortLevel: number;
  goal: string;
  meaningful: string;
  meaningfulScore: number;
  patientCourseId: number;
  realistic: string;
  realisticScore: number;
  specific: string;
  specificScore: number;
  timeBound: string;
  timeBoundScore: number;
  totalScore: number;

  behaviorTypeId?: number;
  motivation?: EMotivationType;
}

export enum EMotivationType {
  CARROT = 'carrot',
  STICK = 'stick',
}

export interface IGoalDetails {
  goal: string;
  specific: string;
  meaningful: string;
  adaptive: string;
  realistic: string;
  timeBound: string;
  motivation: EMotivationType;
}

export interface IGoalScore {
  adaptiveScore: number;
  meaningfulScore: number;
  realisticScore: number;
  specificScore: number;
  timeBoundScore: number;
}

export interface IGoal {
  behaviorTypeGoalSettingId: number;
  behaviorTypeId: number;
  createdByUserId: number;
  createdDate: Date;
  description: string;
  levelOneExample: string;
  levelThreeExample: string;
  levelTwoExample: string;
  updatedByUserId: number;
  updatedDate: Date;
}

export interface ICreateGoalWorksheetModel {
  adaptive: string;
  adaptiveScore: number;
  behaviorTypeId: number;
  effortLevel: number;
  goal: string;
  meaningful: string;
  meaningfulScore: number;
  patientCourseId: number;
  realistic: string;
  realisticScore: number;
  specific: string;
  specificScore: number;
  timeBound: string;
  timeBoundScore: number;
  totalScore: number;
  type: number;
}

export interface IWorksheet {
  goalWorksheetId: number;
  patientCourseId: number;
  behaviorTypeId: number;
  courseName: string;
  goal: string;
  type: number;
  specific: string;
  specificScore: number;
  meaningful: string;
  meaningfulScore: number;
  adaptive: string;
  adaptiveScore: number;
  realistic: string;
  realisticScore: number;
  timeBound: string;
  timeBoundScore: number;
  totalScore: number;
  effortLevel: number;
  createdByUserId: number;
  createdDate: Date;
  updatedByUserId: number;
  updatedDate: Date;
  isDeleted: boolean;
  isArchived: boolean;
  goalAccomplished: boolean;
}

export interface IUpdateResponse {
  errorData: any;
  errorKey: any;
  errorMessage: any;
  isNotFound: boolean;
  isOk: boolean;
}

export interface IGoalReviewWorkflow {
  feelings: string | null;
  continueWorking?: boolean;
  goalAccomplished?: boolean;
  goalWorksheetId?: number;
  sendMessage?: boolean;
  shareResults?: boolean;
}

export interface IUpdateReviewWorkflow {
  continueWorking: boolean;
  courseId: number;
  feelings: string | null;
  goalAccomplished: boolean;
  goalWorksheetId: number;
  shareResults: boolean;
}

export interface IPopUpReviewWorkflow {
  courseId: number;
  title: string;
  worksheetId: number;
}
