import type {FC} from 'react';
import React from 'react';
import styles from './error.module.scss';
import {Icon, IconType} from '../icon';
import classNames from 'classnames';

interface IErrorProps {
  errorMessage: string;
  className?: string;
  name?: string;
}

const Error: FC<IErrorProps> = ({errorMessage, className, name}) => (
  <div className={classNames(styles.errorText, className)} data-testid={`error-${name}`}>
    <Icon type={IconType.AlertCircle} />
    <span>{errorMessage}</span>
  </div>
);

export {Error};
export type {IErrorProps};
