import type {FC} from 'react';
import React, {useMemo} from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, Modal} from '../../../components';
import styles from './documents-modal-download.module.scss';
import type {DocumentsModel} from '../../model';

interface IDocumentsModalDownloadProps {
  document: DocumentsModel;
  onClose: () => void;
  onDownload: (document: DocumentsModel) => void;
}

const DocumentsModalDownload: FC<IDocumentsModalDownloadProps> = ({onClose, onDownload, document}) => {
  const actions = useMemo(
    () => (
      <CardActions className={styles.cardActions}>
        <Button flat onClick={onClose} className={styles.cancel}>
          cancel
        </Button>
        <Button
          type={'button'}
          onClick={() => {
            onDownload(document);
          }}>
          Download
        </Button>
      </CardActions>
    ),
    [document, onClose, onDownload],
  );

  return (
    <Modal onClose={onClose}>
      <Card className={styles.card}>
        <CardHeader
          title="By selecting download, I am voluntarily requesting an electronic copy of my protected health information"
          closable
          noBorder
          titleLarge
          onClose={onClose}
          className={styles.cardHeader}
        />
        <CardContent className={styles.cardContent}>
          As the owner or agent of the owner of protected health information, I have authenticated my identity in
          connection with the record herein, and I hereby authorize Trellus Health to release the underlying protected
          health information. Should I disclose the underlying record following this download to someone, or another
          entity, not legally required to keep my protected health information confidential, I further understand my
          rights and protections under state and federal privacy laws may be precluded.
        </CardContent>
        {actions}
      </Card>
    </Modal>
  );
};

export {DocumentsModalDownload};
export type {IDocumentsModalDownloadProps};
