import type {IMedicationsCourseDiscontinueGetResponseDto, IMedicationsCourseGetResponseDto} from '../repository';
import {MedicationsCourseModel} from './medications-course.model';
import {EnumCommentUnionParseHelper, shiftTimezone} from '../../helpers';
import {MedicationsCourseDiscontinueModelFactory} from './medications-course-discontinue.model.factory';
import {MedicationsModelFactory} from './medications.model.factory';
import type {IMedicationsCourseDiscontinueFormDto, IMedicationsCourseFormDto} from '../components';
import type {MedicationsModel} from './medications.model';

class MedicationsCourseModelFactory {
  static fromGetResponseDto(dto: IMedicationsCourseGetResponseDto): MedicationsCourseModel {
    const medication = MedicationsModelFactory.fromCourseGetResponseDto(dto);
    const frequency = EnumCommentUnionParseHelper(dto.frequency, dto.frequencyOther);

    return new MedicationsCourseModel(
      dto.medicationCourseId,
      dto.patientId,
      medication,
      null,
      dto.startDate ? shiftTimezone(new Date(dto.startDate)) : null,
      dto.endDate ? shiftTimezone(new Date(dto.endDate)) : null,
      dto.dose,
      dto.unit,
      frequency,
      dto.frequencyOther,
      dto.isDiscontinued,
      dto.discontinuedDate ? shiftTimezone(new Date(dto.discontinuedDate)) : null,
      dto.reason,
      dto.reasonOther,
      dto.createdBy,
      shiftTimezone(new Date(dto.created)),
      dto.updatedBy,
      dto.lastUpdated ? shiftTimezone(new Date(dto.lastUpdated)) : null,
    );
  }

  static fromDiscontinueResponseDto(dto: IMedicationsCourseDiscontinueGetResponseDto): MedicationsCourseModel {
    const medication = MedicationsModelFactory.fromDiscontinueCourseGetResponseDto(dto);
    const frequency = EnumCommentUnionParseHelper(dto.frequency, dto.frequencyOther);
    const discontinue = MedicationsCourseDiscontinueModelFactory.fromGetResponseDto(dto);
    const reason = EnumCommentUnionParseHelper(dto.reason, dto.reasonOther);

    return new MedicationsCourseModel(
      dto.medicationCourseId,
      dto.patientId,
      medication,
      discontinue,
      dto.startDate ? shiftTimezone(new Date(dto.startDate)) : null,
      dto.endDate ? shiftTimezone(new Date(dto.endDate)) : null,
      dto.dose,
      dto.unit,
      frequency,
      dto.frequencyOther,
      true,
      dto.discontinuedDate ? shiftTimezone(new Date(dto.discontinuedDate)) : null,
      reason,
      dto.reasonOther,
      '',
      null,
    );
  }

  static fromFormDto(
    dto: IMedicationsCourseFormDto,
    patientId: number,
    options: MedicationsModel[],
    id?: number,
  ): MedicationsCourseModel {
    const medication = options.find((i) => i.safeId === dto.medicationSafeId);

    if (!medication) {
      throw new Error('no medication found');
    }

    const frequency = EnumCommentUnionParseHelper(dto.frequency, dto.frequencyOther);

    const reason = EnumCommentUnionParseHelper(dto.reason, dto.reasonOther);

    return new MedicationsCourseModel(
      id ?? -1,
      patientId,
      medication,
      null,
      dto.start,
      dto.end,
      dto.dose,
      dto.unit,
      frequency,
      dto.frequencyOther,
      dto.isDiscontinued,
      dto.discontinuedDate,
      reason,
      dto.reasonOther,
    );
  }

  static fromDiscontinueFormDto(dto: IMedicationsCourseDiscontinueFormDto, id: number): MedicationsCourseModel {
    const medication = MedicationsModelFactory.fromCourseDiscontinueFormDto(dto);
    const discontinue = MedicationsCourseDiscontinueModelFactory.fromCourseDiscontinueFormDto(dto);

    return new MedicationsCourseModel(id, -1, medication, discontinue);
  }
}

export {MedicationsCourseModelFactory};
