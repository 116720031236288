import type {Dispatch, FC, SetStateAction} from 'react';
import React, {useMemo, useState} from 'react';
import {Button, Card} from '../../../../../components';
import styles from './my-providers.module.scss';
import type {ProfileTabsEnum} from '../../profile.enum';
import type {IOption} from '../../../../../types';
import {ProvidersSearch} from './providers-search-form';
import {
  AddExternalProviderModal,
  InviteExternalProviderModal,
  ConfirmationModal,
  UpdateExternalProviderModal,
  DeleteExternalProviderModal,
} from './modals';
import {SearchResult} from './search-result';
import {MyProvidersList} from './my-providers-list';
import type {
  ExternalProvider,
  InviteInterface,
  MyExternalProvider,
  NPIProvider,
  SearchInterface,
  UpdateMyExternalProvider,
} from '../../../../../account';
import {ShareInfoDecisionEnum} from '../../../../../enums';

interface IMyProvidersProps {
  setActiveTab: Dispatch<SetStateAction<ProfileTabsEnum>>;
  isProfileFormSaving?: boolean;
  actions: {
    updateInfoDecision: (status: ShareInfoDecisionEnum) => void;
    providersSearch: (formData: SearchInterface) => void;
    addProvider: (model: UpdateMyExternalProvider) => void;
    updateProvider: (model: UpdateMyExternalProvider) => void;
    deleteProvider: (id: number) => void;
    inviteProvider: (model: InviteInterface) => void;
  };
  data: {
    isShowAllowModal: boolean;
    isShowShareToggle: boolean;
    myExternalProviders: MyExternalProvider[];
    states: IOption<string>[];
    searchResult: ExternalProvider[];
    searchNPIResult: NPIProvider[];
    isLoading: boolean;
  };
}
const MyProviders: FC<IMyProvidersProps> = ({setActiveTab, isProfileFormSaving, actions: propsActions, data}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(data.isShowAllowModal);
  const [updateProviderModalData, setUpdateProviderModalData] = useState<MyExternalProvider | undefined>();
  const [addProviderModalData, setAddProviderModalData] = useState<ExternalProvider | undefined>();
  const [inviteProviderModalData, setInviteProviderModalData] = useState<SearchInterface | undefined>();
  const [deleteProviderModalData, setDeleteProviderModalData] = useState<MyExternalProvider | undefined>();
  const [searchModel, setSearchModel] = useState<SearchInterface>();

  const [isEditMode, setEditMode] = useState(data.isShowAllowModal);

  const actions = {
    confirmation: {
      onSubmit: () => {
        propsActions.updateInfoDecision(ShareInfoDecisionEnum.Agree);
        setShowConfirmationModal(false);
      },
      onClose: () => {
        propsActions.updateInfoDecision(ShareInfoDecisionEnum.Never);
        setShowConfirmationModal(false);
      },
    },
    update: {
      setActive: (model: MyExternalProvider) => setUpdateProviderModalData(model),
      onSubmit: (model: MyExternalProvider) => {
        propsActions.updateProvider(model);
        setUpdateProviderModalData(undefined);
        setEditMode(false);
      },
      onClose: () => setUpdateProviderModalData(undefined),
    },
    add: {
      setActive: (model: ExternalProvider) => setAddProviderModalData(model),
      onSubmit: (model: ExternalProvider, isSharingInfo: boolean) => {
        propsActions.addProvider({providerId: model.providerId, isSharingInfo});
        setAddProviderModalData(undefined);
        setEditMode(false);
      },
      onClose: () => setAddProviderModalData(undefined),
    },
    invite: {
      setActive: (model: SearchInterface) => setInviteProviderModalData(model),
      onSubmit: (model: SearchInterface) => {
        propsActions.inviteProvider(model);
        setInviteProviderModalData(undefined);
        setEditMode(false);
      },
      onClose: () => setInviteProviderModalData(undefined),
    },
    delete: {
      setActive: (model: MyExternalProvider) => setDeleteProviderModalData(model),
      onSubmit: (providerId: number) => {
        propsActions.deleteProvider(providerId);
        setDeleteProviderModalData(undefined);
        setEditMode(false);
      },
      onClose: () => setDeleteProviderModalData(undefined),
    },
    search: {
      onSearch: (model: SearchInterface) => {
        propsActions.providersSearch(model);
        setSearchModel(model);
      },
      onClickResult: (model: ExternalProvider) => actions.add.setActive(model),
      onClickInvite: (model: SearchInterface) => actions.invite.setActive(model),
    },
  };

  const actionButtons = useMemo(
    () => (
      <div className={styles.actions}>
        {isEditMode ? (
          <div className={styles.cancel} onClick={() => setEditMode(false)}>
            Cancel
          </div>
        ) : (
          <Button className={styles.button} block onClick={() => setEditMode(true)} isLoading={isProfileFormSaving}>
            Edit Profile
          </Button>
        )}
      </div>
    ),
    [isEditMode, isProfileFormSaving],
  );

  return (
    <div className={styles.root}>
      {showConfirmationModal && (
        <ConfirmationModal onSubmit={actions.confirmation.onSubmit} onClose={actions.confirmation.onClose} />
      )}
      {addProviderModalData && (
        <AddExternalProviderModal
          onClose={actions.add.onClose}
          onSubmit={actions.add.onSubmit}
          provider={addProviderModalData}
          isShowShareToggle={data.isShowShareToggle}
        />
      )}
      {inviteProviderModalData && (
        <InviteExternalProviderModal
          provider={inviteProviderModalData}
          onClose={actions.invite.onClose}
          onSubmit={actions.invite.onSubmit}
        />
      )}
      {updateProviderModalData && (
        <UpdateExternalProviderModal
          provider={updateProviderModalData}
          onSubmit={actions.update.onSubmit}
          onClose={actions.update.onClose}
        />
      )}
      {deleteProviderModalData && typeof deleteProviderModalData !== 'undefined' && (
        <DeleteExternalProviderModal
          provider={deleteProviderModalData}
          onSubmit={actions.delete.onSubmit}
          onClose={actions.delete.onClose}
        />
      )}
      <Card className={styles.card}>
        {actionButtons}
        <MyProvidersList
          isEditMode={isEditMode}
          providers={data.myExternalProviders}
          onUpdate={actions.update.setActive}
          onDelete={actions.delete.setActive}
          isLoading={data.isLoading}
          isShowShareToggle={data.isShowShareToggle}
        />
      </Card>
      <Card className={styles.card}>
        <ProvidersSearch states={data.states} isEditMode={isEditMode} onSearch={actions.search.onSearch} />

        {searchModel && isEditMode && (
          <SearchResult
            searchResultClickHandler={actions.search.onClickResult}
            inviteProviderClickHandler={actions.search.onClickInvite}
            providers={data.searchResult}
            npiProviders={data.searchNPIResult}
            searchModel={searchModel}
            isLoading={data.isLoading}
          />
        )}
      </Card>
    </div>
  );
};

export {MyProviders};
