import type {IHealthMonitorQuery} from '../../health-monitor.repository.interface';
import {HealthMonitorPostRequestDto} from './health-monitor-post-request.dto';

class HealthMonitorPostRequestFactoryDto {
  static fromQuery(query: Partial<IHealthMonitorQuery>): HealthMonitorPostRequestDto | null {
    return !!query.type && !!query.patientId
      ? new HealthMonitorPostRequestDto(query?.startDate, query?.endDate, query.type, query.patientId)
      : null;
  }
}

export {HealthMonitorPostRequestFactoryDto};
