import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo} from 'react';
import type {ISelfEfficacyAssessmentService} from '../service';
import {SELF_EFFICACY_ASSESSMENT_SERVICE} from '../service';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import styles from './self-efficacy-assessment-list.module.scss';
import type {IAssessmentsTableRow} from '../../components';
import {AssessmentsTable, Button, PageLoader, PageTitle} from '../../components';
import {format} from 'date-fns';
import {DEFAULT_FORMAT} from '../../helpers';
import type {IAccountService} from '../../account';
import {ACCOUNT_SERVICE, calculateAge} from '../../account';
import {SpecialtyType} from '../../enums';

interface IServiceProps {
  account: IAccountService;
  selfEfficacyService: ISelfEfficacyAssessmentService;
}

const PAGE_TITLE = 'Self-Efficacy Scale';
const PAGE_TITLE_FOR_ADOLESCENT = 'Pediatric Adolescent Self-Efficacy Scale';

const SelfEfficacyAssessmentList: FC<IServiceProps> = ({account, selfEfficacyService}) => {
  const assessmentTableHeader = ['Date', 'Name'];

  const assessmentTableRows: IAssessmentsTableRow[] = selfEfficacyService.listAssessments.map((item) => {
    const date = item.lastUpdated ? format(item.lastUpdated, DEFAULT_FORMAT) : '';

    return {
      id: item.id,
      cells: [date, item.name],
    };
  });

  const pageTitle = useMemo(
    () =>
      account.info?.specialty?.name === SpecialtyType.IBD &&
      !!account.info?.dateOfBirth &&
      calculateAge(new Date(account.info?.dateOfBirth)) < 18
        ? PAGE_TITLE_FOR_ADOLESCENT
        : PAGE_TITLE,
    [account.info?.dateOfBirth, account.info?.specialty?.name],
  );

  const onReassessmentClick = useCallback(() => {
    selfEfficacyService.navigateToAssessment();
  }, [selfEfficacyService]);

  const navigateCallback = useCallback((id) => selfEfficacyService.navigateToAssessment(id), [selfEfficacyService]);

  useEffect(() => {
    selfEfficacyService.loadAssessments();
  }, [selfEfficacyService]);

  return (
    <>
      <div className={styles.headerContainer}>
        <PageTitle>{pageTitle}</PageTitle>
        {!selfEfficacyService.isReadonly && (
          <Button className={styles.button} onClick={onReassessmentClick} testid={'start-reassessment-button'}>
            {selfEfficacyService.listAssessments.length ? 'Start the reassessment' : 'Start the assessment'}
          </Button>
        )}
      </div>
      {selfEfficacyService.isLoading ? (
        <PageLoader />
      ) : (
        <AssessmentsTable
          header={assessmentTableHeader}
          rows={assessmentTableRows}
          onClickRow={navigateCallback}
          noResultsMessage={`Reminder to complete the ${pageTitle}`}
        />
      )}
    </>
  );
};

const SelfEfficacyAssessmentListObserver = observer(SelfEfficacyAssessmentList);

const SelfEfficacyAssessmentListInjected: FC = () => (
  <SelfEfficacyAssessmentListObserver
    account={useInjection(ACCOUNT_SERVICE)}
    selfEfficacyService={useInjection(SELF_EFFICACY_ASSESSMENT_SERVICE)}
  />
);

export {SelfEfficacyAssessmentListInjected as SelfEfficacyAssessmentList};
