import type {ISurveySpecElement, ISurveySpecElementOption} from '../../../../model';
import type {FC} from 'react';
import React from 'react';
import {parseMetaData} from './helpers';
import classNames from 'classnames';
import {
  BmImageType1,
  BmImageType2,
  BmImageType3,
  BmImageType4,
  BmImageType5,
  BmImageType6,
  BmImageType7,
} from '../../../../../assets/survey';
import styles from './radio-buttons-image-item.module.scss';

interface IRadioButtonsImageItemOption {
  id: number;
  text: string;
}

interface IRadioButtonsImageItemProps {
  element: ISurveySpecElement;
  option: ISurveySpecElementOption;
  value: IRadioButtonsImageItemOption;
  onChange?: (value: IRadioButtonsImageItemOption) => void;
  isError?: boolean;
  readonly?: boolean;
}

const getImage = (imageType: string) => {
  switch (imageType) {
    case 'image_type_1':
      return <BmImageType1 />;
    case 'image_type_2':
      return <BmImageType2 />;
    case 'image_type_3':
      return <BmImageType3 />;
    case 'image_type_4':
      return <BmImageType4 />;
    case 'image_type_5':
      return <BmImageType5 />;
    case 'image_type_6':
      return <BmImageType6 />;
    case 'image_type_7':
      return <BmImageType7 />;
    default:
      <></>;
  }
};

const RadioButtonsImageItem: FC<IRadioButtonsImageItemProps> = ({
  element,
  option,
  value,
  onChange,
  isError,
  readonly,
}) => {
  const radioButtonOption: IRadioButtonsImageItemOption = {
    id: option.elementOptionId,
    text: option.value,
  };

  const metaData = parseMetaData(option.metaData);

  return metaData ? (
    <div
      className={classNames(styles.optionWrapper, {[styles.optionHaveError]: isError}, {[styles.readonly]: readonly})}
      data-testid="radio-buttons-image-item"
      onClick={() => onChange && onChange(radioButtonOption)}
      key={option.elementOptionId}>
      <input
        className={classNames(styles.option, {[styles.optionError]: isError})}
        type="radio"
        value={String(radioButtonOption.id)}
        name={element.name}
        checked={radioButtonOption.id === value?.id}
        data-testid={`radio_buttons_image_item-${element.name}`}
        readOnly
      />
      <label className={classNames(styles.label, {[styles.readonly]: readonly})} htmlFor={element.name} />
      <div className={styles.type}>{metaData.type}</div>
      <div className={styles.image}>{getImage(metaData.imageType)}</div>
      <div className={styles.info}>
        <div className={styles.title}>{metaData.title}</div>
        <div className={styles.subTitle}>{metaData.subTitle}</div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export {RadioButtonsImageItem};
