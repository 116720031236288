enum EncounterMethodTypeEnum {
  'None' = 0,
  'Video' = 1,
  'Phone' = 2,
  'Email' = 3,
  'In-Person' = 4,
  'Message' = 5,
  'Other' = 10,
}

export {EncounterMethodTypeEnum};
