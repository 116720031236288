import {inject, injectable} from 'inversify';
import type {IPreScreeningRepository} from './pre-screening.repository.interface';
import {NotImplementedException} from '../../utils/exception/not-implemented.expection';
import type {PreScreeningModel} from '../model';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {IPreScreeningGetResponseDto} from './dto';
import {PreScreeningModelFactory} from '../model/pre-screening.model.factory';

@injectable()
class PreScreeningRepository implements IPreScreeningRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(query?: Partial<{}>): Promise<PreScreeningModel | null> {
    const {data: dtos} = await this._http.get<IPreScreeningGetResponseDto[]>('/PreScreening/getforpatient');

    return PreScreeningModelFactory.fromGetDto(dtos);
  }

  list(query?: Partial<{}>): Promise<PreScreeningModel[]> {
    throw new NotImplementedException();
  }

  remove(domain: PreScreeningModel): Promise<void> {
    throw new NotImplementedException();
  }

  save(domain: PreScreeningModel): Promise<void> {
    throw new NotImplementedException();
  }
}

export {PreScreeningRepository};
