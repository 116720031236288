import type {FC} from 'react';
import type {ErrorListItemColor, ErrorListItemFontSize} from './error-list-item.enum';
import styles from './errror-list-item.module.scss';
import classNames from 'classnames';

interface IErrorListItemProps {
  message: string;
  icon?: JSX.Element;
  color?: ErrorListItemColor;
  fontSize?: ErrorListItemFontSize;
}

const ErrorListItem: FC<IErrorListItemProps> = (props) => (
  <span
    className={classNames(
      styles.root,
      props.color ? styles[`messageColor${props.color}`] : styles.messageColorDefault,
      props.fontSize ? styles[`fontSize${props.fontSize}`] : styles.fontSizeDefault,
    )}>
    {props.icon}
    {props.message}
  </span>
);

export {ErrorListItem};
export type {IErrorListItemProps};
