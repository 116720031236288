import React, {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {CloseIcon} from '../../assets/icons';
import styles from './card-header.module.scss';

interface ICardHeaderProps {
  title: string;
  metaText?: string;
  logo?: string;
  closable?: boolean;
  noBorder?: boolean;
  titleCenter?: boolean;
  titleLarge?: boolean;
  childrenTitleInline?: boolean;
  className?: string;

  onClose?: () => void;
}

const CardHeader: React.FC<ICardHeaderProps> = (props) => {
  const meta = useMemo(
    () => props.metaText && <span className={styles.metaText}>{props.metaText}</span>,
    [props.metaText],
  );
  const logo = useMemo(
    () => props.logo && <img className={styles.logo} src={props.logo} alt={props.metaText} />,
    [props.logo, props.metaText],
  );
  const close = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    }
  }, [props]);
  const closeButton = useMemo(
    () => (
      <>
        {props.closable && (
          <button className={styles.closeButton} onClick={close}>
            <CloseIcon />
          </button>
        )}
      </>
    ),
    [props.closable, close],
  );

  return (
    <div
      className={classNames(styles.root, props.className, {
        [styles.rootBorder]: !props.noBorder,
      })}>
      <div className={styles.header}>
        {logo ?? meta}
        {closeButton}
      </div>
      <div
        className={classNames(styles.subHeader, {
          [styles.subHeaderCenter]: props.titleCenter,
        })}>
        <div
          className={classNames(styles.title, {
            [styles.titleCenter]: props.titleCenter,
            [styles.titleLarge]: props.titleLarge,
          })}>
          {props.title ?? ''}
        </div>
        {props.childrenTitleInline && props.children}
      </div>
      {!props.childrenTitleInline && props.children}
    </div>
  );
};

export {CardHeader};
export type {ICardHeaderProps};
