import {SurveyGetResponsesRequestDto} from './survey-get-responses-request.dto';
import type {ISurveyQuery} from '../../../survey.repository.interface';

class SurveyGetResponsesRequestDtoFactory {
  static fromQuery(query: Partial<ISurveyQuery>): SurveyGetResponsesRequestDto {
    return new SurveyGetResponsesRequestDto(query.patientId, query.type, query.periodType, query.date ?? null);
  }
}

export {SurveyGetResponsesRequestDtoFactory};
