import {isDate, isDropdown, isRadioButton} from '../index';
import type {IAssessmentFormDto, IAssessmentValue} from '../../assessment';

const getQuestionStringValue = (questionAnswer: IAssessmentValue) => {
  if (!questionAnswer) {
    return null;
  }

  if (isDropdown(questionAnswer)) {
    return questionAnswer.value;
  }

  if (isRadioButton(questionAnswer)) {
    return questionAnswer.id;
  }

  if (isDate(questionAnswer)) {
    return new Date(questionAnswer).getFullYear().toString();
  }

  return questionAnswer;
};

const parseFormulaToShowQuestion = (formula: string, formValues: IAssessmentFormDto) => {
  const defaultFunctionBody = 'return false';
  let functionBody = `return ${formula}`;
  let questionCurrentValue;

  const questionNames = functionBody.match(new RegExp(`\\\${(.*?)}`, 'g'));

  questionNames?.forEach((q) => {
    const questionName = q.replace(/[${}]/g, '');

    if (formValues) {
      questionCurrentValue = getQuestionStringValue(formValues[questionName]);

      functionBody = functionBody.replace(new RegExp(`\\\${${questionName}}`, 'g'), `"${questionCurrentValue}"`);
    }
  });

  // eslint-disable-next-line no-new-func
  return new Function(functionBody ?? defaultFunctionBody)();
};

export {parseFormulaToShowQuestion};
