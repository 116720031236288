import type {IHttpConfigService} from './http.config.service.interface';
import {injectable} from 'inversify';

declare var API_URL: string;

@injectable()
class HttpConfigService implements IHttpConfigService {
  readonly apiUrl: string;

  constructor() {
    try {
      // eslint-disable-next-line no-template-curly-in-string
      this.apiUrl = process.env.REACT_APP_API_URL || (API_URL === '${API_URL}' ? '/api' : API_URL);
    } catch {
      this.apiUrl = '/api';
    }
  }
}

export {HttpConfigService};
