import 'reflect-metadata';
import {Container} from 'inversify';
import {NotifierModule} from '../notifier';
import {AuthModule} from '../utils/auth';
import {HttpModule} from '../utils/http';
import {LocalStorageModule} from '../utils/local-storage';
import {StrengthsAssessmentModule} from '../strengths-assessment';
import {WssModule} from '../utils/wss';
import {VaccinationModule} from '../vaccination';
import {CookieModule} from '../utils/cookie';
import {EducationModule} from '../education';
import {RoutingModule} from '../utils/routing';
import {NotificationsModule} from '../notifications';
import {LabResultsModule} from '../lab-results';
import {CompanyModule} from '../company';
import {HealthMonitorModule} from '../health-monitor';
import {PreVisitModule} from '../pre-visit';
import {AppModule} from '../app';
import {QuoteModule} from '../quote';
import {EncounterModule} from '../encounter';
import {MessageModule} from '../message';
import {AssessmentReportModule} from '../assessment-report';
import {MedicationsModule} from '../medications';
import {ProfileSettingsModule} from '../profile-settings';
import {FileStorageModule} from '../file-storage';
import {TaskModule} from '../task';
import {CourseModule} from '../patient-course';
import {DocumentsModule} from '../documents';
import {PdfViewerModule} from '../pdf-viewer/ioc';
import {PreScreeningModule} from '../pre-screening/ioc';
import {ResilienceAssessmentModule} from '../resilience-assessment';
import {SelfEfficacyAssessmentModule} from '../self-efficacy-assessment';
import {DiseaseSeverityScreenerModule} from '../disease-severity-screener';
import {VideoStreamModule} from '../utils/video-stream';
import {EmotionalImpactScaleModule} from '../emotional-impact-scale';
import {BodyMassIndexModule} from '../body-mass-index';
import {ChronicConditionChecklistModule} from '../chronic-condition-checklist';
import {LayoutsModule} from '../layouts';
import {AlertIndicatorModule} from '../alert-indicator';
import {AccountModule} from '../account';
import {SurveyModule} from '../survey';
import {WorkProductivityModule} from '../work-productivity';
import {LifeQualityModule} from '../life-quality';
import {GlobalImprovementScaleModule} from '../global-improvement-scale';
import {PopupModule} from '../utils/popup';
import {ProfileVerificationModule} from '../profile-verification';
import {TrellisModule} from '../trellis-visualization/ioc';
import {ServicesModule} from '../services/ioc';
import {ThriveSkillModule} from '../thrive-skill/ioc/thrive-skill.module';

const container = new Container();

container.load(
  RoutingModule,
  CookieModule,
  LocalStorageModule,
  AuthModule,
  WssModule,
  HttpModule,
  TrellisModule,
  EncounterModule,
  QuoteModule,
  NotifierModule,
  StrengthsAssessmentModule,
  VaccinationModule,
  EducationModule,
  NotificationsModule,
  LabResultsModule,
  CompanyModule,
  HealthMonitorModule,
  PreVisitModule,
  MessageModule,
  AppModule,
  AssessmentReportModule,
  MedicationsModule,
  ProfileSettingsModule,
  ProfileVerificationModule,
  FileStorageModule,
  TaskModule,
  DocumentsModule,
  PdfViewerModule,
  ResilienceAssessmentModule,
  PreScreeningModule,
  SelfEfficacyAssessmentModule,
  DiseaseSeverityScreenerModule,
  EmotionalImpactScaleModule,
  VideoStreamModule,
  BodyMassIndexModule,
  ChronicConditionChecklistModule,
  LayoutsModule,
  AlertIndicatorModule,
  AccountModule,
  SurveyModule,
  WorkProductivityModule,
  LifeQualityModule,
  GlobalImprovementScaleModule,
  PopupModule,
  ServicesModule,
  CourseModule,
  ThriveSkillModule,
);

export {container};
