import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {IVaccinationQuery, IVaccinationRepository} from './vaccination.repository.interface';
import type {VaccinationModel} from '../model';
import {VaccinationModelFactory} from '../model';
import type {IVaccinationGetResponseDto} from './dto';
import {VaccinationCreateRequestDtoFabric, VaccinationUpdateRequestDtoFabric} from './dto';

@injectable()
class VaccinationRepository implements IVaccinationRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async find(query?: Partial<IVaccinationQuery>): Promise<VaccinationModel | null> {
    let model: VaccinationModel | null = null;

    const res = await this._http.get<IVaccinationGetResponseDto>(`/VaccinationRecord`);

    if (!!res.data) {
      model = VaccinationModelFactory.fromGetResponseDto(res.data);
    }

    return model;
  }

  list(query?: Partial<IVaccinationQuery>): Promise<VaccinationModel[]> {
    throw new Error('Not implemented');
  }

  remove(model: VaccinationModel): Promise<void> {
    throw new Error('Not implemented');
  }

  async save(model: VaccinationModel): Promise<VaccinationModel> {
    let responseDto: IVaccinationGetResponseDto | null;

    if (!!model.id) {
      const dto = VaccinationUpdateRequestDtoFabric.fromModel(model);

      const result = await this._http.put<IVaccinationGetResponseDto>(`/VaccinationRecord/${model.id}`, dto);
      responseDto = result.data;
    } else {
      const dto = VaccinationCreateRequestDtoFabric.fromModel(model);
      const result = await this._http.post<IVaccinationGetResponseDto>('/VaccinationRecord', dto);
      responseDto = result.data;
    }

    if (!responseDto) {
      throw new Error('Response dto is undefined');
    }

    return VaccinationModelFactory.fromGetResponseDto(responseDto);
  }
}

export {VaccinationRepository};
