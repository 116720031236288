import styles from './numerical-indicator.component.module.scss';

interface INumericalIndicatorProps {
  x?: number;
  y?: number;
  numericalIndicator?: number | string;
  radius?: number;
  textSize?: number;
}

const NumericalIndicator = ({
  numericalIndicator = 0,
  x = 0,
  y = 0,
  radius = 24,
  textSize = 28,
}: INumericalIndicatorProps): JSX.Element => (
  <>
    {numericalIndicator && (
      <svg viewBox="0 0 160 160" width="40" height="40" x={x} y={y} data-testid="numerical-indicator">
        {/* Green Circle */}
        <circle cx="50" cy="50" r={radius} fill="#c8e7dec2" stroke="green" strokeWidth={4} />

        {/* Dynamic Number Text Adjust the x and y attributes to position the text correctly */}
        <text
          x="50"
          y="50"
          text-anchor="middle"
          fill="green"
          font-size={textSize}
          font-family="Arial"
          fontWeight="bold"
          dy=".3em"
          className={styles.indicatorText}>
          {numericalIndicator}
        </text>
      </svg>
    )}
  </>
);

export {NumericalIndicator};
