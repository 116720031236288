import type {FC} from 'react';
import React, {useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Button, Card} from '../../components';
import styles from './emotional-impact-scale-form.module.scss';
import type {EmotionalImpactScaleQuestionModel, IEmotionalImpactScaleItem} from '../model';
import {EmotionalImpactScaleQuestions} from './questions';
import {formatDefaultValueForForm} from '../../helpers';
import type {IEmotionalImpactScaleFormDto} from '../service';

interface IEmotionalImpactScaleFormProps {
  selectedAssessmentItems: IEmotionalImpactScaleItem[] | null;
  listQuestions: EmotionalImpactScaleQuestionModel[];
  onSubmit: (data: IEmotionalImpactScaleFormDto) => void;
  onCancel: () => void;
  submitDisabled: boolean;
  readonly: boolean;
}

const EmotionalImpactScaleForm: FC<IEmotionalImpactScaleFormProps> = ({
                                                                        selectedAssessmentItems,
                                                                        listQuestions,
                                                                        onSubmit,
                                                                        onCancel,
                                                                        submitDisabled,
                                                                        readonly,
                                                                      }) => {
  const defaultValues = useMemo(
    () =>
      listQuestions?.length
        ? listQuestions.reduce(
            (acc, question) => ({
              ...acc,
              [question.name]: formatDefaultValueForForm(listQuestions, question.name, question.defaultValue),
            }),
            {},
          )
        : {},
    [listQuestions],
  );

  const defaultLoadedValues = useMemo(
    () =>
      selectedAssessmentItems?.length
        ? selectedAssessmentItems?.reduce(
            (acc, item) => ({
              ...acc,
              [item.question]: formatDefaultValueForForm(listQuestions, item.question, item.value),
            }),
            defaultValues,
          )
        : defaultValues,
    [defaultValues, listQuestions, selectedAssessmentItems],
  );

  const {reset, ...formMethods} = useForm<IEmotionalImpactScaleFormDto>({defaultValues: defaultLoadedValues});

  const actions = useMemo(
    () => (
      <div className={styles.buttonContainer}>
        <Button block className={styles.button} onClick={onCancel} testid={'survey-button-cancel'}>
          Cancel
        </Button>

        <Button
          block
          type={'submit'}
          className={styles.button}
          disabled={submitDisabled}
          testid={'survey-button-submit'}>
          Done
        </Button>
      </div>
    ),
    [onCancel, submitDisabled],
  );

  return (
    <FormProvider reset={reset} {...formMethods}>
      {!readonly && <div className={styles.subTitle}>In the past 7 days:</div>}
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Card>
          <EmotionalImpactScaleQuestions listQuestions={listQuestions} readonly={readonly}/>
        </Card>
        {!readonly && actions}
      </form>
    </FormProvider>
  );
};

export {EmotionalImpactScaleForm};
