import {observer} from 'mobx-react';
import {useCallback, useEffect, useState} from 'react';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import {Button, CollapseDescription, PageLoader, PageTitle, SecondaryModal} from '../../../components';
import {useFormConfirmationDialog, useQuery} from '../../../hooks';
import {useInjection} from '../../../ioc';
import type {IGlobalImprovementScaleFormDto, IGlobalImprovementScaleService} from '../../service';
import {GLOBAL_IMPROVEMENT_SCALE_SERVICE} from '../../service';
import {GlobalImprovementScaleForm} from '../form';
import styles from './global-improvement-scale-screen.module.scss';

interface IServiceProps {
  globalImprovementScaleService: IGlobalImprovementScaleService;
  account: IAccountService;
}

const GlobalImprovementScaleScreen: React.FC<IServiceProps> = ({globalImprovementScaleService, account}) => {
  const {id: assessmentId} = useQuery<{id: number}>();

  const [visibleCancelModal, setVisibleCancelModal] = useState<boolean>(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const {getConfirmation} = useFormConfirmationDialog();

  const patientId = account.id;
  const selectedAssessment =
    globalImprovementScaleService.selectedAssessment || globalImprovementScaleService.lastFilledAssessment;

  useEffect(() => {
    if (assessmentId) {
      globalImprovementScaleService.loadAssessmentById(assessmentId);
    } else {
      globalImprovementScaleService.loadAssessments();
    }
  }, [assessmentId, globalImprovementScaleService]);

  const onBack = useCallback(() => globalImprovementScaleService.navigateToList(), [globalImprovementScaleService]);

  const onModalClose = useCallback(() => setVisibleCancelModal(false), []);

  const onCancel = useCallback(() => {
    setVisibleCancelModal(true);
  }, [setVisibleCancelModal]);

  const onSubmit = useCallback(
    async (data: IGlobalImprovementScaleFormDto) => {
      setSubmitDisabled(true);

      const isConfirmed = await getConfirmation({});

      if (isConfirmed) {
        patientId && (await globalImprovementScaleService.createAssessment(data, patientId));
      } else {
        setSubmitDisabled(false);
      }
    },
    [getConfirmation, patientId, globalImprovementScaleService],
  );

  const readonly = !!assessmentId || globalImprovementScaleService.isReadonly;

  return (
    <>
      <PageTitle className={styles.header}>Global Improvement Scale</PageTitle>
      {readonly && !globalImprovementScaleService.isLoading && (
        <Button className={styles.button} onClick={onBack}>
          Back
        </Button>
      )}
      <CollapseDescription>
        <p>It is important to learn how much improvement you have had in your IBS symptoms</p>
      </CollapseDescription>
      {globalImprovementScaleService.isLoading ? (
        <PageLoader />
      ) : (
        <GlobalImprovementScaleForm
          selectedAssessmentItems={selectedAssessment?.items ?? null}
          listQuestions={globalImprovementScaleService.listQuestions}
          readonly={readonly}
          isSubmitDisabled={isSubmitDisabled}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
      {visibleCancelModal && (
        <SecondaryModal
          text={'All answers will be lost. Do you want to cancel the assessment?'}
          onClose={onModalClose}
          onSubmit={onBack}
        />
      )}
    </>
  );
};

const GlobalImprovementScaleScreenObserver = observer(GlobalImprovementScaleScreen);

const GlobalImprovementScaleScreenObserverInjected: React.FC = () => (
  <GlobalImprovementScaleScreenObserver
    globalImprovementScaleService={useInjection(GLOBAL_IMPROVEMENT_SCALE_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
  />
);

export {GlobalImprovementScaleScreenObserverInjected as GlobalImprovementScaleScreen};
