import type {FC} from 'react';
import styles from './progress-bar.module.scss';
import classNames from 'classnames';

interface IProgressBarProps {
  step: number;
  totalSteps: number;
  title: string;
}

const ProgressBar: FC<IProgressBarProps> = (props) => (
  <div className={styles.container}>
    <div className={styles.info} data-testid="progressBar">
      <div
        className={classNames(
          styles.circleProgress,
          props.step === 1 ? styles.thirdProgress : props.step === 2 ? styles.twoThirdsProgress : null,
        )}
      />
      <div className={styles.title}>
        {props.step}/{props.totalSteps} {props.title}
      </div>
    </div>
  </div>
);

export {ProgressBar};
export type {IProgressBarProps};
