import classNames from 'classnames';
import type {FC} from 'react';
import {useCallback, useState} from 'react';
import {ArrowIconDown, ArrowIconRight} from '../../assets/icons';
import styles from './list.module.scss';

enum ListDataTestIdEnum {
  Wrapper = 'wrapper',
}

interface IListProps {
  noStyle?: boolean;
  numeric?: boolean;
  title?: string | JSX.Element;
  sixColumns?: boolean;
  collapse?: boolean;
  className?: string;
}

const List: FC<IListProps> = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  const TitleElement = props.title && <p className={styles.title}>{props.title}</p>;
  const ListElement = props.numeric ? 'ol' : 'ul';
  const CollapseIcon = props.collapse ? collapseOpen ? <ArrowIconDown /> : <ArrowIconRight /> : null;

  const Wrapper = useCallback(
    (wrapperProps) => {
      if (props.title) {
        return <div data-testid={ListDataTestIdEnum.Wrapper}>{wrapperProps.children}</div>;
      } else {
        return <>{wrapperProps.children}</>;
      }
    },
    [props.title],
  );

  const handleCollapse = () => {
    if (props.collapse) {
      setCollapseOpen(!collapseOpen);
    }
  };

  return (
    <Wrapper>
      <span
        data-testid="title-span"
        className={classNames({[styles.titleHolder]: props.collapse})}
        onClick={handleCollapse}>
        {TitleElement} {CollapseIcon}
      </span>
      <ListElement
        className={classNames(
          styles.list,
          {
            [styles.noStyle]: props.noStyle,
            [styles.sixColumns]: props.sixColumns,
            [styles.collapsibleContent]: props.collapse,
            [styles.collapsed]: props.collapse && !collapseOpen,
            [styles.expanded]: props.collapse && collapseOpen,
          },
          props.className,
        )}>
        {props.children}
      </ListElement>
    </Wrapper>
  );
};

export {List, ListDataTestIdEnum};
export type {IListProps};
