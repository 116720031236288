enum MedicationsFrequenciesTypesEnum {
  BlankPerNull = 0,
  Daily = 1,
  TwiceDaily = 2,
  Weekly = 3,
  TwiceWeekly = 4,
  PRN = 5,
  Other = 6,
  EveryTwoWeeks = 7,
  EveryFourWeeks = 8,
  EverySixWeeks = 9,
  EveryEightWeeks = 10,
}

export {MedicationsFrequenciesTypesEnum};
