import type {ISurveyElementResponse, SurveyStatus} from '../../../model';

interface ISurveyResponse {
  surveyId: number;
  elementResponses: Array<ISurveyElementResponse>;
}

class SurveyPostDto {
  constructor(
    public patientId: number,
    public surveyResponse: ISurveyResponse | null = null,
    public status?: SurveyStatus,
    public formType?: number | null,
    public date?: string,
  ) {}
}

export {SurveyPostDto};
export type {ISurveyResponse};
