import type {SurveySpecElementType} from './enum';
import {SurveyType} from './enum';

interface ISurveySpecElementOption {
  elementOptionId: number;
  elementId: number;
  name: string;
  value: string;
  optionDisplayOrder: number | null;
  metaData: string | null;
  weight: number | null;
}

interface ISurveySpecElementMetadata {
  sectionType: number | null;
  additionalText: string | null;
  diseaseType: number | null;
  additionalElement: boolean | null;
  maxWidth: string | null;
  height: string | null;
  width: string | null;
  textTemplate: string | null;
  sourceEndpoint: string | null;
  textType: number | null;
  sectionKey: string | null;
  layoutType: number | null;
  toolTip: string | null;
  defaultSubElementCount: number | null;
  maxSubElementCount: number | null;
  formula: string | null;
  nullable: boolean | null;
  searchOptions: boolean | null;
  formType: number | null;
  placeholder: string | null;
  confirmationMessage: string | null;
  isTextFormula: boolean;
  linkedDynamicSection: string | null;
  clearedIf: string | null;
  linkedElement: string | null;
  href: string | null;
  minValue: string | null;
  maxValue: string | null;
  hiddenElementType: number | null;
  optionalFormTypes: number | null;
  alwaysEditable: boolean | null;
  doNotCopySectionWithValue: string | null;
  image: string | null;
  defaultValue: string | null;
  isSubQuestion: boolean | null;
  isIntegerNumber?: boolean | null;
  isCurrentDateMaxDate?: boolean | null;
  maxLength?: number | null;
  decimalPrecision?: number | null;
}

interface ISurveySpecElement {
  elementId: number;
  surveyId: number;
  name: string;
  text: string;
  elementType: SurveySpecElementType;
  displayOrder: number | null;
  isRequired: boolean;
  visibleIf: string | null;
  disabledIf: string | null;
  metadata: ISurveySpecElementMetadata | null;
  options: Array<ISurveySpecElementOption>;
  subElements: Array<ISurveySpecElement>;
  notProvidedIf: string | null;
}

class SurveySpecModel {
  public surveyId: number = 0;
  public title: string = '';
  public surveyType: SurveyType = SurveyType.Undefined;
  public elements: Array<ISurveySpecElement> = [];
}

export {SurveySpecModel};
export type {ISurveySpecElementOption, ISurveySpecElementMetadata, ISurveySpecElement};
