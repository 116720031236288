import type {FC} from 'react';
import React, {Fragment, useCallback, useMemo} from 'react';
import type {RichText} from '../../types';
import {Patterns} from '../../helpers/patterns';
import {isUrl} from '../../helpers';

interface IRichTextComponentProps {
  text: RichText;
}

const PARAGRAPH = '\n\n';

const RichTextComponent: FC<IRichTextComponentProps> = ({text}) => {
  const searchAndConvertTextInLink = useCallback(
    (text: string) =>
      text
        .split(' ')
        .map((str, index) => {
          if (str.match(Patterns.Url)) {
            const url = isUrl(str) ? str : `http://${str}`;

            return (
              <a key={url + index} href={url} target="_blank" rel="noreferrer">
                {str}
              </a>
            );
          }

          return str;
        })
        .reduce((acc: Array<string | JSX.Element>, item) => (!acc.length ? [item] : [...acc, ' ', item]), []),
    [],
  );

  const elements = useMemo(
    () =>
      text.map((i, index) => {
        const key = String(new Date().getTime()) + i + index;
        const urlParams = typeof i !== 'string' && !!i.params ? new URLSearchParams(i.params).toString() : '';

        return (
          <Fragment key={key}>
            {typeof i === 'string' ? (
              i.includes(PARAGRAPH) ? (
                <span dangerouslySetInnerHTML={{__html: i.replaceAll(PARAGRAPH, '<br/><br/>')}} />
              ) : (
                <span>{searchAndConvertTextInLink(i)}</span>
              )
            ) : (
              <a
                key={key}
                href={`${i.href}${urlParams ? `?${urlParams}` : ''}`}
                rel="noreferrer"
                target={i.href === 'messages' ? '_self' : '_blank'}
                style={{textDecoration: 'underline'}}>
                {i.title}
              </a>
            )}
          </Fragment>
        );
      }),
    [searchAndConvertTextInLink, text],
  );

  return <>{elements}</>;
};

export {RichTextComponent};
export type {IRichTextComponentProps};
