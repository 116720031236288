export interface MaskedInputProps {
  value: string;
  label?: string;
  onValueChanged?: (value: string) => void;
  mask: string | Array<string | RegExp>;
  isValid?: boolean;
  name?: string;
  className?: string;
  labelClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: MaskedInputType;
  readOnly?: boolean;
  errorMessage?: string;
  alwaysShowMask?: boolean;
  maskPlaceholder?: string | null;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

export enum MaskedInputType {
  Default = 'default',
  LabelInInput = 'labelInInput',
}
