import type {FC} from 'react';
import React from 'react';
import {Button, Card, CardActions, CardContent} from '../../../components';
import styles from './no-medications-to-add.module.scss';

interface INoMedicationsToAddConfirmModalProps {
  onClose: () => void;
  onSubmit: () => void;
}

const NoMedicationsToAddConfirmModal: FC<INoMedicationsToAddConfirmModalProps> = ({onClose, onSubmit}) => (
  <Card borderLess>
    <CardContent className={styles.cardContent}>Please confirm there are no medications to add</CardContent>
    <CardActions>
      <Button flat onClick={onClose}>
        No
      </Button>
      <Button bold type={'button'} onClick={onSubmit}>
        Yes
      </Button>
    </CardActions>
  </Card>
);

export {NoMedicationsToAddConfirmModal};
