import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import styles from './disease-information.module.scss';
import {Button, PageLoader, Preloader, ProgressBar, SingleDropdown} from '../../../components';
import {DiagnosisElapsedType, SpecialtyType} from '../../../enums';
import {OnBoardingLayout} from '../../../layouts';
import {Controller, useForm} from 'react-hook-form';
import classNames from 'classnames';
import {AlertCircleIcon} from '../../../assets/icons';
import {REQUIRED_FIELD_ERROR} from '../../../helpers';
import type {IOption} from '../../../types';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import {useInjection} from '../../../ioc';

interface IDiseaseInformationForm {
  diseaseId?: number | null;
  elapsedTime?: number;
  provider?: number;
  specialtyId?: number | null;
}

const DIAGNOSIS_ELAPSED_TYPES = [
  {
    value: DiagnosisElapsedType.ThisMonth,
    displayName: 'This Month',
  },
  {
    value: DiagnosisElapsedType.ThisYear,
    displayName: 'This Year',
  },
  {
    value: DiagnosisElapsedType.From1To5Years,
    displayName: '1-5 Years',
  },
  {
    value: DiagnosisElapsedType.Over5YearsAgo,
    displayName: 'Over 5 Years Ago',
  },
];

const DISEASE_TYPE_TITLE = 'Disease Type';
const SPECIALTY_TYPE_TITLE = 'Diagnosis';

interface IService {
  account: IAccountService;
}

const DiseaseInformationScreen: FC<IService> = ({account}) => {
  const {handleSubmit, control, errors, reset, setValue} = useForm<IDiseaseInformationForm>();

  const [isDiseaseType, setIsDiseaseType] = useState<boolean>(false);
  const [specialtyName, setSpecialtyName] = useState<string>('');

  const isShowDiseaseTypeDropdown = isDiseaseType && specialtyName !== SpecialtyType.IBS;

  const specialties: IOption[] = useMemo(
    () => [
      ...(account.specialties.map((s) => ({
        value: s.id,
        displayName: s.name,
      })) ?? []),
    ],
    [account.specialties],
  );

  const diseaseTypes: IOption[] = useMemo(
    () => [
      ...(account.diseaseTypes.map((s) => ({
        value: s.id,
        displayName: s.name,
      })) ?? []),
    ],
    [account.diseaseTypes],
  );

  const specialtyReadonly = useMemo(
    () => specialties.find((specialty) => specialty.value === account.diseaseInfo?.specialtyId)?.displayName,
    [account.diseaseInfo?.specialtyId, specialties],
  );

  const diseaseReadonly = useMemo(
    () => diseaseTypes.find((diseaseType) => diseaseType.value === account.diseaseInfo?.diseaseId)?.displayName,
    [account.diseaseInfo?.diseaseId, diseaseTypes],
  );

  const loadDiseaseTypes = useCallback(
    (specialtyId: number) => {
      account.loadDiseaseTypesBySpecialty(specialtyId);
    },
    [account],
  );

  useEffect(() => {
    Promise.all([account.getProviders(), account.loadSpecialties(), account.getDisease()]);
  }, [account]);

  useEffect(() => {
    if (account.diseaseInfo) {
      const {diseaseId, diagnosisElapsedTime, specialtyId} = account.diseaseInfo;
      setIsDiseaseType(!!specialtyId);
      !!specialtyId && loadDiseaseTypes(+specialtyId);

      reset({
        diseaseId: diseaseId === 0 ? undefined : diseaseId,
        elapsedTime: !diagnosisElapsedTime ? undefined : diagnosisElapsedTime,
        specialtyId: specialtyId === 0 ? undefined : specialtyId,
      });
      const specialty = specialtyId && specialties.find((specialty) => specialty.value === specialtyId);
      if (specialty) {
        setSpecialtyName(specialty.displayName);
      }
    }
  }, [account.diseaseInfo, loadDiseaseTypes, reset, setSpecialtyName, specialties]);

  const onSubmit = useCallback(
    (data: IDiseaseInformationForm) => {
      const diseaseInfo = {
        patientId: Number(account.id),
        diseaseId: Number(data.diseaseId),
        diagnosisElapsedTime: Number(data.elapsedTime),
        specialtyId: Number(data.specialtyId),
      };

      account.setDisease(diseaseInfo);
    },
    [account],
  );

  const handleChange = useCallback(
    (value, onChange) => {
      onChange(value);
      setIsDiseaseType(!!value);
      setValue('diseaseId', 0);
      !!value && loadDiseaseTypes(+value);
    },
    [loadDiseaseTypes, setValue],
  );

  const showPreloader: false | JSX.Element = useMemo(
    () => (account.areDiseaseTypesLoading || account.isSaving) && <Preloader isSpinnerShown={!account.isSaving} />,
    [account.areDiseaseTypesLoading, account.isSaving],
  );

  return (
    <OnBoardingLayout>
      <div className={styles.content}>
        <ProgressBar
          title={'Your Account Setup'}
          totalSteps={account.onboardingStepsCount}
          step={account.onboardingStepsCount - 1}
        />
        {showPreloader}
        {account.isLoading && <PageLoader />}
        {!account.isLoading && (
          <>
            <div className={styles.mainTitle}>
              Your personalized roadmap is being built and we look forward to learning more about you and your goals for
              your Trellus Elevate&trade; journey.
              <br /> Tell us more about you, and we will see you soon!
            </div>
            <div className={styles.title}>Disease Information</div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name={'specialtyId'}
                defaultValue={null}
                control={control}
                rules={{required: REQUIRED_FIELD_ERROR}}
                render={({value, name, onChange}) => {
                  const dropdownValue = specialties.find((type) => type.value === value);

                  if (dropdownValue) {
                    setSpecialtyName(dropdownValue.displayName);
                  }

                  return !specialtyReadonly ? (
                    <SingleDropdown
                      name={name}
                      options={specialties}
                      value={dropdownValue}
                      placeholder={SPECIALTY_TYPE_TITLE}
                      className={classNames(styles.dropdown, {
                        [styles.dropdownError]: !!errors.specialtyId?.message,
                      })}
                      onChange={(selectedItem) => handleChange(selectedItem.value, onChange)}
                      isValid={!errors.specialtyId?.message}
                    />
                  ) : (
                    <div className={styles.diseaseItemReadonly}>
                      <span className={styles.readonlyTitle}>{SPECIALTY_TYPE_TITLE}</span>
                      <span>{specialtyReadonly}</span>
                    </div>
                  );
                }}
              />
              {errors.specialtyId?.message && (
                <div className={styles.errorMessage}>
                  <AlertCircleIcon />
                  <span>{errors.specialtyId?.message}</span>
                </div>
              )}

              {isShowDiseaseTypeDropdown && (
                <>
                  <Controller
                    name={'diseaseId'}
                    control={control}
                    rules={{required: REQUIRED_FIELD_ERROR}}
                    render={({value, name, onChange}) => {
                      const dropdownValue = diseaseTypes.find((type) => type.value === value);

                      return !diseaseReadonly ? (
                        <SingleDropdown
                          name={name}
                          options={diseaseTypes}
                          value={dropdownValue}
                          placeholder={DISEASE_TYPE_TITLE}
                          className={classNames(styles.dropdown, {
                            [styles.dropdownError]: !!errors.diseaseId?.message,
                          })}
                          onChange={(selectedItem) => onChange(selectedItem.value)}
                          isValid={!errors.diseaseId?.message}
                        />
                      ) : (
                        <div className={styles.diseaseItemReadonly}>
                          <span className={styles.readonlyTitle}>{DISEASE_TYPE_TITLE}</span>
                          <span>{diseaseReadonly}</span>
                        </div>
                      );
                    }}
                  />
                  {errors.diseaseId?.message && (
                    <div className={styles.errorMessage}>
                      <AlertCircleIcon />
                      <span>{errors.diseaseId?.message}</span>
                    </div>
                  )}
                </>
              )}
              <Controller
                name={'elapsedTime'}
                control={control}
                defaultValue={null}
                rules={{required: REQUIRED_FIELD_ERROR}}
                render={({value, name, onChange}) => {
                  const dropdownValue = DIAGNOSIS_ELAPSED_TYPES.find((type) => type.value === value);

                  return (
                    <SingleDropdown
                      name={name}
                      options={DIAGNOSIS_ELAPSED_TYPES}
                      value={dropdownValue}
                      placeholder={'Time Since Diagnosis'}
                      className={classNames(styles.dropdown, {[styles.dropdownError]: !!errors.elapsedTime?.message})}
                      onChange={(selectedItem) => onChange(selectedItem.value)}
                      isValid={!errors.elapsedTime?.message}
                    />
                  );
                }}
              />
              {errors.elapsedTime?.message && (
                <div className={styles.errorMessage}>
                  <AlertCircleIcon />
                  <span>{errors.elapsedTime?.message}</span>
                </div>
              )}

              {errors.provider?.message && (
                <div className={styles.errorMessage}>
                  <AlertCircleIcon />
                  <span>{errors.provider?.message}</span>
                </div>
              )}

              <div className={styles.contentImage} />

              <Button
                className={classNames(styles.submitButton, {[styles.loadingButton]: account.isSaving})}
                type={'submit'}
                isLoading={account.isSaving}>
                continue
              </Button>
            </form>
          </>
        )}
      </div>
    </OnBoardingLayout>
  );
};

const DiseaseInformationScreenObserver = observer(DiseaseInformationScreen);
const DiseaseInformationScreenObserverInjected: FC = () => (
  <DiseaseInformationScreenObserver account={useInjection(ACCOUNT_SERVICE)} />
);

export {DiseaseInformationScreenObserverInjected as DiseaseInformationScreen};
