import {makeAutoObservable} from 'mobx';
import type {ICourseService} from './course.service.interface';
import type {Course} from '../model/course';
import {inject, injectable} from 'inversify';
import type {ICourseRepository} from '../repository';
import {COURSE_REPOSITORY} from '../repository';
import type {IFileStorageService} from '../../file-storage';
import {FILE_STORAGE_SERVICE} from '../../file-storage';
import type {FileStorageModel} from '../../file-storage/model';
import type {CourseTask} from '../model/course-task';

@injectable()
class CourseService implements ICourseService {
  constructor(
    @inject(COURSE_REPOSITORY) private readonly _repo: ICourseRepository,
    @inject(FILE_STORAGE_SERVICE) private readonly _fileService: IFileStorageService,
  ) {
    makeAutoObservable(this);
  }

  private _courses: Course[] = [];

  get courses(): Course[] {
    return this._courses;
  }

  get completed(): Course[] {
    return this._courses.filter((course) => course.completed);
  }

  get inCompleted(): Course[] {
    return this._courses.filter((course) => !course.completed);
  }

  async loadCourses(): Promise<Course[]> {
    const courses = await this._repo.list();
    for (const course of courses) {
      let courseFetchedImage: FileStorageModel | null = null;
      if (course.behaviorTypeThumbnailObjectFileId) {
        courseFetchedImage = await this._fileService.get(course.behaviorTypeThumbnailObjectFileId as string);
      }
      course.courseImage = courseFetchedImage ?? course.categoryDefaultThumbnailPath;

      for (const lesson of course.tasks) {
        let fetchedImage: FileStorageModel | null = null;
        if (lesson.courseThumbnailObjectFileId) {
          fetchedImage = await this._fileService.get(lesson.courseThumbnailObjectFileId);
        }
        if (fetchedImage !== null) {
          lesson.courseThumbnailObjectFile = fetchedImage;
        } else {
          lesson.courseThumbnailObjectFile = course.courseImage;
        }
      }
      course.tasks.sort((task1, task2) => +task1.complete - +task2.complete);
    }

    this._courses = courses;

    return courses;
  }

  async setFavoriteLesson(taskId: number, isFavoriteLesson: boolean): Promise<void> {
    await this._repo
      .setFavoriteLesson(taskId, isFavoriteLesson)
      .then(() => this.loadCourses())
      .catch(console.error);
  }

  get incompleteLessons(): CourseTask[] {
    return this._courses.reduce(
      (acc, course) => [...acc, ...course.tasks.filter((task) => !task.complete)],
      [] as CourseTask[],
    );
  }

  get completeLessons(): CourseTask[] {
    return this._courses.reduce(
      (acc, course) => [...acc, ...course.tasks.filter((task) => task.complete)],
      [] as CourseTask[],
    );
  }

  updateTask(taskId: number, begin: boolean, skip?: boolean): void {
    this._repo.updateTask(taskId, begin, skip).finally(() => this.loadCourses());
  }
}

export {CourseService};
