import type {FC} from 'react';
import React from 'react';
import styles from './add-another-button.module.scss';
import {CirclePlusIcon} from '../../assets/icons';
import classNames from 'classnames';

interface IAddAnotherButton {
  onClick: () => void;
  name?: string;
  className?: string;
}

const AddAnotherButton: FC<IAddAnotherButton> = ({onClick, name, className}) => (
  <div
    className={classNames(styles.addButtonContainer, className)}
    onClick={onClick}
    data-testid={`medical-history-${name}-add-another`}>
    <CirclePlusIcon className={styles.addIcon} />
    <div className={styles.buttonText}>Add Another</div>
  </div>
);

export {AddAnotherButton};
export type {IAddAnotherButton};
