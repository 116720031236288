import _ from 'lodash';
import type {FieldErrors} from 'react-hook-form';

const scrollToError = (
  errors: FieldErrors,
  scrollParams: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'center',
  },
) => {
  if (errors) {
    const firstError = _(errors)
      .keys()
      .map((name) => document.getElementsByName(name)[0])
      .filter((el) => !!el)
      .orderBy((error) => error.getBoundingClientRect().y)
      .first();

    if (firstError) {
      queueMicrotask(() => {
        firstError.parentElement?.scrollIntoView(scrollParams);
        firstError.focus();
      });

      return true;
    }
  }

  return false;
};

export {scrollToError};
