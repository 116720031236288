import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IChronicConditionChecklistRepository} from '../repository';
import {CHRONIC_CONDITION_CHECKLIST_REPOSITORY, ChronicConditionChecklistRepository} from '../repository';
import type {IChronicConditionChecklistService} from '../service';
import {CHRONIC_CONDITION_CHECKLIST_SERVICE, ChronicConditionChecklistService} from '../service';

const ChronicConditionChecklistModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IChronicConditionChecklistRepository>(CHRONIC_CONDITION_CHECKLIST_REPOSITORY)
    .to(ChronicConditionChecklistRepository)
    .inSingletonScope();

  bind<IChronicConditionChecklistService>(CHRONIC_CONDITION_CHECKLIST_SERVICE)
    .to(ChronicConditionChecklistService)
    .inSingletonScope();
});

export {ChronicConditionChecklistModule};
