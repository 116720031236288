import type {IOption} from '../../types';

type TimeZone = IOption<string>;

class ProfileSettingsModel {
  constructor(
    public areSmsReceived: boolean = true,
    public arePushReceived: boolean = true,
    public timeZones: Array<TimeZone> = [],
    public notificationType: string = '',
    public selectedTimeZone: TimeZone | null = null,
    public verificationCode: string | null = null,
  ) {}
}

export {ProfileSettingsModel};
export type {TimeZone};
