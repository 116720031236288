interface ILifeQualityItem {
  question: string;
  value: string;
}

class LifeQualityModel {
  constructor(
    public items: ILifeQualityItem[] = [],
    public patientId: number = -1,
    public id: number = -1,
    public lastUpdated: Date | null = null,
    public name: string = '',
  ) {}
}

export {LifeQualityModel};
export type {ILifeQualityItem};
