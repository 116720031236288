import {useContext} from 'react';
import {ConfirmationDialogContext} from './provider';

const useFormConfirmationDialog = () => {
  const {openDialog} = useContext(ConfirmationDialogContext);

  const getConfirmation = ({...options}): Promise<boolean> =>
    new Promise((res) => {
      openDialog && openDialog({actionCallback: res, ...options});
    });

  return {getConfirmation};
};

export {useFormConfirmationDialog};
