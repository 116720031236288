import type {Nullable} from '../../types';

interface PatientLanguage {
  id: number;
  name: string;
  isNeedInterpreter: boolean;
  text: string | null;
}

interface AccountLanguage {
  id: number;
  name: string;
}

interface Country {
  id: number;
  name: string;
}

interface State {
  id: string;
  name: string;
}

interface Provider {
  providerId: number;
  firstName: string;
  lastName: string;
}

interface DiseaseModel {
  id: number;
  name: string;
}

interface SpecialtyModel {
  id: number;
  name: string;
}

interface EmergencyContact {
  partnerInCareId: number;
  patientId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  relationshipType: number;
}
interface IBannerModel {
  bannerId: number;
  createdByFullName: string;
  createdByUserAccountId: number;
  createdDate: string;
  subject: string;
  text: string;
}

interface TrellusTeamMember {
  providerId: number;
  firstName: string;
  lastName: string;
  providerTitle: string;
  providerType: string;
  providerTypeId: number;
  objectFileId: string | null;
  avatar: string | null;
  isTriadMember: boolean;
  isGiProvider: boolean;
  providerStatement: string | null;
  emergencyAppointmentProtocol: string | null;
  officePhone: string;
  userRoleId: number;
  openForCommunicationWithPatient: boolean;
}

interface PersonalInfo {
  firstName: string;
  lastName: string;
  email?: string;
  phone: string;
  alternatePhone: string | null;
  dateOfBirth: string;
  country: number;
  zipCode: string;
  state: string;
  city: string;
  address: string;
  sex: number;
  language: number;
  isNeedInterpreter?: boolean;
  otherLanguage: string;
  userName?: string;
  verificationCode: string | null;
}

interface HealthInsurance {
  insuranceCompany: string;
  plan: string;
  memberId: string;
  insurancePhone: string;
  verificationCode: string;
}

interface Specialty {
  id: number;
  name: string;
}

interface DiseaseType {
  id: number;
  name: string;
}

interface SearchProvider {
  providerId: number;
  firstName: string;
  lastName: string;
}

interface Coupon {
  id: string;
  promotionCodeId: string;
  livemode: boolean;
  valid: boolean;
  duration: string;
  percentOff: number;
  durationInMonths: number;
}

class AccountModel {
  constructor(
    public id: number = 0,
    public firstName: string = '',
    public lastName: string = '',
    public lastLoginDate: string | null = null,
    public email: string = '',
    public userName: string = '',
    public phone: string = '',
    public alternatePhone: string | null = null,
    public dateOfBirth: string = '',
    public country: number = 0,
    public zipCode: string | null = null,
    public state: string | null = null,
    public city: string | null = null,
    public address: string | null = null,
    public sex: number | null = null,
    public language: PatientLanguage = {
      name: '',
      text: null,
      id: -1,
      isNeedInterpreter: false,
    },
    public grittScore: number | null = null,
    public quadrant: number | null = null,
    public status: number | null = null,
    public totalAnnualSpend: number | null = null,
    public process: string | null = null,
    public clientId: number | null = null,
    public clientGroupId: number | null = null,
    public objectFileId: string | null = null,
    public providerId: number | null = null,
    public providers: Provider[] = [],
    public disease: DiseaseModel | null = null,
    public specialty: SpecialtyModel | null = null,
    public diagnosisDate: string | null = null,
    public showWelcomeToThrive: boolean | null = null,
    public insuranceCompany: string | null = null,
    public insurancePolicyNumber: string | null = null,
    public insurancePhone: string | null = null,
    public plan: string | null = null,
    public memberId: string | null = null,
    public emergencyContact: Nullable<EmergencyContact> = {
      firstName: null,
      email: null,
      patientId: null,
      lastName: null,
      phone: null,
      partnerInCareId: null,
      relationshipType: null,
    },
    public communicationPreferences: number[] = [],
    public informationToReceive: number[] = [],
    public isExistSuperVisit: boolean | null = null,
    public isSuperVisitSubmitted: boolean | null = null,
    public isEmailAddressChangeRequested: boolean | null = null,
    public registrationDate: string | null = null,
    public diseaseType = 0,
    public timeZone = '',
    public verificationCode: string | null = null,
    public readyForThrive: boolean | null = null,
    public clientType: number | null = null,
  ) {}
}

export {AccountModel};
export type {
  PatientLanguage,
  TrellusTeamMember,
  DiseaseModel,
  Provider,
  PersonalInfo,
  AccountLanguage,
  HealthInsurance,
  Specialty,
  DiseaseType,
  SearchProvider,
  Country,
  State,
  Coupon,
  IBannerModel,
};
