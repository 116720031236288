import type {FC} from 'react';
import React from 'react';
import styles from './providers-search-form.module.scss';
import {Controller, useForm} from 'react-hook-form';
import {Button, Error, Input, MaskedInput, SingleDropdown} from '../../../../../../components';
import {getClearedPhoneValue, REQUIRED_FIELD_ERROR} from '../../../../../../helpers';
import {Patterns} from '../../../../../../helpers/patterns';
import type {IOption} from '../../../../../../types';
import type {SearchInterface} from '../../../../../../account';

const titleOptionsBEMapping: Array<IOption<string>> = [
  {value: 'MD', displayName: 'MD'},
  {value: 'NP', displayName: 'NP'},
  {value: 'PA', displayName: 'PA'},
  {value: 'PA', displayName: 'PSYD'},
  {value: 'LCSW', displayName: 'LCSW'},
  {value: 'RD', displayName: 'RD'},
  {value: 'RDN', displayName: 'RDN'},
];

const titleOptions: Array<IOption<string>> = [
  {value: 'MD', displayName: 'M.D.'},
  {value: 'NP', displayName: 'N.P.'},
  {value: 'PA', displayName: 'P.A.'},
  {value: 'PSYD', displayName: 'Psy.D.'},
  {value: 'LCSW', displayName: 'L.C.S.W'},
  {value: 'RD', displayName: 'R.D.'},
  {value: 'RDN', displayName: 'R.D.N.'},
  {value: '', displayName: 'Other / Unknown'},
];

interface IProvidersSearchProps {
  isEditMode: boolean;
  states: IOption<string>[];
  onSearch(formData: SearchInterface): void;
}
export const ProvidersSearch: FC<IProvidersSearchProps> = ({isEditMode, states, onSearch}) => {
  const {handleSubmit, control, errors} = useForm<SearchInterface>();

  const onFormSubmit = (data: SearchInterface) => {
    onSearch({
      ...data,
      title: titleOptionsBEMapping.find((value) => data.title === value.displayName)?.value ?? '',
      phone: getClearedPhoneValue(data.phone as string) ?? '',
    });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={styles.root}>
      <div className={styles.searchFormTitle}>Provider search</div>
      <Controller
        name={'title'}
        control={control}
        render={({name, value, onChange}) => {
          const selectedValue = titleOptions?.find((option) => option.value === value);

          return (
            <SingleDropdown
              placeholder={'Title'}
              className={styles.dropdown}
              name={name}
              disabled={!isEditMode}
              options={titleOptions}
              value={selectedValue}
              onChange={(selectedItem) => onChange(selectedItem.value)}
            />
          );
        }}
      />
      <Controller
        name={'firstName'}
        control={control}
        rules={{
          required: REQUIRED_FIELD_ERROR,
        }}
        render={({name, value, onChange}) => (
          <div>
            <Input
              className={styles.inputField}
              placeholder={'First name *'}
              name={name}
              value={value}
              disabled={!isEditMode}
              onValueChanged={onChange}
              isValid={!errors.firstName}
            />
            {errors.firstName && (
              <div className={styles.errorField}>
                <Error errorMessage={REQUIRED_FIELD_ERROR} />
              </div>
            )}
          </div>
        )}
      />
      <Controller
        name={'lastName'}
        rules={{
          required: REQUIRED_FIELD_ERROR,
        }}
        control={control}
        render={({name, value, onChange}) => (
          <div>
            <Input
              className={styles.inputField}
              placeholder={'Last name *'}
              name={name}
              value={value}
              disabled={!isEditMode}
              onValueChanged={onChange}
              isValid={!errors.lastName}
            />
            {errors.lastName && (
              <div className={styles.errorField}>
                <Error errorMessage={REQUIRED_FIELD_ERROR} />
              </div>
            )}
          </div>
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({value, onChange, name}) => (
          <MaskedInput
            className={styles.inputField}
            mask={Patterns.PhoneMask}
            name={name}
            placeholder="Phone"
            value={value ? (value.startsWith('+1') ? value : `+1-${value}`) : undefined}
            onValueChanged={onChange}
            isValid={!errors.phone}
            disabled={!isEditMode}
          />
        )}
      />
      <Controller
        name={'state'}
        control={control}
        rules={{
          required: REQUIRED_FIELD_ERROR,
        }}
        render={({name, value, onChange}) => {
          const selectedValue = states?.find((option) => option.value === value);

          return (
            <div>
              <SingleDropdown
                placeholder={'State *'}
                className={styles.dropdown}
                name={name}
                options={states}
                value={selectedValue}
                disabled={!isEditMode}
                onChange={(selectedItem) => onChange(selectedItem.value)}
                isError={!!errors.state}
              />
              {errors.state && (
                <div className={styles.errorField}>
                  <Error errorMessage={REQUIRED_FIELD_ERROR} />
                </div>
              )}
            </div>
          );
        }}
      />
      <Button disabled={!isEditMode} type={'submit'}>
        Search
      </Button>
    </form>
  );
};
