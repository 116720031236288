import type {FC} from 'react';
import React, {useState} from 'react';
import {Card, CardContent} from '../../components';
import {AttentionIcon, CircleArrowDownIcon} from '../../assets/icons';
import classNames from 'classnames';
import styles from './collapse-description.module.scss';

const DEFAULT_TITLE = 'Why does this matter?';

interface ICollapseDescriptionProps {
  title?: string;
  withPreview?: boolean;
  titleClassName?: string;
  withCollapseButton?: boolean;
  date?: string;
  expandText?: string;
  expandTextClassName?: string;
  containerClassName?: string;
  icon?: React.ReactElement;
  iconClassName?: string;
  cardContentClassName?: string;
}

const CollapseDescription: FC<ICollapseDescriptionProps> = ({
  title = DEFAULT_TITLE,
  titleClassName,
  withPreview = true,
  withCollapseButton = true,
  date,
  expandText = 'Learn more',
  expandTextClassName,
  children,
  containerClassName,
  icon,
  iconClassName,
  cardContentClassName,
}) => {
  const [isCollapsed, setCollapse] = useState(true);

  const toggle = () => setCollapse((prevState) => !prevState);

  return (
    <Card className={classNames(styles.root, containerClassName)} borderLess>
      <CardContent className={classNames(styles.content, cardContentClassName)}>
        <div
          className={classNames(styles.header, {
            [styles.collapsedHeader]: isCollapsed,
          })}>
          <span className={classNames(iconClassName, styles.attentionIcon)}>{icon ? icon : <AttentionIcon />}</span>
          <div className={styles.wrapper} data-testid={'test'}>
            <span className={classNames(titleClassName, styles.title)}>{title}</span>
            {withPreview && (
              <div
                className={classNames(styles.preview, {
                  [styles.hideDescription]: !isCollapsed,
                })}>
                <div>{children}</div>
                <span className={classNames(expandTextClassName)} onClick={toggle}>
                  {expandText}
                </span>
              </div>
            )}
            {!withPreview && isCollapsed && (
              <div className={classNames(styles.expandText, expandTextClassName)} onClick={toggle}>
                {expandText}
              </div>
            )}
            <div
              className={classNames(styles.description, {
                [styles.hideDescription]: isCollapsed,
              })}>
              {children}
            </div>
          </div>
          {date && <span className={styles.date}>{date}</span>}
          {withCollapseButton && (
            <span className={classNames(styles.iconArrow, {[styles.iconArrowDown]: !isCollapsed})} onClick={toggle}>
              <CircleArrowDownIcon />
            </span>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export {CollapseDescription};
export type {ICollapseDescriptionProps};
