import type {
  CreateGoalWorksheetDto,
  GoalResponseDto,
  UpdateReviewWorkflowDto,
  WorksheetResponseDto,
} from '../../dto/goal/goal.response.dto';
import type {IGoalWorksheetForm, IUpdateReviewWorkflow} from '../../interfaces';
import {EMotivationType} from '../../interfaces';
import {CreateGoalWorksheetModel, GoalModel, UpdateReviewWorkflowWorkModel, WorksheetModel} from './goal.model';

class GoalModelFactory {
  static fromResponseDto(dto: GoalResponseDto): GoalModel {
    const goal = new GoalModel(
      dto.behaviorTypeGoalSettingId,
      dto.behaviorTypeId,
      dto.createdByUserId,
      dto.createdDate,
      dto.description,
      dto.levelOneExample,
      dto.levelThreeExample,
      dto.levelTwoExample,
      dto.updatedByUserId,
      dto.updatedDate,
    );

    return goal;
  }

  static fromWorksheetResponseDto(dto: WorksheetResponseDto): WorksheetModel {
    const worksheet = new WorksheetModel(
      dto.adaptive,
      dto.adaptiveScore,
      dto.behaviorTypeId,
      dto.courseName,
      dto.createdByUserId,
      dto.createdDate,
      dto.effortLevel,
      dto.goal,
      dto.goalWorksheetId,
      dto.isArchived,
      dto.goalAccomplished,
      dto.isDeleted,
      dto.meaningful,
      dto.meaningfulScore,
      dto.patientCourseId,
      dto.realistic,
      dto.realisticScore,
      dto.specific,
      dto.specificScore,
      dto.timeBound,
      dto.timeBoundScore,
      dto.totalScore,
      dto.type,
      dto.updatedByUserId,
      dto.updatedDate,
    );

    return worksheet;
  }

  static fromWorksheetFormDto(data: IGoalWorksheetForm): CreateGoalWorksheetDto {
    const typeIndex = Object.values(EMotivationType).indexOf(data.motivation as EMotivationType);

    return new CreateGoalWorksheetModel(
      data.adaptive,
      data.adaptiveScore,
      data.behaviorTypeId,
      data.effortLevel,
      data.goal,
      data.meaningful,
      data.meaningfulScore,
      data.patientCourseId,
      data.realistic,
      data.realisticScore,
      data.specific,
      data.specificScore,
      data.timeBound,
      data.timeBoundScore,
      data.totalScore,
      typeIndex,
    );
  }

  static fromReviewWorkflowFormDto(data: IUpdateReviewWorkflow): UpdateReviewWorkflowDto {
    return new UpdateReviewWorkflowWorkModel(
      data.continueWorking,
      data.courseId,
      data.feelings,
      data.goalAccomplished,
      data.goalWorksheetId,
      data.shareResults,
    );
  }
}

export {GoalModelFactory};
