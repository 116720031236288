import type {FC, ImgHTMLAttributes} from 'react';
import classNames from 'classnames';
import styles from './image.module.scss';

type IImageProps = ImgHTMLAttributes<HTMLImageElement>;

const Image: FC<IImageProps> = (props) => (
  <img
    alt={props.alt}
    decoding="async"
    loading={'lazy'}
    {...props}
    className={classNames(styles.root, props.className)}
  />
);

export {Image};
export type {IImageProps};
