import {inject, injectable} from 'inversify';
import type {IHttpService} from '../../../utils/http';
import {HTTP_SERVICE} from '../../../utils/http';
import type {
  CreateGoalWorksheetDto,
  GoalResponseDto,
  UpdateReviewWorkflowDto,
  UpdateGoalWorksheetDto,
  WorksheetResponseDto,
} from '../../dto/goal/goal.response.dto';
import type {IUpdateResponse} from '../../interfaces';
import type {GoalModel, WorksheetModel} from '../../model/goal/goal.model';
import {GoalModelFactory} from '../../model/goal/goal.model.factory';
import type {IGoalRepository} from './goal.repository.interface';

@injectable()
class GoalRepository implements IGoalRepository {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {}

  async getPossibleGoals(courseTypeId: number): Promise<GoalModel> {
    const goal = await this._http
      .get<GoalResponseDto>(`BehaviorTypeGoalSettings/getbybehaviortype/${courseTypeId}`)
      .then(({data}) => GoalModelFactory.fromResponseDto(data));

    return goal;
  }

  async getWorksheets(): Promise<WorksheetModel[]> {
    const worksheets = await this._http
      .get<WorksheetResponseDto[]>('BehaviorTypeGoalSettings/worksheet/getall')
      .then(({data}) => data.map((dto) => GoalModelFactory.fromWorksheetResponseDto(dto)));

    return worksheets;
  }

  async create(model: CreateGoalWorksheetDto): Promise<void> {
    await this._http.post('BehaviorTypeGoalSettings/worksheet/add', {
      ...model,
    });
  }

  async update(model: UpdateGoalWorksheetDto): Promise<boolean> {
    const {data} = await this._http.put<IUpdateResponse>('BehaviorTypeGoalSettings/worksheet/update', {
      ...model,
    });

    return data.isOk;
  }

  async updateReviewWorkflow(model: UpdateReviewWorkflowDto): Promise<void> {
    await this._http.put('BehaviorTypeGoalSettings/worksheet/review', {
      ...model,
    });
  }
}

export {GoalRepository};
