import {inject, injectable} from 'inversify';
import {PreVisitModel, PreVisitModelFabric} from '../model';
import {makeAutoObservable} from 'mobx';
import type {IPreVisitService} from './pre-visit.service.interface';
import type {IHttpService} from '../../utils/http';
import {HTTP_SERVICE} from '../../utils/http';
import type {IPreVisitRecordGetResponseDto} from '../dto';
import {routes} from '../../routing';

@injectable()
class PreVisitService implements IPreVisitService {
  constructor(@inject(HTTP_SERVICE) private readonly _http: IHttpService) {
    makeAutoObservable(this);
  }

  private _preVisitInfo: PreVisitModel = new PreVisitModel();

  get preVisitInfo(): PreVisitModel {
    return this._preVisitInfo;
  }

  async load(patientId: number): Promise<void> {
    await this._http
      .get<IPreVisitRecordGetResponseDto>(`PatientDailyTask/previsittaskinfo?patientid=${patientId}`)
      .then((response) => {
        this._preVisitInfo = PreVisitModelFabric.fromResponseDto(response.data);
      })
      .catch(console.error);
  }

  async downloadPreVisitFile(): Promise<void> {
    return this._http
      .get<string>('PreVisit/createPreVisitFile')
      .then(({data: fileId}) => {
        const path = `${routes.home}${routes.pdf}?id=${fileId}`;

        window.open(path);
      })
      .catch(console.error);
  }
}

export {PreVisitService};
