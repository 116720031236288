import React from 'react';
import styles from './dashboard-section.module.scss';
import classNames from 'classnames';
import {TodaysLessons, TodoList} from '../../../../../assets/icons';
import {LESSONS_SECTION_TITLE, TASKS_SECTION_TITLE} from '../../dashboard.helper';

interface IDashboardSectionProps {
  title: string;
  link?: JSX.Element;
  headerClassName?: string;
}

const renderIcon = (title: string | undefined) => {
  switch (title) {
    case LESSONS_SECTION_TITLE:
      return <TodaysLessons />;
    case TASKS_SECTION_TITLE:
      return <TodoList />;
    default:
      return null;
  }
};

const DashboardSection: React.FC<IDashboardSectionProps> = ({title, link, headerClassName, children}): JSX.Element => (
  <section id={title?.replaceAll(' ', '_')} className={styles.section}>
    <div className={classNames(styles.sectionHeader, headerClassName)}>
      {renderIcon(title)}
      <h4 className={styles.sectionTitle}>{title}</h4>
      {link}
    </div>
    {children}
  </section>
);

export {DashboardSection};
