import {memo} from 'react';
import {DateWrapper} from '../../../../components';
import styles from './disease-severity-screener-sub-header.module.scss';

interface IDiseaseSeverityScreenerSubHeaderProps {
  date?: Date | null;
}

const DiseaseSeverityScreenerSubHeader = memo<IDiseaseSeverityScreenerSubHeaderProps>(({date}) => {
  const formattedLastUpdatedDate = date ? <DateWrapper date={date} /> : null;

  return formattedLastUpdatedDate ? (
    <div className={styles.subHeader}>Last updated · {formattedLastUpdatedDate}</div>
  ) : null;
});

export {DiseaseSeverityScreenerSubHeader};
export type {IDiseaseSeverityScreenerSubHeaderProps};
