import type {FC} from 'react';
import React from 'react';
import classNames from 'classnames';
import styles from './card-content.module.scss';

interface ICardContentProps {
  gap?: number;
  gapLeft?: number;
  gapRight?: number;
  gapTop?: number;
  gapBottom?: number;
  gapY?: number;
  gapX?: number;
  center?: boolean;
  className?: string;
}

const CardContent: FC<ICardContentProps> = (props) => (
  <div
    className={classNames(styles.root, props.className, {
      [styles.center]: props.center,
      [styles.gap0]: props.gap === 0,
      [styles.gap1]: props.gap === 1,
      [styles.gap2]: props.gap === 2,
      [styles.gap3]: props.gap === 3,
      [styles.gapTop0]: props.gapTop === 0 || props.gapY === 0,
      [styles.gapTop1]: props.gapTop === 1 || props.gapY === 1,
      [styles.gapTop2]: props.gapTop === 2 || props.gapY === 2,
      [styles.gapTop3]: props.gapTop === 3 || props.gapY === 3,
      [styles.gapBottom0]: props.gapBottom === 0 || props.gapY === 0,
      [styles.gapBottom1]: props.gapBottom === 1 || props.gapY === 1,
      [styles.gapBottom2]: props.gapBottom === 2 || props.gapY === 2,
      [styles.gapBottom3]: props.gapBottom === 3 || props.gapY === 3,
      [styles.gapLeft0]: props.gapLeft === 0 || props.gapX === 0,
      [styles.gapLeft1]: props.gapLeft === 1 || props.gapX === 1,
      [styles.gapLeft2]: props.gapLeft === 2 || props.gapX === 2,
      [styles.gapLeft3]: props.gapLeft === 3 || props.gapX === 3,
      [styles.gapRight0]: props.gapRight === 0 || props.gapX === 0,
      [styles.gapRight1]: props.gapRight === 1 || props.gapX === 1,
      [styles.gapRight2]: props.gapRight === 2 || props.gapX === 2,
      [styles.gapRight3]: props.gapRight === 3 || props.gapX === 3,
    })}>
    {props.children}
  </div>
);

export {CardContent};
export type {ICardContentProps};
