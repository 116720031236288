import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import type {IHealthMonitorPoint} from '../../model';
import {HealthMonitorPeriodTypes} from '../../model';
import type {IOption} from '../../../types';
import type {
  HealthMonitorFatigueLabels,
  HealthMonitorMyLifeIsGoingWellLabels,
  HealthMonitorPhysicalHealthLabels,
} from './enums';
import {getAxisesConfig} from './get-axises-config';
import * as d3 from 'd3';
import {
  addMonths,
  differenceInMilliseconds,
  format,
  getDate,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  setDate,
  startOfDay,
  sub,
  subMonths,
  subWeeks,
} from 'date-fns';
import './health-monitor-line-chart.scss';
import {cutDate} from '../health-monitor';
import {calculateLastPointHelper} from './calculate-last-point';

const LABEL_HEIGHT = 40;
const RATIO_SHIFT_WITH_LABELS = 1.12;
const RATIO_SHIFT_WITHOUT_LABELS = 1.24;
const WARNING_COLOR = '#ff4b4b';
const HIDDEN_COLOR = '#fff';

type AxisDataType = {min?: number; max?: number; range: number; tick: number};

type ExtendedAxisDataType<T> = AxisDataType & {labels: Array<IOption<T>>};

interface Dimension {
  width: number;
  height: number;
  margins: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

interface IHealthMonitorLineChartProps {
  points: Array<IHealthMonitorPoint>;
  lowerBorderLine?: number | null;
  upperBorderLine?: number | null;
  xAxisLabel?: string;
  yAxisLabel?: string;
  startDate: Date;
  endDate: Date;
  dimensions: Dimension;
  tickRange?: HealthMonitorPeriodTypes;
  chartData:
    | AxisDataType
    | ExtendedAxisDataType<
        HealthMonitorMyLifeIsGoingWellLabels | HealthMonitorFatigueLabels | HealthMonitorPhysicalHealthLabels
      >;
  xLabelsNumber: number;
}

const HealthMonitorLineChart: FC<IHealthMonitorLineChartProps> = (props) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const dynamicMarginTop = props.dimensions.margins.top + (props.yAxisLabel ? LABEL_HEIGHT : 0);

  const dataWidth = props.dimensions.width - props.dimensions.margins.right; // mb should use width - margin.left - margin.right

  const dataHeight = props.dimensions.height - props.dimensions.margins.top - props.dimensions.margins.bottom;

  const isValid = useCallback(
    (value: number) =>
      (props.lowerBorderLine && value >= props.lowerBorderLine) ||
      (props.upperBorderLine && value <= props.upperBorderLine) ||
      (!props.lowerBorderLine && !props.upperBorderLine),
    [props.lowerBorderLine, props.upperBorderLine],
  );

  const modifiedPoints = useMemo((): Array<IHealthMonitorPoint> => {
    if (props.points.length <= 1) {
      return props.points;
    }

    const formattedStartDate = cutDate(props.startDate);
    const lastPointOutOfBounds = props.points
      .reverse()
      .find((p) => p.x && formattedStartDate && isBefore(p.x, formattedStartDate));

    const resultPoints = props.points
      .filter((p) => isAfter(p.x, props.startDate) || isSameDay(p.x, props.startDate))
      .sort((p1, p2) => (differenceInMilliseconds(p1.x, p2.x) > 0 ? 1 : -1));

    if (lastPointOutOfBounds && resultPoints.length) {
      const date = sub(props.startDate, {days: 1});
      const calculatedPoint = calculateLastPointHelper(lastPointOutOfBounds, resultPoints[0], date);
      calculatedPoint && resultPoints.unshift(calculatedPoint);
    }

    return resultPoints;
  }, [props.points, props.startDate]);

  useEffect(() => {
    if (!modifiedPoints.length) {
      return;
    }

    const config = getAxisesConfig(modifiedPoints, props.chartData);
    const {min, max, tick, range, labels} = config;

    const yAxisLabelRatioShift = labels?.length ? RATIO_SHIFT_WITH_LABELS : RATIO_SHIFT_WITHOUT_LABELS;

    let xAxisLabels: Array<Date> = [];

    if (props.tickRange === HealthMonitorPeriodTypes.Week) {
      for (let i = 0; i < props.xLabelsNumber; i++) {
        xAxisLabels.push(subWeeks(props.endDate, i));
      }

      xAxisLabels = xAxisLabels.reverse();
    } else if (modifiedPoints.length) {
      const lastDay = getDate(modifiedPoints[modifiedPoints.length - 1]?.x);
      const startDay = getDate(props.startDate);

      const modifiedStartDate =
        lastDay > startDay ? setDate(subMonths(props.startDate, 1), lastDay) : setDate(props.startDate, lastDay);

      xAxisLabels.push(modifiedStartDate);

      for (let i = 1; i < props.xLabelsNumber; i++) {
        xAxisLabels.push(addMonths(modifiedStartDate, i));
      }
    } else {
      for (let i = 1; i < props.xLabelsNumber; i++) {
        xAxisLabels.push(addMonths(props.startDate, i));
      }
    }

    // Axises
    const xScale = d3
      .scaleTime()
      .domain([xAxisLabels[0], xAxisLabels[xAxisLabels.length - 1]])
      .range([0, dataWidth]);
    const yScale = d3.scaleLinear().domain([max, min]).rangeRound([0, dataHeight]);

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(12)
      .tickSize(0)
      .tickValues([...xAxisLabels])
      .tickFormat((val) => format(new Date(val.toString()), 'dd-MMM yyyy'))
      .tickPadding(25);

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(tick)
      .tickValues(d3.range(min, max + range / 2, range).concat(max))
      .tickFormat((val) =>
        labels !== undefined && val <= max
          ? `${labels.find((l) => l.value === val)?.displayName ?? ''}`
          : d3.format('.1f')(val),
      );

    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll('*').remove();
    const svg = svgEl
      .append('g')
      .attr('transform', `translate(${props.dimensions.margins.left},${dynamicMarginTop + 5})`);

    svg
      .append('g')
      .attr('class', 'line-chart__axis-x')
      .attr('transform', `translate(0, ${dataHeight})`)
      .call(xAxis)
      .selectAll('text')
      .attr('dy', '20px');

    svg
      .append('g')
      .attr('class', 'line-chart__axis-y')
      .call(yAxis)
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .attr('text-anchor', 'start')
      .attr('dy', '13px')
      .attr('dx', `-${props.dimensions.margins.left / yAxisLabelRatioShift}px`);

    d3.selectAll('.line-chart__axis-x text').call(function (t) {
      t.each(function (_) {
        const self = d3.select(this);
        const s = self.text().split(' ');

        self.text('');
        self.append('tspan').attr('x', 0).attr('dy', 0).text(s[0]);
        self.append('tspan').attr('x', 0).attr('dy', 20).text(s[1]);
      });
    });

    // Render minor elements
    // append y axis lines
    let current = min;

    while (current <= max + 0.5 * range) {
      svg
        .append('line')
        .attr('class', 'line-chart__tick-line')
        .attr('x1', -props.dimensions.margins.left)
        .attr('y1', yScale(current))
        .attr('x2', dataWidth + props.dimensions.margins.left)
        .attr('y2', yScale(current));

      current += range;
    }

    // append border line
    (props.lowerBorderLine || props.upperBorderLine) &&
      svg
        .append('line')
        .attr('class', 'line-chart__border-line')
        .attr('x1', 0)
        .attr('y1', yScale(props.lowerBorderLine ?? props.upperBorderLine ?? 0))
        .attr('x2', dataWidth + props.dimensions.margins.left)
        .attr('y2', yScale(props.lowerBorderLine ?? props.upperBorderLine ?? 0));

    svg
      .append('text')
      .attr('class', 'line-chart__label-y')
      .attr('x', 0)
      .attr('y', -26)
      .text(`${props.yAxisLabel}`)
      .attr('transform', `translate(${-props.dimensions.margins.left}, 0)`);

    // Render data
    const line = d3
      .line<IHealthMonitorPoint>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y));

    svg
      .append('path')
      .datum(modifiedPoints)
      .attr('class', 'line-chart__line')
      .call((g) => g.select('.domain').remove())
      .attr('d', line);

    svg
      .selectAll('circle')
      .data(modifiedPoints)
      .enter()
      .append('circle')
      .attr('class', 'line-chart__value')
      .attr('r', 6)
      .attr('cx', (d) => xScale(d.x))
      .attr('cy', (d) => yScale(d.y))
      .style('fill', (d) => (d.x >= startOfDay(props.startDate) ? (isValid(d.y) ? '' : WARNING_COLOR) : HIDDEN_COLOR))
      .style('r', (d) =>
        isBefore(startOfDay(props.startDate), d.x) || isEqual(startOfDay(props.startDate), d.x) ? '' : 0,
      );

    svg.selectAll('circle').data(modifiedPoints).exit().remove();
  }, [
    isValid,
    dataHeight,
    dataWidth,
    dynamicMarginTop,
    props.points,
    props.chartData,
    props.tickRange,
    props.dimensions.margins.left,
    props.lowerBorderLine,
    props.upperBorderLine,
    props.yAxisLabel,
    props.endDate,
    props.startDate,
    props.xLabelsNumber,
    modifiedPoints,
  ]);

  return modifiedPoints.length ? (
    <svg
      role="img"
      aria-label="health-monitor"
      ref={svgRef}
      height={props.dimensions.height + dynamicMarginTop + props.dimensions.margins.bottom}
      width={props.dimensions.width + props.dimensions.margins.left + props.dimensions.margins.right}
    />
  ) : null;
};

export {HealthMonitorLineChart};
export type {Dimension, IHealthMonitorLineChartProps, AxisDataType, ExtendedAxisDataType};
