import {MessageModel} from './message.model';
import type {IMessageGetResponseDto, IMessageSocketDto} from '../dto';
import {parseRichText} from '../../helpers';
import {MessageAttachmentModelFactory} from './message-attachment.model.factory';

class MessageModelFactory {
  static fromGetResponse(dto: IMessageGetResponseDto): MessageModel {
    const attachments = dto.message.attachments.map((dto) => MessageAttachmentModelFactory.fromGetResponse(dto));

    return new MessageModel(
      dto.messageId,
      dto.message.authorId,
      dto.message.threadId,
      parseRichText(dto.message.text),
      new Date(dto.message.createdDate),
      attachments,
      dto.message.isAutoReply,
      dto.recipientsMessageStatus,
      dto.status,
      dto.tempId,
      dto.firstName,
      dto.providerType,
    );
  }

  static fromSocketDto(dto: IMessageSocketDto): MessageModel {
    const attachments = dto.attachments.map((dto) => MessageAttachmentModelFactory.fromSocketDto(dto));

    return new MessageModel(
      dto.messageId,
      dto.authorId,
      dto.threadId,
      parseRichText(dto.text),
      new Date(dto.createdDate),
      attachments,
      dto.isAutoReply,
      dto.recipientsMessageStatus,
      dto.status,
      dto.tempId,
      dto.firstName,
      dto.providerType,
    );
  }
}

export {MessageModelFactory};
