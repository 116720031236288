import {SurveyGetLastResponseInfoRequestDto} from './survey-get-last-response-info-request.dto';
import type {ISurveyQuery} from '../../../survey.repository.interface';

class SurveyGetLastResponseInfoRequestDtoFactory {
  static fromQuery(query: Partial<ISurveyQuery>): SurveyGetLastResponseInfoRequestDto {
    return new SurveyGetLastResponseInfoRequestDto(query.patientId, query.type);
  }
}

export {SurveyGetLastResponseInfoRequestDtoFactory};
