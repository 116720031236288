import type {ISurveyRadioButtonOption} from '../../../../../components';
import {SurveyRadioButtonGroup} from '../../../../../components';
import {Controller, useFormContext} from 'react-hook-form';
import type {FC} from 'react';
import type {IVaccinationFormElement} from '../../../vaccination-form.component';

const SimpleVaccinationInput: FC<{
  element: IVaccinationFormElement;
}> = (props) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={props.element.name}
      control={control}
      rules={{required: true}}
      render={({name, onChange, value}) => {
        const radioOptions: Array<ISurveyRadioButtonOption> = props.element.options.map((option) => ({
          id: option.value,
          text: option.label,
        }));

        const selectedValue: ISurveyRadioButtonOption = {
          id: value,
          text: props.element.options.find((option) => option.value === value)?.label ?? '',
        };

        const onValueChange = (value: ISurveyRadioButtonOption) => {
          onChange(String(value.id));
        };

        return (
          <SurveyRadioButtonGroup name={name} options={radioOptions} value={selectedValue} onChange={onValueChange} ariaLabelTitle={props.element.ariaLabelTitle}/>
        );
      }}
    />
  );
};

export {SimpleVaccinationInput};
