export interface IPotFlower {
  toRender: boolean;
  type: string;
  modifier?: number | string;
  flowerCount?: number;
  text: string;
}

export interface IPotFlowerElement {
  x: number;
  y: number;
  count?: number;
  modifier?: number | string;
  text: string;
}

export interface IPotData {
  completedLessonsCount: number;
  weeklyWellnessConsecutiveCompletionsCount: number;
  weeklyWellnessOverallImprovementCount: number;
  weeklyWellnessHappinessScoreImprovedCount: number;
  weeklyWellnessHappinessLadderScoreIsConsecutivelyGreat: boolean;
  loggedFiveTimesInLast14daysCount: number;
  loggedOneTimeInFourWeeksCount: number;
  haveLoginAfterPause: boolean;
  completedJumpstartLessonsCount: number;
}

export enum PotFlowerTypeEnum {
  Orange = 'pottedOrange',
  Blue = 'pottedBlue',
  Lavender = 'pottedLavender',
  Blue2 = 'pottedBlue2',
  Marigold = 'pottedMarigold',
  Red = 'pottedRed',
  Purple = 'pottedPurple',
  PinkSpray = 'pottedPinkSpray',
  Pink = 'pottedPink',
}
