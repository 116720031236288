interface IMessageThreadRecipientGetResponseDto {
  userAccountId: number;
  userRoleId: number;
  firstName: string;
  lastName: string;
  logoObjectFileId: string;
  providerTitleId: number;
  providerTitle: string;
  providerTypeId: number;
  providerType: string;
  recipientType: RecipientType;
}

enum RecipientType {
  Main = 1,
  Substitute = 2,
}

export type {IMessageThreadRecipientGetResponseDto};
export {RecipientType};
