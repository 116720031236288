import type {FC} from 'react';
import React, {useCallback, useEffect, useRef} from 'react';
import {Overlay} from '../overlay';
import styles from './modal.module.scss';
import {CloseIcon} from '../../assets/icons';

interface IModalProps {
  closeIcon?: boolean;
  onClose?: () => void;
  zIndex?: number;
  shouldBeAbleToClose?: boolean;
}

const Modal: FC<IModalProps> = ({children, onClose, zIndex, closeIcon, shouldBeAbleToClose = true}) => {
  const onModalClick = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

  const modalRef = useRef<HTMLDivElement>(null); // Ref for the modal

  useEffect(() => {
    const modal = modalRef.current;

    if (modal) {
      const focusableElementsString = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const focusableElements = modal.querySelectorAll(focusableElementsString) as NodeListOf<HTMLElement>;
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];

      const handleKeyDown = (e: KeyboardEvent) => {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
          return;
        }

        firstFocusableElement?.classList.remove(styles.noOutline);

        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus();
            e.preventDefault();
          }
        }
      };

      firstFocusableElement?.focus();
      firstFocusableElement?.classList.add(styles.noOutline);
      modal.addEventListener('keydown', handleKeyDown);

      return () => {
        modal.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, []);

  useEffect(() => {
    const close = (e: KeyboardEvent): void => {
      if (shouldBeAbleToClose) {
        if (e.code === 'Escape') {
          onClose && onClose();
        }
      }
    };

    const event = 'keydown';
    document.addEventListener(event, close);

    return () => document.removeEventListener(event, close);
  });

  const closeModalOptional = () => {
    if (shouldBeAbleToClose) {
      onClose && onClose();
    }
  };

  return (
    <Overlay ref={modalRef} onClose={closeModalOptional} zIndex={zIndex} hideOverflow>
      <div className={styles.overlay}>
        {closeIcon && <CloseIcon className={styles.closeIcon} onClick={closeModalOptional} />}
        <div onClick={onModalClick}>{children}</div>
      </div>
    </Overlay>
  );
};

export {Modal};
export type {IModalProps};
