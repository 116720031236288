import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import './styles/global.scss';
import {InjectionProvider} from './ioc';
import {container} from './ioc/container';
import {inject} from './ioc/inject';
import type {IRoutingService} from './utils/routing';
import {ROUTING_SERVICE} from './utils/routing';
import {App} from './app';

const history = inject<IRoutingService>(ROUTING_SERVICE);

ReactDOM.render(
  <React.StrictMode>
    <InjectionProvider container={container}>
      <Router history={history}>
        <App />
      </Router>
    </InjectionProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
