import {observer} from 'mobx-react';
import type {FC} from 'react';
import {useMemo} from 'react';
import {useInjection} from '../../../ioc';
import type {ITrellisService} from '../../service';
import {TRELLIS_SERVICE} from '../../service';
import {potFlowersConfig} from './pot.config';
import type {IPotData, IPotFlower} from './pot.types';
import {potCoordinatesMap, renderPotFlowerElement} from './pot.util';

interface IPotProps {
  trellisService: ITrellisService;
}

const getItemsToRender = (data?: IPotData): IPotFlower[] =>
  data
    ? potFlowersConfig
        .map((config) => ({
          toRender: config.toRender(data),
          type: config.type,
          flowerCount: config.flowerCount(data),
          modifier: config.modifier(data),
          text: config.text?.(data) ?? '',
        }))
        .filter((potFlower) => potFlower.toRender)
    : [];

const Pots: FC<IPotProps> = ({trellisService}) => {
  const flowers = useMemo(() => getItemsToRender(trellisService.potsFlowersData), [trellisService.potsFlowersData]);

  return <g>{flowers.map((item: IPotFlower, index) => renderPotFlowerElement(item, potCoordinatesMap[index]))}</g>;
};

const PotsScreenObserver = observer(Pots);
const PotsScreenInjected: FC = () => <PotsScreenObserver trellisService={useInjection(TRELLIS_SERVICE)} />;
export {PotsScreenInjected as Pots};
