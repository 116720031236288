import type {IOption} from '../../types';

interface ISingleDropdownProps {
  label?: string;
  value?: IOption<string | number>;
  type?: SingleDropdownType;
  options: readonly IOption<string | number>[];
  isNullable?: boolean;
  onChange?: (value: IOption<string | number>) => void;
  className?: string;
  labelClassName?: string;
  inputRef?: React.Ref<HTMLSelectElement>;
  name?: string;
  isValid?: boolean;
  placeholder?: string;
  isSearchEnabled?: boolean;
  query?: string;
  onQueryChanged?: (value: string) => void;
  noOptionsMessage?: string;
  disabled?: boolean;
  onToggleDropdown?: (isOpened: boolean) => void;
  isError?: boolean;
  showInputValueAlways?: boolean;
  isCleanIcon?: boolean;
  visibleOptionsCount?: number;
  addLabel?: string;
  onAdd?: (title: string) => Promise<string> | void;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

interface SingleDropdownState {
  isOpened: boolean;
  isFocused: boolean;
  value?: string;
}

enum SingleDropdownType {
  Common = 'common',
  Small = 'small',
  Default = 'default',
}

export type {ISingleDropdownProps, SingleDropdownState};
export {SingleDropdownType};
