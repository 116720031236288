enum HealthMonitorGraphTypes {
  Weight = 1,
  LabResultsCRP = 101,
  LabResultsFecalCalprotectin = 102,
  LabResultsVitaminD = 103,
  LabResultsHemoglobin = 104,
  LabResultsAlbumin = 105,
  WwbMyLifeIsGoingWell = 201,
  WwbIbdPain = 202,
  GwbFatigue = 203,
  GwbPhysicalHealth = 204,
  GwbSleepQuality = 205,
  AbdominalBloating = 301,
  AbdominalPain = 302,
  AbdominalDiscomfort = 303,
  IbsSss = 304,
}

export {HealthMonitorGraphTypes};
