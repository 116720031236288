const parseMetaData = (metaData: string | null) => {
  if (metaData) {
    const meta = metaData?.split('|');

    if (meta.length) {
      const type = meta[0];
      const imageType = meta[1].replace(/[()]/g, '');
      const title = meta[2].replace(/\(.+\)/g, '');
      const subTitle = meta[2].match(/\(.+\)/g)?.map((i) => i.replace(/[()]/g, ''))[0];

      return {type, imageType, title, subTitle};
    }
  }

  return null;
};

export {parseMetaData};
