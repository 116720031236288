import React, {useMemo} from 'react';
import {Button, CollapseDescription} from '../../../../../components';
import styles from './index.module.scss';
import {DEFAULT_FORMAT} from '../../../../../helpers';
import {format} from 'date-fns';
import {WarningIcon} from '../../../../../assets/icons';
import type {IBannerModel} from '../../../../../account';

interface IProps {
  onSubmit: (id: number) => void;
  banners: IBannerModel[];
}

const DashBoardBanner = ({onSubmit, banners}: IProps): React.ReactElement => {
  const icon = useMemo(() => <WarningIcon width={45} height={45} />, []);

  return (
    <div className={styles.banners}>
      {banners.map((item) => (
        <CollapseDescription
          key={item.bannerId}
          withPreview={false}
          date={format(new Date(item.createdDate), DEFAULT_FORMAT)}
          title={item.subject}
          titleClassName={styles.title}
          expandText={'View More'}
          expandTextClassName={styles.expandText}
          withCollapseButton={false}
          containerClassName={styles.container}
          icon={icon}
          iconClassName={styles.warningIcon}
          cardContentClassName={styles.cardContent}>
          <div className={styles.text}>{item.text}</div>
          <div className={styles.buttonLayout}>
            <Button onClick={() => onSubmit(item.bannerId)}>OK</Button>
          </div>
        </CollapseDescription>
      ))}
    </div>
  );
};

export {DashBoardBanner};
