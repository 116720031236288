import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {ISelfEfficacyAssessmentRepository} from '../repository';
import {SELF_EFFICACY_ASSESSMENT_REPOSITORY, SelfEfficacyAssessmentRepository} from '../repository';
import type {ISelfEfficacyAssessmentService} from '../service';
import {SELF_EFFICACY_ASSESSMENT_SERVICE, SelfEfficacyAssessmentService} from '../service/';

const SelfEfficacyAssessmentModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<ISelfEfficacyAssessmentRepository>(SELF_EFFICACY_ASSESSMENT_REPOSITORY)
    .to(SelfEfficacyAssessmentRepository)
    .inSingletonScope();

  bind<ISelfEfficacyAssessmentService>(SELF_EFFICACY_ASSESSMENT_SERVICE)
    .to(SelfEfficacyAssessmentService)
    .inSingletonScope();
});

export {SelfEfficacyAssessmentModule};
