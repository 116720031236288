interface IResilienceAssessmentItem {
  question: string;
  dynamicSectionIndex: string | null;
  value: string | null | undefined;
}

class ResilienceAssessmentModel {
  constructor(
    public patientId: number = -1,
    public items: Array<IResilienceAssessmentItem> = [],
    public lastUpdated: Date | null = null,
  ) {}
}

export {ResilienceAssessmentModel};
export type {IResilienceAssessmentItem};
