import type {FC} from 'react';
import {Button} from '../../components';

interface StepperControlProps {
  currentStep: number;
  stepsLength: number;

  onNext: () => void;
  onPrevious: () => void;
}

const StepperControl: FC<StepperControlProps> = ({currentStep, stepsLength, onNext, onPrevious}) => (
  <div>
    {currentStep > 0 && (
      <Button type="button" onClick={onPrevious} primary radius>
        Previous
      </Button>
    )}
    {currentStep < stepsLength - 1 ? (
      <Button type="button" onClick={onNext} primary radius>
        Next
      </Button>
    ) : (
      <Button type="button" onClick={onNext} primary radius>
        Finish
      </Button> // or perform a submit action
    )}
  </div>
);

export default StepperControl;
