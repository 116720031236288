import type {FC} from 'react';
import React from 'react';
import styles from './on-boarding-layout.module.scss';
import {LogoImage} from '../../assets/logo';
import {Footer, Image, OnBoardingBackButton} from '../../components';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import type {ICompanyService} from '../../company';
import {COMPANY_SERVICE} from '../../company';

interface IProps {
  allowToFallBack?: boolean;
}

interface IService {
  company: ICompanyService;
}

const OnBoardingLayout: FC<IProps & IService> = ({allowToFallBack, company, children}) => (
  <div className={styles.root}>
    {allowToFallBack && <OnBoardingBackButton />}
    <header className={styles.header}>
      <Image className={styles.logo} src={LogoImage} alt="trellus" />
    </header>
    <main className={styles.main}>{children}</main>
    <Footer
      className={styles.footer}
      copyright={company.companyInfo.copyRight ?? ''}
      disclaimer={company.companyInfo.disclaimer ?? ''}
    />
  </div>
);

const ObservableOnBoardingLayout = observer(OnBoardingLayout);

const InjectedOnBoardingLayout: FC<IProps> = (props) => (
  <ObservableOnBoardingLayout {...props} company={useInjection(COMPANY_SERVICE)} />
);

export {InjectedOnBoardingLayout as OnBoardingLayout};
