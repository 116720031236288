import type {IEncounterDetailsGetResponseDto} from '../dto';
import {EncounterDetailsModel} from './encounter-detail.model';

class EncounterDetailsModelFactory {
  static fromGetResponseDto(dto: IEncounterDetailsGetResponseDto): EncounterDetailsModel {
    return new EncounterDetailsModel(
      dto.encounterId,
      dto.patientId,
      new Date(dto.date),
      dto.encounterType,
      dto.providerFullName,
      dto.providerTitle,
      dto.progressNotes,
      dto.visitDuration,
      dto.nextRecommendedVisitInfo,
      dto.skills,
      dto.assessmentAndPlan,
    );
  }
}

export {EncounterDetailsModelFactory};
