enum UserRole {
  Undefined = -1,
  SuperAdmin = 0,
  Nurse = 1,
  Provider = 2,
  Patient = 3,
  Doctor = 4,
}

export {UserRole};
