import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Button, Card, CardContent, Modal, Input, Tooltip} from '../../../../../components';
import styles from './two-factor-auth-form.module.scss';
import {useCountdownTimer} from '../../../../../hooks';
import {inject, observer} from 'mobx-react';
import {useInjection} from '../../../../../ioc';
import {getClearedPhoneValue} from '../../../../../helpers';
import classNames from 'classnames';
import {PROFILE_VERIFICATION_SERVICE} from '../../../../../profile-verification';
import type {IProfileVerificationService} from '../../../../../profile-verification';
import {AUTH_SERVICE} from '../../../../../utils/auth';
import type {IAuthService} from '../../../../../utils/auth';
import {SimpleVariant} from './variants/simple';
import {Confirmed} from './variants/confirmed';
import {NewPhoneWithVerification} from './variants/newPhoneWithVerification';
import {NewPhoneWithOutVerification} from './variants/newPhoneWithOutVerification';
import {CloseIcon} from '../../../../../assets/icons';

interface ITwoFactorAuthFormProps {
  onClose: () => void;
  onSubmit: (code: string, phone?: string) => void;
  isVisible: boolean;
  variant: 'newPhone' | 'newAltPhone' | 'simple';
}

interface IServiceProps {
  profileVerificationService: IProfileVerificationService;
  auth: IAuthService;
}

export const SUPPORT = 'support@trellushealth.com';
export const MIN_CODE_LENGTH = 6;
export const MIN_CODE_LENGTH_ERROR_MESSAGE = 'Code must have at least 6 digits';

const RESEND_CODE_ATTEMPT_COUNT = 5;

type TwoFactorAuthFormDto = {
  code: string;
  phone?: string;
  code2?: string;
};

const TwoFactorAuthForm: FC<IServiceProps & ITwoFactorAuthFormProps> = ({
  profileVerificationService,
  onClose,
  isVisible,
  onSubmit,
  variant,
  auth,
}) => {
  const [resendCodeCounter, setResendCodeCounter] = useState(0);
  const [verified, setVerified] = useState(!(variant === 'newPhone'));

  const [resendCodeTimer, reloadResendCodeTimer, isDuringResetCodeTimer] = useCountdownTimer(0, 59);
  const [isLifetimeCodeTimer, reloadLifetimeCodeTimer] = useCountdownTimer(4, 59);

  const {control, handleSubmit, getValues, ...methods} = useForm<TwoFactorAuthFormDto>({
    defaultValues: {code: '', code2: '', phone: ''},
  });

  const onFormSubmit = useCallback(
    async (dto: TwoFactorAuthFormDto) => {
      profileVerificationService.isSend &&
        onSubmit(dto.phone ? (dto.code2 as string) : dto.code, getClearedPhoneValue(dto.phone ?? '') ?? undefined);
    },
    [onSubmit, profileVerificationService.isSend],
  );

  const handleClose = useCallback(() => {
    profileVerificationService.clear();
    onClose();
  }, [profileVerificationService, onClose]);

  const resendCode = useCallback(async () => {
    await profileVerificationService.requestCode();
    setResendCodeCounter((resendCodeCounter) => ++resendCodeCounter);
    reloadResendCodeTimer();
    reloadLifetimeCodeTimer();
  }, [profileVerificationService, reloadResendCodeTimer, reloadLifetimeCodeTimer]);

  const isResendCodeDisabled = useMemo(
    () => isDuringResetCodeTimer || resendCodeCounter === RESEND_CODE_ATTEMPT_COUNT,
    [isDuringResetCodeTimer, resendCodeCounter],
  );

  useEffect(() => {
    if (isVisible && profileVerificationService.isSuccess && variant !== 'newPhone') {
      setTimeout(() => {
        handleClose();
      }, 5000);
    }
  }, [profileVerificationService.isSuccess, handleClose, variant, isVisible]);

  const getUserNumber = useMemo(
    () => <Input disabled={true} value={profileVerificationService.phone ?? ''} />,
    [profileVerificationService.phone],
  );

  useEffect(() => {
    profileVerificationService.getPhone();
  }, [profileVerificationService]);

  const handleLogout = () => {
    auth.logout();
  };

  const verifyCode = useCallback(() => {
    profileVerificationService.verifyCode(getValues().code).then(() => {
      setVerified(true);
    });
  }, [profileVerificationService, getValues]);

  const sendCodeToPhone = useCallback(() => {
    profileVerificationService.sendCodeToPhone(getClearedPhoneValue(getValues().phone ?? '') ?? '');
  }, [profileVerificationService, getValues]);

  const content = useMemo(() => {
    switch (variant) {
      case 'newPhone':
        return (
          <NewPhoneWithVerification
            sendCodeToPhone={sendCodeToPhone}
            isLifetimeCodeTimer={isLifetimeCodeTimer}
            getUserNumber={getUserNumber}
            serviceError={profileVerificationService.error}
            verified={verified}
            verifyCode={verifyCode}
          />
        );
      case 'newAltPhone':
        return (
          <NewPhoneWithOutVerification
            serviceError={profileVerificationService.error}
            getUserNumber={getUserNumber}
            isLifetimeCodeTimer={isLifetimeCodeTimer}
          />
        );
      default:
        return (
          <SimpleVariant
            isLifetimeCodeTimer={isLifetimeCodeTimer}
            getUserNumber={getUserNumber}
            serviceError={profileVerificationService.error}
          />
        );
    }
  }, [
    variant,
    isLifetimeCodeTimer,
    getUserNumber,
    profileVerificationService.error,
    verified,
    sendCodeToPhone,
    verifyCode,
  ]);

  return isVisible && profileVerificationService.isSend ? (
    <Modal
      onClose={handleClose}
      shouldBeAbleToClose={variant === 'newPhone' ? !profileVerificationService.isSuccess : true}>
      <Card className={styles.card}>
        <>
          <button className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </button>
          <h1 className={styles.cardHeader}>Verify Your Identity</h1>
        </>
        {!profileVerificationService.isSuccess ? (
          <>
            <p className={styles.cardInfoMessage}>We've sent a text message to:</p>
            <CardContent className={styles.cardContent}>
              <FormProvider control={control} handleSubmit={handleSubmit} getValues={getValues} {...methods}>
                <form className={styles.cardForm}>{content}</form>
              </FormProvider>

              <Button
                className={styles.cardButton}
                onClick={() => handleSubmit(onFormSubmit)()}
                primary={true}
                disabled={!profileVerificationService.isSend || (variant === 'newPhone' && !verified)}>
                Continue
              </Button>
            </CardContent>
            <div className={styles.cardInfoMessageWithLink}>
              <p className={styles.cardInfoMessage}>Didn't receive a code?</p>
              {isResendCodeDisabled ? (
                <Tooltip text={`You will be able to send a new code after: ${resendCodeTimer}`}>
                  <p className={classNames(styles.cardActionLink, {[styles.disable]: isResendCodeDisabled})}>Resend</p>
                </Tooltip>
              ) : (
                <p className={styles.cardActionLink} onClick={resendCode}>
                  Resend
                </p>
              )}
            </div>
            {isDuringResetCodeTimer && resendCodeCounter < RESEND_CODE_ATTEMPT_COUNT && (
              <div className={styles.cardInfoMessage}>
                You will be able to send a new code after:{' '}
                <span className={styles.cardActionLink}>{resendCodeTimer}</span>
              </div>
            )}
            <div className={styles.cardInfoMessageWithLink}>
              <p className={styles.cardInfoMessage}>No longer have access to this phone number?</p>
              <a className={styles.cardActionLink} href={`mailto:${SUPPORT}`}>
                Request a reset
              </a>
            </div>
          </>
        ) : (
          <Confirmed handleLogout={handleLogout} isLogoutShow={variant === 'newPhone'} />
        )}
      </Card>
    </Modal>
  ) : (
    <></>
  );
};

const TwoFactorAuthFormObserver = observer(TwoFactorAuthForm);
const TwoFactorAuthFormObserverLegacyInjected = inject((profileVerificationService) => profileVerificationService)(
  TwoFactorAuthFormObserver,
) as unknown as FC<IServiceProps>;
const TwoFactorAuthFormInjected: FC<ITwoFactorAuthFormProps> = (props) => (
  <TwoFactorAuthFormObserverLegacyInjected
    {...props}
    profileVerificationService={useInjection(PROFILE_VERIFICATION_SERVICE)}
    auth={useInjection(AUTH_SERVICE)}
  />
);

export {TwoFactorAuthFormInjected as TwoFactorAuthForm};
