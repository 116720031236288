import React from 'react';

import './error-list.scss';
import type {ErrorListProps} from './error-list.types';
import classnames from 'classnames';

class ErrorList extends React.Component<ErrorListProps> {
  render() {
    return (
      <div className={classnames('error-list', this.props.className)}>
        {this.props.errors && this.props.errors.length
          ? this.props.errors.map((error: string | React.ReactNode, index: number) => (
              <div className="error-list__error" key={index}>
                {error}
              </div>
            ))
          : null}
      </div>
    );
  }
}

export {ErrorList};
