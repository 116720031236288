import {observer} from 'mobx-react';
import type {FC} from 'react';
import React, {useEffect} from 'react';
import styles from './trellus-team-member-profile.module.scss';
import {Avatar, Card} from '../../../components';
import {useQuery} from '../../../hooks';
import {routes} from '../../../routing';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import type {IAccountService} from '../../../account';
import {ACCOUNT_SERVICE} from '../../../account';
import {useInjection} from '../../../ioc';

interface IService {
  account: IAccountService;
}

const TrellusTeamMemberProfileScreen: FC<IService> = ({account}) => {
  const {id: providerId} = useQuery<{id: string}>();
  const history = useHistory();

  useEffect(() => {
    if (!providerId) {
      history.push(routes.home);
    }
    account.loadTrellusTeam();
  }, [account, history, providerId]);

  const trellusTeamMember = account.team?.find((teamMember) => teamMember.providerId === Number(providerId));
  const name = `${trellusTeamMember?.firstName} ${trellusTeamMember?.lastName}`;
  const showSendMessageButton =
    trellusTeamMember?.isTriadMember && !!providerId && trellusTeamMember.openForCommunicationWithPatient;

  return (
    <>
      <div className={styles.content}>
        <div className={styles.header}>Trellus team member</div>
        <div className={styles.infoContainer}>
          <div className={styles.infoSection}>
            <Avatar
              src={trellusTeamMember?.avatar ? `data:image/png;base64,${trellusTeamMember?.avatar}` : ''}
              size={74}
              alt={name}
              className={styles.avatar}
            />
            <div>
              <div className={styles.trellusTeamItemName}>{name}</div>
              <div className={styles.trellusTeamItemType}>{trellusTeamMember?.providerType ?? ''}</div>
            </div>
          </div>
          {showSendMessageButton && (
            <Link to={`${routes.home}${routes.messages}?id=${providerId}`} className={styles.button}>
              Send Message
            </Link>
          )}
        </div>
        <Card className={styles.aboutContainer}>
          <div className={styles.aboutTitle}>About me</div>
          <div className={styles.aboutText}>{trellusTeamMember?.providerStatement}</div>
        </Card>
      </div>
    </>
  );
};

const TrellusTeamMemberProfileScreenObserver = observer(TrellusTeamMemberProfileScreen);
const TrellusTeamMemberProfileScreenInjected: FC = () => (
  <TrellusTeamMemberProfileScreenObserver account={useInjection(ACCOUNT_SERVICE)} />
);

export {TrellusTeamMemberProfileScreenInjected as TrellusTeamMemberProfileScreen};
