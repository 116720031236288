import type {FC} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import styles from './add-nav.module.scss';

const AdditionalNavigation: FC = () => {
    const [showButton, setShowButton] = useState(false);

    const handlerScroll = useCallback(() => {
        setShowButton(window.scrollY > 400);
      }, []
    );

    useEffect(() => {
      window.addEventListener("scroll", handlerScroll);

      return () => window.removeEventListener("scroll", handlerScroll);
    }, [handlerScroll]);

    const scrollToTop = useCallback(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, []
    );

  return (
    <div>
      {showButton && (
        <button onClick={scrollToTop} className={styles.backToTop}>
          &#8593; BACK TO TOP
        </button>
      )}
    </div>
  );
};

export {AdditionalNavigation};
