import type {FC} from 'react';
import React from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, Modal} from '../../components';
import styles from './secondary-modal.module.scss';
import classNames from 'classnames';

interface ISecondaryModalProps {
  title?: string;
  text?: string;
  onClose: () => void;
  closeText?: string;
  onSubmit?: () => void;
  submitTest?: string;
  centerText?: boolean;
}

const SecondaryModal: FC<ISecondaryModalProps> = ({
  title,
  text,
  onClose,
  closeText,
  onSubmit,
  submitTest,
  centerText,
}) => {
  const actions = (
    <CardActions className={styles.buttonsWrapper}>
      {onClose && (
        <Button flat onClick={onClose} testid="secondary-modal-close">
          {closeText ?? 'no'}
        </Button>
      )}

      {onSubmit && (
        <Button onClick={onSubmit} testid="secondary-modal-submit">
          {submitTest ?? 'yes'}
        </Button>
      )}
    </CardActions>
  );

  return (
    <Modal onClose={onClose}>
      <Card className={styles.card}>
        <CardHeader title={title ?? ''} closable noBorder onClose={onClose} />
        <CardContent>
          {!!text && <p className={classNames(styles.text, {[styles.centerText]: centerText})}>{text}</p>}
          {actions}
        </CardContent>
      </Card>
    </Modal>
  );
};

export {SecondaryModal};
export type {ISecondaryModalProps};
