import {useLocation} from 'react-router-dom';
import {useMemo} from 'react';

const useQuery = <T>(): Partial<T> => {
  const params = new URLSearchParams(useLocation().search);
  const result: {[key: string]: string} = {};
  for (const [key, value] of params) {
    result[key] = value;
  }
  const res = (result as unknown) as T;

  return useMemo(() => res, [res]);
};

export {useQuery};
