import type {IAlertIndicatorService} from './alert-indicator.service.interface';
import {inject, injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import type {IAlertIndicatorRepository} from '../repository';
import {ALERT_INDICATOR_REPOSITORY} from '../repository';
import type {IAssessmentReportService} from '../../assessment-report/service';
import {ASSESSMENT_REPORT_SERVICE} from '../../assessment-report/service';

@injectable()
class AlertIndicatorService implements IAlertIndicatorService {
  constructor(
    @inject(ALERT_INDICATOR_REPOSITORY) private readonly _repo: IAlertIndicatorRepository,
    @inject(ASSESSMENT_REPORT_SERVICE) private readonly _assessmentReport: IAssessmentReportService,
  ) {
    makeAutoObservable(this);
  }

  private _hasUnreadMessages: boolean = false;

  get hasUnreadMessages(): boolean {
    return this._hasUnreadMessages;
  }

  private _hasUnreadNotifications: boolean = false;

  get hasUnreadNotifications(): boolean {
    return this._hasUnreadNotifications;
  }

  private _hasAllowedStrengthsAssessment: boolean = false;

  get hasAllowedStrengthsAssessment(): boolean {
    return this._hasAllowedStrengthsAssessment;
  }

  private _hasAllowedEmotionalImpactScale: boolean = false;

  get hasAllowedEmotionalImpactScale(): boolean {
    return this._hasAllowedEmotionalImpactScale;
  }

  private _hasAllowedSelfEfficacyAssessment: boolean = false;

  get hasAllowedSelfEfficacyAssessment(): boolean {
    return this._hasAllowedSelfEfficacyAssessment;
  }

  private _hasAllowedResilienceAssessment: boolean = false;

  get hasAllowedResilienceAssessment(): boolean {
    return this._hasAllowedResilienceAssessment;
  }

  private _hasAllowedDiseaseSeverityScreenerAssessment: boolean = false;

  get hasAllowedDiseaseSeverityScreenerAssessment(): boolean {
    return this._hasAllowedDiseaseSeverityScreenerAssessment;
  }

  private _hasAllowedChronicConditionChecklistAssessment: boolean = false;

  get hasAllowedChronicConditionChecklistAssessment(): boolean {
    return this._hasAllowedChronicConditionChecklistAssessment;
  }

  private _hasEvenOneAllowedAssessment: boolean = false;

  get hasEvenOneAllowedAssessment(): boolean {
    return this._hasEvenOneAllowedAssessment;
  }

  setIndicatorMessages(value: boolean): void {
    this._hasUnreadMessages = value;
  }

  setIndicatorNotifications(value: boolean): void {
    this._hasUnreadNotifications = value;
  }

  async load(): Promise<void> {
    await this._assessmentReport.load();

    await this._repo.list().then((indicators) => {
      this._hasUnreadMessages = indicators.hasUnreadMessages;
      this._hasUnreadNotifications = indicators.hasUnreadNotifications;
      this._hasAllowedStrengthsAssessment = indicators.hasAllowedStrengthsAssessment;
      this._hasAllowedEmotionalImpactScale = indicators.hasAllowedEmotionalImpactScale;
      this._hasAllowedSelfEfficacyAssessment = indicators.hasAllowedSelfEfficacyAssessment;
      this._hasAllowedResilienceAssessment =
        this._assessmentReport.isDataExists && indicators.hasAllowedResilienceAssessment;
      this._hasAllowedDiseaseSeverityScreenerAssessment = indicators.hasAllowedDiseaseSeverityScreenerAssessment;
      this._hasAllowedChronicConditionChecklistAssessment = indicators.hasAllowedChronicConditionChecklistAssessment;

      this._hasEvenOneAllowedAssessment =
        this._hasAllowedStrengthsAssessment ||
        this._hasAllowedEmotionalImpactScale ||
        this._hasAllowedSelfEfficacyAssessment ||
        this._hasAllowedDiseaseSeverityScreenerAssessment ||
        this._hasAllowedChronicConditionChecklistAssessment ||
        this._hasAllowedResilienceAssessment;
    });
  }
}

export {AlertIndicatorService};
