import type {LabResultsModel} from '../../../model';
import {LabResultsUpdateRequestDto} from './lab-results-update-request.dto';
import {formatDateApi} from '../../../../helpers';

class LabResultsUpdateRequestDtoFactory {
  static fromModel(model: LabResultsModel): LabResultsUpdateRequestDto {
    return new LabResultsUpdateRequestDto(
      model.patientId,
      formatDateApi(model.labDate || new Date()),
      model.labTest,
      model.comparison,
      model.resultValue,
      model.resultType,
      model.unitsType,
      model.id,
    );
  }
}

export {LabResultsUpdateRequestDtoFactory};
