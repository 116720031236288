import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IHealthMonitorRepository} from '../repository';
import {HEALTH_MONITOR_REPOSITORY, HealthMonitorRepository} from '../repository';
import type {IHealthMonitorService} from '../service';
import {HEALTH_MONITOR_SERVICE, HealthMonitorService} from '../service';

const HealthMonitorModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IHealthMonitorRepository>(HEALTH_MONITOR_REPOSITORY).to(HealthMonitorRepository).inSingletonScope();

  bind<IHealthMonitorService>(HEALTH_MONITOR_SERVICE).to(HealthMonitorService).inSingletonScope();
});

export {HealthMonitorModule};
