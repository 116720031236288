import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import {useInjection} from '../../ioc';
import type {IAssessmentReportService} from '../service';
import {ASSESSMENT_REPORT_SERVICE} from '../service';
import styles from './assessment-report.module.scss';
import {AssessmentReportItem, AssessmentReportOverallScore} from '../components';
import {Button, DateWrapper} from '../../components';
import {PrinterIcon} from '../../assets/icons';
import {Link} from 'react-router-dom';
import {routes} from '../../routing';
import type {IResilienceAssessmentService} from '../../resilience-assessment/service';
import {RESILIENCE_ASSESSMENT_SERVICE} from '../../resilience-assessment/service';
import type { IAccountService} from '../../account';
import {ACCOUNT_SERVICE} from '../../account';

interface IProps {
  reportService: IAssessmentReportService;
  resilienceAssessmentService: IResilienceAssessmentService;
  account: IAccountService;
}

const AssessmentReport: FC<IProps> = observer(({reportService, resilienceAssessmentService, account}) => {
  const overallScore = useMemo(() => reportService.report.overallScore, [reportService.report.overallScore]);

  const scores = useMemo(
    () =>
      [
        reportService.report.symptomsScore,
        reportService.report.independenceScore,
        reportService.report.nutritionScore,
        reportService.report.barriersScore,
        reportService.report.emotionsScore,
      ].map((i) => <AssessmentReportItem key={i.title} score={i} />),
    [
      reportService.report.barriersScore,
      reportService.report.emotionsScore,
      reportService.report.independenceScore,
      reportService.report.nutritionScore,
      reportService.report.symptomsScore,
    ],
  );

  useEffect(() => {
    Promise.all([reportService.load(), resilienceAssessmentService.loadAssessments()]);
  }, [reportService, resilienceAssessmentService]);

  const diseaseName = useMemo(() => account.info?.disease?.name, [account.info?.disease?.name]);

  const onPrintPdf = useCallback(() => reportService.printResilienceAssessmentFile(), [reportService]);

  const isReassessmentButtonShown: boolean = useMemo(
    () => !resilienceAssessmentService.isLoading && !resilienceAssessmentService.isReadOnly,
    [resilienceAssessmentService.isLoading, resilienceAssessmentService.isReadOnly],
  );

  return (
    <>
      <div className={styles.header}>
        <div>
          <span className={styles.title}>Resilience-5 Assessment Report</span>
          <span className={styles.date}>
            <DateWrapper date={reportService.report.date} />
          </span>
        </div>
        <div className={styles.headerControl}>
          {isReassessmentButtonShown && (
            <Link to={`${routes.home}${routes.resilienceAssessment}`} className={styles.reassessmentLink}>
              <Button className={styles.reassessmentButton}>Start the reassessment</Button>
            </Link>
          )}
          <Button className={styles.printButton} onClick={onPrintPdf}>
            <PrinterIcon /> print
          </Button>
        </div>
      </div>

      <ul className={styles.descriptionList}>
        <li className={styles.descriptionItem}>
          Resilience is your ability to adapt and bounce back from adversity, including living with a chronic condition
          like {diseaseName}. People with higher resilience see obstacles as opportunities from which they can grow and
          thrive.
        </li>
        <li className={styles.descriptionItem}>
          This report shows your overall resilience, provides insight into your strengths and identifies areas we’ll be
          working on together.
        </li>
      </ul>

      <AssessmentReportOverallScore
        minValue={overallScore.minValue}
        maxValue={overallScore.maxValue}
        progress={overallScore.scoreSafe}
      />

      <span className={styles.assessmentDriversTitle}>Resilience Drivers</span>
      <div className={styles.assessmentDriversWrap}>{scores}</div>
    </>
  );
});

const AssessmentReportScreenInjected: FC = (props) => (
  <AssessmentReport
    reportService={useInjection(ASSESSMENT_REPORT_SERVICE)}
    resilienceAssessmentService={useInjection(RESILIENCE_ASSESSMENT_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
    {...props}
  />
);

export {AssessmentReport, AssessmentReportScreenInjected};
