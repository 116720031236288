import type {IDiseaseSeverityScreenerItem} from './disease-severity-screener.model';
import {DiseaseSeverityScreenerModel} from './disease-severity-screener.model';
import type {IDiseaseSeverityScreenerFormDto} from '../service';
import {isDate, isDropdown, isRadioButton} from '../../helpers';
import type {IDiseaseSeverityScreenerGetResponseDto} from '../repository';

class DiseaseSeverityScreenerModelFactory {
  static fromFormDto(dto: IDiseaseSeverityScreenerFormDto): DiseaseSeverityScreenerModel {
    const responses: Array<IDiseaseSeverityScreenerItem> = Object.keys(dto).reduce((acc, fieldName: any) => {
      const dtoElement = dto[fieldName];

      const diseaseSeverityScreenerItem: IDiseaseSeverityScreenerItem = {
        question: fieldName,
        value: String(dtoElement),
      };

      if (isDropdown(dtoElement)) {
        diseaseSeverityScreenerItem.value = dtoElement?.displayName;
      }

      if (isRadioButton(dtoElement)) {
        diseaseSeverityScreenerItem.value = dtoElement?.id;
      }

      if (isDate(dtoElement)) {
        diseaseSeverityScreenerItem.value = new Date(dtoElement).getFullYear().toString();
      }

      return [...acc, diseaseSeverityScreenerItem];
    }, [] as Array<IDiseaseSeverityScreenerItem>);

    return new DiseaseSeverityScreenerModel(responses);
  }

  static fromGetResponseDto(dto: IDiseaseSeverityScreenerGetResponseDto): DiseaseSeverityScreenerModel {
    const items = dto.items.map((i: IDiseaseSeverityScreenerItem) => ({
      question: i.question,
      value: i.value,
    }));

    return new DiseaseSeverityScreenerModel(items, dto.patientId, dto.id, new Date(dto.lastUpdated), dto.name);
  }
}

export {DiseaseSeverityScreenerModelFactory};
