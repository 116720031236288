import {ContainerModule} from 'inversify';
import {HTTP_SERVICE} from '../../utils/http';
import type {IAssessmentReportService} from '../service';
import {ASSESSMENT_REPORT_SERVICE, AssessmentReportService} from '../service';

const AssessmentReportModule = new ContainerModule((bind, _, isBound) => {
  if (!isBound(HTTP_SERVICE)) {
    throw new Error(`${HTTP_SERVICE.toString()} is required`);
  }

  bind<IAssessmentReportService>(ASSESSMENT_REPORT_SERVICE).to(AssessmentReportService).inSingletonScope();
});

export {AssessmentReportModule};
