import type {FC} from 'react';
import React, {useMemo, useState} from 'react';
import {sliceString} from '../../helpers';
import styles from './expandable-text.module.scss';
import ReactHtmlParser from 'react-html-parser';

enum ButtonText {
  SHOW_MORE = 'Show more',
  SHOW_LESS = 'Show less',
}

const ExpandableText: FC<{text: string}> = (props) => {
  const [expand, setExpand] = useState(false);

  const handleClick = () => {
    setExpand((prevState) => !prevState);
  };

  const slicedText = useMemo(() => sliceString(props.text, '.'), [props.text]);

  const needToShowExpand = useMemo(() => slicedText.length < props.text.length, [props.text.length, slicedText.length]);

  return (
    <p className={styles.description}>
      {ReactHtmlParser(expand ? props.text : slicedText)}
      {needToShowExpand && (
        <>
          {' '}
          <button type="button" className={styles.textButton} onClick={handleClick}>
            {expand ? ButtonText.SHOW_LESS : ButtonText.SHOW_MORE}
          </button>
        </>
      )}
    </p>
  );
};

export {ExpandableText};
