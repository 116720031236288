import type {FC} from 'react';
import React, {useState} from 'react';
import {ConfirmModal} from '../../../components/confirm-modal';

type ConfirmationDialogOptions = {title?: string; message?: string; actionCallback?: (value: boolean) => void};

const ConfirmationDialogContext = React.createContext<{
  openDialog: ((options: ConfirmationDialogOptions) => void) | null;
}>({openDialog: null});

const ConfirmationDialogProvider: FC = ({children}) => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogConfig, setDialogConfig] = useState<ConfirmationDialogOptions>({});

  const openDialog = ({title, message, actionCallback}: ConfirmationDialogOptions) => {
    setDialogOpen(true);
    setDialogConfig({title, message, actionCallback});
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig?.actionCallback && dialogConfig.actionCallback(true);
  };

  const onCancel = () => {
    dialogConfig?.actionCallback && dialogConfig.actionCallback(false);
    resetDialog();
  };

  return (
    <ConfirmationDialogContext.Provider value={{openDialog}}>
      {isDialogOpen && <ConfirmModal onSubmit={onConfirm} onClose={onCancel} />}
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export {ConfirmationDialogProvider, ConfirmationDialogContext};
export type {ConfirmationDialogOptions};
