export class GoalModel {
  constructor(
    public behaviorTypeGoalSettingId = 0,
    public behaviorTypeId = 0,
    public createdByUserId = 0,
    public createdDate = new Date(),
    public description = '',
    public levelOneExample = '',
    public levelThreeExample = '',
    public levelTwoExample = '',
    public updatedByUserId = 0,
    public updatedDate = new Date(),
  ) {}
}

export class CreateGoalWorksheetModel {
  constructor(
    public adaptive: string,
    public adaptiveScore: number,
    public behaviorTypeId: number,
    public effortLevel: number,
    public goal: string,
    public meaningful: string,
    public meaningfulScore: number,
    public patientCourseId: number,
    public realistic: string,
    public realisticScore: number,
    public specific: string,
    public specificScore: number,
    public timeBound: string,
    public timeBoundScore: number,
    public totalScore: number,
    public type: number,
  ) {}
}

export class WorksheetModel {
  constructor(
    public adaptive: string,
    public adaptiveScore: number,
    public behaviorTypeId: number,
    public courseName: string,
    public createdByUserId: number,
    public createdDate: Date,
    public effortLevel: number,
    public goal: string,
    public goalWorksheetId: number,
    public isArchived: boolean,
    public goalAccomplished: boolean,
    public isDeleted: boolean,
    public meaningful: string,
    public meaningfulScore: number,
    public patientCourseId: number,
    public realistic: string,
    public realisticScore: number,
    public specific: string,
    public specificScore: number,
    public timeBound: string,
    public timeBoundScore: number,
    public totalScore: number,
    public type: number,
    public updatedByUserId: number,
    public updatedDate: Date,
  ) {}
}

export class UpdateReviewWorkflowWorkModel {
  constructor(
    public continueWorking = false,
    public courseId = 0,
    public feelings: string | null = null,
    public goalAccomplished = false,
    public goalWorksheetId = 0,
    public shareResults = false,
  ) {}
}
