import type {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import styles from './messages-screen.module.scss';
import {Card, CardContent, PageLoader, PageTitle} from '../../../components';
import {ChatCard, MessagesThreadListCard} from './components';
import {useHistory} from 'react-router-dom';
import {routes} from '../../../routing';
import {useQuery} from '../../../hooks';
import type {IMessageService, MessageThreadModel} from '../../../message';
import {MESSAGE_SERVICE} from '../../../message';
import {useInjection} from '../../../ioc';
import {searchThreadHelper} from './search-thread';
import classNames from 'classnames';
import type {IAccountService} from '../../../account';
import {PopupNotificationType, ACCOUNT_SERVICE} from '../../../account';
import type {IPopupService} from '../../../utils/popup';
import {POPUP_SERVICE} from '../../../utils/popup';

interface IMessagesScreenProps {
  messages: IMessageService;
  account: IAccountService;
  popup: IPopupService;
}

const MessagesScreen: FC<IMessagesScreenProps> = ({messages, account, popup}) => {
  const [selectedMessageThread, setSelectedMessageThread] = useState<MessageThreadModel | null>(null);
  const [isInitial, setInitial] = useState(false);
  const [threadQuery, setThreadQuery] = useState('');
  const {id: providerId} = useQuery<{id: string}>();
  const history = useHistory();

  const threads = searchThreadHelper(messages.threads, threadQuery).filter(
    (th) => th.recipient.openForCommunicationWithPatient,
  );

  const closeThread = useCallback(() => {
    messages.closeThread();
    history.replace(`${routes.home}${routes.messages}`);
  }, [history, messages]);

  const search = useCallback((query: string) => {
    setThreadQuery(query);
  }, []);

  const sendMessage = useCallback(
    async (message: string, attachmentObjectFileIds: Array<string>) => {
      if (account.id && selectedMessageThread) {
        await messages.sendMessage(account.id, message, attachmentObjectFileIds);
        setSelectedMessageThread(messages.selectedThread);
      }
    },
    [account.id, messages, selectedMessageThread],
  );

  const selectThread = useCallback(
    async (threadId: number) => {
      await messages.selectThread({id: threadId});
      const selectedThread = messages.selectedThread;
      setSelectedMessageThread(selectedThread);

      history.replace(`${routes.home}${routes.messages}?id=${selectedThread?.recipient?.id}`);
    },
    [history, messages],
  );

  const loadAndSelectThreads = useCallback(async () => {
    if (account.id && account.team) {
      await messages.loadThreadsPreview(account.team, account.id);
    }

    if (providerId) {
      await messages.selectThread({recipientId: Number(providerId)});
      setSelectedMessageThread(...[messages.selectedThread]);
    }

    setInitial(true);
  }, [account.id, account.team, messages, providerId]);

  useEffect(() => {
    if (!isInitial && account.team) {
      loadAndSelectThreads();
    }
  }, [account.team, isInitial, loadAndSelectThreads]);

  useEffect(() => {
    popup.loadPopupNotification(PopupNotificationType.PatientReadyForThriveMessagesPage);
  }, [popup]);

  useEffect(
    () => () => {
      messages.closeThread();
    },
    [messages],
  );

  useEffect(() => {
    setSelectedMessageThread(messages.selectedThread);
  }, [messages.selectedThread]);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperLoad]: messages.isLoading,
      })}>
      <PageTitle className={styles.title}>Messages</PageTitle>
      {messages.isLoading ? (
        <PageLoader />
      ) : (
        <Card className={styles.root}>
          <MessagesThreadListCard
            threadQuery={threadQuery}
            onSearch={search}
            onSelect={selectThread}
            threads={threads}
            selectedThread={messages.selectedThread}
          />
          {selectedMessageThread ? (
            <ChatCard
              messages={selectedMessageThread.messages}
              onSend={sendMessage}
              recipient={selectedMessageThread.recipient}
              onClose={closeThread}
              readonly={selectedMessageThread.readonly}
              renderInput={selectedMessageThread.recipient?.id !== 1}
            />
          ) : (
            <CardContent center className={styles.emptyCard}>
              <p className={styles.cardEmptyText}>Select a chat to start messaging</p>
            </CardContent>
          )}
        </Card>
      )}
    </div>
  );
};

const MessagesScreenObserver = observer(MessagesScreen);
const MessagesScreenPage: FC = (props) => (
  <MessagesScreenObserver
    {...props}
    messages={useInjection(MESSAGE_SERVICE)}
    account={useInjection(ACCOUNT_SERVICE)}
    popup={useInjection(POPUP_SERVICE)}
  />
);

export {MessagesScreen, MessagesScreenPage};
export type {IMessagesScreenProps};
