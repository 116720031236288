import type {FC} from 'react';
import React, {useCallback, useMemo} from 'react';
import BaseDatePicker from 'react-datepicker';
import {DatepickerHeader, DatepickerHeaderOnlyYear, DatepickerHeaderSelector, DatepickerHeaderWide} from './components';
import './datepicker-custom.scss';
import styles from './datepicker.module.scss';
import classNames from 'classnames';
import {DEFAULT_FORMAT, MONTH_IN_MILLISECONDS, shiftDateByStringTimeZone} from '../../helpers';
import {createDateFilter} from './filter-date';
import {DatePickerInputType, DatePickerView} from './datepicker.enum';
import {format} from 'date-fns';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import {BlackCalendarIcon, GreyCalendarIcon} from '../../assets/icons';

interface IDatePickerProps {
  min?: Date | null;
  max?: Date | null;
  value: Date | null;
  name?: string;
  selectedDate?: number;
  onMonthChange?: (month: number) => void;
  onChange: (date: Date) => void;
  onCalendarOpen?: () => void;
  availableDates?: Date[];
  disabled?: boolean;
  inline?: boolean;
  label?: string;
  showYearPicker?: boolean;
  format?: string;
  showMonthPicker?: boolean;
  inputType?: DatePickerInputType;
  view?: DatePickerView;
  isValid?: boolean;
  popperClassName?: string;
  numberYearsFuture?: number;
  isLoading?: boolean;
  timeZone?: string;
  previousDate?: Date;
  yearRangeLength?: number;
  ariaLabelTitle?: string;
  ariaLabelError?: string;
}

interface IHeaderProps {
  date: Date;
  customHeaderCount: number;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  decreaseYear: () => void;
  increaseYear: () => void;
}

const DatePicker: FC<IDatePickerProps> = (props) => {
  const decreaseDate = useCallback(() => {
    if (!!props.onMonthChange && props.selectedDate !== undefined) {
      props.onMonthChange(props.selectedDate - MONTH_IN_MILLISECONDS);
    }
  }, [props]);

  const increaseDate = useCallback(() => {
    if (!!props.onMonthChange && props.selectedDate !== undefined) {
      props.onMonthChange(props.selectedDate + MONTH_IN_MILLISECONDS);
    }
  }, [props]);

  const Header: FC<IHeaderProps> = useCallback(
    ({
      date,
      decreaseMonth,
      increaseMonth,
      increaseYear,
      decreaseYear,
      nextMonthButtonDisabled,
      nextYearButtonDisabled,
      prevMonthButtonDisabled,
      prevYearButtonDisabled,
      changeYear,
      changeMonth,
    }) => {
      switch (props.view) {
        case DatePickerView.Wide:
          return (
            <DatepickerHeaderWide
              currentDate={date}
              decreaseMonth={decreaseMonth}
              onMonthDecrease={decreaseDate}
              increaseMonth={increaseMonth}
              onMonthIncrease={increaseDate}
              decreaseYear={decreaseYear}
              increaseYear={increaseYear}
              disableIncreaseMonth={nextMonthButtonDisabled}
              disableDecreaseMonth={prevMonthButtonDisabled}
              disableDecreaseYear={prevYearButtonDisabled}
              disableIncreaseYear={nextYearButtonDisabled}
            />
          );
        case DatePickerView.Selector:
          return (
            <DatepickerHeaderSelector
              currentDate={date}
              numberYearsFuture={props.numberYearsFuture}
              changeYear={changeYear}
              changeMonth={changeMonth}
            />
          );
        case DatePickerView.OnlyYear:
          return (
            <DatepickerHeaderOnlyYear
              currentDate={date}
              decreaseYear={decreaseYear}
              increaseYear={increaseYear}
              minDate={props.min}
              maxDate={props.max}
              yearRangeLength={props.yearRangeLength}
            />
          );
        default:
          return (
            <DatepickerHeader
              currentDate={date}
              decreaseDate={props.showMonthPicker ? decreaseYear : decreaseMonth}
              onMonthDecrease={decreaseDate}
              increaseDate={props.showMonthPicker ? increaseYear : increaseMonth}
              onMonthIncrease={increaseDate}
              showMonthYearPicker={props.showMonthPicker}
              disableIncreaseMonth={nextMonthButtonDisabled}
              disableDecreaseMonth={prevMonthButtonDisabled}
              disableDecreaseYear={prevYearButtonDisabled}
              disableIncreaseYear={nextYearButtonDisabled}
            />
          );
      }
    },
    [
      decreaseDate,
      increaseDate,
      props.max,
      props.min,
      props.numberYearsFuture,
      props.showMonthPicker,
      props.view,
      props.yearRangeLength,
    ],
  );

  const daysToHighlight = useMemo(() => {
    if (!!props.value) {
      if (props.timeZone) {
        return [shiftDateByStringTimeZone(props.value, props.timeZone)];
      }

      return [props.value];
    }

    return [];
  }, [props.timeZone, props.value]);

  const dateFormat = useMemo(() => (props.format ? props.format : DEFAULT_FORMAT), [props.format]);

  const filter = useMemo(() => props?.availableDates && createDateFilter(props.availableDates), [props.availableDates]);

  const selectedDate = useMemo(
    () => (!!props.value && props.timeZone ? utcToZonedTime(props.value, props.timeZone) : props.value),
    [props.timeZone, props.value],
  );

  const onChange = useCallback(
    (value) => {
      const date = props.timeZone ? zonedTimeToUtc(value, props.timeZone) : value;
      props.onChange(date);
    },
    [props],
  );

  const getClassName = useCallback(
    (date) => {
      if (props.previousDate) {
        const zonedPreviousDate = props.timeZone
          ? utcToZonedTime(props.previousDate, props.timeZone)
          : props.previousDate;

        return format(date, DEFAULT_FORMAT) === format(zonedPreviousDate, DEFAULT_FORMAT) ? 'previousDate' : null;
      }

      return null;
    },
    [props.previousDate, props.timeZone],
  );

  const formatWeekDay = useCallback((nameOfDay: string) => nameOfDay.substr(0, 1), []);

  const calendarIcon = useMemo(() => {
    const calendarClassName = classNames(styles.calendarIcon, {
      [styles.calendarIconDefault]: props.inputType === DatePickerInputType.Default || !props.inputType,
      [styles.calendarIconSmall]: props.inputType === DatePickerInputType.Small,
      [styles.calendarIconWide]: props.inputType === DatePickerInputType.Wide,
      [styles.calendarIconLabResults]: props.inputType === DatePickerInputType.LabResults,
      [styles.calendarIconMedications]: props.inputType === DatePickerInputType.Medications,
    });

    return props.disabled || props.inputType === DatePickerInputType.Medications ? (
      <GreyCalendarIcon className={calendarClassName} />
    ) : (
      <BlackCalendarIcon className={calendarClassName} />
    );
  }, [props.disabled, props.inputType]);

  const wrapperClassName = useMemo(
    () =>
      classNames(styles.inputWrapper, {
        [styles.inputWrapperDisabled]: props.disabled,
        [styles.inputWrapperLabResults]: props.inputType === DatePickerInputType.LabResults,
        [styles.inputWrapperMedications]: props.inputType === DatePickerInputType.Medications,
      }),
    [props.disabled, props.inputType],
  );

  const inputClassName = useMemo(
    () =>
      classNames(styles.input, {
        [styles.inputDisabled]: props.disabled,
        [styles.typeDefault]: props.inputType === DatePickerInputType.Default || !props.inputType,
        [styles.typeSmall]: props.inputType === DatePickerInputType.Small,
        [styles.typeWide]: props.inputType === DatePickerInputType.Wide,
        [styles.typeLabResults]: props.inputType === DatePickerInputType.LabResults,
        [styles.typeMedications]: props.inputType === DatePickerInputType.Medications,
        [styles.isNotValid]: props.isValid === false,
        [styles.isNotValidBackground]:
          props.isValid === false &&
          (props.inputType === DatePickerInputType.Medications ||
            props.inputType === DatePickerInputType.Default ||
            !props.inputType),
      }),
    [props.disabled, props.inputType, props.isValid],
  );

  const ariaLabelTitle = useMemo(
    () => `${!!props.ariaLabelError ? `${props.ariaLabelError}.` : ''} ${props.ariaLabelTitle ?? props.name}`,
    [props.ariaLabelError, props.ariaLabelTitle, props.name],
  );

  return (
    <>
      <label id={`date-picker-custom-label-id-${props.name}`} className={styles.ariaLabel}>
        {ariaLabelTitle}
      </label>
      <div className="customDatePicker" data-testid="date-picker-custom-wrapper">
        {!!props.label && <div className={styles.label}>{props.label}</div>}
        <BaseDatePicker
          popperClassName={classNames(styles.header, props.popperClassName, {
            [styles.wideHeader]: props.view === DatePickerView.Wide,
            [styles.monthHeader]: props.showMonthPicker,
            [styles.preloader]: props.isLoading,
          })}
          name={props.name}
          renderCustomHeader={Header}
          className={inputClassName}
          minDate={props.min}
          maxDate={props.max}
          selected={selectedDate}
          onChange={onChange}
          dateFormat={dateFormat}
          disabled={props.disabled}
          inline={props.inline}
          filterDate={filter}
          highlightDates={[{[styles.active]: daysToHighlight}]}
          showMonthDropdown={props.showYearPicker}
          showYearDropdown={props.showYearPicker}
          showYearPicker={props.showYearPicker}
          dropdownMode={props.showYearPicker ? 'select' : undefined}
          showMonthYearPicker={props.showMonthPicker}
          formatWeekDay={formatWeekDay}
          onCalendarOpen={props.onCalendarOpen}
          dayClassName={getClassName}
          yearItemNumber={props.yearRangeLength}
          showFourColumnMonthYearPicker
          showIcon
          icon={calendarIcon}
          wrapperClassName={wrapperClassName}
          ariaLabelledBy={`date-picker-custom-label-id-${props.name}`}
          //popperPlacement="top-end"
          //toggleCalendarOnIconClick
          chooseDayAriaLabelPrefix="Choose day"
          nextYearAriaLabel="Next year"
          nextMonthAriaLabel="Next month"
          previousYearAriaLabel="Previous year"
          previousMonthAriaLabel="Previous month"
          weekAriaLabelPrefix="Current week"
          monthAriaLabelPrefix="Current month"
          disabledDayAriaLabelPrefix="Day is not avaliable to choose"
        />
      </div>
    </>
  );
};

export {DatePicker};
export type {IDatePickerProps};
