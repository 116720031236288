import type {ILabResultsGetResponseDto} from '../repository/dto';
import {LabResultsModel} from './lab-results.model';
import type {LabResultsFormDto} from '../form';
import {shiftTimezone} from '../../helpers';

class LabResultsModelFactory {
  static fromGetResponseDto(dto: ILabResultsGetResponseDto): LabResultsModel {
    return new LabResultsModel(
      dto.labResultInfoId,
      dto.patientId,
      shiftTimezone(new Date(dto.labDate)),
      dto.lastUpdatedByFullName,
      dto.labTestType,
      dto.comparisonOperatorType,
      dto.result,
      dto.resultType,
      dto.unitsType,
    );
  }

  static fromFormDto(dto: LabResultsFormDto, patientId: number, recordId: number | null): LabResultsModel {
    return new LabResultsModel(
      recordId,
      patientId,
      dto.labDate,
      '',
      dto.labTest,
      dto.expression,
      dto.value,
      dto.valueType,
      dto.unitType,
    );
  }
}

export {LabResultsModelFactory};
